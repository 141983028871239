import { BackArrowSmIcon, VerificationSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { FollowTabContent } from 'components/pages/follow/FollowTabContent';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Tabs } from 'components/ui/Tabs';
import { Text } from 'components/ui/typography/Text';
import { FollowPageType } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { defaultFollowedQueryParams } from 'constants/services/followed';
import { defaultFollowersQueryParams } from 'constants/services/followers';
import { useStore } from 'effector-react';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { userFollowedEffects, userFollowedEvents, userFollowedStores } from 'stores/followed';
import { userFollowersEffects, userFollowersEvents, userFollowersStores } from 'stores/followers';
import { userEffects, userEvents, userStores } from 'stores/user';
import { Id } from 'types/interfaces/data';

const { $userFollowed, $hasErrorFollowed } = userFollowedStores;
const { getUserFollowedFx, loadMoreFollowedFx } = userFollowedEffects;
const { resetStoresFollowed } = userFollowedEvents;

const { $userFollowers, $hasErrorFollowers } = userFollowersStores;
const { getUserFollowersFx, loadMoreFollowersFx } = userFollowersEffects;
const { resetStoresFollowers } = userFollowersEvents;

const { getUserProfileFx } = userEffects;
const { resetProfileStores } = userEvents;
const { $userProfile } = userStores;

interface QueryProps {
    tab?: string;
}

export const FollowTabs: FC<Id> = ({ id }) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(FollowPageType.Followers);

    const followed = useStore($userFollowed)?.items || [];
    const currentPageIndexFollowed = useStore($userFollowed)?.currentPageIndex;
    const totalPagesFollowed = useStore($userFollowed)?.totalPages;
    const followedIsLoading = useStore(getUserFollowedFx.pending);
    const hasErrorFollowed = useStore($hasErrorFollowed);
    const isMoreLoadingFollowed = useStore(loadMoreFollowedFx.pending);

    const followers = useStore($userFollowers)?.items || [];
    const currentPageIndexFollowers = useStore($userFollowers)?.currentPageIndex;
    const totalPagesFollowers = useStore($userFollowers)?.totalPages;
    const hasErrorFollowers = useStore($hasErrorFollowers);
    const followersIsLoading = useStore(getUserFollowersFx.pending);
    const isMoreLoadingFollowers = useStore(loadMoreFollowersFx.pending);

    const { username, isTrusted } = useStore($userProfile);

    const textFollowers = `${username} doesn’t have any followers`;
    const textFollowed = `${username} doesn’t follow anyone yet`;

    useEffect(() => {
        const { tab } = queryString.parse(history.location.search) as QueryProps;
        const tabs = [FollowPageType.Followers, FollowPageType.Followings];
        if (tab && tabs.includes(tab as FollowPageType)) {
            setActiveTab(tab as FollowPageType);
        } else {
            history.push(`${AppRoute.Profile}/${id}/follow?tab=${FollowPageType.Followers}`);
        }
    }, [id, history]);

    const goToBack = () => {
        history.push(`${AppRoute.Profile}/${id}`);
    };

    const handleTabsChange = (value: FollowPageType) => {
        history.push(`${AppRoute.Profile}/${id}/follow?tab=${value}`);
        setActiveTab(value);
    };

    const [loadRefFollowers] = useInfiniteScroll({
        loading: isMoreLoadingFollowers,
        hasNextPage:
            currentPageIndexFollowers !== undefined &&
            totalPagesFollowers !== undefined &&
            currentPageIndexFollowers + 1 < totalPagesFollowers,
        onLoadMore: () => {
            currentPageIndexFollowers !== undefined &&
                loadMoreFollowersFx({
                    ...defaultFollowersQueryParams,
                    userId: id,
                    pageIndex: currentPageIndexFollowers + 1
                });
        },
        disabled: hasErrorFollowers,
        rootMargin: '0px'
    });

    const [loadRefFollowed] = useInfiniteScroll({
        loading: isMoreLoadingFollowed,
        hasNextPage:
            currentPageIndexFollowed !== undefined &&
            totalPagesFollowed !== undefined &&
            currentPageIndexFollowed + 1 < totalPagesFollowed,
        onLoadMore: () => {
            currentPageIndexFollowed !== undefined &&
                loadMoreFollowedFx({
                    ...defaultFollowedQueryParams,
                    userId: id,
                    pageIndex: currentPageIndexFollowed + 1
                });
        },
        disabled: hasErrorFollowed,
        rootMargin: '0px'
    });

    useEffect(() => {
        if (id) {
            getUserFollowedFx(id);
            getUserFollowersFx(id);
            getUserProfileFx(id);
        }

        return () => {
            resetProfileStores();
            resetStoresFollowed();
            resetStoresFollowers();
        };
    }, [id]);

    return (
        <Column noWrap height="100%" width="100%">
            <Section noWrap marginBottom={isMobile ? '22px' : '46px'}>
                <IconButton onClick={goToBack}>
                    <BackArrowSmIcon />
                </IconButton>
                {isMobile && (
                    <Section marginLeft="36px">
                        <Text isBold>@{username}</Text>
                        {isTrusted && (
                            <Column justifyCenter marginLeft="10px">
                                <VerificationSmIcon height="20px" width="20px" />
                            </Column>
                        )}
                    </Section>
                )}
            </Section>

            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: FollowPageType.Followers,
                        title: 'Followers',
                        content: (
                            <FollowTabContent
                                followers
                                emptyText={textFollowers}
                                isLoading={followersIsLoading}
                                isMoreLoading={isMoreLoadingFollowers}
                                items={followers}
                                loadRef={loadRefFollowers}
                            />
                        ),
                        width: isMobile ? '50%' : '175px'
                    },
                    {
                        id: FollowPageType.Followings,
                        title: 'Followings',
                        content: (
                            <FollowTabContent
                                emptyText={textFollowed}
                                isLoading={followedIsLoading}
                                isMoreLoading={isMoreLoadingFollowed}
                                items={followed}
                                loadRef={loadRefFollowed}
                            />
                        ),
                        width: isMobile ? '50%' : '175px'
                    }
                ]}
                onChange={handleTabsChange}
            />
        </Column>
    );
};
