import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Alert } from 'components/ui/Alert';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { authStores } from 'stores/auth';
import { videoEffects, videoStores } from 'stores/video';
import { Earnings } from '../Earnings';
import { Scores } from '../Scores';
import { Stats } from '../Stats';

interface Props {
    data: BULLZ.GetVideoDetailsResponse | null;
}

export const ValidationFailContent: FC<Props> = ({ data }) => {
    const user = useStore(authStores.$user);
    const isLoading = useStore(videoEffects.getVideoEarningsFx.pending);
    const earningsData = useStore(videoStores.$earningsData);

    const submitVideo = () => {
        if (!data?.id) return;

        videoEffects.restartWomVideoValidateFx({
            contentId: data.id,
            stakeAmount: 1,
            requestStakingPromotion: !!user?.isFreeStakingEligible
        });
    };

    useEffect(() => {
        if (data?.id) {
            videoEffects.getVideoEarningsFx(data.id);
        }
    }, [data]);

    if (!data) return null;

    return (
        <Column noWrap width="100%">
            <Section marginBottom="24px">
                <Earnings />
            </Section>

            {isLoading && (
                <Section justifyCenter marginBottom="24px">
                    <Spinner />
                </Section>
            )}

            {earningsData && (
                <Column noWrap marginBottom="24px" width="100%">
                    <Section marginBottom="8px">
                        <Text isBold color={baseColors.red} size="-1">
                            WOM Authentication
                        </Text>
                    </Section>

                    <Scores
                        authenticity={earningsData.rating?.authenticity}
                        creativity={earningsData.rating?.creativity}
                        positivity={earningsData.rating?.positivity}
                    />
                </Column>
            )}

            <Section marginBottom="24px">
                <Stats data={data.engagementStatistics} />
            </Section>

            <Section marginBottom="24px">
                <Alert type="dark">
                    Unfortunately your video did not pass WOM Authentication and is not eligible to earn rewards. <br />
                    <br />
                    You can resubmit your video for WOM Authentication again if you feel the rating was made in error.
                </Alert>
            </Section>

            <Button wide onClick={submitVideo}>
                Re-Submit
            </Button>
        </Column>
    );
};
