import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Input } from 'components/ui/inputs/Input';
import { Text } from 'components/ui/typography/Text';
import React, { ChangeEvent, FC, useState } from 'react';
import { settingsPasswordEffects } from 'stores/settings/password';
import { NoopType } from 'types';

const { validatePasswordFx } = settingsPasswordEffects;

interface Props {
    onSubmit: (password: string) => void;
    onForgot: NoopType;
}

export const CurrentPasswordStep: FC<Props> = ({ onSubmit, onForgot }) => {
    const [password, setPassword] = useState('');
    const [hasError, setHasError] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        hasError && setHasError(false);
    };

    const handleNextClick = async () => {
        if (!password) {
            setHasError(true);
            return;
        }

        const isValid = await validatePasswordFx(password);

        if (isValid) {
            setHasError(false);
            onSubmit(password);
        } else {
            setHasError(true);
        }
    };

    return (
        <Column noWrap width="100%">
            <Section marginBottom="8px">
                <Text isBold size="1">
                    Enter Current Password
                </Text>
            </Section>

            <Section marginBottom="16px">
                <Text size="-2">Please enter the current password you use</Text>
            </Section>

            <Section marginBottom="16px">
                <Input
                    error={hasError ? 'Password is incorrect' : undefined}
                    label="Password"
                    placeholder="Current Password"
                    type="password"
                    value={password}
                    onChange={handleChange}
                />
            </Section>

            <Section alignCenter justifyBetween>
                <TextButton onClick={onForgot}>Forgot Password?</TextButton>

                <Button disabled={!password} onClick={handleNextClick}>
                    Next
                </Button>
            </Section>
        </Column>
    );
};
