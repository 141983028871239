import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { defaultInputStyles } from 'constants/styles/mixins';
import styled from 'styled-components';

interface LabelProps {
    wide?: boolean;
}

export const Label = styled.label<LabelProps>`
    width: 100%;
    max-width: ${({ wide }) => (wide ? '100%' : '400px')};
    display: flex;
    flex-direction: column;
`;

interface TextareaStyledProps {
    hasError?: boolean;
}

export const TextareaStyled = styled.textarea<TextareaStyledProps>`
    ${defaultInputStyles};
    width: 100%;
    height: 100px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 10px;
    border: 1px solid ${({ hasError }) => (hasError ? baseColors.red : grey['600'])};
    border-radius: 8px;
    resize: none;
    transition: border ${transitionTime} linear;

    &:hover:not(:disabled) {
        border-color: ${({ hasError }) => (hasError ? baseColors.red : baseColors.white)};
    }

    &:focus:not(:disabled) {
        border-color: ${({ hasError }) => (hasError ? baseColors.red : baseColors.blue)};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const ErrorWrapper = styled.div`
    flex-grow: 1;
    color: ${baseColors.red};
`;
