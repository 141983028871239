import { ReportUserModal } from 'components/modals/ReportUserModal';
import { FollowPageType } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { loginModal } from 'stores/initialize.modal';
import { userFollowEffects } from 'stores/user-follow';
import { DesktopView } from './components/DesktopView';
import { MobileView } from './components/MobileView';
import { Wrapper } from './styles';

const { $user } = authStores;

export const ProfileBlock: FC<BULLZ.GetUserProfileResponse> = props => {
    const history = useHistory();
    const currentUser = useStore($user);

    const goToFollow = (tab: FollowPageType) => {
        if (!currentUser) {
            loginModal.openModal();
            return;
        }

        history.push(`${history.location.pathname}/follow?tab=${tab}`);
    };

    const goToTeam = (teamId: string, id: string) => {
        if (teamId && id) {
            history.push(`${AppRoute.Team}/${teamId}?userId=${id}`);
        }
    };

    const handleFollow = () => {
        currentUser &&
            userFollowEffects.followUserFx({
                userId: currentUser.userId,
                creatorId: props.id,
                isFollow: !props.isFollowed
            });
    };

    return (
        <Wrapper>
            {!isMobile ? (
                <DesktopView
                    currentUser={currentUser}
                    data={props}
                    goToFollow={goToFollow}
                    goToTeam={goToTeam}
                    onFollow={handleFollow}
                />
            ) : (
                <MobileView
                    currentUser={currentUser}
                    data={props}
                    goToFollow={goToFollow}
                    goToTeam={goToTeam}
                    onFollow={handleFollow}
                />
            )}

            <ReportUserModal />
        </Wrapper>
    );
};
