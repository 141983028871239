import { EyeBlockedSmIcon, EyeSmIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC, FocusEvent, InputHTMLAttributes, MouseEvent, ReactNode, useState } from 'react';
import { AddonWrapper, ErrorWrapper, InputWrapper, Label, PasswordButton, StyledInput } from './styles';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    label?: string;
    labelComponent?: ReactNode;
    error?: string;
    wide?: boolean;
    prefix?: ReactNode;
    suffix?: ReactNode;
    addonBefore?: ReactNode;
    addonAfter?: ReactNode;
    bottomExtra?: ReactNode;
    regExp?: RegExp;
}

export const Input: FC<InputProps> = ({
    label,
    labelComponent,
    error,
    regExp,
    prefix,
    suffix,
    addonBefore,
    addonAfter,
    bottomExtra,
    wide = false,
    type = 'text',
    required,
    onFocus,
    onBlur,
    value,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [visibleType, setVisibleType] = useState(type);

    const isPassword = type === 'password';
    const inputValue = regExp && value ? value.toString().replace(regExp, '') : value;

    const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    const handleVisiblePassword = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setVisibleType(state => (state === 'password' ? 'text' : 'password'));
    };

    return (
        <Label wide={wide}>
            {label && (
                <Section marginBottom="8px">
                    <Text size="-1">
                        {label} {required && '*'}
                    </Text>
                </Section>
            )}

            {labelComponent && <Section marginBottom="12px">{labelComponent}</Section>}

            <InputWrapper hasError={!!error} isFocused={isFocused}>
                {addonBefore && (
                    <AddonWrapper hasError={!!error} isFocused={isFocused} position="left">
                        {addonBefore}
                    </AddonWrapper>
                )}

                {prefix && <Row marginLeft="10px">{prefix}</Row>}

                <StyledInput
                    required={required}
                    type={visibleType}
                    value={inputValue}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    {...props}
                />

                {suffix && <MarginWrapper marginRight="10px">{suffix}</MarginWrapper>}

                {isPassword && (
                    <MarginWrapper marginRight="10px">
                        <PasswordButton type="button" onClick={handleVisiblePassword}>
                            {visibleType === 'password' ? <EyeSmIcon /> : <EyeBlockedSmIcon />}
                        </PasswordButton>
                    </MarginWrapper>
                )}

                {addonAfter && (
                    <AddonWrapper hasError={!!error} isFocused={isFocused} position="right">
                        {addonAfter}
                    </AddonWrapper>
                )}
            </InputWrapper>

            {(error || bottomExtra) && (
                <>
                    <MarginWrapper marginTop="8px">
                        {error && (
                            <ErrorWrapper>
                                <Text color="inherit" size="-2">
                                    {error}
                                </Text>
                            </ErrorWrapper>
                        )}
                    </MarginWrapper>
                    {bottomExtra && (
                        <Row justifyEnd marginLeft="8px">
                            {bottomExtra}
                        </Row>
                    )}
                </>
            )}
        </Label>
    );
};
