import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { IconWrapper } from './styles';

interface Props {
    title?: string;
    text?: string | number;
    icon?: FC;
    color?: string;
}

export const InfoBlock: FC<Props> = ({ title, text, icon: Icon, color }) => (
    <Section>
        <Section alignCenter noWrap marginBottom="4px">
            {Icon && (
                <IconWrapper>
                    <Icon />
                </IconWrapper>
            )}
            <Section marginLeft="8px">
                <Text isUppercase size="-2">
                    {title}
                </Text>
            </Section>
        </Section>
        <Section marginLeft="32px">
            <Text color={color}>{text}</Text>
        </Section>
    </Section>
);
