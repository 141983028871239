import { useEffect, useRef, useState } from 'react';

export const useCountdown = (futureTime: number, stop = false) => {
    const [time, setTime] = useState(futureTime - Date.now());

    const intervalRef = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return;
        }

        const interval = (intervalRef.current = setInterval(() => {
            setTime(futureTime - Date.now());
        }, 1000));

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stop]);

    const getReturnValues = (countDown: number) => {
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    return getReturnValues(time);
};
