import notFoundImg from 'assets/img/not-found.png';
import { ProfileCollaborationCard } from 'components/cards/ProfileCollaborationCard';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Empty, Image, VideoCardsWrapper } from 'components/pages/profile/ProfileVideoCards/styles';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { defaultCollaborationQueryParams } from 'constants/services/collaboration';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { collaborationsEffects, collaborationsEvents, collaborationsStores } from 'stores/collaborations';
import { Id } from 'types';
import { StyledLink } from './styles';

const { resetMyCollaborations } = collaborationsEvents;
const { getMyCollaborationsFx } = collaborationsEffects;
const { $myCollaborations, $myCollaborationsHasError } = collaborationsStores;

export const MyCollaborations: FC<Id> = ({ id }) => {
    const isLoading = useStore(getMyCollaborationsFx.pending);
    const data = useStore($myCollaborations);
    const hasError = useStore($myCollaborationsHasError);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: !data ? true : data?.hasNext || false,
        onLoadMore: () => {
            if (!data) {
                getMyCollaborationsFx({
                    ...defaultCollaborationQueryParams,
                    userId: id
                });
            } else {
                getMyCollaborationsFx({
                    limit: defaultCollaborationQueryParams.limit,
                    pageIndex:
                        data.pageIndex !== undefined ? data.pageIndex + 1 : defaultCollaborationQueryParams.pageIndex,
                    userId: id
                });
            }
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    useEffect(() => () => resetMyCollaborations(), []);

    return (
        <Column noWrap width="100%">
            {data?.items && data.items.length > 0 && (
                <VideoCardsWrapper>
                    {data.items.map(item => (
                        <StyledLink
                            key={item.id}
                            to={`${AppRoute.Collaborations}/${item.collaboration?.id}?by=myCollaboration`}
                        >
                            <ProfileCollaborationCard {...item} />
                        </StyledLink>
                    ))}
                </VideoCardsWrapper>
            )}

            <HiddenScrollBlock ref={loadRef} />

            {!isLoading && (!data?.items || data?.items?.length === 0) && (
                <Empty>
                    {isMobile && <Image src={notFoundImg} />}
                    <Text color={grey[500]}>
                        {isMobile
                            ? 'Join a Collaboration and get started!'
                            : 'Your Collaborations will appear here. Join a Collaboration and get started!'}
                    </Text>
                </Empty>
            )}

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
