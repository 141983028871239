import { useDebounce } from 'hooks/useDebounce';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { searchEvents } from 'stores/search';

interface QueryProps {
    query?: string;
}

export const useSearchInput = () => {
    const [isFocused, setIsFocused] = useState(false);
    const history = useHistory();
    const { query } = queryString.parse(history.location.search) as QueryProps;
    const [value, setValue] = useState(query || '');
    const debouncedValue = useDebounce(value, 500);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const onClear = () => {
        setValue('');
        setIsFocused(false);
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    useEffect(() => {
        searchEvents.setSearchValue(debouncedValue);
    }, [debouncedValue]);

    return { value, onChange, onClear, onFocus, isFocused, setIsFocused };
};
