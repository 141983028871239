import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

// Events

const closeDropdown = createEvent();
const toggleDropdown = createEvent();
const setError = createEvent();
const resetStores = createEvent();

// Effects

const getLastNotificationsFx = createEffect({
    handler: async () =>
        await API.notifications.notificationsQuery({
            limit: 5,
            pageIndex: 0
        })
});

const getNotificationsFx = createEffect({
    handler: async () =>
        await API.notifications.notificationsQuery({
            limit: 25,
            pageIndex: 0,
            returnQueryCount: true
        })
});

const loadMoreNotificationsFx = createEffect({
    handler: async (data: BULLZ.QueryNotificationsRequest) => await API.notifications.notificationsQuery(data)
});

// Stores

const $lastNotifications = createStore<BULLZ.NotificationItemResponse[]>([]).on(
    getLastNotificationsFx.doneData,
    (_, payload) => payload.items || []
);

const $notifications = createStore<BULLZ.QueryNotificationsResponse>({})
    .on(getNotificationsFx.doneData, (_, payload) => ({ ...payload, items: payload.items || [] }))
    .on(loadMoreNotificationsFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }));

const $dropdownVisible = createStore(false)
    .on(toggleDropdown, state => !state)
    .reset(closeDropdown);

const $hasError = createStore<boolean>(false)
    .on(setError, () => true)
    .reset([loadMoreNotificationsFx.done, getNotificationsFx.done, resetStores]);

// Exports

export const notificationsEvents = {
    closeDropdown,
    toggleDropdown
};

export const notificationsEffects = {
    getLastNotificationsFx,
    getNotificationsFx,
    loadMoreNotificationsFx
};

export const notificationsStores = {
    $lastNotifications,
    $notifications,
    $dropdownVisible,
    $hasError
};
