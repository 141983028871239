import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { giftsEffects, giftsEvents, giftsStores } from 'stores/gifts';
import { DenominationStep } from './DenominationStep';
import { TermsStep } from './TermsStep';

const { $giftPurchaseModal } = giftsStores;
const { closePurchaseModal } = giftsEvents;
const { buyCardFx } = giftsEffects;

enum Step {
    Denomination,
    Terms
}

export const GiftPurchaseModal = () => {
    const data = useStore($giftPurchaseModal);
    const [activeStep, setActiveStep] = useState(Step.Denomination);
    const [value, setValue] = useState(0);

    const close = () => {
        closePurchaseModal();
    };

    const handleBack = () => {
        if (activeStep === Step.Denomination) {
            closePurchaseModal();
        } else {
            setActiveStep(Step.Denomination);
        }
    };

    const toggleStep = () => {
        if (activeStep === Step.Denomination) {
            setActiveStep(Step.Terms);
        } else {
            setActiveStep(Step.Denomination);
        }
    };

    const handleChangeValue = (value: number) => {
        setValue(value);
    };

    const handleSubmit = () => {
        if (!!data?.productId && !!value) {
            buyCardFx({
                productId: data.productId,
                denomination: value
            });
        }
    };

    useEffect(() => {
        if (!data) {
            setActiveStep(Step.Denomination);
            setValue(0);
        }
    }, [data]);

    return (
        <ModalWrapper visible={!!data} onClose={close}>
            <Section alignCenter justifyBetween marginBottom="28px">
                <IconButton onClick={handleBack}>
                    <BackArrowSmIcon />
                </IconButton>
                <IconButton onClick={close}>
                    <CloseSmIcon />
                </IconButton>
            </Section>

            {data && (
                <Column noWrap width="100%">
                    {activeStep === Step.Denomination ? (
                        <DenominationStep
                            data={data}
                            value={value}
                            onChange={handleChangeValue}
                            onNextStep={toggleStep}
                        />
                    ) : (
                        <TermsStep data={data} onSubmit={handleSubmit} />
                    )}
                </Column>
            )}
        </ModalWrapper>
    );
};
