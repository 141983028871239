import axios from './axios';

export const getBrands = (data: BULLZ.GetVoucherBrandsRequest) =>
    axios<BULLZ.GetVoucherBrandsResponse>({
        url: '/voucher/query-brands',
        data
    });

export const getPurchases = (data: BULLZ.QueryVoucherTransactionRequest) =>
    axios<BULLZ.QueryVoucherTransactionResponse>({
        url: '/voucher/query',
        data
    });

export const purchase = (data: BULLZ.CreateVoucherTransactionRequest) =>
    axios<BULLZ.VoucherTransactionResponse>({
        url: '/voucher/purchase',
        data
    });
