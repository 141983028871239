import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { TextButton } from 'components/ui/buttons/TextButton';
import { CommentBase } from 'components/video/FullscreenVideo/components/Comments/CommentBase';
import { defaultRepliesQueryParams } from 'constants/services/comments';
import { useStore } from 'effector-react';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CommentRepliesItem, commentsEffects, commentsStores } from 'stores/comments';
import { CommentContent, CommentsItem, RepliesItem, RepliesItems, RepliesWrapper } from '../styles';

const { getRepliesByParentIdFx, loadMoreRepliesFx } = commentsEffects;
const { $replies, $repliesLoading } = commentsStores;

interface Props {
    comment: BULLZ.GetRootPostResponse;
    setNewComment: Dispatch<SetStateAction<string>>;
    setReplyingComment: Dispatch<SetStateAction<BULLZ.GetRootPostResponse | undefined>>;
}

export const SingleComment: FC<Props> = ({ comment, setNewComment, setReplyingComment }) => {
    const repliesForAllComments = useStore($replies);
    const repliesLoading = useStore($repliesLoading);

    const [isRepliesShown, setIsRepliesShown] = useState(false);
    const [isRepliesLoading, setIsRepliesLoading] = useState(false);
    const [hasMoreReplies, setHasMoreReplies] = useState(false);
    const [currentReplies, setCurrentReplies] = useState<CommentRepliesItem | null>(null);

    useEffect(() => {
        if (comment.id && comment.id in repliesForAllComments) {
            setCurrentReplies(repliesForAllComments[comment.id]);
        } else {
            setCurrentReplies(null);
        }
    }, [repliesForAllComments, comment]);

    useEffect(() => {
        if (comment.id && comment.id in repliesLoading) {
            setIsRepliesLoading(repliesLoading[comment.id]);
        }
    }, [repliesLoading, comment]);

    useEffect(() => {
        if (
            currentReplies &&
            currentReplies.data?.items &&
            currentReplies.data?.totalRecords &&
            currentReplies.data.items.length < currentReplies.data.totalRecords &&
            !currentReplies.isLoading
        ) {
            setHasMoreReplies(true);
        } else {
            setHasMoreReplies(false);
        }
    }, [currentReplies]);

    const handleViewRepliesClick = () => {
        if (comment.id) {
            setIsRepliesShown(true);
            getRepliesByParentIdFx(comment.id);
        }
    };

    const handleCloseRepliesClick = () => {
        setIsRepliesShown(false);
    };

    const handleLoadMoreRepliesClick = (itemsCount: number, totalCount: number, parentId?: string) => {
        if (!parentId) return;

        const totalPages = Math.ceil(totalCount / defaultRepliesQueryParams.limit) - 1;
        const currentPage = Math.ceil(itemsCount / defaultRepliesQueryParams.limit) - 1;

        if (currentPage < totalPages) {
            loadMoreRepliesFx({
                postId: parentId,
                pageIndex: currentPage + 1
            });
        }
    };

    return (
        <CommentsItem key={comment.id}>
            <AvatarImg diameter="40px" src={comment.profileImageUrl || undefined} />

            <CommentContent>
                <CommentBase
                    currentComment={comment}
                    setNewComment={setNewComment}
                    setReplyingComment={setReplyingComment}
                />

                {comment.id && !!comment.repliesCount && !isRepliesShown && (
                    <Section marginTop="16px">
                        <TextButton onClick={handleViewRepliesClick}>View Replies ({comment.repliesCount})</TextButton>
                    </Section>
                )}

                {currentReplies &&
                    currentReplies.data?.items &&
                    currentReplies.data?.items?.length > 0 &&
                    isRepliesShown && (
                        <Section marginBottom="4px" marginTop="16px">
                            <TextButton onClick={handleCloseRepliesClick}>Hide Replies</TextButton>
                        </Section>
                    )}

                <Section marginBottom="4px" marginTop="4px">
                    {isRepliesLoading && <Spinner />}
                </Section>

                {comment.id && isRepliesShown && currentReplies && (
                    <RepliesWrapper>
                        {currentReplies.data?.items && (
                            <>
                                <RepliesItems>
                                    {currentReplies.data?.items?.map(reply => (
                                        <RepliesItem key={reply.id}>
                                            <AvatarImg diameter="40px" src={reply.profileImageUrl || undefined} />

                                            <CommentContent>
                                                <CommentBase
                                                    currentComment={reply}
                                                    setNewComment={setNewComment}
                                                    setReplyingComment={setReplyingComment}
                                                />
                                            </CommentContent>
                                        </RepliesItem>
                                    ))}
                                </RepliesItems>

                                {hasMoreReplies && (
                                    <Section marginTop="4px">
                                        <TextButton
                                            onClick={() =>
                                                handleLoadMoreRepliesClick(
                                                    currentReplies.data?.items?.length || 0,
                                                    currentReplies.data?.totalRecords || 0,
                                                    comment.id
                                                )
                                            }
                                        >
                                            Load More
                                        </TextButton>
                                    </Section>
                                )}
                            </>
                        )}
                    </RepliesWrapper>
                )}
            </CommentContent>
        </CommentsItem>
    );
};
