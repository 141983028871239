import { Column } from 'components/grid/wrappers/FlexWrapper';
import { disableDefaultButtonStyleMixin } from 'constants/styles';
import { grey } from 'constants/styles/colors';
import styled from 'styled-components';

export const VideoInfoWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

export const Dot = styled.div`
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    background-color: ${grey['500']};
    border-radius: 50px;
`;

export const UserLink = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    align-items: center;
`;
