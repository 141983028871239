import { forward } from 'effector';
import { collaborationEffects } from 'stores/collaboration-details';
import { submissionTimerModal } from 'stores/initialize.modal';
import { participateCollaborationEffects } from './index';

const { joinCollaborationFx, requestParticipationFx, leaveCollaborationFx } = participateCollaborationEffects;
const { checkIsMyCollaborationFx, getMyCollaborationFx } = collaborationEffects;

forward({
    from: joinCollaborationFx.done,
    to: submissionTimerModal.openModal
});

forward({
    from: joinCollaborationFx.doneData,
    to: checkIsMyCollaborationFx
});

forward({
    from: requestParticipationFx.doneData,
    to: checkIsMyCollaborationFx
});

forward({
    from: leaveCollaborationFx.doneData,
    to: getMyCollaborationFx
});
