export const asyncErrorMessage = 'Something went wrong';
export const unknownErrorMessage = 'unknown';

export const disclaimerTitle = 'Disclaimer';
export const disclaimerText =
    'Content on BULLZ does not constitute financial advice. The nature of the user generated content presented in the BULLZ app are for general informational purposes only. No information on any part of the app is intended as investment, tax, accounting or legal advice. Users should always check with their licensed financial advisor, legal counsel, and/or tax advisor to determine the suitability of any investment or financial plan.';
export const disclaimerTextForCollaboration =
    'This video is part of a brand Collaboration, which is why it has the hashtag “sponsored”. All content on BULLZ does not constitute financial advice. The nature of the user generated content and collaboration content presented in the BULLZ app are for general informational purposes only. No information on any part of the app is intended as investment, tax, accounting or legal advice. Users should always check with their licensed financial advisor, legal counsel, and/or tax advisor to determine the suitability of any investment or financial plan.';
export const disclaimerButtonText = 'Okay got it';

// Form messages
export const requiredFieldMessage = 'This field is required';
export const requiredShortMessage = 'Required';
export const invalidEmailMessage = 'Invalid email address';
export const invalidWebsiteLinkMessage = 'Invalid address';
export const exceededCharacterLimit = 'Character limit exceeded';

// Password
export const passwordLengthMessage = (min: number) => 'The password must be at least ' + min + ' characters long';
export const oneCapitalCharRequiredMessage = 'At least one capital letter required';
export const atLeastOneNumberRequiredMessage = 'At least one number required';
export const oneSpecialCharacterRequiredMessage = 'At least one special character required';
