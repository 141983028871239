import { GiftSmIcon, PlusSmIcon, WomSmIcon } from 'assets/icons';
import { AppRoute } from 'constants/routes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { qexWidgetModal, walletInfoModal } from 'stores/initialize.modal';
import { ClickableCard } from '../ClickableCard';
import { CardsGrid } from './styles';

export const WalletCards = () => {
    const history = useHistory();

    return (
        <CardsGrid>
            <ClickableCard icon={WomSmIcon} title="Buy WOM" onClick={() => qexWidgetModal.openModal()} />
            <ClickableCard icon={PlusSmIcon} title="Deposit WOM" onClick={() => walletInfoModal.openModal()} />
            <ClickableCard icon={GiftSmIcon} title="Gift Card" onClick={() => history.push(AppRoute.Gifts)} />
        </CardsGrid>
    );
};
