import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(Column)`
    width: 100%;
    height: calc(100vh - 2 * ${headerHeight});
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    ${media.laptop(`
        height: 100%;
    `)};
`;

export const Image = styled.img`
    width: 340px;
    height: 150px;
    margin-bottom: 8px;

    ${media.laptop(`
        width: 724px;
        height: 315px;
    `)};
`;

export const TextWrapper = styled(Section)`
    max-width: 300px;
    margin-bottom: 24px;

    ${media.laptop(`
        max-width: 400px;
    `)};
`;
