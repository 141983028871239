import { Section } from 'components/grid/wrappers/FlexWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { Spinner } from 'components/loaders/Spinner';
import { MyProfileCards } from 'components/pages/profile/MyProfileCards';
import { ProfileBlock } from 'components/pages/profile/ProfileBlock';
import { ProfileVideoCards } from 'components/pages/profile/ProfileVideoCards';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { userEffects, userEvents, userStores } from 'stores/user';
import { Id } from 'types/interfaces/data';

const { openModal: openDisclaimerModal } = infoModal;

export const Profile = () => {
    const { id } = useParams<Partial<Id>>();
    const userProfile = useStore(userStores.$userProfile);
    const isLoading = useStore(userEffects.getUserProfileFx.pending);
    const authUser = useStore(authStores.$user);

    const isMyProfile = useMemo(() => !!authUser && !!id && authUser.userId === id, [authUser, id]);

    const history = useHistory();

    useEffect(() => {
        id &&
            userEffects.getUserProfileFx(id).catch(() =>
                openDisclaimerModal({
                    title: 'Account Unavailable',
                    text: 'Unfortunately, this account is unavailable.',
                    buttonText: 'Ok, got it!',
                    dark: true,
                    onClick: () => history.goBack(),
                    afterCloseCb: () => {
                        history.goBack();
                    }
                })
            );

        return () => userEvents.resetProfileStores();
    }, [history, id]);

    return (
        <NewMainLayout>
            {isLoading ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : (
                <ProfileBlock {...userProfile} />
            )}

            {id && Object.keys(userProfile).length > 0 && (
                <>{isMyProfile ? <MyProfileCards id={id} /> : <ProfileVideoCards id={id} />}</>
            )}
        </NewMainLayout>
    );
};
