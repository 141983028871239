import { useEffect } from 'react';

interface Params {
    [code: string]: () => void;
}

export const useKeyboard = (props: Params) => {
    const handleKeyDown = (event: KeyboardEvent) => {
        const code = event.code;

        if (Object.keys(props).length > 0 && code in props && typeof props[code] === 'function') {
            event.preventDefault();
            props[code]();
            return;
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => document.removeEventListener('keydown', handleKeyDown, false);
    });
};
