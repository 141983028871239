import { CloseSmIcon, EditSmIcon, PauseLgIcon, PlayLgIcon } from 'assets/icons';
import { RelativeWrapper } from 'components/grid/wrappers/RelativeWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Timeline } from 'components/video/Timeline';
import { Volume } from 'components/video/Volume';
import { baseColors } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { playerStores, playerEvents } from 'stores/player';
import { NoopType } from 'types';
import { heightResolutionDefault, maxDurationDefault, minDurationDefault, widthResolutionDefault } from './constants';
import { Controls, IconWrapper, RoundButton, Top } from './styles';

const { $volumeVideoInput, $isMutedVideoInput } = playerStores;
const { setVolumeVideoInput, toggleMuteVolumeVideoInput } = playerEvents;

interface Props {
    source: string;
    setSource: Dispatch<SetStateAction<string | null>>;
    setHasError: Dispatch<SetStateAction<boolean>>;
    setIsMetadataLoaded: Dispatch<SetStateAction<boolean>>;
    editFile: NoopType;
    setFile: Dispatch<SetStateAction<File | null>>;
    isTopControlsVisible?: boolean;
    minDuration?: number;
    maxDuration?: number;
    widthResolution?: number;
    heightResolution?: number;
}

export const Video: FC<Props> = ({
    source,
    setSource,
    setHasError,
    setIsMetadataLoaded,
    editFile,
    setFile,
    isTopControlsVisible,
    minDuration = minDurationDefault,
    maxDuration = maxDurationDefault,
    widthResolution = widthResolutionDefault,
    heightResolution = heightResolutionDefault
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const volume = useStore($volumeVideoInput);
    const isMuted = useStore($isMutedVideoInput);
    const [totalDuration, setTotalDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.volume = volume;
        }
    }, [volume]);

    const toggleVideo = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
                setIsPlaying(false);
            } else {
                videoRef.current.play();
                setIsPlaying(true);
            }
        }
    };

    const setNewTime = (value: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = value;
        }
    };

    const onTimeUpdate = (e: ChangeEvent<HTMLVideoElement>) => {
        setCurrentTime(e.target.currentTime);
    };

    const onLoadedMetadata = (e: ChangeEvent<HTMLVideoElement>) => {
        const { duration, videoHeight, videoWidth } = e.target;

        if (
            duration < minDuration ||
            duration > maxDuration ||
            videoWidth > widthResolution ||
            videoHeight > heightResolution
        ) {
            setHasError(true);
            setSource(null);
        } else {
            setTotalDuration(duration);
        }

        setIsMetadataLoaded(true);
    };

    const onDeleteSource = () => {
        setSource(null);
        setIsMetadataLoaded(false);
        setHasError(false);
        setFile(null);
    };

    const onEditSource = () => {
        onDeleteSource();
        editFile();
    };

    return (
        <RelativeWrapper height="100%" width="100%">
            <video
                ref={videoRef}
                loop
                height="100%"
                src={source}
                width="100%"
                onLoadedMetadata={onLoadedMetadata}
                onTimeUpdate={onTimeUpdate}
            />
            {isTopControlsVisible && (
                <Top>
                    <RoundButton color={baseColors.red} onClick={onDeleteSource}>
                        <CloseSmIcon />
                    </RoundButton>
                    <RoundButton color={baseColors.blue} onClick={onEditSource}>
                        <EditSmIcon />
                    </RoundButton>
                </Top>
            )}
            <IconWrapper>
                <IconButton size="60px" onClick={toggleVideo}>
                    {isPlaying ? <PauseLgIcon /> : <PlayLgIcon />}
                </IconButton>
            </IconWrapper>
            <Controls>
                <Timeline currentTime={currentTime} totalDuration={totalDuration} onChange={setNewTime} />
                <Volume
                    isMuted={isMuted}
                    setVolume={setVolumeVideoInput}
                    toggleMuteVolume={toggleMuteVolumeVideoInput}
                    volume={volume}
                />
            </Controls>
        </RelativeWrapper>
    );
};
