import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import walletImg from 'assets/img/create-wallet/wallet.png';
import { CustomImg } from 'components/common/CustomImg';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { createWalletPinModal } from 'stores/initialize.modal';
import { noop } from 'utils/common';
import { ConfirmStep } from './ConfirmStep';
import { Step } from './constants';
import { CreateStep } from './CreateStep';
import { ImageWrapper } from './styles';
import { validateWalletPinForm, walletPinValues } from './validation';

const { modal, closeModal } = createWalletPinModal;
export const CreateWalletPinModal = () => {
    const [visible] = useStore(modal);
    const [currentStep, setCurrentStep] = useState<Step>(Step.Create);

    const steps: Record<Step, ReactNode> = {
        create: <CreateStep setCurrentStep={setCurrentStep} />,
        confirm: <ConfirmStep />
    };

    const handleClose = () => closeModal();

    const handleBack = () => {
        if (currentStep === Step.Confirm) {
            setCurrentStep(Step.Create);
        } else {
            closeModal();
        }
    };

    useEffect(() => {
        !visible && setCurrentStep(Step.Create);
    }, [visible]);

    return (
        <ModalWrapper fullscreenMobile visible={visible} zIndex={200} onClose={closeModal}>
            <Section justifyBetween marginBottom="24px">
                <IconButton color={grey[500]} onClick={handleBack}>
                    <BackArrowSmIcon />
                </IconButton>
                <IconButton color={grey[500]} onClick={handleClose}>
                    <CloseSmIcon />
                </IconButton>
            </Section>

            <Column justifyBetween noWrap height="100%" width="100%">
                <ImageWrapper>
                    <CustomImg src={walletImg} />
                </ImageWrapper>

                <Formik initialValues={walletPinValues} validate={validateWalletPinForm} onSubmit={noop}>
                    <Column width="100%">{steps[currentStep]}</Column>
                </Formik>
            </Column>
        </ModalWrapper>
    );
};
