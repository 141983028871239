import styled from 'styled-components';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { baseColors, disableDefaultButtonStyleMixin, flexCenter, grey, transitionTime } from 'constants/styles';

export const KeyboardShortcutModalStyled = styled.div`
    position: absolute;
    right: 0;
    z-index: 99; // \* modal below all others
`;

export const ModalContent = styled.div`
    position: fixed;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 228px;
    max-height: 100%;
    border-radius: 8px;
    background-color: ${grey['900']};
    padding: 8px 10px;
    transform: translateX(-100%);
    overflow-y: auto;
`;

export const TitleWrapper = styled.div`
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const ButtonsWrapper = styled(Section)`
    justify-content: flex-end;
    margin-bottom: 4px;
`;

export const ButtonIconWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: ${grey[500]};
    transition: color ${transitionTime} linear;

    &:hover {
        color: ${baseColors.white};
    }
`;

export const ShortcutKeyWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;
