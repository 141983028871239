import { WrongLgIcon } from 'assets/icons';
import { asyncErrorMessage } from 'constants/messages';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { bucmAPI } from 'services/bucm';
import { womAPI } from 'services/wom';
import { authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { userVideosEffects } from 'stores/user-videos';
import { createNotifyingEffect, goToHelpDesk } from 'utils/common';

// Events

const updateIsMuted = createEvent();
const openStatusVideoModal = createEvent<string>();
const closeStatusVideoModal = createEvent();

// Effects

const registerEventVideo = createEffect({
    handler: async (data: BULLZ.RegisterAggregatedEventRequest) =>
        API.video.registerVideoAsViewed({ ...data, sessionId: authStores.$sessionId.getState() })
});

const reportVideo = createEffect({
    handler: async (data: BULLZ.ReportVideoRequest) => API.video.reportVideo(data)
});

const reportVideoFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: `There was a problem reporting this video. Please try again or contact our support team.`,
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onCloseClick: goToHelpDesk
        });
    }
});

export const loadPlaylistCreator = createNotifyingEffect({
    handler: async (data: BULLZ.QueryPlaylistVideosRequest) => {
        const playlist = await API.playlist.queryByFilter(data);

        return playlist.items || [];
    }
});

const getValidVideoFx = createEffect({
    handler: async (data: BULLZ.GetVideoDetailsRequest) => API.video.getValidationVideo(data)
});

const beginWomVideoValidateFx = createEffect({
    handler: async (data: BULLZ.WOMStartValidationRequest) =>
        API.video.beginWomValidate({ ...data, holdUntilApproved: false })
});

const beginWomVideoValidateDoneFx = createEffect({
    handler: (data: BULLZ.ValidationStateResponse) => {
        if (data.remoteContentId) {
            getValidVideoFx({
                videoId: data.remoteContentId
            });
        }

        userVideosEffects.getMyUserVideosFx();
    }
});

const failFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onCloseClick: goToHelpDesk
        });
    }
});

const restartWomVideoValidateFx = createEffect({
    handler: async (data: BULLZ.WOMRestartValidationRequest) => API.video.restartWomValidate(data)
});

const getVideoEarningsFx = createEffect({
    handler: async (videoId: string) => womAPI.videos.getVideoEarnings({ remoteContentId: videoId })
});

interface CollaborationVideoEarningsParams {
    userId: string;
    collaborationId: string;
}

const getCollaborationVideoEarningsFx = createEffect({
    handler: async ({ userId, collaborationId }: CollaborationVideoEarningsParams) => {
        try {
            if (!userId || !collaborationId) {
                throw new Error('User ID or collab ID is empty');
            }

            const { data, isSuccess } = await bucmAPI.collaboration.getCollaborationVideoEarnings(
                collaborationId,
                userId
            );

            if (!data || !isSuccess) {
                throw new Error();
            }

            return data;
        } catch (e) {
            console.log('Failed to get video earnings:', e);
            return null;
        }
    }
});

// Stores

const playlistItemsCreator = createStore<BULLZ.PlaylistVideoResponse[]>([]).on(
    loadPlaylistCreator.doneData,
    (state, playlist: BULLZ.PlaylistVideoResponse[]) => [...state, ...playlist]
);

const isMuted = createStore(false).on(updateIsMuted, state => !state);

const $statusVideoModalId = createStore<string | null>(null)
    .on(openStatusVideoModal, (_, id) => id)
    .reset([closeStatusVideoModal, getValidVideoFx.fail]);

const $statusVideoData = createStore<BULLZ.GetVideoDetailsResponse | null>(null)
    .on(getValidVideoFx.doneData, (_, data) => data)
    .reset([closeStatusVideoModal, getValidVideoFx.fail]);

const $earningsData = createStore<WOM.EarningReportResponse | null>(null)
    .on(getVideoEarningsFx.doneData, (_, data) => data)
    .reset([closeStatusVideoModal, getVideoEarningsFx.fail]);

const $collaborationEarningsData = createStore<BUCM.CollaborationEarningDetailsResponse | null>(null)
    .on(getCollaborationVideoEarningsFx.doneData, (_, data) => data)
    .reset([closeStatusVideoModal, getCollaborationVideoEarningsFx.fail]);

const $isLoadingRegisterEventVideo = createStore<boolean>(false)
    .on(registerEventVideo, () => true)
    .on(registerEventVideo.finally, () => false);

// Exports

export const videoEffects = {
    registerEventVideo,
    loadPlaylistCreator,
    reportVideo,
    reportVideoFailFx,
    getValidVideoFx,
    beginWomVideoValidateFx,
    beginWomVideoValidateDoneFx,
    restartWomVideoValidateFx,
    failFx,
    getVideoEarningsFx,
    getCollaborationVideoEarningsFx
};

export const videoEvents = {
    updateIsMuted,
    openStatusVideoModal,
    closeStatusVideoModal
};

export const videoStores = {
    playlistItemsCreator,
    isMuted,
    $statusVideoModalId,
    $statusVideoData,
    $earningsData,
    $collaborationEarningsData,
    $isLoadingRegisterEventVideo
};
