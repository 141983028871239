import { Section } from 'components/grid/wrappers/FlexWrapper';
import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { defaultInputStyles } from 'constants/styles/mixins';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface InputsWrapperProps {
    hasError?: boolean;
}

export const InputsWrapper = styled(Section)<InputsWrapperProps>`
    .otp-input {
        width: 100%;
    }

    .otp-input input {
        ${defaultInputStyles};
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 100% !important;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        color: ${baseColors.white};
        border: 1px solid ${({ hasError }) => (hasError ? baseColors.red : grey[600])};
        border-radius: 8px;
        transition: border ${transitionTime} linear;

        &:hover {
            border-color: ${baseColors.white};
        }

        &:focus {
            border-color: ${baseColors.blue};
        }
    }
`;

export const containerStyle: CSSProperties = {
    width: '100%',
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'repeat(6, 1fr)'
};
