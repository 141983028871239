import { BackArrowSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { ChannelInfo } from 'components/pages/quickSignals/ChannelInfo';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface Params {
    channelId: string;
}

export const QuickSignalsChannel = () => {
    const history = useHistory();
    const { channelId } = useParams<Params>();

    const goToBack = () => {
        history.push(AppRoute.QuickSignalsSubscriptions);
    };

    return (
        <NewMainLayout hasMobilePaddingTop>
            <Section alignCenter noWrap marginBottom="24px">
                <IconButton color={grey[500]} onClick={goToBack}>
                    <BackArrowSmIcon />
                </IconButton>
                <Column marginLeft="12px">
                    <Text isBold size="1">
                        Channel Details
                    </Text>
                </Column>
            </Section>

            <ChannelInfo channelId={channelId} />
        </NewMainLayout>
    );
};
