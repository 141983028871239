import { InputField } from 'components/form/InputField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Network, networkAssociation } from 'components/modals/ShareLinkModal/constants';
import { networkTexts } from 'components/modals/ShareLinkModal/ThirdStep/constants';
import { LinkBlock, LinkText } from 'components/modals/ShareLinkModal/ThirdStep/styles';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { yupUrl } from 'constants/yup';
import { useStore } from 'effector-react';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { bucmUserStores } from 'stores/bucm-user';
import { collaborationStores } from 'stores/collaboration-details';
import { socialNetworksStores } from 'stores/social-networks';
import { uploadSubmissionEffects } from 'stores/upload-submission';
import * as Yup from 'yup';

const { $myCollaborationData } = collaborationStores;
const { $bucmUser } = bucmUserStores;

interface Props {
    selectedNetwork: Network | null;
}

export const ThirdStep: FC<Props> = ({ selectedNetwork }) => {
    const { data: networks } = useStore(socialNetworksStores.$externalNetworks);
    const myCollaborationData = useStore($myCollaborationData);
    const bucmUser = useStore($bucmUser);
    const textData = selectedNetwork ? networkTexts[selectedNetwork] : null;
    const [isEditing, setIsEditing] = useState(true);

    const formik = useFormik({
        initialValues: {
            postUri: ''
        },
        onSubmit: ({ postUri }) => {
            if (selectedNetwork && bucmUser.userId) {
                const networkId =
                    networks?.items?.find(
                        network => network.title?.toLowerCase() === networkAssociation[selectedNetwork]
                    )?.id || '';
                uploadSubmissionEffects.shareSocialLinkFx({
                    collaborationId: myCollaborationData?.id || '',
                    networkId,
                    userId: bucmUser.userId,
                    videoId: (myCollaborationData?.mediaAssets && myCollaborationData.mediaAssets[0].remoteId) || '',
                    postUri,
                    networkType: textData?.text || ''
                });
            }
        },
        validationSchema: Yup.object().shape({
            postUri: yupUrl
        })
    });
    const { isValid, dirty, handleSubmit, values } = formik;

    if (!textData) {
        return null;
    }

    return (
        <Section justifyCenter>
            <Title size="4">{isEditing ? `Add Link to ${textData.title}` : 'Submit Link'}</Title>
            <Section justifyCenter marginBottom="16px" marginTop="16px">
                <Text alignTextCenter size="-2">
                    {isEditing
                        ? `Copy the link to your ${textData.text} and paste it here:`
                        : `Are you sure this is the correct link to your ${textData.text}? This cannot be changed after submitting. Please note: we will need the correct link to ensure you receive your reward.`}
                </Text>
            </Section>
            <FormikProvider value={formik}>
                <FormWrapper onSubmit={handleSubmit}>
                    {isEditing ? (
                        <>
                            <Section marginBottom="24px">
                                <InputField label="Add Link" name="postUri" placeholder={textData.placeholder} />
                            </Section>
                            <Button key="0" wide disabled={!isValid || !dirty} onClick={() => setIsEditing(false)}>
                                Add Link
                            </Button>
                        </>
                    ) : (
                        <>
                            <LinkBlock>
                                <MarginWrapper marginBottom="6px">
                                    <Text size="-2">Your shared link</Text>
                                </MarginWrapper>
                                <LinkText>{values.postUri}</LinkText>
                            </LinkBlock>
                            <Section marginBottom="24px" marginTop="24px">
                                <Button key="1" wide type="submit">
                                    Submit
                                </Button>
                            </Section>
                            <TextButton isBold onClick={() => setIsEditing(true)}>
                                EDIT LINK
                            </TextButton>
                        </>
                    )}
                </FormWrapper>
            </FormikProvider>
        </Section>
    );
};
