import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { notify } from 'components/ui/Toasts';
import { baseURL, bullzApiVersion, lsAnonymousUserKey, lsTokenKey } from 'constants/global';
import { asyncErrorMessage, unknownErrorMessage } from 'constants/messages';
import { authEffects, authEvents } from 'stores/auth';

const bullzAxiosInstance = axios.create({
    baseURL: baseURL,
    method: 'POST'
});

const exceptionRoutes = ['/user/delete-account', '/categories'];

bullzAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status;
        const data = config.response.data;
        const url = (config.response.config.url as string) || '';

        if ([401, 403].includes(status) && !exceptionRoutes.includes(url)) {
            authEffects.createAnonymousAccountFx();
            authEvents.logout();
        }

        if (status >= 500) {
            const message: string | undefined = data?.message;
            notify(message && message.toLowerCase() !== unknownErrorMessage ? message : asyncErrorMessage, 'danger');
        }

        return Promise.reject(data);
    }
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const tokenFromLS = JSON.parse(localStorage.getItem(lsTokenKey) || '') as string;
    const { token: anonymousTokenFromLS } = JSON.parse(
        localStorage.getItem(lsAnonymousUserKey) || ''
    ) as BULLZ.UserJwtTokenResponse;

    const headers: any = {};

    if (withToken) {
        headers['Authorization'] = `Bearer ${tokenFromLS || anonymousTokenFromLS}`;
    }

    headers['api-version'] = bullzApiVersion;

    const request: AxiosPromise<T> = bullzAxiosInstance({
        ...config,
        headers: { ...config.headers, ...headers }
    });

    return (request as any) as Promise<T>;
};
