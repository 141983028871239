import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors, ellipsisMixin } from 'constants/styles';
import styled from 'styled-components';

export const LinkBlock = styled(Column)`
    background-color: ${baseColors.black};
    border-radius: 4px;
    padding: 8px;
`;

export const LinkText = styled(Text)`
    width: 100%;
    ${ellipsisMixin}
`;
