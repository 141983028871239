import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { VideoCardsProps } from 'components/pages/trending/TrendingVideoCards';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const VideoCardsWrapper = styled.div<VideoCardsProps>`
    display: grid;
    width: 100%;
    padding-bottom: 24px;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    ${media.laptop(`
        grid-template-columns: repeat(4, 1fr);
        row-gap: 8px;
        column-gap: 5px;
    `)};
`;

export const Wrapper = styled(Column)`
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
`;

export const Empty = styled.div`
    width: 100%;
    height: calc(100vh - 2 * ${headerHeight} - 56px);
    background-color: ${baseColors.darkGrey};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ImageWrapper = styled.div`
    ${flexCenter};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 12px;
    background-color: ${baseColors.black};
`;

export const TitleWrapper = styled(Section)`
    flex-wrap: nowrap;
    margin-bottom: 20px;

    ${media.laptop(`
        margin-bottom: 24px;
    `)};
`;
