import { Column } from 'components/grid/wrappers/FlexWrapper';
import { baseColors } from 'constants/styles';
import styled, { css } from 'styled-components';

interface WrapperProps {
    hasBackground?: boolean;
}

export const Wrapper = styled(Column)<WrapperProps>`
    width: 100%;
    flex-wrap: nowrap;

    ${({ hasBackground }) =>
        hasBackground &&
        css`
            background-color: ${baseColors.darkGrey};
            padding: 8px;
            border-radius: 8px;
        `};
`;
