import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

    ${media.laptop(`
        grid-template-columns: repeat(4, 1fr);
    `)};
`;
