import { yupCode } from 'constants/yup';
import { FormikErrors } from 'formik';
import * as Yup from 'yup';
import { WalletPinFieldName } from './constants';

// Initial data

export interface WalletPinFormValues {
    code: string;
    confirmCode: string;
}

export const walletPinValues: WalletPinFormValues = {
    code: '',
    confirmCode: ''
};

// Validation

export const schema = Yup.object().shape({
    code: yupCode,
    confirmCode: yupCode
});

const validateCode = async (code: string) => {
    try {
        await Yup.reach(schema, WalletPinFieldName.Code).validate(code);

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validateConfirmCode = async (code: string, confirmCode: string) => {
    try {
        await Yup.reach(schema, WalletPinFieldName.Confirm).validate(confirmCode);

        if (code !== confirmCode) {
            return 'Pins don’t match';
        }

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

export const validateWalletPinForm = async ({ code, confirmCode }: WalletPinFormValues) => {
    const errors: FormikErrors<Partial<WalletPinFormValues>> = {};

    errors.code = await validateCode(code);
    errors.confirmCode = await validateConfirmCode(code, confirmCode);

    return errors;
};
