import { VideoCard } from 'components/cards/VideoCard';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { useKeyboard } from 'hooks/useKeyboard';
import { videoClassNamePrefix } from 'constants/defaults/data';
import { KeyboardCode } from 'constants/special';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { homeEffects, homeStores } from 'stores/home';
import { loginModal } from 'stores/initialize.modal';
import { authStores } from 'stores/auth';
import { videoEffects, videoStores } from 'stores/video';
import { playerEvents } from 'stores/player';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems } from 'utils/video';

const { toggleMuteVolume } = playerEvents;
const { registerEventVideo } = videoEffects;
const { getHomePlaylistFx } = homeEffects;
const { $homePlaylist, $isBlockedLoading, $focusedVideoId, $isFocusedVideoPlayer } = homeStores;

export const HomeVideoCards = () => {
    const history = useHistory();

    const { items } = useStore($homePlaylist);
    const isLoading = useStore(getHomePlaylistFx.pending);
    const isBlockedLoading = useStore($isBlockedLoading);
    const focusedVideoId = useStore($focusedVideoId);
    const isAuth = useStore(authStores.$isAuth);
    const isLoadingRegisterEventVideo = useStore(videoStores.$isLoadingRegisterEventVideo);
    const isFocusedVideoPlayer = useStore($isFocusedVideoPlayer);

    const handleFullscreen = (id: string) => {
        fullscreenVideoEvents.setPlaylist(getPlaylistItems(items));
        history.replace(getFullscreenVideoUrl(id, 'recommendations'));
    };

    const handleLikeVideo = (videoList: BULLZ.PlaylistVideoResponse[]) => {
        if (isAuth) {
            if (!isLoadingRegisterEventVideo) {
                const currentVideoIndex = videoList.findIndex(video => video.id === focusedVideoId);
                registerEventVideo({
                    videoId: focusedVideoId,
                    liked: !videoList[currentVideoIndex].engagementUser?.isLiked
                });
            }
        } else {
            loginModal.openModal();
        }
    };

    const scrollToVideo = (videoList: BULLZ.PlaylistVideoResponse[], direction: 'next' | 'previous') => {
        const currentVideoIndex = videoList.findIndex(video => video.id === focusedVideoId);
        const nextVideoIndex = direction === 'previous' ? currentVideoIndex - 1 : currentVideoIndex + 1;
        const nextVideo = videoList[nextVideoIndex];

        if (nextVideo) {
            const nextVideoElement = document.querySelector(`.${videoClassNamePrefix}${nextVideo.id}`);

            if (nextVideoElement) {
                nextVideoElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    };

    useKeyboard(
        isFocusedVideoPlayer && items
            ? {
                  [KeyboardCode.M]: () => toggleMuteVolume(),
                  [KeyboardCode.L]: () => handleLikeVideo(items),
                  [KeyboardCode.ArrowDown]: () => scrollToVideo(items, 'next'),
                  [KeyboardCode.ArrowUp]: () => scrollToVideo(items, 'previous')
              }
            : {}
    );

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: !isBlockedLoading,
        onLoadMore: () => getHomePlaylistFx(),
        disabled: isBlockedLoading,
        rootMargin: '0px'
    });

    useEffect(() => {
        if (focusedVideoId) {
            const element = document.querySelector(`.${videoClassNamePrefix}${focusedVideoId}`);
            element &&
                element.scrollIntoView({
                    block: 'center'
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column noWrap width="100%">
            {items &&
                items.length > 0 &&
                items.map((video, index) => (
                    <VideoCard key={`${video.id}-${index}`} video={video} onFullscreen={handleFullscreen} />
                ))}

            <HiddenScrollBlock ref={loadRef} />

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
