import { allTransition, flexCenter } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const IconWrapper = styled.i`
    width: 24px;
    height: 24px;
    ${flexCenter};
    flex-shrink: 0;
    ${allTransition};
`;

export const SubscriptionsItem = styled(Link)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: ${baseColors.black};
    transition: background-color ${transitionTime} linear;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 8px;

    &:hover {
        background-color: ${grey[800]};

        & > ${IconWrapper} {
            margin-right: 8px;
        }
    }
`;
