import { ProgressButtonProps } from 'components/ui/buttons/ProgressButton';
import { Text } from 'components/ui/typography/Text';
import { baseColors, blue, grey } from 'constants/styles/colors';
import { allTransition, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StyledButton = styled.button<ProgressButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    width: 100%;
    padding: 10px;
    color: ${baseColors.white};
    border: 1px solid rgba(0, 0, 0, 0);
    text-align: center;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    ${allTransition};

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: ${({ progress }) => `${progress || 0}%`};
        height: 100%;
        background-color: ${baseColors.blue};
        z-index: 1;
        ${allTransition};
    }

    &:hover:not(:disabled) {
        &::before {
            background-color: ${blue['400']};
        }
    }

    &:active:not(:disabled) {
        &::before {
            background-color: ${blue['700']};
        }
    }

    &:disabled {
        cursor: not-allowed;
        color: ${grey['700']};
        border-color: ${grey['700']};

        &::before {
            background-color: ${baseColors.white};
        }
    }
`;

export const StyledButtonText = styled(Text)`
    position: relative;
    z-index: 2;
`;
