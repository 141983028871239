import { BackArrowSmIcon, XMdIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { getFormattedDate } from 'components/pages/wallet/utils';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { walletAccountType } from 'constants/global';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { authStores } from 'stores/auth';
import { walletInfoModal } from 'stores/initialize.modal';
import { walletDataStores } from 'stores/wallet-data';
import { Image, TextWrapper } from './styles';

const { modal, closeModal } = walletInfoModal;
const { $walletInfo } = walletDataStores;
const { $user } = authStores;

export const WalletInfoModal = () => {
    const [visible] = useStore(modal);
    const { items } = useStore($walletInfo);
    const user = useStore($user);

    const [qrCodeImage, setQRCodeImage] = useState('');

    const walletInfo = items?.find(item => item.accountType === walletAccountType);

    const handleClose = () => closeModal();

    const generateQR = async (text: string) => {
        const qrcode = await QRCode.toDataURL(text);
        setQRCodeImage(qrcode);
    };

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Address Copied');
        }
    };

    useEffect(() => {
        !!walletInfo?.address && generateQR(walletInfo.address);
    }, [walletInfo]);

    return (
        <ModalWrapper visible={visible} onClose={handleClose}>
            {walletInfo ? (
                <Column noWrap width="100%">
                    <Section alignCenter justifyBetween marginBottom="24px">
                        <IconButton size="24px" onClick={handleClose}>
                            <BackArrowSmIcon />
                        </IconButton>
                        <IconButton size="24px" onClick={handleClose}>
                            <XMdIcon />
                        </IconButton>
                    </Section>

                    <Column alignCenter noWrap width="100%">
                        <Section justifyCenter marginBottom="24px">
                            <Text alignTextCenter isBold>
                                Wallet Information
                            </Text>
                        </Section>

                        <Section justifyBetween marginBottom="32px">
                            <Column>
                                <Column marginBottom="8px">
                                    <Text size="-1"> Wallet Owner</Text>
                                </Column>
                                <Text color={grey[500]} size="-2">
                                    {user?.username || '-'}
                                </Text>
                            </Column>
                            <Column>
                                <Column marginBottom="8px">
                                    <Text size="-1">Wallet Creation Date</Text>
                                </Column>
                                <Text color={grey[500]} size="-2">
                                    {walletInfo.utcCreated ? getFormattedDate(walletInfo.utcCreated, '/') : '-'}
                                </Text>
                            </Column>
                        </Section>

                        <Column alignCenter noWrap marginBottom="28px" width="100%">
                            <Section justifyCenter marginBottom="8px">
                                <Text alignTextCenter size="-1">
                                    This is your wallet address
                                </Text>
                            </Section>

                            <Section justifyCenter marginBottom="28px">
                                <Text alignTextCenter color={grey[500]} size="-1">
                                    When sending WOM, be sure your address is correct. Otherwise your tokens will be
                                    lost.
                                </Text>
                            </Section>

                            {!!qrCodeImage && (
                                <Column alignCenter width="100%">
                                    <Image src={qrCodeImage} />
                                    <Text alignTextCenter size="-1">
                                        Download your QR Code for your personal account information
                                    </Text>
                                </Column>
                            )}
                        </Column>

                        <Column alignCenter noWrap marginBottom="28px" width="100%">
                            <Section justifyCenter marginBottom="8px">
                                <Text alignTextCenter size="-1">
                                    Wallet Address
                                </Text>
                            </Section>

                            <TextWrapper>
                                <Text alignTextCenter color={grey[500]} size="-1">
                                    {walletInfo.address}
                                </Text>
                            </TextWrapper>
                        </Column>

                        <Section>
                            <CopyToClipboard text={walletInfo.address || ''} onCopy={handleCopy}>
                                <Button wide>Copy</Button>
                            </CopyToClipboard>
                        </Section>
                    </Column>
                </Column>
            ) : (
                <Spinner />
            )}
        </ModalWrapper>
    );
};
