import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { allTransition, baseColors, ellipsisMixin, grey, MontserratFontFamily, transitionTime } from 'constants/styles';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const sidebarWidth = '180px';

export const Wrapper = styled.aside`
    width: ${sidebarWidth};
    flex-shrink: 0;
    flex-grow: 1;
    background-color: ${baseColors.black};
`;

export const PagesWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    position: sticky;
    top: calc(${headerHeight} + 16px);
    max-height: calc(100vh - 88px);
    overflow: hidden auto;
    padding-bottom: 18px;
    padding-right: 4px;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${grey['600']};
        border-radius: 10px;
    }
`;

export const LinkElem = styled(NavLink)`
    width: 100%;
    max-width: 120px;
    padding: 10px;
    display: flex;
    font-family: ${MontserratFontFamily};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${grey[500]};
    border-radius: 8px;
    ${allTransition};

    &:hover {
        background-color: ${grey[800]};
        color: ${baseColors.white};
    }

    &.active {
        font-weight: 700;
        color: ${baseColors.white};
    }
`;

export const TextWrapper = styled.div`
    max-width: 80px;
    color: ${baseColors.white};
    ${ellipsisMixin}
`;

export const CreatorWrapper = styled(Link)`
    display: flex;
    align-items: center;
    flex-wrap: noWrap;
    margin-bottom: 8px;
    padding: 4px 10px;
    border-radius: 8px;
    transition: background-color ${transitionTime} linear;
    width: 100%;
    max-width: 165px;
    cursor: pointer;
    background-color: ${baseColors.black};

    &:hover {
        background-color: ${grey[800]};
    }
`;
