import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

// Events

const setVolume = createEvent<number>();
const toggleMuteVolume = createEvent();
const setVolumeVideoInput = createEvent<number>();
const toggleMuteVolumeVideoInput = createEvent();
const resetStores = createEvent();

// Effects

const registerViewFx = createEffect({
    handler: async (
        data: Pick<BULLZ.RegisterAggregatedEventRequest, 'videoId' | 'sessionId' | 'viewDurationPercentage'>
    ) => API.video.registerVideoAsViewed({ ...data, viewed: true })
});

// Stores

const $volume = createStore<number>(0)
    .on(setVolume, (_, value) => value)
    .on(toggleMuteVolume, state => (state === 0 ? 0.5 : 0));

const $isMuted = $volume.map(state => state === 0);

const $volumeVideoInput = createStore<number>(0)
    .on(setVolumeVideoInput, (_, value) => value)
    .on(toggleMuteVolumeVideoInput, state => (state === 0 ? 0.5 : 0));

const $isMutedVideoInput = $volumeVideoInput.map(state => state === 0);

// Exports

export const playerEvents = {
    resetStores,
    setVolume,
    toggleMuteVolume,
    setVolumeVideoInput,
    toggleMuteVolumeVideoInput
};

export const playerEffects = {
    registerViewFx
};

export const playerStores = {
    $volume,
    $isMuted,
    $volumeVideoInput,
    $isMutedVideoInput
};
