import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const SelectGrid = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 8px 16px;
    grid-template-columns: 1fr;
    margin-bottom: 24px;

    ${media.laptopSmall(`
        grid-template-columns: 1fr 1fr;
    `)};
`;
