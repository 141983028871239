import { FormWrapper } from 'components/form/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { pinCodeLength } from 'components/modals/CreateWalletPinModal/constants';
import { Button } from 'components/ui/buttons/Button';
import { CodeInput } from 'components/ui/inputs/CodeInput';
import { Text } from 'components/ui/typography/Text';
import { useField } from 'formik';
import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { ForgotPinFieldName, Step, subtitles, titles } from '../constants';

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
}

export const CreateStep: FC<Props> = ({ setCurrentStep }) => {
    const [, { value, error, touched }, { setValue, setTouched }] = useField<string>(ForgotPinFieldName.Pin);

    const handleChange = (value: string) => {
        !touched && value.length === pinCodeLength && setTouched(true);
        setValue(value);
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!!error || !value) {
            return;
        }

        setCurrentStep(Step.ConfirmPin);
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="28px">
                <CodeInput
                    count={pinCodeLength}
                    error={touched && error ? error : ''}
                    value={value}
                    onChange={handleChange}
                />
            </Section>

            <Column noWrap marginBottom="24px" width="100%">
                <Section justifyCenter marginBottom="12px">
                    <Text alignTextCenter isBold>
                        {titles[Step.Pin]}
                    </Text>
                </Section>
                <Section justifyCenter>
                    <Text alignTextCenter size="-2">
                        {subtitles[Step.Pin]}
                    </Text>
                </Section>
            </Column>

            <Section>
                <Button wide disabled={!touched || !!error} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
