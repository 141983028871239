import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { Collaborations } from 'components/pages/trending/Collaborations';
import { CreatorSlider } from 'components/pages/trending/CreatorSlider';
import { HashtagSlider } from 'components/pages/trending/HashtagSlider';
import { TrendingQS } from 'components/pages/trending/TrendingQS';
import { TrendingVideoCards } from 'components/pages/trending/TrendingVideoCards';
import { useStore } from 'effector-react';
import { CreatorSliderWrapper, SideWrapper, VideosWrapper } from 'pages/Trending/styles';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { authStores } from 'stores/auth';

export interface TrendingProps {
    isSideBar?: boolean;
}

export const Trending: FC<TrendingProps> = () => {
    const isAuth = useStore(authStores.$isAuth);
    const user = useStore(authStores.$user);

    // TODO add logic for autoApproveMedia and autoApproveParticipants for collaborations card
    const isTrusted = user?.isTrusted;

    const isSideBar = isAuth && !isMobile;

    return (
        <NewMainLayout hasMobilePaddingTop isHideCreators>
            <Section noWrap>
                <VideosWrapper isSideBar={isSideBar}>
                    {isMobile && (
                        <>
                            <Section marginBottom="20px">
                                <TrendingQS />
                            </Section>
                            {isTrusted && (
                                <Section marginBottom="20px">
                                    <Collaborations />
                                </Section>
                            )}
                            <Section marginBottom="20px">
                                <HashtagSlider />
                            </Section>
                        </>
                    )}
                    <CreatorSliderWrapper>
                        <CreatorSlider />
                    </CreatorSliderWrapper>
                    <TrendingVideoCards isSideBar={isSideBar} />
                </VideosWrapper>

                {isSideBar && (
                    <SideWrapper>
                        <TrendingQS />

                        {isTrusted && (
                            <Section marginTop="20px">
                                <Collaborations />
                            </Section>
                        )}
                    </SideWrapper>
                )}
            </Section>
        </NewMainLayout>
    );
};
