import { disableDefaultButtonStyleMixin, flexCenter, grey } from 'constants/styles';
import { transitionTime } from 'constants/styles/global';
import styled from 'styled-components';

export const SelectGrid = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 100%;
    margin-bottom: 28px;
    width: 100%;
`;

export const IconWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    ${flexCenter};
    transition: color ${transitionTime};

    &:hover {
        color: ${grey[500]};
    }
`;

export const FormWrapper = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
