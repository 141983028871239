export enum RenderCase {
    None,
    WalletPinCreated,
    WalletPinNotCreated,
    WalletNotCreated
}

export const titles: Partial<Record<RenderCase, string>> = {
    [RenderCase.WalletPinCreated]: 'Verify your identity',
    [RenderCase.WalletPinNotCreated]: 'Please add a wallet pin',
    [RenderCase.WalletNotCreated]: 'Please create a wallet'
};

export const descriptions: Partial<Record<RenderCase, string>> = {
    [RenderCase.WalletPinCreated]:
        'In order to change your wallet pin, please verify your identity by entering a code sent to your registered mobile number  ****',
    [RenderCase.WalletPinNotCreated]:
        'You have not created a wallet pin yet. Please create your wallet pin for increased security.',
    [RenderCase.WalletNotCreated]:
        'You have not created a wallet yet. Please create your wallet to get started and earn rewards!'
};

export const buttonTexts: Partial<Record<RenderCase, string>> = {
    [RenderCase.WalletPinCreated]: 'Send Code',
    [RenderCase.WalletPinNotCreated]: 'Create Wallet Pin',
    [RenderCase.WalletNotCreated]: 'Create Wallet'
};
