import { Section } from 'components/grid/wrappers/FlexWrapper';
import { BodyType } from 'constants/services/comments';
import React, { FC } from 'react';
import { StyledText } from './styles';

export const CommentBody: FC<BULLZ.GetPostResponse> = ({ body, bodyType }) => (
    <Section marginTop="6px">
        {bodyType === BodyType.Html ? (
            <StyledText
                dangerouslySetInnerHTML={{
                    __html: body || ''
                }}
                size="-2"
            />
        ) : (
            <StyledText size="-2">{body}</StyledText>
        )}
    </Section>
);
