import { baseColors } from 'constants/styles';
import styled from 'styled-components';

export const Container = styled.div<{
    large?: boolean;
}>`
    position: relative;
    display: inline-flex;
    width: ${({ large }) => (large ? '70px' : '48px')};
    height: ${({ large }) => (large ? '70px' : '48px')};
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

export const Join = styled.span`
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    background: ${baseColors.blue};
    border-radius: 18px;
    padding: 2px 8px;
`;
