import { createEvent, createStore } from 'effector';

//Events

const setIsVisibleBurgerMenu = createEvent<boolean>();
const toggleBurgerMenu = createEvent();

//Stores

const $isVisibleBurgerMenu = createStore<boolean>(false)
    .on(setIsVisibleBurgerMenu, (_, payload) => payload)
    .on(toggleBurgerMenu, prevState => !prevState);

//Exports

export const headerStores = { $isVisibleBurgerMenu };

export const headerEvents = { toggleBurgerMenu, setIsVisibleBurgerMenu };
