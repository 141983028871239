import { useStore } from 'effector-react';
import jwtDecode from 'jwt-decode';
import { memo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { authEffects, authStores } from 'stores/auth';
import { DecodedToken } from 'types';

export const IdleTimer = memo(() => {
    const user = useStore(authStores.$anonymousUser);
    const token = user.token as string;
    const expiresSec = jwtDecode<DecodedToken>(token).exp;
    const currentMs = new Date().getTime();
    const timeoutExpiresMs = expiresSec * 1000 - currentMs;

    useIdleTimer({
        timeout: timeoutExpiresMs,
        onIdle: () => authEffects.createAnonymousAccountFx()
    });

    return null;
});
