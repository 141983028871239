import { baseColors, grey } from 'constants/styles';
import React, { FC } from 'react';
import { Wrapper } from './styles';

interface Props {
    tags: string;
}

export const HashtagField: FC<Props> = ({ tags }) => (
    <Wrapper color={tags.trim().length > 0 ? baseColors.white : grey[700]} size="-1">
        {tags.trim().length > 0 ? tags : '#Your tags will appear here'}
    </Wrapper>
);
