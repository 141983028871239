import { transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import styled from 'styled-components';

interface ButtonWrapperProps {
    hasBorder?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
    padding-left: 16px;
    padding-right: 16px;
    ${({ hasBorder }) => hasBorder && `border-right: 1px solid ${grey[600]}`};
`;

export const IconWrapper = styled.div`
    color: ${baseColors.white};
    transition: color ${transitionTime} linear;

    &:hover {
        color: rgba(255, 255, 255, 0.6);
    }
`;
