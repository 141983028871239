import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { CodeInput } from 'components/ui/inputs/CodeInput';
import { Text } from 'components/ui/typography/Text';
import React, { FC, useEffect, useState } from 'react';
import { walletEffects } from 'stores/wallet';

const codeTime = 60;
const codeLength = 6;

interface Props {
    onSuccess: (code: string) => void;
}

export const SmsCodeStep: FC<Props> = ({ onSuccess }) => {
    const [code, setCode] = useState('');
    const [codeSeconds, setCodeSeconds] = useState(codeTime);

    const handleChange = (value: string) => {
        setCode(value);
    };

    const sendCode = () => {
        walletEffects.sendForgotPinSmsCodeFx().then(() => {
            setCodeSeconds(codeTime);
        });
    };

    const handleSubmit = () => {
        if (code.length !== codeLength) return;

        onSuccess(code);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (codeSeconds > 0) {
                setCodeSeconds(codeSeconds - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [codeSeconds]);

    return (
        <Column noWrap height="100%" width="100%">
            <Column justifyBetween height="100%">
                <Column>
                    <Section marginBottom="8px">
                        <Text isBold size="1">
                            Enter Code
                        </Text>
                    </Section>

                    <Section marginBottom="16px">
                        <Text size="-2">Please enter the code sent to your phone number</Text>
                    </Section>

                    <Section marginBottom="16px">
                        <CodeInput value={code} onChange={handleChange} />
                    </Section>
                </Column>

                <Section alignCenter justifyBetween>
                    <TextButton buttonType="primary" disabled={codeSeconds > 0} onClick={sendCode}>
                        {codeSeconds > 0 ? `Resend Code : ${codeSeconds}` : 'Resend Code'}
                    </TextButton>
                    <Button disabled={code.length !== codeLength} onClick={handleSubmit}>
                        Next
                    </Button>
                </Section>
            </Column>
        </Column>
    );
};
