import { getTitleStyles, Size } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import { MontserratFontFamily } from 'constants/styles/fonts';
import styled from 'styled-components';
import { TextAlignment } from 'types/interfaces/styles';

interface Props extends TextAlignment {
    color?: string;
    size?: Size;
}

export const HtmlText = styled.div<Props>`
    font-family: ${MontserratFontFamily};
    font-weight: 500;
    ${({ size = '-1' }) => getTitleStyles(size)};
    color: ${({ color }) => color || baseColors.white};
    ${({ alignTextCenter }) => alignTextCenter && 'text-align: center'};
    margin: 0;
    padding: 0;

    p {
        margin: 0;
        padding: 0;
    }

    a {
        margin: 0;
        padding: 0;
        text-decoration: underline;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
    }

    li {
        list-style-position: inside;
        margin-bottom: 8px;
    }
`;
