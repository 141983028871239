import { baseColors, grey } from 'constants/styles/colors';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
    &.guide-mobile-swiper {
        height: 100%;
        display: flex;
        flex-direction: column;

        .swiper-slide {
            display: flex;
            align-items: center;
        }

        .swiper-pagination {
            position: static;
            margin-top: 24px;
        }

        .swiper-pagination-bullet {
            width: 9px;
            height: 9px;
            background-color: ${grey[700]};

            &-active {
                background-color: ${baseColors.white};
            }
        }
    }
`;

export const ModalContent = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: ${baseColors.black};
    padding: 45px 20px 40px;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
`;
