import { WrongLgIcon } from 'assets/icons';
import { Timer } from 'components/common/Timer';
import { FlexGrow, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { CollaborationStatusesUi, ParticipationState, SubmissionState } from 'constants/defaults/collaborations';
import { useStore } from 'effector-react';
import { EndedParticipationFlow } from 'pages/CollaborationsPage/Details/Footer/EndedParticipationFlow';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { bucmUserStores } from 'stores/bucm-user';
import { collaborationStores } from 'stores/collaboration-details';
import { infoModal, shareLinkModal, submissionTimerModal, submitAlertModal } from 'stores/initialize.modal';
import { participateCollaborationEffects } from 'stores/participate-collaboration';

const { $myCollaborationData, $collaborationData } = collaborationStores;
const { $bucmUser } = bucmUserStores;

interface Props {
    collaborationId: string;
}

export const UserJoinedFlow: FC<Props> = ({ collaborationId }) => {
    const myCollaborationData = useStore($myCollaborationData);
    const collaborationData = useStore($collaborationData);
    const bucmUser = useStore($bucmUser);

    const futureTime = new Date(myCollaborationData?.utcSubmissionDeadline || '').getTime();
    const nowTime = new Date(Date.now()).getTime();
    const timeEnded = futureTime - nowTime < 0;

    useEffect(() => {
        if (myCollaborationData?.participationState === ParticipationState.Enrolled) {
            submissionTimerModal.openModal();
        }
    }, [myCollaborationData]);

    const leaveCollaborationQuery = () => {
        if (bucmUser.userId && collaborationId) {
            participateCollaborationEffects.leaveCollaborationFx({
                collaborationId,
                userId: bucmUser.userId
            });
        }
    };

    const leaveCollaboration = () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: 'Are you sure?',
            buttonText: 'GO BACK',
            cancelText: 'Leave Collaboration',
            onCloseClick: leaveCollaborationQuery,
            text:
                'If you leave this Collaboration you will no longer be able to participate and earn WOM. You will still be able to view and join other Collaborations.'
        });
    };

    const cancelRequest = () => {
        infoModal.openModal({
            title: 'Cancel Request',
            buttonText: 'Go Back',
            cancelText: 'Yes, cancel',
            onCloseClick: leaveCollaborationQuery,
            text: 'You are about the cancel your request to join this collaboration. Are you sure?'
        });
    };

    const submitVideo = () => {
        if (collaborationData) {
            submitAlertModal.openModal({
                collaboration: collaborationData
            });
        }
    };

    const share = () => {
        if (myCollaborationData?.networks && myCollaborationData.networks.length > 0) {
            shareLinkModal.openModal({
                collaborationNetworks: myCollaborationData.networks
            });
        }
    };

    const TimerComponent = () =>
        myCollaborationData?.utcSubmissionDeadline && !isMobile ? (
            <Row width="270px">
                <Timer date={myCollaborationData?.utcSubmissionDeadline} />
            </Row>
        ) : (
            <div />
        );

    if (myCollaborationData?.participationState === ParticipationState.Ended) {
        return <EndedParticipationFlow myCollaborationData={myCollaborationData} timerComponent={TimerComponent} />;
    } else if (myCollaborationData?.participationState === ParticipationState.Submitted) {
        if (
            myCollaborationData.mediaAssets &&
            myCollaborationData.mediaAssets[0].isRejected === null &&
            !collaborationData?.autoApproveMedia &&
            collaborationData?.autoApproveParticipants
        ) {
            return (
                <Section alignCenter justifyCenter>
                    <Button disabled>Please wait for video approval</Button>
                </Section>
            );
        }
        return (
            <Section alignCenter justifyBetween={!isMobile} justifyCenter={isMobile}>
                <TimerComponent />
                <Button
                    disabled={
                        (myCollaborationData?.collaborationState !== CollaborationStatusesUi.Active &&
                            myCollaborationData?.collaborationState !== CollaborationStatusesUi.ActiveClosed) ||
                        (!!myCollaborationData.networks && myCollaborationData.networks?.length < 2) ||
                        myCollaborationData.submissionState !== SubmissionState.Ended
                    }
                    onClick={share}
                >
                    Share to earn WOM
                </Button>
            </Section>
        );
    } else if (myCollaborationData?.participationState === ParticipationState.EnrollmentRequested) {
        return (
            <FlexGrow alignCenter justifyCenter flexDirection={isMobile ? 'column-reverse' : 'row'}>
                <MarginWrapper marginRight={isMobile ? '0' : '18px'} marginTop={isMobile ? '24px' : '0'}>
                    <TextButton isBold isUppercase onClick={cancelRequest}>
                        Cancel request
                    </TextButton>
                </MarginWrapper>
                <Button disabled>Please wait for approval</Button>
            </FlexGrow>
        );
    }
    return (
        <Section alignCenter justifyBetween={!isMobile} justifyCenter={isMobile}>
            <TimerComponent />
            <FlexGrow
                alignCenter
                flexDirection={isMobile ? 'column-reverse' : 'row'}
                justifyCenter={isMobile}
                justifyEnd={!isMobile}
            >
                <MarginWrapper marginRight={isMobile ? '0' : '18px'} marginTop={isMobile ? '24px' : '0'}>
                    <TextButton isBold isUppercase onClick={leaveCollaboration}>
                        Leave Collaboration
                    </TextButton>
                </MarginWrapper>
                <Button disabled={timeEnded} onClick={submitVideo}>
                    Submit
                </Button>
            </FlexGrow>
        </Section>
    );
};
