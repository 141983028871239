import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    ${flexCenter};
    position: relative;
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18px;
    height: 18px;
    ${flexCenter};
    z-index: 1;
`;
