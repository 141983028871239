import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

const sideWidth = 230;
const sideMargin = 16;

export const SavedVideosWrapper = styled(Column)`
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 12px;

    ${media.laptop(`
        width: calc(100% - ${sideWidth + sideMargin}px);
    `)};
`;
