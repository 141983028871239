import { flexCenter } from 'constants/styles/mixins';
import styled, { keyframes } from 'styled-components';

interface SpinnerWrapperProps {
    size: number;
}

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const SpinnerWrapper = styled.div<SpinnerWrapperProps>`
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    ${flexCenter};
    flex-shrink: 0;
    animation: ${rotate} 2s linear infinite;
`;
