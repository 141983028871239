import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import iconSrc from 'assets/img/gifts/how-to-use.png';
import { CustomImg } from 'components/common/CustomImg';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { HtmlText } from 'components/ui/typography/HtmlText';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React from 'react';
import { giftsEvents, giftsStores } from 'stores/gifts';

const { $howToUseModal } = giftsStores;
const { closeHowToUseModal } = giftsEvents;

export const GiftHowToUseModal = () => {
    const data = useStore($howToUseModal);

    const close = () => {
        closeHowToUseModal();
    };

    return (
        <ModalWrapper visible={!!data} zIndex={110} onClose={close}>
            <Section alignCenter justifyBetween marginBottom="28px">
                <IconButton onClick={close}>
                    <BackArrowSmIcon />
                </IconButton>
                <IconButton onClick={close}>
                    <CloseSmIcon />
                </IconButton>
            </Section>

            <Section justifyCenter marginBottom="28px">
                <Text alignTextCenter isBold>
                    How to Use
                </Text>
            </Section>

            <Section justifyCenter marginBottom="32px">
                <CustomImg src={iconSrc} width="120px" />
            </Section>

            <HtmlText dangerouslySetInnerHTML={{ __html: data || '' }} />
        </ModalWrapper>
    );
};
