import axios, { CancelTokenSource } from 'axios';
import { combine, createEffect, createEvent, createStore, forward } from 'effector';
import { nanoid } from 'nanoid';
import { API } from 'services';
import { Hashtags } from 'types';
import { createNotifyingEffect, mergeGenericHashtagResponse } from 'utils/common';

let cancelToken: CancelTokenSource | undefined = axios.CancelToken.source();

const setInitialLoading = createEvent<boolean>();
const initialLoading = createStore(false).on(setInitialLoading, (_, state) => state);
//For set hashtag from search input
const setHashtags = createEvent<string>();
//For searched hashtag (click on hashtag from trending and video player)
const setSearchedHashtag = createEvent<string>();
const deleteHashtags = createEvent<string>();
const setDefaultHashtag = createEvent();
const setSearchValue = createEvent<string>();

export const hashtags = createStore<Hashtags[]>([])
    .on(setHashtags, (hashtag, value) =>
        hashtag.slice(0).concat([
            {
                id: nanoid(),
                value
            }
        ])
    )
    .on(setSearchedHashtag, (hashtag, value) =>
        hashtag.slice(0).concat([
            {
                id: nanoid(),
                value
            }
        ])
    )
    .on(setDefaultHashtag, _ => [])
    .on(deleteHashtags, (hashtag, hashtagId) => hashtag.filter(({ id }) => id !== hashtagId));

const getVideosByHashtags = createNotifyingEffect({
    handler: async () => {
        try {
            setInitialLoading(true);
            let stateHashtags: BULLZ.PlaylistVideoResponse[] = [];
            const hashtagsStore = hashtags.getState();
            for (const { value } of hashtagsStore) {
                const { items } = await API.playlist.queryByFilter({
                    pageIndex: 0,
                    limit: 100,
                    //TODO: pagination
                    byHashtag: value
                });
                stateHashtags = items
                    ? mergeGenericHashtagResponse<BULLZ.PlaylistVideoResponse>(stateHashtags, items)
                    : stateHashtags;
            }
            setInitialLoading(false);
            return stateHashtags;
        } catch {
            setInitialLoading(false);

            return [];
        }
    }
});

const setDefaultVideo = createEvent();

const searchVideos = createStore<BULLZ.PlaylistVideoResponse[]>([])
    .on(getVideosByHashtags.doneData, (_, items) => items)
    .on(setDefaultVideo, _ => []);

forward({
    from: [deleteHashtags, setSearchedHashtag],
    to: getVideosByHashtags
});

forward({
    from: setDefaultHashtag,
    to: getVideosByHashtags
});

const getCreatorByUsername = createEffect({
    handler: async (payload: { searchText: string }) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            const { searchText } = payload;
            setInitialLoading(true);
            const { items } = await API.search.search(
                {
                    searchText: `@${searchText}`,
                    pageIndex: 0,
                    limit: 100,
                    returnQueryCount: false
                },
                cancelToken.token
            );
            setInitialLoading(false);

            // return isTrusted ? items?.filter(({ isTrusted }) => isTrusted) : items;
            return items;
        } catch {
            setInitialLoading(false);
            return [];
        }
    }
});

const getSearchItemsFx = createEffect({
    handler: async (payload: { searchText: string }) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            const { searchText } = payload;
            setInitialLoading(true);
            const { items } = await API.search.search(
                {
                    searchText: `${searchText}`,
                    pageIndex: 0,
                    limit: 100,
                    returnQueryCount: false
                },
                cancelToken.token
            );
            setInitialLoading(false);

            return items;
        } catch {
            setInitialLoading(false);
            return [];
        }
    }
});

const setDefaultCreators = createEvent();
const setDefaultSearchItems = createEvent();

const creators = createStore<BULLZ.SearchResponse[]>([])
    .on(getCreatorByUsername.doneData, (_, data) => data || [])
    .on(setDefaultCreators, _ => []);

const searchItems = createStore<BULLZ.SearchResponse[]>([])
    .on(getSearchItemsFx.doneData, (_, data) => data || [])
    .reset(setDefaultSearchItems);

setDefaultCreators.watch(_ => cancelToken?.cancel());

const searchStore = combine(initialLoading, searchVideos, creators);

const $searchValue = createStore<string>('').on(setSearchValue, (_, newState) => newState);

export const searchEffects = { getCreatorByUsername };
export const searchStores = { searchStore, hashtags, creators, searchItems, loading: initialLoading, $searchValue };
export const searchEvents = {
    setDefaultVideo,
    setDefaultCreators,
    setDefaultSearchItems,
    getVideosByHashtags,
    setHashtags,
    getSearchItemsFx,
    deleteHashtags,
    setDefaultHashtag,
    setSearchedHashtag,
    setSearchValue
};
