import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';

interface Props {
    data?: BULLZ.VideoDetailsEngagementDeltas;
}

export const Stats: FC<Props> = ({ data }) => (
    <Column noWrap width="100%">
        <Section marginBottom="8px">
            <Text isBold size="-1">
                Stats
            </Text>
        </Section>

        <Section justifyBetween>
            <Column alignCenter>
                <Text alignTextCenter isBold size="1">
                    {data?.views || 0}
                </Text>
                <Row justifyCenter marginTop="4px">
                    <Text alignTextCenter size="-2">
                        Views
                    </Text>
                </Row>
            </Column>

            <Column alignCenter>
                <Text alignTextCenter isBold size="1">
                    {data?.likes || 0}
                </Text>
                <Row justifyCenter marginTop="4px">
                    <Text alignTextCenter size="-2">
                        Likes
                    </Text>
                </Row>
            </Column>

            <Column alignCenter>
                <Text alignTextCenter isBold size="1">
                    {data?.shares || 0}
                </Text>
                <Row justifyCenter marginTop="4px">
                    <Text alignTextCenter size="-2">
                        Shares
                    </Text>
                </Row>
            </Column>

            <Column alignCenter>
                <Text alignTextCenter isBold size="1">
                    {data?.saves || 0}
                </Text>
                <Row justifyCenter marginTop="4px">
                    <Text alignTextCenter size="-2">
                        Saves
                    </Text>
                </Row>
            </Column>
        </Section>
    </Column>
);
