import { useNonScrolledBackground } from 'hooks/useNonScrolledBackground';
import React, { FC, useEffect, useRef } from 'react';
import { NoopType } from 'types';
import { ModalBackdrop, ModalContent } from './styles';
import { homeEvents } from 'stores/home';

export interface ModalWrapperProps {
    visible: boolean;
    onClose?: NoopType;
    zIndex?: number;
    dark?: boolean;
    fullscreenMobile?: boolean;
    size?: 'default' | 'large';
}

export const ModalWrapper: FC<ModalWrapperProps> = ({
    children,
    visible,
    onClose,
    dark,
    zIndex,
    fullscreenMobile = false,
    size = 'default'
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = () => {
        onClose && onClose();
    };

    useNonScrolledBackground(visible);

    useEffect(() => {
        homeEvents.setIsFocusedVideoPlayer(!visible);
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <>
            <ModalBackdrop
                dark={dark}
                fullscreenMobile={fullscreenMobile}
                zIndex={zIndex}
                onClick={handleClickOutside}
            />
            <ModalContent ref={modalRef} fullscreenMobile={fullscreenMobile} size={size} zIndex={zIndex}>
                {children}
            </ModalContent>
        </>
    );
};
