import { Row } from 'components/grid/wrappers/FlexWrapper';
import { flexCenter } from 'constants/styles';
import { baseColors } from 'constants/styles/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const opacityBlack = 'rgba(0, 0, 0, 0.4)';

interface ProfileWrapperProps {
    isVisibleFollow?: boolean;
}

export const ProfileWrapper = styled(Row)<ProfileWrapperProps>`
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 24px;
    position: relative;
    border-radius: 18px;
    background-color: ${opacityBlack};
    margin-right: ${({ isVisibleFollow }) => (isVisibleFollow ? '28px' : '16px')};
`;

export const UsernameLink = styled(Link)`
    display: flex;
    align-items: center;
`;

export const AvatarWrapper = styled.div`
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    ${flexCenter};
    border-radius: 50%;
    border: 2px solid ${baseColors.white};
    overflow: hidden;
    z-index: 1;
`;

export const FollowWrapper = styled.div`
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translate(50%, -50%);
    width: 24px;
    height: 24px;
    ${flexCenter};
    z-index: 2;
`;
