import { baseColors, disableDefaultButtonStyleMixin, flexCenter, grey, transitionTime } from 'constants/styles';
import styled from 'styled-components';

export const IconWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    ${flexCenter};
    color: ${grey[500]};
    transition: color ${transitionTime} linear;

    &:hover {
        color: ${baseColors.white};
    }
`;
