import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Button } from 'components/ui/buttons/Button';
import { GiftCard } from 'components/ui/GiftCard';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { defaultGiftsParams } from 'constants/services/gifts';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { giftsEffects, giftsEvents, giftsStores } from 'stores/gifts';
import { CardsGrid } from '../CardsGrid';
import { GiftStatus, GiftTab } from '../types';
import { Empty } from './styles';

const { getPurchasesFx } = giftsEffects;
const { $purchases, $hasPurchasesError } = giftsStores;
const { openDetailsModal } = giftsEvents;

interface Props {
    onChangeTab: (tab: GiftTab) => void;
}

export const MyCards: FC<Props> = ({ onChangeTab }) => {
    const { currentPageIndex, totalPages, items } = useStore($purchases);
    const isLoading = useStore(getPurchasesFx.pending);
    const hasError = useStore($hasPurchasesError);

    const handleCardClick = (item: BULLZ.VoucherTransactionResponse) => () => {
        if (item.transactionStatus !== GiftStatus.InProcess) {
            openDetailsModal(item);
        }
    };

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage:
            currentPageIndex !== undefined && totalPages !== undefined ? currentPageIndex + 1 < totalPages : true,
        onLoadMore: () => {
            getPurchasesFx({
                ...defaultGiftsParams,
                pageIndex: currentPageIndex !== undefined ? currentPageIndex + 1 : defaultGiftsParams.pageIndex
            });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    return (
        <Column marginTop="24px" width="100%">
            {items && items.length > 0 && (
                <CardsGrid>
                    {items.map((item, index) => (
                        <GiftCard
                            key={`${item.productId}-${index}`}
                            src={item.productInfo?.productImage || ''}
                            status={item.transactionStatus}
                            title={item.productInfo?.brandName || ''}
                            onClick={handleCardClick(item)}
                        />
                    ))}
                </CardsGrid>
            )}

            {!isLoading && items && items.length === 0 && (
                <Empty>
                    <Section justifyCenter marginBottom="8px">
                        <Text alignTextCenter isBold>
                            Your Gift Cards
                        </Text>
                    </Section>
                    <Section justifyCenter marginBottom="24px">
                        <Text alignTextCenter size="-2">
                            When you exchange your WOM Tokens for gift cards, you will see your gift cards listed here.
                        </Text>
                    </Section>
                    <Section justifyCenter>
                        <Button isBold={false} onClick={() => onChangeTab(GiftTab.All)}>
                            Browse Gift Cards
                        </Button>
                    </Section>
                </Empty>
            )}

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}

            <HiddenScrollBlock ref={loadRef} />
        </Column>
    );
};
