import { ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { asyncErrorMessage } from 'constants/messages';
import { createEffect } from 'effector';
import { API } from 'services';
import { infoModal } from 'stores/initialize.modal';
import { NoopType } from 'types';
import { goToHelpDesk, noop } from 'utils/common';

// Effects

const validatePasswordFx = createEffect({
    handler: async (currentPassword: string) => {
        try {
            const response = await API.user.validatePassword({ currentPassword });
            return !!response.isSuccess;
        } catch (e) {
            return false;
        }
    }
});

const failFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'There was a problem adding your details. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

interface ChangePasswordParams {
    data: BULLZ.UserChangePasswordRequest;
    onSuccess: NoopType;
}

const changePasswordFx = createEffect({
    handler: async ({ data, onSuccess }: ChangePasswordParams) => {
        try {
            const response = await API.user.setNewPassword({
                currentPassword: data.currentPassword,
                newPassword: data.newPassword
            });

            if (!!response.isSuccess) {
                infoModal.openModal({
                    icon: ConfettiLgIcon,
                    title: 'Congrats!',
                    text: 'You have successfully changed your password',
                    buttonText: 'Okay'
                });

                onSuccess();
            } else {
                failFx();
            }
        } catch (e) {
            failFx();
        }
    }
});

// Exports

export const settingsPasswordEffects = {
    validatePasswordFx,
    changePasswordFx
};
