export enum Step {
    Phone = 'phone',
    Pin = 'pin',
    ConfirmPin = 'confirmPin'
}

export const titles: Record<Step, string> = {
    phone: 'Forgot Wallet PIN',
    pin: 'Please add wallet security',
    confirmPin: 'Confirm Wallet Pin'
};

export const subtitles: Record<Step, string> = {
    phone: 'Please enter the code sent to your phone number',
    pin: 'Please add a wallet pin to secure your wallet.',
    confirmPin: 'Please confirm the wallet pin you entered previously.'
};

export const secondsToResendCode = 60;

export enum ForgotPinFieldName {
    SmsCode = 'smsVerificationCode',
    Pin = 'pin',
    ConfirmPin = 'confirmPin'
}
