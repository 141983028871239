import { BackArrowSmIcon, LinkSmIcon, WomSmIcon } from 'assets/icons';
import { Timer } from 'components/common/Timer';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { CollaborationGuideModal } from 'components/modals/CollaborationGuideModal';
import { ShareLinkModal } from 'components/modals/ShareLinkModal';
import { SubmissionTimerModal } from 'components/modals/SubmissionTimerModal';
import { SubmitAlertModal } from 'components/modals/SubmitAlertModal';
import { UploadCollaborationModal } from 'components/modals/UploadCollaborationModal';
import { Property } from 'components/pages/collaborations/Property';
import { Badge } from 'components/ui/Badge';
import { NetworkIcon } from 'components/ui/NetworkIcon';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { HtmlText } from 'components/ui/typography/HtmlText';
import { Text } from 'components/ui/typography/Text';
import {
    CollaborationStatusesUi,
    ParticipationEndedReason,
    ParticipationState,
    collaborationStatuses
} from 'constants/defaults/collaborations';
import { languages } from 'constants/languages';
import { AppRoute } from 'constants/routes';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import queryString from 'query-string';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { collaborationEffects, collaborationStores } from 'stores/collaboration-details';
import { socialNetworksEffects } from 'stores/social-networks';
import { getCommonReward, getCommonRewardUsd, goToExternalLink } from 'utils/common';
import { getFormattedDate } from 'utils/formateDate';
import { BackgroundWrapper, ContentWrapper, Image, Poster, PriceWrapper, PropertiesGrid, Wrapper } from './styles';

const { getCollaborationFx } = collaborationEffects;
const { $collaborationData, $myCollaborationData } = collaborationStores;

const { $user } = authStores;

const dateFormat = 'yyyy-MM-dd';

interface Props {
    collaborationId: string;
}

export const DetailsInfo: FC<Props> = ({ collaborationId }) => {
    const history = useHistory();

    const user = useStore($user);

    const collaborationData = useStore($collaborationData);
    const myCollaborationData = useStore($myCollaborationData);
    const isLoading = useStore(getCollaborationFx.pending);

    const bullzNetworkInfo = !!collaborationData
        ? collaborationData.networks?.find(item => item.networkTitle?.toLowerCase() === 'bullz') || null
        : null;
    const socialNetworks = !!collaborationData
        ? collaborationData.networks?.filter(item => item.networkTitle?.toLowerCase() !== 'bullz') || []
        : [];

    const commonReward = getCommonReward(socialNetworks, bullzNetworkInfo?.rewardOnSubmitted);
    const commonRewardUsd = getCommonRewardUsd(socialNetworks, bullzNetworkInfo?.rewardOnSubmittedUsd);

    const { by } = queryString.parse(history.location.search);

    const goToBack = () => {
        if (by === 'myCollaboration') {
            history.replace(`${AppRoute.Profile}/${user?.userId}?by=myCollaboration`);
        } else {
            history.goBack();
        }
    };

    useEffect(() => {
        socialNetworksEffects.getExternalNetworksFx();
    }, []);

    useEffect(() => {
        getCollaborationFx(collaborationId);
    }, [collaborationId]);

    if (isLoading) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    if (!collaborationData) {
        // TODO
        return null;
    }

    return (
        <>
            {!isMobile && (
                <Section marginBottom="16px">
                    <IconButton color={grey[500]} onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                </Section>
            )}

            <Wrapper>
                <Column noWrap width="100%">
                    {!isMobile ? (
                        <>
                            <Poster>
                                <Image
                                    alt={collaborationData.name || 'poster'}
                                    src={collaborationData.coverImageUrl || ''}
                                />
                            </Poster>

                            {collaborationData.websiteUrl && (
                                <Section marginTop="20px">
                                    <Button
                                        wide
                                        icon={<LinkSmIcon />}
                                        isBold={false}
                                        size="small"
                                        textSize="small"
                                        onClick={() => goToExternalLink(collaborationData.websiteUrl)}
                                    >
                                        Go to Website
                                    </Button>
                                </Section>
                            )}
                        </>
                    ) : (
                        <BackgroundWrapper src={collaborationData.coverImageUrl || ''}>
                            <Section marginBottom="48px">
                                <IconButton onClick={goToBack}>
                                    <BackArrowSmIcon />
                                </IconButton>
                            </Section>

                            <Section alignCenter justifyBetween noWrap marginBottom="12px">
                                {bullzNetworkInfo && (
                                    <Row alignCenter>
                                        <PriceWrapper>
                                            <Text isBold color={baseColors.black}>
                                                {commonReward}
                                            </Text>

                                            <Column marginLeft="8px">
                                                <WomSmIcon color={baseColors.black} />
                                            </Column>
                                        </PriceWrapper>

                                        <Column marginLeft="16px">
                                            <Text size="-1">{commonRewardUsd.toFixed(2)} USD</Text>
                                        </Column>
                                    </Row>
                                )}

                                {collaborationData.collaborationState && (
                                    <Badge status={collaborationData.collaborationState}>
                                        {collaborationStatuses[collaborationData.collaborationState]}
                                    </Badge>
                                )}
                            </Section>

                            <Section>
                                <Text isBold size="1">
                                    {collaborationData.name}
                                </Text>
                            </Section>
                        </BackgroundWrapper>
                    )}
                </Column>

                <ContentWrapper>
                    {!isMobile ? (
                        <Section alignCenter justifyBetween marginBottom="16px">
                            <Row width="50%">
                                <Text isBold size="1">
                                    {collaborationData.name}
                                </Text>
                            </Row>
                            <Row alignCenter justifyEnd width="50%">
                                {collaborationData.collaborationState && (
                                    <Column marginRight="16px">
                                        <Badge status={collaborationData.collaborationState}>
                                            {collaborationStatuses[collaborationData.collaborationState]}
                                        </Badge>
                                    </Column>
                                )}

                                <Text size="-1">
                                    {collaborationData.enrolledCount}/{collaborationData.enrolmentLimit}
                                </Text>

                                <Column marginLeft="8px">
                                    <Text color={grey[500]} size="-2">
                                        Submissions
                                    </Text>
                                </Column>
                            </Row>
                        </Section>
                    ) : (
                        <Section alignCenter marginBottom="12px">
                            {isMobile &&
                                myCollaborationData?.utcSubmissionDeadline &&
                                myCollaborationData?.collaborationState !== CollaborationStatusesUi.Ended &&
                                (myCollaborationData?.participationEndedReason ===
                                    ParticipationEndedReason.SubmittedAndPublished ||
                                    myCollaborationData?.participationEndedReason ===
                                        ParticipationEndedReason.SubmittedOnly ||
                                    myCollaborationData?.participationState === ParticipationState.Submitted ||
                                    myCollaborationData?.participationState === ParticipationState.Enrolled) && (
                                    <Section marginBottom="12px">
                                        <Timer
                                            color={baseColors.darkGrey}
                                            date={myCollaborationData?.utcSubmissionDeadline}
                                            type="secondary"
                                        />
                                    </Section>
                                )}

                            <Text size="-1">
                                {collaborationData.enrolledCount}/{collaborationData.enrolmentLimit}
                            </Text>

                            <Column marginLeft="8px">
                                <Text color={grey[500]} size="-2">
                                    Creator submissions accepted
                                </Text>
                            </Column>
                        </Section>
                    )}

                    {bullzNetworkInfo && !isMobile && (
                        <Section alignCenter marginBottom="16px">
                            <Text isBold size="1">
                                {commonReward}
                            </Text>
                            <Column marginLeft="12px">
                                <WomSmIcon />
                            </Column>

                            <Column marginLeft="16px">
                                <Text isUppercase color={grey[500]} size="-1">
                                    {commonRewardUsd.toFixed(2)} USD
                                </Text>
                            </Column>
                        </Section>
                    )}

                    <Section marginBottom={isMobile ? '12px' : '24px'}>
                        <Section marginBottom="8px">
                            <Text color={grey[500]} size="-2">
                                DESCRIPTION
                            </Text>
                        </Section>
                        <HtmlText dangerouslySetInnerHTML={{ __html: collaborationData.description || '' }} size="0" />
                    </Section>

                    <Section marginBottom="24px">
                        <PropertiesGrid>
                            <Property
                                hasBackground={isMobile}
                                title="SPOKEN LANGUAGE"
                                tooltipText="This is the language the video must be created in. Videos submitted in other languages will be rejected."
                                value={
                                    collaborationData.spokenLanguage
                                        ? collaborationData.spokenLanguage in languages
                                            ? languages[collaborationData.spokenLanguage].name
                                            : collaborationData.spokenLanguage
                                        : ''
                                }
                            />
                            <Property
                                hasBackground={isMobile}
                                title="START DATE"
                                tooltipText="This is the first day to join the Collaboration."
                                value={
                                    collaborationData.utcStart
                                        ? getFormattedDate(collaborationData.utcStart, dateFormat)
                                        : ''
                                }
                            />
                            <Property
                                hasBackground={isMobile}
                                title="END DATE"
                                tooltipText="This is the last date of the Collaboration. All videos must be submitted and shared by the closing date and time to be rewarded."
                                value={
                                    collaborationData.utcEnd
                                        ? getFormattedDate(collaborationData.utcEnd, dateFormat)
                                        : ''
                                }
                            />

                            {bullzNetworkInfo && (
                                <Property
                                    hasBackground={isMobile}
                                    title="REWARD FOR CONTENT"
                                    tooltipText="Rewards you receive for submitting a video for the Collaboration."
                                    value={bullzNetworkInfo.rewardOnSubmitted}
                                    valueIcon={<WomSmIcon />}
                                />
                            )}

                            {socialNetworks.length > 0 &&
                                socialNetworks.map(item => (
                                    <Property
                                        key={item.networkId}
                                        hasBackground={isMobile}
                                        title="SHARING ON"
                                        titleIcon={
                                            <NetworkIcon color={grey[500]} name={item.networkTitle} size="large" />
                                        }
                                        tooltipText="Rewards you receive for sharing to one of the defined social media platforms. You are rewarded for sharing to one social media platform. Additional shares are not rewarded."
                                        value={item.rewardOnSubmitted}
                                        valueIcon={<WomSmIcon />}
                                    />
                                ))}
                        </PropertiesGrid>
                    </Section>
                </ContentWrapper>
                <SubmitAlertModal />
                <UploadCollaborationModal />
                <CollaborationGuideModal />
                <ShareLinkModal />
                <SubmissionTimerModal />
            </Wrapper>
        </>
    );
};
