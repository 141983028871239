import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { HomeMobileSlider } from 'components/pages/home/HomeMobileSlider';
import { HomeVideoCards } from 'components/pages/home/HomeVideoCards';
import { SliderQS } from 'components/pages/home/SliderQS';
import { Collaborations } from 'components/pages/trending/Collaborations';
import { KeyboardShortcutModal } from 'components/modals/KeyboardShortcutModal';
import { androidAppStoreLink, AppRoute, iosAppStoreLink } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import { homeStores } from 'stores/home';
import { authStores } from 'stores/auth';
import { infoModal, registerModal } from 'stores/initialize.modal';
import { goToExternalLink, noop } from 'utils/common';
import { handleClickAppStoreApp, handleClickGooglePlayApp } from 'utils/googleTagManagerEvents';
import { HomeMainMobileWrapper, HomeMainWrapper, HomeVideosWrapper, SideWrapper } from './styles';

interface ICodeParams {
    code?: string;
}

// TODO fix component name
export const NewHomePage = () => {
    const isAuth = useStore(authStores.$isAuth);
    const { items: videoList } = useStore(homeStores.$homePlaylist);
    const { code } = useParams<ICodeParams>();
    const history = useHistory();
    const isSideBar = isAuth && !isMobile;

    const handleDownloadClick = () => {
        let link = '';

        if (isIOS) {
            handleClickAppStoreApp();
            link = iosAppStoreLink;
        } else {
            handleClickGooglePlayApp();
            link = androidAppStoreLink;
        }

        goToExternalLink(link);
    };

    useEffect(() => {
        if (code) {
            if (!isAuth) {
                registerModal.openModal({
                    referralCode: code
                });

                if (isMobile) {
                    infoModal.openModal({
                        title: 'Do you want to download “BULLZ” app?',
                        buttonText: 'Download',
                        onClick: handleDownloadClick,
                        cancelText: 'Cancel',
                        onCloseClick: noop
                    });
                }
            } else {
                history.replace(AppRoute.Main);
            }
        }
    }, [code, history, isAuth]);

    return (
        <NewMainLayout isHidePaddingX>
            {isMobile ? (
                <HomeMainMobileWrapper>
                    <HomeMobileSlider />
                </HomeMainMobileWrapper>
            ) : (
                <HomeMainWrapper>
                    <HomeVideosWrapper>
                        {isAuth && (
                            <Section>
                                <SliderQS />
                            </Section>
                        )}
                        <Section marginTop="32px">
                            <HomeVideoCards />
                        </Section>
                    </HomeVideosWrapper>

                    {isSideBar && (
                        <SideWrapper>
                            <Collaborations />
                        </SideWrapper>
                    )}

                    {videoList && <KeyboardShortcutModal />}
                </HomeMainWrapper>
            )}
        </NewMainLayout>
    );
};
