import womAxios from './wom-axios';

export const getWalletData = (data: WOM.WalletGetRequest) =>
    womAxios<WOM.WalletResponse>({
        url: '/wallet/get',
        data
    });

export const getTransactionsInfo = (data: WOM.TransactionQueryRequest) =>
    womAxios<WOM.TransactionQueryResponse>({
        url: '/wallet/transaction-query',
        data
    });

export const getWomRates = (data: WOM.ExchangeRateRequest) =>
    womAxios<WOM.ExchangeRateResponse>({
        url: `/wallet/getTokenInfo`,
        data
    });
