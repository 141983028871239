import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import {
    DateSignalsSmIcon,
    DescriptionSignalsSmIcon,
    PriceWomSmIcon,
    QuickSignalSmIcon,
    TotalSignalsSmIcon,
    WomSmIcon
} from 'assets/icons';
import MobileSrc from 'assets/img/quick-signals/mobile.png';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { walletDataEffects, walletDataStores } from 'stores/wallet-data';
import { getFormattedDate } from 'utils/quick-signals';
import { ImageWrapper, MobileImage, TextWrapper, Wrapper } from './styles';

export interface JoinCardProps {
    userId?: string;
    channelId?: string;
    name?: string | null;
    description?: string | null;
    womValue?: number;
    totalSignalsCount?: number;
    utcCreated?: string;
    avatar?: string;
    onJoin?: (channelId: string, userId?: string) => void;
}

export const JoinCard: FC<JoinCardProps> = ({
    channelId,
    userId,
    name,
    description,
    womValue,
    totalSignalsCount,
    utcCreated,
    avatar = defaultAvatar,
    onJoin
}) => {
    const ratesData = useStore(walletDataStores.$womRates);
    const usdRate = ratesData.rates?.find(item => item.assetName?.toLowerCase() === 'usd');

    const join = () => {
        if (channelId && onJoin) {
            onJoin(channelId, userId);
        }
    };

    useEffect(() => {
        walletDataEffects.getWomRatesFx();
    }, []);

    return (
        <Wrapper>
            <Section justifyCenter marginBottom="24px">
                <ImageWrapper>
                    <QsAvatar large url={avatar} />
                    <MobileImage src={MobileSrc} />
                </ImageWrapper>
            </Section>

            {!!name && (
                <Column noWrap marginBottom="4px" width="100%">
                    <Section alignCenter>
                        <Column marginRight="8px">
                            <QuickSignalSmIcon />
                        </Column>
                        <Text size="-2">TITLE</Text>
                    </Section>

                    <TextWrapper>
                        <Text>{name}</Text>
                    </TextWrapper>
                </Column>
            )}

            {!!totalSignalsCount && (
                <Column noWrap marginBottom="4px" width="100%">
                    <Section alignCenter>
                        <Column marginRight="8px">
                            <TotalSignalsSmIcon />
                        </Column>
                        <Text size="-2">TOTAL SIGNALS</Text>
                    </Section>

                    <TextWrapper>
                        <Text>{totalSignalsCount} Signals published</Text>
                    </TextWrapper>
                </Column>
            )}

            {!!utcCreated && (
                <Column noWrap marginBottom="4px" width="100%">
                    <Section alignCenter>
                        <Column marginRight="8px">
                            <DateSignalsSmIcon />
                        </Column>
                        <Text size="-2">CHANNEL CREATED</Text>
                    </Section>

                    <TextWrapper>
                        <Text>{getFormattedDate(utcCreated, false)}</Text>
                    </TextWrapper>
                </Column>
            )}

            {!!womValue && (
                <Column noWrap marginBottom="4px" width="100%">
                    <Section alignCenter>
                        <Column marginRight="8px">
                            <PriceWomSmIcon />
                        </Column>
                        <Text size="-2">PRICE IN WOM PER MONTH</Text>
                    </Section>

                    <TextWrapper>
                        <Text>{womValue}</Text>
                    </TextWrapper>
                </Column>
            )}

            {!!description && (
                <Column noWrap marginBottom="4px" width="100%">
                    <Section alignCenter>
                        <Column marginRight="8px">
                            <DescriptionSignalsSmIcon />
                        </Column>
                        <Text size="-2">DESCRIPTION</Text>
                    </Section>

                    <TextWrapper>
                        <Text>{description}</Text>
                    </TextWrapper>
                </Column>
            )}

            {!!womValue && (
                <Column marginTop="8px" width="100%">
                    <Section marginBottom="12px">
                        <Button wide icon={<WomSmIcon />} iconPosition="left" onClick={join}>
                            {womValue}/month
                        </Button>
                    </Section>

                    {usdRate && usdRate.price && (
                        <Section justifyCenter>
                            <Text alignTextCenter size="-2">
                                Current exchange rate: ${usdRate.price.toFixed(4)} USD = 1 WOM
                            </Text>
                        </Section>
                    )}

                    <Section justifyCenter marginTop="20px">
                        <Text alignTextCenter color={grey[500]} size="-2">
                            You can cancel at any time
                        </Text>
                    </Section>
                </Column>
            )}
        </Wrapper>
    );
};
