import { forward, sample } from 'effector';
import { savedVideoEffects } from 'stores/saved-video';
import { videoEffects } from 'stores/video';

sample({
    clock: savedVideoEffects.saveVideoFx.done,
    fn: ({ params, result }: { params: BULLZ.CreateSavedVideoRequest; result: BULLZ.MessageResponseBase }) => {
        if (result?.isSuccess) {
            return { videoId: params.videoId, saved: params.isSave };
        }
        return {};
    },
    target: videoEffects.registerEventVideo
});

forward({
    from: savedVideoEffects.saveVideoFx.done,
    to: savedVideoEffects.saveVideoDoneFx
});
