import { BackArrowSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { StyledSwiper } from 'components/modals/WalletGuideModal/styles';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { collaborationGuideModal } from 'stores/initialize.modal';
import Swiper, { Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { slides } from './constants';
import { Slide } from './Slide';

const { modal, closeModal } = collaborationGuideModal;

export const CollaborationGuideModal = () => {
    const [visible] = useStore(modal);
    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleNextClick = () => {
        if (swiper) {
            if (swiper.isEnd) {
                closeModal();
            } else {
                swiper.slideNext();
            }
        }
    };

    useEffect(() => {
        !visible && setActiveIndex(0);
    }, [visible]);

    return (
        <ModalWrapper fullscreenMobile visible={visible}>
            <Column alignCenter justifyBetween noWrap height="100%" width="100%">
                <Column noWrap width="100%">
                    {isMobile && (
                        <Section marginBottom="8px">
                            <IconButton onClick={() => closeModal()}>
                                <BackArrowSmIcon />
                            </IconButton>
                        </Section>
                    )}

                    <Section marginBottom="24px">
                        <StyledSwiper
                            autoHeight
                            className="guide-swiper"
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            slidesPerView={1}
                            spaceBetween={20}
                            onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
                            onSwiper={swiper => setSwiper(swiper)}
                        >
                            {slides.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Slide {...item} />
                                </SwiperSlide>
                            ))}
                        </StyledSwiper>
                    </Section>
                </Column>

                <Button wide onClick={handleNextClick}>
                    {activeIndex === slides.length - 1 ? 'Okay' : 'Next'}
                </Button>
            </Column>
        </ModalWrapper>
    );
};
