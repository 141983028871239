import { AtSignSmIcon } from 'assets/icons';
import { InputField } from 'components/form/InputField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { useStore } from 'effector-react';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { authEffects, authStores } from 'stores/auth';
import { forgotPasswordModal, loginModal } from 'stores/initialize.modal';
import { UsernameLoginFormValues, usernameLoginSchema, usernameLoginValues } from './constants';

const { loginFx } = authEffects;

export const UsernameForm = () => {
    const isLoading = useStore(loginFx.pending);
    const loginError = useStore(authStores.$loginError);

    const openForgotPassword = () => {
        loginModal.closeModal();
        forgotPasswordModal.openModal();
    };

    return (
        <Formik initialValues={usernameLoginValues} validationSchema={usernameLoginSchema} onSubmit={loginFx}>
            {({ handleSubmit, isValid, dirty }: FormikProps<UsernameLoginFormValues>) => (
                <FormWrapper onSubmit={handleSubmit}>
                    <Section marginBottom="24px">
                        <InputField
                            error={loginError || undefined}
                            label="Username"
                            name="usernameOrEmail"
                            placeholder="Enter username here"
                            prefix={<AtSignSmIcon />}
                        />
                    </Section>
                    <Section marginBottom="8px">
                        <InputField
                            error={loginError || undefined}
                            label="Password"
                            name="password"
                            placeholder="Please enter your password"
                            type="password"
                        />
                    </Section>

                    <Section justifyEnd marginBottom="32px">
                        <TextButton onClick={openForgotPassword}>Forgot Password?</TextButton>
                    </Section>
                    <Button wide disabled={!isValid || !dirty || isLoading} type="submit">
                        Next
                    </Button>
                </FormWrapper>
            )}
        </Formik>
    );
};
