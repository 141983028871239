import { ConfettiLgIcon, DeleteAccountLgIcon, WrongLgIcon } from 'assets/icons';
import { notify } from 'components/ui/Toasts';
import { lsAnonymousUserKey, lsSessionKey, lsTokenKey, lsUserKey } from 'constants/global';
import { asyncErrorMessage } from 'constants/messages';
import { localeStandard } from 'constants/services';
import { createEffect, createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { API } from 'services';
import { forgotPasswordModal, infoModal } from 'stores/initialize.modal';
import { Token } from 'types';
import { goToHelpDesk, noop, retrieveLocale } from 'utils/common';
import { v4 as uuid4 } from 'uuid';

// Events

const setUser = createEvent<BULLZ.GetUserResponse | null>();
const setToken = createEvent<string | null>();
const logout = createEvent();
const updateAvatar = createEvent<BULLZ.GetUserProfileImageResponse>();
const setDeletingError = createEvent<string>();

// Effects

const createAnonymousAccountFx = createEffect({
    handler: async () => await API.user.createAccountAnonymous({ locale: retrieveLocale(), localeStandard })
});

const loginFx = createEffect({
    handler: async (data: BULLZ.UserAuthChallengeEmailOrUsernameOrPhoneRequest) =>
        await API.auth.authenticateUser({
            ...data
        })
});

const analyzeMobileNumberFx = createEffect({
    handler: async (data: BULLZ.AnalyzeMobileNumberRequest) =>
        await API.auth.analyzeMobileNumber({
            ...data
        })
});

const authFx = createEffect({
    handler: (data?: BULLZ.UserJwtTokenResponse) => {
        if (data && data.user && data.token) {
            setUser(data.user);
            setToken(data.token);
        }
    }
});

const getCurrentUserFx = createEffect({
    handler: async () => await API.user.getUser({})
});

interface SendCodeParams {
    phone: string;
    firebaseToken: string;
}

const forgotPasswordSendSmsCodeFx = createEffect({
    handler: async ({ phone, firebaseToken }: SendCodeParams) =>
        await API.user.forgotPasswordSendVerificationCode(
            {
                mobileNumber: phone
            },
            firebaseToken
        )
});

const forgotPasswordFx = createEffect({
    handler: async (data: BULLZ.ChangeUserPasswordViaSmsRequest) => await API.user.changePassword(data)
});

const forgotPasswordDoneFx = createEffect({
    handler: () => {
        forgotPasswordModal.closeModal();
        infoModal.openModal({
            icon: ConfettiLgIcon,
            title: 'Congrats!',
            text: 'You successfully created a new password!',
            buttonText: 'Let’s Go',
            onClick: noop
        });
    }
});

const forgotPasswordFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'There was a problem creating your password. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

interface UploadAvatarParams {
    userId: string;
    file: File;
}

const uploadAvatarFx = createEffect({
    handler: async ({ userId, file }: UploadAvatarParams) => await API.user.uploadAvatar(userId, file)
});

const updateAccountFx = createEffect({
    handler: async (data: BULLZ.UserUpdateRequest) => await API.user.updateAccount(data)
});

const updateFailFx = createEffect({
    handler: () => {
        notify(asyncErrorMessage, 'danger');
    }
});

const deleteAccountFx = createEffect({
    handler: async (password: string) => await API.user.deleteAccount({ password })
});

const deleteAccountFailFx = createEffect({
    handler: (data?: any) => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text:
                'There was a problem deleting your account. Please try again or contact our support team. We are sorry for inconvenience.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });

        if (data.message) {
            setDeletingError(data.message as string);
        }
    }
});

const deleteAccountDoneFx = createEffect({
    handler: (data: BULLZ.MessageResponseBase) => {
        if (data.isSuccess) {
            setDeletingError('');
            logout();
            infoModal.openModal({
                icon: DeleteAccountLgIcon,
                title: 'Account Deleted',
                text: 'Your account has been deleted',
                buttonText: 'Okay',
                onClick: noop
            });
        } else {
            setDeletingError(data.message || 'Password is incorrect');
        }
    }
});

// Stores

const userLocalStorage = connectLocalStorage(lsUserKey).onError(err => console.log(err));
const tokenLocalStorage = connectLocalStorage(lsTokenKey).onError(err => console.log(err));
const anonymousUserLocalStorage = connectLocalStorage(lsAnonymousUserKey).onError(err => console.log(err));
const sessionLocalStorage = connectLocalStorage(lsSessionKey).onError(err => console.log(err));

const $anonymousUser = createStore<BULLZ.UserJwtTokenResponse>(anonymousUserLocalStorage.init({})).on(
    createAnonymousAccountFx.doneData,
    (_, user) => user
);
const $sessionId = createStore<string>(sessionLocalStorage.init(uuid4()));

const $user = createStore<BULLZ.GetUserResponse | null>(userLocalStorage.init(null))
    .on(loginFx.doneData, (_state, payload) => payload.user || null)
    .on(getCurrentUserFx.doneData, (_state, payload) => payload || null)
    .on(setUser, (state, payload) => {
        if (!payload) return payload;

        return {
            ...payload,
            profile: {
                ...payload?.profile,
                profileImageUrl: state?.profile?.profileImageUrl || ''
            }
        };
    })
    .on(loginFx.fail, () => null)
    .on(logout, () => null)
    .on(updateAvatar, (state, data) => {
        if (!state) return state;

        return {
            ...state,
            profile: {
                ...state.profile,
                profileImageUrl: data.profileImageUrl
            }
        };
    });

const $token = createStore<Token>(tokenLocalStorage.init(null))
    .on(loginFx.doneData, (_state, payload) => payload.token || null)
    .on(setToken, (_state, payload) => payload)
    .on(loginFx.fail, () => null)
    .on(logout, () => null);

const $isAuth = $token.map(token => !!token);

const $loginError = createStore<string | null>(null)
    .on(loginFx.fail, () => 'Unable to login with these credentials')
    .reset([logout, loginFx.done]);

const $deletingError = createStore<string>('')
    .on(setDeletingError, (_, payload) => payload)
    .reset(logout);

// Exports

export const localStorageConnects = {
    userLocalStorage,
    tokenLocalStorage,
    anonymousUserLocalStorage,
    sessionLocalStorage
};

export const authStores = {
    $user,
    $token,
    $isAuth,
    $loginError,
    $anonymousUser,
    $sessionId,
    $deletingError
};

export const authEvents = {
    setUser,
    setToken,
    logout,
    updateAvatar,
    setDeletingError
};

export const authEffects = {
    loginFx,
    analyzeMobileNumberFx,
    authFx,
    createAnonymousAccountFx,
    getCurrentUserFx,
    forgotPasswordSendSmsCodeFx,
    forgotPasswordFx,
    forgotPasswordDoneFx,
    forgotPasswordFailFx,
    uploadAvatarFx,
    updateFailFx,
    updateAccountFx,
    deleteAccountFx,
    deleteAccountFailFx,
    deleteAccountDoneFx
};
