import { sidebarWidth } from 'components/grid/SideBar/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexStart } from 'constants/styles/mixins';
import styled from 'styled-components';

interface WrapperProps {
    isHidePaddingX?: boolean;
    hasMobilePaddingTop?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    max-width: 1280px;
    padding-left: ${({ isHidePaddingX }) => !isHidePaddingX && '16px'};
    padding-right: ${({ isHidePaddingX }) => !isHidePaddingX && '16px'};
    padding-top: ${({ hasMobilePaddingTop }) => (hasMobilePaddingTop ? `calc(${headerHeight} + 8px)` : headerHeight)};
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: nowrap;

    ${media.laptop(`
        padding-left: 20px;
        padding-right: 20px;
        padding-top: calc(${headerHeight} + 16px);
    `)}
`;

interface ContainerProps {
    isMobile?: boolean;
    isHideSideBar?: boolean;
}

export const Container = styled.div<ContainerProps>`
    width: ${({ isMobile, isHideSideBar }) => (isMobile || isHideSideBar ? '100%' : `calc(100% - ${sidebarWidth})`)};
    ${({ isMobile, isHideSideBar }) => !isMobile && !isHideSideBar && 'padding-left: 16px'};
    ${flexStart};
    flex-direction: column;
    background-color: ${baseColors.black};
    padding-bottom: ${({ isMobile }) => (!isMobile ? '24px' : headerHeight)};
`;
