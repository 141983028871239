export enum Step {
    Create = 'create',
    Confirm = 'confirm'
}

export const titles: Record<Step, string> = {
    create: 'Please add wallet security',
    confirm: 'Confirm Wallet Pin'
};

export const subtitles: Record<Step, string> = {
    create: 'Please add a wallet pin to secure your wallet.',
    confirm: 'Please confirm the wallet pin you entered previously.'
};

export enum WalletPinFieldName {
    Code = 'code',
    Confirm = 'confirmCode'
}

export const pinCodeLength = 6;
