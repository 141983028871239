import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { TextButton } from 'components/ui/buttons/TextButton';
import { flexCenter } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Empty = styled.div`
    width: 100%;
    height: calc(100vh - ${headerHeight} - 172px);
    min-height: 280px;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${media.laptop(`
        background-color: ${baseColors.darkGrey};
        height: 100%;
    `)};
`;

export const Image = styled.img`
    width: 220px;
    height: 220px;
    margin-bottom: 18px;
`;

export const StyledButton = styled(TextButton)`
    width: 100%;
    padding: 4px;
    ${flexCenter};
    border-radius: 4px;
    transition: background-color ${transitionTime} linear;

    &:hover:not(:disabled) {
        background: ${grey[800]};
    }
`;
