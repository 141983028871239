import NotFound from 'assets/img/not-found.png';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { NotificationItem } from 'components/pages/notifications/NotificationItem';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { defaultUserNotificationsQueryParams } from 'constants/services/user-notifications';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { notificationsEffects, notificationsStores } from 'stores/notifications';
import { Empty, Image } from '../styles';

const { getNotificationsFx, loadMoreNotificationsFx } = notificationsEffects;
const { $notifications, $hasError } = notificationsStores;

export const PageView = () => {
    const isLoading = useStore(getNotificationsFx.pending);
    const { items, currentPageIndex, totalPages } = useStore($notifications);
    const hasError = useStore($hasError);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreNotificationsFx({
                    ...defaultUserNotificationsQueryParams,
                    pageIndex: currentPageIndex + 1
                });
        },
        disabled: hasError
    });

    useEffect(() => {
        getNotificationsFx();
    }, []);

    if (isLoading) {
        return (
            <Section justifyCenter marginBottom="24px" marginTop="24px">
                <Spinner />
            </Section>
        );
    }

    if (!items || items.length === 0) {
        return (
            <Empty>
                <Image src={NotFound} />
                <Text alignTextCenter color={grey[500]} size={isMobile ? '-2' : '0'}>
                    No Notifications Found
                </Text>
            </Empty>
        );
    }

    return (
        <Column noWrap width="100%">
            {items.map(item => (
                <NotificationItem key={item.id} data={item} />
            ))}
            <HiddenScrollBlock ref={loadRef} />
        </Column>
    );
};
