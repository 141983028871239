import { Textarea, TextareaProps } from 'components/ui/inputs/Textarea';
import { useField } from 'formik';
import React, { ChangeEvent, FC } from 'react';

interface Props extends Omit<TextareaProps, 'name' | 'onChange'> {
    name: string;
}

export const TextareaField: FC<Props> = ({ name, error: errorMessage, ...props }) => {
    const [field, { error, touched }, { setTouched }] = useField(name);

    const handleChange = (e: ChangeEvent) => {
        !touched && setTouched(true);
        field.onChange(e);
    };

    return <Textarea {...props} {...field} error={touched && error ? error : errorMessage} onChange={handleChange} />;
};
