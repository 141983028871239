import { CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { FormWrapper, IconWrapper, SelectGrid } from 'components/modals/ReportVideoModal/styles';
import { Button } from 'components/ui/buttons/Button';
import { SelectButton } from 'components/ui/buttons/SelectButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { yupDefault } from 'constants/yup';
import { useStore } from 'effector-react';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { infoModal, reportVideoModal } from 'stores/initialize.modal';
import { videoEffects } from 'stores/video';
import * as Yup from 'yup';

const { modal, closeModal } = reportVideoModal;

export const ReportVideoModal = () => {
    const [visible, { videoId, title, text, isQuickSignal = false }] = useStore(modal);
    const videoReportReasons: Record<BULLZ.VideoReportedReasonRequest, string> = {
        0: 'Content contains nudity',
        1: 'Content contains violence',
        2: 'Content contains harassment',
        3: 'Content is objectionable',
        4: 'Content is inappropriate'
    };

    const validationSchema = Yup.object().shape({
        videoId: yupDefault,
        reason: yupDefault.oneOf(Object.keys(videoReportReasons))
    });

    const handleClose = () => closeModal();

    const { isValid, dirty, values, setFieldValue, handleSubmit, resetForm } = useFormik({
        initialValues: {
            videoId: '',
            reason: undefined
        },
        onSubmit: values => {
            videoEffects.reportVideo(values).then(() => {
                handleClose();
                resetForm();
                infoModal.openModal({
                    title: `Reported Successfully`,
                    text: `You have successfully reported this ${isQuickSignal ? 'Quick Signal' : ''} video.`,
                    buttonText: 'Okay'
                });
            });
            handleClose();
            resetForm();
        },
        validationSchema
    });

    useEffect(() => {
        setFieldValue('videoId', videoId);
    }, [setFieldValue, videoId]);

    return (
        <ModalWrapper visible={visible} onClose={handleClose}>
            <FormWrapper onSubmit={handleSubmit}>
                <Column alignCenter>
                    <Section justifyEnd marginBottom="22px">
                        <IconWrapper type="button" onClick={handleClose}>
                            <CloseSmIcon />
                        </IconWrapper>
                    </Section>
                    <Section justifyCenter marginBottom="16px">
                        <Title size="4">Report</Title>
                    </Section>
                    <Section justifyCenter marginBottom="16px">
                        <Text alignTextCenter size="-2">
                            {title ||
                                'We are sorry that you found this content inappropriate. Please indicate what you found unacceptable about the video and the action you would like us to undertake.'}
                        </Text>
                    </Section>
                    <SelectGrid>
                        {Object.entries(videoReportReasons).map(([reasonType, title]) => (
                            <SelectButton
                                key={reasonType}
                                wide
                                selected={values.reason === reasonType}
                                type="button"
                                onClick={() => setFieldValue('reason', reasonType)}
                            >
                                {title}
                            </SelectButton>
                        ))}
                    </SelectGrid>
                    <Section justifyCenter marginBottom="8px">
                        <Text alignTextCenter size="-2">
                            What happens now?
                        </Text>
                    </Section>
                    <Section marginBottom="28px">
                        <Text alignTextCenter size="-2">
                            {text ||
                                'This video will be hidden immediately from all viewers. We will review your feedback and take appropriate action.'}
                        </Text>
                    </Section>
                    <Section marginBottom="16px">
                        <Button wide disabled={!isValid || !dirty} type="submit">
                            Report
                        </Button>
                    </Section>
                </Column>
            </FormWrapper>
        </ModalWrapper>
    );
};
