import { baseColors } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${baseColors.black};
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    ${media.laptop(`
        background-color: ${baseColors.darkGrey};
        padding-left: 0px;
        padding-right: 0px;
    `)};
`;

export const Container = styled.div`
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
`;
