import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import {
    DateSignalsSmIcon,
    DescriptionSignalsSmIcon,
    PriceWomSmIcon,
    QuickSignalSmIcon,
    TotalSignalsSmIcon
} from 'assets/icons';
import phoneSrc from 'assets/img/quick-signals-phone.png';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { InfoBlock } from 'components/pages/quickSignals/QuickSignalsInfo/InfoBlock';
import {
    AvatarWrapper,
    Image,
    ImageWrapper,
    InfoBlocksWrapper
} from 'components/pages/quickSignals/QuickSignalsInfo/styles';
import { AvatarBorder } from 'components/ui/AvatarBorder';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { infoModal } from 'stores/initialize.modal';
import { quickSignalsEffects } from 'stores/quick-signals';
import { qsChannelEffects, qsChannelEvents, qsChannelStores } from 'stores/quick-signals-channel';
import { noop } from 'utils/common';
import { getFormattedDate } from 'utils/quick-signals';

const { getChannelInfoFx } = qsChannelEffects;
const { $channelInfo } = qsChannelStores;
const { resetStores } = qsChannelEvents;

const { leaveQSChannelFx } = quickSignalsEffects;

interface Props {
    channelId: string;
}

export const ChannelInfo: FC<Props> = ({ channelId }) => {
    const history = useHistory();

    const data = useStore($channelInfo);
    const isLoading = useStore(getChannelInfoFx.pending);

    const getInfo = () => {
        getChannelInfoFx(channelId).catch(() => {
            history.replace(AppRoute.QuickSignalsSubscriptions);
        });
    };

    const leave = () => {
        if (data) {
            leaveQSChannelFx(channelId).then(() => {
                getInfo();
                infoModal.openModal({
                    qsAvatar: data?.avatarUrl || defaultAvatar,
                    title: 'Cancelation Success',
                    text: 'You have left this Channel. You can resubscribe at any time.',
                    buttonText: 'My Channels',
                    onClick: () => history.replace(AppRoute.QuickSignalsSubscriptions)
                });
            });
        }
    };

    const leaveChannel = () => {
        infoModal.openModal({
            qsAvatar: data?.avatarUrl || defaultAvatar,
            title: 'Leave Channel',
            text: `Are you sure you want to leave this ${data?.channelInfo?.name || 'channel'}?`,
            buttonText: 'No',
            onClick: noop,
            cancelText: 'Yes',
            onCloseClick: leave
        });
    };

    useEffect(() => {
        getInfo();

        return () => resetStores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelId]);

    if (isLoading) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <Column noWrap width="100%">
            <Section justifyCenter={isMobile}>
                <ImageWrapper noWrap>
                    <AvatarWrapper>
                        <AvatarBorder src={data.avatarUrl || defaultAvatar} />
                    </AvatarWrapper>
                    <Image alt="phone" src={phoneSrc} />
                </ImageWrapper>
            </Section>

            <Column noWrap marginBottom="40px" width="100%">
                <InfoBlocksWrapper>
                    <InfoBlock icon={QuickSignalSmIcon} text={data?.channelInfo?.name || ''} title="TITLE" />
                    <InfoBlock
                        icon={PriceWomSmIcon}
                        text={data?.channelInfo?.womValue || 0}
                        title="PRICE IN WOM PER MONTH"
                    />
                    <InfoBlock
                        icon={TotalSignalsSmIcon}
                        text={`${data?.totalSignalsCount || 0} Signals published`}
                        title="TOTAL SIGNALS"
                    />
                    <InfoBlock
                        color={data.isSubscriptionCancelled ? baseColors.red : undefined}
                        icon={DateSignalsSmIcon}
                        text={getFormattedDate(data?.subscriptionRenewDate) || ''}
                        title={data.isSubscriptionCancelled ? 'AVAILABLE UNTIL' : 'RENEW DATE'}
                    />
                </InfoBlocksWrapper>

                <InfoBlock
                    icon={DescriptionSignalsSmIcon}
                    text={data?.channelInfo?.description || ''}
                    title="DESCRIPTION"
                />
            </Column>

            {!data.isSubscriptionCancelled && (
                <Column noWrap width="100%">
                    <Section marginBottom="16px">
                        <TextButton onClick={leaveChannel}>Leave Channel</TextButton>
                    </Section>

                    <Section>
                        <Text color={grey[500]} size="-2">
                            If you leave this Channel now, you can still access Signals from this Channel until{' '}
                            {getFormattedDate(data?.subscriptionRenewDate)}
                        </Text>
                    </Section>
                </Column>
            )}
        </Column>
    );
};
