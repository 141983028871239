import { yupDefault, yupFile, yupStringLimited, yupUrl } from 'constants/yup';
import * as Yup from 'yup';

export interface FillDataFormValues {
    project: string;
    topic: string;
    website: string;
    category: string;
    language: string;
    videoFile: File | null;
}

export const uploadVideoInitialData: FillDataFormValues = {
    project: '',
    topic: '',
    website: '',
    category: '',
    language: '',
    videoFile: null
};

export const fillDataSchema = Yup.object().shape({
    project: yupStringLimited(20),
    topic: yupStringLimited(20),
    website: yupUrl,
    category: yupDefault,
    language: yupDefault,
    videoFile: yupFile
});
