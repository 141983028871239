import { Section } from 'components/grid/wrappers/FlexWrapper';
import styled from 'styled-components';

export const Image = styled.img`
    width: 174px;
    height: 174px;
    border-radius: 14px;
    margin-bottom: 12px;
`;

export const TextWrapper = styled(Section)`
    justify-content: center;
    text-align: center;
    word-break: break-all;
    white-space: pre-wrap;
`;
