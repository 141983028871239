import { PlaylistValues } from './types';

export const getPlaylistValues = (playlist: string[], videoId: string): PlaylistValues => {
    const index = playlist.findIndex(item => item === videoId);

    if (index === -1) {
        return {
            prev: [...playlist],
            next: []
        };
    }

    return {
        prev: [...playlist].slice(0, index).reverse(),
        next: [...playlist].slice(index + 1)
    };
};
