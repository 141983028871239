import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { baseColors, flexCenter, grey } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding: 16px;
    background-color: ${baseColors.darkGrey};
    border-radius: 16px;
    margin-top: 16px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${grey[600]};
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const HeaderExtra = styled(Row)`
    flex-grow: 1;
    padding-left: 8px;

    ${media.laptop(`
        padding-left: 16px;
    `)};
`;

export const GradientLabel = styled.div`
    background: linear-gradient(29.65deg, #f15f31 11.36%, #4353ff 64.09%);
    ${flexCenter};
    padding: 2px 8px;
    border-radius: 50px;
    margin-right: 10px;

    ${media.phone(`
        margin-right: 0;
    `)};
`;

export const FollowWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;

    ${media.laptop(`
        grid-gap: 74px;
        grid-template-columns: 4fr 1fr;
    `)};
`;
