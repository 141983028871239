import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { FullscreenVideo } from 'components/video/FullscreenVideo';
import { PlaylistType } from 'components/video/FullscreenVideo/types';
import queryString from 'query-string';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Id } from 'types/interfaces/data';

interface QueryProps {
    playlistType?: PlaylistType;
}

export const VideoPage = () => {
    const history = useHistory();
    const { id } = useParams<Partial<Id>>();

    const { playlistType } = queryString.parse(history.location.search) as QueryProps;

    if (!id) {
        return (
            <Section justifyCenter marginTop="24px">
                <Spinner />
            </Section>
        );
    }

    return <FullscreenVideo playlistType={playlistType} videoId={id} />;
};
