import {
    BackArrowSmIcon,
    BullzSmIcon,
    DiscordSmIcon,
    InstagramSmIcon,
    MediumSmIcon,
    TelegramSmIcon,
    TwitterSmIcon
} from 'assets/icons';
import BullzImage from 'assets/img/bullz-image.png';
import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { ButtonLink } from 'components/pages/settings/components/ButtonLink';
import { EmptyBlock, SettingsTitle, Wrapper } from 'components/pages/settings/components/CommonPart/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import {
    bullzSiteUrl,
    discordSiteUrl,
    instagramSiteUrl,
    mediumSiteUrl,
    telegramSiteUrl,
    twitterSiteUrl
} from 'constants/routes';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { ReactClick } from 'types/react';
import { Image, TextWrapper } from './styles';

export const AboutContent: FC<ReactClick<HTMLButtonElement>> = ({ onClick }) => (
    <Wrapper>
        {isMobile ? (
            <Section alignCenter justifyBetween marginBottom="20px">
                <IconButton onClick={onClick}>
                    <BackArrowSmIcon />
                </IconButton>
                <SettingsTitle isBold size="1">
                    About
                </SettingsTitle>
                <EmptyBlock />
            </Section>
        ) : (
            <Section marginBottom="24px">
                <SettingsTitle isBold size="-1">
                    About
                </SettingsTitle>
            </Section>
        )}
        <Section justifyCenter>
            <Image src={BullzImage} />
        </Section>

        <TextWrapper>
            <Text size={isMobile ? '-1' : '-2'}>Come chat with us!</Text>
        </TextWrapper>

        <ButtonLink icon={InstagramSmIcon} link={instagramSiteUrl} title="Instagram" />
        <ButtonLink icon={TwitterSmIcon} link={twitterSiteUrl} title="Twitter" />
        <ButtonLink icon={MediumSmIcon} link={mediumSiteUrl} title="Medium" />
        <ButtonLink icon={TelegramSmIcon} link={telegramSiteUrl} title="Telegram" />
        <ButtonLink icon={DiscordSmIcon} link={discordSiteUrl} title="Discord" />
        <ButtonLink icon={BullzSmIcon} link={bullzSiteUrl} title="BULLZ" />
    </Wrapper>
);
