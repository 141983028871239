import { BackArrowSmIcon, ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import {
    EmptyBlock,
    InnerWrapper,
    SettingsTitle,
    Wrapper
} from 'components/pages/settings/components/CommonPart/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { asyncErrorMessage } from 'constants/messages';
import React, { FC, ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { infoModal } from 'stores/initialize.modal';
import { settingsWalletPinEffects } from 'stores/settings/wallet-pin';
import { NoopType } from 'types';
import { goToHelpDesk } from 'utils/common';
import { NewPinStep } from './NewPinStep';
import { SendCodeStep } from './SendCodeStep';
import { SmsCodeStep } from './SmsCodeStep';

enum Step {
    SendCode,
    SmsCode,
    NewPin
}

interface Props {
    onBack: NoopType;
}

export const ChangeWalletPin: FC<Props> = ({ onBack }) => {
    const [currentStep, setCurrentStep] = useState(Step.SendCode);
    const [smsCode, setSmsCode] = useState('');

    const reset = () => {
        setCurrentStep(Step.SendCode);
        setSmsCode('');
    };

    const handleSendSmsCodeSuccess = () => {
        setCurrentStep(Step.SmsCode);
    };

    const handleConfirmSmsCodeSuccess = (smsCode: string) => {
        setSmsCode(smsCode);
        setCurrentStep(Step.NewPin);
    };

    const handleNewCodeSuccess = (newPin: string) => {
        settingsWalletPinEffects
            .changeWalletPinFx({
                smsOtp: smsCode,
                newPin
            })
            .then(() => {
                infoModal.openModal({
                    icon: ConfettiLgIcon,
                    title: 'Congrats!',
                    text: 'You have successfully changed your wallet pin',
                    buttonText: 'Okay'
                });
                reset();
            })
            .catch(() => {
                infoModal.openModal({
                    icon: WrongLgIcon,
                    title: asyncErrorMessage,
                    text: 'There was a problem changing your wallet pin. Please try again or contact our support team.',
                    buttonText: 'Try Again',
                    cancelText: 'CONTACT SUPPORT',
                    onCloseClick: goToHelpDesk
                });
            });
    };

    const steps: Record<Step, ReactNode> = {
        [Step.SendCode]: <SendCodeStep onSuccess={handleSendSmsCodeSuccess} />,
        [Step.SmsCode]: <SmsCodeStep onSuccess={handleConfirmSmsCodeSuccess} />,
        [Step.NewPin]: <NewPinStep onSuccess={handleNewCodeSuccess} />
    };

    return (
        <Wrapper>
            <Section alignCenter noWrap justifyBetween={isMobile} marginBottom={isMobile ? '20px' : '24px'}>
                {isMobile && (
                    <IconButton onClick={onBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                )}

                <SettingsTitle isBold size={isMobile ? '1' : '-1'}>
                    Change Wallet Pin
                </SettingsTitle>

                {isMobile && <EmptyBlock />}
            </Section>

            <InnerWrapper>{steps[currentStep]}</InnerWrapper>
        </Wrapper>
    );
};
