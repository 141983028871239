import { ChevronDownSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import React, { ReactNode, useRef, useState } from 'react';
import { Disabled } from 'types';
import { Option } from './Option';
import { GlobalStyle, OptionsWrapper, SelectValue, SelectValueWrapper, SelectWrapper } from './styles';
import { SelectOnChange, SelectOption, SelectOptionValue } from './types';

export interface SelectProps<T> extends SelectOnChange, Pick<TooltipProps, 'placement'>, Disabled {
    value?: SelectOptionValue;
    placeholder?: string;
    items: T[];
    renderOption?: (props: T) => ReactNode;
    optionsWidth?: string;
    hideBorder?: boolean;
    hideOnChange?: boolean;
    error?: string;
}

export const Select = <T extends SelectOption>({
    value,
    items,
    placeholder = 'Select',
    disabled,
    renderOption,
    onChange,
    placement = 'bottomLeft',
    optionsWidth,
    hideBorder = false,
    hideOnChange = false,
    error
}: SelectProps<T>) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    const selectedValue = items.find(item => item.value === value);

    const handleChange = (value: SelectOptionValue) => {
        onChange && onChange(value);
        hideOnChange && setIsVisible(false);
    };

    return (
        <>
            <Column noWrap width="100%">
                <SelectWrapper ref={selectRef}>
                    <RcTooltip
                        destroyTooltipOnHide
                        overlay={
                            <OptionsWrapper>
                                {items.length > 0 ? (
                                    items.map(item => (
                                        <Option
                                            key={item.value}
                                            disabled={disabled}
                                            isSelected={item.value === value}
                                            value={item.value}
                                            onChange={handleChange}
                                        >
                                            {renderOption ? renderOption(item) : <Text size="-2">{item.label}</Text>}
                                        </Option>
                                    ))
                                ) : (
                                    <Section alignCenter justifyCenter>
                                        <MarginWrapper marginBottom="10px" marginTop="10px">
                                            <Text size="-2">No options</Text>
                                        </MarginWrapper>
                                    </Section>
                                )}
                            </OptionsWrapper>
                        }
                        overlayClassName="custom-select"
                        placement={placement}
                        showArrow={false}
                        trigger="click"
                        visible={isVisible}
                        onVisibleChange={setIsVisible}
                    >
                        <SelectValueWrapper hasError={!!error} hideBorder={hideBorder} isVisible={isVisible}>
                            <MarginWrapper marginRight="8px">
                                <SelectValue size="-1">{selectedValue ? selectedValue.label : placeholder}</SelectValue>
                            </MarginWrapper>
                            <IconButton rotate={isVisible ? 180 : undefined}>
                                <ChevronDownSmIcon />
                            </IconButton>
                        </SelectValueWrapper>
                    </RcTooltip>
                </SelectWrapper>

                {!!error && (
                    <Section marginTop="8px">
                        <Text color={baseColors.red} size="-2">
                            {error}
                        </Text>
                    </Section>
                )}
            </Column>
            <GlobalStyle width={optionsWidth || `${selectRef.current?.clientWidth}px`} />
        </>
    );
};
