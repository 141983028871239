import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { TextButton } from 'components/ui/buttons/TextButton';
import { KeyboardCode } from 'constants/special';
import { useStore } from 'effector-react';
import React, { ChangeEvent, Dispatch, FC, KeyboardEvent, SetStateAction } from 'react';
import { authStores } from 'stores/auth';
import { commentsEffects } from 'stores/comments';
import { CommentsInputParentWrapper, CommentsInputWrapper, StyledInput } from './styles';

enum PostBodyType {
    Undefined,
    PlainText,
    Html
}

interface Props {
    newComment: string;
    setNewComment: Dispatch<SetStateAction<string>>;
    replyingComment: BULLZ.GetRootPostResponse | undefined;
    setReplyingComment: Dispatch<SetStateAction<BULLZ.GetRootPostResponse | undefined>>;
    videoId?: string;
}

export const CommentInput: FC<Props> = ({
    newComment,
    setNewComment,
    setReplyingComment,
    replyingComment,
    videoId
}) => {
    const user = useStore(authStores.$user);
    const isAuth = useStore(authStores.$isAuth);

    const onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setReplyingComment(undefined);
        }
        setNewComment(e.target.value);
    };

    const onReplyClear = () => {
        setNewComment('');
        setReplyingComment(undefined);
    };

    const onPostClick = () => {
        commentsEffects.addCommentFx({
            videoId,
            body: newComment,
            bodyType: PostBodyType.PlainText,
            ...(replyingComment ? { parentCommentId: replyingComment.id } : {})
        });
        onReplyClear();
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardCode.Enter) {
            onPostClick();
        }
    };

    const getPlaceholder = () => {
        if (isAuth) {
            return replyingComment ? `Reply to ${replyingComment.username}` : 'Add a comment...';
        } else return 'Log In/Sign Up to add a comment...';
    };

    return (
        <CommentsInputParentWrapper>
            <CommentsInputWrapper>
                {isAuth && <AvatarImg diameter="32px" src={user?.profile?.profileImageUrl || undefined} />}
                <StyledInput
                    disabled={!isAuth}
                    placeholder={getPlaceholder()}
                    value={newComment}
                    onChange={onCommentChange}
                    onKeyDown={handleKeyDown}
                />
                {replyingComment && !newComment && (
                    <TextButton buttonType="primary" onClick={onReplyClear}>
                        Reset
                    </TextButton>
                )}
                {newComment && (
                    <TextButton buttonType="primary" onClick={onPostClick}>
                        Post
                    </TextButton>
                )}
            </CommentsInputWrapper>
        </CommentsInputParentWrapper>
    );
};
