import { ForgotPinFieldName } from 'components/modals/ForgotPinModal/constants';
import { yupCode } from 'constants/yup';
import { FormikErrors } from 'formik';
// import { authEffects } from 'stores/auth';
// import { registerEffects } from 'stores/register';
import * as Yup from 'yup';

// Initial data

export interface ForgotPinFormValues {
    smsVerificationCode: string;
    pin: string;
    confirmPin: string;
}

export const forgotPinInitialValues: ForgotPinFormValues = {
    smsVerificationCode: '',
    pin: '',
    confirmPin: ''
};

// Validation

export const schema = Yup.object().shape({
    smsVerificationCode: yupCode,
    pin: yupCode,
    confirmPin: yupCode
});

const validateVerificationCode = async (smsCode: string) => {
    try {
        await Yup.reach(schema, ForgotPinFieldName.SmsCode).validate(smsCode);

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validatePin = async (pin: string) => {
    try {
        await Yup.reach(schema, ForgotPinFieldName.Pin).validate(pin);

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validateConfirmPin = async (pin: string, confirmPin: string) => {
    try {
        await Yup.reach(schema, ForgotPinFieldName.ConfirmPin).validate(confirmPin);

        if (pin !== confirmPin) {
            return 'Pins don’t match';
        }

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

export const validateForgotPinForm = async ({ smsVerificationCode, pin, confirmPin }: ForgotPinFormValues) => {
    const errors: FormikErrors<Partial<ForgotPinFormValues>> = {};

    errors.smsVerificationCode = await validateVerificationCode(smsVerificationCode);
    errors.pin = await validatePin(pin);
    errors.confirmPin = await validateConfirmPin(pin, confirmPin);

    return errors;
};
