import { forward } from 'effector';
import { qsEffects, qsStores } from 'stores/quick-signals-trending';

const { $isVisibleQSModal } = qsStores;
const { getQSUsersFx } = qsEffects;

forward({
    from: $isVisibleQSModal,
    to: getQSUsersFx
});
