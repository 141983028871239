import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { NotificationItem } from 'components/pages/notifications/NotificationItem';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { notificationsEffects, notificationsEvents, notificationsStores } from 'stores/notifications';
import { Empty, StyledButton } from '../styles';

const { getLastNotificationsFx } = notificationsEffects;
const { $lastNotifications } = notificationsStores;

export const DropdownView = () => {
    const history = useHistory();

    const isLoading = useStore(getLastNotificationsFx.pending);
    const items = useStore($lastNotifications);

    const seeAll = () => {
        if (history.location.pathname === AppRoute.Notifications) {
            notificationsEvents.closeDropdown();
        } else {
            history.push(AppRoute.Notifications);
        }
    };

    useEffect(() => {
        getLastNotificationsFx();
    }, []);

    if (!items.length && isLoading) {
        return (
            <Section justifyCenter marginBottom="24px" marginTop="24px">
                <Spinner />
            </Section>
        );
    }

    if (!items.length) {
        return (
            <Empty>
                <Text alignTextCenter color={grey[500]} size={isMobile ? '-2' : '0'}>
                    You’re up to date! There are no new notifications
                </Text>
            </Empty>
        );
    }

    return (
        <Column noWrap width="100%">
            {items.map(item => (
                <NotificationItem key={item.id} data={item} />
            ))}

            <StyledButton wide onClick={seeAll}>
                <Text alignTextCenter size="-2">
                    See All
                </Text>
            </StyledButton>
        </Column>
    );
};
