import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { bucmUserStores } from 'stores/bucm-user';
import { videoEffects, videoStores } from 'stores/video';
import { Earnings } from '../Earnings';
import { Stats } from '../Stats';

interface Props {
    data: BULLZ.GetVideoDetailsResponse | null;
}

export const CollaborationContent: FC<Props> = ({ data }) => {
    const bucmUser = useStore(bucmUserStores.$bucmUser);
    const isLoading = useStore(videoEffects.getCollaborationVideoEarningsFx.pending);
    const earningsData = useStore(videoStores.$collaborationEarningsData);

    useEffect(() => {
        if (data && data.isInCollaboration && data.collaborationId && bucmUser.userId) {
            videoEffects.getCollaborationVideoEarningsFx({
                collaborationId: data.collaborationId,
                userId: bucmUser.userId
            });
        }
    }, [data, bucmUser]);

    if (!data || !data.collaborationId) {
        return null;
    }

    return (
        <Column noWrap width="100%">
            {isLoading ? (
                <Section justifyCenter marginBottom="24px">
                    <Spinner />
                </Section>
            ) : (
                earningsData && (
                    <>
                        <Section justifyBetween marginBottom="24px">
                            <Earnings wom={(earningsData.contentReward || 0) + (earningsData.sharingReward || 0)} />
                        </Section>

                        <Column marginBottom="24px">
                            <Text isBold size="-1">
                                Collaboration Name
                            </Text>
                            <Row marginTop="8px">
                                <Text isBold size="1">
                                    {earningsData.name || '-'}
                                </Text>
                            </Row>
                        </Column>

                        <Section justifyBetween marginBottom="24px">
                            <Earnings small title="Content Reward" wom={earningsData.contentReward} />
                            <Earnings small title="Social Sharing Reward" wom={earningsData.sharingReward} />
                        </Section>
                    </>
                )
            )}

            <Stats data={data.engagementStatistics} />
        </Column>
    );
};
