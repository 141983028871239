import { Section } from 'components/grid/wrappers/FlexWrapper';
import { defaultInputStyles } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const CommentsInputParentWrapper = styled(Section)`
    padding: 8px 10px;
    margin-top: 3px;
    background: ${grey[900]};

    ${media.laptop(`
        background: none;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 16px;
        margin-top: 0;
    `)};
`;

export const CommentsInputWrapper = styled(Section)`
    padding: 8px 16px;
    background-color: ${baseColors.white};
    flex-shrink: 0;
    height: 48px;
    border-radius: 280px;
    align-items: center;
`;

export const StyledInput = styled.input`
    ${defaultInputStyles};
    height: 100%;
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 8px;
    color: ${baseColors.black};
    font-size: 12px;
    line-height: 18px;

    &::placeholder {
        color: ${grey[500]};
    }
`;
