import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { QuickSignalsInfo } from 'components/pages/quickSignals/QuickSignalsInfo';
import { QuickSignalsTabs } from 'components/pages/quickSignals/QuickSignalsTabs';
import React from 'react';

export const QuickSignals = () => (
    <NewMainLayout>
        <Section marginBottom="38px">
            <QuickSignalsInfo />
        </Section>
        <QuickSignalsTabs />
    </NewMainLayout>
);
