import { ErrorSmIcon, UploadSmIcon } from 'assets/icons';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { VisibilityWrapper } from 'components/grid/wrappers/VisibilityWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Button } from 'components/ui/buttons/Button';
import { Video } from 'components/ui/inputs/VideoInput/Video';
import { Text } from 'components/ui/typography/Text';
import { baseColors, grey } from 'constants/styles';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { convertBytesToMegabytes } from 'utils/convertBytesToMegabytes';
import { maxSizeVideoInMegaBytes } from './constants';
import { StyledColumn, StyledInput, Wrapper } from './styles';
import { checkVideoType } from './utils';

interface Props {
    isTopControlsVisible?: boolean;
    setFile: Dispatch<SetStateAction<File | null>>;
    size?: number;
    sizeHint?: string;
    durationHint?: string;
    resolutionHint?: string;
    minDuration?: number;
    maxDuration?: number;
    widthResolution?: number;
    heightResolution?: number;
}

export const VideoInput: FC<Props> = ({
    setFile,
    isTopControlsVisible = true,
    size = maxSizeVideoInMegaBytes,
    sizeHint = '1 GB',
    durationHint = '5-60 sec',
    resolutionHint = '1080×1920 px',
    minDuration,
    maxDuration,
    widthResolution,
    heightResolution
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [source, setSource] = useState<string | null>(null);
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsMetadataLoaded(false);
        setCurrentFile(null);
        setHasError(false);
        const file = event.target?.files?.[0];
        if (file) {
            if (checkVideoType(file.type) || convertBytesToMegabytes(file.size) <= size) {
                const url = URL.createObjectURL(file);
                setIsLoading(true);
                setSource(url);
                setCurrentFile(file);
            } else {
                setHasError(true);
            }
        }
        event.target.value = '';
    };

    const handleChoose = () => {
        if (inputRef?.current?.click) {
            inputRef.current.click();
        }
    };

    useEffect(() => {
        if (isMetadataLoaded) {
            setIsLoading(false);
            if (!hasError) {
                setFile(currentFile);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMetadataLoaded, hasError]);

    return (
        <Wrapper alignCenter justifyCenter>
            <StyledInput ref={inputRef} accept=".mov,.mp4" type="file" onChange={handleFileChange} />
            {!source && (
                <StyledColumn alignCenter>
                    {hasError ? (
                        <>
                            <MarginWrapper marginBottom="14px">
                                <ErrorSmIcon />
                            </MarginWrapper>
                            <Text alignTextCenter color={baseColors.red} size="-1">
                                The video does not match the requirements. Please upload again.
                            </Text>
                        </>
                    ) : (
                        <>
                            <MarginWrapper marginBottom="16px">
                                {isLoading ? <Spinner size={20} /> : <UploadSmIcon />}
                            </MarginWrapper>
                            <Text size="-1">Upload Video</Text>
                        </>
                    )}
                    <Column alignCenter marginBottom="18px" marginTop="22px">
                        <Text color={grey[500]} size="-2">
                            MP4 or Mov
                        </Text>
                        <Text color={grey[500]} size="-2">
                            {resolutionHint}
                        </Text>
                        <Text color={grey[500]} size="-2">
                            {durationHint}
                        </Text>
                        <Text color={grey[500]} size="-2">
                            less than {sizeHint}
                        </Text>
                    </Column>
                    {!isLoading && <Button onClick={handleChoose}>Select File</Button>}
                </StyledColumn>
            )}
            {source && (
                <VisibilityWrapper visible={isMetadataLoaded && !isLoading}>
                    <Video
                        editFile={handleChoose}
                        heightResolution={heightResolution}
                        isTopControlsVisible={isTopControlsVisible}
                        maxDuration={maxDuration}
                        minDuration={minDuration}
                        setFile={setFile}
                        setHasError={setHasError}
                        setIsMetadataLoaded={setIsMetadataLoaded}
                        setSource={setSource}
                        source={source}
                        widthResolution={widthResolution}
                    />
                </VisibilityWrapper>
            )}
        </Wrapper>
    );
};
