import { WrongLgIcon } from 'assets/icons';
import { notify } from 'components/ui/Toasts';
import { defaultCommentsQueryParams, defaultRepliesQueryParams } from 'constants/services/comments';
import { createEffect, createEvent, createStore, forward, sample } from 'effector';
import cloneDeep from 'lodash.clonedeep';
import { API } from 'services';
import { fullscreenVideoEvents, fullscreenVideoStores } from 'stores/fullscreen-video';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';

// Types

export interface CommentRepliesItem {
    data?: BULLZ.QueryPostsResponse;
    isLoading?: boolean;
}

export interface CommentReplies {
    [id: string]: CommentRepliesItem;
}
interface RepliesLoading {
    [id: string]: boolean;
}

interface CommentRepliesLoadingEvent {
    id: string;
    isLoading: boolean;
}

// Events

const setRepliesLoading = createEvent<CommentRepliesLoadingEvent>();
const clearStores = createEvent();
const resetReplies = createEvent();
const setComments = createEvent<BULLZ.QueryPostsResponse>();
const setReplies = createEvent<CommentReplies>();

// Effects

const getCommentsByVideoIdFx = createEffect({
    handler: async (id: string) =>
        await API.comments.getComments({
            ...defaultCommentsQueryParams,
            videoId: id
        })
});

const loadMoreCommentsByVideoIdFx = createEffect({
    handler: async (params: Required<Pick<BULLZ.QueryPostsRequest, 'pageIndex' | 'videoId'>>) =>
        await API.comments.getComments({
            ...defaultCommentsQueryParams,
            ...params
        })
});

const getRepliesByParentIdFx = createEffect({
    handler: async (postId: string) => {
        try {
            setRepliesLoading({
                id: postId,
                isLoading: true
            });

            const data = await API.comments.getReplies({
                ...defaultRepliesQueryParams,
                postId
            });

            return {
                id: postId,
                data
            };
        } catch {
            return {
                id: postId,
                data: {}
            };
        } finally {
            setRepliesLoading({
                id: postId,
                isLoading: false
            });
        }
    }
});

const loadMoreRepliesFx = createEffect({
    handler: async (params: Required<Pick<BULLZ.QueryRepliesRequest, 'pageIndex' | 'postId'>>) => {
        try {
            setRepliesLoading({
                id: params.postId,
                isLoading: true
            });

            const data = await API.comments.getReplies({
                ...defaultRepliesQueryParams,
                ...params
            });

            return {
                id: params.postId,
                data
            };
        } catch {
            return {
                id: params.postId,
                data: {}
            };
        } finally {
            setRepliesLoading({
                id: params.postId,
                isLoading: false
            });
        }
    }
});

interface DeleteCommentProps extends BULLZ.DeletePostRequest {
    parentId?: string;
}

const deleteCommentFx = createEffect({
    handler: async (data: DeleteCommentProps) =>
        await API.comments.deleteComment({
            postId: data.postId
        })
});

const addCommentFx = createEffect({
    handler: async (data: BULLZ.CreatePostRequest) =>
        await API.comments.addComment({
            ...data
        })
});

const addCommentFailFx = createEffect({
    handler: () =>
        infoModal.openModal({
            icon: WrongLgIcon,
            title: 'Action Blocked',
            text: `This action was blocked. Please try again later. We restrict certain actions to protect our community.`,
            buttonText: 'Ok, got it!',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        })
});

// Stores

const $currentVideoComments = createStore<BULLZ.QueryPostsResponse>({})
    .on(getCommentsByVideoIdFx.doneData, (_, payload) => payload)
    .on(loadMoreCommentsByVideoIdFx.doneData, (state, payload) => ({
        ...state,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .on(deleteCommentFx.done, (prevState, { params, result }) => {
        if (result.isSuccess && prevState.items) {
            notify('Comment deleted!');
            const newItems: BULLZ.GetRootPostResponse[] = prevState.items.filter(
                comment => comment.id !== params.postId
            );
            if (newItems.length === 0) {
                return {};
            }
            return { ...prevState, items: newItems };
        }
        return prevState;
    })
    .on(setComments, (_, newState) => newState)
    .reset(clearStores);

const $fetchingCommentsError = createStore<boolean>(false)
    .on(getCommentsByVideoIdFx.fail, () => true)
    .on(loadMoreCommentsByVideoIdFx.done, () => true)
    .reset([getCommentsByVideoIdFx.done, loadMoreCommentsByVideoIdFx.done, clearStores]);

const $replies = createStore<CommentReplies>({})
    .on(getRepliesByParentIdFx.doneData, (state, payload: { data: BULLZ.QueryPostsResponse; id: string }) => ({
        ...state,
        [payload.id]: {
            data: payload.data
        }
    }))
    .on(loadMoreRepliesFx.doneData, (state, payload: { data: BULLZ.QueryPostsResponse; id: string }) => ({
        ...state,
        [payload.id]: {
            data: {
                ...state[payload.id].data,
                ...payload.data,
                items: [...(state[payload.id].data?.items || []), ...(payload.data.items || [])]
            }
        }
    }))
    .on(deleteCommentFx.done, (prevState, { params, result }) => {
        if (params.parentId && result.isSuccess) {
            const newAllReplies = cloneDeep(prevState);
            const commentReplies = newAllReplies[params.parentId];
            if (commentReplies.data?.items) {
                const newItems = commentReplies.data?.items.filter(reply => reply.id !== params.postId);
                const newTotalRecords = newAllReplies[params.parentId].data?.totalRecords;
                newAllReplies[params.parentId] = {
                    ...newAllReplies[params.parentId],
                    data: {
                        ...newAllReplies[params.parentId].data,
                        items: newItems,
                        totalRecords: newTotalRecords !== undefined && newTotalRecords > 0 ? newTotalRecords - 1 : 0
                    }
                };
            }
            return newAllReplies;
        }
        return prevState;
    })
    .on(setReplies, (_, newState) => newState)
    .reset(resetReplies);

const $repliesLoading = createStore<RepliesLoading>({})
    .on(setRepliesLoading, (state, { id, isLoading }) => ({
        ...state,
        [id]: isLoading
    }))
    .reset(resetReplies);

sample({
    clock: addCommentFx.done,
    fn: ({ params }: { params: BULLZ.CreatePostRequest }) => params.videoId || '',
    target: getCommentsByVideoIdFx
});

forward({
    from: addCommentFx.fail,
    to: addCommentFailFx
});

sample({
    clock: addCommentFx.doneData,
    source: fullscreenVideoStores.$video,
    fn: (sourceData, response) => {
        if (response.isSuccess) {
            const newCountComments = (sourceData.engagement?.commentCount || 0) + 1;
            const source = {
                ...sourceData,
                engagement: {
                    ...sourceData.engagement,
                    commentCount: newCountComments
                }
            };
            return { ...source };
        }
        return sourceData;
    },
    target: fullscreenVideoEvents.updateVideo
});

sample({
    clock: deleteCommentFx.doneData,
    source: fullscreenVideoStores.$video,
    fn: (sourceData, response) => {
        if (response.isSuccess && sourceData.engagement?.commentCount && sourceData.engagement?.commentCount > 0) {
            const newCountComments = sourceData.engagement?.commentCount - 1;
            const source = {
                ...sourceData,
                engagement: {
                    ...sourceData.engagement,
                    commentCount: newCountComments
                }
            };
            return { ...source };
        }
        return sourceData;
    },
    target: fullscreenVideoEvents.updateVideo
});

// Exports

export const commentsEvents = {
    clearStores,
    resetReplies,
    setComments,
    setReplies
};

export const commentsStores = {
    $currentVideoComments,
    $fetchingCommentsError,
    $replies,
    $repliesLoading
};

export const commentsEffects = {
    getCommentsByVideoIdFx,
    loadMoreCommentsByVideoIdFx,
    getRepliesByParentIdFx,
    loadMoreRepliesFx,
    deleteCommentFx,
    addCommentFx
};
