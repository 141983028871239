import { Row } from 'components/grid/wrappers/FlexWrapper';
import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
    align-items: center;
    cursor: pointer;
`;

interface ImagesWrapperProps {
    count: number;
}

export const ImagesWrapper = styled.div<ImagesWrapperProps>`
    width: ${({ count }) => `${32 + (count - 1) * 24}px`};
    height: 32px;
    position: relative;
`;

interface AvatarWrapperProps {
    index: number;
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
    ${flexCenter};
    position: absolute;
    top: 0;
    left: ${({ index }) => `${index * 20}px`};
    z-index: ${({ index }) => index + 1};
`;
