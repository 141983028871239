import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { PlusBgMdIcon } from 'assets/icons';
import { notify } from 'components/ui/Toasts';
import { asyncErrorMessage } from 'constants/messages';
import { useStore } from 'effector-react';
import React, { ChangeEvent } from 'react';
import { authEffects, authStores } from 'stores/auth';
import { convertBytesToMegabytes } from 'utils/convertBytesToMegabytes';
import { Avatar, HiddenInput, IconWrapper, Label } from './styles';

const availableFormats = ['image/jpeg', 'image/png', 'image/webp'];
const maxFileSize = 64;

export const UploadAvatar = () => {
    const user = useStore(authStores.$user);
    const src = user?.profile?.profileImageUrl || defaultAvatar;

    const validateFile = (file: File) =>
        availableFormats.includes(file.type) && convertBytesToMegabytes(file.size) <= maxFileSize;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0 && user?.userId) {
            const avatar = e.target.files[0];
            const isValid = validateFile(avatar);

            if (isValid) {
                authEffects.uploadAvatarFx({ userId: user.userId, file: e.target.files[0] });
            } else {
                notify(asyncErrorMessage, 'danger');
            }
        }
    };

    return (
        <Label>
            <HiddenInput name="avatar" type="file" onChange={handleChange} />
            <Avatar src={src} />
            <IconWrapper>
                <PlusBgMdIcon height={36} width={36} />
            </IconWrapper>
        </Label>
    );
};
