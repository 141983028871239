import { ButtonWrapper, Wrapper } from 'components/pages/profile/MyProfileCards/styles';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Tabs } from 'components/ui/Tabs';
import { defaultCollaborationQueryParams } from 'constants/services/collaboration';
import { useStore } from 'effector-react';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { collaborationsEffects, collaborationsStores } from 'stores/collaborations';
import { userVideosStores } from 'stores/user-videos';
import { Id } from 'types';
import { MyCollaborations } from '../MyCollaborations';
import { MyProfileVideoCards } from '../MyProfileVideoCards';

enum Tab {
    Videos,
    Collaborations
}

const { $myCollaborations } = collaborationsStores;
const { $myUserVideos } = userVideosStores;
const { getMyCollaborationsFx } = collaborationsEffects;

export const MyProfileCards: FC<Id> = ({ id }) => {
    const history = useHistory();
    const { by } = queryString.parse(history.location.search);

    const [activeTab, setActiveTab] = useState(by === 'myCollaboration' ? Tab.Collaborations : Tab.Videos);
    const [isEditing, setIsEditing] = useState(false);

    const collaborations = useStore($myCollaborations);
    const { items } = useStore($myUserVideos);

    const isHaveButtonEdit = collaborations && collaborations.items?.length !== 0 && items && activeTab === Tab.Videos;

    const handleTabsChange = (value: Tab) => {
        setActiveTab(value);
    };

    const handleEditVideo = () => {
        setIsEditing(!isEditing);
    };

    useEffect(() => {
        if (id) {
            getMyCollaborationsFx({
                ...defaultCollaborationQueryParams,
                userId: id
            });
        }
    }, [id]);

    return (
        <Wrapper>
            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: Tab.Videos,
                        title: 'My Videos',
                        content: <MyProfileVideoCards id={id} isEdit={isEditing} />,
                        width: isMobile && isHaveButtonEdit ? '40%' : isMobile && !isHaveButtonEdit ? '50%' : '175px'
                    },
                    {
                        id: Tab.Collaborations,
                        title: 'My Collaborations',
                        content: <MyCollaborations id={id} />,
                        width: isMobile && isHaveButtonEdit ? '40%' : isMobile && !isHaveButtonEdit ? '50%' : '175px',
                        hidden: collaborations && collaborations.items?.length !== 0 ? false : true
                    }
                ]}
                onChange={handleTabsChange}
            />

            {activeTab === Tab.Videos && items?.length !== 0 && (
                <ButtonWrapper>
                    <TextButton buttonType="primary" onClick={handleEditVideo}>
                        {isEditing ? 'Done' : 'Edit'}
                    </TextButton>
                </ButtonWrapper>
            )}
        </Wrapper>
    );
};
