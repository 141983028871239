import { flexCenter, transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import styled, { css } from 'styled-components';

interface CardWrapperProps {
    hasClick?: boolean;
}

export const CardWrapper = styled.div<CardWrapperProps>`
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    transition: background-color ${transitionTime} linear;
    ${({ hasClick }) => hasClick && 'cursor: pointer'};

    ${({ hasClick }) =>
        hasClick &&
        css`
            &:hover {
                background-color: ${grey[800]};
            }
        `};
`;

interface ImageWrapperProps {
    src?: string;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
    width: 100%;
    height: 90px;
    background-image: ${({ src }) => `url(${src})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    ${flexCenter};
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: ${baseColors.white};
    position: relative;
    overflow: hidden;
`;

export const FailWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const AmountWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 0;
`;
