export const grey = {
    50: '#F7F9FA',
    100: '#F0F2F3',
    200: '#E7E8EA',
    300: '#D7D8DA',
    400: '#B3B4B6',
    500: '#939496',
    600: '#6B6C6D',
    700: '#57595A',
    800: '#393A3B',
    900: '#191A1B'
};

export const blue = {
    50: '#EBECFF',
    100: '#CCCEFF',
    200: '#A8AEFF',
    300: '#818DFF',
    400: '#6270FF',
    500: '#4353FF',
    600: '#3E49F3',
    700: '#343DE6',
    800: '#2B30DB',
    900: '#160FCA'
};

export const green = {
    50: '#E4FFEF',
    100: '#BEFFD6',
    200: '#8BFFBC',
    300: '#33FF9D',
    400: '#00FB81',
    500: '#00F56D',
    600: '#00E361',
    700: '#00CE53',
    800: '#00BC47',
    900: '#009A30'
};

export const orange = {
    50: '#FFF3E0',
    100: '#FFE0B3',
    200: '#FFCD81',
    300: '#FFB84F',
    400: '#FFA82A',
    500: '#FF990A',
    600: '#FF8D0A',
    700: '#F97D09',
    800: '#F36C09',
    900: '#EA5108'
};

export const red = {
    50: '#FFEAED',
    100: '#FFCBD0',
    200: '#FB9694',
    300: '#F46B6A',
    400: '#FF4343',
    500: '#FF2922',
    600: '#F61924',
    700: '#E4001E',
    800: '#D70016',
    900: '#C70006'
};

export const baseColors = {
    white: '#FFFFFF',
    black: '#000000',
    green: green['300'],
    red: red['400'],
    lightGrey: grey['700'],
    darkGrey: grey['900'],
    blue: blue['500']
};
