import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const SettingsWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: ${headerHeight};
    background-color: transparent;

    ${media.laptop(`
        padding: 30px 24px 24px;
        border-radius: 16px;
        width: 906px;
        height: auto;
        background-color: ${baseColors.darkGrey};
    `)};
`;
