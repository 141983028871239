import { WrongLgIcon } from 'assets/icons';
import { asyncErrorMessage } from 'constants/messages';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';

//Events

const resetStores = createEvent();

//Effects

export const getQuickSignalsInfoFx = createEffect({
    handler: async () => await API.quickSignals.getQuickSignalsInfo()
});

export const getQuickSignalsFollowersFx = createEffect({
    handler: async (id: string) => await API.quickSignals.getFollowersByChannelId(id)
});

export const getQuickSignalsVideosFx = createEffect({
    handler: async (id: string) => await API.quickSignals.getVideosByChannelId(id)
});

export const getQuickSignalsSubscriptionsFx = createEffect({
    handler: async () => await API.quickSignals.getQuickSignalSubscriptions({ limit: 3 })
});

export const getQuickSignalsChannelsFx = createEffect({
    handler: async (id: string) => {
        const response = await API.quickSignals.getQuickSignalUserChannels(id);

        return response.data;
    }
});

export const subscribeToQSChannelFx = createEffect({
    handler: async (channelId: string) => await API.quickSignals.subscribe(channelId)
});

export const leaveQSChannelFx = createEffect({
    handler: async (channelId: string) => await API.quickSignals.leaveChannel(channelId)
});

const leaveQSChannelFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

export const registerViewQsFx = createEffect({
    handler: async (id: string) =>
        await API.quickSignals.registerEvent({
            viewed: true,
            quickSignalId: id
        })
});

const createNewQsVideoFx = createEffect({
    handler: async (website: string) => await API.video.getNewVideoId({ isQuickSignal: true, productLink: website })
});

interface UploadQsVideoFxParams {
    videoUploadId: string;
    file: File;
}

const uploadQsVideoFx = createEffect({
    handler: async ({ videoUploadId, file }: UploadQsVideoFxParams) => await API.video.uploadVideo(videoUploadId, file)
});

//Stores

const $quickSignalsInfo = createStore<BULLZ.GetChannelOwnerInfoResponseListApiResponse>({})
    .on(getQuickSignalsInfoFx.doneData, (_, newState) => newState)
    .reset(resetStores);

const $quickSignalsInfoIsLoaded = createStore<boolean>(false)
    .on(getQuickSignalsInfoFx.finally, () => true)
    .reset(resetStores);

const $quickSignalsFollowers = createStore<BULLZ.GetChannelSubscribersResponseApiResponse>({})
    .on(getQuickSignalsFollowersFx.doneData, (_, newState) => newState)
    .reset(resetStores);

const $quickSignalsVideos = createStore<BULLZ.GetMyQuickSignalsResponseApiResponse>({})
    .on(getQuickSignalsVideosFx.doneData, (_, newState) => newState)
    .reset(resetStores);

const $quickSignalsSubscriptions = createStore<BULLZ.GetMySubscriptionsResponseApiResponse>({})
    .on(getQuickSignalsSubscriptionsFx.doneData, (_, newState) => newState)
    .reset(resetStores);

const $quickSignalsChannels = createStore<BULLZ.GetQuickSignalChannelsResponse>({})
    .on(getQuickSignalsChannelsFx.doneData, (_, newState) => newState)
    .reset([resetStores, getQuickSignalsChannelsFx.fail]);

//Exports

export const quickSignalsEvents = { resetStores };
export const quickSignalsEffects = {
    getQuickSignalsInfoFx,
    getQuickSignalsFollowersFx,
    getQuickSignalsVideosFx,
    getQuickSignalsSubscriptionsFx,
    getQuickSignalsChannelsFx,
    subscribeToQSChannelFx,
    registerViewQsFx,
    createNewQsVideoFx,
    uploadQsVideoFx,
    leaveQSChannelFx,
    leaveQSChannelFailFx
};
export const quickSignalsStores = {
    $quickSignalsInfo,
    $quickSignalsFollowers,
    $quickSignalsVideos,
    $quickSignalsSubscriptions,
    $quickSignalsChannels,
    $quickSignalsInfoIsLoaded
};
