import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';

// TODO replace mock data

interface Props {
    authenticity?: number;
    creativity?: number;
    positivity?: number;
}

export const Scores: FC<Props> = ({ authenticity = 0, creativity = 0, positivity = 0 }) => (
    <Section alignCenter justifyBetween>
        <Column alignCenter>
            <Row justifyCenter marginBottom="4px">
                <Text alignTextCenter size="1">
                    {authenticity.toFixed(1)}
                </Text>
            </Row>

            <Text alignTextCenter size="-2">
                Authenticity
            </Text>
        </Column>

        <Column alignCenter>
            <Row justifyCenter marginBottom="4px">
                <Text alignTextCenter size="1">
                    {creativity.toFixed(1)}
                </Text>
            </Row>

            <Text alignTextCenter size="-2">
                Creativity
            </Text>
        </Column>

        <Column alignCenter>
            <Row justifyCenter marginBottom="4px">
                <Text alignTextCenter size="1">
                    {positivity.toFixed(1)}
                </Text>
            </Row>

            <Text alignTextCenter size="-2">
                Positivity
            </Text>
        </Column>
    </Section>
);
