import { DeleteAccountLgIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Input } from 'components/ui/inputs/Input';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { authEffects, authEvents, authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { NoopType } from 'types';
import { noop } from 'utils/common';

interface Props {
    onCancel?: NoopType;
}

export const DeleteProfile: FC<Props> = ({ onCancel }) => {
    const [password, setPassword] = useState('');
    const error = useStore(authStores.$deletingError);

    const handlePasswordChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setPassword(target.value);
    };

    const deleteAccount = () => {
        authEffects.deleteAccountFx(password);
    };

    const openConfirmation = () => {
        infoModal.openModal({
            icon: DeleteAccountLgIcon,
            title: 'Are you sure?',
            text:
                'Deleting your account means you won`t be able to reactivate your account. Your profile and everything else you`ve added will be permanently deleted. You won`t be able to retrieve anything you`ve added.',
            cancelText: 'Continue',
            onCloseClick: deleteAccount,
            buttonText: 'Go Back',
            onClick: noop
        });
    };

    useEffect(
        () => () => {
            authEvents.setDeletingError('');
        },
        []
    );

    return (
        <Column noWrap height="100%" width="100%">
            <Section marginBottom="8px">
                <Text isBold size="1">
                    Enter Current Password
                </Text>
            </Section>
            <Section marginBottom="16px">
                <Text size="-2">Please enter the current password you use</Text>
            </Section>

            <Section marginBottom="16px">
                <Input
                    autoComplete="new-password"
                    error={error}
                    label="Password"
                    name="currentPassword"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                />
            </Section>

            <Section alignCenter justifyBetween>
                {onCancel && <TextButton onClick={onCancel}>Cancel</TextButton>}
                <Button disabled={!password} onClick={openConfirmation}>
                    Next
                </Button>
            </Section>
        </Column>
    );
};
