import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

// Types

interface OpenModalParams extends BULLZ.GetQuickSignalUserResponse {
    index: number;
}

// Events

const openQSModal = createEvent<OpenModalParams>();
const closeQSModal = createEvent();
const resetStores = createEvent();
const updateUsers = createEvent<BULLZ.GetQuickSignalUserResponse[]>();

// Effects

const getQSUsersFx = createEffect({
    handler: async () => {
        try {
            const response = await API.quickSignals.getQuickSignalUsers();

            // @ts-ignore
            return response.data.items || [];
        } catch {
            return [];
        }
    }
});

const getUserQSVideosFx = createEffect({
    handler: async (userId: string) => {
        try {
            const response = await API.quickSignals.getQuickSignalUserVideos(userId);

            // @ts-ignore
            return response.data || [];
        } catch {
            return [];
        }
    }
});

const registerViewFx = createEffect({
    handler: async (
        data: Pick<BULLZ.RegisterAggregatedEventRequest, 'videoId' | 'sessionId' | 'viewDurationPercentage'>
    ) => API.video.registerVideoAsViewed({ ...data, viewed: true })
});

// Stores

const $currentQSUser = createStore<OpenModalParams | null>(null)
    .on(openQSModal, (_, data) => data)
    .reset(closeQSModal);

const $isVisibleQSModal = createStore(false)
    .on(openQSModal, () => true)
    .reset(closeQSModal);

const $currentUserData = createStore<BULLZ.GetQuickSignalUserVideosResponse | null>(null).on(
    getUserQSVideosFx.doneData,
    (_, data) => data
);

const $qsUsers = createStore<BULLZ.GetQuickSignalUserResponse[]>([]).on(
    [getQSUsersFx.doneData, updateUsers],
    (_, data) => data
);

// Exports

export const qsEvents = {
    openQSModal,
    closeQSModal,
    resetStores,
    updateUsers
};

export const qsEffects = {
    getUserQSVideosFx,
    registerViewFx,
    getQSUsersFx
};

export const qsStores = {
    $qsUsers,
    $currentQSUser,
    $currentUserData,
    $isVisibleQSModal
};
