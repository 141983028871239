import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const VideoCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 8px;
    width: 100%;

    ${media.laptop(`
        grid-template-columns: repeat(4, 1fr);
        row-gap: 16px;
        column-gap: 26px;
    `)};
`;

export const Empty = styled(Column)`
    ${flexCenter};
    width: 100%;
    height: calc(100vh - 2 * ${headerHeight} - 146px);
    border-radius: 8px;
    background-color: ${baseColors.darkGrey};

    ${media.laptop(`
        height: 100%;
        background-color: transparent;
    `)};
`;
