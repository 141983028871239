export const getVideoWithNewEngagements = (
    video: BULLZ.PlaylistSingleVideoResponse | BULLZ.PlaylistVideoResponse,
    params: BULLZ.RegisterAggregatedEventRequest
) => {
    let likes = video.engagement?.likes;

    if (video.engagement?.likes !== undefined) {
        if (params.liked === true) {
            likes = video.engagement?.likes + 1;
        } else if (params.liked === false) {
            likes = video.engagement?.likes - 1;
        }
    }

    let sharesCount = video.engagement?.shares || 0;

    if (video.engagement?.shares !== undefined && params.shared === true) {
        sharesCount = sharesCount + 1;
    }

    return {
        ...video,
        engagement: {
            ...video.engagement,
            likes,
            shares: sharesCount
        },
        engagementUser: {
            ...video.engagementUser,
            isLiked: params.liked ?? video.engagementUser?.isLiked,
            isSaved: params.saved ?? video.engagementUser?.isSaved
        }
    };
};
