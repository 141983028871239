import { flexCenter } from 'constants/styles';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

interface PlayerWrapperProps {
    hasClick?: boolean;
    isCover?: boolean;
}

export const PlayerWrapper = styled.div<PlayerWrapperProps>`
    width: 100%;
    height: 100%;
    ${flexCenter};
    ${({ hasClick }) => hasClick && 'cursor: pointer'};
    ${({ isCover }) =>
        isCover &&
        css`
            position: relative;

            video {
                object-fit: cover;
                height: auto !important;
            }
        `};
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

interface ControlsWrapperProps {
    isFullscreen?: boolean;
}

export const ControlsWrapper = styled.div<ControlsWrapperProps>`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: ${({ isFullscreen }) => (isFullscreen ? 'row' : 'column-reverse')};
    flex-wrap: nowrap;
    align-items: ${({ isFullscreen }) => (isFullscreen ? 'center' : 'stretch')};
    justify-content: space-between;
    padding: ${({ isFullscreen }) => (isFullscreen ? '16px' : '12px 8px')};
    z-index: 1;
`;
