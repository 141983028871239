import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

export const defaultUserHashtagsQueryParams: BULLZ.QueryFollowedHashTagsRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};

// Events

const resetUserHashtags = createEvent();

// Effects

const followHashtagFx = createEffect({
    handler: async (data: BULLZ.UserFollowHashTagRequest) =>
        await API.userHashtag.followHashtag({
            ...data
        })
});

const handleFollowHashtagFx = createEffect({
    handler: async (data: BULLZ.UserFollowHashTagRequest) =>
        await API.userHashtag.toFollowHashtag({
            ...data
        })
});

const getUserHashtagsFx = createEffect({
    handler: async () => await API.userHashtag.getUserHashtags({ ...defaultUserHashtagsQueryParams })
});

const loadMoreUserHashtagsFx = createEffect({
    handler: async (params: Required<Pick<BULLZ.QueryFollowedHashTagsRequest, 'pageIndex'>>) =>
        await API.userHashtag.getUserHashtags({
            ...defaultUserHashtagsQueryParams,
            ...params
        })
});

// Stores

const $userHashtags = createStore<BULLZ.QueryFollowingHashTagsResponse>({})
    .on(getUserHashtagsFx.doneData, (_, payload) => payload)
    .on(loadMoreUserHashtagsFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetUserHashtags);

export const userHashtagEffects = {
    followHashtagFx,
    getUserHashtagsFx,
    loadMoreUserHashtagsFx,
    handleFollowHashtagFx
};

export const userHashtagStores = {
    $userHashtags
};

export const userHashtagEvents = {
    resetUserHashtags
};
