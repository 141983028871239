import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { VideoSlide } from 'components/video/VideoSlide';
import React, { FC, useEffect, useMemo, useState } from 'react';
import SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NoopType } from 'types';
import { PlaylistValues } from '../../types';
import { SliderWrapper, swiperStyles } from './styles';

interface Props {
    currentVideo: BULLZ.PlaylistSingleVideoResponse;
    isLoading?: boolean;
    playlist: PlaylistValues;
    onSlidePrev?: NoopType;
    onSlideNext?: NoopType;
    onClose?: NoopType;
}

const Loader = () => (
    <Column alignCenter justifyCenter height="100%" width="100%">
        <Spinner />
    </Column>
);

export const MobileSlider: FC<Props> = ({ currentVideo, isLoading, playlist, onSlidePrev, onSlideNext, onClose }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [swiper, setSwiper] = useState<SwiperType | null>(null);

    const hasPrevVideos = useMemo(() => !!playlist && playlist.prev.length > 0, [playlist]);
    const hasNextVideos = useMemo(() => !!playlist && playlist.next.length > 0, [playlist]);

    const handleSlideChange = (swiper: SwiperType) => {
        if (swiper.activeIndex > activeIndex) {
            onSlideNext?.();
        }

        if (swiper.activeIndex < activeIndex) {
            onSlidePrev?.();
        }
    };

    useEffect(() => {
        if (swiper) {
            if (hasPrevVideos) {
                swiper.slideTo(1, 0, false);
                setActiveIndex(1);
            } else {
                setActiveIndex(0);
            }
        }
    }, [hasPrevVideos, swiper]);

    return (
        <SliderWrapper>
            <Swiper
                direction="vertical"
                slidesPerView={1}
                style={swiperStyles}
                onSlideChangeTransitionEnd={handleSlideChange}
                onSwiper={swiper => setSwiper(swiper)}
            >
                {hasPrevVideos && (
                    <SwiperSlide style={swiperStyles}>
                        <Loader />
                    </SwiperSlide>
                )}

                <SwiperSlide style={swiperStyles}>
                    {isLoading ? <Loader /> : <VideoSlide isActive video={currentVideo} onClose={onClose} />}
                </SwiperSlide>

                {hasNextVideos && (
                    <SwiperSlide style={swiperStyles}>
                        <Loader />
                    </SwiperSlide>
                )}
            </Swiper>
        </SliderWrapper>
    );
};
