import { sample } from 'effector';
import { authStores } from 'stores/auth';
import { fullscreenVideoEffects } from 'stores/fullscreen-video';

const { getVideoByIdFx, getVideoValidationFx } = fullscreenVideoEffects;

sample({
    clock: getVideoByIdFx.doneData,
    source: authStores.$user,
    fn: (sourceData, response) => {
        const creatorId = response.ownerId;
        const videoId = response.id;

        if (!!videoId && !!creatorId && !!sourceData && sourceData.userId === creatorId) {
            return videoId;
        }

        return '';
    },
    target: getVideoValidationFx
});
