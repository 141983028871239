import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { bucmApiVersion, bucmURL, lsAnonymousUserKey, lsTokenKey } from 'constants/global';

const bucmAxiosInstance = axios.create({
    baseURL: bucmURL,
    method: 'POST'
});

bucmAxiosInstance.interceptors.response.use(config => config.data);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const tokenFromLS = JSON.parse(localStorage.getItem(lsTokenKey) || '') as string;
    const { token: anonymousTokenFromLS } = JSON.parse(
        localStorage.getItem(lsAnonymousUserKey) || ''
    ) as BUCM.UserJwtTokenResponse;

    const headers: any = {};

    if (withToken) {
        headers['Authorization'] = `Bearer ${tokenFromLS || anonymousTokenFromLS}`;
    }

    headers['api-version'] = bucmApiVersion;

    const request: AxiosPromise<T> = bucmAxiosInstance({
        ...config,
        headers: { ...config.headers, ...headers }
    });

    return (request as any) as Promise<T>;
};
