import { ThreeDotsSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { notify } from 'components/ui/Toasts';
import { CommentBody } from 'components/video/FullscreenVideo/components/Comments/CommentBody';
import { CommentHead } from 'components/video/FullscreenVideo/components/Comments/CommentHead';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import copy from 'copy-to-clipboard';
import { useStore } from 'effector-react';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { commentsEffects } from 'stores/comments';
import { loginModal, reportCommentModal } from 'stores/initialize.modal';

interface Props {
    currentComment: BULLZ.GetRootPostResponse;
    setNewComment: Dispatch<SetStateAction<string>>;
    setReplyingComment: Dispatch<SetStateAction<BULLZ.GetRootPostResponse | undefined>>;
}

export const CommentBase: FC<Props> = ({ currentComment, setNewComment, setReplyingComment }) => {
    const history = useHistory();
    const user = useStore(authStores.$user);
    const isAuth = useStore(authStores.$isAuth);

    const getCommentOptions = () => {
        const baseOptions = [
            {
                id: 0,
                title: 'Copy',
                action: () => {
                    const copyResult = copy(currentComment.body || '');
                    if (copyResult) notify('Comment Copied');
                }
            }
        ];
        if (currentComment.deep === 0) {
            baseOptions.push({
                id: 1,
                title: 'Reply',
                action: () => {
                    if (isAuth) {
                        setNewComment('');
                        setReplyingComment(currentComment);
                    } else {
                        loginModal.openModal();
                    }
                }
            });
        }
        if (user?.userId === currentComment.userId) {
            baseOptions.push({
                id: 2,
                title: 'Delete',
                action: () => {
                    commentsEffects.deleteCommentFx({ postId: currentComment.id, parentId: currentComment.parentId });
                }
            });
        } else {
            baseOptions.push({
                id: 3,
                title: 'Visit profile',
                action: () => {
                    history.push(`${AppRoute.Profile}/${currentComment.userId}`);
                }
            });
            baseOptions.push({
                id: 4,
                title: 'Report',
                action: () => {
                    if (isAuth && user?.userId !== currentComment.userId) {
                        reportCommentModal.openModal({
                            commentId: currentComment.id,
                            parentId: currentComment.parentId
                        });
                    } else {
                        loginModal.openModal();
                    }
                }
            });
        }
        return baseOptions;
    };

    return (
        <>
            <Section alignCenter justifyBetween>
                <CommentHead {...currentComment} />

                <OptionsPopover items={getCommentOptions()}>
                    <IconButton color={grey['500']}>
                        <ThreeDotsSmIcon />
                    </IconButton>
                </OptionsPopover>
            </Section>
            <CommentBody {...currentComment} />
        </>
    );
};
