import { InfoSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { GiftStatus } from 'components/pages/gifts/types';
import { AmountWrapper, CardWrapper, FailWrapper, ImageWrapper } from 'components/ui/GiftCard/styles';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles';
import React, { FC } from 'react';
import { NoopClick } from 'types/interfaces/global';

interface Props extends NoopClick {
    src?: string;
    title: string;
    status?: GiftStatus;
    amount?: number;
}

export const GiftCard: FC<Props> = ({ src, title, amount, onClick, status = GiftStatus.None }) => (
    <CardWrapper hasClick={status === GiftStatus.InProcess ? false : !!onClick} onClick={onClick}>
        <ImageWrapper src={src}>
            {(status === GiftStatus.Failure || status === GiftStatus.InProcess) && (
                <FailWrapper>
                    <Column alignCenter justifyCenter height="100%">
                        <MarginWrapper marginBottom="3px">
                            <InfoSmIcon height="18px" width="18px" />
                        </MarginWrapper>
                        <Text alignTextCenter size="-2">
                            {status === GiftStatus.Failure ? 'Gift card purchase failed' : 'Pending'}
                        </Text>
                    </Column>
                </FailWrapper>
            )}
            {amount && (
                <AmountWrapper>
                    <Text color={baseColors.black} size="-2">
                        ${amount}
                    </Text>
                </AmountWrapper>
            )}
        </ImageWrapper>

        <Section justifyCenter>
            <Text alignTextCenter size="-2">
                {title}
            </Text>
        </Section>
    </CardWrapper>
);
