import { createGlobalStyle } from 'styled-components';
import { baseColors } from './colors';

export const transitionTime = '0.3s';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: ${baseColors.black};
    color: ${baseColors.white}
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: ${baseColors.white}
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: ${baseColors.white};
  }

  ::-webkit-scrollbar-track
   {
    background-color: transparent;
   }

   ::-webkit-scrollbar
   {
    width: 1px;
    height: 1px;
    background-color: transparent;
   }

   ::-webkit-scrollbar-thumb
   {
    background-color: transparent;
   }
`;
