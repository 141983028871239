import { CopySmIcon } from 'assets/icons';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconWrapper, TextWrapper, Wrapper } from './styles';

interface Props {
    copiedText: string;
    text?: string;
    successMessage?: string;
    isDark?: boolean;
    textColor?: string;
}

export const CopiedBox: FC<Props> = ({
    copiedText,
    text,
    successMessage,
    isDark = false,
    textColor = baseColors.white
}) => {
    const handleCopy = (_text: string, result: boolean) => {
        if (result && successMessage) {
            notify(successMessage);
        }
    };

    return (
        <CopyToClipboard text={copiedText} onCopy={handleCopy}>
            <Wrapper isDark={isDark}>
                <TextWrapper>
                    <Text color={textColor} size="-1">
                        {text || copiedText}
                    </Text>
                </TextWrapper>

                <IconWrapper>
                    <CopySmIcon height={15} width={15} />
                </IconWrapper>
            </Wrapper>
        </CopyToClipboard>
    );
};
