import { DescriptionSignalsSmIcon, InfoSmIcon, PriceWomSmIcon, QuickSignalSmIcon } from 'assets/icons';
import { InputField } from 'components/form/InputField';
import { TextareaField } from 'components/form/TextareaField';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Label } from 'components/pages/quickSignals/create/Label';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { qsChannelTitleRegExp } from 'constants/regExp';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { FC, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { infoModal } from 'stores/initialize.modal';
import { qsChannelEffects, qsChannelStores } from 'stores/quick-signals-channel';
import { walletDataStores } from 'stores/wallet-data';

interface Props {
    isValid: boolean;
    isDirty: boolean;
}

export const CreateForm: FC<Props> = ({ isValid, isDirty }) => {
    const ratesData = useStore(walletDataStores.$womRates);
    const usdRate = ratesData.rates?.find(item => item.assetName?.toLowerCase() === 'usd');
    const titleIsAvailable = useStore(qsChannelStores.$titleIsAvailable);
    const titleIsAvailableIsLoading = useStore(qsChannelEffects.checkTitleFx.pending);

    const [, { value: titleValue }] = useField<string>('title');
    const [, { value: descriptionValue }] = useField<string>('description');

    const titleChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '3 to 30 characters',
                isSuccess: titleValue.length >= 3 && titleValue.length <= 30
            },
            {
                title: 'Title is available',
                isSuccess: titleIsAvailable,
                isLoading: titleIsAvailableIsLoading
            },
            {
                title: 'Characters allowed: - _& . 0-9A-z',
                isSuccess: qsChannelTitleRegExp.test(titleValue)
            }
        ],
        [titleIsAvailable, titleIsAvailableIsLoading, titleValue]
    );

    const descriptionChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: 'From 10 to 200 characters',
                isSuccess: descriptionValue.length >= 10 && descriptionValue.length <= 200
            }
        ],
        [descriptionValue]
    );

    const handleInfoClick = () => {
        infoModal.openModal({
            title: 'BULLZ Fee',
            text: 'This helps us run BULLZ and continually provide you with ways to earn.',
            buttonText: 'Okay'
        });
    };

    return (
        <Column noWrap width="100%">
            <Column marginBottom="24px" width="100%">
                <Section marginBottom="16px">
                    <InputField
                        wide
                        labelComponent={<Label icon={<QuickSignalSmIcon />} title="Title" />}
                        name="title"
                        placeholder="e.g. *name*’s Quick Signals Channel"
                    />
                </Section>

                <RulesChecklist items={titleChecklist} />
            </Column>

            <Column marginBottom="24px" width="100%">
                <Section marginBottom="8px">
                    <InputField
                        wide
                        labelComponent={<Label icon={<PriceWomSmIcon />} title="WOM PER MONTH PRICE" />}
                        name="price"
                        placeholder="e.g. 50"
                        type="number"
                    />
                </Section>

                <Section alignCenter marginBottom="8px">
                    <Column marginRight="8px">
                        <Text size="-2">BULLZ Fee 20%</Text>
                    </Column>

                    <IconButton color={grey[500]} onClick={handleInfoClick}>
                        <InfoSmIcon />
                    </IconButton>
                </Section>

                {usdRate && usdRate.price && (
                    <Section>
                        <Text size="-2">Current exchange rate: ${usdRate.price.toFixed(4)} USD = 1 WOM</Text>
                    </Section>
                )}
            </Column>

            <Column marginBottom="24px" width="100%">
                <Section marginBottom="16px">
                    <TextareaField
                        wide
                        labelComponent={<Label icon={<DescriptionSignalsSmIcon />} title="Description" />}
                        name="description"
                        placeholder="Add Description"
                    />
                </Section>

                <RulesChecklist items={descriptionChecklist} />
            </Column>

            <Button disabled={!isValid || !isDirty} type="submit" wide={isMobile}>
                Submit
            </Button>
        </Column>
    );
};
