import { BackArrowSmIcon, CloseSmIcon, DeleteAccountLgIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { infoModal, uploadQsModal } from 'stores/initialize.modal';
import { noop } from 'utils/common';
import { UploadQsForm } from './Form';

const { modal, closeModal } = uploadQsModal;

export const UploadQsModal = () => {
    const [visible] = useStore(modal);
    const [hasFile, setHasFile] = useState(false);

    const close = () => {
        if (hasFile) {
            infoModal.openModal({
                icon: DeleteAccountLgIcon,
                title: 'Are you sure?',
                text: 'You have unsaved data. Your unsaved data will be lost. Are you sure you want to quit this page?',
                buttonText: 'Go Back',
                cancelText: 'Yes, I am sure',
                onClick: noop,
                onCloseClick: () => closeModal()
            });
        } else {
            closeModal();
        }
    };

    return (
        <ModalWrapper fullscreenMobile size="large" visible={visible} onClose={close}>
            <Section alignCenter justifyBetween={!isMobile} marginBottom="24px">
                <IconButton onClick={close}>
                    <BackArrowSmIcon />
                </IconButton>

                {isMobile ? (
                    <Row marginLeft="8px">
                        <Text alignTextCenter isBold size="1">
                            Uploading Quick Signals
                        </Text>
                    </Row>
                ) : (
                    <Title alignTextCenter size="4">
                        Upload Quick Signal
                    </Title>
                )}

                {!isMobile && (
                    <IconButton onClick={close}>
                        <CloseSmIcon />
                    </IconButton>
                )}
            </Section>

            <UploadQsForm setHasFile={setHasFile} onClose={close} />
        </ModalWrapper>
    );
};
