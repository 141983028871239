import { WrongLgIcon } from 'assets/icons';
import { createEffect } from 'effector';
import { API } from 'services';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk } from 'utils/common';

// Effects

interface ReportProps extends BULLZ.ReportPostRequest {
    parentId?: string;
}

const reportCommentFx = createEffect({
    handler: async ({ commentId, reportedReason, parentId }: ReportProps) => {
        const result = await API.comments.reportComment({
            reportedReason,
            commentId
        });
        return { ...result, parentId };
    }
});

interface ResultProps extends BULLZ.MessageResponseBase {
    parentId?: string;
}

const reportCommentDoneFx = createEffect({
    handler: ({ result, params }: { result: ResultProps; params: BULLZ.ReportPostRequest }) => {
        if (result.isSuccess) {
            infoModal.openModal({
                title: `Reported Successfully`,
                text: `You have successfully reported this comment.`,
                buttonText: 'Okay'
            });
        }
        return { commentId: params.commentId, parentId: result.parentId };
    }
});

const reportCommentFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: 'Something went wrong',
            text: `There was a problem reporting this comment. Please try again or contact our support team.`,
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onCloseClick: goToHelpDesk
        });
    }
});

// Exports

export const reportCommentsEffects = {
    reportCommentDoneFx,
    reportCommentFailFx,
    reportCommentFx
};
