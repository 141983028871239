import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(Section)`
    padding: 8px 10px;
    margin-bottom: 8px;

    ${media.laptop(`
        width: 420px;
        margin-bottom: 16px;
    `)};
`;
