import { BackArrowSmIcon } from 'assets/icons';
import { FlexGrow, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalContent, StyledSwiper } from 'components/modals/WalletGuideModal/MobileModal/styles';
import { Button } from 'components/ui/buttons/Button';
import { useStore } from 'effector-react';
import React, { ReactNode, useState } from 'react';
import { walletGuideModal } from 'stores/initialize.modal';
import { walletEffects, walletStores } from 'stores/wallet';
import Swiper, { Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { Slide } from '../constants';
import { IconWrapper } from '../styles';
import { SlideOne, SlideThree, SlideTwo } from './Slides';

const { modal, closeModal } = walletGuideModal;
const { createWomWalletFx } = walletEffects;
const { $hasWallet, $womUserIsLoaded } = walletStores;

export const MobileModal = () => {
    const [visible] = useStore(modal);
    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const hasWallet = useStore($hasWallet);
    const womUserIsLoaded = useStore($womUserIsLoaded);

    const slides: Record<Slide, ReactNode> = {
        [Slide.First]: <SlideOne />,
        [Slide.Second]: <SlideTwo />,
        [Slide.Third]: <SlideThree />
    };

    const handleBackClick = () => {
        if (activeIndex === Slide.First) {
            closeModal();
        } else {
            swiper && swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (activeIndex === Slide.Third) {
            createWomWalletFx();
        } else {
            swiper && swiper.slideNext();
        }
    };

    if (!womUserIsLoaded) {
        return null;
    }

    if (visible && !hasWallet) {
        return (
            <ModalContent>
                <Section>
                    <IconWrapper onClick={handleBackClick}>
                        <BackArrowSmIcon />
                    </IconWrapper>
                </Section>
                <FlexGrow flexDirection="row" marginBottom="24px" width="100%">
                    <StyledSwiper
                        className="guide-mobile-swiper"
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                        onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
                        onSwiper={swiper => setSwiper(swiper)}
                    >
                        {Object.entries(slides).map(([key, slide]) => (
                            <SwiperSlide key={key}>{slide}</SwiperSlide>
                        ))}
                    </StyledSwiper>
                </FlexGrow>
                <Button wide onClick={handleNextClick}>
                    {activeIndex === Slide.Third ? 'Create wallet' : 'Next'}
                </Button>
            </ModalContent>
        );
    }

    return null;
};
