import { IconWrapper, StyledClickableWrapper } from 'components/pages/wallet/ClickableCard/styles';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { NoopType } from 'types';

interface Props {
    title: string;
    icon: FC;
    onClick: NoopType;
}

export const ClickableCard: FC<Props> = ({ title, icon: Icon, onClick }) => (
    <StyledClickableWrapper type="button" onClick={onClick}>
        <IconWrapper>
            <Icon />
        </IconWrapper>
        <Text size="-2">{title}</Text>
    </StyledClickableWrapper>
);
