import { FormWrapper } from 'components/form/styles';
import { Form } from 'components/modals/CreateOrEditTeamModal/components/Form';
import {
    createTeamInitialValues,
    createTeamSchema,
    TeamNameFormValues
} from 'components/modals/CreateOrEditTeamModal/components/InputName/validation';
import { Button } from 'components/ui/buttons/Button';
import { Formik, FormikProps } from 'formik';
import React, { FC } from 'react';

interface Props {
    handleChangeTeam: (teamName: TeamNameFormValues) => void;
    isLoading?: boolean;
    data?: BULLZ.CreateTeamResponse;
    isEdit?: boolean;
}

export const InputName: FC<Props> = ({ handleChangeTeam, isLoading, data, isEdit }) => {
    const editTeamInitialValues: TeamNameFormValues = {
        teamName: data?.name || ''
    };

    if (data && isEdit) {
        return (
            <Formik
                initialValues={editTeamInitialValues}
                validationSchema={createTeamSchema}
                onSubmit={handleChangeTeam}
            >
                {({ handleSubmit, isValid, dirty }: FormikProps<TeamNameFormValues>) => (
                    <FormWrapper onSubmit={handleSubmit}>
                        <Form />

                        <Button wide disabled={!isValid || !dirty || isLoading} type="submit">
                            Save Team
                        </Button>
                    </FormWrapper>
                )}
            </Formik>
        );
    }

    return (
        <Formik initialValues={createTeamInitialValues} validationSchema={createTeamSchema} onSubmit={handleChangeTeam}>
            {({ handleSubmit, isValid, dirty }: FormikProps<TeamNameFormValues>) => (
                <FormWrapper onSubmit={handleSubmit}>
                    <Form />

                    <Button wide disabled={!isValid || !dirty || isLoading} type="submit">
                        Create Team
                    </Button>
                </FormWrapper>
            )}
        </Formik>
    );
};
