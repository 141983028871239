import { getExpiredDate } from 'components/cards/ValidatePreviewVideoCard/ValidationStatusButton/utils';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Alert } from 'components/ui/Alert';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React, { FC, useState } from 'react';
import Countdown from 'react-countdown';
import { videoEffects } from 'stores/video';
import { Earnings } from '../Earnings';
import { Stats } from '../Stats';

const { getValidVideoFx } = videoEffects;

interface Props {
    data: BULLZ.GetVideoDetailsResponse | null;
}

export const ValidateProcessContent: FC<Props> = ({ data }) => {
    const [visibleTimer, setVisibleTimer] = useState(true);

    const onCompletedWomValidation = () => {
        if (data?.id) {
            getValidVideoFx({ videoId: data.id });
            setVisibleTimer(false);
        }
    };

    if (!data) return null;

    return (
        <Column noWrap width="100%">
            <Section marginBottom="24px">
                <Earnings />
            </Section>

            <Column noWrap marginBottom="24px" width="100%">
                <Section marginBottom="8px">
                    <Text isBold size="-1">
                        WOM Authentication
                    </Text>
                </Section>
                <Section>
                    <Text color={grey[500]} size="-1">
                        Your video is currently being rated. If your video passes, you can start earning WOM rewards.
                    </Text>
                </Section>
            </Column>

            <Section marginBottom="24px">
                <Stats data={data.engagementStatistics} />
            </Section>

            <Section marginBottom="24px">
                <Alert type="dark">
                    The timer indicates the remaining time before your video completes authentication.
                </Alert>
            </Section>

            {data?.validation?.wom?.utcValidationExpired && visibleTimer && (
                <Section>
                    <Button disabled wide>
                        <Countdown
                            date={getExpiredDate(data.validation.wom.utcValidationExpired)}
                            onComplete={onCompletedWomValidation}
                        />
                    </Button>
                </Section>
            )}
        </Column>
    );
};
