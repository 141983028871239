import { LimitPageIndex } from 'types/interfaces/data';
import axios from './axios';

export const getQuickSignalUsers = () =>
    axios<BULLZ.GetQuickSignalUsersResponse>({
        url: '/quick-signals/users',
        method: 'GET'
    });

// TODO check type
export const getQuickSignalUserVideos = (userId: string) =>
    axios<BULLZ.GetQuickSignalUserVideosResponse>({
        url: `/quick-signals/users/${userId}/videos`,
        method: 'GET'
    });

export const getQuickSignalUserChannels = (userId: string) =>
    axios<BULLZ.GetQuickSignalChannelsResponseApiResponse>({
        url: `/quick-signals/users/${userId}/channels`,
        method: 'GET'
    });

export const getQuickSignalsInfo = () =>
    axios<BULLZ.GetChannelOwnerInfoResponseListApiResponse>({
        url: '/quick-signals/users/me/channels',
        method: 'GET'
    });

export const getFollowersByChannelId = (channelId: string) =>
    axios<BULLZ.GetChannelSubscribersResponseApiResponse>({
        url: `/quick-signals/users/me/subscribers/${channelId}`,
        method: 'GET'
    });

export const getVideosByChannelId = (channelId: string) =>
    axios<BULLZ.GetMyQuickSignalsResponseApiResponse>({
        url: `/quick-signals/users/me/${channelId}/videos`,
        method: 'GET'
    });

export const getQuickSignalSubscriptions = (params: LimitPageIndex) =>
    axios<BULLZ.GetMySubscriptionsResponseApiResponse>({
        url: '/quick-signals/subscriptions',
        method: 'GET',
        params
    });

export const subscribe = (channelId: string) =>
    axios<BULLZ.SubscribeChannelResponseApiResponse>({
        url: `/quick-signals/subscriptions/${channelId}`
    });

export const getChannel = (channelId: string) =>
    axios<BULLZ.GetMyQuickSignalSubscriptionInfoResponseApiResponse>({
        url: `/quick-signals/subscriptions/${channelId}`,
        method: 'GET'
    });

export const leaveChannel = (channelId: string) =>
    axios<BULLZ.UnsubscribeChannelResponseApiResponse>({
        url: `/quick-signals/subscriptions/${channelId}`,
        method: 'DELETE'
    });

export const registerEvent = (data: BULLZ.QuickSignalEngagementEventRequest) =>
    axios<BULLZ.QuickSignalEngagementEventResponseApiResponse>({
        url: '/quick-signals/register-event',
        data
    });

export const checkTitle = (data: BULLZ.ValidateQuickSignalChannelTitleRequest) =>
    axios<BULLZ.BooleanApiResponse>({
        url: '/quick-signals/users/me/channels/validate-title',
        data
    });

export const createChannel = (data: BULLZ.CreateQuickSignalChannelRequest) =>
    axios<BULLZ.ObjectIdApiResponse>({
        url: '/quick-signals/users/me/channels',
        data
    });

export const editChannel = (channelId: string, data: BULLZ.UpdateQuickSignalChannelRequest) =>
    axios<BULLZ.BooleanApiResponse>({
        method: 'PUT',
        url: `/quick-signals/users/me/channels/${channelId}`,
        data
    });
