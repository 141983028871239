import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { PropertyGrid } from 'components/modals/GiftDetailsModal/styles';
import { PropertyBlock } from 'components/pages/gifts/PropertyBlock';
import { totalCurrency } from 'components/pages/wallet/utils';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { GiftCard } from 'components/ui/GiftCard';
import { Input } from 'components/ui/inputs/Input';
import { Select } from 'components/ui/Select';
import { SelectOption, SelectOptionValue } from 'components/ui/Select/types';
import { Text } from 'components/ui/typography/Text';
import { walletAccountType } from 'constants/global';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { giftsEvents } from 'stores/gifts';
import { walletDataEffects, walletDataStores } from 'stores/wallet-data';
import { NoopType } from 'types';
import { SuffixWrapper } from './styles';

const { openHowToUseModal } = giftsEvents;

interface Props {
    data: BULLZ.BrandVoucherResponse;
    value: number;
    onChange: (value: number) => void;
    onNextStep: NoopType;
}

export const DenominationStep: FC<Props> = ({ data, value, onNextStep, onChange }) => {
    const {
        productImage,
        brandName,
        countryName,
        expiryAndValidity,
        howToUse,
        denominations,
        currency,
        foreignDenominations,
        valueRestrictions
    } = data;

    const { items: walletItems } = useStore(walletDataStores.$walletInfo);
    const { rates } = useStore(walletDataStores.$womRates);
    const walletIsLoading = useStore(walletDataEffects.getWalletInfoFx.pending);
    const ratesIsLoading = useStore(walletDataEffects.getWomRatesFx.pending);

    const currentRate =
        rates && rates.length > 0
            ? rates.find(item => item.assetName?.toLowerCase() === currency?.toLocaleLowerCase())
            : null;
    const walletInfo = walletItems?.find(item => item.accountType === walletAccountType);
    const currencyBalance =
        currentRate && walletInfo ? Number(totalCurrency(walletInfo.balance || 0, currentRate.price || 0)) : null;
    const womBalance = Number(walletInfo?.balance?.toFixed(2) || 0);
    const rateMessage = currencyBalance !== null ? `${currencyBalance} ${currency}` : 'Exchange rate unavailable';
    const balanceIsLoading = walletIsLoading || ratesIsLoading;

    const [isSelect, setIsSelect] = useState(false);
    const [selectedValue, setSelectedValue] = useState<SelectOptionValue | undefined>(undefined);
    const [selectItems, setSelectItems] = useState<SelectOption[]>([]);

    const [inputValue, setInputValue] = useState<string>('');
    const [inputIsTouched, setInputIsTouched] = useState(false);

    const selectedValueIndex =
        isSelect && selectedValue !== undefined && !!denominations?.length
            ? denominations?.findIndex(item => item === selectedValue)
            : -1;
    const selectedValueInWom =
        selectedValueIndex > -1 && foreignDenominations ? foreignDenominations[selectedValueIndex] : 0;

    const openHowToUse = () => {
        if (howToUse) {
            openHowToUseModal(howToUse);
        }
    };

    const resetState = () => {
        setIsSelect(false);
        setSelectedValue(undefined);
        setSelectItems([]);
        setInputValue('');
        setInputIsTouched(false);
    };

    const handleSelectChange = (value: SelectOptionValue) => {
        setSelectedValue(value);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        !inputIsTouched && setInputIsTouched(true);
    };

    const handleInputBlur = () => {
        !inputIsTouched && setInputIsTouched(true);
    };

    const handleSubmit = () => {
        onNextStep();
    };

    const calculateWom = () => {
        if (currentRate) {
            return (Number(inputValue) / (currentRate.price || 0)).toFixed(2);
        }

        return '0';
    };

    const validate = () => {
        if (!data) return 'You do not have enough WOM tokens';
        if (!womBalance) return 'You do not have enough WOM tokens';

        if (isSelect) {
            if (!selectedValue) return 'Required';
            if (selectedValueIndex === -1) return 'Required';
            if (selectedValueInWom > womBalance) return 'You do not have enough WOM tokens';

            return '';
        }

        if (!inputIsTouched) return '';

        const currentValue = Number(inputValue);
        const minValue = Math.ceil(valueRestrictions?.minVal || 0);
        const maxValue = Math.ceil(valueRestrictions?.maxVal || 0);

        if (!inputValue || !currentValue) return 'Required';
        if (currentValue < minValue || currentValue > maxValue) return `Min: ${minValue}, Max: ${maxValue}`;

        return '';
    };

    const error = validate();
    const isDisabled = !!error || balanceIsLoading || (!isSelect && !inputIsTouched);

    useEffect(() => {
        walletDataEffects.getWalletInfoFx();
        walletDataEffects.getWomRatesFx();
    }, []);

    useEffect(() => {
        if (!!denominations?.length) {
            setIsSelect(true);

            setSelectItems(
                denominations.map(item => ({
                    value: item,
                    label: `${item} ${currency}`
                }))
            );

            if (value > 0) {
                const index = denominations.findIndex(item => item === value);
                if (index > -1) {
                    setSelectedValue(denominations[index]);
                } else {
                    setSelectedValue(denominations[0]);
                }
            } else {
                setSelectedValue(denominations[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onChange(Number(selectedValue || 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    useEffect(() => {
        onChange(Number(inputValue || 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => () => resetState(), []);

    return (
        <>
            <Section justifyCenter marginBottom="28px">
                <Text alignTextCenter isBold>
                    Purchase Gift Card
                </Text>
            </Section>

            <Section justifyCenter marginBottom="24px">
                <Column maxWidth="152px" width="100%">
                    <GiftCard src={productImage || ''} title={brandName || ''} />
                </Column>
            </Section>

            <Section justifyCenter marginBottom="24px">
                {isSelect ? (
                    <Select
                        hideOnChange
                        error={error}
                        items={selectItems}
                        value={selectedValue}
                        onChange={handleSelectChange}
                    />
                ) : (
                    <Input
                        error={error}
                        placeholder="Denomination"
                        prefix={<Text size="-1">{currency}</Text>}
                        suffix={
                            currentRate ? (
                                <SuffixWrapper>
                                    <Text color={grey[500]} size="-1">
                                        ({calculateWom()} WOM)
                                    </Text>
                                </SuffixWrapper>
                            ) : undefined
                        }
                        type="number"
                        value={inputValue}
                        onBlur={handleInputBlur}
                        onChange={handleInputChange}
                    />
                )}
            </Section>

            <Column alignCenter noWrap marginBottom="24px" width="100%">
                <Row justifyCenter marginBottom="8px">
                    <Text alignTextCenter size="-1">
                        Available Balance:
                    </Text>
                </Row>

                {balanceIsLoading ? (
                    <Spinner />
                ) : (
                    <Text alignTextCenter color={grey[500]} size="-1">
                        {rateMessage} ({womBalance} WOM)
                    </Text>
                )}
            </Column>

            <Column noWrap marginBottom="24px" width="100%">
                <Section marginBottom="20px">
                    <PropertyGrid>
                        <PropertyBlock title="Country " value={countryName || ''} />
                        <PropertyBlock title="Voucher Currency " value={currency || 'USD'} />
                    </PropertyGrid>
                </Section>

                <PropertyGrid>
                    <PropertyBlock isHtml title="Expiration" value={expiryAndValidity || ''} />
                </PropertyGrid>
            </Column>

            {howToUse && (
                <Section justifyCenter marginBottom="24px">
                    <TextButton isBold isUnderline size="large" onClick={openHowToUse}>
                        How to use
                    </TextButton>
                </Section>
            )}

            <Button wide disabled={isDisabled} onClick={handleSubmit}>
                Pay
            </Button>
        </>
    );
};
