import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { FollowTabs } from 'components/pages/follow/FollowTabs';
import { Wrapper } from 'pages/FollowPage/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Id } from 'types/interfaces/data';

export const FollowPage = () => {
    const { id } = useParams<Partial<Id>>();

    return (
        <NewMainLayout>
            <Wrapper>{id && <FollowTabs id={id} />}</Wrapper>
        </NewMainLayout>
    );
};
