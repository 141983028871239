import React from 'react';
import { LikeKeySmIcon, MuteKeySmIcon, NextKeySmIcon, PrevKeySmIcon } from 'assets/icons';

export const SHORTCUTS_KEY = [
    {
        title: 'Go to previous video',
        icon: <PrevKeySmIcon />
    },
    {
        title: 'Go to next video',
        icon: <NextKeySmIcon />
    },
    {
        title: 'Like video',
        icon: <LikeKeySmIcon />
    },
    {
        title: 'Mute/ unmute video',
        icon: <MuteKeySmIcon />
    }
];
