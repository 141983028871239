import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { StatusWrapper, Wrapper } from './styles';
import { CollaborationUserStateUI, getCollaborationUserStatus, messages } from './utils';

interface Props extends BUCM.UserEngagedCollaborationResponse {}

export const ProfileCollaborationCard: FC<Props> = ({
    collaboration,
    mediaAssets,
    participationState,
    autoApproveMedia
}) => {
    const video = mediaAssets && mediaAssets.length > 0 ? mediaAssets[0] : null;
    const status = getCollaborationUserStatus(
        participationState,
        collaboration?.state,
        video,
        autoApproveMedia,
        collaboration?.networks
    );

    return (
        <Wrapper src={collaboration?.coverImageUrl || ''}>
            {status !== CollaborationUserStateUI.None && (
                <StatusWrapper state={status}>
                    <Text alignTextCenter color="inherit" size="-2">
                        {messages[status]}
                    </Text>
                </StatusWrapper>
            )}
        </Wrapper>
    );
};
