import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { ModalEnum } from 'hooks/useHandleQueryParams';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { walletStores } from 'stores/wallet';

export const WalletAuthRoute: FC<RouteProps> = ({ component: Component, path, ...props }) => {
    const walletToken = useStore(walletStores.$walletToken);
    const isAuth = useStore(authStores.$isAuth);

    const isAvailable = !!walletToken && isAuth;

    if (!isAvailable) {
        return <Redirect to={`${AppRoute.Main}?modal=${ModalEnum.Wallet}`} />;
    }

    return <Route component={Component} path={path} {...props} />;
};
