import axios from './axios';

export const query = (data: BULLZ.CreatePlaylistRequest) =>
    axios<BULLZ.CreatePlaylistForVideosResponse>({
        url: '/playlist/query',
        data
    });

export const get = (data: BULLZ.GetPlaylistVideoRequest) =>
    axios<BULLZ.PlaylistSingleVideoResponse>({
        url: '/playlist/get',
        data
    });

export const queryByFilter = (data: BULLZ.QueryPlaylistVideosRequest) =>
    axios<BULLZ.QueryPlaylistVideosResponse>({
        url: '/playlist/query-videos',
        data
    });
