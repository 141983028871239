import { BackArrowSmIcon } from 'assets/icons';
import imageSrc from 'assets/img/invite-friends.png';
import { CustomImage } from 'components/common/CustomImg/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import {
    EmptyBlock,
    InnerWrapper,
    SettingsTitle,
    Wrapper
} from 'components/pages/settings/components/CommonPart/styles';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { CopiedBox } from 'components/ui/CopiedBox';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { Shares } from 'components/ui/Shares';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { settingsInviteEffects, settingsInviteStores } from 'stores/settings/invite-friends';
import { NoopType } from 'types';

const { getReferralLinkFx, getReferralCountFx } = settingsInviteEffects;
const { $referralLink, $referralCount } = settingsInviteStores;

interface Props {
    onBack: NoopType;
}

export const InviteFriends: FC<Props> = ({ onBack }) => {
    const link = useStore($referralLink);
    const linkIsLoading = useStore(getReferralLinkFx.pending);

    const count = useStore($referralCount);
    const countIsLoading = useStore(getReferralCountFx.pending);

    const isLoading = linkIsLoading || countIsLoading;

    const handleShareClick = () => {
        if (window.navigator.share && link && isMobile) {
            window.navigator.share({
                title: 'BULLZ',
                url: link
            });
        }
    };

    useEffect(() => {
        getReferralLinkFx();
        getReferralCountFx();
    }, []);

    return (
        <Wrapper>
            <Section alignCenter noWrap justifyBetween={isMobile} marginBottom={isMobile ? '20px' : '24px'}>
                {isMobile && (
                    <IconButton onClick={onBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                )}

                <SettingsTitle isBold size={isMobile ? '1' : '-1'}>
                    Invite Friends
                </SettingsTitle>

                {isMobile && <EmptyBlock />}
            </Section>

            <InnerWrapper>
                <Column noWrap width="100%">
                    <Section marginBottom="8px">
                        <Text isBold size="1">
                            Want to share BULLZ?
                        </Text>
                    </Section>

                    <Section marginBottom="16px">
                        <Text size="-2">
                            Share your unique download link with your friends and receive reward benefits anytime your
                            referral participates in collaborations!
                        </Text>
                    </Section>

                    <Section justifyCenter marginBottom="16px">
                        <CustomImage src={imageSrc} width="150px" />
                    </Section>

                    {isLoading ? (
                        <Section justifyCenter>
                            <Spinner />
                        </Section>
                    ) : (
                        <>
                            <CopiedBox
                                copiedText={link || 'Not found'}
                                isDark={!isMobile}
                                successMessage="Link Copied"
                            />

                            {!!count && (
                                <Section marginTop="8px">
                                    <Text size="-2">You have invited {count} people</Text>
                                </Section>
                            )}

                            {link && (
                                <Section justifyEnd marginTop="24px">
                                    {!!window.navigator.share && isMobile ? (
                                        <Button onClick={handleShareClick}>Share link</Button>
                                    ) : (
                                        <OptionsPopover placement="right-end" renderItems={<Shares link={link} />}>
                                            <Button>Share link</Button>
                                        </OptionsPopover>
                                    )}
                                </Section>
                            )}
                        </>
                    )}
                </Column>
            </InnerWrapper>
        </Wrapper>
    );
};
