import { createEvent, createStore } from 'effector';
import { SizesAdaptiveType } from 'types';

const setSizesAdaptive = createEvent<SizesAdaptiveType>();
const sizesAdaptive = createStore<SizesAdaptiveType>([0, 0]).on(setSizesAdaptive, (_, sizes) => sizes);

const setSizesWindowAdaptive = createEvent<SizesAdaptiveType>();
const sizesWindowAdaptive = createStore<SizesAdaptiveType>([0, 0]).on(setSizesWindowAdaptive, (_, sizes) => sizes);

const setError = createEvent<BULLZ.Error400BadRequest>();
const setErrorDefault = createEvent();
const error = createStore<BULLZ.Error400BadRequest>({})
    .on(setError, (_, error) => error)
    .on(setErrorDefault, _ => ({}));

export const commonStores = { sizesAdaptive, sizesWindowAdaptive, error };
export const commonEvents = { setSizesAdaptive, setSizesWindowAdaptive, setError, setErrorDefault };
