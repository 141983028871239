import { baseColors } from 'constants/styles';
import styled from 'styled-components';

export const TextWrapper = styled.div`
    width: 100%;
    max-height: 312px;
    overflow-y: auto;
    padding: 8px;
    border-radius: 8px;
    background-color: ${baseColors.black};
`;
