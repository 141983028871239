import { forward, sample } from 'effector';
import cloneDeep from 'lodash.clonedeep';
import { commentsEvents } from 'stores/comments';
import { commentsStores } from '../comments';
import { reportCommentsEffects } from './index';

const { reportCommentDoneFx, reportCommentFailFx, reportCommentFx } = reportCommentsEffects;
const { $currentVideoComments, $replies } = commentsStores;
const { setComments, setReplies } = commentsEvents;

forward({
    from: reportCommentFx.done,
    to: reportCommentDoneFx
});

forward({
    from: reportCommentFx.fail,
    to: reportCommentFailFx
});

sample({
    clock: reportCommentDoneFx.done,
    source: $currentVideoComments,
    fn: (source, { result }) => {
        const newItems = source.items?.filter(comment => comment.id !== result.commentId);
        if (newItems?.length === 0) {
            return {};
        }
        return { ...source, items: newItems };
    },
    target: setComments
});

sample({
    clock: reportCommentDoneFx.done,
    source: $replies,
    fn: (allReplies, { result }) => {
        const newAllReplies = cloneDeep(allReplies);
        if (result.parentId) {
            const commentReplies = newAllReplies[result.parentId];
            if (commentReplies.data?.items) {
                const newItems = commentReplies.data?.items.filter(reply => reply.id !== result.commentId);
                const newTotalRecords = newAllReplies[result.parentId].data?.totalRecords;
                newAllReplies[result.parentId] = {
                    ...newAllReplies[result.parentId],
                    data: {
                        ...newAllReplies[result.parentId].data,
                        items: newItems,
                        totalRecords: newTotalRecords !== undefined && newTotalRecords > 0 ? newTotalRecords - 1 : 0
                    }
                };
            }
        }
        return newAllReplies;
    },

    target: setReplies
});
