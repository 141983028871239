import { baseColors, grey } from 'constants/styles/colors';
import { allTransition, disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

interface TagStyledProps {
    hasClick?: boolean;
}

export const TagStyled = styled.button<TagStyledProps>`
    ${disableDefaultButtonStyleMixin};
    display: inline-flex;
    padding: 4px 10px;
    border-radius: 50px;
    background-color: ${grey['900']};
    text-align: center;
    color: ${baseColors.white};
    ${allTransition};
    cursor: ${({ hasClick }) => (hasClick ? 'pointer' : 'default')};
    white-space: nowrap;

    &:hover:not(:disabled) {
        background-color: ${grey['800']};
    }

    &:disabled {
        color: ${grey['500']};
        background-color: ${grey['700']};
        cursor: not-allowed;
    }
`;
