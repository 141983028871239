import { SelectButtonProps } from 'components/ui/buttons/SelectButton';
import {
    allTransition,
    baseColors,
    disableDefaultButtonStyleMixin,
    grey,
    MontserratFontFamily
} from 'constants/styles';
import styled, { css } from 'styled-components';

export const StyledButton = styled.button<SelectButtonProps>`
    ${disableDefaultButtonStyleMixin};
    color: ${grey[600]};
    border: 1px solid ${grey[600]};
    border-radius: 8px;
    padding: 10px 20px;
    font-family: ${MontserratFontFamily};
    font-size: 14px;
    line-height: 20px;
    ${allTransition};

    ${({ selected }) =>
        selected &&
        css`
            background-color: ${baseColors.blue};
            border-color: ${baseColors.blue};
            color: ${baseColors.white};
        `}

    &:hover {
        color: ${baseColors.white};
        border-color: ${baseColors.white};
    }

    ${({ wide }) =>
        wide &&
        css`
            width: 100%;
        `}
`;
