import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Network, ShareStep } from 'components/modals/ShareLinkModal/constants';
import { FirstStep } from 'components/modals/ShareLinkModal/FirstStep';
import { SecondStep } from 'components/modals/ShareLinkModal/SecondStep';
import { ThirdStep } from 'components/modals/ShareLinkModal/ThirdStep';
import { useStore } from 'effector-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { shareLinkModal } from 'stores/initialize.modal';

const { modal, closeModal } = shareLinkModal;

export const ShareLinkModal = () => {
    const [visible, { collaborationNetworks }] = useStore(modal);
    const [currentStep, setCurrentStep] = useState(ShareStep.First);
    const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(null);

    const steps: Record<ShareStep, ReactNode> = {
        [ShareStep.First]: (
            <FirstStep
                collaborationNetworks={collaborationNetworks}
                setCurrentStep={setCurrentStep}
                setSelectedNetwork={setSelectedNetwork}
            />
        ),
        [ShareStep.Second]: <SecondStep selectedNetwork={selectedNetwork} setCurrentStep={setCurrentStep} />,
        [ShareStep.Third]: <ThirdStep selectedNetwork={selectedNetwork} />
    };

    useEffect(() => {
        !visible && setCurrentStep(ShareStep.First);
    }, [visible]);

    return (
        <ModalWrapper
            fullscreenMobile={currentStep === ShareStep.Second}
            visible={visible}
            onClose={() => closeModal()}
        >
            {steps[currentStep]}
        </ModalWrapper>
    );
};
