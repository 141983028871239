import { Network } from 'components/modals/ShareLinkModal/constants';

export const networkTexts = {
    [Network.Twitter]: {
        title: 'Tweet',
        text: 'Tweet',
        placeholder: 'e.g. https://twitter.com/post'
    },
    [Network.Post]: {
        title: 'Post',
        text: 'Instagram post',
        placeholder: 'e.g. https://instagram.com/post'
    },
    [Network.Reels]: {
        title: 'Reels',
        text: 'Instagram Reels',
        placeholder: 'e.g. https://instagram.com/post'
    },
    [Network.Story]: {
        title: 'Story',
        text: 'Instagram Story',
        placeholder: 'e.g. https://instagram.com/post'
    },
    [Network.Youtube]: {
        title: 'Video',
        text: 'YouTube video',
        placeholder: 'e.g. https://youtube.com/post'
    },
    [Network.Shorts]: {
        title: 'Shorts',
        text: 'YouTube Shorts',
        placeholder: 'e.g. https://youtube.com/post'
    },
    [Network.TikTok]: {
        title: 'Video',
        text: 'TikTok video',
        placeholder: 'e.g. https://tiktok.com/post'
    },
    [Network.Twitch]: {
        title: 'Video',
        text: 'Twitch video',
        // TODO: need real placeholder for twitch
        placeholder: 'e.g. https://twitch.com'
    }
};
