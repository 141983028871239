import slideOneMobileImage from 'assets/img/quick-signals/guide/slide-1-mobile.png';
import slideOneImage from 'assets/img/quick-signals/guide/slide-1.png';
import slideTwoMobileImage from 'assets/img/quick-signals/guide/slide-2-mobile.png';
import slideTwoImage from 'assets/img/quick-signals/guide/slide-2.png';
import slideThreeMobileImage from 'assets/img/quick-signals/guide/slide-3-mobile.png';
import slideThreeImage from 'assets/img/quick-signals/guide/slide-3.png';
import { SlideProps } from './Slide';

export const slides: SlideProps[] = [
    {
        imgSrc: slideOneImage,
        mobileSrc: slideOneMobileImage,
        title: 'Congratulations!',
        description:
            'You have successfully created a Quick Signals Channel! Now you can get started creating Quick Signals. Share your Channel with your communities and encourage them to join!'
    },
    {
        imgSrc: slideTwoImage,
        mobileSrc: slideTwoMobileImage,
        title: 'Upload a Quick Signal',
        description: 'Choose Quick Signal and get started!'
    },
    {
        imgSrc: slideThreeImage,
        mobileSrc: slideThreeMobileImage,
        title: 'Earn and use WOM Tokens',
        description:
            'As you recruit followers who join your Channel you earn WOM. Use your WOM for gift cards in BULLZ or transfer them out to your external wallet.'
    }
];
