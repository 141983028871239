import React, { FC } from 'react';
import { BadgeWrapper } from './styles';

export interface BadgeProps {
    isUppercase?: boolean;
    status?: number;
}

export const Badge: FC<BadgeProps> = ({ children, status, isUppercase }) => (
    <BadgeWrapper isUppercase={isUppercase} size="-2" status={status}>
        {children}
    </BadgeWrapper>
);
