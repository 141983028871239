import { defaultTeamMembersQueryParams, defaultTeamVideosQueryParams } from 'constants/services/team';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

//Events
const resetStore = createEvent();
const updateMembers = createEvent<BULLZ.QueryTeamVideosResponse>();
const updateTeamInfo = createEvent<BULLZ.GetTeamDetailResponse>();

//Effects
const getTeamVideosFx = createEffect({
    handler: async (id: string) =>
        await API.team.getTeamVideos({
            ...defaultTeamVideosQueryParams,
            id: id
        })
});

const getTeamInfoFx = createEffect({
    handler: async (id: string) =>
        await API.team.getTeamInfo({
            ...defaultTeamVideosQueryParams,
            id: id
        })
});

const getTeamMembersFx = createEffect({
    handler: async (id: string) =>
        await API.team.getTeamMembers({
            ...defaultTeamMembersQueryParams,
            teamId: id
        })
});

const loadMoreTeamVideosFx = createEffect({
    handler: async (data: BULLZ.QueryTeamVideosRequest) => await API.team.getTeamVideos(data)
});

const removeUserFromTeamFx = createEffect({
    handler: async (data: BULLZ.RemoveTeamMemberRequest) =>
        await API.team.removeUser({
            ...data
        })
});

const joinToTeamFx = createEffect({
    handler: async (data: BULLZ.JoinTeamRequest) => await API.team.joinToTeam({ ...data })
});

const leaveTeamFx = createEffect({
    handler: async (data: BULLZ.LeaveTeamRequest) => await API.team.leaveFromTeam({ ...data })
});

const createTeamFx = createEffect({
    handler: async (data: BULLZ.CreateTeamRequest) =>
        await API.team.createTeam({
            ...data
        })
});

const updateTeamFx = createEffect({
    handler: async (data: BULLZ.UpdateTeamRequest) =>
        await API.team.updateTeam({
            ...data
        })
});

const existsTeamFx = createEffect({
    handler: async (data: BULLZ.TeamNameExistsRequest) => await API.team.existsTeam(data)
});

//Stores
const $teamVideos = createStore<BULLZ.QueryTeamVideosResponse>({})
    .on(getTeamVideosFx.doneData, (_, newState) => newState)
    .on(loadMoreTeamVideosFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStore);

const $teamInfo = createStore<BULLZ.GetTeamDetailResponse>({})
    .on([getTeamInfoFx.doneData, updateTeamInfo], (_, newState) => newState)
    .reset(resetStore);

const $newTeamInfo = createStore<BULLZ.CreateTeamResponse>({})
    .on(createTeamFx.doneData, (_, newState) => newState)
    .reset(resetStore);

const $updatingTeam = createStore<BULLZ.UpdateTeamResponse>({})
    .on(updateTeamFx.doneData, (_, newState) => newState)
    .reset(resetStore);

const $teamMembers = createStore<BULLZ.QueryMembersResponse>({})
    .on([getTeamMembersFx.doneData, updateMembers], (_, payload) => payload)
    .reset(resetStore);

const $hasError = createStore<boolean>(false)
    .on(getTeamVideosFx.fail, () => true)
    .on(loadMoreTeamVideosFx.done, () => true)
    .reset([getTeamVideosFx.done, loadMoreTeamVideosFx.done, resetStore]);

const $createTeamError = createStore<string | null>(null)
    .on(createTeamFx.fail, () => 'Team name is not available')
    .reset([resetStore, createTeamFx.done]);

//Exports
export const teamStores = {
    $teamVideos,
    $teamMembers,
    $hasError,
    $teamInfo,
    $newTeamInfo,
    $createTeamError,
    $updatingTeam
};
export const teamEffects = {
    getTeamVideosFx,
    getTeamMembersFx,
    loadMoreTeamVideosFx,
    removeUserFromTeamFx,
    getTeamInfoFx,
    joinToTeamFx,
    leaveTeamFx,
    createTeamFx,
    updateTeamFx,
    existsTeamFx
};
export const teamEvents = { resetStore, updateMembers, updateTeamInfo };
