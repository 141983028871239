export enum AppRoute {
    Main = '/',
    Profile = '/profile',
    Video = '/video',
    Home = '/home',
    Trending = '/trending',
    Search = '/search/list',
    SearchVideos = '/search/videos',
    SavedVideos = '/saved/videos',
    Team = '/team',
    FollowedHashtags = '/follow/hashtags',
    Notifications = '/notifications',
    Wallet = '/wallet',
    Settings = '/settings',
    QuickSignals = '/quick-signals',
    QuickSignalsCreate = '/quick-signals/create',
    QuickSignalsEdit = '/quick-signals/edit',
    QuickSignalsSubscriptions = '/quick-signals/subscriptions',
    QuickSignalsChannel = '/quick-signals/channel',
    Collaborations = '/collaborations',
    Gifts = '/gifts'
}

export const profileUrl = `${AppRoute.Profile}/:id`;
export const videoIdUrl = `${AppRoute.Video}/:id`;
export const teamUrl = `${AppRoute.Team}/:id`;
export const followUrl = profileUrl + '/follow';
export const qsChannelDetailsUrl = `${AppRoute.QuickSignalsChannel}/:channelId`;
export const collaborationDetailsUrl = `${AppRoute.Collaborations}/:id`;
export const referralUrl = `/refer/:code`;

export const iosAppStoreLink = 'https://apps.apple.com/us/app/bullz/id1579098036';
export const androidAppStoreLink = 'https://play.google.com/store/apps/details?id=io.womprotocol.bullz';

export const bullzSiteUrl = 'https://bullz.com';
export const bullzSiteTermsUrl = `${bullzSiteUrl}/terms-of-service`;
export const bullzSitePolicyUrl = `${bullzSiteUrl}/privacy-policy`;
export const bullzSiteFaqs = `${bullzSiteUrl}/faqs`;
export const bullzSiteQualityGuidelines = `${bullzSiteUrl}/quality-guidelines`;

export const helpDeskLink = 'https://yeaywom.atlassian.net/servicedesk/customer/portal/4';
export const reportAnIssue = `${helpDeskLink}/group/9`;
export const makeSuggestion = `${helpDeskLink}/group/10`;
export const getVerified = `${helpDeskLink}/group/11`;

export const instagramSiteUrl = 'https://www.instagram.com/bullz.app/';
export const twitterSiteUrl = 'https://twitter.com/bullzapp';
export const mediumSiteUrl = 'https://medium.com/wom-protocol';
export const telegramSiteUrl = 'https://t.me/WOMProtocolChat';
export const discordSiteUrl = 'https://discord.gg/Y3ua3pUDkj';

export const walletRoutes = [AppRoute.Wallet, AppRoute.Gifts];
