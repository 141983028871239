import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { FillDataForm } from 'components/modals/UploadCollaborationModal/FillDataForm';
import { IconButton } from 'components/ui/buttons/IconButton';
import { VideoInput } from 'components/ui/inputs/VideoInput';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { uploadCollaborationModal } from 'stores/initialize.modal';
import { StyledSection, VideoWrapper } from './styles';

const { modal, closeModal } = uploadCollaborationModal;

export const UploadCollaborationModal = () => {
    const [visible, { collaborationId }] = useStore(modal);
    const [videoFile, setVideoFile] = useState<File | null>(null);

    return (
        <ModalWrapper fullscreenMobile size="large" visible={visible} onClose={closeModal}>
            <Section>
                <Section alignCenter justifyBetween marginBottom="30px">
                    <Row alignCenter>
                        {!isMobile ? (
                            <Title size="3">Please submit your video</Title>
                        ) : (
                            <>
                                <Section marginBottom="16px">
                                    <IconButton onClick={() => closeModal()}>
                                        <BackArrowSmIcon />
                                    </IconButton>
                                </Section>
                                <Section noWrap>
                                    <Text isBold size="1">
                                        Please submit your video
                                    </Text>
                                </Section>
                            </>
                        )}
                    </Row>
                    {!isMobile && (
                        <IconButton onClick={() => closeModal()}>
                            <CloseSmIcon />
                        </IconButton>
                    )}
                </Section>
                <Section noWrap={!isMobile}>
                    <VideoWrapper>
                        <VideoInput isTopControlsVisible setFile={setVideoFile} />
                    </VideoWrapper>
                    <StyledSection>
                        <FillDataForm collaborationId={collaborationId} videoFile={videoFile} />
                    </StyledSection>
                </Section>
            </Section>
        </ModalWrapper>
    );
};
