import { transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { createGlobalStyle } from 'styled-components';

export const SliderWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SwiperWrapper = styled.div`
    width: 100%;

    ${media.laptop(`
        margin-right: 12px;
        margin-left: 12px;
        width: calc(100% - 72px);
    `)}
`;

export const IconButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    ${flexCenter};
    flex-shrink: 0;
    color: ${grey[600]};
    transition: color ${transitionTime} linear;

    &:hover {
        color: ${baseColors.white};
    }
`;

export const GlobalStyle = createGlobalStyle`
    .swiper-slide {
        display: flex;
        justify-content: center;
    }
`;
