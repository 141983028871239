import { SearchInput } from 'components/ui/inputs/SearchInput';
import { Tabs } from 'components/ui/Tabs';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useSearchInput } from 'hooks/useSearchInput';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { searchStores } from 'stores/search';
import { Dropdown, SearchContainer } from './styles';
import { useSearchTabs } from './useSearchTabs';

export enum SearchItemType {
    None,
    User,
    Video,
    Hashtag,
    Product,
    Topic,
    Team
}

export const SearchDropdown = () => {
    const history = useHistory();
    const ref = useRef(null);
    const isLoading = useStore(searchStores.loading);
    const isPageSearch = history.location.pathname === AppRoute.Search;
    const searchInput = useSearchInput();
    const { value, isFocused, setIsFocused, onClear } = searchInput;
    const searchTabs = useSearchTabs(value, onClear);
    useOutsideClick(ref, () => setIsFocused(false));

    const onSearchSubmit = () => {
        history.push(`${AppRoute.Search}?query=${value}&type=${searchTabs.activeTab}`);
        setIsFocused(false);
    };

    return (
        <SearchContainer ref={ref}>
            <SearchInput
                disableAutocomplete
                isLoading={isLoading}
                placeholder="Search"
                onSubmit={onSearchSubmit}
                {...searchInput}
            />
            {isFocused && !isPageSearch && (
                <Dropdown>
                    <Tabs {...searchTabs} />
                </Dropdown>
            )}
        </SearchContainer>
    );
};
