import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React, { FC } from 'react';
import { getTimePassed } from './utils';

export const CommentHead: FC<BULLZ.GetPostResponse> = ({ username, utcCreated }) => (
    <Row alignCenter>
        <Text size="-2">@{username}</Text>

        <Column marginLeft="10px">
            <Text color={grey['500']} size="-2">
                {getTimePassed(utcCreated)}
            </Text>
        </Column>
    </Row>
);
