import { flexCenter, grey } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    border-radius: 8px;
    background-color: ${grey[900]};
    height: 580px;
`;
