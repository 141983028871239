import { defaultUserVideosQueryParams } from 'constants/services/user-videos';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

//Events
const resetStores = createEvent();

//Effects
const getHashtagOrTopicVideosFx = createEffect({
    handler: async (values: Omit<BULLZ.QueryPlaylistVideosRequest, 'pageIndex' | 'limit'>) =>
        await API.playlist.queryByFilter({ ...defaultUserVideosQueryParams, ...values })
});

const loadMoreHashtagOrTopicFx = createEffect({
    handler: async (data: BULLZ.QueryPlaylistVideosRequest) => await API.playlist.queryByFilter(data)
});

//Stores
const $hashtagOrTopicVideos = createStore<BULLZ.QueryPlaylistVideosResponse>({})
    .on(getHashtagOrTopicVideosFx.doneData, (_, newState) => newState)
    .on(loadMoreHashtagOrTopicFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStores);

const $hasError = createStore<boolean>(false)
    .on(getHashtagOrTopicVideosFx.fail, () => true)
    .on(loadMoreHashtagOrTopicFx.done, () => true)
    .reset([getHashtagOrTopicVideosFx.done, loadMoreHashtagOrTopicFx.done, resetStores]);

//Exports

export const hashtagOrTopicVideosEvents = { resetStores };
export const hashtagOrTopicVideosEffects = { getHashtagOrTopicVideosFx, loadMoreHashtagOrTopicFx };
export const hashtagOrTopicVideosStores = { $hashtagOrTopicVideos, $hasError };
