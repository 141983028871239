import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const ImageWrapper = styled(Section)`
    width: 144px;
    height: 110px;
    margin-bottom: 20px;

    ${media.laptop(`
        margin-left: 20px;
    `)};
`;

export const AvatarWrapper = styled.div`
    z-index: 1;
`;

export const Image = styled.img`
    margin-left: -20px;
    width: 75px;
    height: 95px;
`;

export const InfoBlocksWrapper = styled.div`
    display: grid;
    width: 100%;
    row-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 8px;

    ${media.laptop(`
        grid-template-columns: repeat(2, 1fr);
        row-gap: 24px;
        margin-bottom: 24px;
    `)};
`;
