import { ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { asyncErrorMessage } from 'constants/messages';
import { AppRoute } from 'constants/routes';
import { createEffect, createEvent, createStore } from 'effector';
import { womAPI } from 'services/wom';
import { createWalletPinModal, forgotWalletPinModal, infoModal, verifyWalletPinModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';

// Events

const resetWomUser = createEvent();
const resetWalletToken = createEvent();
const resetIsPurchaseEnabled = createEvent();

// Effects

const getWomUserFx = createEffect({
    handler: async () => await womAPI.user.getWomUser({})
});

const createWomWalletFx = createEffect({
    handler: async () => await womAPI.wallet.createWallet()
});

const createWomWalletFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'There was a problem creating your wallet. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const createWalletPinFx = createEffect({
    handler: async (pin: string) => await womAPI.wallet.createWalletPin({ pin })
});

const createWalletPinDoneFx = createEffect({
    handler: () => {
        createWalletPinModal.closeModal();
        forgotWalletPinModal.closeModal();
        infoModal.openModal({
            icon: ConfettiLgIcon,
            title: 'Congrats!',
            text: 'You successfully created your wallet pin.',
            buttonText: 'Let’s Go',
            onClick: () => verifyWalletPinModal.openModal(),
            afterCloseCb: () => verifyWalletPinModal.openModal()
        });
    }
});

const createWalletPinFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'There was a problem creating your wallet pin. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const existsWalletPinFx = createEffect({
    handler: async () => await womAPI.wallet.existsWalletPin()
});

const existsWalletPinDoneFx = createEffect({
    handler: () => {
        verifyWalletPinModal.openModal();
    }
});

const existsWalletPinFailFx = createEffect({
    handler: () => {
        createWalletPinModal.openModal();
    }
});

const verifyWalletPinFx = createEffect({
    handler: async (pin: string) => {
        try {
            await womAPI.wallet.verifyWalletPin({ pin });
            return true;
        } catch {
            return false;
        }
    }
});

const getWalletTokenFx = createEffect({
    handler: async (pin: string) => await womAPI.wallet.getWalletToken({ pin })
});

const getWalletTokenDoneFx = createEffect({
    handler: () => {
        verifyWalletPinModal.closeModal();
    }
});

const getWalletTokenFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const sendForgotPinSmsCodeFx = createEffect({
    handler: async () => await womAPI.wallet.sendForgotPinSmsCode()
});

const sendForgotPinSmsCodeFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const forgotWalletPinFx = createEffect({
    handler: async (data: WOM.ResetWalletPinRequest) => await womAPI.wallet.forgotWalletPin(data)
});

const verifyPurchaseFx = createEffect({
    handler: async (data: WOM.VerifyPurchaseRequest) => await womAPI.wallet.verifyPurchase(data)
});

const payWomFx = createEffect({
    handler: async () => {
        try {
            const redirectUrl = `${window.location.origin}${AppRoute.Wallet}`;
            const { payLink } = await womAPI.payment.payWomMixpay({ redirectUrl });

            if (!!payLink) {
                window.open(payLink);
            } else {
                infoModal.openModal({
                    icon: WrongLgIcon,
                    title: asyncErrorMessage,
                    onClick: noop
                });
            }
        } catch (e) {
            infoModal.openModal({
                icon: WrongLgIcon,
                title: asyncErrorMessage,
                onClick: noop
            });
        }
    }
});

// Stores

const $womUser = createStore<WOM.GetUserResponse | null>(null)
    .on(getWomUserFx.doneData, (_, data) => data || null)
    .on(getWomUserFx.fail, () => null)
    .reset(resetWomUser);

const $womUserIsLoaded = createStore<boolean>(false)
    .on(getWomUserFx.finally, () => true)
    .reset(resetWomUser);

const $hasWallet = $womUser.map(state => {
    if (!state) {
        return false;
    }

    if (state.hasWallet === undefined) {
        return false;
    }

    return !!state.hasWallet;
});

const $walletToken = createStore<string | null>(null)
    .on(getWalletTokenFx.doneData, (_, data) => data.walletJwt || null)
    .reset(resetWalletToken);

const $isPurchaseEnabled = createStore<boolean>(false)
    .on(verifyPurchaseFx.doneData, (_, payload) => payload.isSufficient)
    .reset(resetIsPurchaseEnabled);

// Exports

export const walletEvents = {
    resetWomUser,
    resetWalletToken,
    resetIsPurchaseEnabled
};

export const walletEffects = {
    getWomUserFx,
    createWomWalletFx,
    createWomWalletFailFx,
    createWalletPinFx,
    createWalletPinDoneFx,
    createWalletPinFailFx,
    existsWalletPinFx,
    existsWalletPinDoneFx,
    existsWalletPinFailFx,
    verifyWalletPinFx,
    getWalletTokenFx,
    getWalletTokenDoneFx,
    getWalletTokenFailFx,
    sendForgotPinSmsCodeFx,
    sendForgotPinSmsCodeFailFx,
    forgotWalletPinFx,
    verifyPurchaseFx,
    payWomFx
};

export const walletStores = {
    $womUser,
    $womUserIsLoaded,
    $hasWallet,
    $walletToken,
    $isPurchaseEnabled
};
