import { BackArrowSmIcon, HashtagSmIcon } from 'assets/icons';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { Spinner } from 'components/loaders/Spinner';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { baseColors, grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { userHashtagEffects, userHashtagEvents, userHashtagStores } from 'stores/user-hashtag';
import { Empty, HashtagGrid, IconWrapper } from './styles';

const { getUserHashtagsFx, loadMoreUserHashtagsFx } = userHashtagEffects;
const { $userHashtags } = userHashtagStores;
const { resetUserHashtags } = userHashtagEvents;

const { $user } = authStores;

export const FollowedHashtags: FC = () => {
    const history = useHistory();
    const { items, currentPageIndex, totalPages } = useStore($userHashtags);
    const isMoreLoading = useStore(loadMoreUserHashtagsFx.pending);
    const isLoading = useStore(getUserHashtagsFx.pending);

    const userId = useStore($user)?.userId;

    useEffect(() => {
        getUserHashtagsFx();

        return () => resetUserHashtags();
    }, []);

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreUserHashtagsFx({
                    pageIndex: currentPageIndex + 1
                });
        },
        rootMargin: '0px'
    });

    const goToBack = () => {
        !!userId ? history.push(`${AppRoute.Profile}/${userId}`) : history.goBack();
    };

    const goToHashtag = (tag: string) => {
        history.push(`${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&name=${tag}&tagId=${tag}`);
    };

    return (
        <NewMainLayout>
            <Column height="100%" maxWidth="622px" width="100%">
                <Section noWrap marginBottom="28px">
                    <IconButton onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                    <Section marginLeft="8px">
                        <Text isBold size={isMobile ? '0' : '1'}>
                            Hashtags Followed
                        </Text>
                    </Section>
                </Section>
                {items && items.length > 0 && (
                    <>
                        <HashtagGrid>
                            {items.map(({ hashTag }, index) => (
                                <Section key={index} alignCenter justifyBetween>
                                    <TextButton onClick={() => goToHashtag(hashTag || '')}>
                                        <Row alignCenter>
                                            <MarginWrapper marginRight="16px">
                                                <IconWrapper>
                                                    <HashtagSmIcon />
                                                </IconWrapper>
                                            </MarginWrapper>
                                            <Text size="-1">#{hashTag}</Text>
                                        </Row>
                                    </TextButton>
                                    {/* TODO add follow logic */}
                                    {/* <TextButton>Follow</TextButton> */}
                                </Section>
                            ))}
                        </HashtagGrid>
                        <HiddenScrollBlock ref={loadRef} />
                    </>
                )}
                {(!items || items.length === 0) && !isLoading && (
                    <Empty alignCenter justifyCenter>
                        <IconWrapper color={baseColors.black}>
                            <HashtagSmIcon height="18px" width="15px" />
                        </IconWrapper>
                        <MarginWrapper marginBottom="8px" marginTop="12px">
                            <Text size="-1">No hashtags followed</Text>
                        </MarginWrapper>
                        <Row width="280px">
                            <Text alignTextCenter color={grey[500]} size="-2">
                                When you follow a hashtag, you will see it listed here
                            </Text>
                        </Row>
                    </Empty>
                )}
                {(isLoading || isMoreLoading) && (
                    <Section justifyCenter marginTop="24px">
                        <Spinner />
                    </Section>
                )}
            </Column>
        </NewMainLayout>
    );
};
