import { FieldTitle } from 'components/form/FieldTitle';
import { InputField } from 'components/form/InputField';
import { SelectField } from 'components/form/SelectField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { HashtagField } from 'components/ui/HashtagField';
import { useStore } from 'effector-react';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { bucmUserStores } from 'stores/bucm-user';
import { categoriesEffects, categoriesStores } from 'stores/categories';
import { uploadVideoModal } from 'stores/initialize.modal';
import { socialNetworksStores } from 'stores/social-networks';
import { uploadSubmissionEffects } from 'stores/upload-submission';
import { prepareCategories } from 'utils/prepareCategories';
import { prepareLanguages } from 'utils/prepareLanguages';
import { FillDataFormValues, fillDataSchema, fillDataValues } from './constants';

const { closeModal } = uploadVideoModal;
const { uploadSubmissionFx } = uploadSubmissionEffects;

interface Props {
    videoFile: File | null;
    collaborationId: string;
}

export const FillDataForm: FC<Props> = ({ videoFile, collaborationId }) => {
    const categories = useStore(categoriesStores.$categories);
    const bucmUser = useStore(bucmUserStores.$bucmUser);
    const { data: networks } = useStore(socialNetworksStores.$externalNetworks);
    const preparedCategories = prepareCategories(categories);
    const [tags, setTags] = useState('');
    const formik = useFormik({
        initialValues: fillDataValues,
        validationSchema: fillDataSchema,
        onSubmit: ({ language, topic, project, category, website }: FillDataFormValues) => {
            if (videoFile) {
                uploadSubmissionFx({
                    brand: project,
                    product: topic,
                    productLink: website,
                    categoryTagId: category,
                    spokenLanguages: [language],
                    collaborationId,
                    file: videoFile,
                    userId: bucmUser.userId || '',
                    networkId: networks?.items?.find(network => network.title?.toLowerCase() === 'bullz')?.id || ''
                });
            }
        }
    });
    const { values, isValid, dirty, setFieldValue } = formik;

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    useEffect(() => {
        const { category, project, topic } = values;
        const categoryName = preparedCategories.find(currentCategory => currentCategory.value === category);
        const categoryTag = categoryName ? categoryName.label : '';
        const projectTag = project ? `#${project}` : '';
        const topicTag = topic ? `#${topic}` : '';

        setTags(`${categoryTag} #sponsored ${projectTag} ${topicTag}`);
    }, [values, preparedCategories]);

    useEffect(() => {
        setFieldValue('videoFile', videoFile, true);
    }, [videoFile, setFieldValue]);

    return (
        <FormikProvider value={formik}>
            <FormWrapper>
                <Section marginBottom="16px">
                    <FieldTitle title="Category" />
                    <SelectField
                        hideOnChange
                        items={preparedCategories}
                        name="category"
                        placeholder="Please select one"
                    />
                </Section>
                <Section marginBottom="16px">
                    <FieldTitle currentLetters={values.project.length} title="Project" />
                    <InputField wide name="project" placeholder="e.g. ConsenSys" />
                </Section>
                <Section marginBottom="16px">
                    <FieldTitle currentLetters={values.topic.length} title="Topic" />
                    <InputField wide name="topic" placeholder="e.g. Metamask" />
                </Section>
                <Section marginBottom="16px">
                    <FieldTitle title="Website URL" />
                    <InputField wide name="website" placeholder="e.g. https://metamask.io" />
                </Section>
                <Section marginBottom="16px">
                    <FieldTitle title="Your Tags" />
                    <HashtagField tags={tags} />
                </Section>
                <Section marginBottom="32px">
                    <FieldTitle title="Spoken Language" />
                    <SelectField
                        hideOnChange
                        items={prepareLanguages()}
                        name="language"
                        placeholder="Please select one"
                    />
                </Section>
                <Section alignCenter justifyBetween>
                    <TextButton onClick={() => closeModal()}>Cancel</TextButton>
                    <Button disabled={!isValid || !dirty || !videoFile} type="submit">
                        Next
                    </Button>
                </Section>
            </FormWrapper>
        </FormikProvider>
    );
};
