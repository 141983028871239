import { Column } from 'components/grid/wrappers/FlexWrapper';
import styled from 'styled-components';

export const Wrapper = styled(Column)`
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
`;

export const ImageWrapper = styled.div`
    position: relative;
    display: inline-flex;
`;

export const MobileImage = styled.img`
    position: absolute;
    right: 0;
    transform: translateX(calc(100% - 13px));
    z-index: 1;
    width: 64px;
`;

export const TextWrapper = styled.div`
    width: 100%;
    padding-left: 32px;
    margin-top: 4px;
`;
