import { ChevronLeftBorderSmIcon, ChevronRightBorderSmIcon } from 'assets/icons';
import { CreatorCard } from 'components/cards/CreatorCard';
import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { trendingsStores } from 'stores/trendings';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { defaultNavigation } from './constants';
import { GlobalStyle, IconButton, SliderWrapper, SwiperWrapper } from './styles';

interface NavigationProperties {
    prevEl: HTMLButtonElement | null;
    nextEl: HTMLButtonElement | null;
}

export const CreatorSlider = () => {
    const navigationPrevRef = useRef<HTMLButtonElement>(null);
    const navigationNextRef = useRef<HTMLButtonElement>(null);
    const [navigation, setNavigation] = useState<NavigationProperties>(defaultNavigation);

    const items = useStore(trendingsStores.users);

    const history = useHistory();

    const goToCreator = (id: string) => {
        history.push(`${AppRoute.Profile}/${id}`);
    };

    useEffect(() => {
        if (navigationPrevRef.current && navigationNextRef.current) {
            setNavigation({
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
            });
        }
    }, []);

    return (
        <Column width="100%">
            <MarginWrapper marginBottom="20px">
                <Text isBold size="1">
                    Trending Creators
                </Text>
            </MarginWrapper>

            <SliderWrapper>
                {!isMobile && (
                    <IconButton ref={navigationPrevRef} type="button">
                        <ChevronLeftBorderSmIcon />
                    </IconButton>
                )}

                <SwiperWrapper>
                    <Swiper
                        modules={[Navigation]}
                        navigation={navigation}
                        slidesPerGroup={4}
                        slidesPerView="auto"
                        spaceBetween={12}
                        style={{ width: '100%' }}
                    >
                        {items &&
                            items.length > 0 &&
                            items.map(item => (
                                <SwiperSlide key={item.userId} style={{ width: 82 }}>
                                    <CreatorCard
                                        id={item.userId}
                                        profileImageUrl={item?.profileImageUrl}
                                        username={item?.username}
                                        onClick={goToCreator}
                                    />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </SwiperWrapper>

                {!isMobile && (
                    <IconButton ref={navigationNextRef} type="button">
                        <ChevronRightBorderSmIcon />
                    </IconButton>
                )}
                <GlobalStyle />
            </SliderWrapper>
        </Column>
    );
};
