import { FlexGrow, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { CollaborationStatusesUi } from 'constants/defaults/collaborations';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import { UserJoinedFlow } from 'pages/CollaborationsPage/Details/Footer/UserJoinedFlow';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { bucmUserStores } from 'stores/bucm-user';
import { collaborationEffects, collaborationStores } from 'stores/collaboration-details';
import { participateCollaborationEffects } from 'stores/participate-collaboration';

const { $collaborationData, $isUserJoinedCollaboration } = collaborationStores;
const { checkIsMyCollaborationFx, getMyCollaborationFx } = collaborationEffects;
const { $bucmUser } = bucmUserStores;

interface Props {
    collaborationId: string;
}

export const Footer: FC<Props> = ({ collaborationId }) => {
    const collaboration = useStore($collaborationData);
    const bucmUser = useStore($bucmUser);
    const isUserJoinedCollaboration = useStore($isUserJoinedCollaboration);

    useEffect(() => {
        if (bucmUser.userId) {
            checkIsMyCollaborationFx({ bcmUserId: bucmUser.userId, collaborationId });
        }
    }, [bucmUser, collaborationId]);

    useEffect(() => {
        if (bucmUser.userId && isUserJoinedCollaboration) {
            getMyCollaborationFx({ bcmUserId: bucmUser.userId, collaborationId });
        }
    }, [bucmUser, collaborationId, isUserJoinedCollaboration]);

    const joinCollaboration = () => {
        if (bucmUser.userId && collaborationId) {
            participateCollaborationEffects.joinCollaborationFx({
                collaborationId,
                userId: bucmUser.userId
            });
        }
    };

    const requestParticipation = () => {
        if (bucmUser.userId && collaboration?.id) {
            participateCollaborationEffects.requestParticipationFx({
                collaborationId: collaboration.id,
                userId: bucmUser.userId
            });
        }
    };

    const getButtonState = () => {
        switch (true) {
            case collaboration?.autoApproveMedia && collaboration?.autoApproveParticipants: {
                return {
                    text: 'Join Collaboration',
                    onClick: joinCollaboration
                };
            }
            case collaboration?.autoApproveMedia && !collaboration?.autoApproveParticipants: {
                return {
                    text: 'Request to participate',
                    onClick: requestParticipation
                };
            }
            case !collaboration?.autoApproveMedia && collaboration?.autoApproveParticipants: {
                return {
                    text: 'Yes, I want to participate',
                    onClick: joinCollaboration
                };
            }
            default: {
                return {
                    text: '',
                    onClick: () => {}
                };
            }
        }
    };

    const buttonState = getButtonState();

    if (collaboration?.collaborationState === CollaborationStatusesUi.Ended) {
        return (
            <Section justifyCenter>
                <Button disabled>Collaboration Ended</Button>
            </Section>
        );
    }

    if (collaboration?.isFull && !isUserJoinedCollaboration) {
        return (
            <FlexGrow
                alignCenter
                flexDirection={isMobile ? 'column' : 'row'}
                justifyBetween={!isMobile}
                justifyCenter={isMobile}
            >
                <Row marginBottom={isMobile ? '24px' : '0'} width={isMobile ? 'auto' : '460px'}>
                    <Text alignTextCenter={isMobile} color={grey[500]} size="-1">
                        Unfortunately this Collaboration is full. You can check out other Collaborations or come back
                        later if a spot opens up.
                    </Text>
                </Row>
                <Button disabled>Collaboration Full</Button>
            </FlexGrow>
        );
    }

    if (isUserJoinedCollaboration) {
        return <UserJoinedFlow collaborationId={collaboration?.id || ''} />;
    }

    if (collaboration?.collaborationState === CollaborationStatusesUi.StartingSoon) {
        return (
            <Section justifyCenter>
                <Button disabled>{buttonState.text}</Button>
            </Section>
        );
    }

    return (
        <Section justifyCenter>
            <Button onClick={buttonState.onClick}>{buttonState.text}</Button>
        </Section>
    );
};
