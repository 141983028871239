import slideOneImage from 'assets/img/collaboration-guide/slide-1.png';
import slideTwoImage from 'assets/img/collaboration-guide/slide-2.png';
import slideThreeImage from 'assets/img/collaboration-guide/slide-3.png';
import slideOneMobileImage from 'assets/img/collaboration-guide/slide-mobile-1.png';
import slideTwoMobileImage from 'assets/img/collaboration-guide/slide-mobile-2.png';
import slideThreeMobileImage from 'assets/img/collaboration-guide/slide-mobile-3.png';
import { SlideProps } from './Slide';

export const slides: SlideProps[] = [
    {
        imgSrc: slideOneImage,
        mobileSrc: slideOneMobileImage,
        title: 'Congratulations',
        description: 'You successfully submitted your video to the Collaboration.'
    },
    {
        imgSrc: slideTwoImage,
        mobileSrc: slideTwoMobileImage,
        title: 'Your video will now be reviewed',
        description: 'You will receive the WOM reward once your video is reviewed and passes the quality guidelines.'
    },
    {
        imgSrc: slideThreeImage,
        mobileSrc: slideThreeMobileImage,
        title: 'Earn more WOM by sharing',
        description:
            'Some Collaborations reward more WOM when sharing your video on other social media accounts. Check if this Collaboration rewards for social sharing to earn more!'
    }
];
