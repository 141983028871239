import { CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { IconButton } from 'components/ui/buttons/IconButton';
import { ValidationStatusUI } from 'constants/services/video-statuses';
import { useStore } from 'effector-react';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { videoEffects, videoEvents, videoStores } from 'stores/video';
import { getValidationStatus } from 'utils/video';
import { CollaborationContent } from './components/CollaborationContent';
import { ReSubmitContent } from './components/ReSubmitContent';
import { SubmitContent } from './components/SubmitContent';
import { ValidateProcessContent } from './components/ValidateProcessContent';
import { ValidationFailContent } from './components/ValidationFailContent';
import { WithdrawContent } from './components/WithdrawContent';

const { $statusVideoModalId, $statusVideoData } = videoStores;
const { closeStatusVideoModal } = videoEvents;
const { getValidVideoFx } = videoEffects;

export const VideoStatusModal = () => {
    const statusVideoModalId = useStore($statusVideoModalId);
    const data = useStore($statusVideoData);
    const isLoading = useStore(getValidVideoFx.pending);

    const validStatus = useMemo(() => getValidationStatus(data?.validation, data?.isInCollaboration), [data]);
    const components: Partial<Record<ValidationStatusUI, ReactNode>> = {
        [ValidationStatusUI.Submit]: <SubmitContent data={data} />,
        [ValidationStatusUI.ReSubmit]: <ReSubmitContent data={data} />,
        [ValidationStatusUI.WomValidationProcessing]: <ValidateProcessContent data={data} />,
        [ValidationStatusUI.Fail]: <ValidationFailContent data={data} />,
        [ValidationStatusUI.Live]: <WithdrawContent data={data} />,
        [ValidationStatusUI.CollaborationLive]: <CollaborationContent data={data} />
    };

    const close = () => closeStatusVideoModal();

    useEffect(() => {
        if (statusVideoModalId) {
            getValidVideoFx({ videoId: statusVideoModalId });
        }
    }, [statusVideoModalId]);

    return (
        <ModalWrapper visible={!!statusVideoModalId} onClose={close}>
            {isLoading ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : (
                <Column width="100%">
                    <Section justifyEnd marginBottom="24px">
                        <IconButton onClick={close}>
                            <CloseSmIcon />
                        </IconButton>
                    </Section>

                    {validStatus in components ? components[validStatus] : null}
                </Column>
            )}
        </ModalWrapper>
    );
};
