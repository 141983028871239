import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles/colors';
import styled from 'styled-components';

export const CardWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 24px;
`;

export const CardHeader = styled(Section)`
    align-items: center;
    padding: 4px 10px;
`;

export const Dot = styled.div`
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    background-color: ${grey['500']};
    border-radius: 50px;
`;

export const CardBody = styled(Section)`
    flex-wrap: nowrap;
    padding-top: 8px;
    align-items: stretch;
`;

export const VideoWrapper = styled.div`
    width: 270px;
    height: 460px;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${grey['900']};
`;

export const VideoInfoWrapper = styled(Column)`
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 32px;
`;

export const SponsoredWrapper = styled.div`
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    background-color: ${grey[800]};
`;
