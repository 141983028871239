import * as data from './data';
import * as payment from './payment';
import * as user from './user';
import * as videos from './videos';
import * as wallet from './wallet';

export const womAPI = {
    user,
    wallet,
    data,
    payment,
    videos
};
