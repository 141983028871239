import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text, TextProps } from 'components/ui/typography/Text';
import React, { FC } from 'react';

interface Props extends TextProps {
    items: string[];
    indent?: number;
    center?: boolean;
}

export const TextList: FC<Props> = ({ items, center, indent = 8, ...textProps }) => (
    <Column noWrap alignCenter={center} width="100%">
        {items.map((item, index) => (
            <Section
                key={index}
                justifyCenter={center}
                marginBottom={index < items.length - 1 ? `${indent}px` : undefined}
            >
                <Text alignTextCenter={center} {...textProps}>
                    {item}
                </Text>
            </Section>
        ))}
    </Column>
);
