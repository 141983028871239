import { PauseLgIcon, PlayLgIcon } from 'assets/icons';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Timeline } from 'components/video/Timeline';
import { Volume } from 'components/video/Volume';
import { useStore } from 'effector-react';
import React, { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactHlsPlayer from 'react-hls-player';
import { authStores } from 'stores/auth';
import { loginModal } from 'stores/initialize.modal';
import { playerEffects, playerStores, playerEvents } from 'stores/player';
import { quickSignalsEffects } from 'stores/quick-signals';
import { ControlsWrapper, IconWrapper, PlayerWrapper, Wrapper } from './styles';

const { $isMuted, $volume } = playerStores;
const { toggleMuteVolume, setVolume } = playerEvents;
const { registerViewFx } = playerEffects;
const { registerViewQsFx } = quickSignalsEffects;

const { modal } = loginModal;

interface Props {
    videoId?: string;
    videoSrc?: string | null;
    posterSrc?: string | null;
    isFullscreen?: boolean;
    onClick?: (e: MouseEvent<HTMLVideoElement>) => void;
    hideControls?: boolean;
    onTimeUpdate?: (time: number) => void;
    isCover?: boolean;
    onProgressChange?: (progress: number) => void;
    contentType?: 'video' | 'quick-signal';
}

// TODO handle hot keys

export const PlayerCard: FC<Props> = ({
    videoId,
    videoSrc,
    posterSrc,
    isFullscreen,
    hideControls,
    onClick,
    onTimeUpdate,
    isCover,
    onProgressChange,
    contentType = 'video'
}) => {
    const playerRef = useRef<HTMLVideoElement>(null);

    const [visible] = useStore(modal);

    const isMuted = useStore($isMuted);
    const volume = useStore($volume);
    const sessionId = useStore(authStores.$sessionId);

    const [isHovered, setIsHovered] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [totalDuration, setTotalDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const isAutoPlay = useMemo(() => {
        const win = window as any;

        if (isMobile) {
            // Check mixin app
            if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.MixinContext) {
                return false;
            } else if (win.MixinContext && typeof win.MixinContext.getContext === 'function') {
                return false;
            }
        }

        return true;
    }, []);

    const handleMouseEnter = () => {
        isFullscreen && !isMobile && setIsHovered(true);
    };

    const handleMouseLeave = () => {
        isFullscreen && !isMobile && setIsHovered(false);
    };

    const handleVideoLoaded = () => {
        setIsLoaded(true);

        if (playerRef.current) {
            setTotalDuration(playerRef.current.duration || 0);
        }
    };

    const handleTimeUpdate = () => {
        if (playerRef.current) {
            onTimeUpdate && onTimeUpdate(playerRef.current.currentTime || 0);
            setCurrentTime(playerRef.current.currentTime || 0);

            if (onProgressChange) {
                onProgressChange(Math.ceil((playerRef.current.currentTime * 100) / playerRef.current.duration));
            }
        }
    };

    const togglePlay = () => {
        if (playerRef.current) {
            if (playerRef.current.paused) {
                playerRef.current.play();
            } else {
                playerRef.current.pause();
            }
        }
    };

    const handlePlayClick = () => {
        if (!isLoaded) return;

        if (isFullscreen) {
            togglePlay();
        }
    };

    const handleVideoClick = (e: MouseEvent<HTMLVideoElement>) => {
        if (!isLoaded) return;

        if (isFullscreen) {
            togglePlay();
        }

        onClick && onClick(e);
    };

    const handleVideoPlay = () => {
        setIsPlaying(true);
    };

    const handleVideoPause = () => {
        setIsPlaying(false);
    };

    const setNewTime = (value: number) => {
        if (playerRef.current) {
            playerRef.current.currentTime = value;
        }
    };

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        if (videoId && sessionId) {
            switch (contentType) {
                case 'video':
                default:
                    registerViewFx({
                        videoId,
                        sessionId
                    });
                    break;
                case 'quick-signal':
                    registerViewQsFx(videoId);
                    break;
            }
        }
    }, [contentType, sessionId, videoId]);

    useEffect(() => {
        if (visible && playerRef.current) {
            playerRef.current.pause();
        }
    }, [visible]);

    if (!videoSrc) {
        return null;
    }

    return (
        <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <PlayerWrapper hasClick={!!onClick} isCover={isCover}>
                <ReactHlsPlayer
                    loop
                    playsInline
                    autoPlay={isAutoPlay}
                    height={isFullscreen ? '100%' : undefined}
                    muted={isMuted}
                    playerRef={playerRef}
                    poster={posterSrc || undefined}
                    src={videoSrc}
                    width="100%"
                    onClick={handleVideoClick}
                    onEnded={handleVideoPause}
                    onLoadedData={handleVideoLoaded}
                    onPause={handleVideoPause}
                    onPlay={handleVideoPlay}
                    onTimeUpdate={handleTimeUpdate}
                />
            </PlayerWrapper>

            {!isLoaded ? (
                <IconWrapper>
                    <Spinner />
                </IconWrapper>
            ) : (
                <>
                    {(isHovered || !isPlaying) && isFullscreen && (
                        <IconWrapper>
                            <IconButton size="60px" onClick={handlePlayClick}>
                                {isPlaying ? <PauseLgIcon /> : <PlayLgIcon />}
                            </IconButton>
                        </IconWrapper>
                    )}
                </>
            )}

            {!hideControls && (
                <ControlsWrapper isFullscreen={isFullscreen}>
                    <Timeline currentTime={currentTime} totalDuration={totalDuration} onChange={setNewTime} />
                    <MarginWrapper
                        marginBottom={!isFullscreen ? '4px' : undefined}
                        marginLeft={isFullscreen ? '8px' : 'auto'}
                    >
                        <Volume
                            isMuted={isMuted}
                            setVolume={setVolume}
                            toggleMuteVolume={toggleMuteVolume}
                            volume={volume}
                        />
                    </MarginWrapper>
                </ControlsWrapper>
            )}
        </Wrapper>
    );
};
