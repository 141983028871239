import { Column } from 'components/grid/wrappers/FlexWrapper';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors, grey } from 'constants/styles/colors';
import { MontserratFontFamily } from 'constants/styles/fonts';
import { allTransition, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MobileMenuWrapper = styled(Column)`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - ${headerHeight});
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 52px;
    background-color: ${baseColors.black};
    z-index: 9;
    overflow-y: auto;
`;

export const StyledNavLink = styled(NavLink)`
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    font-family: ${MontserratFontFamily};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${grey[500]};
    border-radius: 8px;
    ${allTransition};

    &:hover {
        background-color: ${grey[800]};
        color: ${baseColors.white};
    }

    &.active {
        font-weight: 700;
        color: ${baseColors.white};
    }
`;

export const IconWrapper = styled.i`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    ${flexCenter};
`;

export const StyledMobileLink = styled(Link)`
    width: 100%;
`;

export const StyledMobileButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    text-align: left;
`;
