import { Column } from 'components/grid/wrappers/FlexWrapper';
import { baseColors, grey } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const FullscreenVideoWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: ${baseColors.black};
    display: flex;
    justify-content: space-between;
`;

export const PlayerWrapper = styled.div`
    height: 100%;
    flex-grow: 1;
    ${flexCenter};
    position: relative;
`;

export const VideoWrapper = styled.div`
    width: 100%;
    max-width: 440px;
    height: 100%;
    ${flexCenter};
    position: relative;
`;

export const InfoWrapper = styled(Column)`
    width: 480px;
    height: 100%;
    flex-wrap: nowrap;
    padding: 16px;
    flex-shrink: 0;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${grey['600']};
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 11;
`;

export const ArrowsWrapper = styled(Column)`
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    align-items: center;
`;

export const InfoButtonWrapper = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 11;
`;
