import { WomSmIcon } from 'assets/icons';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { walletDataEffects, walletDataStores } from 'stores/wallet-data';
import { getFormattedDollars, getFormattedNumber } from 'utils/common';

interface Props {
    title?: string;
    wom?: number;
    small?: boolean;
}

export const Earnings: FC<Props> = ({ title = 'Earnings', wom = 0, small = false }) => {
    const ratesData = useStore(walletDataStores.$womRates);
    const usdRate =
        ratesData.rates && wom > 0
            ? ratesData.rates.find(item => item.assetName?.toLowerCase() === 'usd') || null
            : null;
    const usdValue = usdRate ? wom * (usdRate.price || 0) : 0;

    useEffect(() => {
        wom > 0 && walletDataEffects.getWomRatesFx();
    }, [wom]);

    return (
        <Column noWrap>
            <Row marginBottom="8px">
                <Text isBold size="-1">
                    {title}
                </Text>
            </Row>
            <Row alignCenter marginBottom="4px">
                <Text isBold size={small ? '1' : '4'}>
                    {getFormattedNumber(wom)}
                </Text>

                <Column justifyCenter marginLeft="8px">
                    <WomSmIcon />
                </Column>
            </Row>

            <Text size={small ? '-2' : '0'}>{getFormattedDollars(usdValue)} USD</Text>
        </Column>
    );
};
