import React, { FC, ReactNode } from 'react';
import { Disabled, HTMLButtonType } from 'types/interfaces/form';
import { ReactClick } from 'types/react';
import { IconWrapper, StyledButton } from './styles';

export interface ButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    buttonType?: 'primary' | 'red' | 'green' | 'secondary' | 'dark' | 'transparent';
    size?: 'default' | 'small' | 'middle';
    textSize?: 'small' | 'default';
    wide?: boolean;
    icon?: ReactNode;
    iconPosition?: 'left' | 'right';
    isBold?: boolean;
    noWrap?: boolean;
}

export const Button: FC<ButtonProps> = ({
    buttonType = 'primary',
    size = 'default',
    icon,
    iconPosition = 'right',
    textSize = 'default',
    children,
    type = 'button',
    isBold = true,
    ...props
}) => {
    const iconComponent = icon ? <IconWrapper iconPosition={iconPosition}>{icon}</IconWrapper> : null;

    return (
        <StyledButton buttonType={buttonType} isBold={isBold} size={size} textSize={textSize} type={type} {...props}>
            {iconComponent && iconPosition === 'left' && iconComponent}
            {children}
            {iconComponent && iconPosition === 'right' && iconComponent}
        </StyledButton>
    );
};
