export enum Step {
    Birthday = 'birthday',
    Gender = 'gender',
    Categories = 'categories'
}

export const titles: Record<Step, string> = {
    [Step.Birthday]: 'Add Birthday',
    [Step.Gender]: 'Add Gender',
    [Step.Categories]: 'Add Categories'
};

export const subtitles: Record<Step, string> = {
    [Step.Birthday]: 'Your birthday will not be made public.',
    [Step.Gender]: 'Choose the gender that most accurately describes you.',
    [Step.Categories]: 'What are your interests?'
};

export enum AdditionalRegisterFieldName {
    Day = 'day',
    Month = 'month',
    Year = 'year',
    FullBirthday = 'fullBirthday',
    Gender = 'gender',
    Categories = 'categoryTagIds'
}

export const ageThreshold = 13;
export const minimumAmountOfCategories = 2;
