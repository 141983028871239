import styled from 'styled-components';
import { media } from 'constants/styles/media';
import { baseColors } from 'constants/styles/colors';

export const Empty = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 140px;
    padding-bottom: 140px;
    background-color: ${baseColors.darkGrey};

    ${media.laptop(`
        padding-top: 0;
        padding-bottom: 0;
    `)};
`;
