import { transitionTime } from 'constants/styles';
import { grey } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const ButtonLinkWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px;
    width: 100%;
    border-radius: 8px;
    transition: background-color ${transitionTime} linear;

    ${media.laptop(`
        width: 420px;
    `)};

    &:hover {
        background-color: ${grey[800]};
    }
`;
