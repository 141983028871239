import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { HtmlText } from 'components/ui/typography/HtmlText';
import { Text } from 'components/ui/typography/Text';
import React, { ChangeEvent, FC, useState } from 'react';
import { NoopType } from 'types';
import { TextWrapper } from './styles';

interface Props {
    data: BULLZ.BrandVoucherResponse;
    onSubmit: NoopType;
}

export const TermsStep: FC<Props> = ({ data, onSubmit }) => {
    const [isAccepted, setIsAccepted] = useState(false);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAccepted(e.target.checked);
    };

    return (
        <>
            <Section justifyCenter marginBottom="28px">
                <Text alignTextCenter isBold>
                    Terms & Conditions
                </Text>
            </Section>

            <Section marginBottom="24px">
                <TextWrapper>
                    <HtmlText dangerouslySetInnerHTML={{ __html: data.termsAndConditions || '' }} />
                </TextWrapper>
            </Section>

            <Section marginBottom="24px">
                <Checkbox
                    checked={isAccepted}
                    label="This reward is non-refundable. The full terms and conditions are available on the digital rewards claim site."
                    onChange={handleCheckboxChange}
                />
            </Section>

            <Button wide disabled={!isAccepted} onClick={onSubmit}>
                Continue
            </Button>
        </>
    );
};
