import {
    AttachSmIcon,
    CommentSmIcon,
    HeartFilledSmIcon,
    HeartSmIcon,
    InfoSmIcon,
    LinkSmIcon,
    ReportSmIcon,
    SaveSmIcon,
    ShareFilledSmIcon,
    ThreeDotsSmIcon,
    VerificationSmIcon
} from 'assets/icons';
import { PlayerCard } from 'components/cards/PlayerCard';
import { CustomImage } from 'components/common/CustomImg/styles';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { Shares } from 'components/ui/Shares';
import { Tag } from 'components/ui/Tag';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { videoClassNamePrefix } from 'constants/defaults/data';
import { SearchCategory } from 'constants/enum';
import {
    disclaimerButtonText,
    disclaimerText,
    disclaimerTextForCollaboration,
    disclaimerTitle
} from 'constants/messages';
import { AppRoute } from 'constants/routes';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useInView } from 'react-intersection-observer';
import { Link, useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { homeEvents, homeStores } from 'stores/home';
import { infoModal, loginModal, reportVideoModal } from 'stores/initialize.modal';
import { savedVideoEffects } from 'stores/saved-video';
import { userFollowEffects } from 'stores/user-follow';
import { videoEffects, videoStores } from 'stores/video';
import { getVideoLink, goToExternalLink } from 'utils/common';
import { CardBody, CardHeader, CardWrapper, Dot, SponsoredWrapper, VideoInfoWrapper, VideoWrapper } from './styles';

const { openModal: openDisclaimerModal } = infoModal;
const { setFocusedVideoId } = homeEvents;
const { $focusedVideoId } = homeStores;
const { registerEventVideo } = videoEffects;

const sponsoredTag = 'Sponsored';

interface Props {
    video: BULLZ.PlaylistVideoResponse;
    onFullscreen?: (id: string) => void;
}

export const VideoCard: FC<Props> = ({ video, onFullscreen }) => {
    const history = useHistory();
    const {
        id,
        username,
        profileImageUrl,
        hashTags,
        isTrusted,
        streaming,
        primaryProduct,
        ownerId,
        engagement,
        isFollowed,
        engagementUser,
        isInCollaboration
    } = video;
    const user = useStore(authStores.$user);
    const isAuth = useStore(authStores.$isAuth);

    const tags = hashTags ? hashTags.filter(tag => !!tag && tag.toLocaleLowerCase() !== sponsoredTag) : [];

    const focusedVideoId = useStore($focusedVideoId);
    const isLoadingRegisterEventVideo = useStore(videoStores.$isLoadingRegisterEventVideo);

    const handleSaveVideo = () => {
        if (isAuth) {
            savedVideoEffects.saveVideoFx({
                videoId: id,
                isSave: !engagementUser?.isSaved
            });
        } else {
            loginModal.openModal();
        }
    };

    const handleReportVideo = () => {
        if (isAuth) {
            reportVideoModal.openModal({ videoId: id });
        } else {
            loginModal.openModal();
        }
    };

    const additionalOptions = [
        {
            id: 0,
            title: 'Save',
            action: handleSaveVideo,
            iconComponent: (
                <SaveSmIcon color={engagementUser?.isSaved ? baseColors.white : grey[500]} height="14px" width="14px" />
            )
        },
        ...(user?.userId !== ownerId
            ? [
                  {
                      id: 1,
                      title: 'Report',
                      action: handleReportVideo,
                      iconComponent: <ReportSmIcon color={grey[500]} height="14px" width="14px" />
                  }
              ]
            : [])
    ];

    const handleTagClick = (tag: string) => {
        history.push(`${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&tagId=${tag}&name=${tag}`);
    };

    const handleInfoClick = () => {
        openDisclaimerModal({
            title: disclaimerTitle,
            text: isInCollaboration ? disclaimerTextForCollaboration : disclaimerText,
            buttonText: disclaimerButtonText
        });
    };

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Link Copied');
        }
    };

    const handleFullscreenClick = () => {
        if (id && onFullscreen) {
            onFullscreen(id);
        }
    };

    const goToWebsite = () => {
        primaryProduct?.link && goToExternalLink(primaryProduct.link);
    };

    const { ref: observerRef, inView } = useInView({
        threshold: 0.7
    });

    const handleFollow = () => {
        if (user)
            userFollowEffects.followUserFx({
                userId: user.userId,
                creatorId: ownerId,
                isFollow: !isFollowed
            });
    };

    const handleLikeVideo = () => {
        if (isAuth) {
            if (!isLoadingRegisterEventVideo) {
                registerEventVideo({
                    videoId: id,
                    liked: !engagementUser?.isLiked
                });
            }
        } else {
            loginModal.openModal();
        }
    };

    useEffect(() => {
        if (id && inView) {
            setFocusedVideoId(id);
        }
    }, [id, inView]);

    return (
        <CardWrapper ref={observerRef} className={`${videoClassNamePrefix}${id}`}>
            <CardHeader>
                <Link to={`${AppRoute.Profile}/${ownerId}`}>
                    <Row alignCenter>
                        <AvatarImg src={profileImageUrl || undefined} />

                        <Column marginLeft="4px">
                            <Text size="-2">@{username}</Text>
                        </Column>
                    </Row>
                </Link>

                {isTrusted && (
                    <Column marginLeft="4px">
                        <VerificationSmIcon />
                    </Column>
                )}

                {user && user.userId !== ownerId && (
                    <>
                        <Column marginLeft="16px">
                            <Dot />
                        </Column>

                        <Column marginLeft="16px">
                            <TextButton onClick={handleFollow}>{isFollowed ? 'Following' : 'Follow'} </TextButton>
                        </Column>
                    </>
                )}
            </CardHeader>

            <CardBody>
                <VideoWrapper>
                    {focusedVideoId === id ? (
                        <PlayerCard
                            posterSrc={streaming?.screenGrabUrl}
                            videoId={id}
                            videoSrc={streaming?.hlsUrl}
                            onClick={handleFullscreenClick}
                        />
                    ) : (
                        <CustomImage src={streaming?.screenGrabUrl || undefined} />
                    )}
                </VideoWrapper>

                <VideoInfoWrapper>
                    <Column width="100%">
                        <Section alignCenter justifyBetween>
                            <Row alignCenter>
                                <Text isUppercase color={grey['500']} size="-2">
                                    IN THIS VIDEO
                                </Text>

                                {isInCollaboration && (
                                    <Column marginLeft="8px">
                                        <SponsoredWrapper>
                                            <Text size="-2">{sponsoredTag}</Text>
                                        </SponsoredWrapper>
                                    </Column>
                                )}
                            </Row>

                            <CopyToClipboard text={getVideoLink(id)} onCopy={handleCopy}>
                                <IconButton color={grey['500']}>
                                    <AttachSmIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </Section>

                        {tags.length > 0 && (
                            <Section marginTop="16px">
                                {tags.map(tag => (
                                    <Column key={tag} marginBottom="4px" marginRight="4px">
                                        <Tag subject={tag} onClick={handleTagClick}>
                                            #{tag}
                                        </Tag>
                                    </Column>
                                ))}
                            </Section>
                        )}

                        {primaryProduct && primaryProduct.link && (
                            <Section marginTop="12px">
                                <Button icon={<LinkSmIcon />} isBold={false} size="small" onClick={goToWebsite}>
                                    Go to Website
                                </Button>
                            </Section>
                        )}
                    </Column>

                    <Column>
                        <MarginWrapper marginTop="16px">
                            <IconButton
                                color={engagementUser?.isLiked ? baseColors.white : grey['500']}
                                size="auto"
                                onClick={handleLikeVideo}
                            >
                                <Column alignCenter>
                                    {engagementUser?.isLiked ? <HeartFilledSmIcon /> : <HeartSmIcon />}
                                    <MarginWrapper marginTop="2px">
                                        <Text color="inherit" size="-2">
                                            {engagement?.likes || 0}
                                        </Text>
                                    </MarginWrapper>
                                </Column>
                            </IconButton>
                        </MarginWrapper>

                        <MarginWrapper marginTop="16px">
                            <IconButton color={grey['500']} size="auto" onClick={handleFullscreenClick}>
                                <Column alignCenter>
                                    <CommentSmIcon />
                                    <MarginWrapper marginTop="2px">
                                        <Text color="inherit" size="-2">
                                            {engagement?.commentCount || 0}
                                        </Text>
                                    </MarginWrapper>
                                </Column>
                            </IconButton>
                        </MarginWrapper>

                        <MarginWrapper marginTop="16px">
                            <OptionsPopover placement="right-end" renderItems={<Shares videoId={id} />}>
                                <IconButton color={grey['500']} size="auto">
                                    <Column alignCenter>
                                        <ShareFilledSmIcon />
                                        <MarginWrapper marginTop="2px">
                                            <Text color="inherit" size="-2">
                                                {engagement?.shares || 0}
                                            </Text>
                                        </MarginWrapper>
                                    </Column>
                                </IconButton>
                            </OptionsPopover>
                        </MarginWrapper>

                        <MarginWrapper marginTop="16px">
                            <IconButton color={grey['500']} onClick={handleInfoClick}>
                                <InfoSmIcon />
                            </IconButton>
                        </MarginWrapper>

                        <MarginWrapper marginTop="16px">
                            <OptionsPopover noPadding items={additionalOptions} placement="right-end" width="117px">
                                <IconButton color={grey['500']}>
                                    <ThreeDotsSmIcon />
                                </IconButton>
                            </OptionsPopover>
                        </MarginWrapper>
                    </Column>
                </VideoInfoWrapper>
            </CardBody>
        </CardWrapper>
    );
};
