import { requiredShortMessage } from 'constants/messages';
import { teamEffects } from 'stores/team';
import * as Yup from 'yup';

const { existsTeamFx } = teamEffects;

export interface TeamNameFormValues {
    teamName: string;
}

export const createTeamInitialValues: TeamNameFormValues = {
    teamName: ''
};

export const createTeamSchema = Yup.object().shape({
    teamName: Yup.string()
        .required(requiredShortMessage)
        .min(3, '')
        .max(40, '')
        .test('api validation', 'Team name is not available', async teamName => {
            try {
                const response = await existsTeamFx({ teamName });

                if (response?.exists) {
                    return false;
                }

                return true;
            } catch (e) {
                return false;
            }
        })
});
