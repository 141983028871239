import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

// Constants

const defaultCategoriesParams = {
    limit: 100
};

//Events

const resetCategories = createEvent();

//Effects

const getCategoriesFx = createEffect({
    handler: async (params?: Paths.Categories.Get.QueryParameters) =>
        await API.categories.getCategories(params || defaultCategoriesParams)
});

//Stores

const $categories = createStore<BULLZ.GetCategoriesResponse>({})
    .on(getCategoriesFx.doneData, (_, newState) => newState.data)
    .reset(resetCategories);

//Exports

export const categoriesStores = { $categories };

export const categoriesEffects = {
    getCategoriesFx
};

export const categoriesEvents = { resetCategories };
