import { VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { quickSignalsEffects, quickSignalsStores } from 'stores/quick-signals';
import { Empty } from '../QuickSignalsTabs/styles';
import { ContentWrapper, MemberWrapper, TextWrapper } from './styles';

const { $quickSignalsFollowers, $quickSignalsInfo } = quickSignalsStores;
const { getQuickSignalsFollowersFx } = quickSignalsEffects;

export const TabContentMembers = () => {
    const history = useHistory();

    const { data } = useStore($quickSignalsFollowers);
    const items = useMemo(() => data?.items, [data]);
    const quickSignalsInfo = useStore($quickSignalsInfo);

    const [info] = quickSignalsInfo?.data ? quickSignalsInfo?.data : [];
    const id = info?.id;

    const isLoading = useStore(getQuickSignalsFollowersFx.pending);

    useEffect(() => {
        id && getQuickSignalsFollowersFx(id);
    }, [id]);

    return (
        <ContentWrapper>
            {items && items?.length > 0 && (
                <Column height="100%" width="100%">
                    {items?.map(({ userId, avatarUrl, username, isTrusted }) => (
                        <Section key={userId} justifyBetween noWrap marginBottom="24px">
                            <MemberWrapper onClick={() => history.push(`${AppRoute.Profile}/${userId}`)}>
                                <Row alignCenter noWrap>
                                    <AvatarImg src={avatarUrl || undefined} />
                                    <TextWrapper>
                                        <Text size="-2">@{username}</Text>
                                    </TextWrapper>

                                    {isTrusted && (
                                        <Column marginLeft="10px">
                                            <VerificationSmIcon height="20px" width="20px" />
                                        </Column>
                                    )}
                                </Row>
                            </MemberWrapper>
                        </Section>
                    ))}
                </Column>
            )}

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}

            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    <Text color={grey[500]}>No one has joined your Channel yet</Text>
                </Empty>
            )}
        </ContentWrapper>
    );
};
