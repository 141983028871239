import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import {
    ChevronLeftBgLgIcon,
    ChevronRightBgLgIcon,
    CloseBgMdIcon,
    ConfettiLgIcon,
    InfoSmIcon,
    ReportSmIcon,
    WrongLgIcon
} from 'assets/icons';
import { PlayerCard } from 'components/cards/PlayerCard';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { UserProfile } from 'components/video/UserProfile';
import { Volume } from 'components/video/Volume';
import { asyncErrorMessage, disclaimerButtonText, disclaimerText, disclaimerTitle } from 'constants/messages';
import { womErrorStatusCode } from 'constants/services/quick-signals';
import { baseColors, grey } from 'constants/styles';
import { useStore } from 'effector-react';
import { useNonScrolledBackground } from 'hooks/useNonScrolledBackground';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { authStores } from 'stores/auth';
import { infoModal, loginModal, reportVideoModal, walletGuideModal } from 'stores/initialize.modal';
import { quickSignalsEffects } from 'stores/quick-signals';
import { qsEffects, qsEvents, qsStores } from 'stores/quick-signals-trending';
import { walletEffects, walletStores } from 'stores/wallet';
import { playerEvents, playerStores } from 'stores/player';
import { goToHelpDesk, noop } from 'utils/common';
import { JoinCard } from './components/JoinCard';
import { MobileView } from './components/MobileView';
import {
    BottomBarWrapper,
    Card,
    CloseButtonWrapper,
    LeftCardsWrapper,
    LeftIconWrapper,
    ModalWrapper,
    PlayerWrapper,
    RightCardsWrapper,
    RightIconWrapper,
    StoriesLine,
    StoriesLines,
    TopBarWrapper,
    VideoWrapper
} from './styles';

const { $isVisibleQSModal, $currentQSUser, $currentUserData, $qsUsers } = qsStores;
const { getUserQSVideosFx, getQSUsersFx } = qsEffects;
const { closeQSModal } = qsEvents;
const { $user, $isAuth } = authStores;
const { openModal: openInfoModal } = infoModal;
const { $isMuted, $volume } = playerStores;
const { toggleMuteVolume, setVolume } = playerEvents;

// TODO study logic and refactor!!!!!

export const QSModal = () => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentUserIndex, setCurrentUserIndex] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);

    const hasWallet = useStore(walletStores.$hasWallet);
    const womUserIsLoaded = useStore(walletStores.$womUserIsLoaded);

    const currentUserVideos = useStore($currentUserData);
    const users = useStore($qsUsers);
    const isVisible = useStore($isVisibleQSModal);
    const currentQSUser = useStore($currentQSUser);
    const authUser = useStore($user);
    const userIsLoading = useStore(getUserQSVideosFx.pending);
    const usersIsLoading = useStore(getQSUsersFx.pending);
    const isAuth = useStore($isAuth);
    const isMuted = useStore($isMuted);
    const volume = useStore($volume);

    const isLoading = userIsLoading || usersIsLoading;

    const prevIndexStart = currentUserIndex - 2 < 0 ? 0 : currentUserIndex - 2;

    const currentUser = users[currentUserIndex];

    const isVisibleVideos =
        !!currentUserVideos &&
        !!currentUserVideos.channelInfo &&
        (currentUserVideos.channelInfo?.isSubscribed || authUser?.userId === currentUser.userProfile?.id);

    const onClose = () => closeQSModal();

    const handleProgress = (value: number) => {
        setCurrentProgress(value);
    };

    const goToNextUser = (nextUser?: BULLZ.GetQuickSignalUserResponse) => {
        if (nextUser && currentUserIndex < users.length - 1 && nextUser.userProfile?.id) {
            getUserQSVideosFx(nextUser.userProfile.id);
            setCurrentUserIndex(currentUserIndex + 1);
            setCurrentProgress(0);
            setCurrentVideoIndex(0);
        }
    };

    const goToPrevUser = (prevUser?: BULLZ.GetQuickSignalUserResponse) => {
        if (currentUserIndex !== 0 && prevUser && prevUser.userProfile?.id) {
            getUserQSVideosFx(prevUser.userProfile.id);
            setCurrentUserIndex(currentUserIndex - 1);
            setCurrentProgress(0);
            setCurrentVideoIndex(0);
        }
    };

    const goToNextVideo = () => {
        setCurrentProgress(0);
        setCurrentVideoIndex(currentVideoIndex + 1);
    };

    const goToPrevVideo = () => {
        setCurrentProgress(0);
        setCurrentVideoIndex(currentVideoIndex - 1);
    };

    const goToNext = () => {
        const nextUser = users[currentUserIndex + 1];

        if (
            (currentUserVideos?.items &&
                currentVideoIndex === currentUserVideos.items.length - 1 &&
                currentUserIndex === users.length - 1) ||
            (!isVisibleVideos && currentUserIndex === users.length - 1)
        ) {
            closeQSModal();
            return;
        }

        if (isVisibleVideos) {
            if (currentUserVideos?.items && currentVideoIndex < currentUserVideos.items.length - 1) {
                goToNextVideo();
            } else {
                goToNextUser(nextUser);
            }
        } else {
            goToNextUser(nextUser);
        }
    };

    const goToPrev = () => {
        const prevUser = users[currentUserIndex - 1];
        setCurrentProgress(0);

        if (currentVideoIndex === 0 && currentUserIndex === 0) {
            closeQSModal();
            return;
        }

        if (isVisibleVideos) {
            if (currentVideoIndex > 0) {
                goToPrevVideo();
            } else {
                goToPrevUser(prevUser);
            }
        } else {
            goToPrevUser(prevUser);
        }
    };

    const goToUser = (item: BULLZ.GetQuickSignalUserResponse) => () => {
        const userId = item.userProfile?.id;

        if (userId) {
            const index = users.findIndex(user => user.userProfile?.id === userId);

            if (index !== -1) {
                getUserQSVideosFx(userId);
                setCurrentUserIndex(index);
                setCurrentVideoIndex(0);
                setCurrentProgress(0);
            }
        }
    };

    const handleSlideNext = () => {
        const nextUser = users[currentUserIndex + 1];
        goToNextUser(nextUser);
    };

    const handleSlidePrev = () => {
        const prevUser = users[currentUserIndex - 1];
        goToPrevUser(prevUser);
    };

    const handleInfoClick = () => {
        openInfoModal({
            title: disclaimerTitle,
            text: disclaimerText,
            buttonText: disclaimerButtonText
        });
    };

    const handleSubscribeError = (code?: string) => {
        if (code && code === womErrorStatusCode) {
            openInfoModal({
                icon: WrongLgIcon,
                title: 'Not enough WOM',
                text: 'Don’t worry! Just add WOM to your wallet and then you can join My Cool Quick Signals.',
                buttonText: 'Go to Wallet',
                cancelText: 'Not Now',
                onClick: () => walletEffects.existsWalletPinFx(),
                onCloseClick: noop
            });
        } else {
            openInfoModal({
                icon: WrongLgIcon,
                title: asyncErrorMessage,
                text: 'Please try again or contact our support team.',
                buttonText: 'Try Again',
                cancelText: 'contact support',
                onClick: () => noop,
                onCloseClick: goToHelpDesk
            });
        }
    };

    const subscribeRequest = (channelId: string) => {
        quickSignalsEffects
            .subscribeToQSChannelFx(channelId)
            .then(() => {
                if (currentUser?.userProfile?.id) {
                    getUserQSVideosFx(currentUser.userProfile.id);
                    setCurrentVideoIndex(0);
                    setCurrentProgress(0);
                }

                // TODO onClicks
                openInfoModal({
                    icon: ConfettiLgIcon,
                    title: 'Congratulations!',
                    text: `You have joined ${currentUserVideos?.channelInfo?.name}`,
                    buttonText: 'View Channel',
                    cancelText: 'See All My Channels',
                    onClick: () => noop,
                    onCloseClick: noop
                });
            })
            .catch(({ error }) => {
                handleSubscribeError(error.code as string);
            });
    };

    const handleJoin = (channelId: string) => {
        if (!womUserIsLoaded) {
            return;
        }

        if (hasWallet) {
            openInfoModal({
                title: 'Confirm Subscription',
                text: 'Are you ready to join My Cool Quick Signals?',
                qsAvatar: currentUserVideos?.userProfile?.avatarUrl || defaultAvatar,
                buttonText: 'Yes',
                cancelText: 'No',
                onClick: () => subscribeRequest(channelId),
                onCloseClick: noop
            });
        } else {
            closeQSModal();
            walletGuideModal.openModal();
        }
    };

    const handleReportVideo = () => {
        const videoId = currentUserVideos?.items && currentUserVideos?.items[currentVideoIndex].id;
        if (isAuth && videoId) {
            reportVideoModal.openModal({
                videoId: videoId,
                title:
                    'We are sorry that you found this content inappropriate. Please indicate what you found unacceptable about the video.',
                text: 'We will review your feedback and take appropriate action. You will no longer see this video.',
                isQuickSignal: true
            });
        } else {
            loginModal.openModal();
        }
    };

    useEffect(() => {
        if (currentQSUser?.userProfile?.id) {
            getUserQSVideosFx(currentQSUser.userProfile.id);
            setCurrentUserIndex(currentQSUser.index);
        }
    }, [currentQSUser]);

    useEffect(() => {
        if (isVisible) {
            getQSUsersFx();
        }

        if (!isVisible) {
            setCurrentVideoIndex(0);
            setCurrentUserIndex(0);
            setCurrentProgress(0);
        }
    }, [isVisible]);

    useNonScrolledBackground(isVisible);

    if (!isVisible || users.length === 0) {
        return null;
    }

    if (isMobile) {
        return (
            <MobileView
                currentProgress={currentProgress}
                currentUser={currentUser}
                currentUserIndex={currentUserIndex}
                currentUserVideos={currentUserVideos}
                currentVideoIndex={currentVideoIndex}
                isLoading={isLoading}
                isVisibleVideos={isVisibleVideos}
                users={users}
                onClose={onClose}
                onInfoClick={handleInfoClick}
                onJoin={handleJoin}
                onNextVideo={goToNextVideo}
                onPrevVideo={goToPrevVideo}
                onProgressChange={handleProgress}
                onSlideNext={handleSlideNext}
                onSlidePrev={handleSlidePrev}
            />
        );
    }

    return (
        <ModalWrapper>
            <CloseButtonWrapper>
                <IconButton color={grey['900']} hoverColor={grey['800']} size="40px" onClick={onClose}>
                    <CloseBgMdIcon />
                </IconButton>
            </CloseButtonWrapper>

            <PlayerWrapper>
                {currentUserIndex !== 0 && (
                    <LeftCardsWrapper>
                        {users.slice(prevIndexStart, currentUserIndex).map(item => (
                            <div key={item.userProfile?.id}>
                                <Card onClick={goToUser(item)}>
                                    <QsAvatar
                                        isSubscribed
                                        hasNewVideos={item.hasNewVideos}
                                        url={item.userProfile?.avatarUrl || ''}
                                    />

                                    <Section justifyCenter marginTop="8px">
                                        <Text alignTextCenter size="-1">
                                            {item.userProfile?.username}
                                        </Text>
                                    </Section>
                                </Card>
                            </div>
                        ))}
                    </LeftCardsWrapper>
                )}

                <LeftIconWrapper type="button" onClick={goToPrev}>
                    <ChevronLeftBgLgIcon />
                </LeftIconWrapper>

                <RightIconWrapper type="button" onClick={goToNext}>
                    <ChevronRightBgLgIcon />
                </RightIconWrapper>

                {isLoading ? (
                    <Section justifyCenter>
                        <Spinner />
                    </Section>
                ) : (
                    <>
                        {isVisibleVideos ? (
                            <VideoWrapper>
                                <TopBarWrapper>
                                    {currentUserVideos?.items?.length && (
                                        <StoriesLines count={currentUserVideos.items.length}>
                                            {currentUserVideos.items.map((_, index) => (
                                                <StoriesLine
                                                    key={index}
                                                    done={index < currentVideoIndex}
                                                    isCurrent={index === currentVideoIndex}
                                                    progress={currentProgress}
                                                />
                                            ))}
                                        </StoriesLines>
                                    )}

                                    {currentUser && currentUser.userProfile && (
                                        <Section justifyEnd noWrap marginTop="14px">
                                            <UserProfile
                                                avatar={currentUser.userProfile.avatarUrl || ''}
                                                isFollowed={currentUser.userProfile.isFollowed}
                                                isTrusted={currentUser.userProfile.isTrusted}
                                                userId={currentUser.userProfile.id}
                                                username={currentUser.userProfile.username || ''}
                                            />
                                        </Section>
                                    )}
                                </TopBarWrapper>

                                <PlayerCard
                                    hideControls
                                    contentType="quick-signal"
                                    posterSrc={
                                        currentUserVideos?.items?.length
                                            ? currentUserVideos?.items[currentVideoIndex].thumbnailUrl
                                            : ''
                                    }
                                    videoId={
                                        currentUserVideos?.items?.length
                                            ? currentUserVideos?.items[currentVideoIndex].id
                                            : ''
                                    }
                                    videoSrc={
                                        currentUserVideos?.items?.length
                                            ? currentUserVideos?.items[currentVideoIndex].streamUrl
                                            : ''
                                    }
                                    onProgressChange={handleProgress}
                                />

                                <BottomBarWrapper>
                                    <Section justifyEnd marginTop="12px">
                                        <Volume
                                            isMuted={isMuted}
                                            setVolume={setVolume}
                                            toggleMuteVolume={toggleMuteVolume}
                                            volume={volume}
                                        />
                                    </Section>
                                    <Section justifyEnd marginTop="12px">
                                        <IconButton onClick={handleInfoClick}>
                                            <InfoSmIcon />
                                        </IconButton>
                                    </Section>

                                    {authUser?.userId !== currentQSUser?.userProfile?.id && (
                                        <Section justifyEnd marginTop="12px">
                                            <IconButton onClick={handleReportVideo}>
                                                <ReportSmIcon color={baseColors.white} />
                                            </IconButton>
                                        </Section>
                                    )}
                                </BottomBarWrapper>
                            </VideoWrapper>
                        ) : (
                            <JoinCard
                                avatar={currentUser.userProfile?.avatarUrl || undefined}
                                channelId={currentUserVideos?.channelInfo?.id}
                                description={currentUserVideos?.channelInfo?.description}
                                name={currentUserVideos?.channelInfo?.name}
                                totalSignalsCount={currentUserVideos?.channelInfo?.totalSignalsCount}
                                utcCreated={currentUserVideos?.channelInfo?.utcCreated}
                                womValue={currentUserVideos?.channelInfo?.womValue}
                                onJoin={handleJoin}
                            />
                        )}
                    </>
                )}

                {currentUserIndex !== users.length - 1 && (
                    <RightCardsWrapper>
                        {users.slice(currentUserIndex + 1, currentUserIndex + 3).map(item => (
                            <div key={item.userProfile?.id}>
                                <Card onClick={goToUser(item)}>
                                    <QsAvatar
                                        isSubscribed
                                        hasNewVideos={item.hasNewVideos}
                                        url={item.userProfile?.avatarUrl || ''}
                                    />

                                    <Section justifyCenter marginTop="8px">
                                        <Text alignTextCenter size="-1">
                                            {item.userProfile?.username}
                                        </Text>
                                    </Section>
                                </Card>
                            </div>
                        ))}
                    </RightCardsWrapper>
                )}
            </PlayerWrapper>
        </ModalWrapper>
    );
};
