import { Section } from 'components/grid/wrappers/FlexWrapper';
import { CollaborationLayout } from 'components/layouts/CollaborationLayout';
import { Spinner } from 'components/loaders/Spinner';
import { DetailsInfo } from 'components/pages/collaborations/DetailsInfo';
import { Footer } from 'pages/CollaborationsPage/Details/Footer';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collaborationEvents } from 'stores/collaboration-details';
import { Id } from 'types/interfaces/data';

export const CollaborationDetailsPage = () => {
    const { id: collaborationId } = useParams<Partial<Id>>();

    useEffect(() => () => collaborationEvents.resetStores(), []);

    if (!collaborationId) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    return (
        <CollaborationLayout isHidePaddingX footer={<Footer collaborationId={collaborationId} />}>
            <DetailsInfo collaborationId={collaborationId} />
        </CollaborationLayout>
    );
};
