import axios from '../axios';

export const saveVideo = (data: BULLZ.CreateSavedVideoRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/video/save',
        data
    });

export const getSavedVideos = (data: BULLZ.QuerySavedVideosRequest) =>
    axios<BULLZ.QuerySavedVideosResponse>({
        url: '/user/video/query-saved',
        data
    });
