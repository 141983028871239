import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { StyledImg } from 'components/common/imageComponents/AvatarImg/styles';
import { AvatarImgProps } from 'components/common/imageComponents/AvatarImg/types';
import React, { SyntheticEvent } from 'react';
import { NoopClick } from 'types';

interface Props extends AvatarImgProps, NoopClick {}

export const AvatarImg = ({ alt = 'avatar', src = defaultAvatar, ...styles }: Props) => {
    const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = defaultAvatar;
    };

    return <StyledImg alt={alt} {...styles} src={src} onError={handleError} />;
};
