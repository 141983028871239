import { createEffect, createStore } from 'effector';
import { womAPI } from 'services/wom';
import { walletEffects } from 'stores/wallet';

// Effects

const existsWalletPinFx = createEffect({
    handler: async () => await womAPI.wallet.existsWalletPin()
});

const changeWalletPinFx = createEffect({
    handler: async (data: WOM.ResetWalletPinRequest) => await womAPI.wallet.forgotWalletPin(data)
});

// Stores

const $hasWalletPin = createStore(false)
    .on(existsWalletPinFx.done, () => true)
    .on(walletEffects.createWalletPinFx.done, () => true)
    .reset(existsWalletPinFx.fail);

const $hasWalletPinIsLoaded = createStore(false).on(existsWalletPinFx.finally, () => true);

// Exports

export const settingsWalletPinEffects = {
    existsWalletPinFx,
    changeWalletPinFx
};

export const settingsWalletPinStores = {
    $hasWalletPin,
    $hasWalletPinIsLoaded
};
