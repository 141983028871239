import { PreviewVideoCard } from 'components/cards/PreviewVideoCard';
import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { trendingsEffects, trendingsStores } from 'stores/trendings';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems } from 'utils/video';
import { CardsWrapper, Empty, VideoCardsWrapper } from './styles';

const { getTrendings } = trendingsEffects;

export interface VideoCardsProps {
    isSideBar?: boolean;
}

export const TrendingVideoCards: FC<VideoCardsProps> = ({ isSideBar }) => {
    const history = useHistory();
    const isLoading = useStore(getTrendings.pending);
    const videos = useStore(trendingsStores.videos);

    const handleCardClick = useCallback(
        (id?: string | null) => {
            fullscreenVideoEvents.setPlaylist(getPlaylistItems(videos));
            id && history.replace(getFullscreenVideoUrl(id, 'trending'));
        },
        [history, videos]
    );

    useEffect(() => {
        getTrendings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CardsWrapper>
            <Section marginBottom="12px">
                <Text isBold size="1">
                    Trending Videos
                </Text>
            </Section>

            {videos && videos.length > 0 && (
                <VideoCardsWrapper isSideBar={isSideBar}>
                    {videos.map(item => (
                        <PreviewVideoCard
                            key={item.videoId}
                            id={item.videoId}
                            thumbnailUrl={item?.thumbnailUrl}
                            viewCount={item?.viewCount}
                            onClick={handleCardClick}
                        />
                    ))}
                </VideoCardsWrapper>
            )}

            {(!videos || videos.length === 0) && !isLoading && (
                <Empty>
                    <Text color={grey[500]}>No Videos Found</Text>
                </Empty>
            )}

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}
        </CardsWrapper>
    );
};
