import { getToken } from 'firebase/app-check';
import { useEffect, useState } from 'react';
import { firebaseAppCheck } from 'services/firebase';
import { loaderEffects } from 'stores/loader';
import { NoopType } from 'types';

const { loaderFx } = loaderEffects;

interface Params {
    onSuccess?: (token: string) => void;
    onError?: NoopType;
}

export const useReCaptcha = (params?: Params) => {
    const [token, setToken] = useState('');

    useEffect(() => {
        const getReCaptchaToken = async () => {
            try {
                loaderFx(true);
                const response = await getToken(firebaseAppCheck);
                setToken(response.token);
                params?.onSuccess?.(response.token);
            } catch {
                params?.onError?.();
            } finally {
                loaderFx(false);
            }
        };

        getReCaptchaToken();
    }, [params]);

    return token;
};
