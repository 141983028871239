import { Network } from 'components/modals/ShareLinkModal/constants';

import slideInstagramOne from 'assets/img/share-slides/instagram/instagram-1.svg';
import slideInstagramTwo from 'assets/img/share-slides/instagram/instagram-2.svg';
import slideInstagramThree from 'assets/img/share-slides/instagram/instagram-3.svg';
import slideInstagramMobileOne from 'assets/img/share-slides/instagram/instagram-mobile-1.svg';
import slideInstagramMobileThree from 'assets/img/share-slides/instagram/instagram-mobile-3.svg';

import slideReelsOne from 'assets/img/share-slides/reels/reels-1.svg';
import slideReelsMobileOne from 'assets/img/share-slides/reels/reels-mobile-1.svg';

import slideTwitterOne from 'assets/img/share-slides/twitter/twitter-1.svg';
import slideTwitterTwo from 'assets/img/share-slides/twitter/twitter-2.svg';
import slideTwitterThree from 'assets/img/share-slides/twitter/twitter-3.svg';
import slideTwitterMobileOne from 'assets/img/share-slides/twitter/twitter-mobile-1.svg';
import slideTwitterMobileThree from 'assets/img/share-slides/twitter/twitter-mobile-3.svg';

import slideYoutubeOne from 'assets/img/share-slides/youtube/youtube-1.svg';
import slideYoutubeTwo from 'assets/img/share-slides/youtube/youtube-2.svg';
import slideYoutubeThree from 'assets/img/share-slides/youtube/youtube-3.svg';
import slideYoutubeMobileOne from 'assets/img/share-slides/youtube/youtube-mobile-1.svg';
import slideYoutubeMobileThree from 'assets/img/share-slides/youtube/youtube-mobile-3.svg';

import slideShortsOne from 'assets/img/share-slides/shorts/shorts-1.svg';
import slideShortsMobileOne from 'assets/img/share-slides/shorts/shorts-mobile-1.svg';

import slideTiktokOne from 'assets/img/share-slides/tiktok/tiktok-1.svg';
import slideTiktokTwo from 'assets/img/share-slides/tiktok/tiktok-2.svg';
import slideTiktokThree from 'assets/img/share-slides/tiktok/tiktok-3.svg';
import slideTiktokMobileOne from 'assets/img/share-slides/tiktok/tiktok-mobile-1.svg';
import slideTiktokMobileThree from 'assets/img/share-slides/tiktok/tiktok-mobile-3.svg';

import slideMobileTwo from 'assets/img/share-slides/mobile-2.svg';

import slideTwitchOne from 'assets/img/share-slides/twitch/twitch-1.svg';
import slideTwitchTwo from 'assets/img/share-slides/twitch/twitch-2.svg';
import slideTwitchThree from 'assets/img/share-slides/twitch/twitch-3.svg';
import slideTwitcheMobileOne from 'assets/img/share-slides/twitch/twitch-mobile-1.svg';
import slideTwitchMobileThree from 'assets/img/share-slides/twitch/twitch-mobile-3.svg';

const getPostDescription = (value: string) => `Post your Collaboration video on ${value}.`;

const getHashtagDescription = (value: string) =>
    `Remember to add to your ${value} any required hashtags mentioned in the Collaboration.`;

const getLinkDescription = (value: string) =>
    `Click “share” to copy the link to the ${value} and add it in the next step.`;

export const networkSlides = {
    [Network.Twitter]: [
        {
            id: 0,
            title: 'Tweet Your Video',
            description: getPostDescription('Twitter'),
            imgSrc: slideTwitterOne,
            mobileSrc: slideTwitterMobileOne
        },
        {
            id: 1,
            title: 'Add Required Hashtags',
            description: getHashtagDescription('Tweet'),
            imgSrc: slideTwitterTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('Tweet'),
            imgSrc: slideTwitterThree,
            mobileSrc: slideTwitterMobileThree
        }
    ],
    [Network.Post]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('Instagram'),
            imgSrc: slideInstagramOne,
            mobileSrc: slideInstagramMobileOne
        },
        {
            id: 1,
            title: 'Add Required Hashtags',
            description: getHashtagDescription('caption'),
            imgSrc: slideInstagramTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('post'),
            imgSrc: slideInstagramThree,
            mobileSrc: slideInstagramMobileThree
        }
    ],
    [Network.Reels]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('Instagram Reels'),
            imgSrc: slideReelsOne,
            mobileSrc: slideReelsMobileOne
        },
        {
            id: 1,
            title: 'Add Required Hashtags',
            description: getHashtagDescription('caption'),
            imgSrc: slideInstagramTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('Reels'),
            imgSrc: slideInstagramThree,
            mobileSrc: slideInstagramMobileThree
        }
    ],
    [Network.Shorts]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('YouTube Shorts'),
            imgSrc: slideShortsOne,
            mobileSrc: slideShortsMobileOne
        },
        {
            id: 1,
            title: 'Use Required Hashtags',
            description: getHashtagDescription('description'),
            imgSrc: slideYoutubeTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('YouTube Shorts'),
            imgSrc: slideYoutubeThree,
            mobileSrc: slideYoutubeMobileThree
        }
    ],
    [Network.Story]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('Instagram Stories'),
            imgSrc: slideInstagramOne,
            mobileSrc: slideInstagramMobileOne
        },
        {
            id: 1,
            title: 'Add Required Hashtags',
            description: getHashtagDescription('caption'),
            imgSrc: slideInstagramTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('Story'),
            imgSrc: slideInstagramThree,
            mobileSrc: slideInstagramMobileThree
        }
    ],
    [Network.TikTok]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('TikTok'),
            imgSrc: slideTiktokOne,
            mobileSrc: slideTiktokMobileOne
        },
        {
            id: 1,
            title: 'Use Required Hashtags',
            description: getHashtagDescription('caption'),
            imgSrc: slideTiktokTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('post'),
            imgSrc: slideTiktokThree,
            mobileSrc: slideTiktokMobileThree
        }
    ],
    [Network.Youtube]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('Youtube'),
            imgSrc: slideYoutubeOne,
            mobileSrc: slideYoutubeMobileOne
        },
        {
            id: 1,
            title: 'Use Required Hashtags',
            description: getHashtagDescription('description'),
            imgSrc: slideYoutubeTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('post'),
            imgSrc: slideYoutubeThree,
            mobileSrc: slideYoutubeMobileThree
        }
    ],
    [Network.Twitch]: [
        {
            id: 0,
            title: 'Post Your Video',
            description: getPostDescription('Twitch'),
            imgSrc: slideTwitchOne,
            mobileSrc: slideTwitcheMobileOne
        },
        {
            id: 1,
            title: 'Use Required Hashtags',
            description: getHashtagDescription('caption'),
            imgSrc: slideTwitchTwo,
            mobileSrc: slideMobileTwo
        },
        {
            id: 2,
            title: 'Copy Link',
            description: getLinkDescription('video'),
            imgSrc: slideTwitchThree,
            mobileSrc: slideTwitchMobileThree
        }
    ]
};
