import {
    DeleteAccountLgIcon,
    InstagramSmIcon,
    SuccessLgIcon,
    TiktokSmIcon,
    TwitchSmIcon,
    TwitterSmIcon,
    YoutubeSmIcon
} from 'assets/icons';
import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { ButtonToLinkAccount } from 'components/pages/settings/components/ButtonToLinkAccount';
import { Wrapper } from 'components/pages/settings/components/CommonPart/styles';
import { ContentHeader } from 'components/pages/settings/components/ContentHeader';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { API } from 'services';
import { authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { socialNetworksEffects, socialNetworksStores } from 'stores/social-networks';
import { ReactClick } from 'types/react';
import { goToHelpDesk, noop } from 'utils/common';

const { $user } = authStores;
const { getUserNetworksFx, getExternalNetworksFx } = socialNetworksEffects;
const { $externalNetworks } = socialNetworksStores;

enum NetworkName {
    Instagram = 'Instagram',
    Twitter = 'Twitter',
    TikTok = 'TikTok',
    Youtube = 'Youtube',
    Twitch = 'Twitch',
    Bullz = 'BULLZ',
    None = 'None'
}

export const LinkedAccounts: FC<ReactClick<HTMLButtonElement>> = ({ onClick }) => {
    const user = useStore($user);
    const { data } = useStore($externalNetworks);

    const [isLoading, setIsLoading] = useState(NetworkName.None);

    const networks = data?.items;

    const getNetworkIcon = (name: NetworkName) => {
        switch (name.toLowerCase()) {
            case 'instagram':
                return <InstagramSmIcon />;
            case 'twitter':
                return <TwitterSmIcon />;
            case 'youtube':
                return <YoutubeSmIcon />;
            case 'tiktok':
                return <TiktokSmIcon />;
            case 'twitch':
                return <TwitchSmIcon />;
            default:
                return null;
        }
    };

    const userId = user?.userId;

    const resetLoading = () => {
        setIsLoading(NetworkName.None);
    };

    const handleError = () => {
        infoModal.openModal({
            icon: DeleteAccountLgIcon,
            title: 'Something went wrong',
            text: 'Your account could not be linked at this time. Please try again.',
            buttonText: 'Try Again',
            onClick: noop,
            cancelText: 'contact support',
            onCloseClick: goToHelpDesk
        });

        resetLoading();
    };

    const handleLink = async (title: NetworkName) => {
        setIsLoading(title);

        const { data } = await API.phyllo.getPhylloSdkToken();
        const workPlatformId = networks?.find(item => item.title?.toLowerCase() === title.toLowerCase())
            ?.workPlatformId;

        try {
            if (data && workPlatformId && userId) {
                const config = {
                    clientDisplayName: 'Bullz',
                    // TODO 'production' is temporary, we always need to take environment field from response (waiting for backend)
                    environment: data.environment || 'production',
                    userId: userId,
                    token: data.token,
                    workPlatformId: workPlatformId
                };

                // @ts-ignore
                const phylloConnect = PhylloConnect.initialize(config);

                phylloConnect.on('accountConnected', (_accountId: string, _workplatformId: string, _userId: string) => {
                    userId && getUserNetworksFx(userId);

                    infoModal.openModal({
                        icon: SuccessLgIcon,
                        title: 'Success!',
                        text: `You successfully linked your ${title} account`,
                        buttonText: 'Okay',
                        onClick: noop
                    });
                });

                phylloConnect.on(
                    'accountDisconnected',
                    (_accountId: string, _workplatformId: string, _userId: string) => {
                        userId && getUserNetworksFx(userId);

                        infoModal.openModal({
                            icon: SuccessLgIcon,
                            title: 'Unlink Succesfull',
                            text: `You have successfully unlinked your ${title} account`,
                            buttonText: 'Okay',
                            onClick: noop
                        });
                    }
                );

                phylloConnect.on('tokenExpired', (_userId: string) => {
                    handleError();
                });

                phylloConnect.on('exit', (_reason: string, _userId: string) => {
                    resetLoading();
                });

                phylloConnect.on('connectionFailure', (_reason: string, _workplatformId: string, _userId: string) => {
                    handleError();
                });

                phylloConnect.open();
            } else {
                throw new Error();
            }
        } catch (e) {
            console.error(e);
            handleError();
        }
    };

    useEffect(() => {
        userId && getUserNetworksFx(userId);
    }, [userId]);

    useEffect(() => {
        getExternalNetworksFx();
    }, []);

    return (
        <Wrapper>
            <ContentHeader title="Linked Accounts" onClick={onClick} />

            <Column marginBottom={isMobile ? '-8px' : '-16px'} marginTop={isMobile ? '20px' : '32px'}>
                {networks
                    ?.filter(item => item.title !== NetworkName.Bullz)
                    .map(
                        item =>
                            item.title && (
                                <ButtonToLinkAccount
                                    key={item.id}
                                    icon={getNetworkIcon(item.title as NetworkName)}
                                    isLoading={isLoading === (item.title as NetworkName)}
                                    title={item.title}
                                    onClick={() => handleLink(item.title as NetworkName)}
                                />
                            )
                    )}
            </Column>
        </Wrapper>
    );
};
