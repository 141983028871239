import { SocialNetworkName, socialNetworks } from 'constants/services/social-networks';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { bucmAPI } from 'services/bucm';

// Events

const resetStores = createEvent();

// Effects

const getUserNetworksFx = createEffect({
    handler: async (userId: string) => {
        const { items } = await API.user.getNetworksByUserId({ userId });

        if (items && items.length > 0) {
            return items
                .map(item => ({
                    ...item,
                    networkName: item.networkName?.toLocaleLowerCase() || ''
                }))
                .filter(item => !!item.networkName && socialNetworks.includes(item.networkName as SocialNetworkName));
        }

        return [];
    }
});

const getExternalNetworksFx = createEffect({
    handler: async () => await bucmAPI.network.getExternalNetworks()
});

// Stores

const $userNetworks = createStore<BULLZ.GetUserExternalNetworkResponse[]>([])
    .on(getUserNetworksFx.doneData, (_, data) => data)
    .reset([getUserNetworksFx.fail, resetStores]);

const $externalNetworks = createStore<BUCM.ExternalNetworksResponseApiResponse>({})
    .on(getExternalNetworksFx.doneData, (_, data) => data)
    .reset([getExternalNetworksFx.fail, resetStores]);

// Exports

export const socialNetworksEvents = { resetStores };
export const socialNetworksEffects = { getUserNetworksFx, getExternalNetworksFx };
export const socialNetworksStores = { $userNetworks, $externalNetworks };
