import { defaultUserVideosQueryParams } from 'constants/services/user-videos';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { loaderEffects } from 'stores/loader';

// Events

const setError = createEvent();
const resetStores = createEvent();
const updateVideos = createEvent<BULLZ.QueryUploadedVideosResponse>();

// Effects

const getUserVideosFx = createEffect({
    handler: async (id: string) => {
        try {
            const data = await API.playlist.queryByFilter({ ...defaultUserVideosQueryParams, byCreatorId: id });

            return data || {};
        } catch {
            setError();
            return {};
        }
    }
});

const loadMoreUserVideosFx = createEffect({
    handler: async (data: BULLZ.QueryPlaylistVideosRequest) => {
        try {
            const response = await API.playlist.queryByFilter(data);

            return response || {};
        } catch {
            setError();
            return {};
        }
    }
});

const getMyUserVideosFx = createEffect({
    handler: async () => await API.video.getValidationVideos({ ...defaultUserVideosQueryParams })
});

const loadMoreMyUserVideosFx = createEffect({
    handler: async (data: BULLZ.QueryUploadedVideosRequest) => await API.video.getValidationVideos(data)
});

const getMyVideoById = createEffect({
    handler: async (id: string) => await API.video.getValidationVideo({ videoId: id })
});

export const deleteVideoFx = createEffect({
    handler: async (data: BULLZ.DeleteVideoRequest) => await API.video.deleteVideo(data)
});

forward({
    from: deleteVideoFx.pending,
    to: loaderEffects.loaderFx
});

// Stores

const $userVideos = createStore<BULLZ.QueryPlaylistVideosResponse>({})
    .on(getUserVideosFx.doneData, (_, newState) => newState)
    .on(loadMoreUserVideosFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStores);

const $hasError = createStore<boolean>(false)
    .on(setError, () => true)
    .reset([loadMoreUserVideosFx.done, getUserVideosFx.done, resetStores]);

const $myUserVideos = createStore<BULLZ.QueryUploadedVideosResponse>({})
    .on([getMyUserVideosFx.doneData, updateVideos], (_, newState) => newState)
    .on(loadMoreMyUserVideosFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .on(getMyVideoById.doneData, (state, payload) => {
        const items = [...(state.items || [])];
        const index = items.findIndex(item => item.id === payload.id);

        if (~index && items.length - 1 > index) {
            items[index] = payload;
        }

        return {
            ...state,
            items
        };
    })
    .reset(resetStores);

const $myUserVideosError = createStore<boolean>(false)
    .on(getMyUserVideosFx.fail, () => true)
    .on(loadMoreMyUserVideosFx.fail, () => true)
    .reset([getMyUserVideosFx.done, loadMoreMyUserVideosFx.done, resetStores]);

// Exports

export const userVideosEvents = { resetStores, updateVideos };
export const userVideosEffects = {
    getUserVideosFx,
    loadMoreUserVideosFx,
    getMyUserVideosFx,
    loadMoreMyUserVideosFx,
    getMyVideoById,
    deleteVideoFx
};
export const userVideosStores = { $userVideos, $hasError, $myUserVideos, $myUserVideosError };
