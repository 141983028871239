import { baseColors, grey } from 'constants/styles/colors';
import { allTransition, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Label = styled.label`
    display: flex;
    width: 100%;
    position: relative;
    cursor: pointer;
`;

export const HiddenInput = styled.input`
    opacity: 0;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    height: 1px;
`;

interface CheckboxProps {
    checked?: boolean;
}

export const StyledCheckbox = styled.div<CheckboxProps>`
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid ${({ checked }) => (checked ? baseColors.blue : grey[500])};
    background: ${({ checked }) => (checked ? baseColors.blue : 'none')};
    ${flexCenter};
    ${allTransition};
`;
