import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

//Events

const resetTags = createEvent();

//Effects

const getTagsFx = createEffect({
    handler: async (params: Paths.Tags.Get.QueryParameters) =>
        await API.tags.getTags({
            ...params,
            limit: 25,
            pageIndex: 0
        })
});

const loadMoreTagsFx = createEffect({
    handler: async (params: Paths.Tags.Get.QueryParameters) => await API.tags.getTags(params)
});

//Stores

const $tags = createStore<BULLZ.GetTagsResponse>({})
    .on(getTagsFx.doneData, (_, newState) => newState.data)
    .on(loadMoreTagsFx.doneData, (state, payload) => ({
        ...payload.data,
        items: [...(state.items || []), ...(payload.data?.items || [])]
    }))
    .reset(resetTags);

//Exports

export const tagsStores = { $tags };

export const tagsEffects = {
    getTagsFx,
    loadMoreTagsFx
};

export const tagsEvents = { resetTags };
