import {
    BackArrowSmIcon,
    CommentSmIcon,
    HeartFilledSmIcon,
    HeartSmIcon,
    InfoSmIcon,
    ReportSmIcon,
    SaveSmIcon,
    ShareFilledSmIcon,
    ThreeDotsSmIcon,
    WebsiteGoSmIcon
} from 'assets/icons';
import { PlayerCard } from 'components/cards/PlayerCard';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { MobileCommentsModal } from 'components/modals/MobileCommentsModal';
import { IconButton } from 'components/ui/buttons/IconButton';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { Shares } from 'components/ui/Shares';
import { Tag } from 'components/ui/Tag';
import { Text } from 'components/ui/typography/Text';
import { ValidationStatusButton } from 'components/video/FullscreenVideo/components/ValidationStatusButton';
import { UserProfile } from 'components/video/UserProfile';
import { Volume } from 'components/video/Volume';
import { SearchCategory } from 'constants/enum';
import { disclaimerButtonText, disclaimerText, disclaimerTitle } from 'constants/messages';
import { AppRoute } from 'constants/routes';
import { ValidationStatusUI } from 'constants/services/video-statuses';
import { baseColors, grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { fullscreenVideoStores } from 'stores/fullscreen-video';
import { infoModal, loginModal, reportVideoModal } from 'stores/initialize.modal';
import { playerEvents, playerStores } from 'stores/player';
import { savedVideoEffects } from 'stores/saved-video';
import { videoEffects } from 'stores/video';
import { NoopType } from 'types';
import { getVideoLink, goToExternalLink } from 'utils/common';
import { getValidationStatus } from 'utils/video';
import {
    ButtonsWrapper,
    GoWrapper,
    LinksWrapper,
    PreviewImg,
    SlideWrapper,
    TagsWrapper,
    TopBarWrapper,
    WebsiteButton
} from './styles';

const { openModal: openDisclaimerModal } = infoModal;
const { registerEventVideo } = videoEffects;
const { saveVideoFx } = savedVideoEffects;
const { $currentVideoValidation } = fullscreenVideoStores;
const { $isMuted, $volume } = playerStores;
const { toggleMuteVolume, setVolume } = playerEvents;

interface Props {
    video: BULLZ.PlaylistVideoResponse;
    isActive?: boolean;
    onClose?: NoopType;
}

export const VideoSlide: FC<Props> = ({ video, isActive, onClose }) => {
    const history = useHistory();
    const isAuth = useStore(authStores.$isAuth);
    const currentVideoValidation = useStore($currentVideoValidation);
    const user = useStore(authStores.$user);
    const isMuted = useStore($isMuted);
    const volume = useStore($volume);
    const [commentsIsVisible, setCommentsIsVisible] = useState(false);

    const {
        id,
        streaming,
        username,
        isTrusted,
        isFollowed,
        profileImageUrl,
        ownerId,
        engagement,
        primaryProduct,
        hashTags,
        engagementUser,
        isInCollaboration
    } = video;

    const tags = hashTags ? hashTags.filter(tag => !!tag) : [];

    const validStatus = useMemo(() => getValidationStatus(currentVideoValidation, isInCollaboration), [
        currentVideoValidation,
        isInCollaboration
    ]);
    const statusIsVisible = validStatus !== ValidationStatusUI.None && !!currentVideoValidation;

    const handleInfoClick = () => {
        openDisclaimerModal({
            title: disclaimerTitle,
            text: disclaimerText,
            buttonText: disclaimerButtonText
        });
    };

    const goToWebsite = () => {
        primaryProduct?.link && goToExternalLink(primaryProduct.link);
    };

    const handleTagClick = (tag: string) => {
        history.push(`${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&name=${tag}&tagId=${tag}`);
    };

    const openCommentsModal = () => {
        setCommentsIsVisible(true);
    };

    const closeCommentsModal = () => {
        setCommentsIsVisible(false);
    };

    const handleLikeVideo = () => {
        if (isAuth) {
            registerEventVideo({
                videoId: id,
                liked: !engagementUser?.isLiked
            });
        } else {
            loginModal.openModal();
        }
    };

    const handleSaveVideo = () => {
        if (isAuth) {
            saveVideoFx({
                videoId: id,
                isSave: !engagementUser?.isSaved
            });
        } else {
            loginModal.openModal();
        }
    };

    const handleReportVideo = () => {
        if (isAuth) {
            reportVideoModal.openModal({ videoId: id });
        } else {
            loginModal.openModal();
        }
    };

    const handleShareClick = () => {
        if (window.navigator.share && id) {
            window.navigator
                .share({
                    title: 'BULLZ',
                    url: getVideoLink(id)
                })
                .then(() => {
                    registerEventVideo({
                        videoId: id,
                        shared: true
                    });
                });
        }
    };

    const additionalOptions = [
        {
            id: 0,
            title: 'Save',
            action: handleSaveVideo,
            iconComponent: (
                <SaveSmIcon color={engagementUser?.isSaved ? baseColors.white : grey[500]} height="14px" width="14px" />
            )
        },
        ...(user?.userId !== ownerId
            ? [
                  {
                      id: 1,
                      title: 'Report',
                      action: handleReportVideo,
                      iconComponent: <ReportSmIcon color={grey[500]} height="14px" width="14px" />
                  }
              ]
            : [])
    ];

    return (
        <SlideWrapper>
            <TopBarWrapper>
                <Row alignCenter>
                    {onClose && (
                        <Column marginRight="12px">
                            <IconButton onClick={onClose}>
                                <BackArrowSmIcon />
                            </IconButton>
                        </Column>
                    )}
                    <Volume
                        hideRange
                        isMuted={isMuted}
                        setVolume={setVolume}
                        toggleMuteVolume={toggleMuteVolume}
                        volume={volume}
                    />
                </Row>

                <UserProfile
                    avatar={profileImageUrl || ''}
                    isFollowed={isFollowed}
                    isTrusted={isTrusted}
                    userId={ownerId}
                    username={username || ''}
                />
            </TopBarWrapper>

            {isActive ? (
                <PlayerCard
                    hideControls
                    isCover
                    isFullscreen
                    posterSrc={streaming?.screenGrabUrl}
                    videoId={id}
                    videoSrc={streaming?.hlsUrl}
                />
            ) : (
                <PreviewImg alt="Preview" src={streaming?.screenGrabUrl || ''} />
            )}

            <ButtonsWrapper>
                <Row justifyCenter marginBottom="16px">
                    <IconButton size="auto" onClick={handleLikeVideo}>
                        <Column alignCenter>
                            {engagementUser?.isLiked ? <HeartFilledSmIcon /> : <HeartSmIcon />}
                            <MarginWrapper marginTop="2px">
                                <Text size="-2">{engagement?.likes || 0}</Text>
                            </MarginWrapper>
                        </Column>
                    </IconButton>
                </Row>

                <Row justifyCenter marginBottom="16px">
                    <IconButton size="auto" onClick={openCommentsModal}>
                        <Column alignCenter>
                            <CommentSmIcon />
                            <MarginWrapper marginTop="2px">
                                <Text size="-2">{engagement?.commentCount || 0}</Text>
                            </MarginWrapper>
                        </Column>
                    </IconButton>
                </Row>

                <Row justifyCenter marginBottom="16px">
                    {!!window.navigator.share ? (
                        <IconButton size="auto" onClick={handleShareClick}>
                            <Column alignCenter>
                                <ShareFilledSmIcon />
                                <MarginWrapper marginTop="2px">
                                    <Text size="-2">{engagement?.shares || 0}</Text>
                                </MarginWrapper>
                            </Column>
                        </IconButton>
                    ) : (
                        <OptionsPopover placement="left-end" renderItems={<Shares videoId={id} />}>
                            <IconButton size="auto">
                                <Column alignCenter>
                                    <ShareFilledSmIcon />
                                    <MarginWrapper marginTop="2px">
                                        <Text size="-2">{engagement?.shares || 0}</Text>
                                    </MarginWrapper>
                                </Column>
                            </IconButton>
                        </OptionsPopover>
                    )}
                </Row>

                <Row justifyCenter marginBottom="16px">
                    <IconButton onClick={handleInfoClick}>
                        <InfoSmIcon />
                    </IconButton>
                </Row>

                {statusIsVisible && id && (
                    <Row justifyCenter marginBottom="16px">
                        <ValidationStatusButton status={validStatus} videoId={id} />
                    </Row>
                )}

                <Row justifyCenter>
                    <OptionsPopover noPadding items={additionalOptions} placement="left-end" width="117px">
                        <IconButton>
                            <ThreeDotsSmIcon />
                        </IconButton>
                    </OptionsPopover>
                </Row>
            </ButtonsWrapper>

            <LinksWrapper>
                {primaryProduct && primaryProduct.link && (
                    <WebsiteButton type="button" onClick={goToWebsite}>
                        <WebsiteGoSmIcon />
                        <Column justifyCenter marginLeft="8px" marginRight="8px">
                            <Text size="-2">Website</Text>
                        </Column>
                        <GoWrapper>
                            <Text size="-2">Go</Text>
                        </GoWrapper>
                    </WebsiteButton>
                )}

                {tags.length > 0 && (
                    <TagsWrapper>
                        {tags.map(tag => (
                            <Column key={tag} marginRight="8px">
                                <Tag subject={tag} onClick={handleTagClick}>
                                    #{tag}
                                </Tag>
                            </Column>
                        ))}
                    </TagsWrapper>
                )}
            </LinksWrapper>

            {commentsIsVisible && (
                <MobileCommentsModal
                    commentsCount={engagement?.commentCount || 0}
                    videoId={id}
                    onClose={closeCommentsModal}
                />
            )}
        </SlideWrapper>
    );
};
