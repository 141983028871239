import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { GiftCard } from 'components/ui/GiftCard';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { giftsEvents } from 'stores/gifts';
import { giftPurchaseDoneModal } from 'stores/initialize.modal';

const { modal, closeModal } = giftPurchaseDoneModal;
const { openHowToUseModal } = giftsEvents;

export const GiftPurchaseDoneModal = () => {
    const [visible, data] = useStore(modal);
    const history = useHistory();

    const close = () => closeModal();

    const openHowToUse = () => {
        if (data && data.productInfo?.howToUse) {
            openHowToUseModal(data.productInfo.howToUse);
        }
    };

    const goToWallet = () => {
        closeModal();
        history.push(AppRoute.Wallet);
    };

    return (
        <ModalWrapper visible={visible} onClose={close}>
            <Section alignCenter justifyBetween marginBottom="28px">
                <IconButton onClick={close}>
                    <BackArrowSmIcon />
                </IconButton>
                <IconButton onClick={close}>
                    <CloseSmIcon />
                </IconButton>
            </Section>

            <Section justifyCenter marginBottom="12px">
                <Text alignTextCenter isBold>
                    Success
                </Text>
            </Section>

            <Section justifyCenter marginBottom="24px">
                <Text alignTextCenter size="-2">
                    You have successfully purchased this gift card!
                </Text>
            </Section>

            <Section justifyCenter marginBottom="24px">
                <Column maxWidth="152px" width="100%">
                    <GiftCard src={data?.productInfo?.productImage || ''} title={data?.productInfo?.brandName || ''} />
                </Column>
            </Section>

            {data?.productInfo?.howToUse && (
                <Section justifyCenter marginBottom="24px">
                    <TextButton isBold isUnderline size="large" onClick={openHowToUse}>
                        How to use
                    </TextButton>
                </Section>
            )}

            <Button wide onClick={goToWallet}>
                Return to Wallet
            </Button>
        </ModalWrapper>
    );
};
