import { FormWrapper } from 'components/form/styles';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import styled from 'styled-components';

export const Wrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    flex-grow: 1;
`;

export const Form = styled(FormWrapper)`
    height: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
`;
