import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { uploadVideoModal } from 'stores/initialize.modal';
import { uploadVideoEvents, UploadVideoSteps, uploadVideoStores } from 'stores/upload-video';
import { Form } from './Form';
import { TextWrapper } from './styles';

const { modal, closeModal } = uploadVideoModal;

export const UploadVideoModal = () => {
    const [visible] = useStore(modal);
    const currentStep = useStore(uploadVideoStores.$currentStep);

    const titles: Record<UploadVideoSteps, string> = {
        fillData: 'What are you recommending?',
        submit: 'Submit Video'
    };

    useEffect(() => {
        if (!visible) {
            uploadVideoEvents.setStep(UploadVideoSteps.FillData);
        }
    }, [visible]);

    return (
        <ModalWrapper fullscreenMobile size="large" visible={visible} onClose={closeModal}>
            <Section>
                <Section alignCenter justifyBetween marginBottom="30px">
                    <Row alignCenter>
                        {!isMobile ? (
                            <>
                                {currentStep === UploadVideoSteps.Submit && (
                                    <MarginWrapper marginRight="16px">
                                        <IconButton
                                            onClick={() => uploadVideoEvents.setStep(UploadVideoSteps.FillData)}
                                        >
                                            <BackArrowSmIcon />
                                        </IconButton>
                                    </MarginWrapper>
                                )}
                                <Title size="4">{titles[currentStep]}</Title>
                            </>
                        ) : (
                            <>
                                <Section marginBottom="16px">
                                    {currentStep === UploadVideoSteps.Submit ? (
                                        <IconButton
                                            onClick={() => uploadVideoEvents.setStep(UploadVideoSteps.FillData)}
                                        >
                                            <BackArrowSmIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => closeModal()}>
                                            <BackArrowSmIcon />
                                        </IconButton>
                                    )}
                                </Section>
                                <TextWrapper>
                                    <Text isBold size="1">
                                        {titles[currentStep]}
                                    </Text>
                                </TextWrapper>
                            </>
                        )}
                    </Row>
                    {!isMobile && (
                        <IconButton onClick={() => closeModal()}>
                            <CloseSmIcon />
                        </IconButton>
                    )}
                </Section>

                <Form />
            </Section>
        </ModalWrapper>
    );
};
