export enum Step {
    Phone = 'phone',
    Password = 'password'
}

export const titles: Record<Step, string> = {
    phone: 'Forgot Password',
    password: 'Set New Password'
};

export const subtitles: Record<Step, string> = {
    phone: 'Please enter the phone number you used to sign up',
    password: 'Please enter a new password to secure your account'
};

export const secondsToResendCode = 60;

export enum ForgotPasswordFieldName {
    CountryCode = 'countryCode',
    MobileNumber = 'mobileNumber',
    SmsVerificationCode = 'smsVerificationCode',
    Password = 'password',
    ConfirmPassword = 'confirmPassword'
}
