import { Select, SelectProps } from 'components/ui/Select';
import { SelectOption, SelectOptionValue } from 'components/ui/Select/types';
import { useField } from 'formik';
import React from 'react';

interface Props<T> extends SelectProps<T> {
    name: string;
}

export const SelectField = <T extends SelectOption>({ name, ...props }: Props<T>) => {
    const [field, { touched }, { setValue, setTouched }] = useField(name);

    const handleChange = (value: SelectOptionValue) => {
        !touched && setTouched(true);
        setValue(value);
    };

    return <Select {...props} {...field} onChange={handleChange} />;
};
