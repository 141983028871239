import { VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { SearchItem } from 'components/grid/Header/SearchDropdown/SearchTabContent';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { EmptyResult } from 'components/pages/search/SearchList/EmptyResult';
import { TabItem } from 'components/pages/search/SearchList/styles';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { tagsEffects, tagsStores } from 'stores/tags';

interface Props {
    searchItems: SearchItem[];
    query?: string | Array<string | null> | null;
    type: SearchCategory;
}

export const SearchTabContent: FC<Props> = ({ searchItems, query, type }) => {
    const history = useHistory();
    const tags = useStore(tagsStores.$tags);

    const onItemClick = (item: SearchItem) => {
        switch (type) {
            case SearchCategory.Topic: {
                history.push(
                    `${AppRoute.SearchVideos}?by=${SearchCategory.Topic}&query=${query}&topicId=${item.entityId}&name=${item.title}`
                );
                break;
            }
            case SearchCategory.Hashtag: {
                history.push(
                    `${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&query=${query}&tagId=${
                        item.entityId
                    }&isCategory=${item.isCategory ? +item.isCategory : 0}&name=${item.title}`
                );
                break;
            }
            case SearchCategory.Creator: {
                history.push(`${AppRoute.Profile}/${item.entityId}`);
                break;
            }
        }
    };

    const onLoadMore = () => {
        tagsEffects.loadMoreTagsFx({
            pageIndex: tags?.pageIndex !== undefined ? tags.pageIndex + 1 : 0,
            search: query as string,
            limit: 25
        });
    };

    const [loadRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: !!tags.hasNext,
        onLoadMore,
        rootMargin: '0px'
    });

    const getResultsByType = () => {
        switch (type) {
            case SearchCategory.Topic: {
                return (
                    <>
                        {searchItems.length > 0 &&
                            searchItems.map(item => (
                                <TabItem key={item.entityId} onClick={() => onItemClick(item)}>
                                    {item.title}
                                </TabItem>
                            ))}
                    </>
                );
            }
            case SearchCategory.Hashtag: {
                return (
                    <>
                        {searchItems?.length > 0 &&
                            searchItems.map(item => (
                                <TabItem key={item.entityId} onClick={() => onItemClick(item)}>
                                    {item.title}
                                </TabItem>
                            ))}
                        <HiddenScrollBlock ref={loadRef} />
                    </>
                );
            }
            case SearchCategory.Creator: {
                return (
                    <>
                        {searchItems.length > 0 &&
                            searchItems.map(item => (
                                <TabItem key={item.title} onClick={() => onItemClick(item)}>
                                    <MarginWrapper marginRight="10px">
                                        <AvatarImg src={item.thumbnailUrl || undefined} />
                                    </MarginWrapper>
                                    {item.title}
                                    {item.isTrusted && (
                                        <Column marginLeft="10px">
                                            <VerificationSmIcon />
                                        </Column>
                                    )}
                                </TabItem>
                            ))}
                    </>
                );
            }
        }
    };

    return (
        <>
            {getResultsByType()}
            {searchItems?.length === 0 && <EmptyResult query={query} />}
        </>
    );
};
