import { Alert, AlertType } from 'components/ui/Alert';
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const notify = (text: string, type?: AlertType) =>
    toast(t => (
        <Alert type={type} onClick={() => toast.dismiss(t.id)}>
            {text}
        </Alert>
    ));

export const Toasts = () => (
    <Toaster
        position="top-center"
        toastOptions={{
            duration: 2000,
            style: {
                width: '100%',
                maxWidth: 620,
                padding: 0,
                boxShadow: 'none',
                background: 'none'
            }
        }}
    />
);
