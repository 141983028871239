import { forward } from 'effector';
import { loaderEffects } from 'stores/loader';
import { quickSignalsEffects } from 'stores/quick-signals';

const {
    subscribeToQSChannelFx,
    createNewQsVideoFx,
    uploadQsVideoFx,
    leaveQSChannelFx,
    leaveQSChannelFailFx
} = quickSignalsEffects;

forward({
    from: [
        subscribeToQSChannelFx.pending,
        createNewQsVideoFx.pending,
        uploadQsVideoFx.pending,
        leaveQSChannelFx.pending
    ],
    to: loaderEffects.loaderFx
});

forward({
    from: leaveQSChannelFx.fail,
    to: leaveQSChannelFailFx
});
