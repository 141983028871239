import { flexCenter, grey } from 'constants/styles';
import styled from 'styled-components';

export const MainLoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    ${flexCenter};
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
`;

export const SpinnerWrapper = styled.div`
    padding: 32px 54px;
    ${flexCenter};
    border-radius: 20px;
    background-color: ${grey[900]};
`;
