import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { pinCodeLength } from 'components/modals/CreateWalletPinModal/constants';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { CodeInput } from 'components/ui/inputs/CodeInput';
import { useField } from 'formik';
import React, { Dispatch, FC, FormEvent, SetStateAction, useCallback, useEffect } from 'react';
import { walletEffects } from 'stores/wallet';
import { NoopType } from 'types';
import { ForgotPinFieldName, Step } from '../constants';

const { sendForgotPinSmsCodeFx } = walletEffects;

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
    codeSeconds: number;
    codeSended: boolean;
    onCodeSended: NoopType;
}

export const PhoneStep: FC<Props> = ({ setCurrentStep, codeSeconds, codeSended, onCodeSended }) => {
    const [
        ,
        { value: smsCodeValue, error: smsCodeError, touched: smsCodeTouched },
        { setValue, setTouched }
    ] = useField<string>(ForgotPinFieldName.SmsCode);

    const submitIsActive = smsCodeTouched && !smsCodeError && smsCodeValue && codeSended;

    const sendCode = useCallback(() => {
        sendForgotPinSmsCodeFx().then(() => {
            onCodeSended();
        });
    }, [onCodeSended]);

    const handleChange = (value: string) => {
        !smsCodeTouched && value.length === pinCodeLength && setTouched(true);
        setValue(value);
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!smsCodeValue || smsCodeError || !codeSended) {
            return;
        }

        setCurrentStep(Step.Pin);
    };

    useEffect(() => {
        !codeSended && sendCode();
    }, [codeSended, sendCode]);

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="24px">
                <CodeInput
                    count={pinCodeLength}
                    error={smsCodeTouched && smsCodeError ? smsCodeError : ''}
                    value={smsCodeValue}
                    onChange={handleChange}
                />
            </Section>

            <Section justifyCenter marginBottom="32px">
                <TextButton disabled={codeSeconds > 0} onClick={sendCode}>
                    {codeSeconds > 0 ? `Resend Code: ${codeSeconds}s` : 'Send Code'}
                </TextButton>
            </Section>

            <Section>
                <Button wide disabled={!submitIsActive} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
