import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { SavedVideosCards } from 'components/pages/SavedVideos';
import { SavedVideosWrapper } from 'pages/SavedVideos/styles';
import React from 'react';

export const SavedVideos = () => (
    <NewMainLayout>
        <SavedVideosWrapper>
            <SavedVideosCards />
        </SavedVideosWrapper>
    </NewMainLayout>
);
