import { defaultFollowedQueryParams } from 'constants/services/followed';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

//Events
const resetStoresFollowed = createEvent();
const updateFollowed = createEvent<BULLZ.QueryFollowedUsersResponse>();

//Effects
const getUserFollowedFx = createEffect({
    handler: async (id: string) => await API.followed.getUserFollowed({ ...defaultFollowedQueryParams, userId: id })
});

const loadMoreFollowedFx = createEffect({
    handler: async (data: BULLZ.QueryFollowedUsersRequest) => await API.followed.getUserFollowed(data)
});

//Stores
const $userFollowed = createStore<BULLZ.QueryFollowedUsersResponse>({})
    .on([getUserFollowedFx.doneData, updateFollowed], (_, newState) => newState)
    .on(loadMoreFollowedFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStoresFollowed);

const $hasErrorFollowed = createStore<boolean>(false)
    .on(getUserFollowedFx.fail, () => true)
    .on(loadMoreFollowedFx.done, () => true)
    .reset([getUserFollowedFx.done, loadMoreFollowedFx.done, resetStoresFollowed]);

//Exports

export const userFollowedEvents = { resetStoresFollowed, updateFollowed };
export const userFollowedEffects = { getUserFollowedFx, loadMoreFollowedFx };
export const userFollowedStores = { $userFollowed, $hasErrorFollowed };
