import { BackArrowSmIcon, LinkSmIcon, SettingsRightSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { baseColors, grey } from 'constants/styles/colors';
import React, { Key } from 'react';
import { isMobile } from 'react-device-detect';
import { NoopType } from 'types';
import { goToExternalLink, noop } from 'utils/common';
import { ContentWrapper, EmptyBlock, IconWrapper, TabsButton, TabsGrid, TabsPanelWrapper, TabsWrapper } from './styles';
import { TabProps } from './types';

interface Props<T extends Key> {
    tabs: Array<TabProps<T>>;
    onChange?: (value: T) => void;
    activeTab?: T;
    onClose: NoopType;
}

export const SettingsTabs = <T extends Key>({ tabs, onChange = noop, activeTab, onClose }: Props<T>) => {
    const activeTabData = tabs.find(item => item.id === activeTab);

    const handleTabClick = (tab: TabProps<T>) => {
        tab.link ? goToExternalLink(tab.link) : onChange(tab.id);
    };

    return (
        <TabsGrid>
            {((isMobile && !activeTab) || !isMobile) && (
                <TabsWrapper noWrap>
                    {isMobile && (
                        <Section alignCenter justifyBetween noWrap marginBottom="20px" marginTop="20px">
                            <IconButton onClick={onClose}>
                                <BackArrowSmIcon />
                            </IconButton>

                            <Text isBold size="1">
                                Settings
                            </Text>

                            <EmptyBlock />
                        </Section>
                    )}
                    {tabs
                        .filter(tab => !tab.isHidden)
                        .map(tab => (
                            <TabsButton
                                key={tab.id}
                                isActive={activeTab === tab.id}
                                type="button"
                                onClick={() => handleTabClick(tab)}
                            >
                                <Section alignCenter noWrap>
                                    <IconWrapper>{tab.icon}</IconWrapper>
                                    <Text color={activeTab === tab.id ? baseColors.white : grey[500]} size="-1">
                                        {tab.title}
                                    </Text>
                                </Section>
                                {tab.link ? <LinkSmIcon /> : <SettingsRightSmIcon />}
                            </TabsButton>
                        ))}
                </TabsWrapper>
            )}

            {((isMobile && activeTab) || !isMobile) && (
                <ContentWrapper>
                    {activeTabData && <TabsPanelWrapper role="tabpanel">{activeTabData.content}</TabsPanelWrapper>}
                </ContentWrapper>
            )}
        </TabsGrid>
    );
};
