import { SequelFontFamily } from 'constants/styles';
import calculateSize from 'calculate-size';

export const hasPrefix = true;

export const calculateHashtagWidth = (str: string, fontWeight = '400') =>
    calculateSize(str + (hasPrefix ? '#' : ''), {
        font: SequelFontFamily,
        fontSize: '12px',
        fontWeight: fontWeight
    }).width;
