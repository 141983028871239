import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import React, { FC } from 'react';
import { NoopType } from 'types';
import { Form } from '../Form';
import { UploadAvatar } from '../UploadAvatar';

interface Props {
    onCancel?: NoopType;
}

export const ChangeProfile: FC<Props> = ({ onCancel }) => (
    <Column noWrap width="100%">
        <Section justifyCenter marginBottom="16px">
            <UploadAvatar />
        </Section>

        <Form onCancel={onCancel} />
    </Column>
);
