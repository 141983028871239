import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const VideoCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 8px;
    width: 100%;

    ${media.laptop(`
        grid-template-columns: repeat(5, 1fr);
        row-gap: 16px;
        column-gap: 26px;
    `)};
`;

export const Empty = styled.div`
    width: 100%;
    padding-top: 66px;
    padding-bottom: 66px;
    background-color: ${baseColors.darkGrey};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${media.laptop(`
        height: 100%;
    `)};
`;

export const Image = styled.img`
    width: 120px;
    height: 120px;
    margin-bottom: 8px;

    ${media.laptop(`
        width: 200px;
        height: 200px;
    `)};
`;
