import { baseColors, grey } from 'constants/styles/colors';
import { MontserratFontFamily } from 'constants/styles/fonts';
import { transitionTime } from 'constants/styles/global';
import { isMobile } from 'react-device-detect';
import { css } from 'styled-components';

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ellipsisMixin = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const disableDefaultButtonStyleMixin = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const scrollBarDesktopMixin = css`
    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${grey['600']};
        border-radius: 10px;
    }
`;

export const scrollBarMobileMixin = css`
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    scrollbar-width: none;
`;

export const scrollBarMixin = css`
    ${isMobile ? scrollBarMobileMixin : scrollBarDesktopMixin};
`;

export const allTransition = css`
    transition: all ${transitionTime} linear;
`;

export const defaultInputStyles = css`
    font-family: ${MontserratFontFamily};
    outline: none;
    background: none;
    border: none;
    color: ${baseColors.white};
    font-weight: 500;

    &::placeholder {
        color: ${grey['600']};
    }
`;

export const iconWrapperMixin = css`
    width: 24px;
    height: 24px;
    ${flexCenter};
    flex-shrink: 0;
`;
