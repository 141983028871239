import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { maxUsernameLength, minUsernameLength } from 'components/modals/RegisterModal/constants';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Input } from 'components/ui/inputs/Input';
import { Textarea } from 'components/ui/inputs/Textarea';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { authEffects, authStores } from 'stores/auth';
import { registerEffects, registerStores } from 'stores/register';
import { settingsEvents } from 'stores/settings';
import { NoopType } from 'types';
import { FormStyled } from './styles';

interface Props {
    onCancel?: NoopType;
}

export const Form: FC<Props> = ({ onCancel }) => {
    const user = useStore(authStores.$user);
    const usernameIsAvailable = useStore(registerStores.$usernameIsAvailable);
    const nameIsLoading = useStore(registerEffects.checkUsernameExistenceFx.pending);

    const initialData = {
        name: user ? user.username || '' : '',
        bio: user ? user.profile?.bio || '' : ''
    };

    const [values, setValues] = useState(initialData);

    const nameIsDirty = values.name !== initialData.name;
    const bioIsDirty = values.bio !== initialData.bio;

    const checklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '3 to 40 characters',
                isSuccess: values.name.length >= minUsernameLength && values.name.length <= maxUsernameLength,
                isLoading: false
            },
            {
                title: 'Username is available',
                isSuccess: !nameIsDirty ? true : !!values.name && usernameIsAvailable,
                isLoading: nameIsLoading
            }
        ],
        [nameIsDirty, nameIsLoading, usernameIsAvailable, values.name]
    );

    const nameErrors = checklist.filter(item => !item.isSuccess);
    const submitIsAvailable = (nameIsDirty || bioIsDirty) && !!values.name && nameErrors.length === 0;

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        if (name === 'name') {
            registerEffects.checkUsernameExistenceFx(value);
        }

        setValues(state => ({
            ...state,
            [name]: value
        }));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (submitIsAvailable) {
            authEffects.updateAccountFx({
                username: nameIsDirty ? values.name : undefined,
                bio: bioIsDirty ? values.bio : undefined
            });
        }
    };

    useEffect(() => {
        settingsEvents.setClosureAvailability(!submitIsAvailable);
    }, [submitIsAvailable]);

    if (!initialData) {
        return null;
    }

    return (
        <Column width="100%">
            <FormStyled onSubmit={handleSubmit}>
                <Column noWrap marginBottom="16px" width="100%">
                    <Section marginBottom="16px">
                        <Input
                            label="Username"
                            name="name"
                            placeholder="Username"
                            prefix={
                                <Text color={grey[500]} size="-1">
                                    @
                                </Text>
                            }
                            value={values.name}
                            onChange={handleChange}
                        />
                    </Section>

                    <RulesChecklist items={checklist} />
                </Column>

                <Section marginBottom="16px">
                    <Textarea
                        label="Bio"
                        maxLength={200}
                        name="bio"
                        placeholder="Bio"
                        value={values.bio}
                        onChange={handleChange}
                    />
                </Section>

                <Section alignCenter justifyBetween>
                    {onCancel && <TextButton onClick={onCancel}>Cancel</TextButton>}
                    <Button disabled={!submitIsAvailable} type="submit">
                        Save
                    </Button>
                </Section>
            </FormStyled>
        </Column>
    );
};
