import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { AvatarBorder } from 'components/ui/AvatarBorder';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React from 'react';
import { Container, Join } from './styles';

interface Props {
    url?: string;
    isSubscribed?: boolean;
    large?: boolean;
    hasNewVideos?: boolean;
}

export const QsAvatar: React.FC<Props> = ({ url, isSubscribed = true, large, hasNewVideos = true }) => (
    <Container large={large}>
        <AvatarBorder
            background={!hasNewVideos ? grey[700] : undefined}
            diameter={large ? '70px' : '48px'}
            src={url || defaultAvatar}
        />

        {!isSubscribed && (
            <Join>
                <Text size="-2">Join</Text>
            </Join>
        )}
    </Container>
);
