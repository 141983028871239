import axios from './axios';

export const getComments = (data: BULLZ.QueryPostsRequest) =>
    axios<BULLZ.QueryPostsResponse>({
        url: '/video/comment/query',
        data
    });

export const getReplies = (data: BULLZ.QueryRepliesRequest) =>
    axios<BULLZ.QueryPostsResponse>({
        url: '/video/comment/query-replies',
        data
    });

export const deleteComment = (data: BULLZ.DeletePostRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/video/comment/delete',
        data
    });

export const addComment = (data: BULLZ.CreatePostRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/video/comment/post',
        data
    });

export const reportComment = (data: BULLZ.ReportPostRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/video/comment/report',
        data
    });
