import { createEffect, createStore } from 'effector';
import { API } from 'services';

// Effects

const getReferralLinkFx = createEffect({
    handler: async () => {
        try {
            const data = await API.user.getReferralLink();

            if (data.isOk) {
                return data.response || '';
            }

            return '';
        } catch (e) {
            return '';
        }
    }
});

const getReferralCountFx = createEffect({
    handler: async () => {
        try {
            const res = await API.user.getReferralCount();

            if (res.isSuccess) {
                return res.data?.count || 0;
            }

            return 0;
        } catch (e) {
            return 0;
        }
    }
});

// Stores

const $referralLink = createStore<string>('')
    .on(getReferralLinkFx.doneData, (_, payload) => payload)
    .reset(getReferralLinkFx.fail);

const $referralCount = createStore<number>(0)
    .on(getReferralCountFx.doneData, (_, payload) => payload)
    .reset(getReferralCountFx.fail);

// Exports

export const settingsInviteEffects = {
    getReferralLinkFx,
    getReferralCountFx
};

export const settingsInviteStores = {
    $referralLink,
    $referralCount
};
