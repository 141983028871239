import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const VideoCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 8px;
    width: 100%;

    ${media.laptop(`
        grid-template-columns: repeat(5, 1fr);
        row-gap: 16px;
        column-gap: 26px;
    `)};
`;

export const Empty = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${media.laptop(`
        background-color: ${baseColors.darkGrey};
    `)};
`;

export const Image = styled.img`
    width: 220px;
    height: 220px;
    margin-bottom: 18px;
`;

export const Wrapper = styled(Column)`
    flex-wrap: nowrap;
    height: calc(100vh - 2 * ${headerHeight});
    width: 100%;

    ${media.laptop(`
        height: 100%;
    `)};
`;
