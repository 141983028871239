import { QuickSignalSmIcon, WomSmIcon } from 'assets/icons';
import followedQSImg from 'assets/img/quick-signals/qs-followed.png';
import { CustomImage } from 'components/common/CustomImg/styles';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Subs } from 'components/pages/profile/ProfileBlock/components/QuickSignalsInfo/Subs';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Link } from 'components/ui/Link';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { Shares } from 'components/ui/Shares';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { qsSubscribeModal, walletGuideModal } from 'stores/initialize.modal';
import { quickSignalsEffects, quickSignalsEvents, quickSignalsStores } from 'stores/quick-signals';
import { walletStores } from 'stores/wallet';
import { getProfileLink } from 'utils/common';
import { Divider, FollowWrapper, GradientLabel, HeaderExtra, Wrapper } from './styles';

const { $quickSignalsInfo, $quickSignalsSubscriptions, $quickSignalsChannels } = quickSignalsStores;
const { getQuickSignalsInfoFx, getQuickSignalsSubscriptionsFx, getQuickSignalsChannelsFx } = quickSignalsEffects;
const { resetStores } = quickSignalsEvents;

interface Props {
    userId: string;
    isMyProfile: boolean;
    isVerified?: boolean;
    avatar?: string;
}

enum QsState {
    None,
    MyNotVerified,
    MyVerifiedNotCreated,
    MyVerifiedCreated,
    OtherNotFollowed,
    OtherFollowed
}

export const QuickSignalsInfo: FC<Props> = ({ userId, avatar, isMyProfile, isVerified = false }) => {
    const history = useHistory();

    const { data: qsData } = useStore($quickSignalsInfo);
    const { data: subscriptionsData } = useStore($quickSignalsSubscriptions);
    const { items: otherUserChannels } = useStore($quickSignalsChannels);

    const hasWallet = useStore(walletStores.$hasWallet);
    const womUserIsLoaded = useStore(walletStores.$womUserIsLoaded);

    const qsDataIsLoading = useStore(getQuickSignalsInfoFx.pending);
    const subscriptionsDataIsLoading = useStore(getQuickSignalsSubscriptionsFx.pending);
    const otherUserChannelsIsLoading = useStore(getQuickSignalsChannelsFx.pending);

    const isLoading = qsDataIsLoading || subscriptionsDataIsLoading || otherUserChannelsIsLoading;

    const [myQSInfo] = (qsData || []) as [BULLZ.GetChannelOwnerInfoResponse | undefined];
    const hasMyChannel = !!myQSInfo;

    const [otherQSInfo] = (otherUserChannels || []) as [BULLZ.GetQuickSignalChannelResponse | undefined];

    const qsState: QsState = useMemo(() => {
        if (isMyProfile) {
            if (!isVerified) {
                return QsState.MyNotVerified;
            }

            if (!hasMyChannel) {
                return QsState.MyVerifiedNotCreated;
            }

            return QsState.MyVerifiedCreated;
        } else {
            if (!isVerified) {
                return QsState.None;
            }

            if (otherQSInfo) {
                if (otherQSInfo.isSubscribed) {
                    return QsState.OtherFollowed;
                } else {
                    return QsState.OtherNotFollowed;
                }
            }
        }

        return QsState.None;
    }, [hasMyChannel, isMyProfile, isVerified, otherQSInfo]);

    const subscriptionsAvatars = useMemo(() => {
        if (subscriptionsData && subscriptionsData.items) {
            return subscriptionsData.items
                .map(item => item.avatarUrl || '')
                .filter(item => !!item)
                .slice(0, 3);
        }

        return [];
    }, [subscriptionsData]);

    const equalState = (state: QsState) => qsState === state;

    const goToMySubscribers = () => {
        history.push(`${AppRoute.QuickSignals}?tab=subs`);
    };

    const goToMySubscriptions = () => {
        history.push(AppRoute.QuickSignalsSubscriptions);
    };

    const goToManageSubscription = () => {
        if (otherQSInfo && otherQSInfo.id) {
            history.replace(`${AppRoute.QuickSignalsChannel}/${otherQSInfo.id}`);
        }
    };

    const handleJoin = () => {
        if (!womUserIsLoaded || !otherQSInfo) {
            return;
        }

        if (!hasWallet) {
            walletGuideModal.openModal();
            return;
        }

        qsSubscribeModal.openModal({
            userId,
            avatar,
            channelId: otherQSInfo.id,
            name: otherQSInfo.name,
            description: otherQSInfo.description,
            womValue: otherQSInfo.womValue,
            totalSignalsCount: otherQSInfo.totalSignalsCount,
            utcCreated: otherQSInfo.utcCreated
        });
    };

    const handleShareClick = () => {
        if (window.navigator.share && userId && isMobile) {
            window.navigator.share({
                title: 'BULLZ',
                url: getProfileLink(userId)
            });
        }
    };

    useEffect(() => {
        if (isMyProfile) {
            getQuickSignalsInfoFx();
            getQuickSignalsSubscriptionsFx();
        } else {
            getQuickSignalsChannelsFx(userId);
        }

        return () => resetStores();
    }, [isMyProfile, userId]);

    if (isLoading || equalState(QsState.None)) {
        return null;
    }

    return (
        <Wrapper>
            <Section alignCenter justifyBetween noWrap>
                {equalState(QsState.OtherFollowed) ? (
                    <Section alignCenter justifyBetween>
                        <CustomImage src={followedQSImg} width="80px" />
                        <TextButton buttonType="primary" onClick={goToManageSubscription}>
                            Manage
                        </TextButton>
                    </Section>
                ) : (
                    <>
                        {equalState(QsState.OtherNotFollowed) || isMobile ? (
                            <Section alignCenter>
                                <QuickSignalSmIcon />
                                <Column marginLeft="8px">
                                    <Text noWrap size="-2">
                                        {!isMyProfile && otherQSInfo ? otherQSInfo.name : 'Quick Signals'}
                                    </Text>
                                </Column>
                            </Section>
                        ) : (
                            <Row alignCenter noWrap>
                                <QuickSignalSmIcon />
                                <Column marginLeft="8px">
                                    <Text noWrap size="-1">
                                        {!isMyProfile && otherQSInfo ? otherQSInfo.name : 'Quick Signals'}
                                    </Text>
                                </Column>
                            </Row>
                        )}

                        <HeaderExtra>
                            {equalState(QsState.MyVerifiedNotCreated) && (
                                <Section alignCenter justifyBetween noWrap>
                                    <GradientLabel>
                                        <Text size="-2">New</Text>
                                    </GradientLabel>
                                    <Link noWrap href={AppRoute.QuickSignalsCreate} type="blue">
                                        Get Started
                                    </Link>
                                </Section>
                            )}

                            {equalState(QsState.MyVerifiedCreated) && (
                                <Section alignCenter justifyBetween noWrap>
                                    <Link href={AppRoute.QuickSignals} type="blue">
                                        Manage
                                    </Link>

                                    {!!window.navigator.share && isMobile ? (
                                        <TextButton buttonType="primary" onClick={handleShareClick}>
                                            Promote
                                        </TextButton>
                                    ) : (
                                        <OptionsPopover placement="bottom-end" renderItems={<Shares userId={userId} />}>
                                            <TextButton buttonType="primary">Promote</TextButton>
                                        </OptionsPopover>
                                    )}
                                </Section>
                            )}
                        </HeaderExtra>
                    </>
                )}
            </Section>

            {equalState(QsState.MyNotVerified) && (
                <Section marginTop="16px">
                    {subscriptionsData && !!subscriptionsData.totalSubscription ? (
                        <Subs
                            avatarUrls={subscriptionsAvatars}
                            count={subscriptionsData.totalSubscription}
                            onClick={goToMySubscriptions}
                        />
                    ) : (
                        <Text color={grey[500]} size="-1">
                            You have not joined any channel yet
                        </Text>
                    )}
                </Section>
            )}

            {equalState(QsState.MyVerifiedNotCreated) && (
                <Column noWrap marginTop="16px" width="100%">
                    <Section>
                        <Text color={grey[500]} size="-1">
                            As a verified user, you can earn more with the Quick Signals feature. Create a Channel now
                            to get started!
                        </Text>
                    </Section>

                    {subscriptionsData && !!subscriptionsData.totalSubscription && (
                        <>
                            <Divider />
                            <Section alignCenter justifyBetween={isMobile}>
                                <Text size="-1">Channels you joined</Text>

                                <Column marginLeft="14px">
                                    <Subs
                                        avatarUrls={subscriptionsAvatars}
                                        count={subscriptionsData.totalSubscription}
                                        hideAvatars={isMobile}
                                        onClick={goToMySubscriptions}
                                    />
                                </Column>
                            </Section>
                        </>
                    )}
                </Column>
            )}

            {equalState(QsState.MyVerifiedCreated) && (
                <Column noWrap marginTop="16px" width="100%">
                    <Section alignCenter>
                        {myQSInfo && (myQSInfo.subscribers?.count || 0) > 0 ? (
                            <Section alignCenter justifyBetween={isMobile}>
                                <Text size="-1">Joined your Channel</Text>

                                <Column marginLeft="14px">
                                    <Subs
                                        avatarUrls={myQSInfo.subscribers?.avatarUrls || []}
                                        count={myQSInfo.subscribers?.count || 0}
                                        hideAvatars={isMobile}
                                        onClick={goToMySubscribers}
                                    />
                                </Column>
                            </Section>
                        ) : (
                            <Text color={grey[500]} size="-1">
                                No one has joined your channel yet
                            </Text>
                        )}
                    </Section>

                    {subscriptionsData && !!subscriptionsData.totalSubscription && (
                        <>
                            <Divider />
                            <Section alignCenter justifyBetween={isMobile}>
                                <Text size="-1">Channels you joined</Text>

                                <Column marginLeft="14px">
                                    <Subs
                                        avatarUrls={subscriptionsAvatars}
                                        count={subscriptionsData.totalSubscription}
                                        hideAvatars={isMobile}
                                        onClick={goToMySubscriptions}
                                    />
                                </Column>
                            </Section>
                        </>
                    )}
                </Column>
            )}

            {equalState(QsState.OtherFollowed) && (
                <Section marginTop="8px">
                    <Text color={grey[500]} size="-1">
                        You joined {otherQSInfo?.name || ''}
                    </Text>
                </Section>
            )}

            {equalState(QsState.OtherNotFollowed) && (
                <Section marginTop="8px">
                    <FollowWrapper>
                        <Section justifyCenter={isMobile}>
                            <Text alignTextCenter={isMobile} color={grey[500]} size="-1">
                                {otherQSInfo?.description || ''}
                            </Text>
                        </Section>

                        {otherQSInfo?.womValue && (
                            <Section justifyCenter={isMobile}>
                                <Button
                                    icon={<WomSmIcon />}
                                    iconPosition="left"
                                    isBold={false}
                                    size="small"
                                    textSize="small"
                                    wide={!isMobile}
                                    onClick={handleJoin}
                                >
                                    {otherQSInfo?.womValue}/month
                                </Button>
                            </Section>
                        )}
                    </FollowWrapper>
                </Section>
            )}
        </Wrapper>
    );
};
