import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { infoModal, uploadOptionModal, uploadQsModal, uploadVideoModal } from 'stores/initialize.modal';
import { quickSignalsEffects, quickSignalsStores } from 'stores/quick-signals';
import { walletStores } from 'stores/wallet';

const { modal, closeModal } = uploadOptionModal;
const { $hasWallet, $womUserIsLoaded } = walletStores;

enum UploadScreen {
    Video = 'video',
    QuickSignal = 'quickSignal'
}

export const UploadOptionModal = () => {
    const history = useHistory();
    const [visible] = useStore(modal);
    const { data: qsData } = useStore(quickSignalsStores.$quickSignalsInfo);
    const qsDataIsLoaded = useStore(quickSignalsStores.$quickSignalsInfoIsLoaded);
    const hasWallet = useStore($hasWallet);
    const womUserIsLoaded = useStore($womUserIsLoaded);

    const [myQSInfo] = (qsData || []) as [BULLZ.GetChannelOwnerInfoResponse | undefined];
    const hasMyChannel = !!myQSInfo;

    const handleSelectClick = (screen: UploadScreen) => () => {
        closeModal();

        if (screen === UploadScreen.Video) {
            if (womUserIsLoaded && !hasWallet) {
                infoModal.openModal({
                    title: 'Create your wallet',
                    text:
                        'Please create your wallet first in order to upload or create a video. This is required to ensure you can receive rewards! ',
                    buttonText: 'Create Wallet',
                    onClick: () => history.push(AppRoute.Wallet)
                });
            } else {
                uploadVideoModal.openModal();
            }
        } else if (screen === UploadScreen.QuickSignal) {
            uploadQsModal.openModal();
        }
    };

    useEffect(() => {
        !qsDataIsLoaded && quickSignalsEffects.getQuickSignalsInfoFx();
    }, [qsDataIsLoaded]);

    return (
        <ModalWrapper visible={visible} onClose={closeModal}>
            <Column alignCenter>
                <MarginWrapper marginBottom="12px">
                    <Text isBold>Choose</Text>
                </MarginWrapper>
                <MarginWrapper marginBottom="24px">
                    <Text size="-2">What would you like to create?</Text>
                </MarginWrapper>

                <Button wide onClick={handleSelectClick(UploadScreen.Video)}>
                    Video
                </Button>

                {hasMyChannel && (
                    <Section marginTop="24px">
                        <Button wide onClick={handleSelectClick(UploadScreen.QuickSignal)}>
                            Quick Signal
                        </Button>
                    </Section>
                )}
            </Column>
        </ModalWrapper>
    );
};
