import { Section } from 'components/grid/wrappers/FlexWrapper';
import { VideoInput } from 'components/ui/inputs/VideoInput';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React, { ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { UploadVideoSteps, uploadVideoStores } from 'stores/upload-video';
import { noop } from 'utils/common';
import { fillDataSchema, uploadVideoInitialData } from '../constants';
import { FillDataForm } from '../FillDataForm';
import { StyledSection, VideoWrapper } from '../styles';
import { SubmitVideoForm } from '../SubmitVideoForm';

export const Form = () => {
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const currentStep = useStore(uploadVideoStores.$currentStep);

    const steps: Record<UploadVideoSteps, ReactNode> = {
        fillData: <FillDataForm videoFile={videoFile} />,
        submit: <SubmitVideoForm />
    };

    return (
        <Section noWrap={!isMobile}>
            <VideoWrapper>
                <VideoInput isTopControlsVisible={currentStep === UploadVideoSteps.FillData} setFile={setVideoFile} />
            </VideoWrapper>
            <StyledSection>
                <Formik initialValues={uploadVideoInitialData} validationSchema={fillDataSchema} onSubmit={noop}>
                    {steps[currentStep]}
                </Formik>
            </StyledSection>
        </Section>
    );
};
