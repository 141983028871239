import { VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { Empty, UserName } from 'components/pages/follow/FollowTabContent/styles';
import { Button } from 'components/ui/buttons/Button';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { userStores } from 'stores/user';
import { userFollowEffects } from 'stores/user-follow';

interface Props {
    items?: BULLZ.QueryFollowedUserResponse[] | BULLZ.QueryFollowerResponse[] | null;
    emptyText?: string | null;
    isLoading?: boolean;
    isMoreLoading?: boolean;
    followers?: boolean;
    loadRef?: (instance: HTMLDivElement | null) => void;
}

const { followUserFx } = userFollowEffects;
const { $user } = authStores;

export const FollowTabContent: FC<Props> = ({
    isMoreLoading,
    emptyText,
    items,
    isLoading = false,
    followers,
    loadRef
}) => {
    const user = useStore($user);
    const userProfile = useStore(userStores.$userProfile);
    const history = useHistory();

    const toFollow = (userId?: string, isFollowed?: boolean) => {
        user &&
            followUserFx({
                userId: user.userId,
                creatorId: userId,
                isFollow: !isFollowed
            });
    };

    const goToTrendingCreators = () => {
        history.push(AppRoute.Trending);
    };

    return (
        <Column height="100%" marginTop="22px" width="100%">
            {items && items?.length > 0 && (
                <>
                    {items?.map(({ userId, profileImageUrl, username, isFollowed, isTrusted }) => (
                        <Section key={userId} justifyBetween noWrap marginBottom="24px">
                            <Link to={`${AppRoute.Profile}/${userId}`}>
                                <Row alignCenter noWrap>
                                    <AvatarImg src={profileImageUrl || undefined} />
                                    <Column marginLeft="10px">
                                        <UserName size="-2">@{username}</UserName>
                                    </Column>

                                    {isTrusted && (
                                        <Column marginLeft="10px">
                                            <VerificationSmIcon height="20px" width="20px" />
                                        </Column>
                                    )}
                                </Row>
                            </Link>
                            {user?.userId !== userId && (
                                <Button
                                    buttonType={isFollowed ? 'secondary' : 'primary'}
                                    isBold={false}
                                    size="small"
                                    onClick={() => toFollow(userId, isFollowed)}
                                >
                                    {isFollowed ? 'Following' : 'Follow'}
                                </Button>
                            )}
                        </Section>
                    ))}
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}

            {(!items || items.length === 0) &&
                !isLoading &&
                (user?.userId === userProfile?.id ? (
                    followers ? (
                        <Empty>
                            <Text color={grey[500]}>You don’t have any followers</Text>
                        </Empty>
                    ) : (
                        <Empty>
                            <Column alignCenter>
                                <MarginWrapper marginBottom="16px">
                                    <Text color={grey[500]}>You don’t follow anyone yet</Text>
                                </MarginWrapper>
                                <Button isBold={false} size="small" onClick={goToTrendingCreators}>
                                    Trending Creators
                                </Button>
                            </Column>
                        </Empty>
                    )
                ) : (
                    <Empty>
                        <Text color={grey[500]}>{emptyText}</Text>
                    </Empty>
                ))}

            {(isLoading || isMoreLoading) && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
