import axios from '../axios';

export const authenticateUser = (data: BULLZ.UserAuthChallengeEmailOrUsernameOrPhoneRequest) =>
    axios<BULLZ.UserJwtTokenResponse>({
        url: '/user/authenticate',
        data
    });

export const analyzeMobileNumber = (data: BULLZ.AnalyzeMobileNumberRequest) =>
    axios<BULLZ.AnalyzeMobileNumberResponse>({
        url: '/user/sms-analyze-mobile-number',
        data
    });
