import { Column } from 'components/grid/wrappers/FlexWrapper';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors, grey } from 'constants/styles';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Color } from 'types';

export const IconWrapper = styled.div<Color>`
    width: 40px;
    height: 40px;
    background-color: ${({ color }) => color || grey[900]};
    border-radius: 50%;
    ${flexCenter};
`;

export const HashtagGrid = styled.div`
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    grid-gap: 24px;
`;

export const Empty = styled(Column)`
    width: 100%;
    height: calc(100vh - 2 * ${headerHeight} - 48px);
    background-color: ${baseColors.darkGrey};
    border-radius: 8px;
`;
