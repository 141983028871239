export enum Step {
    Phone = 'phone',
    Username = 'username',
    Password = 'password'
}

export const titles: Record<Step, string> = {
    phone: 'Sign Up',
    username: 'Add Username',
    password: 'Set New Password'
};

export const subtitles: Record<Step, string> = {
    phone: 'Create a profile, discover and share what’s new in crypto, and earn rewards.',
    username: 'Let’s start with a unique username',
    password: 'Please enter a new password to secure your account'
};

export const minUsernameLength = 3;
export const maxUsernameLength = 40;

export const secondsToResendCode = 60;

export enum RegisterFieldName {
    Username = 'username',
    CountryCode = 'countryCode',
    MobileNumber = 'mobileNumber',
    SmsVerificationCode = 'smsVerificationCode',
    Password = 'password',
    ConfirmPassword = 'confirmPassword'
}
