import { ChevronLeftBgLgIcon, ChevronRightBgLgIcon, CloseBgMdIcon, CloseSmIcon, InfoSmIcon } from 'assets/icons';
import { PlayerCard } from 'components/cards/PlayerCard';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import {
    BottomBarWrapper,
    CloseButtonWrapper,
    StoriesLine,
    StoriesLines,
    TopBarWrapper
} from 'components/modals/QSModal/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { UserProfile } from 'components/video/UserProfile';
import { Volume } from 'components/video/Volume';
import { disclaimerButtonText, disclaimerText, disclaimerTitle } from 'constants/messages';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useNonScrolledBackground } from 'hooks/useNonScrolledBackground';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import React, { MouseEvent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { authStores } from 'stores/auth';
import { infoModal, myQsModal } from 'stores/initialize.modal';
import { playerEvents, playerStores } from 'stores/player';
import { quickSignalsEffects, quickSignalsStores } from 'stores/quick-signals';
import { ModalWrapper, VideoWrapper } from './styles';

const { modal, closeModal } = myQsModal;
const { getQuickSignalsVideosFx } = quickSignalsEffects;
const { $quickSignalsVideos } = quickSignalsStores;
const { $user } = authStores;
const { $isMuted, $volume } = playerStores;
const { toggleMuteVolume, setVolume } = playerEvents;

export const MyQsModal = () => {
    const { width } = useWindowDimensions();

    const [visible, state] = useStore(modal);
    const { data } = useStore($quickSignalsVideos);
    const isLoading = useStore(getQuickSignalsVideosFx.pending);
    const user = useStore($user);
    const isMuted = useStore($isMuted);
    const volume = useStore($volume);

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);

    const close = () => closeModal();

    const handleInfoClick = () => {
        infoModal.openModal({
            title: disclaimerTitle,
            text: disclaimerText,
            buttonText: disclaimerButtonText
        });
    };

    const resetState = () => {
        setCurrentVideoIndex(0);
        setCurrentProgress(0);
    };

    const handleProgress = (value: number) => {
        setCurrentProgress(value);
    };

    const goToPrev = () => {
        if (currentVideoIndex > 0) {
            setCurrentProgress(0);
            setCurrentVideoIndex(state => state - 1);
        } else {
            close();
        }
    };

    const goToNext = () => {
        if (data?.items && currentVideoIndex < data?.items?.length - 1) {
            setCurrentProgress(0);
            setCurrentVideoIndex(state => state + 1);
        } else {
            close();
        }
    };

    const handleVideoClick = (e: MouseEvent<HTMLVideoElement>) => {
        e.persist();

        if (e.clientX >= width / 2) {
            goToNext();
        } else {
            goToPrev();
        }
    };

    useEffect(() => {
        if (visible && state) {
            getQuickSignalsVideosFx(state.channelId);
        }

        if (!visible) {
            resetState();
        }
    }, [state, visible]);

    useEffect(() => {
        if (state.videoId) {
            const index = data?.items?.findIndex(item => item.id === state.videoId);

            if (index && index !== -1) {
                setCurrentVideoIndex(index);
            }
        }
    }, [data, state]);

    useNonScrolledBackground(visible);

    if (!visible) {
        return null;
    }

    return (
        <ModalWrapper>
            {!isMobile && (
                <CloseButtonWrapper>
                    <IconButton color={grey['900']} hoverColor={grey['800']} size="40px" onClick={close}>
                        <CloseBgMdIcon />
                    </IconButton>
                </CloseButtonWrapper>
            )}

            <Row alignCenter justifyCenter height="100%">
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {!isMobile && (
                            <Row marginRight="12px">
                                <IconButton color={grey[500]} size="52px" onClick={goToPrev}>
                                    <ChevronLeftBgLgIcon />
                                </IconButton>
                            </Row>
                        )}

                        <VideoWrapper>
                            <TopBarWrapper>
                                {data?.items?.length && (
                                    <StoriesLines count={data.items.length}>
                                        {data.items.map((_, index) => (
                                            <StoriesLine
                                                key={index}
                                                done={index < currentVideoIndex}
                                                isCurrent={index === currentVideoIndex}
                                                progress={currentProgress}
                                            />
                                        ))}
                                    </StoriesLines>
                                )}

                                {user && (
                                    <Section
                                        alignCenter
                                        noWrap
                                        justifyBetween={isMobile}
                                        justifyEnd={!isMobile}
                                        marginTop="14px"
                                    >
                                        {isMobile && (
                                            <IconButton onClick={close}>
                                                <CloseSmIcon />
                                            </IconButton>
                                        )}

                                        <UserProfile
                                            isFollowed
                                            avatar={user.profile?.profileImageUrl || ''}
                                            isTrusted={user.isTrusted}
                                            userId={user.userId}
                                            username={user.username || ''}
                                        />
                                    </Section>
                                )}
                            </TopBarWrapper>

                            <PlayerCard
                                hideControls
                                contentType="quick-signal"
                                isCover={isMobile}
                                posterSrc={data?.items?.length ? data?.items[currentVideoIndex].thumbnailUrl : ''}
                                videoId={data?.items?.length ? data?.items[currentVideoIndex].id : ''}
                                videoSrc={data?.items?.length ? data?.items[currentVideoIndex].streamUrl : ''}
                                onClick={handleVideoClick}
                                onProgressChange={handleProgress}
                            />

                            <BottomBarWrapper>
                                <Section justifyEnd marginTop="12px">
                                    <Volume
                                        isMuted={isMuted}
                                        setVolume={setVolume}
                                        toggleMuteVolume={toggleMuteVolume}
                                        volume={volume}
                                    />
                                </Section>
                                <Section justifyEnd marginTop="12px">
                                    <IconButton onClick={handleInfoClick}>
                                        <InfoSmIcon />
                                    </IconButton>
                                </Section>
                            </BottomBarWrapper>
                        </VideoWrapper>

                        {!isMobile && (
                            <Row marginLeft="12px">
                                <IconButton color={grey[500]} size="52px" onClick={goToNext}>
                                    <ChevronRightBgLgIcon />
                                </IconButton>
                            </Row>
                        )}
                    </>
                )}
            </Row>
        </ModalWrapper>
    );
};
