import { flexCenter } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const RangeWrapper = styled.div`
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    padding-top: 12px;
    padding-bottom: 12px;
    ${flexCenter};
    border-radius: 50px;
    background-color: ${grey['900']};
    z-index: 11;
    display: none;
`;

export const VolumeWrapper = styled.div`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    position: relative;

    &:hover ${RangeWrapper} {
        display: block;
    }
`;

const lineWidth = 2;

export const sliderStyles: CSSProperties = {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
};

export const handleStyles: CSSProperties = {
    height: 12,
    width: 12,
    backgroundColor: baseColors.white,
    marginLeft: 0,
    border: 'none',
    boxShadow: 'none',
    opacity: 1
};

export const trackStyles: CSSProperties = {
    width: lineWidth,
    left: 'unset',
    backgroundColor: baseColors.blue,
    borderRadius: 26,
    cursor: 'pointer'
};

export const railStyles: CSSProperties = {
    height: '100%',
    width: lineWidth,
    backgroundColor: baseColors.lightGrey,
    borderRadius: 26,
    cursor: 'pointer'
};
