import { createEffect, createEvent, createStore } from 'effector';
import { bucmAPI } from 'services/bucm';

// Events

const resetStores = createEvent();

// Effects

const getCollaborationFx = createEffect({
    handler: async (collaborationId: string) => {
        const res = await bucmAPI.collaboration.getCreatorCollaboration(collaborationId);

        if (res.isSuccess) {
            return res.data || null;
        }

        return null;
    }
});

interface MyCollaborationRequest {
    bcmUserId: string;
    collaborationId: string;
}

const getMyCollaborationFx = createEffect({
    handler: async (params: MyCollaborationRequest) => {
        const res = await bucmAPI.collaboration.getMyCollaboration(params.bcmUserId, params.collaborationId);

        if (res.isSuccess) {
            return res.data || null;
        }

        return null;
    }
});

const checkIsMyCollaborationFx = createEffect({
    handler: async (params: MyCollaborationRequest) =>
        await bucmAPI.collaboration.checkIsMyCollaboration(params.bcmUserId, params.collaborationId)
});

// Stores

const $collaborationData = createStore<BUCM.ActiveCollaborationResponse | null>(null)
    .on(getCollaborationFx.doneData, (_, data) => data)
    .reset([resetStores, getCollaborationFx.fail]);

const $myCollaborationData = createStore<BUCM.CreatorCollaborationResponse | null>(null)
    .on(getMyCollaborationFx.doneData, (_, data) => data)
    .reset([resetStores, getMyCollaborationFx.fail]);

const $isUserJoinedCollaboration = createStore<boolean>(false)
    .on(checkIsMyCollaborationFx.doneData, () => true)
    .on(checkIsMyCollaborationFx.failData, () => false)
    .reset([resetStores]);

// Exports

export const collaborationEvents = { resetStores };
export const collaborationEffects = { getCollaborationFx, getMyCollaborationFx, checkIsMyCollaborationFx };
export const collaborationStores = { $collaborationData, $myCollaborationData, $isUserJoinedCollaboration };
