import { transitionTime } from 'constants/styles';
import { baseColors, blue, grey } from 'constants/styles/colors';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface Props {
    underline?: boolean;
    type: string;
}

const whiteLink = css`
    color: ${baseColors.white};
    text-decoration-color: ${baseColors.white};

    &:hover {
        color: ${grey[100]};
    }
`;

const blueLink = css`
    color: ${baseColors.blue};
    text-decoration-color: ${baseColors.blue};

    &:hover {
        color: ${blue[300]};
    }
`;

const getTypeStyle = (props: Props) => {
    switch (props.type) {
        case 'white':
        default:
            return whiteLink;
        case 'blue':
            return blueLink;
    }
};

export const TextWrapper = styled(Link)<Props>`
    ${({ underline }) => underline && 'text-decoration: underline'};
    ${props => getTypeStyle(props)};
    transition: color ${transitionTime} linear;
`;
