import { CopySmIcon } from 'assets/icons';
import qsSrc from 'assets/img/quick-signals/mobile.png';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { infoModal } from 'stores/initialize.modal';
import {
    ImagesWrapper,
    ImageWrapper,
    ProfileImageLeftWrapper,
    ProfileImageRightWrapper,
    QsImage,
    QsWrapper,
    TextSection
} from './styles';

const { modal, closeModal } = infoModal;

export const InfoModal = () => {
    const [visible, state] = useStore(modal);
    const {
        title,
        text,
        icon: Icon,
        srcLeft,
        srcRight,
        qsAvatar,
        buttonText,
        cancelText,
        copyText,
        image,
        onClick,
        onCloseClick,
        afterCloseCb,
        copyButtonText,
        dark = false
    } = state;

    const handleOkClick = () => {
        closeModal();
        onClick?.();
    };

    const handleCancelClick = () => {
        closeModal();
        onCloseClick?.();
    };

    const handleClose = () => {
        closeModal();
        afterCloseCb?.();
    };

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Link Copied');
        }
    };

    return (
        <ModalWrapper dark={dark} visible={visible} zIndex={200} onClose={handleClose}>
            <Column noWrap width="100%">
                {Icon && (
                    <Section justifyCenter marginBottom="12px">
                        <Icon />
                    </Section>
                )}

                {image && (
                    <Section justifyCenter marginBottom="12px">
                        <ImageWrapper src={image} />
                    </Section>
                )}

                {srcLeft && srcRight && (
                    <Section justifyCenter marginBottom="12px">
                        <ImagesWrapper>
                            <ProfileImageLeftWrapper src={srcLeft} />
                            <ProfileImageRightWrapper src={srcRight} />
                        </ImagesWrapper>
                    </Section>
                )}

                {qsAvatar && (
                    <Section justifyCenter marginBottom="24px">
                        <QsWrapper>
                            <QsAvatar large url={qsAvatar} />
                            <QsImage src={qsSrc} />
                        </QsWrapper>
                    </Section>
                )}

                {title && (
                    <TextSection marginBottom="12px">
                        <Text alignTextCenter isBold>
                            {title}
                        </Text>
                    </TextSection>
                )}

                {text && (
                    <TextSection marginBottom="12px">
                        {typeof text === 'string' ? (
                            <Text alignTextCenter size="-2">
                                {text}
                            </Text>
                        ) : (
                            text
                        )}
                    </TextSection>
                )}

                <Section marginTop="12px">
                    <Button wide onClick={handleOkClick}>
                        {buttonText || 'Ok'}
                    </Button>
                </Section>

                {copyButtonText && copyText && (
                    <Section marginTop="12px">
                        <CopyToClipboard text={copyText} onCopy={handleCopy}>
                            <Button
                                wide
                                buttonType="dark"
                                icon={<CopySmIcon />}
                                iconPosition="left"
                                isBold={false}
                                textSize="small"
                            >
                                {copyButtonText}
                            </Button>
                        </CopyToClipboard>
                    </Section>
                )}

                {onCloseClick && (
                    <Section marginTop="24px">
                        <TextButton isBold isUppercase wide onClick={handleCancelClick}>
                            {cancelText || 'Cancel'}
                        </TextButton>
                    </Section>
                )}
            </Column>
        </ModalWrapper>
    );
};
