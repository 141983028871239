import { Column } from 'components/grid/wrappers/FlexWrapper';
import { transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background-color: ${baseColors.darkGrey};
    display: flex;
    justify-content: space-between;

    ${media.laptop(`
        background-color: ${baseColors.black};
    `)};
`;

export const PlayerWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    ${media.laptop(`
        width: 350px;
    `)};
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 11;
`;

export const RightIconWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -100px;
    cursor: pointer;
    color: ${grey[500]};
    transition: color ${transitionTime} linear;

    &:hover:not(:disabled) {
        color: ${baseColors.white};
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const LeftIconWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -100px;
    cursor: pointer;
    color: ${grey[500]};
    transition: color ${transitionTime} linear;

    &:hover:not(:disabled) {
        color: ${baseColors.white};
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const LeftCardsWrapper = styled.div`
    position: absolute;
    display: inline-flex;
    left: 0;
    top: 50%;
    transform: translate(calc(-100% - 150px), -50%);

    & > div {
        padding-left: 23px;
    }
`;

export const RightCardsWrapper = styled.div`
    position: absolute;
    display: inline-flex;
    right: 0;
    top: 50%;
    transform: translate(calc(100% + 150px), -50%);

    & > div {
        padding-right: 23px;
    }
`;

interface CardProps {
    fullSize?: boolean;
}

export const Card = styled.div<CardProps>`
    width: ${({ fullSize }) => (fullSize ? '100%' : '150px')};
    height: ${({ fullSize }) => (fullSize ? '100%' : '250px')};
    background-color: ${baseColors.darkGrey};
    ${flexCenter};
    flex-direction: column;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const TopBarWrapper = styled(Column)`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    padding: 16px;
    flex-wrap: nowrap;
    z-index: 5;
`;

interface StoriesLinesProps {
    count: number;
}

export const StoriesLines = styled.div<StoriesLinesProps>`
    width: 100%;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
`;

interface StoriesLineDone {
    progress?: number;
    done?: boolean;
    isCurrent?: boolean;
}

export const StoriesLine = styled.div<StoriesLineDone>`
    flex: 1;
    height: 2px;
    background-color: ${({ done }) => (done ? baseColors.blue : grey[700])};
    border-radius: 50px;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: ${({ progress, isCurrent }) => (isCurrent ? `${progress || 0}%` : 0)};
        height: 100%;
        background-color: ${baseColors.blue};
        z-index: 1;
        transition: width 0.2s linear;
    }
`;

export const BottomBarWrapper = styled(Column)`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 16px;
    flex-wrap: nowrap;
    z-index: 5;
`;
