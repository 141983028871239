import { VideoSlider } from 'components/video/VideoSlider';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { homeEffects, homeEvents, homeStores } from 'stores/home';

const { setActiveMobileIndex } = homeEvents;
const { getHomePlaylistFx } = homeEffects;
const { $homePlaylist, $isBlockedLoading, $activeMobileIndex } = homeStores;

export const HomeMobileSlider = () => {
    const { items } = useStore($homePlaylist);
    const isLoading = useStore(getHomePlaylistFx.pending);
    const isBlockedLoading = useStore($isBlockedLoading);
    const index = useStore($activeMobileIndex);

    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

    const handleLastSlide = () => {
        if (!isBlockedLoading) {
            getHomePlaylistFx();
        }
    };

    const handleSlideChange = (index: number) => {
        setActiveMobileIndex(index);
    };

    useEffect(() => {
        getHomePlaylistFx();
    }, []);

    useEffect(() => {
        if (items && items.length > 0 && index !== 0 && index <= items.length - 1) {
            setActiveIndex(index);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VideoSlider
            activeItemIndex={activeIndex}
            isLoading={isLoading}
            items={items}
            onLastSlide={handleLastSlide}
            onSlideChange={handleSlideChange}
        />
    );
};
