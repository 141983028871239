import { Tabs } from 'components/ui/Tabs';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { giftsEvents } from 'stores/gifts';
import { MoreCards } from '../MoreCards';
import { MyCards } from '../MyCards';
import { GiftTab } from '../types';
import { Wrapper } from './styles';

const { resetBrandsStores, resetPurchasesStores } = giftsEvents;

export const GiftsTabs = () => {
    const [activeTab, setActiveTab] = useState(GiftTab.All);

    const handleTabsChange = (value: GiftTab) => {
        setActiveTab(value);

        if (value === GiftTab.All) {
            resetPurchasesStores();
        } else {
            resetBrandsStores();
        }
    };

    useEffect(
        () => () => {
            resetBrandsStores();
            resetPurchasesStores();
        },
        []
    );

    return (
        <Wrapper>
            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: GiftTab.All,
                        title: 'More Cards',
                        content: <MoreCards />,
                        width: isMobile ? '50%' : '175px'
                    },
                    {
                        id: GiftTab.My,
                        title: 'My Cards',
                        content: <MyCards onChangeTab={handleTabsChange} />,
                        width: isMobile ? '50%' : '175px'
                    }
                ]}
                onChange={handleTabsChange}
            />
        </Wrapper>
    );
};
