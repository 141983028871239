import { Section } from 'components/grid/wrappers/FlexWrapper';
import styled from 'styled-components';

export const ImageWrapper = styled(Section)`
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 4px;
`;

export const TextWrapper = styled(Section)`
    justify-content: center;
    text-align: center;
`;
