import { TextWrapper, WrapperCard } from 'components/cards/CreatorCard/styles';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC, MouseEvent } from 'react';

interface Props {
    username?: string | null;
    profileImageUrl?: string | null;
    id?: string | null;
    onClick?: (id: string) => void;
}

export const CreatorCard: FC<Props> = ({ username, profileImageUrl, id, onClick }) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        id && onClick && onClick(id);
    };

    return (
        <WrapperCard type="button" onClick={handleClick}>
            <MarginWrapper marginBottom="18px">
                <AvatarImg src={profileImageUrl || undefined} />
            </MarginWrapper>
            <TextWrapper>
                <Text size="-2">{username}</Text>
            </TextWrapper>
        </WrapperCard>
    );
};
