import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { teamEffects } from 'stores/team';
import { Id } from 'types/interfaces/data';
import { noop } from 'utils/common';

const { $user } = authStores;
const { removeUserFromTeamFx } = teamEffects;

interface Props {
    urlName?: string | null;
    ownerId?: string | null;
    teamId?: string | null;
    isLoading?: boolean;
    items?: BULLZ.TeamMemberResponse[] | null;
}

export const TeamMembersTabs: FC<Props> = ({ urlName, ownerId, teamId, isLoading, items }) => {
    const user = useStore($user);

    const { id } = useParams<Partial<Id>>();

    const removeUser = (userId: string, username: string, profileImageUrl: string) => {
        infoModal.openModal({
            srcLeft: urlName || defaultAvatar,
            srcRight: profileImageUrl || defaultAvatar,
            title: 'Remove from Team',
            text: `Are you sure you want to remove @${username} from your team?`,
            buttonText: 'Not Now',
            cancelText: 'remove',
            onClick: noop,
            onCloseClick: () => {
                removeUserFromTeamFx({
                    userId: userId,
                    teamId: id
                });
            }
        });
    };

    return (
        <Column height="100%" marginTop="22px">
            {isLoading ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : (
                <Column height="100%" width="100%">
                    {items &&
                        items?.length > 0 &&
                        items?.map(({ userId, profileImageUrl, username, isTrusted }) => (
                            <Section key={userId} alignCenter justifyBetween noWrap marginBottom="24px">
                                <Link to={`${AppRoute.Profile}/${userId}`}>
                                    <Row alignCenter>
                                        <AvatarImg src={profileImageUrl || undefined} />
                                        <Column marginLeft="10px">
                                            <Text size="-2">@{username}</Text>
                                        </Column>

                                        {isTrusted && (
                                            <Column marginLeft="10px">
                                                <VerificationSmIcon height="20px" width="20px" />
                                            </Column>
                                        )}
                                    </Row>
                                </Link>
                                {user?.userId !== userId &&
                                    user?.userId === ownerId &&
                                    user?.ownedTeam?.teamId === teamId && (
                                        <TextButton
                                            isBold={false}
                                            onClick={() =>
                                                removeUser(userId || '', username || '', profileImageUrl || '')
                                            }
                                        >
                                            Remove
                                        </TextButton>
                                    )}
                            </Section>
                        ))}
                </Column>
            )}
        </Column>
    );
};
