import { NotFound } from 'components/common/NotFound';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles';
import React, { FC } from 'react';

interface Props {
    query?: string | Array<string | null> | null;
}

export const EmptyResult: FC<Props> = ({ query }) => (
    <>
        {!query ? (
            <Section alignCenter justifyCenter height="580px">
                <Text color={grey[500]} size="-2">
                    Your searches will appear here
                </Text>
            </Section>
        ) : (
            <NotFound />
        )}
    </>
);
