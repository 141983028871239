import { ClickableWrapper } from 'components/grid/wrappers/ClickableWrapper';
import { baseColors, disableDefaultButtonStyleMixin, flexCenter, grey, transitionTime } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

interface Props {
    href?: string;
}

export const StyledExternalButton = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    ${flexCenter};
    padding: 32px 20px;
    border-radius: 8px;
    background-color: ${baseColors.black};
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    ${media.laptop(`
        background-color: transparent;
        padding: 30px 26px;

        &:hover {
            background-color: ${grey[800]};
        }
    `)};
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
`;

export const LinkWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;

    ${media.laptop(`
        row-gap: 18px;
        column-gap: 16px;
    `)};
`;

export const MixPayButton = styled(ClickableWrapper)`
    width: 200px;
    height: 50px;
    background-color: ${baseColors.blue};
    border-radius: 4px;
    transition: opacity ${transitionTime};

    :hover {
        opacity: 0.8;
    }
`;
