import { SelectOption } from 'components/ui/Select/types';
import { differenceInDays, differenceInYears } from 'date-fns';

export const getDays = (): SelectOption[] =>
    Array(31)
        .fill(null)
        .map((_, i) => ({
            value: `${i + 1}`,
            label: `${i + 1}`
        }));

export const getMonths = (): SelectOption[] => {
    const monthsFull = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    return monthsFull.map((item, i) => ({
        value: `${i + 1}`,
        label: item
    }));
};

export const getYears = (): SelectOption[] => {
    const currentYear = new Date().getFullYear();
    const result: SelectOption[] = [];

    for (let i = currentYear; i >= 1950; i--) {
        result.push({
            value: `${i}`,
            label: `${i}`
        });
    }

    return result;
};

export const getAge = (date: Date) => differenceInYears(new Date(), date);

export const getDaysUntilBirthday = (day: string, month: string) => {
    const currentYear = new Date().getFullYear();
    let year = currentYear;

    if (new Date(currentYear, +month - 1, +day).getTime() > new Date().getTime()) {
        year = currentYear;
    } else {
        year = currentYear + 1;
    }

    return differenceInDays(new Date(year, +month - 1, +day), new Date()) + 1;
};

export const getAgeText = (day: string, month: string, year: string) => {
    const age = getAge(new Date(+year, +month - 1, +day));
    const nextBirthdayDays = getDaysUntilBirthday(day, month);

    return `You’re ${age} years old, there’s ${nextBirthdayDays} days until your birthday 🎉`;
};
