import 'assets/styles/global.css';
import { AppModals } from 'components/modals/AppModals';
import { AuthRoute } from 'components/route/AuthRoute';
import { WalletAuthRoute } from 'components/route/WalletAuthRoute';
import {
    AppRoute,
    collaborationDetailsUrl,
    followUrl,
    profileUrl,
    qsChannelDetailsUrl,
    referralUrl,
    teamUrl,
    videoIdUrl,
    walletRoutes
} from 'constants/routes';
import { GlobalStyle } from 'constants/styles';
import { useStore } from 'effector-react';
import { useHandleQueryParams } from 'hooks/useHandleQueryParams';
import { Error404 } from 'pages/404';
import { CollaborationsPage } from 'pages/CollaborationsPage';
import { CollaborationDetailsPage } from 'pages/CollaborationsPage/Details';
import { FollowedHashtags } from 'pages/FollowedHashtags';
import { FollowPage } from 'pages/FollowPage';
import { Gifts } from 'pages/Gifts';
import { LoadingPage } from 'pages/LoadingPage';
import { NewHomePage } from 'pages/NewHome';
import { NotificationsPage } from 'pages/NotificationsPage';
import { Profile } from 'pages/Profile';
import { QuickSignals } from 'pages/QuickSignals';
import { QuickSignalsChannel } from 'pages/QuickSignals/Channel';
import { QuickSignalsCreateOrEdit } from 'pages/QuickSignals/CreateOrEdit';
import { QuickSignalsSubscriptions } from 'pages/QuickSignals/Subscriptions';
import { SavedVideos } from 'pages/SavedVideos';
import { Search } from 'pages/Search';
import { SearchVideos } from 'pages/SearchVideos';
import { Settings } from 'pages/Settings';
import { Team } from 'pages/Team';
import { Trending } from 'pages/Trending';
import { VideoPage } from 'pages/Video';
import { WalletPage } from 'pages/Wallet';
import React, { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Route, Switch, useLocation } from 'react-router-dom';
import { authEffects, authStores } from 'stores/auth';
import { bucmUserEffects } from 'stores/bucm-user';
import { headerEvents } from 'stores/header';
import { myQsModal } from 'stores/initialize.modal';
import { notificationsEvents } from 'stores/notifications';
import { quickSignalsEffects } from 'stores/quick-signals';
import { qsEvents } from 'stores/quick-signals-trending';
import { walletEffects, walletEvents } from 'stores/wallet';
import { IdleTimer } from 'utils/IdleTimer';

const App = () => {
    const location = useLocation();
    const { token } = useStore(authStores.$anonymousUser);
    const isAuth = useStore(authStores.$isAuth);

    useEffect(() => {
        qsEvents.closeQSModal();
        myQsModal.closeModal();
        headerEvents.setIsVisibleBurgerMenu(false);
        notificationsEvents.closeDropdown();

        // Clear wallet token
        if (!walletRoutes.includes(location.pathname as AppRoute)) {
            walletEvents.resetWalletToken();
        }

        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        !token && authEffects.createAnonymousAccountFx();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAuth) {
            authEffects.getCurrentUserFx();
            walletEffects.getWomUserFx();
            bucmUserEffects.getBucmUserFx();
            quickSignalsEffects.getQuickSignalsInfoFx();
        }
    }, [isAuth]);

    useHandleQueryParams();

    if (!token) return <LoadingPage />;

    if (isIOS && !isMobile) return null;

    return (
        <>
            <GlobalStyle />
            <IdleTimer />
            <AppModals />

            <Switch>
                <Route exact component={NewHomePage} path={AppRoute.Home} />
                <Route exact component={NewHomePage} path={AppRoute.Main} />
                <Route exact component={Profile} path={profileUrl} />
                <Route exact component={Trending} path={AppRoute.Trending} />
                <Route exact component={VideoPage} path={videoIdUrl} />
                <Route exact component={SearchVideos} path={AppRoute.SearchVideos} />
                <Route exact component={Search} path={AppRoute.Search} />
                <Route exact component={Team} path={teamUrl} />
                <Route exact component={NewHomePage} path={referralUrl} />

                <AuthRoute exact component={FollowPage} path={followUrl} />
                <AuthRoute exact component={SavedVideos} path={AppRoute.SavedVideos} />
                <AuthRoute exact component={FollowedHashtags} path={AppRoute.FollowedHashtags} />
                <AuthRoute exact component={NotificationsPage} path={AppRoute.Notifications} />
                <AuthRoute exact component={Settings} path={AppRoute.Settings} />
                <AuthRoute exact component={QuickSignals} path={AppRoute.QuickSignals} />
                <AuthRoute exact component={QuickSignalsCreateOrEdit} path={AppRoute.QuickSignalsCreate} />
                <AuthRoute exact component={QuickSignalsCreateOrEdit} path={AppRoute.QuickSignalsEdit} />
                <AuthRoute exact component={QuickSignalsSubscriptions} path={AppRoute.QuickSignalsSubscriptions} />
                <AuthRoute exact component={QuickSignalsChannel} path={qsChannelDetailsUrl} />
                <AuthRoute exact component={CollaborationsPage} path={AppRoute.Collaborations} />
                <AuthRoute exact component={CollaborationDetailsPage} path={collaborationDetailsUrl} />

                <WalletAuthRoute exact component={WalletPage} path={AppRoute.Wallet} />
                <WalletAuthRoute exact component={Gifts} path={AppRoute.Gifts} />

                <Route exact component={Error404} path="*" />
            </Switch>
        </>
    );
};

export default App;
