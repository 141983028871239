import { grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 8px;
    border-radius: 8px;
    text-align: left;
    transition: background-color ${transitionTime} linear;
    margin-bottom: 8px;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    ${flexCenter};
`;
