import { BackArrowSmIcon, ThreeDotsVerticalSmIcon, VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { FollowPageType } from 'constants/enum';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { loginModal, reportUserModal, walletGuideModal } from 'stores/initialize.modal';
import { walletEffects, walletStores } from 'stores/wallet';
import { ClickableWrapper } from '../../styles';
import { ProfileBlockViewProps } from '../../types';
import { QuickSignalsInfo } from '../QuickSignalsInfo';
import { SocialNetworks } from '../SocialNetworks';
import { MobileBio, MobileContainer, MobileHeaderWrapper, MobileTeamButton, TeamText } from './styles';

const { $hasWallet, $womUserIsLoaded } = walletStores;

export const MobileView: FC<ProfileBlockViewProps> = ({ data, currentUser, goToFollow, goToTeam, onFollow }) => {
    const { id, username, isTrusted, isFollowed, profileImageUrl, followedCount, followersCount, team, bio } = data;
    const hasWallet = useStore($hasWallet);
    const womUserIsLoaded = useStore($womUserIsLoaded);
    const history = useHistory();

    const isAuth = !!currentUser;
    const isMyProfile = isAuth && currentUser.userId === id;

    const goToBack = () => {
        history.goBack();
    };

    const handleReportUser = () => {
        if (isAuth && id) {
            reportUserModal.openModal({ userId: id });
        } else {
            loginModal.openModal();
        }
    };

    return (
        <MobileContainer>
            <MobileHeaderWrapper>
                <IconButton onClick={goToBack}>
                    <BackArrowSmIcon height={15} width={16} />
                </IconButton>

                <Row alignCenter>
                    <Text isBold isUppercase>
                        @{username}
                    </Text>
                    {isTrusted && (
                        <Column justifyCenter marginLeft="10px">
                            <VerificationSmIcon height="20px" width="20px" />
                        </Column>
                    )}
                </Row>

                {!isMyProfile ? (
                    <IconButton onClick={handleReportUser}>
                        <ThreeDotsVerticalSmIcon />
                    </IconButton>
                ) : (
                    <div />
                )}
            </MobileHeaderWrapper>

            <Column alignCenter noWrap width="100%">
                <AvatarImg diameter="80px" src={profileImageUrl || undefined} />

                <Section alignCenter justifyCenter marginTop="20px">
                    <ClickableWrapper type="button" onClick={() => goToFollow(FollowPageType.Followers)}>
                        <Text size="-1">{followersCount}</Text>
                        <MarginWrapper marginLeft="8px">
                            <Text size="-2">Followers</Text>
                        </MarginWrapper>
                    </ClickableWrapper>

                    <ClickableWrapper type="button" onClick={() => goToFollow(FollowPageType.Followings)}>
                        <Text size="-1">{followedCount}</Text>
                        <MarginWrapper marginLeft="8px">
                            <Text size="-2">Following</Text>
                        </MarginWrapper>
                    </ClickableWrapper>
                </Section>

                <Section alignCenter justifyCenter noWrap marginTop="8px">
                    <SocialNetworks isAuth={isAuth} userId={id} />
                </Section>

                <Section alignCenter justifyCenter noWrap marginTop="8px">
                    {isAuth && (
                        <>
                            {isMyProfile ? (
                                <>
                                    {womUserIsLoaded && (
                                        <>
                                            {hasWallet ? (
                                                <Button
                                                    noWrap
                                                    isBold={false}
                                                    size="small"
                                                    textSize="small"
                                                    onClick={() => walletEffects.existsWalletPinFx()}
                                                >
                                                    WOM Wallet
                                                </Button>
                                            ) : (
                                                <Button
                                                    isBold={false}
                                                    size="small"
                                                    onClick={() => walletGuideModal.openModal()}
                                                >
                                                    Create Wallet
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Button
                                    buttonType={isFollowed ? 'secondary' : undefined}
                                    isBold={false}
                                    size="small"
                                    onClick={onFollow}
                                >
                                    {isFollowed ? 'Following' : 'Follow'}
                                </Button>
                            )}
                        </>
                    )}

                    {team && (
                        <Column justifyCenter marginLeft={isAuth ? '8px' : undefined}>
                            <MobileTeamButton type="button" onClick={() => goToTeam(team.id || '', id || '')}>
                                <AvatarImg diameter="24px" src={team.ownerProfileImageUrl || undefined} />
                                <Column justifyCenter marginLeft="8px">
                                    <TeamText size="-2">{team.name}</TeamText>
                                </Column>
                            </MobileTeamButton>
                        </Column>
                    )}
                </Section>

                {bio && (
                    <MobileBio marginTop="16px">
                        <Text size="-2">{bio}</Text>
                    </MobileBio>
                )}

                {isAuth && id && (
                    <QuickSignalsInfo
                        avatar={profileImageUrl || undefined}
                        isMyProfile={isMyProfile}
                        isVerified={isTrusted}
                        userId={id}
                    />
                )}
            </Column>
        </MobileContainer>
    );
};
