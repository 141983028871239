import { createEffect, createEvent, createStore } from 'effector';
import { bucmAPI } from 'services/bucm';

//Events

const resetBucmUser = createEvent();

//Effects

const getBucmUserFx = createEffect({
    handler: async (data?: BUCM.GetUserRequest) => await bucmAPI.users.getBucmUser(data)
});

//Stores

const $bucmUser = createStore<BUCM.UserResponse>({})
    .on(getBucmUserFx.doneData, (_, newState) => newState.response)
    .reset(resetBucmUser);

//Exports

export const bucmUserStores = {
    $bucmUser
};

export const bucmUserEffects = {
    getBucmUserFx
};

export const bucmUserEvents = { resetBucmUser };
