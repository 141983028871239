import { VerificationSmIcon } from 'assets/icons';
import Hash from 'assets/img/hash.png';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { SearchItemType } from 'components/grid/Header/SearchDropdown';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { headerEvents } from 'stores/header';
import { EmptyMessage, HashImg, StyledButton, TabContent, TabItem, TabWrapper } from './styles';

export interface SearchItem {
    title?: string | null;
    entityId?: string | null;
    isTrusted?: boolean;
    thumbnailUrl?: string | null;
    type?: SearchItemType;
    isCategory?: boolean;
}

interface Props {
    searchValue: string;
    items: SearchItem[];
    type: SearchCategory;
    isTabHashtag?: boolean;
    onItemClick: () => void;
}

export const SearchTabContent: React.FC<Props> = ({ searchValue, isTabHashtag, items, type, onItemClick }) => {
    const history = useHistory();

    const handleSeeAll = () => {
        history.push(`${AppRoute.Search}?query=${searchValue}&type=${type}`);
    };

    const handleItemClick = (data?: SearchItem) => {
        if (data) {
            switch (type) {
                case SearchCategory.Topic:
                    history.push(
                        `${AppRoute.SearchVideos}?by=${SearchCategory.Topic}&query=${searchValue}&topicId=${data.entityId}&name=${data.title}`
                    );
                    break;
                case SearchCategory.Hashtag:
                    history.push(
                        `${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&query=${searchValue}&tagId=${
                            data.entityId
                        }&isCategory=${data.isCategory ? +data.isCategory : 0}&name=${data.title}`
                    );
                    break;
                case SearchCategory.Creator:
                    history.push(`${AppRoute.Profile}/${data.entityId}`);
                    break;
            }
            headerEvents.setIsVisibleBurgerMenu(false);
        }

        onItemClick();
    };

    return (
        <TabWrapper>
            <TabContent>
                {items.map(item => (
                    <TabItem
                        key={item.entityId}
                        onClick={() => {
                            handleItemClick(item);
                        }}
                    >
                        {item.type === SearchItemType.Hashtag && <HashImg src={Hash} />}
                        {item.type === SearchItemType.User && (
                            <MarginWrapper marginRight="10px">
                                <AvatarImg src={item.thumbnailUrl || undefined} />
                            </MarginWrapper>
                        )}
                        {item.title}
                        {item.isTrusted && (
                            <Column marginLeft="10px">
                                <VerificationSmIcon />
                            </Column>
                        )}
                    </TabItem>
                ))}
                {!items.length && (
                    <EmptyMessage>{!searchValue ? 'Your searches will appear here' : 'No results found'}</EmptyMessage>
                )}
            </TabContent>
            {(!isMobile || items.length > 0) && (
                <StyledButton wide disabled={!searchValue && !isTabHashtag} onClick={handleSeeAll}>
                    See all
                </StyledButton>
            )}
        </TabWrapper>
    );
};
