import { StatusSubmitSmIcon } from 'assets/icons';
import styled, { keyframes } from 'styled-components';

// Определение ключевых кадров для анимации
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const StyledSvg = styled(StatusSubmitSmIcon)`
    animation: ${pulseAnimation} 2s infinite;
`;
