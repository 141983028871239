import React, { FC } from 'react';
import { TextProperties } from 'types/interfaces/styles';
import { Size, Text } from '../typography/Text';
import { TextWrapper } from './styles';

interface Props extends TextProperties {
    isUppercase?: boolean;
    isBold?: boolean;
    size?: Size;
    href: string;
    underline?: boolean;
    type?: 'blue' | 'white';
    isExternal?: boolean;
    noWrap?: boolean;
}

export const Link: FC<Props> = ({
    children,
    href,
    isUppercase,
    isBold,
    size = '-2',
    type = 'white',
    underline = false,
    isExternal,
    noWrap = false
}) => (
    <TextWrapper
        target={isExternal ? '_blank' : undefined}
        to={isExternal ? { pathname: href } : href}
        type={type}
        underline={underline || undefined}
    >
        <Text color="inherit" isBold={isBold} isUppercase={isUppercase} noWrap={noWrap} size={size}>
            {children}
        </Text>
    </TextWrapper>
);
