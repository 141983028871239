import { Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import { ErrorWrapper, Label, TextareaStyled } from './styles';

export interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    labelComponent?: ReactNode;
    error?: string;
    wide?: boolean;
}

export const Textarea: FC<TextareaProps> = ({
    label,
    labelComponent,
    error,
    wide = false,
    value,
    required,
    maxLength,
    ...props
}) => (
    <Label wide={wide}>
        {label && (
            <Section marginBottom="4px">
                <Text size="-1">
                    {label} {required && '*'}
                </Text>
            </Section>
        )}

        {labelComponent && <Section marginBottom="12px">{labelComponent}</Section>}

        <TextareaStyled hasError={!!error} maxLength={maxLength} required={required} value={value} {...props} />

        {(error || maxLength) && (
            <Section justifyBetween marginTop="4px">
                {error ? (
                    <ErrorWrapper>
                        <Text color="inherit" size="-2">
                            {error}
                        </Text>
                    </ErrorWrapper>
                ) : (
                    <div />
                )}

                {maxLength && (
                    <MarginWrapper marginLeft="8px">
                        <Text color={!!error ? baseColors.red : undefined} size="-2">
                            {value?.toString().length || 0}/{maxLength}
                        </Text>
                    </MarginWrapper>
                )}
            </Section>
        )}
    </Label>
);
