import { CloseSmIcon } from 'assets/icons';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { Comments } from 'components/video/FullscreenVideo/components/Comments';
import React, { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { NoopType } from 'types';
import { ModalBackdrop, ModalContent, ModalHeader } from './styles';

interface Props {
    videoId?: string;
    commentsCount?: number;
    onClose?: NoopType;
}

export const MobileCommentsModal: FC<Props> = ({ videoId, commentsCount = 0, onClose }) => {
    const body = document.body;
    const modalRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        onClose && onClose();
    };

    if (!videoId) {
        return null;
    }

    return createPortal(
        <>
            <ModalBackdrop onClick={handleClose} />
            <ModalContent ref={modalRef}>
                <ModalHeader>
                    <Text isBold>Comments ({commentsCount})</Text>
                    <IconButton onClick={handleClose}>
                        <CloseSmIcon />
                    </IconButton>
                </ModalHeader>
                <Comments videoId={videoId} />
            </ModalContent>
        </>,
        body
    );
};
