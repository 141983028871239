import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { CollaborationCard } from 'components/ui/CollaborationCard';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { collaborationsEffects, collaborationsStores } from 'stores/collaborations';
import { CollaborationWrapper, CollaborationsList, SeeAllWrapper, SliderWrapper } from './styles';

const viewCount = 4;
const { $creatorCollaborations, $hasError } = collaborationsStores;
const { getCreatorCollaborationsFx } = collaborationsEffects;

export const Collaborations = () => {
    const isLoading = useStore(getCreatorCollaborationsFx.pending);
    const { data } = useStore($creatorCollaborations);
    const hasError = useStore($hasError);
    const collaborations = data?.items;

    const history = useHistory();

    const seeAll = () => {
        history.push(AppRoute.Collaborations);
    };

    const handleClickCard = (id: string) => {
        id && history.push(`${AppRoute.Collaborations}/${id}`);
    };

    useEffect(() => {
        getCreatorCollaborationsFx();
    }, []);

    if (isLoading) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    if ((collaborations && collaborations.length === 0) || !collaborations || hasError) {
        return null;
    }

    return (
        <Column noWrap width="100%">
            <Section alignCenter justifyBetween marginBottom={isMobile ? '12px' : '20px'}>
                <Text isBold={isMobile} size={isMobile ? '1' : '-1'}>
                    Collaborations
                </Text>

                {isMobile && <TextButton onClick={seeAll}>See All</TextButton>}
            </Section>

            {isMobile ? (
                <SliderWrapper>
                    {collaborations &&
                        collaborations.map(item => (
                            <CollaborationWrapper key={item.id} onClick={() => handleClickCard(item.id || '')}>
                                <CollaborationCard showAllNetworks data={item} />
                            </CollaborationWrapper>
                        ))}
                </SliderWrapper>
            ) : (
                <CollaborationsList>
                    {collaborations &&
                        collaborations.slice(0, viewCount).map(item => (
                            <CollaborationWrapper key={item.id} onClick={() => handleClickCard(item.id || '')}>
                                <CollaborationCard rounded data={item} />
                            </CollaborationWrapper>
                        ))}
                </CollaborationsList>
            )}

            {!isMobile && collaborations && collaborations.length > viewCount && (
                <SeeAllWrapper>
                    <TextButton onClick={seeAll}>See All</TextButton>
                </SeeAllWrapper>
            )}
        </Column>
    );
};
