import axios from '../axios';

export const followHashtag = (data: BULLZ.UserFollowHashTagRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/follow',
        data
    });

export const getUserHashtags = (data: BULLZ.QueryFollowedHashTagsRequest) =>
    axios<BULLZ.QueryFollowingHashTagsResponse>({
        url: '/user/hashtag/query-followed',
        data
    });

export const toFollowHashtag = (data: BULLZ.UserFollowHashTagRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/hashtag/follow',
        data
    });
