import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { CloseSmIcon, ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { JoinCard } from 'components/modals/QSModal/components/JoinCard';
import { IconButton } from 'components/ui/buttons/IconButton';
import { asyncErrorMessage } from 'constants/messages';
import { womErrorStatusCode } from 'constants/services/quick-signals';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React from 'react';
import { infoModal, qsSubscribeModal } from 'stores/initialize.modal';
import { quickSignalsEffects } from 'stores/quick-signals';
import { userEffects } from 'stores/user';
import { walletEffects } from 'stores/wallet';
import { goToHelpDesk, noop } from 'utils/common';

const { openModal: openInfoModal } = infoModal;

const { modal, closeModal } = qsSubscribeModal;

export const QsSubscribeModal = () => {
    const [visible, state] = useStore(modal);

    const close = () => closeModal();

    const handleSubscribeError = (code?: string) => {
        if (code && code === womErrorStatusCode) {
            openInfoModal({
                icon: WrongLgIcon,
                title: 'Not enough WOM',
                text: 'Don’t worry! Just add WOM to your wallet and then you can join My Cool Quick Signals.',
                buttonText: 'Go to Wallet',
                cancelText: 'Not Now',
                onClick: () => {
                    closeModal();
                    walletEffects.existsWalletPinFx();
                },
                onCloseClick: noop
            });
        } else {
            openInfoModal({
                icon: WrongLgIcon,
                title: asyncErrorMessage,
                text: 'Please try again or contact our support team.',
                buttonText: 'Try Again',
                cancelText: 'contact support',
                onClick: () => noop,
                onCloseClick: goToHelpDesk
            });
        }
    };

    const subscribeRequest = (channelId: string, userId?: string) => {
        quickSignalsEffects
            .subscribeToQSChannelFx(channelId)
            .then(() => {
                if (userId) {
                    userEffects.getUserProfileFx(userId);
                }

                closeModal();

                // TODO onClicks
                openInfoModal({
                    icon: ConfettiLgIcon,
                    title: 'Congratulations!',
                    text: `You have joined ${state.name}`,
                    buttonText: 'View Channel',
                    cancelText: 'See All My Channels',
                    onClick: () => noop,
                    onCloseClick: noop
                });
            })
            .catch(({ error }) => {
                handleSubscribeError(error.code as string);
            });
    };

    const handleJoin = (channelId: string, userId?: string) => {
        openInfoModal({
            title: 'Confirm Subscription',
            text: 'Are you ready to join My Cool Quick Signals?',
            qsAvatar: state.avatar || defaultAvatar,
            buttonText: 'Yes',
            cancelText: 'No',
            onClick: () => subscribeRequest(channelId, userId),
            onCloseClick: noop
        });
    };

    return (
        <ModalWrapper visible={visible} onClose={close}>
            <Column>
                <Section justifyEnd marginBottom="24px">
                    <IconButton color={grey[500]} type="button" onClick={close}>
                        <CloseSmIcon />
                    </IconButton>
                </Section>
                <JoinCard {...state} onJoin={handleJoin} />
            </Column>
        </ModalWrapper>
    );
};
