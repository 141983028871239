import { Spinner } from 'components/loaders/Spinner';
import { useStore } from 'effector-react';
import React from 'react';
import { mainLoader } from 'stores/initialize.modal';
import { MainLoaderWrapper, SpinnerWrapper } from './styles';

const { modal } = mainLoader;

export const MainLoader = () => {
    const [visible] = useStore(modal);

    if (!visible) {
        return null;
    }

    return (
        <MainLoaderWrapper>
            <SpinnerWrapper>
                <Spinner size={40} />
            </SpinnerWrapper>
        </MainLoaderWrapper>
    );
};
