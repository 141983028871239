import { forward, sample } from 'effector';
import { authEffects, authStores } from 'stores/auth';
import { loaderEffects } from 'stores/loader';
import { teamEffects, teamEvents, teamStores } from 'stores/team';
import { userEvents, userStores } from 'stores/user';

const { removeUserFromTeamFx, joinToTeamFx, leaveTeamFx, createTeamFx, updateTeamFx } = teamEffects;
const { $teamMembers, $teamInfo } = teamStores;
const { updateMembers, updateTeamInfo } = teamEvents;

const { $userProfile } = userStores;
const { updateProfile } = userEvents;

const { loaderFx } = loaderEffects;

sample({
    clock: removeUserFromTeamFx.done,
    source: $teamMembers,
    fn: (
        sourceData: BULLZ.QueryMembersResponse,
        { params, result }: { params: BULLZ.RemoveTeamMemberRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData?.items) {
            const newItems = sourceData?.items.filter(item => item.userId !== params.userId);
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: updateMembers
});

sample({
    clock: joinToTeamFx.done,
    source: [$teamMembers, authStores.$user],
    fn: (
        [sourceData, user]: [BULLZ.QueryMembersResponse, BULLZ.GetUserResponse | null],
        { result }: { result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && user) {
            const newUser: BULLZ.TeamMemberResponse = {
                userId: user.userId,
                username: user.username,
                isTrusted: user.isTrusted,
                profileImageUrl: user.profile?.profileImageUrl
            };
            const newItems: BULLZ.TeamMemberResponse[] = [...(sourceData?.items || []), newUser];
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: updateMembers
});

sample({
    clock: leaveTeamFx.done,
    source: [$teamMembers, authStores.$user],
    fn: (
        [sourceData, user]: [BULLZ.QueryMembersResponse, BULLZ.GetUserResponse | null],
        { result }: { result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData?.items && user) {
            const newItems = sourceData?.items.filter(item => item.userId !== user.userId);
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: updateMembers
});

forward({
    from: [leaveTeamFx.done, joinToTeamFx.done],
    to: authEffects.getCurrentUserFx
});

forward({
    from: createTeamFx.pending,
    to: loaderFx
});

forward({
    from: updateTeamFx.pending,
    to: loaderFx
});

forward({
    from: createTeamFx.done,
    to: authEffects.getCurrentUserFx
});

sample({
    clock: updateTeamFx.done,
    source: $teamInfo,
    fn: (source: BULLZ.GetTeamDetailResponse, { result }: { result: BULLZ.UpdateTeamResponse }) => {
        if (source && result) {
            const newTeam: BULLZ.GetTeamDetailResponse = {
                ...source,
                name: result.name
            };
            return { ...newTeam };
        }

        return source;
    },
    target: updateTeamInfo
});

sample({
    clock: createTeamFx.done,
    source: $userProfile,
    fn: (source: BULLZ.GetUserProfileResponse, { result }: { result: BULLZ.CreateTeamResponse }) => {
        if (source && result) {
            const newTeam: BULLZ.GetUserProfileResponse = {
                ...source,
                team: {
                    id: result.id,
                    ownerId: result.owner?.userId,
                    ownerName: result.owner?.username,
                    name: result.name,
                    urlName: result.urlName,
                    ownerProfileImageUrl: result.owner?.profileImageUrl
                }
            };
            return { ...newTeam };
        }

        return source;
    },
    target: updateProfile
});
