import { TextButtonProps } from 'components/ui/buttons/TextButton';
import { baseColors, blue, grey, red } from 'constants/styles/colors';
import { MontserratFontFamily } from 'constants/styles/fonts';
import { transitionTime } from 'constants/styles/global';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

const buttonBase = css`
    ${disableDefaultButtonStyleMixin};
    display: inline;
    font-family: ${MontserratFontFamily};
    transition: color ${transitionTime} linear;
    white-space: nowrap;

    &:disabled {
        cursor: not-allowed;
    }
`;

const wideStyles = css`
    width: 100%;
    text-align: center;
`;

const largeSize = css`
    font-size: 14px;
    line-height: 24px;
`;

const smallSize = css`
    font-size: 12px;
    line-height: 18px;
`;

const getSizeStyle = (props: TextButtonProps) => {
    switch (props.size) {
        case 'large':
        default:
            return largeSize;
        case 'small':
            return smallSize;
    }
};

const primaryStyles = css`
    color: ${baseColors.blue};

    &:hover:not(:disabled) {
        color: ${blue['400']};
    }

    &:active:not(:disabled) {
        color: ${blue['700']};
    }

    &:disabled {
        color: ${grey['700']};
    }
`;

const redStyles = css`
    color: ${baseColors.red};

    &:hover:not(:disabled) {
        color: ${red['500']};
    }

    &:active:not(:disabled) {
        color: ${red['700']};
    }

    &:disabled {
        color: ${red['200']};
    }
`;

const secondaryStyles = css`
    color: ${baseColors.white};

    &:hover:not(:disabled) {
        color: ${grey['100']};
    }

    &:active:not(:disabled) {
        color: ${grey['300']};
    }

    &:disabled {
        color: ${grey['700']};
    }
`;

const getTypeStyle = (props: TextButtonProps) => {
    switch (props.buttonType) {
        case 'primary':
        default:
            return primaryStyles;
        case 'red':
            return redStyles;
        case 'secondary':
            return secondaryStyles;
    }
};

export const StyledTextButton = styled.button<TextButtonProps>`
    ${buttonBase};
    ${props => getSizeStyle(props)};
    ${props => getTypeStyle(props)};
    ${({ wide }) => wide && wideStyles};
    font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
    ${({ isUppercase }) => isUppercase && 'text-transform: uppercase'};
    ${({ isUnderline }) => isUnderline && 'text-decoration: underline'};
`;
