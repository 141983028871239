import { Column } from 'components/grid/wrappers/FlexWrapper';
import { baseColors } from 'constants/styles';
import styled from 'styled-components';

export const Empty = styled(Column)`
    width: 100%;
    padding: 24px;
    background-color: ${baseColors.darkGrey};
    border-radius: 8px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
`;
