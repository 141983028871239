import { yupDefault } from 'constants/yup';
import * as Yup from 'yup';

export interface UsernameLoginFormValues {
    usernameOrEmail: string;
    password: string;
}

export const usernameLoginValues: UsernameLoginFormValues = {
    usernameOrEmail: '',
    password: ''
};

export const usernameLoginSchema = Yup.object().shape({
    usernameOrEmail: yupDefault,
    password: yupDefault
});
