import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: ${baseColors.black};
    ${flexCenter};
`;

export const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    ${media.laptop(`
        width: 350px;
    `)};
`;
