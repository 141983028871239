import axios from 'services/axios';
import womAxios from './wom-axios';

export const createWallet = () =>
    axios<BULLZ.WalletResponse>({
        url: '/wom/create-wom-wallet'
    });

export const createWalletPin = (data: WOM.PinContainedRequest) =>
    womAxios({
        url: '/wallet/pin/create',
        data
    });

export const existsWalletPin = () =>
    womAxios({
        url: '/wallet/pin/exists',
        data: {}
    });

export const verifyWalletPin = (data: WOM.PinContainedRequest) =>
    womAxios({
        url: '/wallet/pin/verify',
        data
    });

export const getWalletToken = (data: WOM.PinContainedRequest) =>
    womAxios<WOM.WalletJwtTokenResponse>({
        url: '/wallet/pin/issue-jwt',
        data
    });

export const sendForgotPinSmsCode = () =>
    womAxios({
        url: '/wallet/pin/reset/send-sms-otp',
        data: {}
    });

export const forgotWalletPin = (data: WOM.ResetWalletPinRequest) =>
    womAxios({
        url: '/wallet/pin/reset',
        data
    });

export const verifyPurchase = (data: WOM.VerifyPurchaseRequest) =>
    womAxios<WOM.VerifyPurchaseResponse>({
        url: '/wallet/verify-purchase',
        data
    });
