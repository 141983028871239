import { Row } from 'components/grid/wrappers/FlexWrapper';
import { media } from 'constants/styles/media';
import styled, { css } from 'styled-components';

interface Props {
    type: 'primary' | 'secondary';
    color: string;
}

export const Wrapper = styled(Row)<Props>`
    background-color: ${({ color }) => color};

    ${({ type }) =>
        type === 'secondary' &&
        css`
            border-radius: 80px;
            padding: 8px 20px;

            ${media.laptop(`
                padding: 8px 40px;
            `)}
        `}
`;
