import { BackArrowSmIcon } from 'assets/icons';
import walletImg from 'assets/img/create-wallet/wallet.png';
import { CustomImg } from 'components/common/CustomImg';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { ImageWrapper } from 'components/modals/CreateWalletPinModal/styles';
import { forgotPinInitialValues, validateForgotPinForm } from 'components/modals/ForgotPinModal/validation';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { forgotWalletPinModal } from 'stores/initialize.modal';
import { noop } from 'utils/common';
import { ConfirmStep } from './ConfirmStep';
import { secondsToResendCode, Step, subtitles, titles } from './constants';
import { CreateStep } from './CreateStep';
import { PhoneStep } from './PhoneStep';

const { modal, closeModal } = forgotWalletPinModal;

export const ForgotPinModal = () => {
    const [visible] = useStore(modal);
    const [currentStep, setCurrentStep] = useState<Step>(Step.Phone);

    // sms-code states
    const [codeSended, setCodeSended] = useState(false);
    const [codeSeconds, setCodeSeconds] = useState(0);

    const handleClose = () => {
        closeModal();
    };

    const handleBack = () => {
        if (currentStep === Step.Phone) {
            closeModal();
        }

        if (currentStep === Step.ConfirmPin) {
            setCurrentStep(Step.Pin);
        }

        if (currentStep === Step.Pin) {
            setCurrentStep(Step.Phone);
        }
    };

    const handleCodeSended = () => {
        setCodeSended(true);
        setCodeSeconds(secondsToResendCode);
    };

    const steps: Record<Step, ReactNode> = {
        phone: (
            <PhoneStep
                codeSeconds={codeSeconds}
                codeSended={codeSended}
                setCurrentStep={setCurrentStep}
                onCodeSended={handleCodeSended}
            />
        ),
        pin: <CreateStep setCurrentStep={setCurrentStep} />,
        confirmPin: <ConfirmStep />
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (codeSeconds > 0) {
                setCodeSeconds(codeSeconds - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [codeSeconds]);

    useEffect(() => {
        !visible && setCurrentStep(Step.Phone);
    }, [visible]);

    return (
        <ModalWrapper fullscreenMobile visible={visible} onClose={handleClose}>
            <Section justifyBetween marginBottom="24px">
                <IconButton color={grey[500]} onClick={handleBack}>
                    <BackArrowSmIcon />
                </IconButton>
            </Section>

            <Column justifyBetween noWrap height="100%" width="100%">
                {currentStep === Step.Phone ? (
                    <Column noWrap width="100%">
                        <Section justifyCenter marginBottom="16px">
                            <Text alignTextCenter isBold size="4">
                                {titles[Step.Phone]}
                            </Text>
                        </Section>

                        <Section justifyCenter marginBottom="16px">
                            <Text alignTextCenter size="-2">
                                {subtitles[Step.Phone]}
                            </Text>
                        </Section>
                    </Column>
                ) : (
                    <ImageWrapper>
                        <CustomImg src={walletImg} />
                    </ImageWrapper>
                )}

                <Column noWrap width="100%">
                    <Formik initialValues={forgotPinInitialValues} validate={validateForgotPinForm} onSubmit={noop}>
                        <Column width="100%">{steps[currentStep]}</Column>
                    </Formik>

                    {currentStep === Step.Phone && (
                        <Section justifyCenter marginTop="32px">
                            <Text alignTextCenter size="-2">
                                You will receive an SMS with a security code. If you do not receive an SMS, check the
                                phone number and try again.
                            </Text>
                        </Section>
                    )}
                </Column>
            </Column>
        </ModalWrapper>
    );
};
