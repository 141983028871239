import { InputField } from 'components/form/InputField';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import { teamStores } from 'stores/team';

const { $createTeamError } = teamStores;

export const Form = () => {
    const [, { value: teamNameValue }] = useField<string>('teamName');
    const createTeamError = useStore($createTeamError);

    const titleChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '3 to 40 characters',
                isSuccess: teamNameValue.length >= 3 && teamNameValue.length <= 40
            }
        ],
        [teamNameValue]
    );
    return (
        <Section>
            <Section marginBottom="24px">
                <InputField
                    error={createTeamError || undefined}
                    label="Team Name"
                    name="teamName"
                    placeholder="Add team name here"
                />
            </Section>

            <Section marginBottom="24px">
                <RulesChecklist items={titleChecklist} />
            </Section>
        </Section>
    );
};
