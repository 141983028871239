import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { videoEffects, videoStores } from 'stores/video';
import { Earnings } from '../Earnings';
import { Scores } from '../Scores';
import { Stats } from '../Stats';

interface Props {
    data: BULLZ.GetVideoDetailsResponse | null;
}

export const WithdrawContent: FC<Props> = ({ data }) => {
    const isLoading = useStore(videoEffects.getVideoEarningsFx.pending);
    const earningsData = useStore(videoStores.$earningsData);

    useEffect(() => {
        if (data?.id) {
            videoEffects.getVideoEarningsFx(data.id);
        }
    }, [data]);

    if (!data) return null;

    return (
        <Column noWrap width="100%">
            {isLoading && (
                <Section justifyCenter marginBottom="24px">
                    <Spinner />
                </Section>
            )}

            {earningsData && (
                <>
                    <Section marginBottom="24px">
                        <Earnings wom={earningsData.value} />
                    </Section>

                    <Column noWrap marginBottom="24px" width="100%">
                        <Section marginBottom="8px">
                            <Text isBold size="-1">
                                WOM Authentication
                            </Text>
                        </Section>

                        <Scores
                            authenticity={earningsData.rating?.authenticity}
                            creativity={earningsData.rating?.creativity}
                            positivity={earningsData.rating?.positivity}
                        />
                    </Column>
                </>
            )}

            <Section marginBottom="24px">
                <Stats data={data.engagementStatistics} />
            </Section>
        </Column>
    );
};
