import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { NotificationsTabs } from 'components/pages/notifications/NotificationsTabs';
import { Wrapper } from 'pages/NotificationsPage/styles';
import React from 'react';

export const NotificationsPage = () => (
    <NewMainLayout>
        <Wrapper>
            <NotificationsTabs />
        </Wrapper>
    </NewMainLayout>
);
