import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC, ReactNode } from 'react';

interface Props {
    title: string;
    icon: ReactNode;
}

export const Label: FC<Props> = ({ title, icon }) => (
    <Section alignCenter>
        <Column marginRight="8px">{icon}</Column>
        <Text size="-2">{title}</Text>
    </Section>
);
