import { Section } from 'components/grid/wrappers/FlexWrapper';
import { TabProps } from 'components/ui/Tabs/types';
import React, { Key } from 'react';
import { noop } from 'utils/common';
import { TabsButton, TabsButtons, TabsPanelWrapper, TabsWrapper } from './styles';

interface Props<T extends Key> {
    tabs: Array<TabProps<T>>;
    onChange?: (value: T) => void;
    activeTab: T;
}

export const Tabs = <T extends Key>({ tabs, onChange = noop, activeTab }: Props<T>) => (
    <>
        <TabsWrapper>
            <TabsButtons>
                {tabs
                    .filter(item => !item.hidden)
                    .map((tab: TabProps<T>) => (
                        <TabsButton
                            key={tab.id}
                            isActive={activeTab === tab.id}
                            width={tab.width}
                            onClick={() => onChange(tab.id)}
                        >
                            {tab.title}
                        </TabsButton>
                    ))}
            </TabsButtons>
        </TabsWrapper>

        <Section height="100%" marginTop="10px">
            {tabs
                .filter(item => !item.hidden)
                .map((tab: TabProps<T>) => (
                    <TabsPanelWrapper key={tab.id} isActive={activeTab === tab.id} role="tabpanel">
                        {tab.content}
                    </TabsPanelWrapper>
                ))}
        </Section>
    </>
);
