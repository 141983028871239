import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { activityStatuses, StatusesUi } from 'components/pages/wallet/constants';
import { MontserratFontFamily, transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

interface Props {
    isTransform?: boolean;
}

export const IconWrapper = styled.div<Props>`
    ${flexCenter};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${baseColors.darkGrey};
    flex-shrink: 0;
    ${({ isTransform }) => isTransform && 'transform: rotate(180deg)'};
`;

export const Separator = styled.div`
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: ${baseColors.lightGrey};
`;

export const CardWrapper = styled(Section)`
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    cursor: pointer;
    border-radius: 8px;
    padding: 12px;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }
`;

interface StatusProps {
    status?: string;
}

export const getStatusStyle = (status: string) => {
    switch (status) {
        case activityStatuses[StatusesUi.Completed]:
            return css`
                color: ${baseColors.blue};
            `;
        case activityStatuses[StatusesUi.Failed]:
            return css`
                color: ${baseColors.white};
            `;
        case activityStatuses[StatusesUi.Pending]:
        default:
            return css`
                color: ${baseColors.lightGrey};
            `;
    }
};

export const Status = styled.div<StatusProps>`
    font-family: ${MontserratFontFamily};
    font-size: 12px;
    line-height: 18px;
    ${({ status }) => (status ? getStatusStyle(status) : baseColors.lightGrey)};
`;
