import { transitionTime } from 'constants/styles';
import styled from 'styled-components';

export const Label = styled.label`
    width: 80px;
    height: 80px;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    transition: opacity ${transitionTime} linear;

    &:hover {
        opacity: 0.7;
    }
`;

export const HiddenInput = styled.input`
    opacity: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    z-index: 2;
    cursor: pointer;
`;

interface AvatarProps {
    src: string;
}

export const Avatar = styled.div<AvatarProps>`
    width: 100%;
    height: 100%;
    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100px;
`;

export const IconWrapper = styled.div`
    width: 36px;
    height: 36px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    z-index: 1;
`;
