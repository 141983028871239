import styled from 'styled-components';

export const AvatarWrapper = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
`;

export const NameWrapper = styled.div`
    height: 32px;
    max-width: 135px;
    padding-left: 8px;
    flex-grow: 1;
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    max-width: 190px;
    height: 24px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 24px;
`;

export const TagsWrapper = styled.div`
    width: 100%;
    max-width: 235px;
    height: 24px;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 16px;
`;

export const WebsiteWrapper = styled.div`
    width: 100%;
    max-width: 170px;
    height: 32px;
    margin-top: 16px;
`;
