import { Row } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { FC, useEffect, useRef, useState } from 'react';
import { handleStyles, railStyles, RangeWrapper, TimelineWrapper, trackStyles } from './styles';
import { getPlayerTime } from './utils';

interface Props {
    totalDuration: number;
    currentTime: number;
    onChange?: (value: number) => void;
}

export const Timeline: FC<Props> = ({ totalDuration, currentTime, onChange }) => {
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const [value, setValue] = useState(0);
    const [isChanging, setIsChanging] = useState(false);

    useEffect(() => {
        if (!isChanging) {
            setValue(currentTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime]);

    const handleValueChange = (value: number | number[]) => {
        if (typeof value === 'number' && onChange) {
            setValue(value);
            setIsChanging(true);

            if (timer.current) {
                clearTimeout(timer.current);
            }

            timer.current = setTimeout(() => {
                onChange(value);
                setIsChanging(false);
            }, 100);
        }
    };

    return (
        <TimelineWrapper>
            <RangeWrapper>
                <RcSlider
                    handleStyle={handleStyles}
                    max={totalDuration}
                    min={0}
                    railStyle={railStyles}
                    step={0.1}
                    trackStyle={trackStyles}
                    value={value}
                    onChange={handleValueChange}
                />
            </RangeWrapper>

            <Row flexShrink={0} marginLeft="12px">
                <Text size="-2">
                    {getPlayerTime(value)}/{getPlayerTime(totalDuration)}
                </Text>
            </Row>
        </TimelineWrapper>
    );
};
