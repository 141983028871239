import { forward } from 'effector';
import { authEffects, authEvents } from 'stores/auth';
import { loaderEffects } from 'stores/loader';
import { localStorageConnects, registerEffects, registerStores } from 'stores/register';

const {
    sendCodeFx,
    createAccountFx,
    createAccountFailFx,
    createAccountDoneFx,
    updateAccountDoneFx,
    updateAccountFx,
    updateAccountFailFx,
    sendCodeFailFx
} = registerEffects;
const { authFx } = authEffects;
const { setUser } = authEvents;
const { additionalRegisterLs } = localStorageConnects;
const { $additionalRegisterIsCompleted } = registerStores;

$additionalRegisterIsCompleted.watch(additionalRegisterLs);

forward({
    from: [sendCodeFx.pending, createAccountFx.pending, updateAccountFx.pending],
    to: loaderEffects.loaderFx
});

// Create account

forward({
    from: sendCodeFx.failData,
    to: sendCodeFailFx
});

forward({
    from: createAccountFx.doneData,
    to: authFx
});

forward({
    from: createAccountFx.done,
    to: createAccountDoneFx
});

forward({
    from: createAccountFx.fail,
    to: createAccountFailFx
});

// Update account

forward({
    from: updateAccountFx.doneData,
    to: setUser
});

forward({
    from: updateAccountFx.done,
    to: updateAccountDoneFx
});

forward({
    from: updateAccountFx.fail,
    to: updateAccountFailFx
});
