import React, { FC } from 'react';
import { Disabled, HTMLButtonType } from 'types';
import { ReactClick } from 'types/react';
import { StyledTextButton } from './styles';

export interface TextButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    buttonType?: 'primary' | 'red' | 'secondary';
    size?: 'large' | 'small';
    wide?: boolean;
    isBold?: boolean;
    isUppercase?: boolean;
    isUnderline?: boolean;
}

export const TextButton: FC<TextButtonProps> = ({
    buttonType = 'secondary',
    size = 'small',
    type = 'button',
    children,
    ...props
}) => (
    <StyledTextButton buttonType={buttonType} size={size} type={type} {...props}>
        {children}
    </StyledTextButton>
);
