export enum NotificationType {
    WomCryptoWithdrawal = 'wom.crypto.withdrawal',
    WomCryptoDeposit = 'wom.crypto.deposit',
    WomCryptoTransactionStatus = 'wom.crypto.transaction.status',

    YeayTrendingUser = 'yeay.trending.user',
    YeayTeamJoined = 'yeay.team.joined',
    YeayPostCreated = 'yeay.post.created',
    YeayCommentCreated = 'yeay.comment.created',
    YeayTrendingVideo = 'yeay.trending.video',
    YeayVideoCuration = 'yeay.video.curation',
    YeayUserIsFollowed = 'yeay.user.is-followed',
    YeayVideoLiked = 'yeay.video.liked',
    YeayDepositSuccess = 'yeay.deposit.success',
    YeayDepositFailed = 'yeay.deposit.failed',
    YeayWithdrawalSuccess = 'yeay.withdrawal.success',
    YeayWithdrawalFailed = 'yeay.withdrawal.failed',

    BcmCollaborationShareVerified = 'bcm.collaboration.share.verified',
    BcmCollaborationStart = 'bcm.collaboration.start',
    BcmCollaborationPaymentShare = 'bcm.collaboration.payment.share',
    BcmCollaborationStopped = 'bcm.collaboration.stopped',
    BcmCollaborationEnrollment = 'bcm.collaboration.enrollment',
    BcmCollaborationRemind = 'bcm.collaboration.remind',
    BcmCollaborationPaymentSubmit = 'bcm.collaboration.payment.submit',
    BcmParticipantKickedOut = 'bcm.participant.kicked.out',
    BcmParticipantLeft = 'bcm.participant.left',
    BcmCollaborationLinkSocials = 'bcm.collaboration.link.socials',
    BcmCollaborationContentRejected = 'bcm.collaboration.content.rejected',

    QuickSignalChannelRenewSubscription = 'quickSignal.channel.renewSubscription',
    QuickSignalChargeReminder = 'quickSignal.charge.reminder',
    QuickSignalBalanceReminder = 'quickSignal.balance.reminder',
    QuickSignalPostFiveDaysReminder = 'quickSignal.post.fiveDays.reminder',
    QuickSignalNewSubsriber = 'quickSignal.new.subsriber',
    QuickSignalNewVideo = 'quickSignal.new.video',
    QuickSignalPostTenDaysReminder = 'quickSignal.post.tenDays.reminder',
    QuickSignalPostTwentyDaysReminder = 'quickSignal.post.twentyDays.reminder',

    ReferralReward = 'referral.reward'
}
