export enum StatusesUi {
    Failed = -1,
    None = 0,
    Pending = 1,
    Completed = 2
}

export const activityStatuses = {
    [StatusesUi.None]: 'None',
    [StatusesUi.Completed]: 'Completed',
    [StatusesUi.Failed]: 'Failed',
    [StatusesUi.Pending]: 'Pending'
};

export const transactionType: Record<WOM.WOMNarrativeType, string> = {
    0: 'None',
    200: 'Withdrawal',
    300: 'Creator Stake',
    400: 'Validation Stake',
    600: 'User Transfer',
    800: 'Migration Sync',
    2000: 'Gift Card Payment',
    10000: 'Platform fee',

    100: 'Deposit',
    301: 'Creator Reward',
    302: 'Creator Stake Refund',
    303: 'Creator Bonus',
    700: 'Reward',
    1000: 'Performance Payment ',
    2100: 'Gift Card Refund',
    4001: 'Escrow Release',

    401: 'Validation Reward',
    402: 'Validation Stake Refund',
    410: 'Validation Profit',
    500: 'Exchange',
    900: 'Campaign Payment',
    1001: 'Performance Payment Creator',
    1002: 'Performance Payment Authenticator',
    1003: 'Performance Payment Facilitator',
    1004: 'Performance Payment Publisher',
    1005: 'Performance Payment DisplayNetwork',
    1006: 'Performance Payment Advertiser',
    1007: 'Performance Payment Brand',
    3000: 'Organization Purchase',
    4000: 'Escrow In',
    5000: 'Agent Payout'
};

export const inComeTransaction = [100, 301, 302, 303, 700, 1000, 2100, 4001];
