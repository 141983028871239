import { CheckSmIcon } from 'assets/icons';
import { InputField } from 'components/form/InputField';
import { CountryOption } from 'components/form/options/CountryOption';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Link } from 'components/ui/Link';
import { Select } from 'components/ui/Select';
import { SelectOptionValue } from 'components/ui/Select/types';
import { Text } from 'components/ui/typography/Text';
import { bullzSitePolicyUrl, bullzSiteTermsUrl } from 'constants/routes';
import { baseColors, grey } from 'constants/styles/colors';
import { useField } from 'formik';
import { useReCaptcha } from 'hooks/useReCaptcha';
import React, { Dispatch, FC, FormEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { registerEffects } from 'stores/register';
import { prepareCountryOptions } from 'utils/forms';
import { RegisterFieldName, secondsToResendCode, Step } from '../constants';
import { CenterSection } from './styles';

const { sendCodeFx } = registerEffects;

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
}

export const PhoneStep: FC<Props> = ({ setCurrentStep }) => {
    const [codeSended, setCodeSended] = useState(false);
    const [codeSeconds, setCodeSeconds] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState<SelectOptionValue | null>(null);
    const countries = useMemo(() => prepareCountryOptions(), []);

    const [, { value: mobileNumber, error: mobileNumberError, touched: mobileNumberTouched }] = useField<string>(
        RegisterFieldName.MobileNumber
    );
    const [, { error: smsCodeError, touched: smsCodeTouched }] = useField<string>(
        RegisterFieldName.SmsVerificationCode
    );
    const [, { value: countryCode }, { setValue: setCountryCode }] = useField<string>(RegisterFieldName.CountryCode);

    const token = useReCaptcha(); // TODO handle error

    const submitIsActive = mobileNumberTouched && smsCodeTouched && !mobileNumberError && !smsCodeError && !!token;

    const phonePlaceholder = isMobile ? 'Phone Number' : 'Please enter your phone number';
    const codePlaceholder = isMobile ? 'Enter Code' : 'Please enter your 6-digit code';
    const codeResendText = isMobile ? '' : 'Resend Code: ';

    const sendCode = () => {
        sendCodeFx({
            phone: `${countryCode}${mobileNumber}`,
            firebaseToken: token
        }).then(() => {
            setCodeSended(true);
            setCodeSeconds(secondsToResendCode);
        });
    };

    const handleSelectCountry = (value: SelectOptionValue) => {
        setSelectedCountry(value);

        const foundCountry = countries.find(country => country.value === value);
        if (foundCountry) {
            setCountryCode(`+${foundCountry.code}`);
        }
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        setCurrentStep(Step.Username);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (codeSeconds > 0) {
                setCodeSeconds(codeSeconds - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [codeSeconds]);

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="24px">
                <InputField
                    addonBefore={
                        <Select
                            hideBorder
                            hideOnChange
                            items={countries}
                            optionsWidth={isMobile ? '200px' : '260px'}
                            placeholder="Add code"
                            renderOption={CountryOption}
                            value={selectedCountry || ''}
                            onChange={handleSelectCountry}
                        />
                    }
                    label="Phone number"
                    name={RegisterFieldName.MobileNumber}
                    placeholder={phonePlaceholder}
                    suffix={
                        mobileNumberTouched && !mobileNumberError ? <CheckSmIcon color={baseColors.blue} /> : undefined
                    }
                    type="tel"
                />
            </Section>
            <Section marginBottom="32px">
                <InputField
                    addonAfter={
                        <TextButton
                            disabled={!mobileNumberTouched || !!mobileNumberError || codeSeconds > 0 || !token}
                            onClick={sendCode}
                        >
                            {codeSeconds > 0 ? `${codeResendText}${codeSeconds}s` : 'Send Code'}
                        </TextButton>
                    }
                    disabled={!codeSended}
                    name={RegisterFieldName.SmsVerificationCode}
                    placeholder={codePlaceholder}
                    type="tel"
                />
            </Section>

            <Section marginBottom="16px">
                <Button wide disabled={!submitIsActive} type="submit">
                    Next
                </Button>
            </Section>

            <CenterSection>
                <Text color={grey[600]} size="-2">
                    By continuing, you agree to the BULLZ{' '}
                    <Link isExternal href={bullzSiteTermsUrl} type="blue">
                        Terms and Conditions
                    </Link>{' '}
                    and confirm that you have read the BULLZ{' '}
                    <Link isExternal href={bullzSitePolicyUrl} type="blue">
                        Privacy Policy
                    </Link>
                    . SMS fees may apply.
                </Text>
            </CenterSection>
        </FormWrapper>
    );
};
