import { TextList } from 'components/ui/typography/TextList';
import React, { ReactNode } from 'react';

// BULLZ validation statuses

export enum BullzCurationState {
    None,
    Processing,
    Accepted,
    Rejected
}

export enum BullzCurationEndedReason {
    None,
    DeclineRequested,
    Inappropriate,
    GraphicContent,
    Violence,
    Copyright,
    TestVideo,
    IncorrectFormat,
    UserRequested,
    Other,
    NotProductRecommendation,
    WrongLanguage,
    QualityGuidelines
}

// WOM validation statuses

export enum WomProcessStatus {
    Rejected = -1,
    NotProcessed = 0,
    Accepted = 1,
    Processing = 2
}

export enum WomEndedReason {
    None,
    CompletedNormally,
    ConsensusNotReached,
    TerminatedInHold,
    Deleted
}

export enum WomCurrentStage {
    NotStarted,
    Processing,
    Ended,
    Held
}

export enum WomValidationResult {
    RejectedByConsensus = -1,
    NotProcessed = 0,
    AcceptedByConsensus = 1
}

// Statuses for our UI

export enum ValidationStatusUI {
    None = 'none',
    Processing = 'processing',
    Rejected = 'rejected',
    Submit = 'submit',
    WomValidationProcessing = 'womValidationProcessing',
    ReSubmit = 'reSubmit',
    Fail = 'fail',
    Live = 'live',
    CollaborationLive = 'collaborationLive'
}

export const validationStatusTitles: Partial<Record<ValidationStatusUI, string>> = {
    processing: 'Processing',
    submit: 'Submit',
    reSubmit: 'Re-Submit',
    fail: 'Re-Submit',
    rejected: 'Rejected',
    live: 'Withdraw',
    collaborationLive: 'Withdraw'
};

export const validationStatusModalTitles: Partial<Record<ValidationStatusUI, string>> = {
    processing: 'Video Processing',
    submit: 'Submit Video',
    womValidationProcessing: 'Awaiting Approval',
    reSubmit: 'Re-submit video',
    fail: 'WOM Authentication'
};

export const validationStatusModalTexts: Partial<Record<ValidationStatusUI, string>> = {
    processing: 'Hang tight while we format your video. It should appear shortly',
    submit: 'You have not submitted your video to WOM yet',
    womValidationProcessing: 'The timer indicates the remaining time before your video completes authentication.',
    reSubmit:
        'Your video was not rated in the required time frame. Your WOM stake has been refunded. We encourage you to re-submit yor video to be eligible for WOM rewards!',
    fail:
        'Unfortunately your video did not pass WOM Authentication and is not eligible to earn rewards. You can resubmit your video for WOM Authentication again if you feel the rating was made in error.'
};

export const validationStatusModalButton: Partial<Record<ValidationStatusUI, string>> = {
    processing: 'OK',
    submit: 'OK',
    womValidationProcessing: 'OK',
    reSubmit: 'OK',
    fail: 'OK, got it!'
};

export const validationStatusTooltip: Partial<Record<ValidationStatusUI, string>> = {
    submit: 'You have not submitted your video to WOM yet',
    womValidationProcessing: 'Your video is currently being rated',
    reSubmit:
        'Your video was not rated in the required time frame. Re-submit your video to be eligible for WOM rewards.',
    fail:
        'This icon is shown when a video failed curation, a video reached negative consensus, a video did not reach consensus due to the lack of authenticators, a video did not reach consensus for other reasons',
    rejected: 'Your video was rejected',
    live: 'Your video passed authentication! You can now earn WOM based on engagement with your video',
    collaborationLive: 'Your video passed authentication! You can now earn WOM based on engagement with your video'
};

export const videoRejectReasons: Record<BullzCurationEndedReason, ReactNode> = {
    [BullzCurationEndedReason.None]: '',
    [BullzCurationEndedReason.DeclineRequested]: (
        <TextList
            center
            items={[
                'Your video was rejected.',
                'Don’t worry! Just review the Quality Guidelines and try again.',
                'BULLZ is not a regular social app. On BULLZ you can recommend your favorite crypto or blockchain tech you use everyday and EARN rewards that you can redeem for gift cards to use at your favorite stores!',
                'So go ahead and create a video recommending whatever crypto topic you love and start earning!'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.Inappropriate]: (
        <TextList
            center
            items={[
                'Your video was rejected because it contains inappropriate content.',
                'Certain types of content are not accepted on BULLZ. Please review the Quality Guidelines to learn what types of content are acceptable on BULLZ.'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.GraphicContent]: (
        <TextList
            center
            items={[
                'Your video was rejected because it contains graphic content.',
                'Certain types of content are not accepted on BULLZ. Please review the Quality Guidelines to learn what types of content are acceptable on BULLZ.'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.Violence]: (
        <TextList
            center
            items={[
                'Your video was rejected because it contains violence.',
                'Certain types of content are not accepted on BULLZ. Please review the Quality Guidelines to learn what types of content are acceptable on BULLZ.'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.Copyright]: (
        <TextList
            center
            items={[
                'Your video was rejected for copyright violation.',
                'Your video contains copyrighted content. Be sure that the videos you submit are 100% unique content created by you.'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.TestVideo]: (
        <TextList
            center
            items={['Your video was rejected because it is a test video, not intended for public viewing.']}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.IncorrectFormat]: (
        <TextList
            center
            items={[
                'Your video was rejected because it is in an incorrect format.',
                'Your video may have been too short, missing audio, or had some other issue preventing it from playing correctly.',
                'Don’t worry! Just review the Quality Guidelines and try again!'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.UserRequested]: (
        <TextList center items={['Your video was rejected per your request.']} size="-2" />
    ),
    [BullzCurationEndedReason.Other]: (
        <TextList
            center
            items={[
                'Don’t worry! Just review the Quality Guidelines and try again.',
                'BULLZ is not a regular social app. On BULLZ you can recommend your favorite crypto or blockchain tech you use everyday and EARN rewards that you can redeem for gift cards to use at your favorite stores!',
                'So go ahead and create a video recommending whatever crypto topic you love and start earning!'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.NotProductRecommendation]: (
        <TextList
            center
            items={[
                'Your video was rejected because it is not a recommendation.',
                'Don’t worry! Just try again and this time post a video recommending your favorite crypto topic.',
                'BULLZ is not a regular social app. On BULLZ you can recommend your favorite crypto or blockchain tech you use everyday and EARN rewards that you can redeem for gift cards to use at your favorite stores!',
                'So go ahead and create a video recommending whatever crypto topic you love and start earning!'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.WrongLanguage]: (
        <TextList
            center
            items={[
                'Your video was rejected because the spoken language is different than the language you selected.',
                'Don’t worry! Just try again and this time post a video recommending your favorite crypto topic.'
            ]}
            size="-2"
        />
    ),
    [BullzCurationEndedReason.QualityGuidelines]: (
        <TextList
            center
            items={[
                'Your video was rejected because it does not meet the Quality Guidelines.',
                'Don’t worry! Just review the Quality Guidelines and try again.',
                'BULLZ is not a regular social app. On BULLZ you can recommend your favorite crypto or blockchain tech you use everyday and EARN rewards that you can redeem for gift cards to use at your favorite stores!',
                'So go ahead and create a video recommending whatever crypto topic you love and start earning!'
            ]}
            size="-2"
        />
    )
};
