import { BackArrowSmIcon, RefreshSmIcon } from 'assets/icons';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { GiftDetailsModal } from 'components/modals/GiftDetailsModal';
import { GiftHowToUseModal } from 'components/modals/GiftHowToUseModal';
import { GiftPurchaseDoneModal } from 'components/modals/GiftPurchaseDoneModal';
import { GiftPurchaseModal } from 'components/modals/GiftPurchaseModal';
import { GiftsTabs } from 'components/pages/gifts/GiftsTabs';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { baseColors, grey } from 'constants/styles/colors';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { giftsEvents } from 'stores/gifts';

const { resetBrandsStores, resetPurchasesStores } = giftsEvents;

export const Gifts = () => {
    const history = useHistory();

    const goToBack = () => {
        history.replace(AppRoute.Wallet);
    };

    const refresh = () => {
        resetBrandsStores();
        resetPurchasesStores();
    };

    return (
        <NewMainLayout>
            <Section justifyBetween noWrap marginBottom="24px">
                <Row noWrap justifyBetween={isMobile} width={isMobile ? '100%' : undefined}>
                    <IconButton color={isMobile ? baseColors.white : grey[500]} onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>

                    <Column alignCenter={isMobile} marginLeft="12px">
                        <Text isBold alignTextCenter={isMobile} size="1">
                            Gift Cards
                        </Text>

                        <Row justifyCenter={isMobile} marginTop="8px">
                            <Text alignTextCenter={isMobile} size="-2">
                                Exchange your WOM Tokens for your favorite gift cards
                            </Text>
                        </Row>
                    </Column>

                    {isMobile && (
                        <IconButton onClick={refresh}>
                            <RefreshSmIcon height={16} width={16} />
                        </IconButton>
                    )}
                </Row>

                {!isMobile && (
                    <Button isBold={false} size="small" onClick={refresh}>
                        Refresh
                    </Button>
                )}
            </Section>

            <GiftsTabs />

            <GiftDetailsModal />
            <GiftHowToUseModal />
            <GiftPurchaseModal />
            <GiftPurchaseDoneModal />
        </NewMainLayout>
    );
};
