import { LoadingSmIcon } from 'assets/icons';
import React, { FC } from 'react';
import { SpinnerWrapper } from './styles';

interface Props {
    size?: number;
}

export const Spinner: FC<Props> = ({ size = 24 }) => (
    <SpinnerWrapper size={size}>
        <LoadingSmIcon height={size} width={size} />
    </SpinnerWrapper>
);
