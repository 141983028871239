import React, { FC } from 'react';
import { Disabled, HTMLButtonType } from 'types/interfaces/form';
import { ReactClick } from 'types/react';
import { StyledButton } from './styles';

interface Props extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    size?: string;
    color?: string;
    hoverColor?: string;
    rotate?: number;
}

export const IconButton: FC<Props> = ({ children, type = 'button', ...props }) => (
    <StyledButton type={type} {...props}>
        {children}
    </StyledButton>
);
