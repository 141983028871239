import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { videoWidth } from 'components/ui/inputs/VideoInput/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

const videoMarginRight = '34px';

export const VideoWrapper = styled(Row)`
    margin-bottom: 24px;
    width: 100%;
    justify-content: center;

    ${media.laptop(`
        margin-right: ${videoMarginRight};
        margin-bottom: 0;
        width: auto;
    `)};
`;

export const StyledSection = styled(Section)`
    ${media.laptop(`
        width: calc(100% - ${videoMarginRight} - ${videoWidth});
    `)};
`;

export const TextWrapper = styled.div`
    white-space: nowrap;
`;
