import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

const contentInfoHeight = '350px';

export const Empty = styled.div`
    width: 100%;
    height: calc(100vh - 2 * ${headerHeight} - ${contentInfoHeight});
    min-height: 280px;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${media.laptop(`
        background-color: ${baseColors.darkGrey};
        height: 100%;
    `)};
`;

export const Image = styled.img`
    width: 200px;
    height: 200px;
    margin-bottom: 18px;
`;
