import { FieldTitle } from 'components/form/FieldTitle';
import { InputField } from 'components/form/InputField';
import { SelectField } from 'components/form/SelectField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { HashtagField } from 'components/ui/HashtagField';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { categoriesEffects, categoriesStores } from 'stores/categories';
import { uploadVideoModal } from 'stores/initialize.modal';
import { uploadVideoEvents, UploadVideoSteps } from 'stores/upload-video';
import { prepareCategories } from 'utils/prepareCategories';
import { prepareLanguages } from 'utils/prepareLanguages';
import { FillDataFormValues } from '../constants';

const { closeModal } = uploadVideoModal;

interface Props {
    videoFile: File | null;
}

export const FillDataForm: FC<Props> = ({ videoFile }) => {
    const categories = useStore(categoriesStores.$categories);
    const preparedCategories = prepareCategories(categories);
    const [tags, setTags] = useState('');

    const { values, isValid, dirty, setFieldValue } = useFormikContext<FillDataFormValues>();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!isValid || !dirty || !values.videoFile) return;
        uploadVideoEvents.setStep(UploadVideoSteps.Submit);
    };

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    useEffect(() => {
        const { category, project, topic } = values;
        const categoryName = preparedCategories.find(currentCategory => currentCategory.value === category);
        const categoryTag = categoryName ? categoryName.label : '';
        const projectTag = project ? `#${project}` : '';
        const topicTag = topic ? `#${topic}` : '';

        setTags(`${categoryTag} ${projectTag} ${topicTag}`);
    }, [values, preparedCategories]);

    useEffect(() => {
        setFieldValue('videoFile', videoFile, true);
    }, [videoFile, setFieldValue]);

    return (
        <FormWrapper onSubmit={handleSubmit}>
            <Section marginBottom="16px">
                <FieldTitle title="Category" />
                <SelectField hideOnChange items={preparedCategories} name="category" placeholder="Please select one" />
            </Section>
            <Section marginBottom="16px">
                <FieldTitle currentLetters={values.project.length} title="Project" />
                <InputField wide name="project" placeholder="e.g. ConsenSys" />
            </Section>
            <Section marginBottom="16px">
                <FieldTitle currentLetters={values.topic.length} title="Topic" />
                <InputField wide name="topic" placeholder="e.g. Metamask" />
            </Section>
            <Section marginBottom="16px">
                <FieldTitle title="Website URL" />
                <InputField wide name="website" placeholder="e.g. https://metamask.io" />
            </Section>
            <Section marginBottom="16px">
                <FieldTitle title="Your Tags" />
                <HashtagField tags={tags} />
            </Section>
            <Section marginBottom="32px">
                <FieldTitle title="Spoken Language" />
                <SelectField hideOnChange items={prepareLanguages()} name="language" placeholder="Please select one" />
            </Section>
            <Section alignCenter justifyBetween>
                <TextButton onClick={() => closeModal()}>Cancel</TextButton>
                <Button disabled={!isValid || !dirty} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
