import { MainLoader } from 'components/loaders/MainLoader';
import { AdditionalRegisterModal } from 'components/modals/AdditionalRegisterModal';
import { CreateOrEditTeamModal } from 'components/modals/CreateOrEditTeamModal';
import { CreateWalletPinModal } from 'components/modals/CreateWalletPinModal';
import { ForgotPasswordModal } from 'components/modals/ForgotPasswordModal';
import { ForgotPinModal } from 'components/modals/ForgotPinModal';
import { InfoModal } from 'components/modals/InfoModal';
import { LoginModal } from 'components/modals/LoginModal';
import { MyQsModal } from 'components/modals/MyQsModal';
import { QexWidgetModal } from 'components/modals/QexWidgetModal';
import { QsGuideModal } from 'components/modals/QsGuideModal';
import { QSModal } from 'components/modals/QSModal';
import { QsSubscribeModal } from 'components/modals/QsSubscribeModal';
import { RegisterModal } from 'components/modals/RegisterModal';
import { ReportCommentModal } from 'components/modals/ReportCommentModal';
import { ReportVideoModal } from 'components/modals/ReportVideoModal';
import { UploadOptionModal } from 'components/modals/UploadOptionModal';
import { UploadQsModal } from 'components/modals/UploadQsModal';
import { UploadVideoModal } from 'components/modals/UploadVideoModal';
import { VerifyWalletPinModal } from 'components/modals/VerifyWalletPinModal';
import { VideoStatusModal } from 'components/modals/VideoStatusModal';
import { WalletGuideModal } from 'components/modals/WalletGuideModal';
import { WalletInfoModal } from 'components/modals/WalletInfoModal';
import { Toasts } from 'components/ui/Toasts';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { authStores } from 'stores/auth';

const AuthModals: FC = ({ children }) => {
    const isAuth = useStore(authStores.$isAuth);

    if (!isAuth) {
        return null;
    }

    return <>{children}</>;
};

export const AppModals = () => (
    <>
        <Toasts />
        <MainLoader />
        <InfoModal />

        <LoginModal />
        <RegisterModal />
        <ForgotPasswordModal />

        <AuthModals>
            <AdditionalRegisterModal />
            <WalletInfoModal />
            <QexWidgetModal />
            <ReportVideoModal />
            <ReportCommentModal />
            <WalletGuideModal />
            <CreateWalletPinModal />
            <CreateOrEditTeamModal />
            <VerifyWalletPinModal />
            <ForgotPinModal />
            <QSModal />
            <QsSubscribeModal />
            <QsGuideModal />
            <MyQsModal />
            <UploadOptionModal />
            <UploadVideoModal />
            <UploadQsModal />
            <VideoStatusModal />
        </AuthModals>
    </>
);
