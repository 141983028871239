import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Network, networkAssociation, networks, ShareStep } from 'components/modals/ShareLinkModal/constants';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { authStores } from 'stores/auth';
import { socialNetworksEffects, socialNetworksStores } from 'stores/social-networks';
import { ButtonGrid } from './styles';

interface Props {
    collaborationNetworks: BUCM.TiltedPublishingNetworkRequirementWithUsdValue[];
    setCurrentStep: Dispatch<SetStateAction<ShareStep>>;
    setSelectedNetwork: Dispatch<SetStateAction<Network | null>>;
}

export const FirstStep: FC<Props> = ({ setCurrentStep, setSelectedNetwork, collaborationNetworks }) => {
    const userNetworks = useStore(socialNetworksStores.$userNetworks);
    const user = useStore(authStores.$user);
    const userId = user?.userId;

    const handleClick = (network: Network) => () => {
        setSelectedNetwork(network);
        setCurrentStep(ShareStep.Second);
    };

    useEffect(() => {
        userId && socialNetworksEffects.getUserNetworksFx(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredNetworks = networks.filter(
        network =>
            collaborationNetworks.find(
                collaborationNetwork =>
                    collaborationNetwork.networkTitle?.toLowerCase() === networkAssociation[network.title]
            ) &&
            userNetworks.find(
                userNetwork => userNetwork.networkName?.toLowerCase() === networkAssociation[network.title]
            )
    );

    if (filteredNetworks.length === 0) {
        return (
            <Section justifyCenter marginBottom="28px">
                <Text isBold>You don't have linked social networks</Text>
            </Section>
        );
    }

    return (
        <>
            <Section justifyCenter marginBottom="28px">
                <Text isBold>Select a platform</Text>
            </Section>
            <ButtonGrid>
                {filteredNetworks.map(({ icon: Icon, title, id }) => (
                    <Button key={id} icon={<Icon />} iconPosition="left" onClick={handleClick(title)}>
                        {title}
                    </Button>
                ))}
            </ButtonGrid>
        </>
    );
};
