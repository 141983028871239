import { PlusSmIcon, WomSmIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Badge } from 'components/ui/Badge';
import { NetworkIcon } from 'components/ui/NetworkIcon';
import { Text } from 'components/ui/typography/Text';
import { collaborationStatuses } from 'constants/defaults/collaborations';
import { baseColors } from 'constants/styles';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { getCommonReward } from 'utils/common';
import { BadgeWrapper, CollaborationCardWrapper, CollaborationInformation, InfoBlock, StyledTitle } from './styles';

interface Props {
    data: BUCM.ActiveCollaborationPreviewResponse;
    rounded?: boolean;
    showAllNetworks?: boolean;
}

export const CollaborationCard: FC<Props> = ({ data, rounded, showAllNetworks }) => {
    const { name, coverImageUrl, networks, collaborationState, enrolledCount, enrolmentLimit } = data;

    const bullzNetworkInfo = networks?.find(item => item.networkTitle?.toLowerCase() === 'bullz');
    const socialNetworks = networks?.filter(item => item.networkTitle?.toLowerCase() !== 'bullz') || [];

    const commonReward = getCommonReward(socialNetworks, bullzNetworkInfo?.rewardOnSubmitted);

    return (
        <CollaborationCardWrapper image={coverImageUrl || ''} rounded={rounded}>
            <CollaborationInformation>
                {collaborationState && (
                    <BadgeWrapper>
                        <Badge status={collaborationState}>{collaborationStatuses[collaborationState]}</Badge>
                    </BadgeWrapper>
                )}

                <Section marginBottom="4px">
                    <InfoBlock>
                        <Text color={baseColors.white} size="-2">
                            {commonReward}
                        </Text>
                        <Row marginLeft="2px">
                            <WomSmIcon height="10px" width="10px" />
                        </Row>

                        {!isMobile && socialNetworks.length > 0 && (
                            <>
                                <Row marginLeft="4px">
                                    <PlusSmIcon height="9px" width="9px" />
                                </Row>
                                {!showAllNetworks && socialNetworks.length > 3 ? (
                                    <Row alignCenter noWrap>
                                        {socialNetworks.slice(0, 2).map(item => (
                                            <Row key={item.networkId} marginLeft="2px">
                                                <NetworkIcon name={item?.networkTitle} />
                                            </Row>
                                        ))}
                                        <Text size="-2">{`+${socialNetworks.length - 2}`}</Text>
                                    </Row>
                                ) : (
                                    socialNetworks.map(item => (
                                        <Row key={item.networkId} marginLeft="2px">
                                            <NetworkIcon name={item?.networkTitle} />
                                        </Row>
                                    ))
                                )}
                            </>
                        )}
                    </InfoBlock>
                </Section>

                <Section marginBottom="4px">
                    <StyledTitle size="-2">{name}</StyledTitle>
                </Section>

                <Section>
                    <Text size="-2">
                        {enrolledCount}/{enrolmentLimit}
                    </Text>
                </Section>
            </CollaborationInformation>
        </CollaborationCardWrapper>
    );
};
