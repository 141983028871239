import { VerificationSmIcon } from 'assets/icons';
import slideOneImage from 'assets/img/wallet-guide/wallet-guide-mobile-1.png';
import slideThreeImage from 'assets/img/wallet-guide/wallet-guide-mobile-3.png';
import { CustomImg } from 'components/common/CustomImg';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { criteria } from 'components/modals/WalletGuideModal/constants';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { CriterionGrid } from './styles';

export const SlideOne = () => (
    <Section>
        <Section justifyCenter marginBottom="28px">
            <CustomImg height="370px" src={slideOneImage} width="280px" />
        </Section>
        <Section justifyCenter marginBottom="16px">
            <Text alignTextCenter isBold>
                <span aria-label="" role="img">
                    🤑
                </span>{' '}
                Get WOM rewards
            </Text>
        </Section>
        <Section justifyCenter marginBottom="8px">
            <Text alignTextCenter size="-2">
                There are two ways to earn WOM rewards in BULLZ:
            </Text>
        </Section>

        <Section justifyCenter marginBottom="8px">
            <Text alignTextCenter size="-2">
                1- Create videos and get rewarded based on engagement when your video passes Authentication
            </Text>
        </Section>

        <Section justifyCenter marginBottom="8px">
            <Text alignTextCenter size="-2">
                2- Authenticate videos and get rewarded when you are in consensus with other Authenticators
            </Text>
        </Section>
    </Section>
);

interface Props {
    title: string;
    text: string;
}

const CriteriaItem: FC<Props> = ({ title, text }) => (
    <Section justifyCenter>
        <Row alignCenter marginBottom="18px">
            <MarginWrapper marginRight="16px">
                <VerificationSmIcon />
            </MarginWrapper>
            <Text alignTextCenter isBold>
                {title}
            </Text>
        </Row>
        <Text alignTextCenter size="-1">
            {text}
        </Text>
    </Section>
);

export const SlideTwo = () => (
    <Section justifyCenter>
        <Section justifyCenter marginBottom="8px">
            <Text alignTextCenter isBold>
                How Do Ratings Work?
            </Text>
        </Section>
        <Section justifyCenter marginBottom="32px">
            <Text alignTextCenter size="-2">
                Authenticators rate videos based on:
            </Text>
        </Section>
        <CriterionGrid>
            {criteria.map(item => (
                <CriteriaItem key={item.id} {...item} />
            ))}
        </CriterionGrid>
    </Section>
);

export const SlideThree = () => (
    <Section>
        <Section marginBottom="28px">
            <CustomImg src={slideThreeImage} />
        </Section>
        <Section justifyCenter marginBottom="16px">
            <Text alignTextCenter isBold>
                <span aria-label="" role="img">
                    📲
                </span>{' '}
                Submit your videos!
            </Text>
        </Section>
        <Section justifyCenter marginBottom="8px">
            <Text alignTextCenter size="-2">
                To submit a video or a rating, you need to stake WOM. A stake is a deposit to show you believe in your
                recommendation or rating.
            </Text>
        </Section>
        <Section justifyCenter marginBottom="8px">
            <Text alignTextCenter size="-2">
                For Creators, you can start earning WOM after your video passes Authentication. If your video does not
                pass, you lose your stake.
            </Text>
        </Section>
        <Section justifyCenter>
            <Text alignTextCenter size="-2">
                For Authenticators, you earn WOM if your ratings are in consensus with other Authenticators, otherwise
                you lose your stake.
            </Text>
        </Section>
    </Section>
);
