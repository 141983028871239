export enum Step {
    Phone = 'phone',
    SmsCode = 'smsCode',
    Password = 'password'
}

export const titles: Record<Step, string> = {
    phone: 'Verify your identity',
    smsCode: 'Enter Code',
    password: 'Create New Password'
};

export const subtitles: Record<Step, string> = {
    phone:
        'In order to change your password, please verify your identity by entering a code sent to your registered mobile number  ****',
    smsCode: 'Please enter the code sent to your phone number',
    password: 'Please create a new password to secure your account'
};

export const secondsToResendCode = 60;
export const pinCodeLength = 6;

export enum ForgotPasswordFieldName {
    MobileNumber = 'mobileNumber',
    SmsVerificationCode = 'smsVerificationCode',
    Password = 'password',
    ConfirmPassword = 'confirmPassword'
}
