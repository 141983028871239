import { BackArrowSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { Spinner } from 'components/loaders/Spinner';
import { CollaborationCard } from 'components/ui/CollaborationCard';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { defaultCollaborationQueryParams } from 'constants/services/collaboration';
import { useStore } from 'effector-react';
import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { collaborationsEffects, collaborationsStores } from 'stores/collaborations';
import { CollaborationWrapper, CollaborationsList } from './styles';

const { $creatorCollaborations, $hasError } = collaborationsStores;
const { loadMoreCollaborationsFx } = collaborationsEffects;

export const CollaborationsPage = () => {
    const { data } = useStore($creatorCollaborations);
    const isMoreLoading = useStore(loadMoreCollaborationsFx.pending);
    const hasError = useStore($hasError);

    const collaborations = data?.items;
    const currentPageIndex = data?.pageIndex;
    const hasNext = data?.hasNext;

    const history = useHistory();

    const handleClickCard = (id: string) => {
        id && history.replace(`${AppRoute.Collaborations}/${id}`);
    };

    const goToBack = () => {
        history.goBack();
    };

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: hasNext === undefined ? true : hasNext || false,
        onLoadMore: () => {
            loadMoreCollaborationsFx({
                ...defaultCollaborationQueryParams,
                pageIndex:
                    currentPageIndex !== undefined ? currentPageIndex + 1 : defaultCollaborationQueryParams.pageIndex
            });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    return (
        <NewMainLayout>
            <Column width="100%">
                <Section noWrap marginBottom="12px">
                    <IconButton onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>

                    <Section marginLeft="12px">
                        <Text isBold size="1">
                            All Collaborations
                        </Text>
                    </Section>
                </Section>

                {collaborations && collaborations.length > 0 && (
                    <CollaborationsList>
                        {collaborations.map(item => (
                            <CollaborationWrapper key={item.id} onClick={() => handleClickCard(item.id || '')}>
                                <CollaborationCard showAllNetworks data={item} />
                            </CollaborationWrapper>
                        ))}
                    </CollaborationsList>
                )}
                <HiddenScrollBlock ref={loadRef} />

                {isMoreLoading && (
                    <Section justifyCenter marginTop="24px">
                        <Spinner />
                    </Section>
                )}
            </Column>
        </NewMainLayout>
    );
};
