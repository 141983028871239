import { BackArrowSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Slide as SlideComponent } from 'components/modals/QsGuideModal/Slide';
import { Network, ShareStep, Slide } from 'components/modals/ShareLinkModal/constants';
import { networkSlides } from 'components/modals/ShareLinkModal/SecondStep/constants';
import { Button } from 'components/ui/buttons/Button';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { isMobile } from 'react-device-detect';
import SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconWrapper } from './styles';

interface Props {
    setCurrentStep: Dispatch<SetStateAction<ShareStep>>;
    selectedNetwork: Network | null;
}

export const SecondStep: FC<Props> = ({ setCurrentStep, selectedNetwork }) => {
    const [swiper, setSwiper] = useState<SwiperType | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const slideData = selectedNetwork ? networkSlides[selectedNetwork] : [];

    const handleNextClick = () => {
        if (activeIndex === Slide.Third) {
            setCurrentStep(ShareStep.Third);
        } else {
            swiper && swiper.slideNext();
        }
    };

    const onBackClick = () => {
        setCurrentStep(ShareStep.First);
    };

    if (!selectedNetwork) {
        return null;
    }

    return (
        <>
            {isMobile && (
                <MarginWrapper marginBottom="18px">
                    <IconWrapper type="button" onClick={onBackClick}>
                        <BackArrowSmIcon />
                    </IconWrapper>
                </MarginWrapper>
            )}
            <Section marginBottom="24px">
                <Swiper
                    autoHeight
                    className="guide-swiper"
                    slidesPerView={1}
                    onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
                    onSwiper={swiper => setSwiper(swiper)}
                >
                    {slideData.map(({ id, ...item }) => (
                        <SwiperSlide key={id}>
                            <SlideComponent {...item} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Section>
            <Button wide onClick={handleNextClick}>
                Next
            </Button>
        </>
    );
};
