import { Section } from 'components/grid/wrappers/FlexWrapper';
import { transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { defaultInputStyles, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

const inputHeight = '40px';

interface LabelProps {
    wide?: boolean;
}

export const Label = styled.label<LabelProps>`
    width: 100%;
    max-width: ${({ wide }) => (wide ? '100%' : '400px')};
    display: flex;
    flex-direction: column;
`;

interface InputWrapperProps {
    isFocused?: boolean;
    hasError?: boolean;
}

export const InputWrapper = styled(Section)<InputWrapperProps>`
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid
        ${({ isFocused, hasError }) => (hasError ? baseColors.red : isFocused ? baseColors.blue : grey['600'])};
    border-radius: 8px;
    transition: border ${transitionTime} linear;
    overflow: hidden;

    &:hover:not(:disabled) {
        border-color: ${({ isFocused, hasError }) =>
            hasError ? baseColors.red : isFocused ? baseColors.blue : grey['300']};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const StyledInput = styled.input`
    ${defaultInputStyles};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: ${inputHeight};
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const PasswordButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    height: 24px;
    width: 24px;
    ${flexCenter};
    flex-shrink: 1;
    color: ${grey['500']};
    transition: color ${transitionTime} linear;

    &:hover {
        color: ${baseColors.white};
    }
`;

interface AddonWrapperProps extends InputWrapperProps {
    position: 'left' | 'right';
}

const getAddonBorder = (props: AddonWrapperProps) => {
    const borderColor = props.hasError ? baseColors.red : props.isFocused ? baseColors.blue : grey['600'];

    switch (props.position) {
        case 'left':
        default:
            return css`
                border-right: 1px solid ${borderColor};
            `;
        case 'right':
            return css`
                border-left: 1px solid ${borderColor};
            `;
    }
};

export const AddonWrapper = styled.div<AddonWrapperProps>`
    min-height: ${inputHeight};
    ${flexCenter};
    padding-left: 10px;
    padding-right: 10px;
    ${props => getAddonBorder(props)};
    transition: border ${transitionTime} linear;
`;

export const ErrorWrapper = styled.div`
    flex-grow: 1;
    color: ${baseColors.red};
`;
