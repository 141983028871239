import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import React, { FC } from 'react';
import OtpInput from 'react-otp-input';
import { containerStyle, InputsWrapper } from './styles';

interface Props {
    value: string;
    count?: number;
    onChange?: (value: string) => void;
    error?: string;
    shouldAutoFocus?: boolean;
}

export const CodeInput: FC<Props> = ({ value, error, onChange, count = 6, shouldAutoFocus = true }) => (
    <Column width="100%">
        <InputsWrapper hasError={!!error}>
            <OtpInput
                isInputNum
                className="otp-input"
                containerStyle={containerStyle}
                numInputs={count}
                shouldAutoFocus={shouldAutoFocus}
                value={value}
                onChange={onChange}
            />
        </InputsWrapper>

        {error && (
            <Section marginTop="8px">
                <Text color={baseColors.red} size="-2">
                    {error}
                </Text>
            </Section>
        )}
    </Column>
);
