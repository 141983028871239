import { disableDefaultButtonStyleMixin } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import styled from 'styled-components';

export const StyledClickableWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 94px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: ${baseColors.darkGrey};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;
