import { PreviewVideoCard } from 'components/cards/PreviewVideoCard';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import { myQsModal } from 'stores/initialize.modal';
import { quickSignalsEffects, quickSignalsStores } from 'stores/quick-signals';
import { Empty } from '../QuickSignalsTabs/styles';
import { VideoCardsWrapper } from './styles';

const { getQuickSignalsVideosFx } = quickSignalsEffects;
const { $quickSignalsVideos, $quickSignalsInfo } = quickSignalsStores;

export const TabContentVideos = () => {
    const { data } = useStore($quickSignalsVideos);
    const items = useMemo(() => data?.items, [data]);

    const quickSignalsInfo = useStore($quickSignalsInfo);
    const [info] = quickSignalsInfo?.data ? quickSignalsInfo?.data : [];
    const id = info?.id;

    const isLoading = useStore(getQuickSignalsVideosFx.pending);

    const openQsModal = (itemId?: string | null) => {
        if (id) {
            myQsModal.openModal({
                channelId: id,
                videoId: itemId
            });
        }
    };

    useEffect(() => {
        id && getQuickSignalsVideosFx(id);
    }, [id]);

    return (
        <Column height="100%" marginTop="10px" width="100%">
            {!isLoading && items && items.length > 0 && (
                <VideoCardsWrapper>
                    {items.map(item => (
                        <PreviewVideoCard
                            key={item.id}
                            id={item.id}
                            thumbnailUrl={item?.thumbnailUrl}
                            videoSrc={item?.streamUrl}
                            viewCount={item?.engagements?.viewsCount}
                            onClick={openQsModal}
                        />
                    ))}
                </VideoCardsWrapper>
            )}

            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    <Text color={grey[500]}>You dont have any videos yet</Text>
                </Empty>
            )}

            {isLoading && (
                <Section justifyCenter marginTop="24px">
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
