import { CloseSmIcon, InfoSmIcon, ReportSmIcon } from 'assets/icons';
import { PlayerCard } from 'components/cards/PlayerCard';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { JoinCard } from 'components/modals/QSModal/components/JoinCard';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { UserProfile } from 'components/video/UserProfile';
import { Volume } from 'components/video/Volume';
import { baseColors } from 'constants/styles';
import { useStore } from 'effector-react';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { authStores } from 'stores/auth';
import { loginModal, reportVideoModal } from 'stores/initialize.modal';
import { playerEvents, playerStores } from 'stores/player';
import SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NoopType } from 'types';
import { noop } from 'utils/common';
import {
    BottomBarWrapper,
    Card,
    ModalWrapper,
    StoriesLine,
    StoriesLines,
    TopBarWrapper,
    VideoWrapper
} from '../../styles';

const { $isAuth } = authStores;
const { $isMuted, $volume } = playerStores;
const { toggleMuteVolume, setVolume } = playerEvents;

interface Props {
    users: BULLZ.GetQuickSignalUserResponse[];
    currentUser: BULLZ.GetQuickSignalUserResponse;
    currentUserVideos: BULLZ.GetQuickSignalUserVideosResponse | null;
    isVisibleVideos: boolean;
    currentVideoIndex: number;
    currentProgress: number;
    onProgressChange?: (progress: number) => void;
    currentUserIndex: number;
    onClose: NoopType;
    isLoading?: boolean;
    onSlideNext?: NoopType;
    onSlidePrev?: NoopType;
    onNextVideo?: NoopType;
    onPrevVideo?: NoopType;
    onInfoClick?: NoopType;
    onJoin?: (channelId: string, userId?: string) => void;
}

export const MobileView: FC<Props> = ({
    users,
    currentUser,
    currentUserVideos,
    isVisibleVideos,
    currentVideoIndex,
    currentProgress,
    currentUserIndex,
    isLoading = false,
    onProgressChange,
    onClose,
    onSlideNext,
    onSlidePrev,
    onJoin,
    onNextVideo,
    onPrevVideo,
    onInfoClick = noop
}) => {
    const [swiper, setSwiper] = useState<SwiperType | null>(null);
    const [firstView, setFirstView] = useState(true);
    const isMuted = useStore($isMuted);
    const volume = useStore($volume);

    const { width } = useWindowDimensions();
    const isAuth = useStore($isAuth);

    const handleSlideChange = (swiper: SwiperType) => {
        if (swiper.activeIndex > currentUserIndex) {
            onSlideNext?.();
        }

        if (swiper.activeIndex < currentUserIndex) {
            onSlidePrev?.();
        }
    };

    const handleVideoClick = (e: MouseEvent<HTMLVideoElement>) => {
        e.persist();

        if (e.clientX >= width / 2) {
            if (currentUserVideos?.items && currentVideoIndex < currentUserVideos.items.length - 1) {
                onNextVideo?.();
            } else {
                swiper?.slideNext();
            }
        } else {
            if (currentVideoIndex > 0) {
                onPrevVideo?.();
            } else {
                swiper?.slidePrev();
            }
        }
    };

    const handleReportVideo = () => {
        const videoId = currentUserVideos?.items && currentUserVideos?.items[currentVideoIndex].id;
        if (isAuth && videoId) {
            reportVideoModal.openModal({
                videoId: videoId,
                title:
                    'We are sorry that you found this content inappropriate. Please indicate what you found unacceptable about the video.',
                text: 'We will review your feedback and take appropriate action. You will no longer see this video.',
                isQuickSignal: true
            });
        } else {
            loginModal.openModal();
        }
    };

    useEffect(() => {
        if (firstView && swiper) {
            if (currentUserIndex > 0) {
                swiper.slideTo(currentUserIndex, 0);
            }

            setFirstView(false);
        }
    }, [currentUserIndex, firstView, swiper]);

    return (
        <ModalWrapper>
            <Column alignCenter justifyCenter height="100%" width="100%">
                <Swiper
                    slidesPerView={1}
                    style={{ width: '100%', height: '100%' }}
                    onSlideChangeTransitionEnd={handleSlideChange}
                    onSwiper={swiper => setSwiper(swiper)}
                >
                    {users.map((item, userIndex) => (
                        <SwiperSlide key={item.userProfile?.id}>
                            {isLoading ? (
                                <Column alignCenter justifyCenter height="100%" width="100%">
                                    <Spinner />
                                </Column>
                            ) : (
                                <VideoWrapper>
                                    {currentUser.userProfile?.id === item.userProfile?.id && (
                                        <TopBarWrapper>
                                            {isVisibleVideos && currentUserVideos?.items?.length && (
                                                <StoriesLines count={currentUserVideos.items.length}>
                                                    {currentUserVideos.items.map((_, index) => (
                                                        <StoriesLine
                                                            key={index}
                                                            done={index < currentVideoIndex}
                                                            isCurrent={index === currentVideoIndex}
                                                            progress={currentProgress}
                                                        />
                                                    ))}
                                                </StoriesLines>
                                            )}

                                            {isVisibleVideos && currentUser && currentUser.userProfile && (
                                                <Section alignCenter justifyBetween noWrap marginTop="14px">
                                                    <IconButton onClick={onClose}>
                                                        <CloseSmIcon />
                                                    </IconButton>

                                                    <UserProfile
                                                        avatar={currentUser.userProfile.avatarUrl || ''}
                                                        isFollowed={currentUser.userProfile.isFollowed}
                                                        isTrusted={currentUser.userProfile.isTrusted}
                                                        userId={currentUser.userProfile.id}
                                                        username={currentUser.userProfile.username || ''}
                                                    />
                                                </Section>
                                            )}

                                            {!isVisibleVideos && (
                                                <Section marginTop="14px">
                                                    <IconButton onClick={onClose}>
                                                        <CloseSmIcon />
                                                    </IconButton>
                                                </Section>
                                            )}
                                        </TopBarWrapper>
                                    )}

                                    <Column alignCenter justifyCenter height="100%" width="100%">
                                        {currentUser.userProfile?.id === item.userProfile?.id ? (
                                            <>
                                                {isVisibleVideos ? (
                                                    <PlayerCard
                                                        hideControls
                                                        isCover
                                                        contentType="quick-signal"
                                                        posterSrc={
                                                            currentUserVideos?.items?.length
                                                                ? currentUserVideos?.items[currentVideoIndex]
                                                                      .thumbnailUrl
                                                                : ''
                                                        }
                                                        videoId={
                                                            currentUserVideos?.items?.length
                                                                ? currentUserVideos?.items[currentVideoIndex].id
                                                                : ''
                                                        }
                                                        videoSrc={
                                                            currentUserVideos?.items?.length
                                                                ? currentUserVideos?.items[currentVideoIndex].streamUrl
                                                                : ''
                                                        }
                                                        onClick={handleVideoClick}
                                                        onProgressChange={onProgressChange}
                                                    />
                                                ) : (
                                                    <JoinCard
                                                        avatar={currentUser.userProfile?.avatarUrl || undefined}
                                                        channelId={currentUserVideos?.channelInfo?.id}
                                                        description={currentUserVideos?.channelInfo?.description}
                                                        name={currentUserVideos?.channelInfo?.name}
                                                        totalSignalsCount={
                                                            currentUserVideos?.channelInfo?.totalSignalsCount
                                                        }
                                                        utcCreated={currentUserVideos?.channelInfo?.utcCreated}
                                                        womValue={currentUserVideos?.channelInfo?.womValue}
                                                        onJoin={onJoin}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <Card fullSize>
                                                <QsAvatar
                                                    hasNewVideos={users[userIndex].hasNewVideos}
                                                    isSubscribed={users[userIndex].isSubscribed}
                                                    url={users[userIndex].userProfile?.avatarUrl || ''}
                                                />

                                                <Section justifyCenter marginTop="8px">
                                                    <Text alignTextCenter size="-1">
                                                        {item.userProfile?.username}
                                                    </Text>
                                                </Section>
                                            </Card>
                                        )}
                                    </Column>

                                    {isVisibleVideos && currentUser.userProfile?.id === item.userProfile?.id && (
                                        <BottomBarWrapper>
                                            <Section justifyEnd marginTop="12px">
                                                <Volume
                                                    hideRange
                                                    isMuted={isMuted}
                                                    setVolume={setVolume}
                                                    toggleMuteVolume={toggleMuteVolume}
                                                    volume={volume}
                                                />
                                            </Section>
                                            <Section justifyEnd marginTop="12px">
                                                <IconButton onClick={onInfoClick}>
                                                    <InfoSmIcon />
                                                </IconButton>
                                            </Section>
                                            <Section justifyEnd marginTop="12px">
                                                <IconButton onClick={handleReportVideo}>
                                                    <ReportSmIcon color={baseColors.white} />
                                                </IconButton>
                                            </Section>
                                        </BottomBarWrapper>
                                    )}
                                </VideoWrapper>
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Column>
        </ModalWrapper>
    );
};
