import { ModalWrapperProps } from 'components/grid/wrappers/ModalWrapper';
import { baseColors, grey } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

interface ModalProps extends Pick<ModalWrapperProps, 'fullscreenMobile' | 'zIndex' | 'size' | 'dark'> {}

export const ModalBackdrop = styled.div<ModalProps>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${({ zIndex }) => zIndex || 100};
    background-color: ${({ dark }) => (dark ? baseColors.black : 'rgba(0, 0, 0, 0.6)')};
    ${flexCenter};
`;

const getStyleBySize = (size: 'default' | 'large') => {
    switch (size) {
        case 'default':
        default: {
            return css`
                ${media.phoneBig(`
                    padding: 30px 50px;
                    max-width: 400px;
                `)}

                ${media.laptopSmall(`
                    padding: 40px 85px;
                    max-width: 570px;
                `)}
            `;
        }
        case 'large': {
            return css`
                ${media.phoneBig(`
                    max-width: 100%;
                `)}

                ${media.laptopSmall(`
                    padding: 30px 45px;
                    max-width: 800px;
                `)}
            `;
        }
    }
};
export const ModalContent = styled.div<ModalProps>`
    width: 100%;
    max-width: ${({ fullscreenMobile }) => (fullscreenMobile ? '100%' : 'calc(100% - 48px)')};
    ${({ fullscreenMobile }) => (fullscreenMobile ? 'height: 100%' : 'max-height: 100%')};
    overflow-y: auto;
    background-color: ${({ fullscreenMobile }) => (fullscreenMobile ? baseColors.black : grey['900'])};
    ${({ fullscreenMobile }) => !fullscreenMobile && 'border-radius: 20px'};
    padding: ${({ fullscreenMobile }) => (fullscreenMobile ? '24px 16px' : '24px')};
    z-index: ${({ zIndex }) => (zIndex ? zIndex + 1 : 100)};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    display: flex;
    flex-direction: column;

    ${({ size }) => size && getStyleBySize(size)}

    ${media.phoneBig(`
        height: auto;
        max-height: 100vh;
        border-radius: 20px;
        background-color: ${grey['900']};
    `)}
`;
