import React, { useEffect } from 'react';
import { CloseSmIcon } from 'assets/icons';
import { useStore } from 'effector-react';
import { grey } from 'constants/styles';
import { keyboardShortcutModal } from 'stores/initialize.modal';
import { homeStores, homeEvents } from 'stores/home';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { SHORTCUTS_KEY } from './constants';
import {
    KeyboardShortcutModalStyled,
    ModalContent,
    ButtonsWrapper,
    ButtonIconWrapper,
    ShortcutKeyWrapper,
    TitleWrapper
} from './styles';

const { modal, openModal, closeModal } = keyboardShortcutModal;
const { $hasCloseKeyboardShortcutModal } = homeStores;
const { setHasCloseKeyboardShortcutModal } = homeEvents;

export const KeyboardShortcutModal = () => {
    const hasCloseKeyboardShortcut = useStore($hasCloseKeyboardShortcutModal);
    const [visible] = useStore(modal);
    const handleClose = () => {
        closeModal();
        setHasCloseKeyboardShortcutModal(true);
    };

    useEffect(() => {
        if (!hasCloseKeyboardShortcut) {
            openModal();
        }
    }, [hasCloseKeyboardShortcut]);

    if (!visible) {
        return null;
    }

    return (
        <KeyboardShortcutModalStyled>
            <ModalContent>
                <Column noWrap width="100%">
                    <ButtonsWrapper>
                        <ButtonIconWrapper type="button" onClick={handleClose}>
                            <CloseSmIcon />
                        </ButtonIconWrapper>
                    </ButtonsWrapper>

                    <TitleWrapper>
                        <Text isBold size="-1">
                            Keyboard shortcuts
                        </Text>
                    </TitleWrapper>

                    {SHORTCUTS_KEY.map(({ title, icon }) => (
                        <ShortcutKeyWrapper key={title}>
                            <Text alignTextCenter color={grey[500]} size="-2">
                                {title}
                            </Text>
                            {icon}
                        </ShortcutKeyWrapper>
                    ))}
                </Column>
            </ModalContent>
        </KeyboardShortcutModalStyled>
    );
};
