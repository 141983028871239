import { forward } from 'effector';
import { giftsEffects } from 'stores/gifts';
import { loaderEffects } from 'stores/loader';

const { buyCardFx, buyCardDoneFx, buyCardFailFx } = giftsEffects;

forward({
    from: buyCardFx.pending,
    to: loaderEffects.loaderFx
});

forward({
    from: buyCardFx.doneData,
    to: buyCardDoneFx
});

forward({
    from: buyCardFx.fail,
    to: buyCardFailFx
});
