import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { FormWrapper } from 'components/form/styles';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { Formik, FormikProps } from 'formik';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { qsChannelEffects, qsChannelEvents } from 'stores/quick-signals-channel';
import { CreateForm } from './CreateForm';
import { EditForm } from './EditForm';
import { QSFormEditValues, QSFormValues, qsInitialValues, validateQSForm } from './validation';

interface Props {
    isEdit: boolean;
    data?: BULLZ.GetChannelOwnerInfoResponse;
}

export const QSCreateForm: FC<Props> = ({ isEdit, data }) => {
    const history = useHistory();
    const user = useStore(authStores.$user);

    const editInitialValues: QSFormEditValues = {
        title: data?.name || '',
        description: data?.description || ''
    };

    const createQs = async (values: QSFormValues) => {
        await qsChannelEffects.createChannelFx({
            name: values.title,
            description: values.description,
            womValue: +values.price
        });

        history.replace(user ? `${AppRoute.Profile}/${user.userId}` : AppRoute.Main);
    };

    const editQs = (values: QSFormEditValues) => {
        if (data && data.id) {
            const titleIsDirty = values.title !== editInitialValues.title;
            const descriptionIsDirty = values.description !== editInitialValues.description;

            if (titleIsDirty || descriptionIsDirty) {
                qsChannelEffects
                    .editChannelFx({
                        channelId: data.id,
                        name: titleIsDirty ? values.title : undefined,
                        description: descriptionIsDirty ? values.description : undefined
                    })
                    .then(() => {
                        infoModal.openModal({
                            qsAvatar: user?.profile?.profileImageUrl || defaultAvatar,
                            title: 'Success!',
                            text: 'Your changes have been saved.',
                            buttonText: 'Okay',
                            cancelText: 'See All My Videos',
                            onClick: () => history.push(AppRoute.QuickSignals),
                            onCloseClick: () => history.push(AppRoute.QuickSignals),
                            afterCloseCb: () => history.push(AppRoute.QuickSignals)
                        });
                    });
            }
        }
    };

    useEffect(() => () => qsChannelEvents.resetStores(), []);

    if (isEdit && data && data.id) {
        return (
            <Formik initialValues={editInitialValues} onSubmit={editQs}>
                {({ handleSubmit }: FormikProps<QSFormEditValues>) => (
                    <FormWrapper onSubmit={handleSubmit}>
                        <Column noWrap maxWidth="420px" width="100%">
                            <EditForm />
                        </Column>
                    </FormWrapper>
                )}
            </Formik>
        );
    }

    return (
        <Formik initialValues={qsInitialValues} validate={validateQSForm} onSubmit={createQs}>
            {({ handleSubmit, isValid, dirty }: FormikProps<QSFormValues>) => (
                <FormWrapper onSubmit={handleSubmit}>
                    <Column noWrap maxWidth="420px" width="100%">
                        <CreateForm isDirty={dirty} isValid={isValid} />
                    </Column>
                </FormWrapper>
            )}
        </Formik>
    );
};
