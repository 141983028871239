import { ReactNode } from 'react';
import { NoopType } from 'types';

export enum CurationState {
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

export interface NotificationKeys {
    profileImageUrl?: string;
    userid?: string;
    username?: string;
    videoid?: string;
    videoownerid?: string;
    teamid?: string;
    brandName?: string;
    collaborationId?: string;
    collaborationName?: string;
    networkName?: string;
    enrollmentDateTime?: string;
    timeLeft?: string;
    curationstate?: CurationState;
    reason?: string;
    address?: string;
    status?: string;
    totalSeconds?: string;
    transactionId?: string;
    txHash?: string;
    value?: string;
    channelId?: string;
    subscriberUserId?: string;
    creatorId?: string;
    creatorName?: string;
    quickSignalChannelName?: string;
}

export interface NotificationData {
    title: string;
    icon?: ReactNode;
    text?: string;
    link?: string;
    action?: NoopType;
}
