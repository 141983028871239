import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const VideoCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 8px;
    width: 100%;

    ${media.laptop(`
        grid-template-columns: repeat(5, 1fr);
        row-gap: 16px;
        column-gap: 26px;
    `)};
`;
