import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { baseColors, disableDefaultButtonStyleMixin, flexCenter, grey, transitionTime } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled, { css } from 'styled-components';
import { Visibility } from 'types';

export const StyledTitle = styled(Title)`
    text-align: center;
    margin-bottom: 16px;
`;

export const StyledSubtitle = styled(Text)`
    text-align: center;
    margin-bottom: 16px;
`;

export const ButtonsWrapper = styled(Section)`
    justify-content: space-between;
    margin-bottom: 16px;

    ${media.laptop(`
        margin-bottom: 24px;
    `)};
`;

export const IconWrapper = styled.button<Visibility>`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    ${flexCenter};
    color: ${grey[500]};
    transition: color ${transitionTime} linear;
    ${({ visible }) =>
        !visible &&
        css`
            visibility: hidden;
        `}

    &:hover {
        color: ${baseColors.white};
    }
`;
