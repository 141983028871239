import { FormWrapper } from 'components/form/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Select } from 'components/ui/Select';
import { SelectOptionValue } from 'components/ui/Select/types';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import { useField } from 'formik';
import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { isMobile } from 'react-device-detect';
import { AdditionalRegisterFieldName, Step } from '../constants';
import { SelectsWrapper, TextWrapper } from './styles';
import { getAgeText, getDays, getMonths, getYears } from './utils';

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
}

export const BirthdayStep: FC<Props> = ({ setCurrentStep }) => {
    const [, { value: dayValue }, { setValue: setDayValue }] = useField<string>(AdditionalRegisterFieldName.Day);
    const [, { value: monthValue }, { setValue: setMonthValue }] = useField<string>(AdditionalRegisterFieldName.Month);
    const [, { value: yearValue }, { setValue: setYearValue }] = useField<string>(AdditionalRegisterFieldName.Year);
    const [, { error }] = useField<string>(AdditionalRegisterFieldName.FullBirthday);

    const isValid = dayValue && monthValue && yearValue && !error;
    const isErrorVisible = dayValue && monthValue && yearValue && error;

    const dayPlaceholder = isMobile ? 'DD' : 'Day';
    const monthPlaceholder = isMobile ? 'MM' : 'Month';
    const yearPlaceholder = isMobile ? 'YY' : 'Year';

    const handleDayChange = (value: SelectOptionValue) => {
        setDayValue(String(value));
    };

    const handleMonthChange = (value: SelectOptionValue) => {
        setMonthValue(String(value));
    };

    const handleYearChange = (value: SelectOptionValue) => {
        setYearValue(String(value));
    };

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!!error) {
            return;
        }

        setCurrentStep(Step.Gender);
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Column width="100%">
                <Section marginBottom="8px">
                    <Text size="-1">Birthdate</Text>
                </Section>

                <SelectsWrapper>
                    <Select
                        hideOnChange
                        items={getDays()}
                        optionsWidth="260px"
                        placeholder={dayPlaceholder}
                        value={dayValue || ''}
                        onChange={handleDayChange}
                    />
                    <Select
                        hideOnChange
                        items={getMonths()}
                        optionsWidth="260px"
                        placeholder={monthPlaceholder}
                        value={monthValue || ''}
                        onChange={handleMonthChange}
                    />
                    <Select
                        hideOnChange
                        items={getYears()}
                        optionsWidth="260px"
                        placeholder={yearPlaceholder}
                        value={yearValue || ''}
                        onChange={handleYearChange}
                    />
                </SelectsWrapper>

                {isErrorVisible && (
                    <TextWrapper>
                        <Text color={baseColors.red} size="-2">
                            {error}
                        </Text>
                    </TextWrapper>
                )}

                {isValid && (
                    <TextWrapper>
                        <Text size="-2">{getAgeText(dayValue, monthValue, yearValue)}</Text>
                    </TextWrapper>
                )}
            </Column>

            <Section marginTop="24px">
                <Button wide disabled={!isValid} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
