import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import styled, { createGlobalStyle, css } from 'styled-components';

export const SelectWrapper = styled.div`
    width: 100%;
    height: 40px;
    position: relative;
`;

interface SelectValueWrapperProps {
    isVisible?: boolean;
    hideBorder?: boolean;
    hasError?: boolean;
}

export const SelectValueWrapper = styled.div<SelectValueWrapperProps>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    transition: border ${transitionTime} linear;
    cursor: pointer;
    user-select: none;

    ${({ hideBorder, isVisible, hasError }) =>
        !hideBorder &&
        css`
            padding: 10px;
            border: 1px solid ${hasError ? baseColors.red : isVisible ? grey['300'] : grey['600']};

            &:hover {
                border-color: ${grey['300']};
            }
        `};
`;

export const SelectValue = styled(Text)`
    white-space: nowrap;
`;

export const OptionsWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

interface GlobalStyleProps {
    width?: string;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    .custom-select {
        &.rc-tooltip {
            opacity: 1;
            padding: 0;
        }

        .rc-tooltip-inner {
            border-radius: 8px !important;
            border: 1px solid ${grey['600']} !important;
            background-color: ${grey['900']} !important;
            overflow-x: hidden !important;
            overflow-y: auto !important;
            padding: 0 !important;
            min-height: auto !important;
            ${({ width }) => `width: ${width || '100%'} !important`};
            max-height: 300px !important;
        }
    }
`;
