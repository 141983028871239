import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const ChecklistWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

interface ChecklistItemProps {
    hasMargin?: boolean;
}

export const ChecklistItem = styled(Section)<ChecklistItemProps>`
    flex-wrap: nowrap;
    align-items: center;
    ${({ hasMargin }) => hasMargin && 'margin-top: 8px'};
`;

export const IndicatorWrapper = styled.div`
    width: 16px;
    height: 16px;
    ${flexCenter};
    flex-shrink: 0;
    margin-right: 8px;
`;

export const Dot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${grey[500]};
`;
