import { ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { buwpLink } from 'constants/global';
import { createEffect } from 'effector';
import { API } from 'services';
import { bucmAPI } from 'services/bucm';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';

//Effects

interface UploadSubmissionParams extends BULLZ.CreateVideoRequest {
    file: File;
    userId: string;
    networkId: string;
}

const uploadSubmissionFx = createEffect({
    handler: async ({ file, userId, networkId, ...data }: UploadSubmissionParams) => {
        const { videoUploadId, videoId } = await API.video.getNewVideoId(data);
        if (videoUploadId) {
            const { isSuccess: isUploadSucceed } = await API.video.uploadVideo(videoUploadId, file);
            if (isUploadSucceed) {
                const { isSuccess } = await bucmAPI.mediaAssets.createAssetForSubmission(data.collaborationId || '', {
                    remoteId: videoId,
                    postUri: `${buwpLink}video/${videoId}`,
                    networkId
                });
                if (!isSuccess) {
                    infoModal.openModal({
                        icon: WrongLgIcon,
                        title: 'Something went wrong',
                        text:
                            'There was a problem submitting your video. Please try again. If the problem persists, please contact our support team.',
                        buttonText: 'Try Again',
                        cancelText: 'CONTACT SUPPORT',
                        onClick: noop,
                        onCloseClick: goToHelpDesk
                    });
                }
            }
        }
        return { bcmUserId: userId, collaborationId: data.collaborationId || '' };
    }
});

interface ShareSocialLinkParams {
    videoId: string;
    userId: string;
    collaborationId: string;
    networkId: string;
    postUri: string;
    networkType: string;
}

const shareSocialLinkFx = createEffect({
    handler: async ({ collaborationId, videoId, networkId, userId, postUri, networkType }: ShareSocialLinkParams) => {
        const { isSuccess } = await bucmAPI.mediaAssets.createAssetForSubmission(collaborationId, {
            remoteId: videoId,
            postUri,
            networkId
        });
        if (isSuccess) {
            infoModal.openModal({
                icon: ConfettiLgIcon,
                title: 'Share success!',
                text: `You successfully submitted the link to your ${networkType}.`,
                buttonText: 'Okay',
                onClick: () => noop
            });
        } else {
            infoModal.openModal({
                icon: WrongLgIcon,
                title: 'Something went wrong',
                text:
                    'There was a problem submitting the link. Please try again. If the problem persists, please contact our support team.',
                buttonText: 'Try Again',
                cancelText: 'CONTACT SUPPORT',
                onClick: noop,
                onCloseClick: goToHelpDesk
            });
        }

        return { bcmUserId: userId, collaborationId };
    }
});

//Exports

export const uploadSubmissionEffects = {
    uploadSubmissionFx,
    shareSocialLinkFx
};
