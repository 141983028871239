import { ScrolledInfo } from 'types';

export const scrollWrapperWidth = '35px';
export const scrollHorizontalPadding = '2px';

export const defaultAlt = 'image';
export const defaultScrollState: ScrolledInfo = { type: 'stop', position: 0 };
export const defaultScrollWidth = parseInt(scrollWrapperWidth) - 2 * parseInt(scrollHorizontalPadding) + 'px';

export const videoClassNamePrefix = 'video-';
