import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, ellipsisMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

const profileHeaderHeight = '48px';

export const MobileContainer = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding-top: ${profileHeaderHeight};
`;

export const MobileHeaderWrapper = styled(Section)`
    position: fixed;
    top: ${headerHeight};
    left: 0;
    right: 0;
    height: ${profileHeaderHeight};
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: ${baseColors.black};
    z-index: 8;
`;

export const MobileBio = styled(Section)`
    justify-content: center;
    text-align: center;
`;

export const MobileTeamButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    padding: 4px 10px;
    border-radius: 50px;
    border: 1px solid ${baseColors.white};
`;

export const TeamText = styled(Text)`
    ${ellipsisMixin};
    max-width: 94px;

    ${media.phone(`
         max-width: 100%;
    `)};
`;
