import { WrongLgIcon } from 'assets/icons';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { CodeInput } from 'components/ui/inputs/CodeInput';
import { asyncErrorMessage } from 'constants/messages';
import { useField } from 'formik';
import { useReCaptcha } from 'hooks/useReCaptcha';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { authEffects } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk } from 'utils/common';
import { ForgotPasswordFieldName, Step, pinCodeLength, secondsToResendCode } from '../constants';
import { ISetCurrentStep } from '../types';

interface Props extends ISetCurrentStep {}

export const SmsCodeStep: FC<Props> = ({ setCurrentStep }) => {
    const [codeSeconds, setCodeSeconds] = useState(secondsToResendCode);

    const [, { value, error, touched }, { setValue, setTouched }] = useField<string>(
        ForgotPasswordFieldName.SmsVerificationCode
    );
    const [, { value: phone }] = useField<string>(ForgotPasswordFieldName.MobileNumber);

    const token = useReCaptcha();

    const submitIsActive = !!value && touched && !error;

    const handleChange = (value: string) => {
        !touched && value.length === pinCodeLength && setTouched(true);
        setValue(value);
    };

    const openErrorCodeModal = (text?: string) => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: text || '',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onCloseClick: goToHelpDesk
        });
    };

    const resendCode = () => {
        authEffects
            .forgotPasswordSendSmsCodeFx({
                phone,
                firebaseToken: token
            })
            .then(data => {
                if (data.isOk) {
                    setCodeSeconds(secondsToResendCode);
                } else {
                    openErrorCodeModal();
                }
            })
            .catch(e => {
                openErrorCodeModal((e.message as string) || '');
            });
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!submitIsActive) return;
        setCurrentStep(Step.Password);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (codeSeconds > 0) {
                setCodeSeconds(codeSeconds - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [codeSeconds]);

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="16px">
                <CodeInput error={touched && error ? error : ''} value={value} onChange={handleChange} />
            </Section>

            <Section alignCenter justifyBetween>
                {token && phone && (
                    <TextButton buttonType="primary" disabled={codeSeconds > 0} onClick={resendCode}>
                        Resend Code {codeSeconds > 0 ? `: ${codeSeconds}` : ''}
                    </TextButton>
                )}

                <Button disabled={!submitIsActive} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
