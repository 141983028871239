import { transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { defaultInputStyles } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

interface SearchFormProps {
    isFocused?: boolean;
    disabled?: boolean;
}

export const SearchForm = styled.form<SearchFormProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid ${({ isFocused }) => (isFocused ? baseColors.blue : grey['600'])};
    border-radius: 35px;
    transition: border ${transitionTime} linear;

    ${({ disabled, isFocused }) =>
        !disabled &&
        css`
            &:hover {
                border-color: ${isFocused ? baseColors.blue : baseColors.white};
            }
        `};
`;

export const StyledInput = styled.input`
    ${defaultInputStyles};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    height: 40px;
    flex-grow: 1;
    padding-left: 8px;

    &:disabled {
        cursor: not-allowed;
    }

    &::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: ${grey[500]};
    }
`;
