import { InputField } from 'components/form/InputField';
import { CountryOption } from 'components/form/options/CountryOption';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { submitValidateLogInForm, validateLogInForm } from 'components/modals/LoginModal/PhoneForm/validation';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Select } from 'components/ui/Select';
import { SelectOptionValue } from 'components/ui/Select/types';
import { useStore } from 'effector-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { authEffects, authStores } from 'stores/auth';
import { forgotPasswordModal, loginModal } from 'stores/initialize.modal';
import { prepareCountryOptions } from 'utils/forms';
import { PhoneLoginFormValues, phoneLoginValues } from './constants';

const { loginFx } = authEffects;

export const PhoneForm = () => {
    const isLoading = useStore(loginFx.pending);
    const loginError = useStore(authStores.$loginError);
    const countries = useMemo(() => prepareCountryOptions(), []);
    const [selectedCountry, setSelectedCountry] = useState<SelectOptionValue | null>(null);

    const handleSelectCountry = (value: SelectOptionValue) => {
        setSelectedCountry(value);
    };

    const openForgotPassword = () => {
        loginModal.closeModal();
        forgotPasswordModal.openModal();
    };

    const onSubmit = async (values: PhoneLoginFormValues, { setErrors }: FormikHelpers<PhoneLoginFormValues>) => {
        try {
            const currentCode = countries.find(country => country.value === selectedCountry)?.code;
            const mobileNumber = `+${currentCode}${values.mobileNumber}`;
            const errors = await submitValidateLogInForm({ mobileNumber });

            if (!!Object.values(errors).length) {
                setErrors(errors);
            } else {
                loginFx({
                    ...values,
                    mobileNumber
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Formik validateOnChange initialValues={phoneLoginValues} validate={validateLogInForm} onSubmit={onSubmit}>
            {({ handleSubmit, isValid, dirty }: FormikProps<PhoneLoginFormValues>) => (
                <FormWrapper onSubmit={handleSubmit}>
                    <Section marginBottom="24px">
                        <InputField
                            addonBefore={
                                <Select
                                    hideBorder
                                    hideOnChange
                                    items={countries}
                                    optionsWidth={isMobile ? '200px' : '260px'}
                                    placeholder="Add code"
                                    renderOption={CountryOption}
                                    value={selectedCountry || ''}
                                    onChange={handleSelectCountry}
                                />
                            }
                            error={loginError || undefined}
                            label="Phone number"
                            name="mobileNumber"
                            placeholder="Please enter your phone number"
                            type="tel"
                        />
                    </Section>
                    <Section marginBottom="8px">
                        <InputField
                            error={loginError || undefined}
                            label="Password"
                            name="password"
                            placeholder="Please enter your password"
                            type="password"
                        />
                    </Section>

                    <Section justifyEnd marginBottom="32px">
                        <TextButton onClick={openForgotPassword}>Forgot Password?</TextButton>
                    </Section>
                    <Button wide disabled={!isValid || !dirty || isLoading} type="submit">
                        Next
                    </Button>
                </FormWrapper>
            )}
        </Formik>
    );
};
