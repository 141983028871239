import { CustomImg } from 'components/common/CustomImg';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

export interface SlideProps {
    imgSrc: string;
    mobileSrc: string;
    title: string;
    description: string;
}

export const Slide: FC<SlideProps> = ({ imgSrc, mobileSrc, title, description }) => (
    <Column alignCenter width="100%">
        <Section marginBottom="12px">
            <CustomImg alt={title} src={isMobile ? mobileSrc : imgSrc} width="100%" />
        </Section>
        <Section justifyCenter marginBottom="12px">
            <Text alignTextCenter isBold>
                {title}
            </Text>
        </Section>
        <Section justifyCenter>
            <Text alignTextCenter size="-2">
                {description}
            </Text>
        </Section>
    </Column>
);
