import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Image = styled.img`
    width: 400px;
    height: 80px;
    margin-bottom: 16px;
    object-fit: cover;

    ${media.laptop(`
        margin-bottom: 12px;
    `)};
`;

export const TextWrapper = styled(Section)`
    margin-bottom: 16px;

    ${media.laptop(`
        margin-bottom: 12px;
        padding-left: 10px;
    `)};
`;
