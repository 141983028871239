import { UndefString } from 'types';

export const videoPlayingEvent = (id: UndefString) =>
    window.dataLayer &&
    window.dataLayer.push({
        event: 'playing',
        category: 'video',
        action: 'video_play',
        label: id,
        value: ''
    });

export const videoLoadedEvent = (id: UndefString) =>
    window.dataLayer &&
    window.dataLayer.push({
        event: 'loadedmetadata',
        category: 'video',
        action: 'video_loads',
        label: id,
        value: ''
    });

export const videoSoundStateEvent = (isMute: boolean) =>
    window.dataLayer &&
    window.dataLayer.push({
        event: 'click',
        category: 'video',
        action: 'video_sound',
        label: isMute ? 'off' : 'on',
        value: ''
    });

const clickOnDownloadAppEvent = (label: 'app store' | 'google play') =>
    window.dataLayer &&
    window.dataLayer.push({
        event: 'click',
        category: 'download click',
        action: 'badge_click',
        label,
        value: ''
    });

export const handleClickAppStoreApp = () => clickOnDownloadAppEvent('app store');
export const handleClickGooglePlayApp = () => clickOnDownloadAppEvent('google play');
