import { baseColors } from 'constants/styles/colors';
import { scrollBarMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    width: 350px;
    max-height: 70vh;
    padding: 8px;
    z-index: 109;
    border-radius: 8px;
    background-color: ${baseColors.darkGrey};
    overflow-y: auto;
    overflow-x: hidden;
    ${scrollBarMixin};
`;

export const DropDownWrapper = styled.div`
    position: relative;
`;

export const DarkBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
`;
