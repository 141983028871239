import { Column } from 'components/grid/wrappers/FlexWrapper';
import { disableDefaultButtonStyleMixin, transitionTime } from 'constants/styles';
import { baseColors, grey } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding-bottom: 16px;

    ${media.laptop(`
        padding-bottom: 24px;
    `)};
`;

interface Props {
    hasBorder?: boolean;
}

export const IconWrapper = styled.div<Props>`
    padding-left: 19px;
    padding-right: 19px;
    cursor: pointer;
    ${({ hasBorder }) => hasBorder && `border-left: 1px solid ${grey[600]}`};
`;

export const ClickableWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px 8px;
    border-radius: 50px;
    background-color: ${baseColors.black};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${baseColors.darkGrey};
    }
`;
