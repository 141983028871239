import { BellFilledSmIcon } from 'assets/icons';
import { DarkBackground, DropDownWrapper, Wrapper } from 'components/pages/notifications/DropdownNotifications/styles';
import { NotificationsTabs } from 'components/pages/notifications/NotificationsTabs';
import { IconButton } from 'components/ui/buttons/IconButton';
import { useStore } from 'effector-react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import React, { useRef } from 'react';
import { notificationsEvents, notificationsStores } from 'stores/notifications';

const { toggleDropdown, closeDropdown } = notificationsEvents;
const { $dropdownVisible } = notificationsStores;

export const DropdownNotifications = () => {
    const ref = useRef(null);

    const isVisible = useStore($dropdownVisible);

    const toggleVisible = () => {
        toggleDropdown();
    };

    useOutsideClick(ref, () => closeDropdown());

    return (
        <DropDownWrapper>
            <IconButton onClick={toggleVisible}>
                <BellFilledSmIcon />
            </IconButton>

            {isVisible && (
                <>
                    <Wrapper ref={ref}>
                        <NotificationsTabs isDropdown />
                    </Wrapper>
                    <DarkBackground />
                </>
            )}
        </DropDownWrapper>
    );
};
