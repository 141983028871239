import { defaultFollowersQueryParams } from 'constants/services/followers';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

//Events
const resetStoresFollowers = createEvent();
const updateFollowers = createEvent<BULLZ.QueryFollowersResponse>();

//Effects
const getUserFollowersFx = createEffect({
    handler: async (id: string) => await API.followers.getUserFollowers({ ...defaultFollowersQueryParams, userId: id })
});

const loadMoreFollowersFx = createEffect({
    handler: async (data: BULLZ.QueryFollowersRequest) => await API.followers.getUserFollowers(data)
});

//Stores
const $userFollowers = createStore<BULLZ.QueryFollowersResponse>({})
    .on([getUserFollowersFx.doneData, updateFollowers], (_, newState) => newState)
    .on(loadMoreFollowersFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStoresFollowers);

const $hasErrorFollowers = createStore<boolean>(false)
    .on(getUserFollowersFx.fail, () => true)
    .on(loadMoreFollowersFx.done, () => true)
    .reset([getUserFollowersFx.done, loadMoreFollowersFx.done, resetStoresFollowers]);

//Exports

export const userFollowersEvents = { resetStoresFollowers, updateFollowers };
export const userFollowersEffects = { getUserFollowersFx, loadMoreFollowersFx };
export const userFollowersStores = { $userFollowers, $hasErrorFollowers };
