export const defaultTeamVideosQueryParams: BULLZ.QueryTeamVideosRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};

export const defaultTeamMembersQueryParams: BULLZ.QueryMembersRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};
