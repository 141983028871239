import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { media } from 'constants/styles/media';
import { TrendingProps } from 'pages/Trending';
import styled from 'styled-components';

const sideWidth = 230;
const sideMargin = 16;

export const VideosWrapper = styled(Column)<TrendingProps>`
    flex-wrap: nowrap;
    width: ${({ isSideBar }) => (isSideBar ? `calc(100% - ${sideWidth + sideMargin}px);` : '100%;')};
`;

export const SideWrapper = styled(Column)`
    width: ${sideWidth}px;
    margin-left: ${sideMargin}px;
`;

export const CreatorSliderWrapper = styled(Section)`
    margin-bottom: 28px;

    ${media.laptop(`
        margin-bottom: 36px;
    `)}
`;
