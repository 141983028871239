import { JoinCardProps } from 'components/modals/QSModal/components/JoinCard';
import { combine, createEvent, createStore } from 'effector';
import { InfoModal, MyQsModalType, ReportVideo, TeamModal } from 'types';

const initializeGenericStoreModal = <T>(initState: T = {} as T) => {
    const openModal = createEvent<T>();
    const closeModal = createEvent();

    const visible = createStore(false)
        .on(openModal, () => true)
        .on(closeModal, () => false);

    const state = createStore<T>(initState)
        .on(openModal, (_, newState) => newState)
        .reset(closeModal);

    const modal = combine(visible, state);

    return { modal, openModal, closeModal };
};

export const infoModal = initializeGenericStoreModal<InfoModal>();
export const loginModal = initializeGenericStoreModal<undefined>();
export const registerModal = initializeGenericStoreModal<{ referralCode?: string }>();
export const additionalRegisterModal = initializeGenericStoreModal<undefined>();
export const mainLoader = initializeGenericStoreModal<undefined>();
export const walletInfoModal = initializeGenericStoreModal<undefined>();
export const qexWidgetModal = initializeGenericStoreModal<undefined>();
export const reportVideoModal = initializeGenericStoreModal<ReportVideo>();
export const reportCommentModal = initializeGenericStoreModal<{ commentId?: string; parentId?: string }>();
export const walletGuideModal = initializeGenericStoreModal<undefined>();
export const uploadOptionModal = initializeGenericStoreModal<undefined>();
export const createWalletPinModal = initializeGenericStoreModal<undefined>();
export const verifyWalletPinModal = initializeGenericStoreModal<undefined>();
export const forgotWalletPinModal = initializeGenericStoreModal<undefined>();
export const createOrEditTeamModal = initializeGenericStoreModal<TeamModal>();
export const uploadVideoModal = initializeGenericStoreModal<undefined>();
export const qsSubscribeModal = initializeGenericStoreModal<Omit<JoinCardProps, 'onJoin'>>();
export const qsGuideModal = initializeGenericStoreModal<undefined>();
export const uploadQsModal = initializeGenericStoreModal<undefined>();
export const myQsModal = initializeGenericStoreModal<MyQsModalType>();
export const giftPurchaseDoneModal = initializeGenericStoreModal<BULLZ.VoucherTransactionResponse>();
export const forgotPasswordModal = initializeGenericStoreModal<undefined>();
export const submitAlertModal = initializeGenericStoreModal<{ collaboration: BUCM.ActiveCollaborationResponse }>();
export const uploadCollaborationModal = initializeGenericStoreModal<{ collaborationId: string }>();
export const collaborationGuideModal = initializeGenericStoreModal<undefined>();
export const shareLinkModal = initializeGenericStoreModal<{
    collaborationNetworks: BUCM.TiltedPublishingNetworkRequirementWithUsdValue[];
}>();
export const submissionTimerModal = initializeGenericStoreModal<undefined>();
export const keyboardShortcutModal = initializeGenericStoreModal<undefined>();
export const reportUserModal = initializeGenericStoreModal<{ userId: string }>();
