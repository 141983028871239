import defaultImg from 'assets/defaults/default_img.svg';
import playImg from 'assets/img/play.svg';
import { Image } from 'components/common/imageComponents/Image';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import ReactHlsPlayer from 'react-hls-player';
import { playImgDiameter } from 'constants/videoCard';
import { FeaturesWrapper, PreviewImage, Views, WrapperCard } from './styles';

interface Props {
    viewCount?: number | null;
    thumbnailUrl?: string | null;
    id?: string | null;
    videoSrc?: string | null;
    isActive?: boolean;
    onClick?: (id?: string | null) => void;
    onMouseEnter?: (id?: string | null) => void;
    onMouseLeave?: (id?: string | null) => void;
}

export const PreviewVideoCard: FC<Props> = ({
    viewCount,
    thumbnailUrl,
    id,
    videoSrc,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isActive = false
}) => {
    const playerRef = useRef<HTMLVideoElement>(null);

    const handleClick = () => {
        onClick && onClick(id);
    };

    const handleMouseEnter = () => {
        if (!isMobile && onMouseEnter) {
            onMouseEnter(id);
        }
    };

    const handleMouseLeave = () => {
        if (!isMobile && onMouseLeave) {
            onMouseLeave(id);
        }
    };

    return (
        <WrapperCard onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isActive && videoSrc ? (
                <ReactHlsPlayer
                    autoPlay
                    loop
                    muted
                    height="auto"
                    playerRef={playerRef}
                    poster={thumbnailUrl || undefined}
                    src={videoSrc}
                    width="100%"
                />
            ) : (
                <PreviewImage alt="Preview" src={thumbnailUrl || defaultImg} />
            )}

            <FeaturesWrapper>
                <Views>
                    <Image height={playImgDiameter} src={playImg} width={playImgDiameter} />
                    <MarginWrapper marginLeft="6px">
                        <Text size="-2">{viewCount || '0'}</Text>
                    </MarginWrapper>
                </Views>
            </FeaturesWrapper>
        </WrapperCard>
    );
};
