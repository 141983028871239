import { ChevronDownBgLgIcon, ChevronUpBgLgIcon, CloseBgMdIcon, InfoSmIcon } from 'assets/icons';
import { PlayerCard } from 'components/cards/PlayerCard';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Comments } from 'components/video/FullscreenVideo/components/Comments';
import { VideoInfo } from 'components/video/FullscreenVideo/components/VideoInfo';
import {
    disclaimerButtonText,
    disclaimerText,
    disclaimerTextForCollaboration,
    disclaimerTitle
} from 'constants/messages';
import { AppRoute } from 'constants/routes';
import { KeyboardCode } from 'constants/special';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useKeyboard } from 'hooks/useKeyboard';
import React, { FC, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { fullscreenVideoEffects, fullscreenVideoEvents, fullscreenVideoStores } from 'stores/fullscreen-video';
import { infoModal } from 'stores/initialize.modal';
import { MobileSlider } from './components/MobileSlider';
import {
    ArrowsWrapper,
    CloseButtonWrapper,
    Divider,
    FullscreenVideoWrapper,
    InfoButtonWrapper,
    InfoWrapper,
    PlayerWrapper,
    VideoWrapper
} from './styles';
import { PlaylistType, PlaylistValues } from './types';
import { getPlaylistValues } from './utils';

const { openModal: openDisclaimerModal } = infoModal;

const { closeFullscreenVideo, resetStores } = fullscreenVideoEvents;
const { getVideoByIdFx, getVideoValidationFx } = fullscreenVideoEffects;
const { $video, $currentPlaylistVideos } = fullscreenVideoStores;

interface Props {
    videoId: string;
    playlistType?: PlaylistType;
}

export const FullscreenVideo: FC<Props> = ({ videoId, playlistType = 'profile' }) => {
    const history = useHistory();

    const video = useStore($video);
    const currentPlaylistVideos = useStore($currentPlaylistVideos);
    const isLoading = useStore(getVideoByIdFx.pending);
    const validationIsLoading = useStore(getVideoValidationFx.pending);

    const streamingInfo = useMemo(
        () => ({
            videoSrc: video.streaming?.hlsUrl,
            posterSrc: video.streaming?.screenGrabUrl
        }),
        [video]
    );

    const playlistValues: PlaylistValues = useMemo(() => {
        if (currentPlaylistVideos.length > 0 && video.id) {
            return getPlaylistValues(currentPlaylistVideos, video.id);
        }

        return {
            prev: [],
            next: []
        };
    }, [currentPlaylistVideos, video.id]);

    const handleInfoClick = () => {
        openDisclaimerModal({
            title: disclaimerTitle,
            text: video?.isInCollaboration ? disclaimerTextForCollaboration : disclaimerText,
            buttonText: disclaimerButtonText
        });
    };

    const close = () => {
        switch (playlistType) {
            case 'profile':
            default:
                history.replace(video.ownerId ? `${AppRoute.Profile}/${video.ownerId}` : AppRoute.Main);
                break;
            case 'trending':
                history.replace(AppRoute.Trending);
                break;
            case 'recommendations':
                history.replace(AppRoute.Main);
                break;
            case 'saved':
                history.replace(AppRoute.SavedVideos);
                break;
            case 'search':
            case 'teams':
            case 'notifications':
                // TODO come up with a better solution
                history.goBack();
                break;
        }

        closeFullscreenVideo();
    };

    const goToNextVideo = () => {
        if (playlistValues && playlistValues.next.length > 0) {
            history.replace(`${AppRoute.Video}/${playlistValues.next[0]}?playlistType=${playlistType}`);
        }
    };

    const goToPrevVideo = () => {
        if (playlistValues && playlistValues.prev.length > 0) {
            history.replace(`${AppRoute.Video}/${playlistValues.prev[0]}?playlistType=${playlistType}`);
        }
    };

    useEffect(() => {
        getVideoByIdFx(videoId);

        return () => resetStores();
    }, [videoId]);

    useKeyboard({
        [KeyboardCode.Escape]: () => close(),
        [KeyboardCode.ArrowDown]: () => goToNextVideo(),
        [KeyboardCode.ArrowUp]: () => goToPrevVideo()
    });

    if (!videoId) {
        return null;
    }

    return (
        <FullscreenVideoWrapper>
            {isMobile ? (
                <MobileSlider
                    currentVideo={video}
                    isLoading={isLoading}
                    playlist={playlistValues}
                    onClose={close}
                    onSlideNext={goToNextVideo}
                    onSlidePrev={goToPrevVideo}
                />
            ) : (
                <>
                    <PlayerWrapper>
                        <CloseButtonWrapper>
                            <IconButton color={grey['900']} hoverColor={grey['800']} size="40px" onClick={close}>
                                <CloseBgMdIcon />
                            </IconButton>
                        </CloseButtonWrapper>

                        <VideoWrapper>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <InfoButtonWrapper>
                                        <IconButton color={grey['500']} onClick={handleInfoClick}>
                                            <InfoSmIcon />
                                        </IconButton>
                                    </InfoButtonWrapper>

                                    <PlayerCard {...streamingInfo} isFullscreen videoId={video.id} />
                                </>
                            )}
                        </VideoWrapper>

                        {currentPlaylistVideos.length > 0 && (
                            <ArrowsWrapper>
                                <MarginWrapper marginBottom="4px">
                                    {playlistValues && playlistValues.prev.length > 0 && (
                                        <IconButton
                                            color={grey['500']}
                                            disabled={playlistValues.prev.length === 0}
                                            size="52px"
                                            onClick={goToPrevVideo}
                                        >
                                            <ChevronUpBgLgIcon height={52} width={52} />
                                        </IconButton>
                                    )}
                                </MarginWrapper>

                                <MarginWrapper marginTop="4px">
                                    {playlistValues && playlistValues.next.length > 0 && (
                                        <IconButton
                                            color={grey['500']}
                                            disabled={playlistValues.next.length === 0}
                                            size="52px"
                                            onClick={goToNextVideo}
                                        >
                                            <ChevronDownBgLgIcon height={52} width={52} />
                                        </IconButton>
                                    )}
                                </MarginWrapper>
                            </ArrowsWrapper>
                        )}
                    </PlayerWrapper>
                    <InfoWrapper>
                        <VideoInfo isLoading={isLoading || validationIsLoading} video={video} />
                        <Divider />
                        <Comments videoId={videoId} />
                    </InfoWrapper>
                </>
            )}
        </FullscreenVideoWrapper>
    );
};
