import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Container = styled.div`
    ${flexCenter};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
