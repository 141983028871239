import { ExternalNetworkKey } from 'constants/defaults/collaborations';

export const sizes = {
    [ExternalNetworkKey.Instagram]: {
        small: {
            w: 10,
            h: 10
        },
        large: {
            w: 18,
            h: 18
        }
    },
    [ExternalNetworkKey.Twitter]: {
        small: {
            w: 12,
            h: 10
        },
        large: {
            w: 22,
            h: 18
        }
    },
    [ExternalNetworkKey.Tiktok]: {
        small: {
            w: 14,
            h: 14
        },
        large: {
            w: 18,
            h: 18
        }
    },
    [ExternalNetworkKey.Twitch]: {
        small: {
            w: 12,
            h: 10
        },
        large: {
            w: 20,
            h: 20
        }
    },
    [ExternalNetworkKey.Youtube]: {
        small: {
            w: 15,
            h: 10
        },
        large: {
            w: 21,
            h: 15
        }
    }
};
