import { CollaborationStatusesUi, ParticipationState } from 'constants/defaults/collaborations';

export enum CollaborationUserStateUI {
    None,
    Requested,
    VideoRequested,
    Submit,
    Share,
    CheckResult
}

export const messages = {
    [CollaborationUserStateUI.None]: '',
    [CollaborationUserStateUI.Requested]: 'Requested',
    [CollaborationUserStateUI.VideoRequested]: 'Video not approved',
    [CollaborationUserStateUI.Submit]: 'Submit',
    [CollaborationUserStateUI.Share]: 'Share',
    [CollaborationUserStateUI.CheckResult]: 'Check result'
};

export const getCollaborationUserStatus = (
    state?: BUCM.ParticipationState,
    collaborationState?: BUCM.CollaborationState,
    video?: BUCM.MediaAssetInfo | null,
    autoApproveMedia?: boolean,
    networks?: BUCM.TiltedPublishingNetworkRequirement[] | null
) => {
    if (!state || collaborationState === undefined) return CollaborationUserStateUI.None;

    if (collaborationState === CollaborationStatusesUi.Ended) {
        return CollaborationUserStateUI.CheckResult;
    }

    if (state === ParticipationState.Enrolled) {
        return CollaborationUserStateUI.Submit;
    }

    if (state === ParticipationState.EnrollmentRequested) {
        return CollaborationUserStateUI.Requested;
    }

    if (!autoApproveMedia && video && video.isRejected === null) {
        return CollaborationUserStateUI.VideoRequested;
    }

    if (state === ParticipationState.Submitted) {
        if (networks && networks.length > 1) {
            return CollaborationUserStateUI.Share;
        }

        return CollaborationUserStateUI.CheckResult;
    }

    if (state === ParticipationState.Published || state === ParticipationState.Ended) {
        return CollaborationUserStateUI.CheckResult;
    }

    return CollaborationUserStateUI.None;
};
