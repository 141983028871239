import React, { FC } from 'react';
import { HTMLButtonType } from 'types';
import { ReactClick } from 'types/react';
import { StyledButton } from './styles';

export interface SelectButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType {
    wide?: boolean;
    selected?: boolean;
}

export const SelectButton: FC<SelectButtonProps> = ({ children, type = 'button', ...props }) => (
    <StyledButton {...props} type={type}>
        {children}
    </StyledButton>
);
