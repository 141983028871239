import { InstagramSmIcon, TiktokLogoSmIcon, TwitchSmIcon, TwitterSmIcon, YoutubeSmIcon } from 'assets/icons';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';
import { baseColors } from 'constants/styles';
import React, { FC } from 'react';
import { sizes } from './constants';

interface Props {
    name?: string | null;
    size?: 'small' | 'large';
    color?: string;
}

export const NetworkIcon: FC<Props> = ({ name, size = 'small', color = baseColors.white }) => {
    if (!name) {
        return null;
    }

    switch (name.toLowerCase()) {
        case ExternalNetworkKey.Instagram:
            return (
                <InstagramSmIcon
                    color={color}
                    height={sizes[ExternalNetworkKey.Instagram][size].h}
                    width={sizes[ExternalNetworkKey.Instagram][size].w}
                />
            );
        case ExternalNetworkKey.Twitter:
            return (
                <TwitterSmIcon
                    color={color}
                    height={sizes[ExternalNetworkKey.Twitter][size].h}
                    width={sizes[ExternalNetworkKey.Twitter][size].w}
                />
            );
        case ExternalNetworkKey.Tiktok:
            return (
                <TiktokLogoSmIcon
                    color={color}
                    height={sizes[ExternalNetworkKey.Tiktok][size].h}
                    width={sizes[ExternalNetworkKey.Tiktok][size].w}
                />
            );
        case ExternalNetworkKey.Youtube:
            return (
                <YoutubeSmIcon
                    color={color}
                    height={sizes[ExternalNetworkKey.Youtube][size].h}
                    width={sizes[ExternalNetworkKey.Youtube][size].w}
                />
            );
        case ExternalNetworkKey.Twitch:
            return (
                <TwitchSmIcon
                    color={color}
                    height={sizes[ExternalNetworkKey.Twitch][size].h}
                    width={sizes[ExternalNetworkKey.Twitch][size].w}
                />
            );
        default:
            return null;
    }
};
