import { HashtagSmIcon, MembersSmIcon, SaveSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { authEffects, authStores } from 'stores/auth';
import { createOrEditTeamModal, walletGuideModal } from 'stores/initialize.modal';
import { savedVideoEffects, savedVideoStores } from 'stores/saved-video';
import { userEffects, userStores } from 'stores/user';
import { walletStores } from 'stores/wallet';
import { CreatingBlockPage } from '../CreatingBlockPage';
import { ButtonWrapper, CreateWalletWrapper, CreatingTeamWrapper, ImageWrapper, RowWrapper } from './styles';

const { $savedVideos } = savedVideoStores;
const { getSavedVideosFx } = savedVideoEffects;

const { getUserHashtagsFx } = userEffects;
const { $userHashtags } = userStores;

const { $isAuth, $user } = authStores;
const { $hasWallet, $womUserIsLoaded } = walletStores;

const { getCurrentUserFx } = authEffects;

interface Props {
    isDropdown?: boolean;
}

export const NotificationsActivity: FC<Props> = ({ isDropdown = false }) => {
    const history = useHistory();

    const savedVideos = useStore($savedVideos);
    const userHashtags = useStore($userHashtags);
    const isAuth = useStore($isAuth);
    const womUserIsLoaded = useStore($womUserIsLoaded);
    const hasWallet = useStore($hasWallet);
    const user = useStore($user);

    const isAvailableDoTeam = user && user?.isTrusted && user.team?.ownerId !== user?.userId && isAuth;
    const isUserEnteredTeam = user?.team && user.team.ownerId !== user?.userId;
    const isHaveTeam = user?.team?.ownerId === user?.userId;

    const isVisibleWalletBlock = isAuth && womUserIsLoaded && !hasWallet;

    const goToSavedVideo = () => {
        history.push(AppRoute.SavedVideos);
    };

    const goToHashtags = () => {
        history.push(AppRoute.FollowedHashtags);
    };

    const goToMembers = () => {
        const userId = user?.userId;
        const teamId = user?.team?.id;
        if (teamId && userId) {
            history.push(`${AppRoute.Team}/${teamId}?userId=${userId}&lastPage=notifications`);
        }
    };

    const createTeam = () => {
        createOrEditTeamModal.openModal({});
    };

    const handleCrateWallet = () => {
        walletGuideModal.openModal();
    };

    useEffect(() => {
        getSavedVideosFx();
        getUserHashtagsFx();
        getCurrentUserFx();
    }, []);

    return (
        <Column height="100%">
            {isVisibleWalletBlock &&
                (!isDropdown ? (
                    <Section marginBottom="4px">
                        <CreatingBlockPage
                            text="Create a WOM wallet to receive, store, and send WOM Tokens."
                            textButton="Get Started"
                            title="Create Your Wallet"
                            onClick={handleCrateWallet}
                        />
                    </Section>
                ) : (
                    <CreateWalletWrapper>
                        <Section marginBottom="8px">
                            <Text isBold size="-1">
                                Create Your Wallet
                            </Text>
                        </Section>
                        <Section marginBottom="12px">
                            <Text size="-2">Create a WOM wallet to receive, store, and send WOM Tokens.</Text>
                        </Section>
                        <Section>
                            <Button wide isBold={false} size="small" onClick={handleCrateWallet}>
                                Get Started
                            </Button>
                        </Section>
                    </CreateWalletWrapper>
                ))}

            {isAvailableDoTeam &&
                (!isDropdown ? (
                    <Section marginBottom="16px">
                        <CreatingBlockPage
                            disabled={isUserEnteredTeam}
                            text={
                                isUserEnteredTeam
                                    ? 'Congratulations! Your account is now verified and you can create a team.  Please first leave the current team to create your own!'
                                    : 'Congratulations! Your account is now verified and you can create a team.'
                            }
                            textButton="Create Team"
                            title="You’re verified"
                            onClick={createTeam}
                        />
                    </Section>
                ) : (
                    <Column>
                        <CreatingTeamWrapper>
                            <Section marginBottom="8px">
                                <Text isBold size="-1">
                                    You’re verified!
                                </Text>
                            </Section>
                            <Section marginBottom="12px">
                                <Text size="-2">
                                    {isUserEnteredTeam
                                        ? 'Congratulations! Your account is now verified and you can create a team.  Please first leave the current team to create your own!'
                                        : 'Congratulations! Your account is now verified and you can create a team.'}
                                </Text>
                            </Section>
                        </CreatingTeamWrapper>
                        <ButtonWrapper>
                            <Button
                                wide
                                disabled={isUserEnteredTeam}
                                isBold={false}
                                textSize="small"
                                onClick={createTeam}
                            >
                                Create team
                            </Button>
                        </ButtonWrapper>
                    </Column>
                ))}

            <Section marginBottom="8px">
                <RowWrapper onClick={goToSavedVideo}>
                    <ImageWrapper>
                        <SaveSmIcon />
                    </ImageWrapper>
                    <Section marginLeft="16px">
                        {savedVideos && <Text size="-1">You have {savedVideos.totalRecords || 0} videos saved</Text>}
                    </Section>
                </RowWrapper>
            </Section>

            <Section marginBottom="8px">
                <RowWrapper onClick={goToHashtags}>
                    <ImageWrapper>
                        <HashtagSmIcon />
                    </ImageWrapper>
                    <Section marginLeft="16px">
                        {userHashtags && <Text size="-1">You follow {userHashtags.totalRecords || 0} hashtag</Text>}
                    </Section>
                </RowWrapper>
            </Section>

            {isHaveTeam && (
                <Section marginBottom="8px">
                    <RowWrapper onClick={goToMembers}>
                        <ImageWrapper>
                            <MembersSmIcon />
                        </ImageWrapper>
                        <Section marginLeft="16px">
                            {user && <Text size="-1">You have {user.ownedTeam?.membersCount || 0} members</Text>}
                        </Section>
                    </RowWrapper>
                </Section>
            )}
        </Column>
    );
};
