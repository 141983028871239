import { Row } from 'components/grid/wrappers/FlexWrapper';
import { baseColors } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Warning = styled(Row)`
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 11px 13px;
    background-color: ${baseColors.darkGrey};

    ${media.laptop(`
        background-color: ${baseColors.black};
    `)};
`;
