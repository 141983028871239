import { Header } from 'components/grid/Header';
import { CollaborationFooter } from 'components/layouts/CollaborationLayout/CollaborationFooter';
import { Container, Wrapper } from 'components/layouts/NewMainLayout/styles';
import { useUploadClick } from 'hooks/useUploadClick';
import React, { FC, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
    isHidePaddingX?: boolean;
    footer: ReactNode;
}

export const CollaborationLayout: FC<Props> = ({ children, isHidePaddingX, footer }) => {
    const handleUploadClick = useUploadClick();

    return (
        <>
            <Header handleUploadClick={handleUploadClick} />
            <Wrapper isHidePaddingX={isHidePaddingX}>
                <Container isHideSideBar isMobile={isMobile}>
                    {children}
                </Container>
            </Wrapper>
            <CollaborationFooter>{footer}</CollaborationFooter>
        </>
    );
};
