import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles/colors';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { AvatarWrapper, ButtonsWrapper, NameWrapper, TagsWrapper, WebsiteWrapper } from './styles';

export const VideoInfoSkeleton = () => (
    <SkeletonTheme baseColor={grey[900]} highlightColor={grey[800]}>
        <Column noWrap width="100%">
            <Section alignCenter>
                <AvatarWrapper>
                    <Skeleton borderRadius={50} height="100%" />
                </AvatarWrapper>

                <NameWrapper>
                    <Skeleton borderRadius={4} height="100%" />
                </NameWrapper>
            </Section>

            <ButtonsWrapper>
                <Skeleton borderRadius={4} height="100%" />
                <Skeleton borderRadius={4} height="100%" />
                <Skeleton borderRadius={4} height="100%" />
            </ButtonsWrapper>

            <TagsWrapper>
                <Skeleton borderRadius={30} height="100%" />
                <Skeleton borderRadius={30} height="100%" />
                <Skeleton borderRadius={30} height="100%" />
            </TagsWrapper>

            <WebsiteWrapper>
                <Skeleton borderRadius={50} height="100%" />
            </WebsiteWrapper>
        </Column>
    </SkeletonTheme>
);
