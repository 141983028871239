import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { baseColors } from 'constants/styles';
import styled from 'styled-components';

export const ModalBackdrop = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled(Column)`
    width: 100%;
    height: 80vh;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 51;
    overflow: hidden auto;
    background-color: ${baseColors.black};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const ModalHeader = styled(Section)`
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
`;
