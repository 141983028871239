import { Section } from 'components/grid/wrappers/FlexWrapper';
import { baseColors } from 'constants/styles/colors';
import styled from 'styled-components';

export const TextSection = styled(Section)`
    text-align: center;
    justify-content: center;
`;

export const ProfileImageLeftWrapper = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
`;

export const ProfileImageRightWrapper = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 2;
    border: 2px solid ${baseColors.white};
    position: absolute;
    object-fit: cover;
    top: 0;
    left: -20px;
`;

export const ImagesWrapper = styled.div`
    position: relative;
    height: 80px;
`;

export const ImageWrapper = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
`;

export const QsWrapper = styled.div`
    position: relative;
    display: inline-flex;
`;

export const QsImage = styled.img`
    position: absolute;
    right: 0;
    transform: translateX(calc(100% - 13px));
    z-index: -1;
    width: 64px;
`;
