import { InputField } from 'components/form/InputField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { Dispatch, FC, FormEvent, SetStateAction, useEffect, useMemo } from 'react';
import { registerEffects, registerEvents, registerStores } from 'stores/register';
import { maxUsernameLength, minUsernameLength, RegisterFieldName, Step } from '../constants';
import { RulesChecklist } from '../RulesChecklist';

const { $usernameIsAvailable } = registerStores;
const { checkUsernameExistenceFx } = registerEffects;
const { resetUsernameIsAvailable } = registerEvents;

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
}

export const UsernameStep: FC<Props> = ({ setCurrentStep }) => {
    const [, { value, error }] = useField<string>(RegisterFieldName.Username);
    const usernameIsAvailable = useStore($usernameIsAvailable);
    const checkIsLoading = useStore(checkUsernameExistenceFx.pending);

    const checklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '3 to 40 characters',
                isSuccess: value.length >= minUsernameLength && value.length <= maxUsernameLength,
                isLoading: false
            },
            {
                title: 'Username is available',
                isSuccess: !!value && usernameIsAvailable,
                isLoading: checkIsLoading
            }
        ],
        [checkIsLoading, usernameIsAvailable, value]
    );

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (error) {
            return;
        }

        setCurrentStep(Step.Password);
    };

    useEffect(() => () => resetUsernameIsAvailable(), []);

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="16px">
                <InputField
                    name={RegisterFieldName.Username}
                    placeholder="Add username here"
                    prefix={
                        <Text color={grey[500]} size="-1">
                            @
                        </Text>
                    }
                />
            </Section>

            <Section marginBottom="24px">
                <RulesChecklist items={checklist} />
            </Section>

            <Section>
                <Button wide disabled={!!error} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
