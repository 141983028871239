import { WrongLgIcon } from 'assets/icons';
import { asyncErrorMessage } from 'constants/messages';
import { defaultQsSubscriptionsParams } from 'constants/services/quick-signals';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { infoModal } from 'stores/initialize.modal';
import { LimitPageIndex } from 'types/interfaces/data';
import { goToHelpDesk, noop } from 'utils/common';

// Events

const resetStores = createEvent();

// Effects

const checkTitleFx = createEffect({
    handler: async (title: string) => await API.quickSignals.checkTitle({ title })
});

const createChannelFx = createEffect({
    handler: async (data: BULLZ.CreateQuickSignalChannelRequest) => await API.quickSignals.createChannel(data)
});

const createChannelFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text:
                'There was a problem creating your Quick Signal Channel. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

interface EditChannelParams extends BULLZ.UpdateQuickSignalChannelRequest {
    channelId: string;
}

const editChannelFx = createEffect({
    handler: async (data: EditChannelParams) =>
        await API.quickSignals.editChannel(data.channelId, {
            name: data.name,
            description: data.description
        })
});

const editChannelFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text:
                'There was a problem updating your Quick Signal Channel. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

export const getSubscriptionsFx = createEffect({
    handler: async (params?: LimitPageIndex) => {
        const { data } = await API.quickSignals.getQuickSignalSubscriptions(params || defaultQsSubscriptionsParams);
        return data || null;
    }
});

export const getChannelInfoFx = createEffect({
    handler: async (channelId: string) => {
        const { data } = await API.quickSignals.getChannel(channelId);
        return data || null;
    }
});

// Stores

const $titleIsAvailable = createStore<boolean>(false)
    .on(checkTitleFx.doneData, (_, data) => !!data.data)
    .reset(resetStores);

const $subscriptions = createStore<BULLZ.GetMySubscriptionsResponse | null>(null)
    .on(getSubscriptionsFx.doneData, (state, payload) => {
        if (!payload) return state;

        return {
            ...state,
            ...payload,
            items: [...(state?.items || []), ...(payload.items || [])]
        };
    })
    .reset(resetStores);

const $hasSubscriptionsError = createStore<boolean>(false)
    .on(getSubscriptionsFx.fail, () => true)
    .reset(resetStores);

const $channelInfo = createStore<BULLZ.GetMyQuickSignalSubscriptionInfoResponse | null>(null)
    .on(getChannelInfoFx.doneData, (_, data) => data)
    .reset([resetStores, getChannelInfoFx.fail]);

// Exports

export const qsChannelEvents = {
    resetStores
};

export const qsChannelEffects = {
    checkTitleFx,
    createChannelFx,
    createChannelFailFx,
    editChannelFx,
    editChannelFailFx,
    getSubscriptionsFx,
    getChannelInfoFx
};

export const qsChannelStores = {
    $titleIsAvailable,
    $subscriptions,
    $hasSubscriptionsError,
    $channelInfo
};
