import {
    AboutSmIcon,
    AccountSmIcon,
    AttachSmIcon,
    // BellSmIcon,
    FaqSmIcon,
    FeedbackSmIcon,
    GiftboxSmIcon,
    LockDotsSmIcon,
    LockSmIcon,
    PrivacyPolicySmIcon,
    TermsSmIcon,
    XMdIcon
} from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { AboutContent } from 'components/pages/settings/AboutContent';
import { AccountSettings } from 'components/pages/settings/AccountSettings';
import { ChangePassword } from 'components/pages/settings/ChangePassword';
import { ChangeWalletPin } from 'components/pages/settings/ChangeWalletPin';
import { FeedbackContent } from 'components/pages/settings/FeedbackContent';
import { ForgotPassword } from 'components/pages/settings/ForgotPassword';
import { InviteFriends } from 'components/pages/settings/InviteFriends';
import { LinkedAccounts } from 'components/pages/settings/LinkedAccounts';
import { SettingsWrapper } from 'components/pages/settings/SettingsContent/styles';
import { SettingsTabs } from 'components/pages/settings/SettingsTabs';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Title } from 'components/ui/typography/Title';
import { bullzSiteFaqs, bullzSitePolicyUrl, bullzSiteTermsUrl } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { settingsEffects, settingsEvents, settingsStores } from 'stores/settings';
import { SettingsCategory } from './constants';

export const SettingsContent = () => {
    const [activeTab, setActiveTab] = useState(isMobile ? SettingsCategory.Null : SettingsCategory.Account);
    const closureAvailability = useStore(settingsStores.$closureAvailability);
    const history = useHistory();

    const goToBack = () => {
        history.goBack();
    };

    const handleTabsChange = (value: SettingsCategory) => {
        if (!closureAvailability) {
            settingsEffects.closeSettingsFx(() => setActiveTab(value));
            return;
        }

        setActiveTab(value);
    };

    const close = () => {
        if (!closureAvailability) {
            settingsEffects.closeSettingsFx(goToBack);
            return;
        }

        goToBack();
    };

    const clearActiveTab = () => {
        setActiveTab(SettingsCategory.Null);
        settingsEvents.setClosureAvailability(true);
    };

    useEffect(
        () => () => {
            settingsEvents.setClosureAvailability(true);
        },
        []
    );

    return (
        <SettingsWrapper>
            {!isMobile && (
                <Section alignCenter justifyBetween noWrap marginBottom="22px">
                    <Section marginLeft="16px">
                        <Title size="4">Settings</Title>
                    </Section>

                    <IconButton size="24px" onClick={close}>
                        <XMdIcon />
                    </IconButton>
                </Section>
            )}

            <SettingsTabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: SettingsCategory.Account,
                        title: 'Account',
                        icon: <AccountSmIcon />,
                        content: <AccountSettings onBack={clearActiveTab} />
                    },
                    {
                        id: SettingsCategory.LinkedAccounts,
                        title: 'Linked Accounts',
                        icon: <AttachSmIcon />,
                        content: <LinkedAccounts onClick={clearActiveTab} />
                    },
                    {
                        id: SettingsCategory.ChangePassword,
                        title: 'Change Password',
                        icon: <LockSmIcon />,
                        content: <ChangePassword onBack={clearActiveTab} onTabChange={handleTabsChange} />
                    },
                    {
                        id: SettingsCategory.ForgotPassword,
                        title: 'Forgot Password',
                        icon: <LockSmIcon />,
                        content: <ForgotPassword onTabChange={handleTabsChange} />,
                        isHidden: true
                    },
                    {
                        id: SettingsCategory.ChangeWalletPassword,
                        title: 'Change Wallet Password',
                        icon: <LockDotsSmIcon />,
                        content: <ChangeWalletPin onBack={clearActiveTab} />
                    },
                    // {
                    //     id: SettingsCategory.Notifications,
                    //     title: 'Notifications',
                    //     icon: <BellSmIcon />,
                    //     content: <Text>Notifications</Text>
                    // },
                    {
                        id: SettingsCategory.Feedback,
                        title: 'Feedback',
                        icon: <FeedbackSmIcon />,
                        content: <FeedbackContent onClick={clearActiveTab} />
                    },
                    {
                        id: SettingsCategory.FAQs,
                        title: 'FAQs',
                        icon: <FaqSmIcon />,
                        link: bullzSiteFaqs
                    },
                    {
                        id: SettingsCategory.PrivacyPolicy,
                        title: 'Privacy Policy',
                        icon: <PrivacyPolicySmIcon />,
                        link: bullzSitePolicyUrl
                    },
                    {
                        id: SettingsCategory.TermsAndConditions,
                        title: 'Terms And Conditions',
                        icon: <TermsSmIcon />,
                        link: bullzSiteTermsUrl
                    },
                    {
                        id: SettingsCategory.About,
                        title: 'About',
                        icon: <AboutSmIcon />,
                        content: <AboutContent onClick={clearActiveTab} />
                    },
                    {
                        id: SettingsCategory.InviteFriends,
                        title: 'Invite Friends',
                        icon: <GiftboxSmIcon />,
                        content: <InviteFriends onBack={clearActiveTab} />
                    }
                ]}
                onChange={handleTabsChange}
                onClose={close}
            />
        </SettingsWrapper>
    );
};
