import { grey } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const SearchContainer = styled.div`
    width: 100%;
    max-width: 100%;
    position: relative;

    ${media.laptop(`
        max-width: 360px;
    `)}
`;

export const Dropdown = styled.div`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    background-color: ${grey[900]};
    border-radius: 8px;
    height: 328px;
    z-index: 20;
    padding: 8px;
    bottom: -9px;
    transform: translateY(100%);
`;
