import {
    BullzCurationState,
    ValidationStatusUI,
    WomCurrentStage,
    WomEndedReason,
    WomProcessStatus,
    WomValidationResult
} from 'constants/services/video-statuses';

export const getValidationStatus = (validation?: BULLZ.VideoDetailsValidation | null, isInCollaboration = false) => {
    if (!validation) return ValidationStatusUI.None;

    if (validation.bullz?.curationState === BullzCurationState.Processing) {
        return ValidationStatusUI.Processing;
    }

    if (validation.bullz?.curationState === BullzCurationState.Rejected) {
        return ValidationStatusUI.Rejected;
    }

    if (
        validation.bullz?.curationState === BullzCurationState.Accepted &&
        validation.wom?.currentStage === WomCurrentStage.NotStarted
    ) {
        if (isInCollaboration) {
            return ValidationStatusUI.CollaborationLive;
        }

        return ValidationStatusUI.Submit;
    }

    if (validation.wom?.currentStage === WomCurrentStage.Processing) {
        return ValidationStatusUI.WomValidationProcessing;
    }

    if (
        validation.wom?.validationResult === WomValidationResult.RejectedByConsensus ||
        validation.wom?.endedReason === WomEndedReason.ConsensusNotReached
    ) {
        return ValidationStatusUI.Fail;
    }

    if (
        validation.wom?.currentStage === WomCurrentStage.Ended &&
        validation.wom?.processStatus === WomProcessStatus.NotProcessed &&
        validation.wom?.validationResult === WomValidationResult.NotProcessed
    ) {
        return ValidationStatusUI.ReSubmit;
    }

    if (
        validation.bullz?.curationState === BullzCurationState.Accepted &&
        validation.wom?.currentStage === WomCurrentStage.Ended &&
        validation.wom?.validationResult === WomValidationResult.AcceptedByConsensus
    ) {
        return ValidationStatusUI.Live;
    }

    return ValidationStatusUI.Live;
};

interface ExtendsPlaylistItem {
    id?: string;
    videoId?: string;
}

export const getPlaylistItems = (items: ExtendsPlaylistItem[] | null = []): string[] => {
    if (!items || items.length === 0) return [];

    return items
        .map(i => {
            if ('videoId' in i) {
                return i.videoId || '';
            }

            return i.id || '';
        })
        .filter(i => !!i);
};
