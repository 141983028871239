import defaultImg from 'assets/defaults/default_img.svg';
import { ErrorSmIcon, QuestionSmIcon, RejectedPopupLgIcon } from 'assets/icons';
import playImg from 'assets/img/play.svg';
import { ValidationStatusButton } from 'components/cards/ValidatePreviewVideoCard/ValidationStatusButton';
import { Image } from 'components/common/imageComponents/Image';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { playImgDiameter } from 'constants/videoCard';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { bullzSiteQualityGuidelines } from 'constants/routes';
import {
    BullzCurationEndedReason,
    validationStatusModalButton,
    validationStatusModalTexts,
    validationStatusModalTitles,
    ValidationStatusUI,
    videoRejectReasons
} from 'constants/services/video-statuses';
import React, { FC, MouseEvent, useCallback, useMemo, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import ReactHlsPlayer from 'react-hls-player';
import { infoModal } from 'stores/initialize.modal';
import { userVideosEffects } from 'stores/user-videos';
import { goToExternalLink, noop } from 'utils/common';
import { getValidationStatus } from 'utils/video';
import {
    FeaturesWrapper,
    InfoButton,
    PreviewImage,
    ValidationStatusWrapper,
    Views,
    WrapperCard,
    WrapperDeleting
} from './styles';

interface Props {
    video: BULLZ.GetVideoDetailsResponse;
    isActive?: boolean;
    onClick?: (id?: string | null) => void;
    onMouseEnter?: (id?: string | null) => void;
    onMouseLeave?: (id?: string | null) => void;
    isEdit?: boolean;
}

const { deleteVideoFx } = userVideosEffects;

export const ValidatePreviewVideoCard: FC<Props> = ({
    video,
    isEdit = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isActive = false
}) => {
    const playerRef = useRef<HTMLVideoElement>(null);

    const { id, streaming, engagementStatistics, validation, isInCollaboration } = video;

    const validStatus = useMemo(() => getValidationStatus(validation, isInCollaboration), [
        isInCollaboration,
        validation
    ]);
    const statusIsVisible = validStatus !== ValidationStatusUI.None;
    const viewsIsVisible = !(
        validStatus === ValidationStatusUI.Processing ||
        validStatus === ValidationStatusUI.WomValidationProcessing ||
        validStatus === ValidationStatusUI.Rejected
    );
    const infoIsVisible =
        validStatus === ValidationStatusUI.Processing ||
        validStatus === ValidationStatusUI.Submit ||
        validStatus === ValidationStatusUI.WomValidationProcessing ||
        validStatus === ValidationStatusUI.ReSubmit ||
        validStatus === ValidationStatusUI.Fail;

    const handleClick = () => {
        if (
            validStatus !== ValidationStatusUI.Processing &&
            validStatus !== ValidationStatusUI.Rejected &&
            streaming?.isReady
        ) {
            onClick && onClick(id);
        }
    };

    const handleMouseEnter = () => {
        if (!isMobile && streaming?.isReady && onMouseEnter) {
            onMouseEnter(id);
        }
    };

    const handleMouseLeave = () => {
        if (!isMobile && streaming?.isReady && onMouseLeave) {
            onMouseLeave(id);
        }
    };

    const handleOpenModalDelete = () => {
        id &&
            infoModal.openModal({
                title: 'Delete Video',
                text: 'Are you sure you want to delete this video?',
                buttonText: 'Yes',
                onClick: () => deleteVideoFx({ videoId: id }),
                onCloseClick: noop
            });
    };

    const handleInfoClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            if (validStatus === ValidationStatusUI.Rejected) {
                id &&
                    infoModal.openModal({
                        icon: RejectedPopupLgIcon,
                        title: 'Rejected',
                        text:
                            videoRejectReasons[
                                validation?.bullz?.curationEndedReason || BullzCurationEndedReason.Other
                            ],
                        buttonText: 'Delete',
                        onClick: handleOpenModalDelete,
                        cancelText: 'See Quality Guidelines',
                        onCloseClick: () => goToExternalLink(bullzSiteQualityGuidelines)
                    });
                return;
            }

            const title = validationStatusModalTitles[validStatus] || '';
            const text = validationStatusModalTexts[validStatus] || '';
            const buttonText = validationStatusModalButton[validStatus] || 'OK';

            infoModal.openModal({
                title,
                text,
                buttonText
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id, validStatus, validation]
    );

    return (
        <WrapperCard onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isActive && streaming?.details?.hlsUrl && streaming?.isReady ? (
                <ReactHlsPlayer
                    autoPlay
                    loop
                    muted
                    height="auto"
                    playerRef={playerRef}
                    poster={streaming?.details?.screenGrabUrl || undefined}
                    src={streaming.details.hlsUrl}
                    width="100%"
                />
            ) : (
                <PreviewImage alt="Preview" src={streaming?.details?.screenGrabUrl || defaultImg} />
            )}

            {isEdit && id && (
                <WrapperDeleting>
                    <IconButton size={isMobile ? '20px' : '52px'} onClick={handleOpenModalDelete}>
                        <ErrorSmIcon height={isMobile ? '20px' : '52px'} width={isMobile ? '20px' : '52px'} />
                    </IconButton>
                </WrapperDeleting>
            )}

            {statusIsVisible && id && (
                <ValidationStatusWrapper>
                    <ValidationStatusButton data={validation} status={validStatus} videoId={id} />
                </ValidationStatusWrapper>
            )}

            {viewsIsVisible && (
                <FeaturesWrapper>
                    <Views>
                        <Image height={playImgDiameter} src={playImg} width={playImgDiameter} />
                        <MarginWrapper marginLeft="6px">
                            <Text size="-2">{engagementStatistics?.views || '0'}</Text>
                        </MarginWrapper>
                    </Views>
                </FeaturesWrapper>
            )}

            {infoIsVisible && (
                <InfoButton type="button" onClick={handleInfoClick}>
                    <QuestionSmIcon />
                </InfoButton>
            )}
        </WrapperCard>
    );
};
