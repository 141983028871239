import { yupDefault } from 'constants/yup';
import * as Yup from 'yup';
import { UserReportProps } from './types';

enum ReportReason {
    PostingInappropriateContent = 1,
    PretendingToBeSomeoneElse = 2,
    InappropriateProfileInfo = 3,
    Under13YearsOld = 4,
    IntellectualPropertyInfringement = 5,
    Other = 6
}

export const userReportReasons: Omit<BULLZ.UserReportedReason, string> = {
    [ReportReason.PostingInappropriateContent]: 'Posting inappropriate content',
    [ReportReason.PretendingToBeSomeoneElse]: 'Pretending to be someone',
    [ReportReason.InappropriateProfileInfo]: 'Inappropriate Profile Info',
    [ReportReason.Under13YearsOld]: 'User could be under 13 years old',
    [ReportReason.IntellectualPropertyInfringement]: 'Intellectual property infringement',
    [ReportReason.Other]: 'Other'
};

export const validationSchema = Yup.object().shape({
    userId: yupDefault,
    reason: yupDefault.oneOf(Object.keys(userReportReasons))
});

export const initialValues: UserReportProps = {
    userId: '',
    reason: undefined
};
