import { InfoSmIcon } from 'assets/icons';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Tooltip } from 'components/ui/Tooltip';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React, { FC, ReactNode } from 'react';
import { Wrapper } from './styles';

interface Props {
    title: string;
    titleIcon?: ReactNode;
    tooltipText?: string;
    value?: string | number | null;
    valueIcon?: ReactNode;
    isUppercase?: boolean;
    hasBackground?: boolean;
}

export const Property: FC<Props> = ({
    title,
    titleIcon,
    value,
    valueIcon,
    tooltipText,
    isUppercase = true,
    hasBackground = false
}) => (
    <Wrapper hasBackground={hasBackground}>
        <Row alignCenter noWrap>
            <Text color={grey[500]} isUppercase={isUppercase} size="-2">
                {title}
            </Text>

            {titleIcon && <Column marginLeft="8px">{titleIcon}</Column>}

            {tooltipText && (
                <Column marginLeft="8px">
                    <Tooltip maxWidth="280px" overlay={<Text size="-2">{tooltipText}</Text>} placement="top">
                        <IconButton color={grey[500]}>
                            <InfoSmIcon />
                        </IconButton>
                    </Tooltip>
                </Column>
            )}
        </Row>

        <Row alignCenter noWrap marginTop="8px">
            <Text>{value || '-'}</Text>

            {valueIcon && <Column marginLeft="8px">{valueIcon}</Column>}
        </Row>
    </Wrapper>
);
