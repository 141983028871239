import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { disableDefaultButtonStyleMixin, ellipsisMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { media } from 'constants/styles/media';
import { baseColors } from 'constants/styles/colors';

export const TextWrapper = styled(Column)`
    margin-left: 10px;
    ${ellipsisMixin};
    max-width: 60%;
`;

export const ContentWrapper = styled(Column)`
    height: 100%;
    margin-top: 10px;
    overflow-x: hidden;
`;

export const MemberWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
`;

export const Empty = styled.div`
    width: 100%;
    background-color: ${baseColors.darkGrey};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 2 * ${headerHeight} - 110px);

    ${media.laptop(`
        height: 100%;
    `)};
`;
