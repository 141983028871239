import { yupFile, yupUrlNotRequired } from 'constants/yup';
import * as Yup from 'yup';

export interface QsFormValues {
    website: string;
    videoFile: File | null;
}

export const qsFormValues: QsFormValues = {
    website: '',
    videoFile: null
};

export const qsFormSchema = Yup.object().shape({
    website: yupUrlNotRequired,
    videoFile: yupFile
});
