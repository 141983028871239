import { VerificationSmIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { Wrapper } from 'components/pages/settings/components/ButtonToLinkAccount/styles';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { FC, ReactNode } from 'react';
import { socialNetworksStores } from 'stores/social-networks';
import { ReactClick } from 'types/react';

const { $userNetworks } = socialNetworksStores;
interface Props extends ReactClick<HTMLButtonElement> {
    title: string;
    icon?: ReactNode;
    isLoading?: boolean;
}

export const ButtonToLinkAccount: FC<Props> = ({ title, icon, onClick, isLoading }) => {
    const userNetworks = useStore($userNetworks);

    const isLinked = userNetworks.find(item => item.networkName?.toLowerCase() === title.toLowerCase());

    return (
        <Wrapper alignCenter noWrap>
            <Section alignCenter noWrap>
                {icon && <Row marginRight="20px">{icon}</Row>}
                <Text size="-1">{title}</Text>
                {isLinked && (
                    <Section marginLeft="18px">
                        <VerificationSmIcon />
                    </Section>
                )}
            </Section>

            {isLoading ? (
                <Row alignCenter justifyCenter height="32px" width="80px">
                    <Spinner />
                </Row>
            ) : (
                <Section justifyCenter maxWidth="75px">
                    <Button
                        buttonType={isLinked ? 'transparent' : 'primary'}
                        isBold={false}
                        size="small"
                        textSize="small"
                        onClick={onClick}
                    >
                        {isLinked ? 'Unlink' : 'Link'}
                    </Button>
                </Section>
            )}
        </Wrapper>
    );
};
