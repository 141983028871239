import { baseColors, grey, MontserratFontFamily, transitionTime } from 'constants/styles';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

export const TabsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
`;

export const TabsButtons = styled.div`
    display: flex;
    border-bottom: 2px solid ${grey[600]};
    width: 100%;

    &::-webkit-scrollbar {
        height: 0px;
    }
`;

interface TabsPanelProps {
    isActive?: boolean;
}

export const TabsPanelWrapper = styled.div<TabsPanelProps>`
    width: 100%;
    height: 100%;

    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

interface TabsButtonProps {
    isActive?: boolean;
    width?: string;
}

export const TabsButton = styled.div<TabsButtonProps>`
    ${disableDefaultButtonStyleMixin};
    font-family: ${MontserratFontFamily};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
    flex-grow: 1;
    text-align: center;
    padding: 8px 10px;
    position: relative;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }

    ${({ width }) =>
        width &&
        css`
            width: ${width};
            flex-grow: unset;
        `}

    ${({ isActive }) =>
        isActive &&
        css`
            ${flexCenter};

            &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                bottom: -2px;
                left: 0;
                background-color: ${baseColors.white};
            }
        `};

    ${({ isActive }) =>
        !isActive &&
        css`
            color: ${grey[600]};
        `};
`;
