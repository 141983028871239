import { forward } from 'effector';
import { createWalletPinModal, walletGuideModal } from 'stores/initialize.modal';
import { loaderEffects } from 'stores/loader';
import { walletEffects } from 'stores/wallet';

const {
    createWomWalletFx,
    getWomUserFx,
    createWomWalletFailFx,
    createWalletPinFx,
    createWalletPinDoneFx,
    createWalletPinFailFx,
    existsWalletPinFx,
    existsWalletPinDoneFx,
    existsWalletPinFailFx,
    getWalletTokenFx,
    getWalletTokenDoneFx,
    getWalletTokenFailFx,
    forgotWalletPinFx,
    sendForgotPinSmsCodeFx,
    sendForgotPinSmsCodeFailFx,
    verifyPurchaseFx,
    payWomFx
} = walletEffects;

forward({
    from: [
        createWomWalletFx.pending,
        createWalletPinFx.pending,
        existsWalletPinFx.pending,
        getWalletTokenFx.pending,
        sendForgotPinSmsCodeFx.pending,
        forgotWalletPinFx.pending,
        verifyPurchaseFx.pending,
        payWomFx.pending
    ],
    to: loaderEffects.loaderFx
});

// Exists wallet pin

forward({
    from: existsWalletPinFx.done,
    to: existsWalletPinDoneFx
});

forward({
    from: existsWalletPinFx.fail,
    to: existsWalletPinFailFx
});

// Create wallet

forward({
    from: createWomWalletFx.done,
    to: [walletGuideModal.closeModal, createWalletPinModal.openModal]
});

forward({
    from: createWomWalletFx.fail,
    to: createWomWalletFailFx
});

// Refresh wom user after created wallet
forward({
    from: createWomWalletFx.done,
    to: getWomUserFx
});

// Create wallet pin

forward({
    from: [createWalletPinFx.done, forgotWalletPinFx.done],
    to: createWalletPinDoneFx
});

forward({
    from: [createWalletPinFx.fail, forgotWalletPinFx.fail],
    to: createWalletPinFailFx
});

// Get wallet token

forward({
    from: getWalletTokenFx.done,
    to: getWalletTokenDoneFx
});

forward({
    from: getWalletTokenFx.fail,
    to: getWalletTokenFailFx
});

// Forgot wallet pin

forward({
    from: sendForgotPinSmsCodeFx.fail,
    to: sendForgotPinSmsCodeFailFx
});
