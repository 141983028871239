import { Header } from 'components/grid/Header';
import { SettingsContent } from 'components/pages/settings/SettingsContent';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Container } from './styles';

export const Settings = () => (
    <>
        {isMobile && <Header />}
        <Container>
            <SettingsContent />
        </Container>
    </>
);
