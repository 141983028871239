import { createEffect } from 'effector';
import { mainLoader } from 'stores/initialize.modal';

const loaderFx = createEffect({
    handler: (pending?: boolean) => {
        if (pending) {
            mainLoader.openModal();
        } else {
            mainLoader.closeModal();
        }
    }
});

export const loaderEffects = {
    loaderFx
};
