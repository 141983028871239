import { notify } from 'components/ui/Toasts';
import { defaultSavedVideosQueryParams } from 'constants/services/saved-videos';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

//Events
const resetStoresSavedVideos = createEvent();

// Effects
const saveVideoFx = createEffect({
    handler: async (data: BULLZ.CreateSavedVideoRequest) =>
        await API.savedVideo.saveVideo({
            ...data
        })
});

const saveVideoDoneFx = createEffect({
    handler: ({ result, params }: { result: BULLZ.MessageResponseBase; params: BULLZ.CreateSavedVideoRequest }) => {
        if (result.isSuccess && params?.isSave) {
            notify('Video has been saved to your activity');
        }
    }
});

const getSavedVideosFx = createEffect({
    handler: async () => await API.savedVideo.getSavedVideos({ ...defaultSavedVideosQueryParams })
});

const loadMoreSavedVideosFx = createEffect({
    handler: async (data: BULLZ.QuerySavedVideosRequest) => await API.savedVideo.getSavedVideos(data)
});

//Stores
const $savedVideos = createStore<BULLZ.QuerySavedVideosResponse>({})
    .on(getSavedVideosFx.doneData, (_, newState) => newState)
    .on(loadMoreSavedVideosFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStoresSavedVideos);

const $hasError = createStore<boolean>(false)
    .on(resetStoresSavedVideos, () => true)
    .reset([loadMoreSavedVideosFx.done, getSavedVideosFx.done, resetStoresSavedVideos]);

//Exports
export const savedVideoEffects = {
    saveVideoFx,
    saveVideoDoneFx,
    getSavedVideosFx,
    loadMoreSavedVideosFx
};

export const savedVideoStores = {
    $savedVideos,
    $hasError
};

export const savedVideoEvents = {
    resetStoresSavedVideos
};
