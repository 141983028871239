import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { lsTokenKey, womApiVersion, womURL } from 'constants/global';
import { walletEvents, walletStores } from 'stores/wallet';

const womAxiosInstance = axios.create({
    baseURL: womURL,
    method: 'POST'
});

womAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status;
        const data = config.response.data;

        if ([401, 403].includes(status)) {
            walletEvents.resetWalletToken();
        }

        return Promise.reject(data);
    }
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const tokenFromLS = localStorage.getItem(lsTokenKey);
    const bullzAccessToken = !!tokenFromLS ? (JSON.parse(tokenFromLS) as string) : '';

    const walletToken = walletStores.$walletToken.getState();

    const headers: any = {};

    if (withToken && (walletToken || bullzAccessToken)) {
        headers['Authorization'] = `Bearer ${walletToken || bullzAccessToken}`;
    }

    headers['api-version'] = womApiVersion;

    const request: AxiosPromise<T> = womAxiosInstance({
        ...config,
        headers: { ...config.headers, ...headers }
    });

    return (request as any) as Promise<T>;
};
