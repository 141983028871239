import { Column } from 'components/grid/wrappers/FlexWrapper';
import { baseColors, flexCenter, grey, transitionTime } from 'constants/styles';
import styled from 'styled-components';

export const ImageWrapper = styled.div`
    ${flexCenter};
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${baseColors.darkGrey};
    color: ${baseColors.white};
    transition: background-color ${transitionTime} linear;
`;

export const RowWrapper = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;
    background-color: transparent;
    border: none;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const CreateWalletWrapper = styled(Column)`
    width: 100%;
    padding: 8px;
`;

export const CreatingTeamWrapper = styled.div`
    padding-left: 16px;
    padding-right: 16px;
`;

export const ButtonWrapper = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    margin-bottom: 24px;
`;
