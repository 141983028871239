// CollaborationStatus for filters
export enum CollaborationStatusesUi {
    None = 0,
    Draft = 10,
    StartingSoon = 15,
    Active = 20,
    ActiveClosed = 24,
    Finalizing = 28,
    Ending = 30,
    Deleted = 40,
    Ended = 1000
}

// export const collaborationStatuses: Record<BUCM.Schemas.CollaborationState, string> = {
export const collaborationStatuses: Record<any, string> = {
    0: 'None',
    10: 'Draft',
    15: 'Starting soon',
    20: 'Active',
    24: 'Active',
    28: 'Finalizing',
    30: 'Ending',
    40: 'Deleted',
    1000: 'Ended'
};

// External networks keys
export enum ExternalNetworkKey {
    Bullz = 'bullz',
    Tiktok = 'tiktok',
    Instagram = 'instagram',
    Twitter = 'twitter',
    Youtube = 'youtube',
    Twitch = 'twitch'
}

export enum ParticipationState {
    None = 0,
    EnrollmentRequested = 5,
    Enrolled = 10,
    Submitted = 20,
    Published = 30,
    Ended = 1000
}

export enum SubmissionState {
    None = 0,
    Pending = 10,
    Curating = 20,
    Ended = 1000
}

export enum ParticipationEndedReason {
    None = 0,
    SubmittedAndPublished = 200,
    SubmittedOnly = 201,
    SubmissionFailed = 500,
    DeadlineMissed = 501,
    SubmissionReported = 502,
    Leave = 503,
    KickedOut = 504,
    ParticipationRequestRejected = 505
}
