import { format } from 'date-fns';

export const getFormattedDate = (date?: string | null, pattern = 'MM/yy') => {
    if (!date) return '';

    const curDate = new Date(date).toISOString();
    if (Date.parse(curDate) < 0) return '';

    return format(Date.parse(curDate), pattern);
};
