import urlRegex from 'url-regex';
import * as Yup from 'yup';
import {
    atLeastOneNumberRequiredMessage,
    exceededCharacterLimit,
    invalidEmailMessage,
    invalidWebsiteLinkMessage,
    oneCapitalCharRequiredMessage,
    oneSpecialCharacterRequiredMessage,
    passwordLengthMessage,
    requiredFieldMessage,
    requiredShortMessage
} from './messages';
import {
    atLeastOneNumberRequiredRegExp,
    oneCapitalCharRequiredRegExp,
    oneSpecialCharacterRequiredRegExp,
    onlySymbolAndNumbersRegExp,
    qsChannelTitleRegExp
} from './regExp';

export const yupDefault = Yup.string().required(requiredFieldMessage);
export const yupStringLimited = (limit: number) =>
    Yup.string().required(requiredFieldMessage).max(limit, exceededCharacterLimit);
export const yupString = Yup.string();

export const yupPhone = Yup.string()
    .required(requiredShortMessage)
    .matches(/^[0-9]+$/, 'Enter valid phone');

export const yupEmail = Yup.string().email(invalidEmailMessage).required(requiredFieldMessage);

export const yupPassword = Yup.string()
    .required(requiredFieldMessage)
    .min(6, passwordLengthMessage(6))
    .matches(oneCapitalCharRequiredRegExp, oneCapitalCharRequiredMessage)
    .matches(atLeastOneNumberRequiredRegExp, atLeastOneNumberRequiredMessage)
    .matches(oneSpecialCharacterRequiredRegExp, oneSpecialCharacterRequiredMessage);

export const yupCode = Yup.string()
    .required(requiredShortMessage)
    .test('len', 'Enter valid code', val => !!val && val.length === 6);

const emptyMsg = ' ';

export const yupQsTitle = Yup.string()
    .required(requiredShortMessage)
    .min(3, emptyMsg)
    .max(30, emptyMsg)
    .matches(qsChannelTitleRegExp, emptyMsg);

export const yupQsPrice = Yup.number().min(5, 'Minimum price 5 WOM').required(requiredShortMessage);

export const yupQsDescription = Yup.string().required(requiredShortMessage).min(10, emptyMsg).max(200, emptyMsg);

export const yupUsername = (min: number, max: number) =>
    Yup.string()
        .required(requiredFieldMessage)
        .matches(onlySymbolAndNumbersRegExp, 'Characters []{}’,@ are not allowed')
        .min(min, `This field must be at least ${min} characters`)
        .max(max, `This field must be no more than ${max} characters`);

export const yupTeamName = Yup.string().required(requiredShortMessage).min(3, emptyMsg).max(40, emptyMsg);

// ***** Boolean *****//
export const yupOnlyTrue = Yup.boolean().oneOf([true]);
export const yupBoolean = Yup.boolean();

// ***** URL *****//
export const yupUrl = Yup.string().matches(urlRegex(), invalidWebsiteLinkMessage).required(requiredFieldMessage);
export const yupUrlNotRequired = Yup.string().matches(urlRegex(), invalidWebsiteLinkMessage);

// ***** File *****//
export const yupFile = Yup.mixed().required('File is required');
