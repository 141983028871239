import { CustomImage } from 'components/common/CustomImg/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: ${headerHeight};
    position: fixed;
    background-color: ${baseColors.black};
    z-index: 9;
`;

export const Container = styled.div`
    max-width: 1280px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;

    ${media.laptop(`
        padding-left: 20px;
        padding-right: 20px;
    `)}
`;

export const LogoWrapper = styled.div`
    display: flex;
    margin-right: 16px;

    ${media.laptop(`
        min-width: 180px;
    `)}
`;

export const StyledLink = styled.a`
    display: inline-block;
    height: 40px;
    width: 110px;
`;

export const Avatar = styled(CustomImage)`
    border-radius: 50%;
    cursor: pointer;
`;

export const DefaultAvatarWrapper = styled.div`
    border-radius: 50%;
    background-color: ${baseColors.darkGrey};
    ${flexCenter};
    width: 32px;
    height: 32px;
    cursor: pointer;
`;
