import { InputField } from 'components/form/InputField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import {
    atLeastOneNumberRequiredRegExp,
    oneCapitalCharRequiredRegExp,
    oneSpecialCharacterRequiredRegExp
} from 'constants/regExp';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { FC, FormEvent, useMemo } from 'react';
import { registerEffects } from 'stores/register';
import { RegisterFieldName } from '../constants';
import { RulesChecklist } from '../RulesChecklist';
import { RulesChecklistItem } from '../RulesChecklist/types';
import { getLocaleStandard, getLocaleTag } from '../utils';

const { createAccountFx } = registerEffects;

interface Props {
    referralCode?: string;
}

export const PasswordStep: FC<Props> = ({ referralCode }) => {
    const isLoading = useStore(createAccountFx.pending);

    const [, { value: usernameValue }] = useField<string>(RegisterFieldName.Username);
    const [, { value: countryCodeValue }] = useField(RegisterFieldName.CountryCode);
    const [, { value: mobileNumberValue }] = useField(RegisterFieldName.MobileNumber);
    const [, { value: smsVerificationCodeValue }] = useField(RegisterFieldName.SmsVerificationCode);
    const [, { value: passwordValue, error: passwordError, touched: passwordTouched }] = useField<string>(
        RegisterFieldName.Password
    );
    const [
        ,
        { value: confirmPasswordValue, error: confirmPasswordError, touched: confirmPasswordTouched }
    ] = useField<string>(RegisterFieldName.ConfirmPassword);

    const submitIsActive = passwordTouched && !passwordError && confirmPasswordTouched && !confirmPasswordError;

    const passwordChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '6 or more characters',
                isSuccess: passwordValue.length >= 6
            },
            {
                title: 'Include a capital letter',
                isSuccess: oneCapitalCharRequiredRegExp.test(passwordValue)
            },
            {
                title: 'Include a number',
                isSuccess: atLeastOneNumberRequiredRegExp.test(passwordValue)
            },
            {
                title: 'Include a special character - _& . $',
                isSuccess: oneSpecialCharacterRequiredRegExp.test(passwordValue)
            }
        ],
        [passwordValue]
    );

    const confirmPasswordChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: 'Passwords match',
                isSuccess: !!passwordValue && passwordValue === confirmPasswordValue
            }
        ],
        [confirmPasswordValue, passwordValue]
    );

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const language = window.navigator.language;

        await createAccountFx({
            username: usernameValue,
            mobileNumber: `${countryCodeValue}${mobileNumberValue}`,
            smsVerificationCode: smsVerificationCodeValue,
            password: passwordValue,
            language: getLocaleStandard(language),
            locale: getLocaleTag(language),
            acceptLicense: true,
            ...(referralCode ? { referralCode } : {})
        });
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="16px">
                <InputField name={RegisterFieldName.Password} placeholder="Add password" type="password" />
            </Section>
            <Section marginBottom="16px">
                <RulesChecklist items={passwordChecklist} />
            </Section>

            <Section marginBottom="16px">
                <InputField name={RegisterFieldName.ConfirmPassword} placeholder="Confirm password" type="password" />
            </Section>
            <Section marginBottom="24px">
                <RulesChecklist items={confirmPasswordChecklist} />
            </Section>

            <Section>
                <Button wide disabled={!submitIsActive || isLoading} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
