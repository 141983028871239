import { requiredFieldMessage } from 'constants/messages';
import { FormikErrors } from 'formik';
import { getAge } from './BirthdayStep/utils';
import { genderList } from './GenderStep/constants';
import { ageThreshold, minimumAmountOfCategories } from './constants';

// Initial data

export interface AdditionalRegisterFormValues {
    day: string;
    month: string;
    year: string;
    fullBirthday: string;
    gender?: BULLZ.UserGender;
    categoryTagIds: string[];
}

export const additionalRegisterInitialValues: AdditionalRegisterFormValues = {
    day: '',
    month: '',
    year: '',
    fullBirthday: '',
    gender: undefined,
    categoryTagIds: []
};

// Validation

const validateBirthday = (day: string, month: string, year: string) => {
    if (!day || !month || !year) {
        return 'Fields is required';
    }

    const age = getAge(new Date(+year, +month - 1, +day));

    if (age < ageThreshold) {
        return `You must be at least ${ageThreshold} years old before you can create an account.`;
    }

    return '';
};

const validateGender = (gender?: BULLZ.UserGender) => {
    if (gender === undefined) {
        return requiredFieldMessage;
    }

    if (!genderList.map(item => item.value).includes(gender)) {
        return requiredFieldMessage;
    }

    return '';
};

const validateCategories = (categoryTagIds: string[]) => {
    if (categoryTagIds.length === 0) {
        return requiredFieldMessage;
    } else if (categoryTagIds.length < minimumAmountOfCategories) {
        return `You must choose at least ${minimumAmountOfCategories + 1} categories to continue.`;
    }

    return '';
};

export const validateAdditionalRegisterForm = ({
    day,
    month,
    year,
    gender,
    categoryTagIds
}: AdditionalRegisterFormValues) => {
    const errors: FormikErrors<Partial<AdditionalRegisterFormValues>> = {};

    errors.fullBirthday = validateBirthday(day, month, year);
    errors.gender = validateGender(gender);
    errors.categoryTagIds = validateCategories(categoryTagIds);

    return errors;
};
