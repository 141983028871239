import { BackArrowSmIcon, SaveSmIcon } from 'assets/icons';
import { PreviewVideoCard } from 'components/cards/PreviewVideoCard';
import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Empty, ImageWrapper, TitleWrapper, VideoCardsWrapper, Wrapper } from 'components/pages/SavedVideos/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { defaultSavedVideosQueryParams } from 'constants/services/saved-videos';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { savedVideoEffects, savedVideoEvents, savedVideoStores } from 'stores/saved-video';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems } from 'utils/video';

const { $savedVideos, $hasError } = savedVideoStores;
const { getSavedVideosFx, loadMoreSavedVideosFx } = savedVideoEffects;
const { resetStoresSavedVideos } = savedVideoEvents;

const { $user } = authStores;

export const SavedVideosCards = () => {
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [isHoveredId, setIsHoveredId] = useState('');

    const { items, currentPageIndex, totalPages } = useStore($savedVideos);
    const hasError = useStore($hasError);
    const isLoading = useStore(getSavedVideosFx.pending);
    const isMoreLoading = useStore(loadMoreSavedVideosFx.pending);

    const history = useHistory();
    const userId = useStore($user)?.userId;

    const goToBack = () => {
        !!userId ? history.push(`${AppRoute.Profile}/${userId}`) : history.goBack();
    };

    const handleCardClick = useCallback(
        (id?: string | null) => {
            fullscreenVideoEvents.setPlaylist(getPlaylistItems(items));
            id && history.replace(getFullscreenVideoUrl(id, 'saved'));
        },
        [history, items]
    );

    const handleCardMouseEnter = useCallback((id?: string | null) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (id) {
            timer.current = setTimeout(() => {
                setIsHoveredId(id);
            }, 300);
        }
    }, []);

    const handleCardMouseLeave = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        setIsHoveredId('');
    }, []);

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreSavedVideosFx({
                    ...defaultSavedVideosQueryParams,
                    pageIndex: currentPageIndex + 1
                });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    useEffect(() => {
        getSavedVideosFx();

        return () => resetStoresSavedVideos();
    }, []);

    return (
        <Wrapper>
            <TitleWrapper>
                <IconButton onClick={goToBack}>
                    <BackArrowSmIcon />
                </IconButton>
                <Section marginLeft="8px">
                    <Text isBold size={isMobile ? '0' : '1'}>
                        Videos Saved
                    </Text>
                </Section>
            </TitleWrapper>

            {items && items.length > 0 && (
                <>
                    <VideoCardsWrapper>
                        {items.map(item => (
                            <PreviewVideoCard
                                key={item.id}
                                id={item.id}
                                isActive={item.id === isHoveredId}
                                thumbnailUrl={item?.streaming?.screenGrabUrl}
                                videoSrc={item?.streaming?.hlsUrl}
                                viewCount={item?.engagement?.views}
                                onClick={handleCardClick}
                                onMouseEnter={handleCardMouseEnter}
                                onMouseLeave={handleCardMouseLeave}
                            />
                        ))}
                    </VideoCardsWrapper>
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}

            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    <ImageWrapper>
                        <SaveSmIcon height="18px" width="15px" />
                    </ImageWrapper>
                    <MarginWrapper marginBottom="8px">
                        <Text size="-1">No Videos Saved</Text>
                    </MarginWrapper>
                    <Text color={grey[500]} size="-2">
                        When you save a video, you will see it here
                    </Text>
                </Empty>
            )}

            {(isLoading || isMoreLoading) && (
                <Section justifyCenter marginTop="24px">
                    <Spinner />
                </Section>
            )}
        </Wrapper>
    );
};
