import {
    BullzContentRejectedMdIcon,
    CheckBgMdIcon,
    ClockBgMdIcon,
    DownLeftArrowMdIcon,
    InstaBgMdIcon,
    LinkSocialsMdIcon,
    NewBgMdIcon,
    NewPostCreatedMdIcon,
    NewQuickSignalVideoMdIcon,
    PostNewQuickSignalMdIcon,
    QuickSignalWomMdIcon,
    ReferralsRewardMdIcon,
    RenewalUnsuccessfulMdIcon,
    ResubscribeSuccessMdIcon,
    RightUpArrowBgMdIcon,
    RisingUpBgMdIcon,
    ShareBgMdIcon,
    StopBgMdIcon,
    SubscribersSadMdIcon,
    TiktokBgMdIcon,
    TwitchBgMdIcon,
    TwitterBgMdIcon,
    VideocamBgMdIcon,
    WomBgMdIcon,
    YoutubeBgMdIcon
} from 'assets/icons';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';
import { bracesRegExp } from 'constants/regExp';
import { AppRoute } from 'constants/routes';
import { NotificationType } from 'constants/services/notifications';
import { format, isToday, isYesterday } from 'date-fns';
import React, { ReactNode } from 'react';
import { authStores } from 'stores/auth';
import { uploadQsModal } from 'stores/initialize.modal';
import { getFullscreenVideoUrl, noop } from 'utils/common';
import { AvatarWrapper } from './AvatarWrapper';
import { CurationState, NotificationData, NotificationKeys } from './types';

const defaultName = 'Someone';
const defaultCollaborationName = 'Collaboration';
const defaultBrandName = 'Brand';
const defaultNetwork = 'BULLZ';
const defaultQSName = 'Quick Signal';

const iconSize = { width: 40, height: 40 };

const networkIcons: Partial<Record<ExternalNetworkKey, ReactNode>> = {
    [ExternalNetworkKey.Instagram]: <InstaBgMdIcon {...iconSize} />,
    [ExternalNetworkKey.Tiktok]: <TiktokBgMdIcon {...iconSize} />,
    [ExternalNetworkKey.Twitter]: <TwitterBgMdIcon {...iconSize} />,
    [ExternalNetworkKey.Youtube]: <YoutubeBgMdIcon {...iconSize} />,
    [ExternalNetworkKey.Twitch]: <TwitchBgMdIcon {...iconSize} />
};

const prepareTemplate = (template?: BULLZ.NotificationMessageTemplate, dataKeys?: NotificationKeys | null) => {
    if (template && template.needBeComposed && template.messageTemplate) {
        let templateMessage = template.messageTemplate;
        const keys = [...Array.from(new Set([...(templateMessage.match(bracesRegExp) || [])]))];

        if (keys.length > 0 && dataKeys) {
            keys.forEach((key: any) => {
                let value: string | undefined | null = null;

                if (key in dataKeys) {
                    const propKey = key as keyof NotificationKeys;
                    value = dataKeys[propKey];
                }

                if (value) {
                    templateMessage = templateMessage.replaceAll(`{${key}}`, value);
                }
            });
        }

        return templateMessage;
    }

    return '';
};

const getLinks = (keys: NotificationKeys | null, user: BULLZ.GetUserResponse | null) => {
    if (!keys) return null;

    return {
        video: keys?.videoid ? getFullscreenVideoUrl(keys.videoid, 'notifications') : undefined,
        user: keys?.userid ? `${AppRoute.Profile}/${keys.userid}` : undefined,
        me: user?.userId ? `${AppRoute.Profile}/${user.userId}` : undefined,
        team: keys?.teamid ? `${AppRoute.Team}/${keys.teamid}` : undefined,
        collaboration: keys?.collaborationId ? `${AppRoute.Collaborations}/${keys.collaborationId}` : undefined,
        qsChannel: keys?.channelId ? `${AppRoute.QuickSignalsChannel}/${keys.channelId}` : undefined
    };
};

export const getNotificationData = (data: BULLZ.NotificationItemResponse): NotificationData | null => {
    const notificationType = data.type;

    if (!notificationType) return null;

    const user = authStores.$user.getState();
    const keys = data.keys as NotificationKeys | null;
    const templateMessage = prepareTemplate(data.messageTemplate, keys);
    const links = getLinks(keys, user);

    switch (notificationType) {
        // Typical

        case NotificationType.YeayVideoLiked:
            return {
                title: templateMessage || `${keys?.username || defaultName} liked your video`,
                icon: <AvatarWrapper src={keys?.profileImageUrl || ''} type="like" />,
                link: links?.video
            };

        case NotificationType.YeayCommentCreated:
            return {
                title: templateMessage || `${keys?.username || defaultName} commented on your video`,
                icon: <AvatarWrapper src={keys?.profileImageUrl || ''} type="comment" />,
                link: links?.video
            };

        case NotificationType.YeayTrendingVideo:
            return {
                title: 'Your video is trending!',
                icon: <RisingUpBgMdIcon {...iconSize} />,
                link: links?.video
            };

        case NotificationType.YeayUserIsFollowed:
            return {
                title: templateMessage || `${keys?.username || defaultName} started following you`,
                icon: <AvatarWrapper src={keys?.profileImageUrl || ''} type="follower" />,
                link: links?.user
            };

        case NotificationType.YeayPostCreated:
            return {
                title:
                    templateMessage ||
                    `${keys?.username || defaultName} posted a new video. Go to their profile to see it!`,
                icon: <NewPostCreatedMdIcon {...iconSize} />,
                link: links?.user
            };

        case NotificationType.YeayTrendingUser:
            return {
                title: 'You are trending as a creator!',
                icon: <AvatarWrapper src={user?.profile?.profileImageUrl || ''} type="trending" />,
                link: links?.me
            };

        case NotificationType.YeayTeamJoined:
            return {
                title: templateMessage || `${keys?.username || defaultName} joined your team!`,
                icon: <AvatarWrapper src={keys?.profileImageUrl || ''} type="team" />,
                link: links?.team
            };

        case NotificationType.YeayVideoCuration:
            const isAccepted = keys?.curationstate === CurationState.Accepted;

            return {
                title: isAccepted ? 'Your video is now live!' : 'Your video was rejected',
                text: !isAccepted
                    ? 'Unfortunately your video did not pass the initial review process. Go to your videos to learn more.'
                    : undefined,
                icon: <VideocamBgMdIcon {...iconSize} />,
                link: links?.video
            };

        case NotificationType.YeayDepositSuccess:
            return {
                title: 'Deposit success',
                text: `Your recent deposit was successful. Go to your Wallet to view your current WOM balance.`,
                icon: <DownLeftArrowMdIcon {...iconSize} />,
                action: noop
            };

        case NotificationType.YeayDepositFailed:
            return {
                title: 'Deposit failed',
                text: `Your recent deposit failed. No worries, it happens sometimes. Go to your Wallet to try the deposit again.`,
                icon: <DownLeftArrowMdIcon {...iconSize} />,
                action: noop
            };

        case NotificationType.YeayWithdrawalSuccess:
            return {
                title: 'Withdrawal success!',
                text: `Your recent WOM withdrawal was successful. Go to your Wallet to view your current WOM balance.`,
                icon: <RightUpArrowBgMdIcon {...iconSize} />,
                action: noop
            };

        case NotificationType.YeayWithdrawalFailed:
            return {
                title: 'Withdrawal failed',
                text: `Your recent withdrawal failed. No worries, it happens sometimes. Go to your Wallet to try the withdrawal again.`,
                icon: <RightUpArrowBgMdIcon {...iconSize} />,
                action: noop
            };

        // TODO wom notifications
        // WOM

        // case NotificationType.WomCryptoDeposit:
        //     return {
        //         title: 'WomCryptoDeposit'
        //     };

        // case NotificationType.WomCryptoWithdrawal:
        //     return {
        //         title: 'WomCryptoWithdrawal'
        //     };

        // Collaborations

        case NotificationType.BcmCollaborationEnrollment:
            return {
                title: 'Enrollment Successful!',
                text:
                    templateMessage ||
                    `You are now enrolled in ${
                        keys?.collaborationName || defaultCollaborationName
                    }. Be sure to submit your video in the next ${keys?.timeLeft || '0'} to earn ${
                        keys?.value || '0'
                    } WOM`,
                icon: <CheckBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationStart:
            return {
                title: 'New Collaboration Available!',
                text:
                    templateMessage ||
                    `Hey! New Collaboration from ${
                        keys?.brandName || defaultBrandName
                    } is here for you. Check it out! 🤑`,
                icon: <NewBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationRemind:
            return {
                title: 'Remember to submit your video!',
                text:
                    templateMessage ||
                    `You have ${keys?.timeLeft || '0'} hour left to submit your video for ${
                        keys?.collaborationName || defaultCollaborationName
                    }`,
                icon: <ClockBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationStopped:
            return {
                title: `${keys?.collaborationName || defaultCollaborationName} Ended Early`,
                text:
                    templateMessage ||
                    `Dont worry, you can still submit your video in the next ${keys?.timeLeft || '0'} and earn ${
                        keys?.value || '0'
                    } WOM`,
                icon: <StopBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationPaymentSubmit:
            return {
                title: 'Reward Received',
                text:
                    templateMessage ||
                    `You received ${keys?.value || '0'} WOM for your ${
                        keys?.collaborationName || defaultCollaborationName
                    } video.`,
                icon: <WomBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationPaymentShare:
            return {
                title: 'Reward Received',
                text:
                    templateMessage ||
                    `You received ${keys?.value || '0'} WOM for sharing your ${
                        keys?.collaborationName || defaultCollaborationName
                    } video.`,
                icon: <ShareBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationShareVerified:
            const network = (keys?.networkName || defaultNetwork).toLowerCase() as ExternalNetworkKey;
            const icon = networkIcons[network] || undefined;

            return {
                title: 'Share Success',
                text:
                    templateMessage ||
                    `You shared your video to ${keys?.networkName || defaultNetwork}. You will receive ${
                        keys?.value || '0'
                    } WOM soon!`,
                icon,
                link: links?.collaboration
            };

        case NotificationType.BcmParticipantKickedOut:
            return {
                title: `Removed from ${keys?.collaborationName || defaultCollaborationName}`,
                text:
                    templateMessage ||
                    `You have been removed from ${
                        keys?.collaborationName || defaultCollaborationName
                    } because you do not meet the language requirements.`,
                icon: <StopBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmParticipantLeft:
            return {
                title: templateMessage || `You have left the ${keys?.collaborationName || defaultCollaborationName}`,
                text: `You are no longer part of this ${
                    keys?.collaborationName || defaultCollaborationName
                } because you have left`,
                icon: <StopBgMdIcon {...iconSize} />,
                link: links?.collaboration
            };

        case NotificationType.BcmCollaborationLinkSocials:
            return {
                title: `Link your socials to view collaborations`,
                text: `Link your social media accounts directly from your settings page to view more potential collaborations! Check it out! 🤑`,
                icon: <LinkSocialsMdIcon {...iconSize} />,
                action: noop
            };

        case NotificationType.BcmCollaborationContentRejected:
            return {
                title: `Collaboration content rejected`,
                text: `One of your content submissions was not approved. Please upload a new video in the next 24 hours.`,
                icon: <BullzContentRejectedMdIcon {...iconSize} />,
                action: noop
            };

        // Quick Signals

        case NotificationType.QuickSignalNewVideo:
            return {
                title: 'Enrollment Successful!',
                text:
                    templateMessage ||
                    `${keys?.creatorName || defaultName} just posted a new Quick Signal 🎉 Tap to watch now!`,
                icon: <NewQuickSignalVideoMdIcon {...iconSize} />,
                link: AppRoute.Trending
            };

        case NotificationType.QuickSignalChargeReminder:
            return {
                title: 'Subscription renewal notice',
                text: `Your subscription to ${
                    keys?.quickSignalChannelName || defaultQSName
                } will renew in 2 days. Make sure you have enough WOM in your wallet so you can keep enjoying premium content from ${
                    keys?.creatorName || defaultName
                } 🚀`,
                icon: <QuickSignalWomMdIcon {...iconSize} />,
                link: links?.qsChannel
            };

        case NotificationType.QuickSignalChannelRenewSubscription:
            return {
                title: 'Resubscribe Success!',
                text: `Your subscription to ${
                    keys?.quickSignalChannelName || defaultQSName
                } has been renewed 🎉 Check out the latest from ${keys?.creatorName || defaultName} now!`,
                icon: <ResubscribeSuccessMdIcon {...iconSize} />,
                link: links?.qsChannel
            };

        case NotificationType.QuickSignalBalanceReminder:
            return {
                title: 'Renewal Unsuccessful',
                text: `Hey! You don’t have enough WOM to renew your subscription to ${
                    keys?.quickSignalChannelName || defaultQSName
                }. Add WOM to your wallet now to keep enjoying premium content from ${
                    keys?.creatorName || defaultName
                }!`,
                icon: <RenewalUnsuccessfulMdIcon {...iconSize} />,
                link: links?.qsChannel
            };

        case NotificationType.QuickSignalNewSubsriber:
            return {
                title: 'New Subscriber!',
                text: 'You have a new subscriber to your Quick Signals Channel 🔥',
                icon: <AvatarWrapper src={keys?.profileImageUrl || ''} type="qs-sub" />,
                link: `${AppRoute.QuickSignals}?tab=subs`
            };

        case NotificationType.QuickSignalPostFiveDaysReminder:
            return {
                title: 'Time to post a new Quick Signal?',
                text: 'Your subscribers want to hear from you! Create a new Quick Signal now 🚀',
                icon: <PostNewQuickSignalMdIcon {...iconSize} />,
                action: () => uploadQsModal.openModal()
            };

        case NotificationType.QuickSignalPostTenDaysReminder:
            return {
                title: 'Time to post a new Quick Signal?',
                text: 'Your subscribers haven’t heard from you in a while. Why not create a Quick Signal now 🎦',
                icon: <PostNewQuickSignalMdIcon {...iconSize} />,
                action: () => uploadQsModal.openModal()
            };

        case NotificationType.QuickSignalPostTwentyDaysReminder:
            return {
                title: 'Your subscribers miss you!',
                text:
                    'You haven’t posted a Quick Signal in a few weeks. Create a Quick Signal now to make sure your followers stay subscribed!',
                icon: <SubscribersSadMdIcon {...iconSize} />,
                action: () => uploadQsModal.openModal()
            };

        // Referrals

        case NotificationType.ReferralReward:
            return {
                title: 'Referral Reward',
                text: 'Congratulations! You received a reward because your referral completed a collaboration!',
                icon: <ReferralsRewardMdIcon {...iconSize} />,
                action: noop
            };

        default:
            return null;
    }
};

export const formatDate = (date?: string) => {
    if (!date) return '';

    const curDate = Date.parse(new Date(date).toISOString());
    if (curDate <= 0) return '';

    if (isToday(curDate)) {
        return `Today ● ${format(curDate, 'kk:mm')}`;
    }

    if (isYesterday(curDate)) {
        return `Yesterday ● ${format(curDate, 'kk:mm')}`;
    }

    return format(curDate, 'dd.MM.yyyy kk:mm');
};
