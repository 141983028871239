import { SuccessLgIcon, WrongLgIcon } from 'assets/icons';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';

export enum UploadVideoSteps {
    FillData = 'fillData',
    Submit = 'submit'
}

//Events

const setStep = createEvent<UploadVideoSteps>();

//Effects

const getNewVideoIdFx = createEffect({
    handler: async (data: BULLZ.CreateVideoRequest) => await API.video.getNewVideoId(data)
});

interface UploadVideoFxParams {
    videoUploadId: string;
    file: File;
}

const uploadVideoFx = createEffect({
    handler: async ({ videoUploadId, file }: UploadVideoFxParams) => {
        await API.video.uploadVideo(videoUploadId, file);
    }
});

const uploadFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: 'Something went wrong',
            text: 'There was a problem uploading your video. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const uploadDoneFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: SuccessLgIcon,
            title: 'Success!',
            text: `You successfully upload video`,
            buttonText: 'Okay',
            onClick: noop
        });
    }
});

//Stores

const $currentStep = createStore<UploadVideoSteps>(UploadVideoSteps.FillData).on(setStep, (_, payload) => payload);

//Exports

export const uploadVideoStores = { $currentStep };

export const uploadVideoEffects = {
    getNewVideoIdFx,
    uploadVideoFx,
    uploadDoneFx,
    uploadFailFx
};

export const uploadVideoEvents = { setStep };
