import axios from './axios';

export const registerVideoAsViewed = (data: BULLZ.RegisterAggregatedEventRequest) =>
    axios({
        url: '/video/register-event',
        data
    });

export const reportVideo = (data: BULLZ.ReportVideoRequest) =>
    axios({
        url: '/video/report',
        data
    });

export const getValidationVideos = (data: BULLZ.QueryUploadedVideosRequest) =>
    axios<BULLZ.QueryUploadedVideosResponse>({
        url: '/video/query-uploaded',
        data
    });

export const getValidationVideo = (data: BULLZ.GetVideoDetailsRequest) =>
    axios<BULLZ.GetVideoDetailsResponse>({
        url: '/video/get',
        data
    });

export const getNewVideoId = (data: BULLZ.CreateVideoRequest) =>
    axios<BULLZ.CreateVideoResponse>({
        url: '/video/new',
        data
    });

export const uploadVideo = (videoUploadId: string, file: File) => {
    const formData = new FormData();
    formData.append('fileName', file);

    return axios<BULLZ.MessageResponseBase>({
        url: `/media/upload/${videoUploadId}`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteVideo = (data: BULLZ.DeleteVideoRequest) =>
    axios<BULLZ.DeleteVideoResponse>({
        url: '/video/delete',
        data
    });

export const beginWomValidate = (data: BULLZ.WOMStartValidationRequest) =>
    axios<BULLZ.ValidationStateResponse>({
        url: '/wom/begin-validation',
        data
    });

export const restartWomValidate = (data: BULLZ.WOMRestartValidationRequest) =>
    axios<BULLZ.ValidationStateResponse>({
        url: '/wom/restart-validation',
        data
    });
