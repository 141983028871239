import defaultAvatar from 'assets/defaults/default_avatar.svg';
import { WrongLgIcon, XMdIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { TeamNameFormValues } from './components/InputName/validation';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { createOrEditTeamModal, infoModal } from 'stores/initialize.modal';
import { teamEffects, teamEvents, teamStores } from 'stores/team';
import { Id } from 'types/interfaces/data';
import { goToHelpDesk, noop } from 'utils/common';
import { InputName } from './components/InputName';
import { CancelWrapper } from './styles';

const { modal, closeModal } = createOrEditTeamModal;
const { createTeamFx, getTeamInfoFx, updateTeamFx, existsTeamFx } = teamEffects;
const { $teamInfo } = teamStores;
const { resetStore } = teamEvents;
const { openModal: openInfoModal } = infoModal;

export const CreateOrEditTeamModal = () => {
    const { id } = useParams<Partial<Id>>();

    const [visible, state] = useStore(modal);
    const { isEdit } = state;
    const isLoading = useStore(createTeamFx.pending);
    const teamInfo = useStore($teamInfo);

    const handleClose = () => {
        closeModal();
    };

    const updateTeam = (teamNameValue: string, teamInfo: BULLZ.GetTeamDetailResponse) => {
        updateTeamFx({
            name: teamNameValue,
            urlName: teamNameValue,
            id: teamInfo.id
        })
            .then(({ name, owner, id }) => {
                const teamLink = `${window.location.origin}${AppRoute.Team}/${id}?userId=${owner?.userId}`;
                closeModal();
                openInfoModal({
                    image: owner?.profileImageUrl || defaultAvatar,
                    title: name || '',
                    text:
                        'You successfully changed your team name! Invite your friends & followers to join your team and start earning WOM!',
                    buttonText: 'Let’s Go',
                    copyButtonText: 'Copy team link',
                    copyText: teamLink,
                    onClick: () => noop
                });
            })
            .catch(() => {
                openInfoModal({
                    icon: WrongLgIcon,
                    title: 'Something went wrong',
                    text: `There was a problem changing your name. Please try again or contact our support team.`,
                    buttonText: 'Try Again',
                    cancelText: 'CONTACT SUPPORT',
                    onCloseClick: goToHelpDesk
                });
            });
    };

    const createTeam = (teamNameValue: string) => {
        createTeamFx({ name: teamNameValue, urlName: teamNameValue })
            .then(({ name, owner, id }) => {
                const teamLink = `${window.location.origin}${AppRoute.Team}/${id}?userId=${owner?.userId}`;
                closeModal();
                openInfoModal({
                    image: owner?.profileImageUrl || defaultAvatar,
                    title: name || '',
                    text:
                        'Congratulations for creating your team! Invite your friends & followers to join your team and start earning WOM!',
                    buttonText: 'Let’s Go',
                    copyButtonText: 'Copy team link',
                    copyText: teamLink,
                    onClick: () => noop
                });
            })
            .catch(() => {
                openInfoModal({
                    icon: WrongLgIcon,
                    title: 'Something went wrong',
                    text: `There was a problem creating your team. Please try again or contact our support team.`,
                    buttonText: 'Try Again',
                    cancelText: 'CONTACT SUPPORT',
                    onCloseClick: goToHelpDesk
                });
            });
    };

    const handleChangeTeam = async (teamName: TeamNameFormValues) => {
        const teamNameValue = teamName.teamName;

        const result = await existsTeamFx(teamName);

        if (!result.exists) {
            if (isEdit && teamInfo) {
                updateTeam(teamNameValue, teamInfo);
            } else {
                createTeam(teamNameValue);
            }
        }
    };

    useEffect(() => {
        if (!visible && isEdit) {
            resetStore();
        }
    }, [visible, isEdit]);

    useEffect(() => {
        id && getTeamInfoFx(id);
    }, [id]);

    return (
        <ModalWrapper visible={visible} onClose={handleClose}>
            {!isMobile && (
                <CancelWrapper>
                    <IconButton size="24px" onClick={handleClose}>
                        <XMdIcon />
                    </IconButton>
                </CancelWrapper>
            )}

            <Column>
                <Section justifyCenter marginBottom={isMobile ? '20px' : '16px'}>
                    <Title size="4">{isEdit ? 'Edit' : 'Create a'} Team</Title>
                </Section>

                <Section justifyCenter marginBottom="16px">
                    <Text size="-2">{isEdit ? 'Edit' : 'Pick'} your team name!</Text>
                </Section>
            </Column>

            <InputName data={teamInfo} handleChangeTeam={handleChangeTeam} isEdit={isEdit} isLoading={isLoading} />
        </ModalWrapper>
    );
};
