import { yupCode } from 'constants/yup';
import { FormikErrors } from 'formik';
import { walletEffects } from 'stores/wallet';
import * as Yup from 'yup';

// Initial data

export interface WalletPinFormValues {
    code: string;
}

export const walletPinValues: WalletPinFormValues = {
    code: ''
};

// Validation

export const schema = Yup.object().shape({
    code: yupCode
});

const validateCode = async (code: string) => {
    try {
        await Yup.reach(schema, 'code').validate(code);

        const isVerified = await walletEffects.verifyWalletPinFx(code);
        if (!isVerified) {
            return 'Pin is incorrect';
        }

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

export const validateWalletPinForm = async ({ code }: WalletPinFormValues) => {
    const errors: FormikErrors<Partial<WalletPinFormValues>> = {};

    errors.code = await validateCode(code);

    return errors;
};
