import {
    RejectedSmIcon,
    ReSubmitSmIcon,
    StatusFailSmIcon,
    StatusProcessSmIcon,
    StatusWithdrawSmIcon
} from 'assets/icons';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Tooltip } from 'components/ui/Tooltip';
import { Text } from 'components/ui/typography/Text';
import { StyledSvg } from 'components/video/FullscreenVideo/components/ValidationStatusButton/styles';
import { validationStatusTooltip, ValidationStatusUI } from 'constants/services/video-statuses';
import { baseColors, grey } from 'constants/styles/colors';
import React, { FC, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { videoEvents } from 'stores/video';

interface Props {
    videoId: string;
    status: ValidationStatusUI;
}

export const ValidationStatusButton: FC<Props> = ({ status, videoId }) => {
    const Icon = useMemo(() => {
        switch (status) {
            case ValidationStatusUI.Processing:
            case ValidationStatusUI.WomValidationProcessing:
            default:
                return <StatusProcessSmIcon />;
            case ValidationStatusUI.Submit:
                return <StyledSvg />;
            case ValidationStatusUI.ReSubmit:
                return <ReSubmitSmIcon />;
            case ValidationStatusUI.Fail:
                return <StatusFailSmIcon />;
            case ValidationStatusUI.Rejected:
                return <RejectedSmIcon />;
            case ValidationStatusUI.Live:
            case ValidationStatusUI.CollaborationLive:
                return <StatusWithdrawSmIcon />;
        }
    }, [status]);

    const handleClick = () => {
        if (status === ValidationStatusUI.Processing || status === ValidationStatusUI.Rejected) return;

        videoEvents.openStatusVideoModal(videoId);
    };

    if (isMobile) {
        return (
            <IconButton color={baseColors.white} onClick={handleClick}>
                {Icon}
            </IconButton>
        );
    }

    return (
        <Tooltip overlay={<Text size="-2">{validationStatusTooltip[status]}</Text>} placement="bottomRight">
            <IconButton color={grey['500']} onClick={handleClick}>
                {Icon}
            </IconButton>
        </Tooltip>
    );
};
