import {
    NotificationCommentSmIcon,
    NotificationFollowerSmIcon,
    NotificationLikeSmIcon,
    NotificationQsSubSmIcon,
    NotificationTeamSmIcon,
    NotificationTrendingSmIcon
} from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import React, { FC } from 'react';
import { IconWrapper, Wrapper } from './styles';

interface Props {
    src?: string | null;
    type: 'like' | 'comment' | 'follower' | 'trending' | 'team' | 'qs-sub';
}

const iconSize = { width: 18, height: 18 };

export const AvatarWrapper: FC<Props> = ({ src, type }) => {
    const getIcon = () => {
        switch (type) {
            case 'like':
            default:
                return <NotificationLikeSmIcon {...iconSize} />;
            case 'comment':
                return <NotificationCommentSmIcon {...iconSize} />;
            case 'follower':
                return <NotificationFollowerSmIcon {...iconSize} />;
            case 'trending':
                return <NotificationTrendingSmIcon {...iconSize} />;
            case 'team':
                return <NotificationTeamSmIcon {...iconSize} />;
            case 'qs-sub':
                return <NotificationQsSubSmIcon {...iconSize} />;
        }
    };

    return (
        <Wrapper>
            <AvatarImg diameter="40px" src={src || ''} />
            <IconWrapper>{getIcon()}</IconWrapper>
        </Wrapper>
    );
};
