import locale from 'locale-codes';

export const getLocaleTag = (langCode?: string) => {
    if (!langCode) return 'en-US';

    return locale.getByTag(langCode).tag || 'en-US';
};

export const getLocaleStandard = (langCode?: string) => {
    if (!langCode) return 'English';

    return locale.getByTag(langCode).name || 'English';
};
