import notFoundImage from 'assets/img/not-found.png';
import { PreviewVideoCard } from 'components/cards/PreviewVideoCard';
import { CustomImage } from 'components/common/CustomImg/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { defaultTeamVideosQueryParams } from 'constants/services/team';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { teamEffects, teamEvents, teamStores } from 'stores/team';
import { Id } from 'types/interfaces/data';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems } from 'utils/video';
import { Empty, VideoCardsWrapper } from './styles';

const { getTeamVideosFx, loadMoreTeamVideosFx } = teamEffects;
const { $teamVideos, $hasError } = teamStores;
const { resetStore } = teamEvents;

export const TeamVideosTab: FC<Id> = ({ id }) => {
    const history = useHistory();
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [isHoveredId, setIsHoveredId] = useState('');

    const { items, currentPageIndex, totalPages } = useStore($teamVideos);
    const isMoreLoading = useStore(loadMoreTeamVideosFx.pending);

    const hasError = useStore($hasError);
    const isLoading = useStore(getTeamVideosFx.pending);

    const handleCardClick = useCallback(
        (id?: string | null) => {
            fullscreenVideoEvents.setPlaylist(getPlaylistItems(items));
            id && history.push(getFullscreenVideoUrl(id, 'teams'));
        },
        [history, items]
    );

    const handleCardMouseEnter = useCallback((id?: string | null) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (id) {
            timer.current = setTimeout(() => {
                setIsHoveredId(id);
            }, 300);
        }
    }, []);

    const handleCardMouseLeave = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        setIsHoveredId('');
    }, []);

    useEffect(() => {
        getTeamVideosFx(id);

        return () => resetStore();
    }, [id]);

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreTeamVideosFx({
                    ...defaultTeamVideosQueryParams,
                    id: id,
                    pageIndex: currentPageIndex + 1
                });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    return (
        <Column height="100%" marginTop="14px" width="100%">
            {items && items.length > 0 && (
                <>
                    <VideoCardsWrapper>
                        {items.map(item => (
                            <PreviewVideoCard
                                key={item.id}
                                id={item.id}
                                isActive={item.id === isHoveredId}
                                thumbnailUrl={item?.streaming?.screenGrabUrl}
                                videoSrc={item?.streaming?.hlsUrl}
                                viewCount={item?.engagement?.views}
                                onClick={handleCardClick}
                                onMouseEnter={handleCardMouseEnter}
                                onMouseLeave={handleCardMouseLeave}
                            />
                        ))}
                    </VideoCardsWrapper>
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}
            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    <MarginWrapper marginBottom="8px">
                        <CustomImage alt="not found" height="200px" src={notFoundImage} width="200px" />
                    </MarginWrapper>
                    <Text color={grey[500]} size="-1">
                        No Team Videos Found
                    </Text>
                </Empty>
            )}
            {(isLoading || isMoreLoading) && (
                <Section justifyCenter marginTop="24px">
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
