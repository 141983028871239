import { TextButton } from 'components/ui/buttons/TextButton';
import { baseColors, ellipsisMixin, grey, MontserratFontFamily, transitionTime } from 'constants/styles';
import styled, { css } from 'styled-components';

export const TabWrapper = styled.div`
    height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TabContent = styled.div`
    height: 220px;
    overflow-y: scroll;
    font-family: ${MontserratFontFamily};
    position: relative;
`;

export const TabItem = styled.div`
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    font-size: 12px;
    align-items: center;
    ${ellipsisMixin};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background: ${baseColors.black};
    }
`;

export const HashImg = styled.img`
    width: 18px;
    margin-right: 10px;
`;

export const EmptyMessage = styled.div`
    position: absolute;
    color: ${grey[500]};
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    text-align: center;
`;

export const StyledButton = styled(TextButton)`
    padding: 4px 0;
    transition: background-color ${transitionTime} linear;

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                background: ${grey[800]};
                border-radius: 4px;
            }
        `};
`;
