import * as auth from './auth';
import * as categories from './categories';
import * as comments from './comments';
import * as curation from './curation';
import * as followed from './followed';
import * as followers from './followers';
import * as gifts from './gifts';
import * as notifications from './notifications';
import * as phyllo from './phyllo';
import * as playlist from './playlist';
import * as quickSignals from './quick-signals';
import * as savedVideo from './saved-video';
import * as search from './search';
import * as tags from './tags';
import * as team from './team';
import * as trendings from './trendings';
import * as user from './user';
import * as userFollow from './user-follow';
import * as userHashtag from './user-hashtag';
import * as video from './video';

export const API = {
    notifications,
    user,
    playlist,
    video,
    trendings,
    search,
    comments,
    auth,
    followed,
    followers,
    userFollow,
    savedVideo,
    userHashtag,
    team,
    quickSignals,
    categories,
    tags,
    curation,
    gifts,
    phyllo
};
