import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { AuthModalLayout } from 'components/layouts/AuthModalLayout';
import { PhoneForm } from 'components/modals/LoginModal/PhoneForm';
import { ScreenChooser } from 'components/modals/LoginModal/ScreenChooser';
import { UsernameForm } from 'components/modals/LoginModal/UsernameForm';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { ReactNode, useState } from 'react';
import { loginModal, registerModal } from 'stores/initialize.modal';

const { modal, closeModal } = loginModal;

export enum Screen {
    Choose = 'choose',
    Username = 'username',
    Phone = 'phone'
}

export const LoginModal = () => {
    const [visible] = useStore(modal);
    const [currentScreen, setCurrentScreen] = useState<Screen>(Screen.Choose);

    const screens: Record<Screen, ReactNode> = {
        choose: <ScreenChooser setCurrentScreen={setCurrentScreen} />,
        username: <UsernameForm />,
        phone: <PhoneForm />
    };

    const subtitles: Record<Screen, string> = {
        choose: 'Please choose how to log into your BULLZ account',
        username: 'Please enter your username',
        phone: 'Please enter your phone number'
    };

    const openSignUp = () => {
        closeModal();
        registerModal.openModal({});
    };

    return (
        <AuthModalLayout
            bottomExtra={
                <Row justifyCenter width="100%">
                    <MarginWrapper marginRight="16px">
                        <Text size="-2">Don’t have an account?</Text>
                    </MarginWrapper>
                    <TextButton onClick={openSignUp}>Sign Up</TextButton>
                </Row>
            }
            closeModal={closeModal}
            isBackButtonVisible={currentScreen !== Screen.Choose}
            subtitle={subtitles[currentScreen]}
            title="Log in to BULLZ"
            visible={visible}
            onBackClick={() => setCurrentScreen(Screen.Choose)}
        >
            <Column width="100%">{screens[currentScreen]}</Column>
        </AuthModalLayout>
    );
};
