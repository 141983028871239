import { CloseSmIcon, SearchSmIcon } from 'assets/icons';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { IconButton } from 'components/ui/buttons/IconButton';
import { KeyboardCode } from 'constants/special';
import { grey } from 'constants/styles/colors';
import React, { FC, FormEvent, useState } from 'react';
import { Disabled, Placeholder } from 'types';
import { ReactChange } from 'types/react';
import { noop } from 'utils/common';
import { SearchForm, StyledInput } from './styles';
import { homeEvents } from 'stores/home';

const { setIsFocusedVideoPlayer } = homeEvents;

interface Props extends ReactChange<HTMLInputElement>, Disabled, Placeholder {
    value?: string;
    isLoading?: boolean;
    disableAutocomplete?: boolean;
    onSubmit?: () => void;
    onClear?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const SearchInput: FC<Props> = ({
    value = '',
    isLoading = false,
    disabled = false,
    placeholder,
    onChange,
    onSubmit,
    disableAutocomplete,
    onBlur = noop,
    onFocus = noop,
    onClear = noop
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
        setIsFocusedVideoPlayer(false);
        onFocus();
    };

    const handleBlur = () => {
        setIsFocused(false);
        setIsFocusedVideoPlayer(true);
        onBlur();
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setIsFocusedVideoPlayer(true);
        onSubmit && onSubmit();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardCode.Enter) {
            setIsFocusedVideoPlayer(true);
            onSubmit && onSubmit();
        }
    };

    return (
        <SearchForm disabled={disabled} isFocused={isFocused} onSubmit={handleSubmit}>
            <IconButton disabled={value.length === 0} type="submit">
                <SearchSmIcon />
            </IconButton>

            <StyledInput
                autoComplete={disableAutocomplete ? 'off' : 'on'}
                disabled={disabled}
                name="search"
                placeholder={placeholder}
                type="text"
                value={value}
                onBlur={handleBlur}
                onChange={onChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
            />

            {isLoading && (
                <MarginWrapper marginLeft="8px">
                    <Spinner />
                </MarginWrapper>
            )}

            {value.length > 0 && (
                <MarginWrapper marginLeft="8px">
                    <IconButton color={grey['500']} disabled={value.length === 0} onClick={onClear}>
                        <CloseSmIcon />
                    </IconButton>
                </MarginWrapper>
            )}
        </SearchForm>
    );
};
