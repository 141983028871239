import { forward } from 'effector';
import { authEffects, authEvents, authStores, localStorageConnects } from 'stores/auth';
import { loginModal } from 'stores/initialize.modal';
import { loaderEffects } from 'stores/loader';
import { quickSignalsEvents } from 'stores/quick-signals';
import { walletEvents } from 'stores/wallet';

const { $user, $token, $anonymousUser, $sessionId } = authStores;
const {
    loginFx,
    forgotPasswordSendSmsCodeFx,
    forgotPasswordFx,
    forgotPasswordDoneFx,
    forgotPasswordFailFx,
    uploadAvatarFx,
    updateFailFx,
    updateAccountFx,
    deleteAccountFx,
    deleteAccountFailFx,
    deleteAccountDoneFx
} = authEffects;
const { logout, updateAvatar, setUser } = authEvents;
const { userLocalStorage, tokenLocalStorage, anonymousUserLocalStorage, sessionLocalStorage } = localStorageConnects;

$user.watch(userLocalStorage);
$token.watch(tokenLocalStorage);
$anonymousUser.watch(anonymousUserLocalStorage);
$sessionId.watch(sessionLocalStorage);

forward({
    from: [
        loginFx.pending,
        forgotPasswordSendSmsCodeFx.pending,
        forgotPasswordFx.pending,
        uploadAvatarFx.pending,
        updateAccountFx.pending,
        deleteAccountFx.pending
    ],
    to: loaderEffects.loaderFx
});

forward({
    from: loginFx.done,
    to: loginModal.closeModal
});

// Reset wom wallet info

forward({
    from: logout,
    to: walletEvents.resetWomUser
});

forward({
    from: logout,
    to: walletEvents.resetWalletToken
});

// Forgot password

forward({
    from: forgotPasswordFx.done,
    to: forgotPasswordDoneFx
});

forward({
    from: forgotPasswordFx.fail,
    to: forgotPasswordFailFx
});

forward({
    from: logout,
    to: quickSignalsEvents.resetStores
});

// Update avatar

forward({
    from: uploadAvatarFx.doneData,
    to: updateAvatar
});

// Update account

forward({
    from: updateAccountFx.doneData,
    to: setUser
});

forward({
    from: [uploadAvatarFx.fail, updateAccountFx.fail],
    to: updateFailFx
});

// Delete account

forward({
    from: deleteAccountFx.failData,
    to: deleteAccountFailFx
});

forward({
    from: deleteAccountFx.doneData,
    to: deleteAccountDoneFx
});
