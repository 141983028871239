import { ClockSmIcon, RejectedPopupLgIcon, RejectedSmIcon, ReSubmitSmIcon, WithdrawSmIcon } from 'assets/icons';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { bullzSiteQualityGuidelines } from 'constants/routes';
import {
    BullzCurationEndedReason,
    validationStatusTitles,
    ValidationStatusUI,
    videoRejectReasons
} from 'constants/services/video-statuses';
import React, { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { isMobile } from 'react-device-detect';
import { infoModal } from 'stores/initialize.modal';
import { userVideosEffects } from 'stores/user-videos';
import { videoEvents } from 'stores/video';
import { goToExternalLink, noop } from 'utils/common';
import { IconWrapper, ValidationStatus } from './styles';
import { getExpiredDate } from './utils';

export interface ValidationStatusButtonProps {
    videoId: string;
    status: ValidationStatusUI;
    data?: BULLZ.VideoDetailsValidation;
}

export const ValidationStatusButton: FC<ValidationStatusButtonProps> = ({ videoId, status, data }) => {
    const [visibleTimer, setVisibleTimer] = useState(true);

    const Icon = useMemo(() => {
        switch (status) {
            case ValidationStatusUI.Processing:
            case ValidationStatusUI.WomValidationProcessing:
            default:
                return <ClockSmIcon />;
            case ValidationStatusUI.Submit:
            case ValidationStatusUI.ReSubmit:
                return <ReSubmitSmIcon />;
            case ValidationStatusUI.Rejected:
                return <RejectedSmIcon />;
            case ValidationStatusUI.Live:
            case ValidationStatusUI.CollaborationLive:
                return <WithdrawSmIcon />;
        }
    }, [status]);

    const handleOpenModalDelete = () => {
        videoId &&
            infoModal.openModal({
                title: 'Delete Video',
                text: 'Are you sure you want to delete this video?',
                buttonText: 'Yes',
                onClick: () => userVideosEffects.deleteVideoFx({ videoId }),
                onCloseClick: noop
            });
    };

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            if (status === ValidationStatusUI.Processing) return;

            if (status === ValidationStatusUI.Rejected) {
                infoModal.openModal({
                    icon: RejectedPopupLgIcon,
                    title: 'Rejected',
                    text: videoRejectReasons[data?.bullz?.curationEndedReason || BullzCurationEndedReason.Other],
                    buttonText: 'Delete',
                    onClick: handleOpenModalDelete,
                    cancelText: 'See Quality Guidelines',
                    onCloseClick: () => goToExternalLink(bullzSiteQualityGuidelines)
                });
                return;
            }

            videoEvents.openStatusVideoModal(videoId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, status, videoId]
    );

    const onCompletedWomValidation = useCallback(() => {
        userVideosEffects.getMyVideoById(videoId);
        setVisibleTimer(false);
    }, [videoId]);

    return (
        <ValidationStatus status={status} type="button" onClick={handleClick}>
            {!isMobile && (
                <Column marginRight="8px">
                    <IconWrapper>{Icon}</IconWrapper>
                </Column>
            )}

            <Text color="inherit" size="-2">
                {status === ValidationStatusUI.WomValidationProcessing && data?.wom?.utcValidationExpired ? (
                    <>
                        {visibleTimer && (
                            <Countdown
                                date={getExpiredDate(data.wom.utcValidationExpired)}
                                onComplete={onCompletedWomValidation}
                            />
                        )}
                    </>
                ) : (
                    validationStatusTitles[status] || '-'
                )}
            </Text>
        </ValidationStatus>
    );
};
