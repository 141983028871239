import PageNotFound from 'assets/img/page-not-found.png';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image, TextWrapper, Wrapper } from './styles';

export const ContentNotFound = () => {
    const history = useHistory();
    const goBack = () => history.goBack();

    return (
        <Wrapper>
            <Image src={PageNotFound} />

            <TextWrapper>
                <Text alignTextCenter color={grey[500]} size="-1">
                    We couldn’t find the page you were looking for. Please go back to homepage
                </Text>
            </TextWrapper>

            <Button isBold buttonType="primary" onClick={goBack}>
                Go back
            </Button>
        </Wrapper>
    );
};
