import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 16px 20px;

    ${media.laptop(`
        padding: 14px 70px 0;
    `)};
`;

export const SettingsTitle = styled(Text)`
    padding-left: 0;
    padding-right: 0;

    ${media.laptop(`
        padding-left: 10px;
        padding-right: 10px;
    `)};
`;

export const EmptyBlock = styled.div`
    width: 24px;
    height: 24px;
`;

export const InnerWrapper = styled(Column)`
    width: 100%;
    height: calc(100% - 44px);
    flex-wrap: nowrap;
    padding-left: 0;
    padding-right: 0;

    ${media.laptop(`
        padding-left: 10px;
        padding-right: 10px;
    `)};
`;
