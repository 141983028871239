import { HomeSmIcon, TrendingActiveSmIcon, TrendingSmIcon, VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Link } from 'components/ui/Link';
import { Tag } from 'components/ui/Tag';
import { Text } from 'components/ui/typography/Text';
import { SearchCategory } from 'constants/enum';
import { AppRoute, bullzSitePolicyUrl, bullzSiteTermsUrl } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { trendingsEffects, trendingsStores } from 'stores/trendings';
import { CreatorWrapper, LinkElem, PagesWrapper, TextWrapper, Wrapper } from './styles';

const { getTrendings } = trendingsEffects;

interface Props {
    isHideCreators?: boolean;
}
export const SideBar: FC<Props> = ({ isHideCreators }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const hashTags = useStore(trendingsStores.hashtags).slice(0, isHideCreators ? 12 : 4);
    const users = useStore(trendingsStores.users).slice(0, 4);

    const handleTagClick = (tag: string) => {
        history.push(`${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&name=${tag}&tagId=${tag}`);
    };

    const year = new Date().getFullYear();

    useEffect(() => {
        getTrendings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <PagesWrapper>
                <Column marginBottom="20px" width="100%">
                    <LinkElem exact to={AppRoute.Main}>
                        <HomeSmIcon height="16px" width="15px" />
                        <MarginWrapper marginLeft="12px">Home</MarginWrapper>
                    </LinkElem>
                    <LinkElem exact to={AppRoute.Trending}>
                        {pathname === AppRoute.Trending ? (
                            <TrendingActiveSmIcon height="20px" width="14px" />
                        ) : (
                            <TrendingSmIcon height="20px" width="14px" />
                        )}
                        <MarginWrapper marginLeft="12px">Trending</MarginWrapper>
                    </LinkElem>
                </Column>

                {!isHideCreators && users.length > 0 && (
                    <>
                        <MarginWrapper marginBottom="16px">
                            <Text size="-1">Top Creators</Text>
                        </MarginWrapper>
                        <Section marginBottom="8px">
                            {users.map(({ profileImageUrl, username, userId, isTrusted }) => (
                                <CreatorWrapper key={userId} to={`${AppRoute.Profile}/${userId}`}>
                                    <Column>
                                        <AvatarImg src={profileImageUrl || undefined} />
                                    </Column>
                                    <Section alignCenter noWrap marginLeft="10px">
                                        <TextWrapper>
                                            <Text size="-2">{username}</Text>
                                        </TextWrapper>
                                        {isTrusted && (
                                            <Column marginLeft="2px">
                                                <VerificationSmIcon />
                                            </Column>
                                        )}
                                    </Section>
                                </CreatorWrapper>
                            ))}
                        </Section>

                        <MarginWrapper marginBottom="24px">
                            <Link href={AppRoute.Trending}>See all</Link>
                        </MarginWrapper>
                    </>
                )}

                {hashTags.length > 0 && (
                    <>
                        <MarginWrapper marginBottom="4px">
                            <Text size="-1">Trending Hashtags</Text>
                        </MarginWrapper>

                        <Column marginBottom="12px">
                            {hashTags.map(({ tag }) => (
                                <Column key={tag} marginTop="8px">
                                    {tag && (
                                        <Tag subject={tag} onClick={handleTagClick}>
                                            #{tag}
                                        </Tag>
                                    )}
                                </Column>
                            ))}
                        </Column>

                        {!isHideCreators && (
                            <MarginWrapper marginBottom="22px">
                                <Link href={AppRoute.Trending}>See all</Link>
                            </MarginWrapper>
                        )}
                    </>
                )}

                <Section justifyBetween marginBottom="8px">
                    <Link isExternal href={bullzSitePolicyUrl}>
                        Privacy
                    </Link>

                    <Link isExternal href={bullzSiteTermsUrl}>
                        Terms & Conditions
                    </Link>
                </Section>

                <Text color={grey[500]} size="-2">
                    {year} © WOM Protocol Pte. Ltd. All Rights reserved.
                </Text>
            </PagesWrapper>
        </Wrapper>
    );
};
