import styled from 'styled-components';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles';
import { BackgroundColor, Round, Sizes } from 'types/interfaces/styles';
import { clickableWrapperDiameter } from './constants';

interface ClickableWrapperProps extends Round, Sizes, BackgroundColor {}

export const ClickableWrapper = styled.button<ClickableWrapperProps>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    ${({ round }) => round && 'border-radius: 50%;'};
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
    width: ${({ width }) => width ?? clickableWrapperDiameter};
    height: ${({ height }) => height ?? clickableWrapperDiameter};
`;
