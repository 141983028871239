import notFoundImg from 'assets/img/not-found.png';
import { ValidatePreviewVideoCard } from 'components/cards/ValidatePreviewVideoCard';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Empty, Image, VideoCardsWrapper } from 'components/pages/profile/ProfileVideoCards/styles';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { defaultUserVideosQueryParams } from 'constants/services/user-videos';
import { ValidationStatusUI } from 'constants/services/video-statuses';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { userVideosEffects, userVideosEvents, userVideosStores } from 'stores/user-videos';
import { Id } from 'types';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems, getValidationStatus } from 'utils/video';

const { getMyUserVideosFx, loadMoreMyUserVideosFx } = userVideosEffects;
const { $myUserVideos, $myUserVideosError } = userVideosStores;
const { resetStores } = userVideosEvents;

interface Props extends Id {
    isEdit?: boolean;
}

export const MyProfileVideoCards: FC<Props> = ({ id, isEdit }) => {
    const history = useHistory();
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [isHoveredId, setIsHoveredId] = useState('');

    const { items, currentPageIndex, totalPages } = useStore($myUserVideos);
    const isMoreLoading = useStore(loadMoreMyUserVideosFx.pending);
    const isLoading = useStore(getMyUserVideosFx.pending);
    const hasError = useStore($myUserVideosError);

    const handleCardClick = useCallback(
        (id?: string | null) => {
            const playlist = !!items
                ? items.filter(item => {
                      const validStatus = getValidationStatus(item.validation, item.isInCollaboration);

                      if (
                          (validStatus === ValidationStatusUI.None || validStatus === ValidationStatusUI.Live) &&
                          item.streaming?.isReady
                      ) {
                          return true;
                      }

                      if (
                          validStatus === ValidationStatusUI.Processing ||
                          validStatus === ValidationStatusUI.Rejected ||
                          !item.streaming?.isReady
                      ) {
                          return false;
                      }

                      return true;
                  })
                : [];
            fullscreenVideoEvents.setPlaylist(getPlaylistItems(playlist));
            id && !isEdit && history.replace(getFullscreenVideoUrl(id));
        },
        [history, items, isEdit]
    );

    const handleCardMouseEnter = useCallback((id?: string | null) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (id) {
            timer.current = setTimeout(() => {
                setIsHoveredId(id);
            }, 300);
        }
    }, []);

    const handleCardMouseLeave = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        setIsHoveredId('');
    }, []);

    useEffect(() => {
        getMyUserVideosFx();

        return () => resetStores();
    }, [id]);

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreMyUserVideosFx({
                    ...defaultUserVideosQueryParams,
                    pageIndex: currentPageIndex + 1
                });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    return (
        <Column height="100%" width="100%">
            {items && items.length > 0 && (
                <>
                    <VideoCardsWrapper>
                        {items.map(item => (
                            <ValidatePreviewVideoCard
                                key={item.id}
                                isActive={item.id === isHoveredId}
                                isEdit={isEdit}
                                video={item}
                                onClick={handleCardClick}
                                onMouseEnter={handleCardMouseEnter}
                                onMouseLeave={handleCardMouseLeave}
                            />
                        ))}
                    </VideoCardsWrapper>
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}
            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    <Image src={notFoundImg} />
                    <Text color={grey[500]}>No Videos Found</Text>
                </Empty>
            )}
            {(isLoading || isMoreLoading) && (
                <Section justifyCenter marginTop="24px">
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
