import { CheckSmIcon, WrongLgIcon } from 'assets/icons';
import { InputField } from 'components/form/InputField';
import { CountryOption } from 'components/form/options/CountryOption';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Select } from 'components/ui/Select';
import { SelectOptionValue } from 'components/ui/Select/types';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { asyncErrorMessage } from 'constants/messages';
import { baseColors } from 'constants/styles/colors';
import { useField } from 'formik';
import { useReCaptcha } from 'hooks/useReCaptcha';
import React, { Dispatch, FC, FormEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { authEffects } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';
import { prepareCountryOptions } from 'utils/forms';
import { ForgotPasswordFieldName, Step, secondsToResendCode } from '../constants';

const { forgotPasswordSendSmsCodeFx } = authEffects;

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
}

export const PhoneStep: FC<Props> = ({ setCurrentStep }) => {
    const [codeSended, setCodeSended] = useState(false);
    const [codeSeconds, setCodeSeconds] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState<SelectOptionValue | null>(null);
    const countries = useMemo(() => prepareCountryOptions(), []);

    const [, { value: mobileNumber, error: mobileNumberError, touched: mobileNumberTouched }] = useField<string>(
        ForgotPasswordFieldName.MobileNumber
    );
    const [, { error: smsCodeError, touched: smsCodeTouched }] = useField<string>(
        ForgotPasswordFieldName.SmsVerificationCode
    );
    const [, { value: countryCode }, { setValue: setCountryCode }] = useField<string>(
        ForgotPasswordFieldName.CountryCode
    );

    const token = useReCaptcha();

    const submitIsActive = mobileNumberTouched && smsCodeTouched && !mobileNumberError && !smsCodeError && !!token;

    const phonePlaceholder = isMobile ? 'Phone Number' : 'Please enter your phone number';
    const codePlaceholder = isMobile ? 'Enter Code' : 'Please enter your 6-digit code';
    const codeResendText = isMobile ? '' : 'Resend Code: ';

    const openErrorCodeModal = (text?: string) => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: text || '',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    };

    const sendCode = () => {
        forgotPasswordSendSmsCodeFx({
            phone: `${countryCode}${mobileNumber}`,
            firebaseToken: token
        })
            .then(data => {
                if (data.isOk) {
                    setCodeSended(true);
                    setCodeSeconds(secondsToResendCode);
                } else {
                    openErrorCodeModal();
                }
            })
            .catch(e => {
                openErrorCodeModal((e.message as string) || '');
            });
    };

    const handleSelectCountry = (value: SelectOptionValue) => {
        setSelectedCountry(value);

        const foundCountry = countries.find(country => country.value === value);
        if (foundCountry) {
            setCountryCode(`+${foundCountry.code}`);
        }
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        setCurrentStep(Step.Password);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (codeSeconds > 0) {
                setCodeSeconds(codeSeconds - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [codeSeconds]);

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="24px">
                <InputField
                    addonBefore={
                        <Select
                            hideBorder
                            items={countries}
                            optionsWidth={isMobile ? '200px' : '260px'}
                            placeholder="Add code"
                            renderOption={CountryOption}
                            value={selectedCountry || ''}
                            onChange={handleSelectCountry}
                        />
                    }
                    label="Phone number"
                    name={ForgotPasswordFieldName.MobileNumber}
                    placeholder={phonePlaceholder}
                    suffix={
                        mobileNumberTouched && !mobileNumberError ? <CheckSmIcon color={baseColors.blue} /> : undefined
                    }
                    type="tel"
                />
            </Section>

            <Section marginBottom="32px">
                <InputField
                    addonAfter={
                        <TextButton
                            disabled={!mobileNumberTouched || !!mobileNumberError || codeSeconds > 0 || !token}
                            onClick={sendCode}
                        >
                            {codeSeconds > 0 ? `${codeResendText}${codeSeconds}s` : 'Send Code'}
                        </TextButton>
                    }
                    disabled={!codeSended}
                    name={ForgotPasswordFieldName.SmsVerificationCode}
                    placeholder={codePlaceholder}
                    type="tel"
                />
            </Section>

            <Section marginBottom="16px">
                <Button wide disabled={!submitIsActive} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
