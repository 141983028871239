import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const BlockWrapper = styled.div`
    padding: 12px 16px;
    border-radius: 16px;
    background-color: ${baseColors.darkGrey};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ${media.laptop(`
        flex-direction: row;
    `)};
`;
