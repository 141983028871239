import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';

import React from 'react';
import { StyledImg, Wrapper } from './styles';
import { AvatarImgProps } from './types';

export const AvatarBorder = ({ alt = 'avatar', src = defaultAvatar, diameter, background }: AvatarImgProps) => (
    <Wrapper background={background} diameter={diameter}>
        <StyledImg alt={alt} diameter={diameter} src={src} />
    </Wrapper>
);
