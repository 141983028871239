import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { VideoCardsProps } from 'components/pages/trending/TrendingVideoCards';
import { baseColors } from 'constants/styles/colors';
import styled from 'styled-components';

export const VideoCardsWrapper = styled.div<VideoCardsProps>`
    display: grid;
    width: 100%;
    padding-bottom: 24px;
    grid-template-columns: ${({ isSideBar }) => `repeat(${isSideBar ? 4 : 5}, 1fr)`};
    row-gap: 16px;
    column-gap: 26px;

    @media (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
    }
`;

export const Empty = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${baseColors.darkGrey};
`;

export const CardsWrapper = styled(Column)`
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
`;
