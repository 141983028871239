import { forward } from 'effector';
import { loaderEffects } from 'stores/loader';
import { settingsPasswordEffects } from 'stores/settings/password';

const { validatePasswordFx, changePasswordFx } = settingsPasswordEffects;

forward({
    from: [validatePasswordFx.pending, changePasswordFx.pending],
    to: loaderEffects.loaderFx
});
