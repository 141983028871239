import { Section } from 'components/grid/wrappers/FlexWrapper';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const CollaborationsList = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
`;

export const CollaborationWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    margin-left: 8px;
    width: 90px;
    height: 140px;
    flex-shrink: 0;

    ${media.laptop(`
        width: 100%;
        margin-left: 0;
        height: 190px;
    `)}
`;

export const SeeAllWrapper = styled(Section)`
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const SliderWrapper = styled.div`
    width: 100%;
    margin-left: -8px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
`;
