import { yupCode, yupDefault, yupPassword } from 'constants/yup';
import { FormikErrors } from 'formik';
import { authEffects } from 'stores/auth';
import { registerEffects } from 'stores/register';
import * as Yup from 'yup';
import { ForgotPasswordFieldName } from './constants';

// Initial data

export interface ForgotPasswordFormValues {
    mobileNumber: string;
    smsVerificationCode: string;
    password: string;
    confirmPassword: string;
}

export const initialValues: ForgotPasswordFormValues = {
    mobileNumber: '',
    smsVerificationCode: '',
    password: '',
    confirmPassword: ''
};

// Validation

export const schema = Yup.object().shape({
    mobileNumber: yupDefault,
    smsVerificationCode: yupCode,
    password: yupPassword,
    confirmPassword: yupPassword
});

enum AnalyzePhoneResult {
    NotFoundInSystem = 1,
    InvalidFormat = -1
}

const validatePhone = async (mobileNumber: string) => {
    try {
        await Yup.reach(schema, ForgotPasswordFieldName.MobileNumber).validate(mobileNumber);

        const response = await authEffects.analyzeMobileNumberFx({ mobileNumber });

        if (response?.result === AnalyzePhoneResult.NotFoundInSystem) {
            return 'Not found in system';
        }

        if (response?.result === AnalyzePhoneResult.InvalidFormat) {
            return 'Enter valid phone';
        }

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validateVerificationCode = async (smsCode: string, phone: string) => {
    try {
        await Yup.reach(schema, ForgotPasswordFieldName.SmsVerificationCode).validate(smsCode);

        const response = await registerEffects.checkSmsCodeFx({ mobileNumber: phone, code: smsCode });

        if (response === true) {
            return '';
        } else if (response === false) {
            return 'Pin Incorrect';
        } else return response;
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validatePassword = async (password: string) => {
    try {
        await Yup.reach(schema, ForgotPasswordFieldName.Password).validate(password);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validateConfirmPassword = async (password: string, confirmPassword: string) => {
    try {
        const result = await Yup.reach(schema, ForgotPasswordFieldName.ConfirmPassword).validate(confirmPassword);

        if (result) {
            if (password === confirmPassword) {
                return '';
            } else {
                return 'Please match passwords';
            }
        }
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

export const validateForm = async ({
    mobileNumber,
    smsVerificationCode,
    password,
    confirmPassword
}: ForgotPasswordFormValues) => {
    const errors: FormikErrors<Partial<ForgotPasswordFormValues>> = {};

    errors.mobileNumber = await validatePhone(mobileNumber);
    errors.smsVerificationCode = await validateVerificationCode(smsVerificationCode, mobileNumber);
    errors.password = await validatePassword(password);
    errors.confirmPassword = await validateConfirmPassword(password, confirmPassword);

    return errors;
};
