import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import styled from 'styled-components';

interface CollaborationCardWrapperProps {
    image: string;
    rounded?: boolean;
}

export const CollaborationCardWrapper = styled(Column)<CollaborationCardWrapperProps>`
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: flex-end;
    padding: 6px 4px;
    background-image: url(${({ image }) => image});
    background-position: center;
    background-size: cover;
    background-color: ${baseColors.darkGrey};
    background-repeat: no-repeat;
    ${({ rounded }) => rounded && 'border-radius: 12px'};
`;

export const CollaborationInformation = styled.div`
    width: 100%;
    z-index: 1;
`;

export const BadgeWrapper = styled.div`
    position: absolute;
    top: 6px;
    right: 4px;
    z-index: 2;
`;

export const StyledTitle = styled(Text)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-all;
    max-width: 100%;
    height: 36px;
    color: ${baseColors.white};
`;

export const InfoBlock = styled.div`
    padding: 3px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${baseColors.blue};
    border-radius: 2px;
`;
