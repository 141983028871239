import * as collaboration from './collaboration';
import * as externalNetworks from './external-networks';
import * as mediaAssets from './media-assets';
import * as network from './network';
import * as participants from './participants';
import * as users from './users';

export const bucmAPI = {
    collaboration,
    participants,
    users,
    mediaAssets,
    externalNetworks,
    network
};
