import axios from './axios';

export const getTeamVideos = (data: BULLZ.QueryTeamVideosRequest) =>
    axios<BULLZ.QueryTeamVideosResponse>({
        url: '/team/get-videos',
        data
    });

export const getTeamMembers = (data: BULLZ.QueryMembersRequest) =>
    axios<BULLZ.QueryMembersResponse>({
        url: '/team/get-members',
        data
    });

export const getTeamInfo = (data: BULLZ.GetTeamDetailRequest) =>
    axios<BULLZ.GetTeamDetailResponse>({
        url: '/team/get-info',
        data
    });

export const removeUser = (data: BULLZ.RemoveTeamMemberRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/team/remove',
        data
    });

export const joinToTeam = (data: BULLZ.JoinTeamRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/team/join',
        data
    });

export const leaveFromTeam = (data: BULLZ.LeaveTeamRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/team/leave',
        data
    });

export const createTeam = (data: BULLZ.CreateTeamRequest) =>
    axios<BULLZ.CreateTeamResponse>({
        url: '/team/create',
        data
    });

export const updateTeam = (data: BULLZ.UpdateTeamRequest) =>
    axios<BULLZ.UpdateTeamResponse>({
        url: '/team/update',
        data
    });

export const existsTeam = (data: BULLZ.TeamNameExistsRequest) =>
    axios<BULLZ.ExistenceResponse>({
        url: '/team/teamName-exists',
        data
    });
