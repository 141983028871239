import { VerificationSmIcon } from 'assets/icons';
import { Spinner } from 'components/loaders/Spinner';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { ChecklistItem, ChecklistWrapper, Dot, IndicatorWrapper } from './styles';
import { RulesChecklistItem } from './types';

interface Props {
    items?: RulesChecklistItem[];
}

const Indicator = ({ isLoading, isSuccess }: RulesChecklistItem) => {
    if (isLoading) {
        return <Spinner size={16} />;
    }

    if (isSuccess) {
        return <VerificationSmIcon height={16} width={16} />;
    }

    return <Dot />;
};

export const RulesChecklist: FC<Props> = ({ items }) => {
    if (items === undefined || items.length === 0) {
        return null;
    }

    return (
        <ChecklistWrapper>
            {items.map((item, index) => (
                <ChecklistItem key={index} hasMargin={index > 0}>
                    <IndicatorWrapper>
                        <Indicator {...item} />
                    </IndicatorWrapper>
                    <Text size="-2">{item.title}</Text>
                </ChecklistItem>
            ))}
        </ChecklistWrapper>
    );
};
