interface Gender {
    value: BULLZ.UserGender;
    label: string;
}

export const genderList: Gender[] = [
    {
        value: 1,
        label: 'Male'
    },
    {
        value: 2,
        label: 'Female'
    },
    {
        value: 0,
        label: 'I prefer not to say'
    }
];
