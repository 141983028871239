import { FormWrapper } from 'components/form/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { CodeInput } from 'components/ui/inputs/CodeInput';
import { Text } from 'components/ui/typography/Text';
import { useField } from 'formik';
import React, { FormEvent } from 'react';
import { walletEffects } from 'stores/wallet';
import { pinCodeLength, Step, subtitles, titles, WalletPinFieldName } from '../constants';

const { createWalletPinFx } = walletEffects;

export const ConfirmStep = () => {
    const [, { value: pinCode }] = useField<string>(WalletPinFieldName.Code);
    const [, { value, error, touched }, { setValue, setTouched }] = useField<string>(WalletPinFieldName.Confirm);

    const handleChange = (value: string) => {
        !touched && value.length === pinCodeLength && setTouched(true);
        setValue(value);
    };

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!!error || !pinCode) {
            return;
        }

        await createWalletPinFx(pinCode);
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="28px">
                <CodeInput
                    count={pinCodeLength}
                    error={touched && error ? error : ''}
                    value={value}
                    onChange={handleChange}
                />
            </Section>

            <Column noWrap marginBottom="24px" width="100%">
                <Section justifyCenter marginBottom="12px">
                    <Text alignTextCenter isBold>
                        {titles[Step.Confirm]}
                    </Text>
                </Section>
                <Section justifyCenter>
                    <Text alignTextCenter size="-2">
                        {subtitles[Step.Confirm]}
                    </Text>
                </Section>
            </Column>

            <Section>
                <Button wide disabled={!touched || !!error} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
