import QSLightning from 'assets/icons/common/qs-lightning.svg';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { authStores } from 'stores/auth';
import { qsEffects, qsEvents, qsStores } from 'stores/quick-signals-trending';
import { AvatarsList, AvatarWrapper, Header, SeeAllWrapper, SliderWrapper, StyledLabel } from './styles';

const { getQSUsersFx } = qsEffects;
const { $qsUsers } = qsStores;
const { openQSModal } = qsEvents;
const { $user } = authStores;

const viewCount = 4;

export const TrendingQS = () => {
    const isLoading = useStore(getQSUsersFx.pending);
    const qsUsers = useStore($qsUsers);
    const authUser = useStore($user);

    useEffect(() => {
        getQSUsersFx();
    }, []);

    const seeAll = () => {
        openQSModal({
            ...qsUsers[0],
            index: 0
        });
    };

    if (isLoading) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    if (qsUsers.length === 0) {
        return null;
    }

    return (
        <Column width="100%">
            <Header>
                <Column marginRight="8px">
                    <img alt="QS" src={QSLightning} />
                </Column>
                <Column marginRight="8px">
                    <Text size="-1">Quick Signals</Text>
                </Column>
                <StyledLabel>
                    <Text alignTextCenter size="-2">
                        New
                    </Text>
                </StyledLabel>
            </Header>

            {!isMobile && (
                <Text color={grey[500]} size="-2">
                    Get latest news on crypto!
                </Text>
            )}

            {isMobile ? (
                <SliderWrapper>
                    {qsUsers.map((item, index) => (
                        <AvatarWrapper
                            key={item.userProfile?.id}
                            onClick={() => {
                                openQSModal({
                                    ...item,
                                    index
                                });
                            }}
                        >
                            <QsAvatar
                                hasNewVideos={item.hasNewVideos}
                                isSubscribed={item.isSubscribed || item.userProfile?.id === authUser?.userId}
                                url={item.userProfile?.avatarUrl || ''}
                            />

                            <Section justifyCenter marginTop="14px">
                                <Text alignTextCenter size="-2">
                                    {item.userProfile?.username}
                                </Text>
                            </Section>
                        </AvatarWrapper>
                    ))}
                </SliderWrapper>
            ) : (
                <AvatarsList>
                    {qsUsers.slice(0, viewCount).map((item, index) => (
                        <AvatarWrapper
                            key={item.userProfile?.id}
                            onClick={() => {
                                openQSModal({
                                    ...item,
                                    index
                                });
                            }}
                        >
                            <QsAvatar
                                hasNewVideos={item.hasNewVideos}
                                isSubscribed={item.isSubscribed || item.userProfile?.id === authUser?.userId}
                                url={item.userProfile?.avatarUrl || ''}
                            />

                            <Section justifyCenter marginTop="14px">
                                <Text size="-2">{item.userProfile?.username}</Text>
                            </Section>
                        </AvatarWrapper>
                    ))}
                </AvatarsList>
            )}

            {!isMobile && qsUsers.length > viewCount && (
                <SeeAllWrapper>
                    <TextButton onClick={seeAll}>See All</TextButton>
                </SeeAllWrapper>
            )}
        </Column>
    );
};
