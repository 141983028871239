import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const CommentsWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    flex-grow: 1;
    overflow: hidden auto;
`;

export const CommentsItems = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

export const CommentsItem = styled(Section)`
    flex-wrap: nowrap;
    padding-left: 8px;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
    background: none;
    transition: background-color ${transitionTime} linear;

    ${media.laptop(`
        &:hover {
            background-color: ${grey['800']};
        }
    `)};
`;

export const CommentContent = styled(Column)`
    width: calc(100% - 48px);
    flex-wrap: nowrap;
    flex-grow: 1;
    padding-left: 8px;
`;

export const RepliesWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

export const RepliesItems = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

export const RepliesItem = styled(Section)`
    flex-wrap: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
`;
