import { DescriptionSignalsSmIcon, QuickSignalSmIcon } from 'assets/icons';
import { InputField } from 'components/form/InputField';
import { TextareaField } from 'components/form/TextareaField';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Label } from 'components/pages/quickSignals/create/Label';
import { Button } from 'components/ui/buttons/Button';
import { qsChannelTitleRegExp } from 'constants/regExp';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { qsChannelEffects, qsChannelStores } from 'stores/quick-signals-channel';

export const EditForm = () => {
    const titleIsAvailable = useStore(qsChannelStores.$titleIsAvailable);
    const titleIsAvailableIsLoading = useStore(qsChannelEffects.checkTitleFx.pending);

    const [, { value: titleValue, initialValue: titleInitialValue }] = useField<string>('title');
    const [, { value: descriptionValue, initialValue: descriptionInitialValue }] = useField<string>('description');

    const titleIsDirty = titleInitialValue !== titleValue;
    const descriptionIsDirty = descriptionInitialValue !== descriptionValue;

    const titleChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '3 to 30 characters',
                isSuccess: titleValue.length >= 3 && titleValue.length <= 30
            },
            {
                title: 'Title is available',
                isSuccess: !titleIsDirty ? true : titleIsAvailable,
                isLoading: titleIsAvailableIsLoading
            },
            {
                title: 'Characters allowed: - _& . 0-9A-z',
                isSuccess: qsChannelTitleRegExp.test(titleValue)
            }
        ],
        [titleIsAvailable, titleIsAvailableIsLoading, titleIsDirty, titleValue]
    );

    const descriptionChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: 'From 10 to 200 characters',
                isSuccess: descriptionValue.length >= 10 && descriptionValue.length <= 200
            }
        ],
        [descriptionValue]
    );

    const titleHasError = !!titleChecklist.find(item => item.isSuccess === false);
    const descriptionHasError = !!descriptionChecklist.find(item => item.isSuccess === false);

    const submitIsAvailable = (titleIsDirty || descriptionIsDirty) && !titleHasError && !descriptionHasError;

    useEffect(() => {
        if (titleIsDirty) {
            qsChannelEffects.checkTitleFx(titleValue);
        }
    }, [titleValue, titleIsDirty]);

    return (
        <Column noWrap width="100%">
            <Column marginBottom="24px" width="100%">
                <Section marginBottom="16px">
                    <InputField
                        wide
                        labelComponent={<Label icon={<QuickSignalSmIcon />} title="Title" />}
                        name="title"
                        placeholder="e.g. *name*’s Quick Signals Channel"
                    />
                </Section>

                <RulesChecklist items={titleChecklist} />
            </Column>

            <Column marginBottom="24px" width="100%">
                <Section marginBottom="16px">
                    <TextareaField
                        wide
                        labelComponent={<Label icon={<DescriptionSignalsSmIcon />} title="Description" />}
                        name="description"
                        placeholder="Add Description"
                    />
                </Section>

                <RulesChecklist items={descriptionChecklist} />
            </Column>

            <Button disabled={!submitIsAvailable} type="submit" wide={isMobile}>
                Save
            </Button>
        </Column>
    );
};
