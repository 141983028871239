export enum SettingsCategory {
    Null,
    Account,
    LinkedAccounts,
    ChangePassword,
    ForgotPassword,
    ChangeWalletPassword,
    Notifications,
    Feedback,
    FAQs,
    PrivacyPolicy,
    TermsAndConditions,
    About,
    InviteFriends
}
