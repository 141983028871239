import { baseColors } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Background, Diameter } from 'types/interfaces/styles';
import { defaultImgDiameter, defaultImgDiameterWrapper } from './constants';
import { AvatarImgStyledProps } from './types';

export const StyledImg = styled.img<AvatarImgStyledProps>`
    width: ${({ diameter }) => (diameter ? `calc(${diameter} - 4px)` : defaultImgDiameter)};
    min-width: ${({ diameter }) => (diameter ? `calc(${diameter} - 4px)` : defaultImgDiameter)};
    height: ${({ diameter }) => (diameter ? `calc(${diameter} - 4px)` : defaultImgDiameter)};
    min-height: ${({ diameter }) => (diameter ? `calc(${diameter} - 4px)` : defaultImgDiameter)};
    border-radius: 50%;
    border: 2px solid ${baseColors.black};
    object-fit: cover;
`;

interface WrapperProps extends Diameter, Background {}

export const Wrapper = styled.div<WrapperProps>`
    width: ${({ diameter }) => diameter || defaultImgDiameterWrapper};
    min-width: ${({ diameter }) => diameter || defaultImgDiameterWrapper};
    height: ${({ diameter }) => diameter || defaultImgDiameterWrapper};
    min-height: ${({ diameter }) => diameter || defaultImgDiameterWrapper};
    border-radius: 50%;
    background: ${({ background }) => background || 'linear-gradient(30deg, #f15f31, #4353ff)'};
    ${flexCenter};
    object-fit: cover;
`;
