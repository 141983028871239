import { LimitPageIndex } from 'types/interfaces/data';
import bucmAxios from './bucm-axios';

export const getCreatorCollaborations = (params: Paths.CreatorsCollaborations.Get.QueryParameters) =>
    bucmAxios<BUCM.PublicCreatorCollaborationsResponseApiResponse>({
        url: '/creators/collaborations',
        method: 'GET',
        params
    });

export const getCreatorCollaboration = (collaborationId: string) =>
    bucmAxios<BUCM.ActiveCollaborationResponseApiResponse>({
        url: `/creators/collaborations/${collaborationId}`,
        method: 'GET'
    });

export const getMyCollaborations = (userId: string, params: LimitPageIndex) =>
    bucmAxios<BUCM.UserEngagedCollaborationsResponseApiResponse>({
        url: `/creators/${userId}/collaborations`,
        method: 'GET',
        params
    });

export const getMyCollaboration = (userId: string, collaborationId: string) =>
    bucmAxios<BUCM.CreatorCollaborationResponseApiResponse>({
        url: `/creators/${userId}/collaborations/${collaborationId}`,
        method: 'GET'
    });

export const checkIsMyCollaboration = (userId: string, collaborationId: string) =>
    bucmAxios<BUCM.CreatorCollaborationResponseApiResponse>({
        url: `/creators/${userId}/collaborations/${collaborationId}`,
        method: 'HEAD'
    });

export const getCollaborationEarnings = (collaborationId: string) =>
    bucmAxios<BUCM.CollaborationEarningDetailsResponseApiResponse>({
        url: `/collaborations/${collaborationId}/earnings`,
        method: 'GET'
    });

export const getCollaborationVideoEarnings = (collaborationId: string, userId: string) =>
    bucmAxios<BUCM.CollaborationEarningDetailsResponseApiResponse>({
        url: `/collaborations/${collaborationId}/earnings/${userId}`,
        method: 'GET'
    });
