import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { TagsWrapper, TagWrapper } from 'components/pages/trending/HashtagSlider/styles';
import { Tag } from 'components/ui/Tag';
import { Text } from 'components/ui/typography/Text';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { trendingsEffects, trendingsStores } from 'stores/trendings';

const { getTrendings } = trendingsEffects;

export const HashtagSlider = () => {
    const hashTags = useStore(trendingsStores.hashtags);
    const history = useHistory();

    const handleTagClick = (tag: string) => {
        history.push(`${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&name=${tag}&tagId=${tag}`);
    };

    useEffect(() => {
        getTrendings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <MarginWrapper marginBottom="12px">
                <Text isBold size="1">
                    Trending Hashtags
                </Text>
            </MarginWrapper>

            <TagsWrapper>
                {hashTags.length > 0 &&
                    hashTags.map(({ tag }) => (
                        <TagWrapper key={tag}>
                            {tag && (
                                <Tag subject={tag} onClick={handleTagClick}>
                                    #{tag}
                                </Tag>
                            )}
                        </TagWrapper>
                    ))}
            </TagsWrapper>
        </>
    );
};
