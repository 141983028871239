import { Column } from 'components/grid/wrappers/FlexWrapper';
import { baseColors } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0;
    padding-bottom: 70px;

    ${media.laptop(`
        grid-template-columns: 166px auto;
        grid-gap: 32px;
        padding-bottom: 46px;
    `)};
`;

export const ContentWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding-left: 16px;
    padding-right: 16px;

    ${media.laptop(`
        padding-left: 0;
        padding-right: 0;
    `)};
`;

export const Poster = styled.div`
    width: 100%;
    height: 250px;
    position: relative;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const PropertiesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;

    ${media.laptop(`
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px 16px;
    `)};
`;

interface BackgroundWrapperProps {
    src?: string;
}

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>`
    width: 100%;
    padding: 16px;
    background-color: ${baseColors.darkGrey};
    ${({ src }) =>
        src && `background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url("${src}")`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 8px;
`;

export const PriceWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 10px;
    border-radius: 50px;
    background-color: ${baseColors.white};
`;
