import { HomeSmIcon, PlusMenuIcon, TrendingActiveSmIcon, TrendingSmIcon, WomSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { IconButton } from 'components/ui/buttons/IconButton';
import { AppRoute } from 'constants/routes';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { loginModal } from 'stores/initialize.modal';
import { BottomMenuWrapper, MenuCol, MenuGrid } from './styles';

const { $user } = authStores;

interface Props {
    handleUploadClick?: () => void;
}

export const BottomMenu: FC<Props> = ({ handleUploadClick }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const user = useStore($user);

    const isMatchRoute = (link: string) => pathname === link;

    const handleHomeClick = () => {
        history.push(AppRoute.Main);
    };

    const handleTrendingClick = () => {
        history.push(AppRoute.Trending);
    };

    const handleWomClick = () => {
        if (user) {
            history.push(AppRoute.Wallet);
        } else {
            loginModal.openModal();
        }
    };

    const handleProfileClick = () => {
        if (!user) {
            loginModal.openModal();
            return;
        }

        history.push(`${AppRoute.Profile}/${user.userId}`);
    };

    return (
        <BottomMenuWrapper>
            <MenuGrid>
                <MenuCol>
                    <IconButton
                        color={isMatchRoute(AppRoute.Main) ? baseColors.white : grey['500']}
                        size="40px"
                        onClick={handleHomeClick}
                    >
                        <HomeSmIcon height={27} width={25} />
                    </IconButton>
                </MenuCol>
                <MenuCol>
                    <IconButton color={grey['500']} size="40px" onClick={handleTrendingClick}>
                        {isMatchRoute(AppRoute.Trending) ? (
                            <TrendingActiveSmIcon height={30} width={21} />
                        ) : (
                            <TrendingSmIcon height={30} width={21} />
                        )}
                    </IconButton>
                </MenuCol>
                <MenuCol>
                    <IconButton color={grey['500']} size="40px" onClick={handleUploadClick}>
                        <PlusMenuIcon height={32} width={32} />
                    </IconButton>
                </MenuCol>
                <MenuCol>
                    <IconButton color={grey['500']} size="40px" onClick={handleWomClick}>
                        <WomSmIcon height={32} width={32} />
                    </IconButton>
                </MenuCol>
                <MenuCol>
                    <IconButton size="40px" onClick={handleProfileClick}>
                        <AvatarImg hasBorder src={user?.profile?.profileImageUrl || undefined} />
                    </IconButton>
                </MenuCol>
            </MenuGrid>
        </BottomMenuWrapper>
    );
};
