import { forward, sample } from 'effector';
import { fullscreenVideoEvents, fullscreenVideoStores } from 'stores/fullscreen-video';
import { homeEvents, homeStores } from 'stores/home';
import { loaderEffects } from 'stores/loader';
import { userVideosEffects, userVideosEvents, userVideosStores } from 'stores/user-videos';
import { videoEffects } from 'stores/video';
import { getVideoWithNewEngagements } from './utils';

const { deleteVideoFx } = userVideosEffects;
const { $myUserVideos } = userVideosStores;
const { updateVideos } = userVideosEvents;

sample({
    clock: videoEffects.registerEventVideo.done,
    source: fullscreenVideoStores.$video,
    fn: (video: BULLZ.PlaylistSingleVideoResponse, { params }: { params: BULLZ.RegisterAggregatedEventRequest }) =>
        video.id === params.videoId ? getVideoWithNewEngagements(video, params) : video,
    target: fullscreenVideoEvents.updateVideo
});

sample({
    clock: videoEffects.registerEventVideo.done,
    source: homeStores.$homePlaylist,
    fn: (
        playlist: BULLZ.CreatePlaylistForVideosResponse,
        { params }: { params: BULLZ.RegisterAggregatedEventRequest }
    ) => {
        if (playlist?.items) {
            const newItems = playlist?.items.map(video =>
                video.id === params.videoId ? getVideoWithNewEngagements(video, params) : video
            );
            return { ...playlist, items: newItems };
        }

        return playlist;
    },
    target: homeEvents.updatePlaylist
});

sample({
    clock: videoEffects.reportVideo.done,
    source: homeStores.$homePlaylist,
    fn: (playlist, { params }) => {
        if (playlist?.items) {
            const newItems = playlist?.items.filter(video => video.id !== params.videoId);
            return { ...playlist, items: newItems };
        }
        return playlist;
    },
    target: homeEvents.updatePlaylist
});

forward({
    from: videoEffects.reportVideo.fail,
    to: videoEffects.reportVideoFailFx
});

sample({
    clock: deleteVideoFx.done,
    source: $myUserVideos,
    fn: (
        sourceData: BULLZ.QueryUploadedVideosResponse,
        { params, result }: { params: BULLZ.DeleteVideoRequest; result: BULLZ.DeleteVideoResponse }
    ) => {
        if (result && sourceData?.items) {
            const newItems = sourceData?.items.filter(item => item.id !== params.videoId);
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: updateVideos
});

// Video upload statuses

forward({
    from: [videoEffects.beginWomVideoValidateFx.pending, videoEffects.restartWomVideoValidateFx.pending],
    to: loaderEffects.loaderFx
});

forward({
    from: [videoEffects.beginWomVideoValidateFx.doneData, videoEffects.restartWomVideoValidateFx.doneData],
    to: videoEffects.beginWomVideoValidateDoneFx
});

forward({
    from: [
        videoEffects.getValidVideoFx.fail,
        videoEffects.beginWomVideoValidateFx.fail,
        videoEffects.restartWomVideoValidateFx.fail
    ],
    to: videoEffects.failFx
});
