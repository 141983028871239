import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(Text)`
    width: 100%;
    border-radius: 10px;
    background-color: ${baseColors.darkGrey};
    padding: 4px 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${media.laptop(`
        padding: 20px 14px;
        background-color: ${baseColors.black};
    `)}
`;
