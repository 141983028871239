import { InfoSmIcon, WrongLgIcon } from 'assets/icons';
import { FormWrapper } from 'components/form/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import React, { FormEvent, MouseEvent, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { authStores } from 'stores/auth';
import { infoModal, uploadVideoModal } from 'stores/initialize.modal';
import { uploadVideoEffects } from 'stores/upload-video';
import { walletEffects, walletEvents, walletStores } from 'stores/wallet';
import { FillDataFormValues } from '../constants';
import { Warning } from './styles';

const { closeModal } = uploadVideoModal;

export const SubmitVideoForm = () => {
    const history = useHistory();
    const isPurchaseEnabled = useStore(walletStores.$isPurchaseEnabled);
    const user = useStore(authStores.$user);

    const submitIsActive = isPurchaseEnabled || !!user?.isFreeStakingEligible;

    const { values, isValid } = useFormikContext<FillDataFormValues>();

    const onCancel = () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: 'Are you sure you want to cancel?',
            text: 'If you proceed, you will lose your video.',
            buttonText: 'Go Back',
            cancelText: 'PROCEED',
            onCloseClick: () => closeModal()
        });
    };

    const goToWallet = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        closeModal();
        history.push(AppRoute.Wallet);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const { project, topic, website, category, language, videoFile } = values;

        const { videoUploadId } = await uploadVideoEffects.getNewVideoIdFx({
            brand: project,
            product: topic,
            productLink: website,
            categoryTagId: category,
            spokenLanguages: [language]
        });

        if (videoUploadId && videoFile) {
            uploadVideoEffects.uploadVideoFx({
                file: videoFile,
                videoUploadId
            });
        }
    };

    useEffect(() => {
        walletEffects.verifyPurchaseFx({
            value: 1.0
        });

        return () => {
            walletEvents.resetIsPurchaseEnabled();
        };
    }, []);

    return (
        <FormWrapper onSubmit={handleSubmit}>
            <Text isBold size="1">
                Submit Video
            </Text>
            <MarginWrapper marginBottom="42px" marginTop="12px">
                <Text color={grey[500]} size="-1">
                    In order to earn rewards you must stake WOM. When you stake, your video will be reviewed by the WOM
                    Authenticators and rated according to the following metrics:
                </Text>
            </MarginWrapper>
            <Section justifyBetween marginBottom="42px">
                <Text isBold size="1">
                    Authenticity
                </Text>
                <Text isBold size="1">
                    Creativity
                </Text>
                <Text isBold size="1">
                    Positivity
                </Text>
            </Section>
            <Text color={grey[500]} size="-1">
                If your video passes authentication, you can start earning WOM Tokens.
            </Text>
            <Section
                alignCenter
                justifyBetween
                marginBottom={isPurchaseEnabled && !isMobile ? '244px' : '38px'}
                marginTop="46px"
            >
                <Text color={grey[500]} size="-1">
                    This review requires a stake of:
                </Text>
                <Text isBold size="1">
                    1.0
                </Text>
            </Section>

            {!submitIsActive && (
                <Warning noWrap>
                    <MarginWrapper marginRight="18px">
                        <InfoSmIcon height={18} width={18} />
                    </MarginWrapper>
                    <Column>
                        <MarginWrapper marginBottom="8px">
                            <Text size="-2">
                                You do not have a sufficient amount of WOM to stake. Please purchase WOM now in order to
                                submit your video for review. Your video will be saved as a draft in your profile.
                            </Text>
                        </MarginWrapper>
                        <Text size="-2">
                            If you do not want to purchase WOM now, you can save your video to submit at a later time.
                        </Text>
                    </Column>
                </Warning>
            )}

            <Section alignCenter justifyBetween>
                <TextButton onClick={onCancel}>Cancel</TextButton>
                {submitIsActive ? (
                    <Button disabled={!isValid} type="submit">
                        Submit
                    </Button>
                ) : (
                    <Button onClick={goToWallet}>Buy WOM</Button>
                )}
            </Section>
        </FormWrapper>
    );
};
