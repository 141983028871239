import { sample } from 'effector';
import { userFollowedEvents, userFollowedStores } from 'stores/followed';
import { userFollowersEvents, userFollowersStores } from 'stores/followers';
import { fullscreenVideoEvents, fullscreenVideoStores } from 'stores/fullscreen-video';
import { homeEvents, homeStores } from 'stores/home';
import { qsEvents, qsStores } from 'stores/quick-signals-trending';
import { userEvents, userStores } from 'stores/user';
import { userFollowEffects } from './index';

sample({
    clock: userFollowEffects.followUserFx.done,
    source: homeStores.$homePlaylist,
    fn: (
        sourceData: BULLZ.CreatePlaylistForVideosResponse,
        { params, result }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData?.items) {
            const newItems = sourceData?.items.map(video => {
                if (video.ownerId === params.creatorId) {
                    return { ...video, isFollowed: params.isFollow };
                }
                return video;
            });
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: homeEvents.updatePlaylist
});

sample({
    clock: userFollowEffects.followUserFx.done,
    source: userStores.$userProfile,
    fn: (
        sourceData: BULLZ.GetUserProfileResponse,
        { params, result }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData.id === params.creatorId) {
            return { ...sourceData, isFollowed: params.isFollow };
        }

        return sourceData;
    },
    target: userEvents.updateProfile
});

sample({
    clock: userFollowEffects.followUserFx.done,
    source: userStores.$userProfile,
    fn: (
        sourceData: BULLZ.GetUserProfileResponse,
        { params, result }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData.followersCount && sourceData.id === params.creatorId) {
            if (params.isFollow) {
                return { ...sourceData, followersCount: sourceData.followersCount + 1, isFollowed: params.isFollow };
            } else {
                return { ...sourceData, followersCount: sourceData.followersCount - 1, isFollowed: params.isFollow };
            }
        }

        return sourceData;
    },
    target: userEvents.updateProfile
});

sample({
    clock: userFollowEffects.followUserFx.done,
    source: userFollowedStores.$userFollowed,
    fn: (
        sourceData: BULLZ.QueryFollowedUsersResponse,
        { params, result }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData?.items) {
            const newItems = sourceData?.items.map(followed => {
                if (followed.userId === params.creatorId) {
                    return { ...followed, isFollowed: params.isFollow };
                }
                return followed;
            });
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: userFollowedEvents.updateFollowed
});

sample({
    clock: userFollowEffects.followUserFx.done,
    source: userFollowersStores.$userFollowers,
    fn: (
        sourceData: BULLZ.QueryFollowersResponse,
        { params, result }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData?.items) {
            const newItems = sourceData?.items.map(followers => {
                if (followers.userId === params.creatorId) {
                    return { ...followers, isFollowed: params.isFollow };
                }
                return followers;
            });
            return { ...sourceData, items: newItems };
        }

        return sourceData;
    },
    target: userFollowersEvents.updateFollowers
});

sample({
    clock: userFollowEffects.followUserFx.done,
    source: fullscreenVideoStores.$video,
    fn: (
        sourceData: BULLZ.PlaylistSingleVideoResponse,
        { params, result }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        if (result.isSuccess && sourceData.ownerId === params.creatorId) {
            return { ...sourceData, isFollowed: params.isFollow };
        }

        return sourceData;
    },
    target: fullscreenVideoEvents.updateVideo
});

sample({
    clock: userFollowEffects.followUserFx.done,
    source: qsStores.$qsUsers,
    fn: (
        sourceData: BULLZ.GetQuickSignalUserResponse[],
        { params }: { params: BULLZ.UserFollowRequest; result: BULLZ.MessageResponseBase }
    ) => {
        const findIndex = sourceData.findIndex(item => item.userProfile?.id === params.creatorId);

        if (findIndex !== -1) {
            const profile = sourceData[findIndex].userProfile;

            if (profile) {
                profile.isFollowed = true;
                sourceData[findIndex].userProfile = profile;
            }
        }

        return sourceData;
    },
    target: qsEvents.updateUsers
});
