import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { infoModal, loginModal, uploadOptionModal, uploadVideoModal } from 'stores/initialize.modal';
import { quickSignalsStores } from 'stores/quick-signals';
import { walletStores } from 'stores/wallet';

const { $user } = authStores;
const { $quickSignalsInfo } = quickSignalsStores;
const { $womUserIsLoaded, $hasWallet } = walletStores;

export const useUploadClick = () => {
    const history = useHistory();

    const { data: qsData } = useStore($quickSignalsInfo);
    const womUserIsLoaded = useStore($womUserIsLoaded);
    const hasWallet = useStore($hasWallet);

    const user = useStore($user);

    const [myQSInfo] = (qsData || []) as [BULLZ.GetChannelOwnerInfoResponse | undefined];
    const hasMyChannel = !!myQSInfo;

    const handleUploadClick = () => {
        if (user) {
            if (hasMyChannel) {
                uploadOptionModal.openModal();
            } else {
                if (womUserIsLoaded && !hasWallet) {
                    infoModal.openModal({
                        title: 'Create your wallet',
                        text:
                            'Please create your wallet first in order to upload or create a video. This is required to ensure you can receive rewards! ',
                        buttonText: 'Create Wallet',
                        onClick: () => history.push(AppRoute.Wallet)
                    });
                } else {
                    uploadVideoModal.openModal();
                }
            }
        } else {
            loginModal.openModal();
        }
    };
    return handleUploadClick;
};
