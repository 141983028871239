import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { VideoSlide } from 'components/video/VideoSlide';
import React, { FC, useEffect, useState } from 'react';
import SwiperType, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { SliderWrapper, swiperStyles } from './styles';

interface Props {
    items?: BULLZ.PlaylistVideoResponse[] | null;
    isLoading?: boolean;
    onLastSlide?: () => void;
    onSlideChange?: (index: number) => void;
    activeItemIndex?: number;
}

export const VideoSlider: FC<Props> = ({ items, isLoading, onLastSlide, onSlideChange, activeItemIndex }) => {
    const [swiper, setSwiper] = useState<SwiperType | null>(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const handleSlideChange = ({ activeIndex, isEnd }: SwiperType) => {
        setCurrentSlideIndex(activeIndex);
        onSlideChange && onSlideChange(activeIndex);

        if (isEnd && onLastSlide) {
            onLastSlide();
        }
    };

    useEffect(() => {
        if (swiper && activeItemIndex && activeItemIndex > 0) {
            swiper.slideTo(activeItemIndex, 0);
        }
    }, [swiper, activeItemIndex]);

    return (
        <SliderWrapper>
            <Swiper
                virtual
                direction="vertical"
                modules={[Virtual]}
                slidesPerView={1}
                style={swiperStyles}
                onSwiper={swiper => setSwiper(swiper)}
                onTransitionEnd={handleSlideChange}
            >
                {items &&
                    items.map((item, index) => (
                        <SwiperSlide key={index} style={swiperStyles} virtualIndex={index}>
                            <VideoSlide isActive={index === currentSlideIndex} video={item} />
                        </SwiperSlide>
                    ))}

                {isLoading && (
                    <SwiperSlide style={swiperStyles}>
                        <Column alignCenter justifyCenter height="100%" width="100%">
                            <Spinner />
                        </Column>
                    </SwiperSlide>
                )}
            </Swiper>
        </SliderWrapper>
    );
};
