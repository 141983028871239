import { ButtonProps } from 'components/ui/buttons/Button';
import { baseColors, blue, green, grey, red } from 'constants/styles/colors';
import { MontserratFontFamily } from 'constants/styles/fonts';
import { allTransition, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

const buttonBase = css`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    font-family: ${MontserratFontFamily};
    color: ${baseColors.white};
    text-align: center;
    border-radius: 50px;
    ${allTransition};

    &:disabled {
        cursor: not-allowed;
    }
`;

const defaultDisabledStyles = css`
    background: none;
    color: ${grey['700']};
    border: 1px solid ${grey['700']};
`;

const primaryButton = css`
    background-color: ${baseColors.blue};

    &:hover:not(:disabled) {
        background-color: ${blue['400']};
    }

    &:active:not(:disabled) {
        background-color: ${blue['700']};
    }

    &:disabled {
        ${defaultDisabledStyles};
    }
`;

const redButton = css`
    background-color: ${baseColors.red};

    &:hover:not(:disabled) {
        background-color: ${red['500']};
    }

    &:active:not(:disabled) {
        background-color: ${red['700']};
    }

    &:disabled {
        background-color: ${red['200']};
    }
`;

const greenButton = css`
    background-color: ${baseColors.green};
    color: ${baseColors.black};

    &:hover:not(:disabled) {
        background-color: ${green['400']};
    }

    &:active:not(:disabled) {
        background-color: ${green['600']};
    }

    &:disabled {
        ${defaultDisabledStyles};
    }
`;

const secondaryButton = css`
    background-color: ${baseColors.white};
    color: ${baseColors.black};

    &:hover:not(:disabled) {
        background-color: ${grey['100']};
    }

    &:active:not(:disabled) {
        background-color: ${grey['300']};
    }

    &:disabled {
        ${defaultDisabledStyles};
    }
`;

const darkButton = css`
    background-color: ${baseColors.darkGrey};
    white-space: nowrap;
    color: ${grey[100]};

    &:hover:not(:disabled) {
        background-color: ${grey['800']};
    }

    &:active:not(:disabled) {
        background-color: ${grey['300']};
    }

    &:disabled {
        background: ${baseColors.darkGrey};
        color: ${grey['700']};
    }
`;

const transparentButton = css`
    background-color: transparent;
    white-space: nowrap;
    color: ${grey[100]};

    &:hover {
        background-color: ${baseColors.darkGrey};
    }
`;

const getTypeStyle = (props: ButtonProps) => {
    switch (props.buttonType) {
        case 'primary':
        default:
            return primaryButton;
        case 'red':
            return redButton;
        case 'green':
            return greenButton;
        case 'secondary':
            return secondaryButton;
        case 'dark':
            return darkButton;
        case 'transparent':
            return transparentButton;
    }
};

const defaultButtonSize = css`
    padding: 10px 40px;
`;

const smallButtonSize = css`
    padding: 7px 24px;
`;

const middleButtonSize = css`
    padding: 7px 10px;
`;

const getSizeStyle = (props: ButtonProps) => {
    switch (props.size) {
        case 'default':
        default:
            return defaultButtonSize;
        case 'small':
            return smallButtonSize;
        case 'middle':
            return middleButtonSize;
    }
};

const defaultTextSize = css`
    font-size: 14px;
    line-height: 20px;
`;

const smallTextSize = css`
    font-size: 12px;
    line-height: 18px;
`;

const getTextSizeStyle = (props: ButtonProps) => {
    switch (props.textSize) {
        case 'default':
        default:
            return defaultTextSize;
        case 'small':
            return smallTextSize;
    }
};

export const StyledButton = styled.button<ButtonProps>`
    ${buttonBase};
    font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
    ${props => getSizeStyle(props)};
    ${props => getTypeStyle(props)};
    ${props => getTextSizeStyle(props)};
    ${({ wide }) => wide && 'width: 100%'};
    white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
`;

export const IconWrapper = styled.i<Pick<ButtonProps, 'iconPosition'>>`
    flex-shrink: 1;
    ${flexCenter};
    ${({ iconPosition }) => (iconPosition === 'left' ? 'margin-right: 8px' : 'margin-left: 8px')};
`;
