import notFoundImage from 'assets/img/not-found.png';
import { CustomImage } from 'components/common/CustomImg/styles';
import { Wrapper } from 'components/common/NotFound/styles';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles';
import React from 'react';

export const NotFound = () => (
    <Wrapper>
        <CustomImage alt="not found" height="200px" src={notFoundImage} width="200px" />
        <Text color={grey[500]} size="-1">
            No Results Found
        </Text>
    </Wrapper>
);
