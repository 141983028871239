import { DeleteAccountLgIcon } from 'assets/icons';
import { createEffect, createEvent, createStore } from 'effector';
import { infoModal } from 'stores/initialize.modal';
import { NoopType } from 'types';

// Events

const setClosureAvailability = createEvent<boolean>();

// Effects

const closeSettingsFx = createEffect({
    handler: (cb?: NoopType) => {
        infoModal.openModal({
            icon: DeleteAccountLgIcon,
            title: 'Are you sure?',
            text: 'You have unsaved data. Your unsaved data will be lost. Are you sure you want to quit this page?',
            buttonText: 'Go Back',
            cancelText: 'Yes, I am sure',
            onCloseClick: () => {
                cb && cb();
                setClosureAvailability(true);
            }
        });
    }
});

// Stores

const $closureAvailability = createStore(true).on(setClosureAvailability, (_, payload) => payload);

// Exports

export const settingsEffects = {
    closeSettingsFx
};

export const settingsEvents = {
    setClosureAvailability
};

export const settingsStores = {
    $closureAvailability
};
