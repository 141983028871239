import { BackArrowSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import {
    EmptyBlock,
    InnerWrapper,
    SettingsTitle,
    Wrapper
} from 'components/pages/settings/components/CommonPart/styles';
import { SettingsCategory } from 'components/pages/settings/SettingsContent/constants';
import { IconButton } from 'components/ui/buttons/IconButton';
import React, { FC, ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { settingsPasswordEffects } from 'stores/settings/password';
import { NoopType } from 'types';
import { CurrentPasswordStep } from './CurrentPasswordStep';
import { NewPasswordStep } from './NewPasswordStep';

const { changePasswordFx } = settingsPasswordEffects;

enum Step {
    CurrentPassword,
    NewPassword
}

interface Props {
    onBack: NoopType;
    onTabChange: (value: SettingsCategory) => void;
}

export const ChangePassword: FC<Props> = ({ onBack, onTabChange }) => {
    const [currentStep, setCurrentStep] = useState(Step.CurrentPassword);
    const [currentPassword, setCurrentPassword] = useState('');

    const reset = () => {
        setCurrentStep(Step.CurrentPassword);
        setCurrentPassword('');
    };

    const handleCurrentPasswordStep = (currentPassword: string) => {
        setCurrentPassword(currentPassword);
        setCurrentStep(Step.NewPassword);
    };

    const handleNewPasswordStep = (newPassword: string) => {
        if (!!currentPassword && !!newPassword) {
            changePasswordFx({
                data: {
                    currentPassword,
                    newPassword
                },
                onSuccess: reset
            });
        }
    };

    const openForgotPasswordTab = () => {
        onTabChange(SettingsCategory.ForgotPassword);
    };

    const steps: Record<Step, ReactNode> = {
        [Step.CurrentPassword]: (
            <CurrentPasswordStep onForgot={openForgotPasswordTab} onSubmit={handleCurrentPasswordStep} />
        ),
        [Step.NewPassword]: <NewPasswordStep onCancel={reset} onSubmit={handleNewPasswordStep} />
    };

    return (
        <Wrapper>
            <Section alignCenter noWrap justifyBetween={isMobile} marginBottom={isMobile ? '20px' : '24px'}>
                {isMobile && (
                    <IconButton onClick={onBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                )}

                <SettingsTitle isBold size={isMobile ? '1' : '-1'}>
                    Change Password
                </SettingsTitle>

                {isMobile && <EmptyBlock />}
            </Section>

            <InnerWrapper>{steps[currentStep]}</InnerWrapper>
        </Wrapper>
    );
};
