import { VolumeSmIcon, XVolumeSmIcon } from 'assets/icons';
import { IconButton } from 'components/ui/buttons/IconButton';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { FC } from 'react';
import { handleStyles, railStyles, RangeWrapper, sliderStyles, trackStyles, VolumeWrapper } from './styles';

interface Props {
    hideRange?: boolean;
    isMuted: boolean;
    toggleMuteVolume: () => void;
    volume: number;
    setVolume: (newVolume: number) => void;
}

export const Volume: FC<Props> = ({ hideRange = false, isMuted, toggleMuteVolume, volume, setVolume }) => {
    const handleVolumeClick = () => {
        toggleMuteVolume();
    };

    const handleVolumeChange = (value: number | number[]) => {
        if (typeof value === 'number') {
            setVolume(value);
        }
    };

    return (
        <VolumeWrapper>
            <IconButton onClick={handleVolumeClick}>{isMuted ? <XVolumeSmIcon /> : <VolumeSmIcon />}</IconButton>

            {!hideRange && (
                <RangeWrapper>
                    <RcSlider
                        vertical
                        handleStyle={handleStyles}
                        max={1}
                        min={0}
                        railStyle={railStyles}
                        step={0.1}
                        style={sliderStyles}
                        trackStyle={trackStyles}
                        value={volume}
                        onChange={handleVolumeChange}
                    />
                </RangeWrapper>
            )}
        </VolumeWrapper>
    );
};
