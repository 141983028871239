import { createEffect } from 'effector';
import { API } from 'services';

// Effects

const followUserFx = createEffect({
    handler: async (data: BULLZ.UserFollowRequest) =>
        await API.userFollow.followUser({
            ...data
        })
});

export const userFollowEffects = {
    followUserFx
};
