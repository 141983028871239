import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const CollaborationsList = styled.div`
    width: 100%;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);

    ${media.laptop(`
        row-gap: 26px;
        column-gap: 16px;
        grid-template-columns: repeat(5, 1fr);
    `)}
`;

export const CollaborationWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    height: 140px;

    ${media.laptop(`
        height: 250px;
    `)}
`;
