import { CSSProperties } from 'react';
import styled from 'styled-components';

export const swiperStyles: CSSProperties = {
    width: '100%',
    height: '100%'
};

export const SliderWrapper = styled.div`
    width: 100%;
    height: 100%;
`;
