import { BackArrowSmIcon, ClockSmIcon, ErrorSmIcon, VerificationSmIcon } from 'assets/icons';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper/styles';
import { CardWrapper, IconWrapper, Separator, Status } from 'components/pages/wallet/Activities/ActivityCard/styles';
import { activityStatuses, inComeTransaction, StatusesUi, transactionType } from 'components/pages/wallet/constants';
import { getFormattedTime } from 'components/pages/wallet/utils';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles';
import React, { FC, useMemo } from 'react';

interface Props {
    item: WOM.TransactionResponse;
    walletInfo?: WOM.AddressResponse;
}

export const ActivityCard: FC<Props> = ({ item: { utcCreated, status, value, type, to }, walletInfo }) => {
    const getIcon = (status?: number) => {
        switch (status) {
            case StatusesUi.Completed:
                return VerificationSmIcon;
            case StatusesUi.Failed:
                return ErrorSmIcon;
            case StatusesUi.None:
                return ErrorSmIcon;
            case StatusesUi.Pending:
            default:
                return ClockSmIcon;
        }
    };

    const Icon = getIcon(status);

    const isInCome = useMemo(() => {
        const currentType = type || 0;

        return inComeTransaction.includes(currentType) || to === walletInfo?.address;
    }, [to, type, walletInfo]);

    return (
        <CardWrapper>
            <Row alignCenter>
                <IconWrapper isTransform={isInCome}>
                    <BackArrowSmIcon height="12px" width="13px" />
                </IconWrapper>

                <Column justifyBetween marginLeft="18px">
                    <Row marginBottom="10px">
                        <Text size="-1">{transactionType[type || 0]}</Text>
                    </Row>

                    <Row alignCenter>
                        <Column marginRight="10px">
                            <Icon height="20px" width="20px" />
                        </Column>

                        <Status status={activityStatuses[status || StatusesUi.Pending]}>
                            {activityStatuses[status || StatusesUi.Pending]}
                        </Status>

                        <Separator />

                        <Text color={baseColors.lightGrey} size="-2">
                            {getFormattedTime(utcCreated)}
                        </Text>
                    </Row>
                </Column>
            </Row>

            <Text color={isInCome ? baseColors.white : baseColors.red} size="-2">
                {isInCome ? value : `-${value}`} WOM
            </Text>
        </CardWrapper>
    );
};
