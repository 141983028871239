import { WrongLgIcon } from 'assets/icons';
import { InputField } from 'components/form/InputField';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { VideoInput } from 'components/ui/inputs/VideoInput';
import { asyncErrorMessage } from 'constants/messages';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { FormikProvider, useFormik } from 'formik';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { infoModal, uploadQsModal } from 'stores/initialize.modal';
import { quickSignalsEffects } from 'stores/quick-signals';
import { NoopType } from 'types';
import { goToHelpDesk, noop } from 'utils/common';
import { QsFormValues, qsFormSchema, qsFormValues } from './constants';
import { Form, Wrapper } from './styles';

const { $user } = authStores;
const { createNewQsVideoFx, uploadQsVideoFx } = quickSignalsEffects;
const { closeModal } = uploadQsModal;

interface Props {
    setHasFile: Dispatch<SetStateAction<boolean>>;
    onClose?: NoopType;
}

export const UploadQsForm: FC<Props> = ({ setHasFile, onClose }) => {
    const history = useHistory();

    const user = useStore($user);
    const createIsLoading = useStore(createNewQsVideoFx.pending);
    const uploadIsLoading = useStore(uploadQsVideoFx.pending);
    const [videoFile, setVideoFile] = useState<File | null>(null);

    const isLoading = createIsLoading || uploadIsLoading;

    const upload = async (values: QsFormValues) => {
        try {
            const { videoUploadId } = await createNewQsVideoFx(values.website);

            if (videoUploadId && values.videoFile) {
                await uploadQsVideoFx({
                    videoUploadId,
                    file: values.videoFile
                });

                closeModal();

                infoModal.openModal({
                    title: 'Success!',
                    text:
                        'Your Quick Signal is live! People who have joined your Channel will receive a notification to check it out.',
                    buttonText: 'See All My Quick Signals',
                    cancelText: 'See All My Videos',
                    onClick: () => history.push(AppRoute.QuickSignals),
                    onCloseClick: () =>
                        history.push(user ? `${AppRoute.Profile}/${user.userId}` : AppRoute.QuickSignals)
                });
            } else {
                throw new Error();
            }
        } catch {
            infoModal.openModal({
                icon: WrongLgIcon,
                title: asyncErrorMessage,
                text: 'There was a problem posting the video. Please try again or contact our support team.',
                buttonText: 'Try Again',
                cancelText: 'CONTACT SUPPORT',
                onClick: noop,
                onCloseClick: goToHelpDesk
            });
        }
    };

    const formik = useFormik({
        initialValues: qsFormValues,
        validationSchema: qsFormSchema,
        onSubmit: upload
    });

    const { isValid, setFieldValue } = formik;

    useEffect(() => {
        setFieldValue('videoFile', videoFile || null);
        setHasFile(videoFile ? true : false);
    }, [setFieldValue, setHasFile, videoFile]);

    return (
        <Wrapper>
            <Section justifyCenter marginBottom="16px">
                <VideoInput
                    isTopControlsVisible
                    durationHint="less than 1 min"
                    maxDuration={60}
                    minDuration={5}
                    resolutionHint="1080x1920 no more"
                    setFile={setVideoFile}
                    size={1024}
                    sizeHint="1 GB"
                />
            </Section>

            <FormikProvider value={formik}>
                <Form>
                    <Section justifyCenter marginBottom="16px">
                        <InputField label="Website URL" name="website" placeholder="e.g. https://metamask.io" />
                    </Section>

                    <Section alignCenter justifyBetween>
                        <TextButton onClick={onClose}>Cancel</TextButton>

                        <Button disabled={!isValid || isLoading} type="submit">
                            Next
                        </Button>
                    </Section>
                </Form>
            </FormikProvider>
        </Wrapper>
    );
};
