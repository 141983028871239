import NotFound from 'assets/img/not-found.png';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { ActivityCard } from 'components/pages/wallet/Activities/ActivityCard';
import { Empty, Image } from 'components/pages/wallet/Activities/styles';
import { prepareTransactions } from 'components/pages/wallet/utils';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { walletAccountType } from 'constants/global';
import { defaultWalletDataQueryParams } from 'constants/services/wallet-data';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { walletDataEffects, walletDataStores } from 'stores/wallet-data';

const { $transactionsInfo, $hasErrorTransactions, $walletInfo } = walletDataStores;
const { getTransactionsInfoFx, loadMoreTransactionsFx } = walletDataEffects;

export const Activities = () => {
    const { items, currentPageIndex, totalPages } = useStore($transactionsInfo);
    const hasErrorTransactions = useStore($hasErrorTransactions);

    const isLoading = useStore(getTransactionsInfoFx.pending);
    const isMoreLoading = useStore(loadMoreTransactionsFx.pending);

    const { items: walletItems } = useStore($walletInfo);

    const walletInfo = walletItems?.find(item => item.accountType === walletAccountType);

    const transactionData = useMemo(() => prepareTransactions(items), [items]);

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreTransactionsFx({
                    ...defaultWalletDataQueryParams,
                    pageIndex: currentPageIndex + 1
                });
        },
        disabled: hasErrorTransactions,
        rootMargin: '0px'
    });

    useEffect(() => {
        getTransactionsInfoFx({});
    }, []);

    return (
        <Section>
            <Section justifyCenter={isMobile} marginBottom={isMobile ? '8px' : '16px'}>
                <Text isBold size="1">
                    Your Activity
                </Text>
            </Section>

            {transactionData.length > 0 && (
                <>
                    <Column width="100%">
                        {transactionData.map(data => (
                            <Column key={data.date} marginBottom={isMobile ? '10px' : '20px'} width="100%">
                                <Section justifyCenter={isMobile} marginBottom="8px">
                                    <Text color={baseColors.lightGrey}>{data.date}</Text>
                                </Section>

                                {data.items.length > 0 &&
                                    data.items.map(item => (
                                        <Section key={item.transactionId} marginBottom="8px">
                                            <ActivityCard item={item} walletInfo={walletInfo} />
                                        </Section>
                                    ))}
                            </Column>
                        ))}
                    </Column>
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}

            {(isLoading || isMoreLoading) && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}

            {(!transactionData || transactionData.length === 0) && !isLoading && (
                <Empty>
                    <Image src={NotFound} />
                    <Text alignTextCenter color={grey[500]} size={isMobile ? '-2' : '-1'}>
                        No transactions found
                    </Text>
                </Empty>
            )}
        </Section>
    );
};
