import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { Tabs } from 'components/ui/Tabs';
import { QuickSignalsTabsType } from 'constants/enum';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { TabContentMembers } from '../TabContentMembers';
import { TabContentVideos } from '../TabContentVideos';

interface QueryProps {
    tab?: string;
}

export const QuickSignalsTabs = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState(QuickSignalsTabsType.Signals);

    const history = useHistory();

    const handleTabsChange = (value: QuickSignalsTabsType) => {
        setActiveTab(value);
    };

    useEffect(() => {
        const { tab } = queryString.parse(history.location.search) as QueryProps;

        if (tab) {
            ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });

            if (tab === 'subs') {
                setActiveTab(QuickSignalsTabsType.Members);
            }
        }
    }, [history.location.search]);

    return (
        <Column ref={ref} noWrap height="100%" width="100%">
            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: QuickSignalsTabsType.Signals,
                        title: 'My Signals',
                        content: <TabContentVideos />,
                        width: isMobile ? '50%' : '175px'
                    },
                    {
                        id: QuickSignalsTabsType.Members,
                        title: 'Joined my Channel',
                        content: <TabContentMembers />,
                        width: isMobile ? '50%' : '175px'
                    }
                ]}
                onChange={handleTabsChange}
            />
        </Column>
    );
};
