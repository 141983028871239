import { SearchItemType } from 'components/grid/Header/SearchDropdown';
import { SearchTabContent } from 'components/grid/Header/SearchDropdown/SearchTabContent';
import { SearchCategory } from 'constants/enum';
import { useStore } from 'effector-react';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { categoriesEffects, categoriesStores } from 'stores/categories';
import { searchEvents, searchStores } from 'stores/search';
import { tagsEffects, tagsEvents, tagsStores } from 'stores/tags';
import { NoopType } from 'types';

const { getSearchItemsFx, setDefaultSearchItems } = searchEvents;
const { getTagsFx } = tagsEffects;
const { resetTags } = tagsEvents;

export const useSearchTabs = (searchText: string, onClear: NoopType) => {
    const [activeTab, setActiveTab] = useState(SearchCategory.Topic);
    const searchItems = useStore(searchStores.searchItems);
    const categories = useStore(categoriesStores.$categories);
    const tags = useStore(tagsStores.$tags);

    const getItems = (value: string) => {
        if (value.length > 0) {
            getSearchItemsFx({
                searchText: value
            });
            getTagsFx({
                search: value
            });
        } else {
            setDefaultSearchItems();
            resetTags();
        }
    };

    const onChange = (value: SearchCategory) => {
        setActiveTab(value);
    };

    const debouncedValue = useDebounce(searchText, 500);

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    useEffect(() => {
        getItems(debouncedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const getTopicsTab = () => {
        const topics = searchItems.filter(item => item.type === SearchItemType.Topic);

        return {
            id: SearchCategory.Topic,
            title: `Topics (${topics.length})`,
            content: (
                <SearchTabContent
                    items={topics}
                    searchValue={searchText}
                    type={SearchCategory.Topic}
                    onItemClick={onClear}
                />
            )
        };
    };

    const getCreatorsTab = () => {
        const creators = searchItems.filter(item => item.type === SearchItemType.User);

        return {
            id: SearchCategory.Creator,
            title: `Creators (${creators.length})`,
            content: (
                <SearchTabContent
                    items={creators}
                    searchValue={searchText}
                    type={SearchCategory.Creator}
                    onItemClick={onClear}
                />
            )
        };
    };

    const getHashtagsTab = () => {
        const preparedTags =
            tags?.items?.map(tag => ({
                entityId: tag.tagId,
                title: `${tag.categoryInfo?.emojiCode ? `${tag.categoryInfo?.emojiCode} ` : ''}${tag.displayName}`,
                isCategory: tag.isCategory
            })) || [];
        const preparedCategories = categories?.items?.map(category => ({
            entityId: category.tagId,
            title: `${category.emojiCode} ${category.translation?.displayName}`,
            isCategory: true
        }));

        return {
            id: SearchCategory.Hashtag,
            title: `Hashtags (${preparedTags?.length > 0 ? preparedTags.length : preparedCategories?.length || 0})`,
            content: (
                <SearchTabContent
                    isTabHashtag={!!(tags || categories)}
                    items={preparedTags?.length > 0 ? preparedTags : preparedCategories || []}
                    searchValue={searchText}
                    type={SearchCategory.Hashtag}
                    onItemClick={onClear}
                />
            )
        };
    };

    const topicsTab = getTopicsTab();
    const creatorsTab = getCreatorsTab();
    const hashtagsTab = getHashtagsTab();

    return { tabs: [topicsTab, creatorsTab, hashtagsTab], onChange, activeTab };
};
