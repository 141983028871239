import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 12px;

    ${media.laptop(`
        width: calc(100% - 244px);
        margin-top: 0;
    `)};
`;
