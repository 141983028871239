import { FormWrapper } from 'components/form/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ProgressButton } from 'components/ui/buttons/ProgressButton';
import { SelectButton } from 'components/ui/buttons/SelectButton';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { FormEvent, useEffect } from 'react';
import { authStores } from 'stores/auth';
import { categoriesEffects, categoriesStores } from 'stores/categories';
import { registerEffects } from 'stores/register';
import { AdditionalRegisterFieldName, minimumAmountOfCategories } from '../constants';
import { SelectGrid } from './styles';

const { updateAccountFx } = registerEffects;
const { $user } = authStores;

export const CategoriesStep = () => {
    const user = useStore($user);
    const categories = useStore(categoriesStores.$categories);

    const [, { value: categoriesValue, error: categoriesError }, { setValue: setCategoriesValue }] = useField<string[]>(
        AdditionalRegisterFieldName.Categories
    );

    const [, { value: dayValue }] = useField<string>(AdditionalRegisterFieldName.Day);
    const [, { value: monthValue }] = useField<string>(AdditionalRegisterFieldName.Month);
    const [, { value: yearValue }] = useField<string>(AdditionalRegisterFieldName.Year);
    const [, { value: genderValue }] = useField<BULLZ.UserGender | undefined>(AdditionalRegisterFieldName.Gender);

    const isSubmitActive =
        categoriesValue !== undefined && categoriesValue.length > minimumAmountOfCategories && !categoriesError;
    const progressInPercent = (categoriesValue.length / (minimumAmountOfCategories + 1)) * 100;

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    const handleCategoriesChange = (value: string) => {
        if (categoriesValue.includes(value)) {
            setCategoriesValue(categoriesValue.filter(item => value !== item));
        } else {
            setCategoriesValue([...categoriesValue, value]);
        }
    };

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!!categoriesError || !user) {
            return;
        }

        const birthday = `${yearValue}-${monthValue.padStart(2, '0')}-${dayValue.padStart(2, '0')}`;

        await updateAccountFx({
            userId: user.userId,
            dateOfBirth: birthday,
            userGender: genderValue,
            categoryTagIds: categoriesValue
        });
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Column width="100%">
                <Section marginBottom="8px">
                    <Text size="-1">Please choose a minimum of 3 categories</Text>
                </Section>
                <SelectGrid>
                    {categories.items &&
                        categories.items.map(({ tagId, translation, emojiCode }) => (
                            <SelectButton
                                key={tagId}
                                wide
                                selected={categoriesValue !== undefined && categoriesValue.includes(tagId || '')}
                                type="button"
                                onClick={() => handleCategoriesChange(tagId || '')}
                            >
                                {`${emojiCode} ${translation?.displayName}`}
                            </SelectButton>
                        ))}
                </SelectGrid>
            </Column>

            <Section>
                <ProgressButton disabled={!isSubmitActive} progress={progressInPercent} type="submit">
                    Next
                </ProgressButton>
            </Section>
        </FormWrapper>
    );
};
