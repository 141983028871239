import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles';
import React, { FC } from 'react';

export const FieldTitle: FC<{ maxLetters?: number; currentLetters?: number; title: string }> = ({
    title,
    currentLetters,
    maxLetters = 20
}) => (
    <Section alignCenter justifyBetween marginBottom="8px">
        <Text size="-1">{title}</Text>
        {currentLetters !== undefined && (
            <Text
                color={currentLetters > maxLetters ? baseColors.red : baseColors.white}
                size="-1"
            >{`${currentLetters}/${maxLetters}`}</Text>
        )}
    </Section>
);
