import { Section } from 'components/grid/wrappers/FlexWrapper';
import { AlertProps } from 'components/ui/Alert';
import { baseColors } from 'constants/styles';
import styled, { css } from 'styled-components';

const getTypeStyles = ({ type }: Pick<AlertProps, 'type'>) => {
    switch (type) {
        case 'primary':
        default:
            return css`
                background-color: ${baseColors.blue};
            `;
        case 'danger':
            return css`
                background-color: ${baseColors.red};
            `;
        case 'dark':
            return css`
                background-color: ${baseColors.black};
            `;
    }
};

export const AlertWrapper = styled(Section)<Pick<AlertProps, 'type'>>`
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    ${props => getTypeStyles(props)};
    cursor: pointer;
`;
