import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const WrapperCard = styled.div`
    position: relative;
    width: 100%;
    height: 140px;
    ${flexCenter};
    cursor: pointer;
    background-color: ${baseColors.darkGrey};
    overflow: hidden;

    ${media.laptop(`
        height: 250px;
    `)};
`;

export const PreviewImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;

    ${media.laptop(`
        height: auto;
    `)};
`;

export const FeaturesWrapper = styled.div`
    position: absolute;
    bottom: 8px;
    left: 4px;
    z-index: 1;

    ${media.laptop(`
        left: 8px;
    `)}
`;

export const Views = styled.div`
    height: 24px;
    background-color: ${baseColors.black};
    mix-blend-mode: normal;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 3px 6px;
`;

export const ValidationStatusWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
    z-index: 1;

    ${media.laptop(`
        padding-left: 8px;
        padding-right: 8px;
    `)}
`;

export const InfoButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 8px;
    right: 4px;
    z-index: 2;

    ${media.laptop(`
        right: 8px;
    `)}
`;

export const WrapperDeleting = styled.div`
    position: absolute;
    top: calc((140px - 20px) / 2);
    left: calc((100% - 20px) / 2);
    z-index: 7;

    ${media.laptop(`
        top: calc((250px - 52px) / 2);
        left: calc((100% - 52px) / 2);
    `)}
`;
