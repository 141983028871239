import { CustomImage } from 'components/common/CustomImg/styles';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles';
import React, { FC, ReactNode, useState } from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { useHistory } from 'react-router';
import { NoopType } from 'types';
import { IconWrapper, StyledButton, StyledText, TooltipContainer } from './styles';

export interface OptionItemProps {
    id: number | string;
    icon?: string;
    iconComponent?: ReactNode;
    title: string;
    link?: string;
    action?: NoopType;
}

interface Props extends Config {
    width?: string;
    items?: OptionItemProps[];
    noPadding?: boolean;
    renderItems?: ReactNode;
}

export const OptionsPopover: FC<Props> = ({
    width = '228px',
    children,
    items = [],
    renderItems,
    trigger = 'click',
    placement = 'bottom-end',
    closeOnOutsideClick = true,
    noPadding = false,
    ...props
}) => {
    const [controlledVisible, setControlledVisible] = useState(false);
    const history = useHistory();
    const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
        trigger,
        closeOnOutsideClick,
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
        placement,
        offset: [0, 10],
        ...props
    });

    const handleClick = ({ link, action }: Pick<OptionItemProps, 'link' | 'action'>) => () => {
        if (link) {
            history.push(link);
        }
        if (action) {
            action();
        }
    };

    return (
        <>
            <div ref={setTriggerRef}>{children}</div>
            {visible && (
                <TooltipContainer ref={setTooltipRef} {...getTooltipProps()} noPadding={noPadding}>
                    <Column width={width}>
                        {renderItems ? (
                            renderItems
                        ) : (
                            <>
                                {items &&
                                    items.map(({ title, id, icon, link, action, iconComponent }) => (
                                        <StyledButton key={id} onClick={handleClick({ link, action })}>
                                            {icon && (
                                                <IconWrapper>
                                                    <CustomImage
                                                        color={grey[500]}
                                                        height="24px"
                                                        src={icon}
                                                        width="24px"
                                                    />
                                                </IconWrapper>
                                            )}
                                            {iconComponent && <IconWrapper>{iconComponent}</IconWrapper>}
                                            <StyledText size="-2">{title}</StyledText>
                                        </StyledButton>
                                    ))}
                            </>
                        )}
                    </Column>
                </TooltipContainer>
            )}
        </>
    );
};
