import { forward } from 'effector';
import { uploadVideoModal } from 'stores/initialize.modal';
import { loaderEffects } from 'stores/loader';
import { uploadVideoEffects } from 'stores/upload-video';

const { getNewVideoIdFx, uploadVideoFx, uploadFailFx, uploadDoneFx } = uploadVideoEffects;

forward({
    from: [getNewVideoIdFx.pending, uploadVideoFx.pending],
    to: loaderEffects.loaderFx
});

forward({
    from: uploadVideoFx.doneData,
    to: uploadVideoModal.closeModal
});

forward({
    from: uploadVideoFx.doneData,
    to: uploadDoneFx
});

forward({
    from: [getNewVideoIdFx.fail, uploadVideoFx.fail],
    to: uploadFailFx
});
