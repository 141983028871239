import { baseColors, blue } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';
import { CollaborationUserStateUI } from './utils';

interface WrapperProps {
    src?: string;
}

export const Wrapper = styled.div<WrapperProps>`
    width: 100%;
    height: 140px;
    padding: 8px;
    cursor: pointer;
    background-color: ${baseColors.darkGrey};
    background-image: ${({ src }) => src && `url("${src}")`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;

    ${media.laptop(`
        height: 250px;
    `)};
`;

interface StatusWrapper {
    state: CollaborationUserStateUI;
}

const getColor = (state: CollaborationUserStateUI) => {
    switch (state) {
        case CollaborationUserStateUI.CheckResult:
        case CollaborationUserStateUI.Submit:
            return blue[300];
        default:
            return baseColors.white;
    }
};

export const StatusWrapper = styled.div<StatusWrapper>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 7px 4px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.4);
    color: ${({ state }) => getColor(state)};
`;
