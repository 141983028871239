import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;

    ${media.laptop(`
        max-width: calc(100% - 245px);
    `)};
`;
