import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import styled from 'styled-components';

export const HomeMainWrapper = styled(Section)`
    flex-wrap: nowrap;
    position: relative;
`;

export const HomeVideosWrapper = styled(Column)`
    max-width: 100%;
    flex-wrap: nowrap;
    flex-grow: 1;
    padding-right: 60px;
`;

export const HomeSideWrapper = styled(Column)`
    width: 230px;
    margin-left: 16px;
    flex-shrink: 0;
`;

export const HomeMainMobileWrapper = styled.div`
    position: fixed;
    top: ${headerHeight};
    left: 0;
    right: 0;
    bottom: ${headerHeight};
    z-index: 1;
    width: 100%;
    height: calc(100% - (${headerHeight} * 2));
`;

const sideWidth = 230;
const sideMargin = 16;

export const SideWrapper = styled(Column)`
    width: ${sideWidth}px;
    margin-left: ${sideMargin}px;
`;
