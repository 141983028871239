import { CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { FormWrapper, IconWrapper, SelectGrid } from 'components/modals/ReportVideoModal/styles';
import { Button } from 'components/ui/buttons/Button';
import { SelectButton } from 'components/ui/buttons/SelectButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { useStore } from 'effector-react';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { reportUserModal } from 'stores/initialize.modal';
import { userEffects } from 'stores/user';
import { noop } from 'utils/common';
import { initialValues, userReportReasons, validationSchema } from './constants';
import { UserReportProps } from './types';

const { modal, closeModal } = reportUserModal;

export const ReportUserModal = () => {
    const [visible, { userId }] = useStore(modal);

    const handleClose = () => closeModal();

    const { isValid, dirty, values, setFieldValue, handleSubmit, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: noop
    });

    const handleClick = ({ userId, reason }: UserReportProps, isBlock: boolean) => {
        userEffects.reportUserFx({
            reportedUserId: userId,
            reportedReason: reason,
            requestUserBlocking: isBlock
        });

        handleClose();
        resetForm();
    };

    useEffect(() => {
        setFieldValue('userId', userId);
    }, [setFieldValue, userId]);

    return (
        <ModalWrapper visible={visible} onClose={handleClose}>
            <FormWrapper onSubmit={handleSubmit}>
                <Column alignCenter>
                    <Section justifyEnd marginBottom="22px">
                        <IconWrapper type="button" onClick={handleClose}>
                            <CloseSmIcon />
                        </IconWrapper>
                    </Section>
                    <Section justifyCenter marginBottom="16px">
                        <Title size="4">Report Account</Title>
                    </Section>
                    <Section justifyCenter marginBottom="16px">
                        <Text alignTextCenter size="-2">
                            We are sorry that you found this account inappropriate. Please indicate what you found
                            unacceptable about the account and the action you would like us to undertake.
                        </Text>
                    </Section>
                    <SelectGrid>
                        {Object.entries(userReportReasons).map(([reasonType, title]) => (
                            <SelectButton
                                key={reasonType}
                                wide
                                selected={values.reason?.toString() === reasonType}
                                type="button"
                                onClick={() => setFieldValue('reason', reasonType)}
                            >
                                {title}
                            </SelectButton>
                        ))}
                    </SelectGrid>
                    <Section justifyCenter marginBottom="8px">
                        <Text alignTextCenter size="-2">
                            What happens now?
                        </Text>
                    </Section>
                    <Section marginBottom="28px">
                        <Text alignTextCenter size="-2">
                            We will review your feedback and take appropriate action.
                        </Text>
                    </Section>
                    <Section marginBottom="16px">
                        <Button wide disabled={!isValid || !dirty} onClick={() => handleClick(values, false)}>
                            Report
                        </Button>
                    </Section>

                    <Section marginBottom="16px">
                        <Button wide disabled={!isValid || !dirty} onClick={() => handleClick(values, true)}>
                            Report & Block
                        </Button>
                    </Section>
                </Column>
            </FormWrapper>
        </ModalWrapper>
    );
};
