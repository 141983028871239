import { InputField } from 'components/form/InputField';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import {
    atLeastOneNumberRequiredRegExp,
    oneCapitalCharRequiredRegExp,
    oneSpecialCharacterRequiredRegExp
} from 'constants/regExp';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { FC, FormEvent, useMemo } from 'react';
import { authEffects } from 'stores/auth';
import { NoopType } from 'types';
import { ForgotPasswordFieldName, Step } from '../constants';
import { ISetCurrentStep } from '../types';

interface Props extends ISetCurrentStep {
    onCancel: NoopType;
}

export const PasswordStep: FC<Props> = ({ setCurrentStep, onCancel }) => {
    const isLoading = useStore(authEffects.forgotPasswordFx.pending);

    const [, { value: mobileNumberValue }] = useField<string>(ForgotPasswordFieldName.MobileNumber);
    const [, { value: smsVerificationCodeValue }] = useField<string>(ForgotPasswordFieldName.SmsVerificationCode);
    const [, { value: passwordValue, error: passwordError, touched: passwordTouched }] = useField<string>(
        ForgotPasswordFieldName.Password
    );
    const [
        ,
        { value: confirmPasswordValue, error: confirmPasswordError, touched: confirmPasswordTouched }
    ] = useField<string>(ForgotPasswordFieldName.ConfirmPassword);

    const submitIsActive = passwordTouched && !passwordError && confirmPasswordTouched && !confirmPasswordError;

    const passwordChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: '6 or more characters',
                isSuccess: passwordValue.length >= 6
            },
            {
                title: 'Include a capital letter',
                isSuccess: oneCapitalCharRequiredRegExp.test(passwordValue)
            },
            {
                title: 'Include a number',
                isSuccess: atLeastOneNumberRequiredRegExp.test(passwordValue)
            },
            {
                title: 'Include a special character - _& . $',
                isSuccess: oneSpecialCharacterRequiredRegExp.test(passwordValue)
            }
        ],
        [passwordValue]
    );

    const confirmPasswordChecklist: RulesChecklistItem[] = useMemo(
        () => [
            {
                title: 'Passwords match',
                isSuccess: !!passwordValue && passwordValue === confirmPasswordValue
            }
        ],
        [confirmPasswordValue, passwordValue]
    );

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!mobileNumberValue || !smsVerificationCodeValue || !passwordValue || !submitIsActive) return;

        authEffects
            .forgotPasswordFx({
                mobileNumber: mobileNumberValue,
                validationCode: smsVerificationCodeValue,
                newPassword: passwordValue
            })
            .then(() => {
                setCurrentStep(Step.Phone);
            });
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section marginBottom="16px">
                <InputField name={ForgotPasswordFieldName.Password} placeholder="Add password" type="password" />
            </Section>
            <Section marginBottom="16px">
                <RulesChecklist items={passwordChecklist} />
            </Section>

            <Section marginBottom="16px">
                <InputField
                    name={ForgotPasswordFieldName.ConfirmPassword}
                    placeholder="Confirm password"
                    type="password"
                />
            </Section>
            <Section marginBottom="16px">
                <RulesChecklist items={confirmPasswordChecklist} />
            </Section>

            <Section alignCenter justifyBetween>
                <TextButton onClick={onCancel}>Cancel</TextButton>

                <Button disabled={!submitIsActive || isLoading} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
