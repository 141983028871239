import { SearchItemType } from 'components/grid/Header/SearchDropdown';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { SearchTabContent } from 'components/pages/search/SearchList/SearchTabContent';
import { SearchInput } from 'components/ui/inputs/SearchInput';
import { Tabs } from 'components/ui/Tabs';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { useDebounce } from 'hooks/useDebounce';
import { useSearchInput } from 'hooks/useSearchInput';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { categoriesStores } from 'stores/categories';
import { searchEvents, searchStores } from 'stores/search';
import { tagsEffects, tagsStores } from 'stores/tags';
import { Container } from './styles';

const { getSearchItemsFx } = searchEvents;
const { getTagsFx } = tagsEffects;

interface QueryProps {
    query?: string;
    type?: string;
}

export const SearchList = () => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(SearchCategory.Topic);
    const searchItems = useStore(searchStores.searchItems);
    const isLoading = useStore(searchStores.loading);
    const searchValue = useStore(searchStores.$searchValue);
    const tags = useStore(tagsStores.$tags);
    const searchInput = useSearchInput();
    const { value } = searchInput;
    const debouncedValue = useDebounce(value, 500);

    const topics = searchItems.filter(item => item.type === SearchItemType.Topic);
    const creators = searchItems.filter(item => item.type === SearchItemType.User);
    const categories = useStore(categoriesStores.$categories);
    const preparedTags =
        tags?.items?.map(tag => ({
            entityId: tag.tagId,
            title: `${tag.categoryInfo?.emojiCode ? `${tag.categoryInfo?.emojiCode} ` : ''}${tag.displayName}`,
            isCategory: tag.isCategory
        })) || [];
    const preparedCategories = categories?.items?.map(category => ({
        entityId: category.tagId,
        title: `${category.emojiCode} ${category.translation?.displayName}`,
        isCategory: true
    }));

    const { query } = queryString.parse(history.location.search);

    useEffect(() => {
        const newQuery = debouncedValue || (query as string);

        if (newQuery) {
            getSearchItemsFx({
                searchText: newQuery
            });
            getTagsFx({
                search: newQuery
            });
        }
    }, [query, debouncedValue]);

    useEffect(() => {
        const { query, type } = queryString.parse(history.location.search) as QueryProps;
        const categories = [SearchCategory.Topic, SearchCategory.Creator, SearchCategory.Hashtag];
        if (type && categories.includes(type as SearchCategory)) {
            setActiveTab(type as SearchCategory);
        } else {
            history.push(`${AppRoute.Search}?query=${query}&type=${SearchCategory.Topic}`);
        }
    }, [history, history.location.search]);

    const handleTabsChange = (value: SearchCategory) => {
        history.push(`${AppRoute.Search}?query=${query}&type=${value}`);
        setActiveTab(value);
    };

    const onSearchSubmit = () => {
        history.push(`${AppRoute.Search}?query=${value}&type=${activeTab}`);
    };

    return (
        <Container>
            {isMobile && (
                <Section marginBottom="26px" marginTop="12px">
                    <SearchInput
                        disableAutocomplete
                        isLoading={isLoading}
                        placeholder="Search"
                        onSubmit={onSearchSubmit}
                        {...searchInput}
                    />
                </Section>
            )}
            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: SearchCategory.Topic,
                        title: `Topics (${topics.length})`,
                        width: '117px',
                        content: (
                            <SearchTabContent query={searchValue} searchItems={topics} type={SearchCategory.Topic} />
                        )
                    },
                    {
                        id: SearchCategory.Creator,
                        title: `Creators (${creators.length})`,
                        width: '117px',
                        content: (
                            <SearchTabContent
                                query={searchValue}
                                searchItems={creators}
                                type={SearchCategory.Creator}
                            />
                        )
                    },
                    {
                        id: SearchCategory.Hashtag,
                        title: `Hashtags (${
                            preparedTags?.length > 0 ? preparedTags.length : preparedCategories?.length || 0
                        })`,
                        width: '117px',
                        content: (
                            <SearchTabContent
                                query={searchValue}
                                searchItems={preparedTags?.length > 0 ? preparedTags : preparedCategories || []}
                                type={SearchCategory.Hashtag}
                            />
                        )
                    }
                ]}
                onChange={handleTabsChange}
            />
        </Container>
    );
};
