import { Text } from 'components/ui/typography/Text';
import React, { FC, MouseEvent } from 'react';
import { Disabled } from 'types';
import { TagStyled } from './styles';

interface Props extends Disabled {
    subject: string;
    onClick?: (subject: string) => void;
    isBold?: boolean;
}

export const Tag: FC<Props> = ({ subject, onClick, disabled, isBold = false, children }) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick && onClick(subject);
    };

    return (
        <TagStyled disabled={disabled} hasClick={!!onClick} type="button" onClick={handleClick}>
            <Text color="inherit" isBold={isBold} size="-2">
                {children}
            </Text>
        </TagStyled>
    );
};
