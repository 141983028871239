export type SocialNetworkName = 'instagram' | 'twitter' | 'youtube' | 'tiktok' | 'twitch';

export const instagramUrl = 'https://www.instagram.com';
export const twitterUrl = 'https://twitter.com';
export const youtubeUrl = 'https://www.youtube.com/channel';
export const tiktokUrl = 'https://tiktok.com';
export const twitchUrl = 'https://twitch.com';

export const socialNetworks: SocialNetworkName[] = ['instagram', 'twitter', 'youtube', 'tiktok', 'twitch'];

export const socialNetworkLinks = {
    instagram: instagramUrl,
    twitter: twitterUrl,
    youtube: youtubeUrl,
    tiktok: tiktokUrl,
    twitch: twitchUrl
};
