import { createStore, forward, sample } from 'effector';
import { collaborationEffects } from 'stores/collaboration-details';
import { collaborationGuideModal, shareLinkModal, uploadCollaborationModal } from 'stores/initialize.modal';
import { loaderEffects } from 'stores/loader';
import { uploadSubmissionEffects } from 'stores/upload-submission';

const { uploadSubmissionFx, shareSocialLinkFx } = uploadSubmissionEffects;
const { getMyCollaborationFx, getCollaborationFx } = collaborationEffects;

forward({
    from: [uploadSubmissionFx.pending, shareSocialLinkFx.pending],
    to: loaderEffects.loaderFx
});

forward({
    from: uploadSubmissionFx.doneData,
    to: [uploadCollaborationModal.closeModal, collaborationGuideModal.openModal]
});

forward({
    from: shareSocialLinkFx.doneData,
    to: shareLinkModal.closeModal
});

forward({
    from: [uploadSubmissionFx.doneData, shareSocialLinkFx.doneData],
    to: getMyCollaborationFx
});

sample({
    source: createStore({}),
    clock: [uploadSubmissionFx.doneData, shareSocialLinkFx.doneData],
    fn: (_, { collaborationId }) => collaborationId,
    target: getCollaborationFx
});
