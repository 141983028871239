import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { NoopClick } from 'types';
import { AvatarWrapper, ImagesWrapper, Wrapper } from './styles';

interface Props extends NoopClick {
    count: number;
    avatarUrls: string[];
    hideAvatars?: boolean;
}

export const Subs: FC<Props> = ({ count, avatarUrls, onClick, hideAvatars }) => {
    const leftSubs = count - avatarUrls.length;
    const countText = count === 1 ? 'channel' : 'channels';

    if (count === 0) {
        return null;
    }

    return (
        <Wrapper onClick={onClick}>
            {!hideAvatars && avatarUrls?.length && (
                <ImagesWrapper count={avatarUrls.length}>
                    {avatarUrls.map((src, index) => (
                        <AvatarWrapper key={src} index={index}>
                            <AvatarImg hasBorder diameter="32px" src={src || undefined} />
                        </AvatarWrapper>
                    ))}
                </ImagesWrapper>
            )}

            <Column marginLeft="14px">
                {leftSubs > 0 ? (
                    <Text size="-1">+{leftSubs} more</Text>
                ) : (
                    <Text size="-1">
                        {count} {countText}
                    </Text>
                )}
            </Column>
        </Wrapper>
    );
};
