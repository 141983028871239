import React, { FC, useMemo } from 'react';
import { Disabled, HTMLButtonType } from 'types/interfaces/form';
import { ReactClick } from 'types/react';
import { StyledButton, StyledButtonText } from './styles';

export interface ProgressButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    progress?: number;
}

export const ProgressButton: FC<ProgressButtonProps> = ({
    children,
    progress = 0,
    disabled = false,
    type = 'button',
    ...props
}) => {
    const isDisabled = progress < 100 || disabled;

    const displayProgress = useMemo(() => {
        if (progress > 100) return 100;
        if (progress < 0) return 0;
        return progress;
    }, [progress]);

    return (
        <StyledButton disabled={isDisabled} progress={displayProgress} type={type} {...props}>
            <StyledButtonText isBold color="inherit" size="-1">
                {children}
            </StyledButtonText>
        </StyledButton>
    );
};
