import { WrongLgIcon } from 'assets/icons';
import { asyncErrorMessage } from 'constants/messages';
import { AppRoute } from 'constants/routes';
import { createEffect, createEvent, createStore } from 'effector';
import { history } from 'index';
import { API } from 'services';
import { giftPurchaseDoneModal, infoModal } from 'stores/initialize.modal';

//Events

const resetBrandsStores = createEvent();
const resetPurchasesStores = createEvent();
const openDetailsModal = createEvent<BULLZ.VoucherTransactionResponse>();
const closeDetailsModal = createEvent();
const openHowToUseModal = createEvent<string>();
const closeHowToUseModal = createEvent();
const openPurchaseModal = createEvent<BULLZ.BrandVoucherResponse>();
const closePurchaseModal = createEvent();

//Effects

const getBrandsFx = createEffect({
    handler: async (data: BULLZ.GetVoucherBrandsRequest) => await API.gifts.getBrands(data)
});

const getPurchasesFx = createEffect({
    handler: async (data: BULLZ.QueryVoucherTransactionRequest) => await API.gifts.getPurchases(data)
});

const buyCardFx = createEffect({
    handler: async (data: BULLZ.CreateVoucherTransactionRequest) => await API.gifts.purchase(data)
});

const buyCardDoneFx = createEffect({
    handler: (data: BULLZ.VoucherTransactionResponse) => {
        resetPurchasesStores();
        closePurchaseModal();
        giftPurchaseDoneModal.openModal(data);
    }
});

const buyCardFailFx = createEffect({
    handler: () => {
        closePurchaseModal();
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'We were unable to process this purchase. Your WOM will be returned',
            buttonText: 'Return to Wallet',
            onClick: () => history.push(AppRoute.Wallet)
        });
    }
});

// Stores

const $brands = createStore<BULLZ.GetVoucherBrandsResponse>({})
    .on(getBrandsFx.doneData, (state, payload) => ({
        ...state,
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetBrandsStores);

const $hasBrandsError = createStore<boolean>(false)
    .on(getBrandsFx.fail, () => true)
    .reset([resetBrandsStores, getBrandsFx.done]);

const $purchases = createStore<BULLZ.QueryVoucherTransactionResponse>({})
    .on(getPurchasesFx.doneData, (state, payload) => ({
        ...state,
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetPurchasesStores);

const $hasPurchasesError = createStore<boolean>(false)
    .on(getPurchasesFx.fail, () => true)
    .reset([resetPurchasesStores, getPurchasesFx.done]);

const $giftInfoModal = createStore<BULLZ.VoucherTransactionResponse | null>(null)
    .on(openDetailsModal, (_, data) => data)
    .reset(closeDetailsModal);

const $howToUseModal = createStore<string | null>(null)
    .on(openHowToUseModal, (_, data) => data)
    .reset(closeHowToUseModal);

const $giftPurchaseModal = createStore<BULLZ.BrandVoucherResponse | null>(null)
    .on(openPurchaseModal, (_, data) => data)
    .reset(closePurchaseModal);

// Exports

export const giftsEvents = {
    resetBrandsStores,
    resetPurchasesStores,
    openDetailsModal,
    closeDetailsModal,
    openHowToUseModal,
    closeHowToUseModal,
    openPurchaseModal,
    closePurchaseModal
};

export const giftsEffects = {
    getBrandsFx,
    getPurchasesFx,
    buyCardFx,
    buyCardDoneFx,
    buyCardFailFx
};

export const giftsStores = {
    $brands,
    $hasBrandsError,
    $purchases,
    $hasPurchasesError,
    $giftInfoModal,
    $howToUseModal,
    $giftPurchaseModal
};
