import { ValidationStatusUI } from 'constants/services/video-statuses';
import { baseColors, blue } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { ValidationStatusButtonProps } from './index';

const getStyleByStatus = (status: ValidationStatusUI) => {
    switch (status) {
        case ValidationStatusUI.Submit:
        case ValidationStatusUI.ReSubmit:
            return css`
                color: ${blue[300]};
            `;
        case ValidationStatusUI.Rejected:
        case ValidationStatusUI.Fail:
            return css`
                color: ${baseColors.red};
            `;
        default:
            return css`
                color: ${baseColors.white};
            `;
    }
};

export const ValidationStatus = styled.button<Pick<ValidationStatusButtonProps, 'status'>>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    ${({ status }) => getStyleByStatus(status)};
`;

export const IconWrapper = styled.i`
    width: 24px;
    height: 24px;
    ${flexCenter};
    flex-shrink: 0;
`;
