import { Row } from 'components/grid/wrappers/FlexWrapper';
import { allTransition, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Controls = styled.div`
    position: absolute;
    bottom: 28px;
    left: 16px;
    right: 16px;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 12px;
`;

export const Top = styled(Row)`
    position: absolute;
    top: 28px;
    left: 20px;
    right: 20px;
    justify-content: space-between;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
`;

export const RoundButton = styled.button<{ color: string }>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    ${allTransition};
    width: 52px;
    height: 52px;
    border-radius: 50%;
    ${({ color }) => `background-color: ${color}`};

    &:hover {
        opacity: 0.8;
    }
`;
