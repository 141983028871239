import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { authStores } from 'stores/auth';
import { videoEffects } from 'stores/video';
import { Earnings } from '../Earnings';
import { Stats } from '../Stats';

interface Props {
    data: BULLZ.GetVideoDetailsResponse | null;
}

export const SubmitContent: FC<Props> = ({ data }) => {
    const user = useStore(authStores.$user);

    const submitVideo = () => {
        if (!data?.id) return;

        videoEffects.beginWomVideoValidateFx({
            contentId: data.id,
            stakeAmount: 1,
            requestStakingPromotion: !!user?.isFreeStakingEligible
        });
    };

    if (!data) return null;

    return (
        <Column noWrap width="100%">
            <Section marginBottom="24px">
                <Earnings />
            </Section>

            <Column noWrap marginBottom="24px" width="100%">
                <Section marginBottom="8px">
                    <Text isBold size="-1">
                        WOM Authentication
                    </Text>
                </Section>
                <Section>
                    <Text color={grey[500]} size="-1">
                        You have not submitted your video for authentication. Submit your video now to be eligible for
                        WOM rewards!
                    </Text>
                </Section>
            </Column>

            <Section marginBottom="24px">
                <Stats data={data.engagementStatistics} />
            </Section>

            <Button wide onClick={submitVideo}>
                Submit
            </Button>
        </Column>
    );
};
