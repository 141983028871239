import { Timer } from 'components/common/Timer';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles';
import { useStore } from 'effector-react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { collaborationStores } from 'stores/collaboration-details';
import { submissionTimerModal } from 'stores/initialize.modal';

const { modal, closeModal } = submissionTimerModal;
const { $myCollaborationData } = collaborationStores;

export const SubmissionTimerModal = () => {
    const [visible] = useStore(modal);
    const myCollaborationData = useStore($myCollaborationData);

    const isNeedApproveForCreator =
        myCollaborationData?.autoApproveMedia && !myCollaborationData?.autoApproveParticipants;

    return (
        <ModalWrapper visible={visible} onClose={closeModal}>
            <Column alignCenter>
                <Section justifyCenter marginBottom="20px">
                    <Text alignTextCenter isBold>
                        {isNeedApproveForCreator ? 'You have been approved! You have...' : 'Great! You have...'}
                    </Text>
                </Section>
                {myCollaborationData?.utcSubmissionDeadline && (
                    <Column marginBottom="16px" width={isMobile ? '100%' : '270px'}>
                        <Timer
                            color={baseColors.black}
                            date={myCollaborationData?.utcSubmissionDeadline}
                            type="secondary"
                        />
                    </Column>
                )}
                <Section justifyCenter marginBottom="24px">
                    <Text alignTextCenter size="-1">
                        to submit your video to receive your reward
                    </Text>
                </Section>
                <Section marginBottom="24px">
                    <Button wide onClick={() => closeModal()}>
                        Got it
                    </Button>
                </Section>
                <TextButton isBold isUppercase onClick={() => closeModal()}>
                    go back
                </TextButton>
            </Column>
        </ModalWrapper>
    );
};
