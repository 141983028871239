import { hasCloseKeyboardShortcutModal } from 'constants/global';
import { defaultHomePlaylistQueryParams } from 'constants/services/home';
import { createEffect, createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { API } from 'services';

// Events

const setFocusedVideoId = createEvent<string>();
const setIsBlockedLoading = createEvent();
const resetFocusedVideoId = createEvent();
const updatePlaylist = createEvent();
const setActiveMobileIndex = createEvent<number>();
const setHasCloseKeyboardShortcutModal = createEvent<boolean>();
const setIsFocusedVideoPlayer = createEvent<boolean>();

// Effects

const getHomePlaylistFx = createEffect({
    handler: async () => {
        const response = await API.playlist.query(defaultHomePlaylistQueryParams);

        if (!response.items || response.items.length === 0) {
            setIsBlockedLoading();
        }

        return response;
    }
});

// Stores

const $focusedVideoId = createStore<string>('')
    .on(setFocusedVideoId, (_, id) => id)
    .reset(resetFocusedVideoId);

const $homePlaylist = createStore<BULLZ.CreatePlaylistForVideosResponse>({})
    .on(getHomePlaylistFx.doneData, (state, data) => {
        if (Object.keys(state).length === 0) {
            return data;
        } else {
            return {
                ...state,
                ...data,
                items: [...(state.items || []), ...(data.items || [])]
            };
        }
    })
    .on(updatePlaylist, (_, newState) => newState);

const $isBlockedLoading = createStore<boolean>(false).on(setIsBlockedLoading, () => true);

const $isFocusedVideoPlayer = createStore<boolean>(true).on(setIsFocusedVideoPlayer, (_, key) => key);

const $activeMobileIndex = createStore<number>(0).on(setActiveMobileIndex, (_, index) => index);

const hasCloseKeyboardShortcutModalLs = connectLocalStorage(hasCloseKeyboardShortcutModal);
const $hasCloseKeyboardShortcutModal = createStore<boolean>(hasCloseKeyboardShortcutModalLs.init(false)).on(
    setHasCloseKeyboardShortcutModal,
    (_, payload) => payload
);

$hasCloseKeyboardShortcutModal.watch(hasCloseKeyboardShortcutModalLs);

// Exports

export const homeEvents = {
    setFocusedVideoId,
    resetFocusedVideoId,
    updatePlaylist,
    setActiveMobileIndex,
    setHasCloseKeyboardShortcutModal,
    setIsFocusedVideoPlayer
};

export const homeEffects = {
    getHomePlaylistFx
};

export const homeStores = {
    $focusedVideoId,
    $homePlaylist,
    $isBlockedLoading,
    $activeMobileIndex,
    $hasCloseKeyboardShortcutModal,
    $isFocusedVideoPlayer
};
