import { CopySmIcon, TelegramSmIcon, TwitterSmIcon, WhatsappSmIcon } from 'assets/icons';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { videoEffects } from 'stores/video';
import { getProfileLink, getVideoLink } from 'utils/common';
import { shareButtonStyles, VideoSharesItem, VideoSharesWrapper } from './styles';

const { registerEventVideo } = videoEffects;

interface Props {
    link?: string;
    videoId?: string;
    userId?: string;
}

export const Shares: FC<Props> = ({ videoId, userId, link = '' }) => {
    const url = videoId ? getVideoLink(videoId) : getProfileLink(userId);

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Link Copied');
        }
    };

    const handleShareClick = () => {
        videoId &&
            registerEventVideo({
                videoId: videoId,
                shared: true
            });
    };

    return (
        <VideoSharesWrapper>
            <CopyToClipboard text={url || link} onCopy={handleCopy}>
                <VideoSharesItem>
                    <CopySmIcon />

                    <Column marginLeft="8px">
                        <Text size="-2">Copy link</Text>
                    </Column>
                </VideoSharesItem>
            </CopyToClipboard>

            <TwitterShareButton style={shareButtonStyles} url={url || link} onClick={handleShareClick}>
                <VideoSharesItem>
                    <TwitterSmIcon />

                    <Column marginLeft="8px">
                        <Text size="-2">Twitter</Text>
                    </Column>
                </VideoSharesItem>
            </TwitterShareButton>

            <TelegramShareButton style={shareButtonStyles} url={url || link} onClick={handleShareClick}>
                <VideoSharesItem>
                    <TelegramSmIcon />

                    <Column marginLeft="8px">
                        <Text size="-2">Telegram</Text>
                    </Column>
                </VideoSharesItem>
            </TelegramShareButton>

            <WhatsappShareButton style={shareButtonStyles} url={url || link} onClick={handleShareClick}>
                <VideoSharesItem>
                    <WhatsappSmIcon />

                    <Column marginLeft="8px">
                        <Text size="-2">WhatsApp</Text>
                    </Column>
                </VideoSharesItem>
            </WhatsappShareButton>
        </VideoSharesWrapper>
    );
};
