import { ThreeDotsVerticalSmIcon, VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { FollowPageType } from 'constants/enum';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { loginModal, reportUserModal, walletGuideModal } from 'stores/initialize.modal';
import { walletEffects, walletStores } from 'stores/wallet';
import { ClickableWrapper } from '../../styles';
import { ProfileBlockViewProps } from '../../types';
import { QuickSignalsInfo } from '../QuickSignalsInfo';
import { SocialNetworks } from '../SocialNetworks';
import { IconWrapper } from './styles';

const { $hasWallet, $womUserIsLoaded } = walletStores;

export const DesktopView: FC<ProfileBlockViewProps> = ({ data, currentUser, goToFollow, goToTeam, onFollow }) => {
    const { id, username, isTrusted, isFollowed, profileImageUrl, followedCount, followersCount, team, bio } = data;
    const hasWallet = useStore($hasWallet);
    const womUserIsLoaded = useStore($womUserIsLoaded);

    const isAuth = !!currentUser;
    const isMyProfile = isAuth && currentUser.userId === id;

    const handleReportUser = () => {
        if (isAuth && id) {
            reportUserModal.openModal({ userId: id });
        } else {
            loginModal.openModal();
        }
    };

    return (
        <Column noWrap width="100%">
            <MarginWrapper marginBottom="16px">
                <AvatarImg diameter="80px" src={profileImageUrl || undefined} />
            </MarginWrapper>

            <Section alignCenter justifyBetween noWrap marginBottom="26px">
                <Row alignCenter>
                    <Text isBold isUppercase>
                        @{username}
                    </Text>
                    {isTrusted && (
                        <MarginWrapper marginLeft="10px">
                            <VerificationSmIcon height="20px" width="20px" />
                        </MarginWrapper>
                    )}
                </Row>

                <Row>
                    <SocialNetworks isAuth={isAuth} userId={id} />

                    {!isMyProfile && (
                        <IconWrapper>
                            <IconButton onClick={handleReportUser}>
                                <ThreeDotsVerticalSmIcon />
                            </IconButton>
                        </IconWrapper>
                    )}
                </Row>
            </Section>

            <Section alignCenter justifyBetween noWrap marginBottom="12px">
                <Row alignCenter>
                    <ClickableWrapper type="button" onClick={() => goToFollow(FollowPageType.Followers)}>
                        <Text size="-1">{followersCount}</Text>
                        <MarginWrapper marginLeft="8px">
                            <Text size="-2">Followers</Text>
                        </MarginWrapper>
                    </ClickableWrapper>

                    <MarginWrapper marginLeft="16px">
                        <ClickableWrapper type="button" onClick={() => goToFollow(FollowPageType.Followings)}>
                            <Text size="-1">{followedCount}</Text>
                            <MarginWrapper marginLeft="8px">
                                <Text size="-2">Following</Text>
                            </MarginWrapper>
                        </ClickableWrapper>
                    </MarginWrapper>

                    {team && (
                        <MarginWrapper marginLeft="16px">
                            <ClickableWrapper type="button" onClick={() => goToTeam(team.id || '', id || '')}>
                                <AvatarImg diameter="24px" src={team.ownerProfileImageUrl || undefined} />
                                <MarginWrapper marginLeft="8px">
                                    <Text size="-2">{team.name}</Text>
                                </MarginWrapper>
                            </ClickableWrapper>
                        </MarginWrapper>
                    )}
                </Row>

                {isAuth && (
                    <>
                        {isMyProfile ? (
                            <>
                                {womUserIsLoaded && (
                                    <>
                                        {hasWallet ? (
                                            <Button
                                                isBold={false}
                                                size="small"
                                                onClick={() => walletEffects.existsWalletPinFx()}
                                            >
                                                WOM Wallet
                                            </Button>
                                        ) : (
                                            <Button
                                                isBold={false}
                                                size="small"
                                                onClick={() => walletGuideModal.openModal()}
                                            >
                                                Create Wallet
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Button
                                buttonType={isFollowed ? 'secondary' : undefined}
                                isBold={false}
                                size="small"
                                onClick={onFollow}
                            >
                                {isFollowed ? 'Following' : 'Follow'}
                            </Button>
                        )}
                    </>
                )}
            </Section>

            {bio ? (
                <Section maxWidth="515px">
                    <Text size="-2">{bio}</Text>
                </Section>
            ) : (
                <Section height="40px" />
            )}

            {isAuth && id && (
                <QuickSignalsInfo
                    avatar={profileImageUrl || undefined}
                    isMyProfile={isMyProfile}
                    isVerified={isTrusted}
                    userId={id}
                />
            )}
        </Column>
    );
};
