import { ChevronLeftBorderSmIcon, ChevronRightBorderSmIcon } from 'assets/icons';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { useStore } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { authStores } from 'stores/auth';
import { qsEffects, qsEvents, qsStores } from 'stores/quick-signals-trending';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { defaultNavigation } from './constants';
import { AvatarWrapper, GlobalStyle, IconButton, QSText, SliderWrapper, SwiperWrapper } from './styles';

interface NavigationProperties {
    prevEl: HTMLButtonElement | null;
    nextEl: HTMLButtonElement | null;
}

const { $qsUsers } = qsStores;
const { getQSUsersFx } = qsEffects;
const { $user } = authStores;
const { openQSModal } = qsEvents;

export const SliderQS = () => {
    const navigationPrevRef = useRef<HTMLButtonElement>(null);
    const navigationNextRef = useRef<HTMLButtonElement>(null);
    const [navigation, setNavigation] = useState<NavigationProperties>(defaultNavigation);

    const qsUsers = useStore($qsUsers);
    const authUser = useStore($user);

    const isSlider = qsUsers && qsUsers.length > 6;

    useEffect(() => {
        if (navigationPrevRef.current && navigationNextRef.current) {
            setNavigation({
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
            });
        }
    }, []);

    useEffect(() => {
        getQSUsersFx();
    }, []);

    if (!qsUsers || qsUsers.length === 0) {
        return null;
    }

    return (
        <Column width="100%">
            <SliderWrapper>
                {isSlider && (
                    <IconButton ref={navigationPrevRef} type="button">
                        <ChevronLeftBorderSmIcon />
                    </IconButton>
                )}

                <SwiperWrapper>
                    <Swiper
                        modules={[Navigation]}
                        navigation={navigation}
                        slidesPerGroup={4}
                        slidesPerView="auto"
                        spaceBetween={12}
                        style={{ width: '100%' }}
                    >
                        {qsUsers.map((item, index) => (
                            <SwiperSlide
                                key={item.userProfile?.id}
                                style={{ width: 82 }}
                                onClick={() => {
                                    openQSModal({
                                        ...item,
                                        index
                                    });
                                }}
                            >
                                <AvatarWrapper>
                                    <QsAvatar
                                        hasNewVideos={item.hasNewVideos}
                                        isSubscribed={item.isSubscribed || item.userProfile?.id === authUser?.userId}
                                        url={item.userProfile?.avatarUrl || ''}
                                    />

                                    <Section justifyCenter marginTop="14px">
                                        <QSText size="-2">{item.userProfile?.username}</QSText>
                                    </Section>
                                </AvatarWrapper>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </SwiperWrapper>

                {isSlider && (
                    <IconButton ref={navigationNextRef} type="button">
                        <ChevronRightBorderSmIcon />
                    </IconButton>
                )}
                <GlobalStyle />
            </SliderWrapper>
        </Column>
    );
};
