import { CloseSmIcon, MixPayWhiteLogo } from 'assets/icons';
import bithumbLogo from 'assets/img/bithumb.png';
import gateIoLogo from 'assets/img/gateio.png';
import kucoinLogo from 'assets/img/kucoin.png';
import mexcLogo from 'assets/img/mexc.png';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { bithumHref, gateIoHref, kuCoinHref, mexcHref } from 'components/modals/QexWidgetModal/constants';
import { Image, LinkWrapper, MixPayButton, StyledExternalButton } from 'components/modals/QexWidgetModal/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React from 'react';
import { qexWidgetModal } from 'stores/initialize.modal';
import { walletEffects } from 'stores/wallet';
import { goToExternalLink } from 'utils/common';

const { modal, closeModal } = qexWidgetModal;

export const QexWidgetModal = () => {
    const [visible] = useStore(modal);

    const onClose = () => closeModal();

    const onMixPayButtonClick = async () => {
        await walletEffects.payWomFx();
        onClose();
    };

    return (
        <ModalWrapper visible={visible} onClose={onClose}>
            <Section justifyEnd marginBottom="28px">
                <IconButton onClick={onClose}>
                    <CloseSmIcon />
                </IconButton>
            </Section>

            <Section justifyCenter marginBottom="12px">
                <Text alignTextCenter isBold size="1">
                    You can buy WOM here
                </Text>
            </Section>

            <Section justifyCenter marginBottom="28px">
                <MixPayButton onClick={onMixPayButtonClick}>
                    <Text size="-2">Pay with</Text>
                    <Column marginLeft="8px">
                        <MixPayWhiteLogo />
                    </Column>
                </MixPayButton>
            </Section>

            <Section justifyCenter marginBottom="12px">
                <Text alignTextCenter isBold size="1">
                    Further exchanges:
                </Text>
            </Section>

            <LinkWrapper>
                <StyledExternalButton onClick={() => goToExternalLink(bithumHref)}>
                    <Image src={bithumbLogo} />
                </StyledExternalButton>

                <StyledExternalButton onClick={() => goToExternalLink(gateIoHref)}>
                    <Image src={gateIoLogo} />
                </StyledExternalButton>

                <StyledExternalButton onClick={() => goToExternalLink(kuCoinHref)}>
                    <Image src={kucoinLogo} />
                </StyledExternalButton>

                <StyledExternalButton onClick={() => goToExternalLink(mexcHref)}>
                    <Image src={mexcLogo} />
                </StyledExternalButton>
            </LinkWrapper>
        </ModalWrapper>
    );
};
