import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { BackArrowSmIcon, CopySmIcon, PlusSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { TeamMembersTabs } from 'components/pages/team/TeamMembersTab';
import { ButtonEditWrapper, CopyButtonWrapper, EditWrapper } from 'components/pages/team/TeamTabs/styles';
import { TeamVideosTab } from 'components/pages/team/TeamVideosTab';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Tabs } from 'components/ui/Tabs';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { TeamPageType } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { createOrEditTeamModal, infoModal } from 'stores/initialize.modal';
import { teamEffects, teamEvents, teamStores } from 'stores/team';
import { Id } from 'types/interfaces/data';
import { noop } from 'utils/common';

const { getTeamInfoFx, leaveTeamFx, joinToTeamFx } = teamEffects;
const { $teamInfo } = teamStores;
const { $teamMembers } = teamStores;
const { getTeamMembersFx } = teamEffects;
const { resetStore } = teamEvents;
const { $isAuth, $user } = authStores;

interface QueryProps {
    userId?: string;
    lastPage?: string;
}

export const TeamTabs: FC<Id> = ({ id }) => {
    const history = useHistory();
    const { userId, lastPage } = queryString.parse(history.location.search) as QueryProps;
    const [activeTab, setActiveTab] = useState(
        lastPage === 'notifications' ? TeamPageType.Creators : TeamPageType.Videos
    );

    const teamInfo = useStore($teamInfo);
    const { items } = useStore($teamMembers);

    const user = useStore($user);
    const isAuth = useStore($isAuth);

    const teamId = teamInfo.id;

    const isLoading = useStore(getTeamMembersFx.pending);

    const isButtonJoin = !user?.team;

    const isButtonLeave = user?.team?.id === teamInfo?.id && user?.userId !== teamInfo?.owner?.userId;

    const isButtonEditTeam = user?.userId === teamInfo?.owner?.userId;

    const handleTabsChange = (value: TeamPageType) => {
        setActiveTab(value);
    };

    const goToBack = () => {
        !!userId ? history.push(`${AppRoute.Profile}/${userId}`) : history.goBack();
    };

    // TODO discuss logic
    const clickButtonJoin = () => {
        joinToTeamFx({ id: teamId }).then(
            ({ isSuccess }) =>
                isSuccess &&
                infoModal.openModal({
                    image: teamInfo?.owner?.profileImageUrl || defaultAvatar,
                    title: teamInfo?.name || '',
                    text: 'Congratulations, you are now part of the team!',
                    buttonText: 'Cool, Let’s Go',
                    onClick: noop
                })
        );
    };

    const clickButtonLeave = () => {
        infoModal.openModal({
            srcLeft: teamInfo?.owner?.profileImageUrl || defaultAvatar,
            srcRight: user?.profile?.profileImageUrl || defaultAvatar,
            title: 'Leave Team',
            text: `Are you sure you want to leave this team?`,
            buttonText: 'Not Now',
            cancelText: 'leave now',
            onClick: noop,
            onCloseClick: () => {
                leaveTeamFx({ id: teamId });
            }
        });
    };

    const copyText = window.location.href;

    const handleEditTeam = () => {
        createOrEditTeamModal.openModal({ isEdit: true });
    };

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Link Copied');
        }
    };

    useEffect(() => {
        id && getTeamInfoFx(id);
        id && getTeamMembersFx(id);

        return () => resetStore();
    }, [id]);

    return (
        <Column noWrap height="100%" width="100%">
            <Section justifyBetween noWrap marginBottom="42px">
                <Section noWrap>
                    <IconButton onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>

                    <Section marginLeft="12px">
                        <Text isBold size="1">
                            {teamInfo.name}
                        </Text>
                    </Section>
                </Section>

                {isButtonJoin && isAuth && !isButtonLeave && (
                    <Button
                        buttonType="primary"
                        icon={<PlusSmIcon />}
                        iconPosition="right"
                        isBold={false}
                        size="small"
                        textSize="small"
                        onClick={clickButtonJoin}
                    >
                        Join
                    </Button>
                )}

                {isButtonEditTeam && isAuth && (
                    <EditWrapper>
                        <CopyButtonWrapper>
                            {copyText && (
                                <CopyToClipboard text={copyText} onCopy={handleCopy}>
                                    <Button
                                        buttonType="transparent"
                                        icon={<CopySmIcon height="15px" width="15px" />}
                                        iconPosition="left"
                                        isBold={false}
                                        size="middle"
                                        textSize="small"
                                    >
                                        Copy
                                    </Button>
                                </CopyToClipboard>
                            )}
                        </CopyButtonWrapper>

                        <ButtonEditWrapper>
                            <Button buttonType="primary" size="small" textSize="small" onClick={handleEditTeam}>
                                Edit
                            </Button>
                        </ButtonEditWrapper>
                    </EditWrapper>
                )}

                {isButtonLeave && isAuth && (
                    <Button buttonType="dark" isBold={false} size="middle" textSize="small" onClick={clickButtonLeave}>
                        Leave Team
                    </Button>
                )}
            </Section>

            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: TeamPageType.Videos,
                        title: 'Team Videos',
                        content: <TeamVideosTab id={id} />,
                        width: isMobile ? '50%' : '175px'
                    },
                    {
                        id: TeamPageType.Creators,
                        title: 'Team Creators',
                        content: (
                            <TeamMembersTabs
                                isLoading={isLoading}
                                items={items}
                                ownerId={teamInfo.owner?.userId}
                                teamId={teamInfo.id}
                                urlName={teamInfo.owner?.profileImageUrl}
                            />
                        ),
                        width: isMobile ? '50%' : '175px'
                    }
                ]}
                onChange={handleTabsChange}
            />
        </Column>
    );
};
