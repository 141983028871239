import { BellFilledSmIcon, HomeSmIcon, SettingsSmIcon, TrendingActiveSmIcon, TrendingSmIcon } from 'assets/icons';
import { SearchDropdown } from 'components/grid/Header/SearchDropdown';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { AppRoute, bullzSitePolicyUrl, bullzSiteTermsUrl } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { loginModal } from 'stores/initialize.modal';
import { NoopType } from 'types';
import { IconWrapper, MobileMenuWrapper, StyledMobileButton, StyledMobileLink, StyledNavLink } from './styles';

const { $isAuth } = authStores;

interface Props {
    isVisible?: boolean;
    onLogout?: NoopType;
}

// TODO add profile links in burger menu (notifications, settings..)

export const MobileMenu: FC<Props> = ({ isVisible, onLogout }) => {
    const { pathname } = useLocation();

    const isAuth = useStore($isAuth);

    const year = new Date().getFullYear();

    if (!isVisible) {
        return null;
    }

    return (
        <MobileMenuWrapper>
            <Column noWrap width="100%">
                <SearchDropdown />

                <Column noWrap marginTop="20px" width="100%">
                    <Section marginBottom="16px">
                        <StyledNavLink exact to={AppRoute.Main}>
                            <IconWrapper>
                                <HomeSmIcon height="16px" width="15px" />
                            </IconWrapper>
                            <Column marginLeft="8px">Home</Column>
                        </StyledNavLink>
                    </Section>

                    <Section marginBottom="16px">
                        <StyledNavLink exact to={AppRoute.Trending}>
                            <IconWrapper>
                                {pathname === AppRoute.Trending ? (
                                    <TrendingActiveSmIcon height="20px" width="14px" />
                                ) : (
                                    <TrendingSmIcon height="20px" width="14px" />
                                )}
                            </IconWrapper>
                            <Column marginLeft="8px">Trending</Column>
                        </StyledNavLink>
                    </Section>

                    {isAuth && (
                        <Section marginBottom="16px">
                            <StyledNavLink exact to={AppRoute.Notifications}>
                                <IconWrapper>
                                    <BellFilledSmIcon height="18px" width="20px" />
                                </IconWrapper>
                                <Column marginLeft="8px">Notifications</Column>
                            </StyledNavLink>
                        </Section>
                    )}

                    <StyledNavLink exact to={AppRoute.Settings}>
                        <IconWrapper>
                            <SettingsSmIcon height="20px" width="18px" />
                        </IconWrapper>
                        <Column marginLeft="8px">Settings</Column>
                    </StyledNavLink>
                </Column>
            </Column>

            <Column noWrap width="100%">
                <Section marginTop="20px">
                    {isAuth ? (
                        <StyledMobileButton type="button" onClick={onLogout}>
                            <Text color={grey['500']}>Log Out</Text>
                        </StyledMobileButton>
                    ) : (
                        <StyledMobileButton type="button" onClick={() => loginModal.openModal()}>
                            <Text color={grey['500']}>Login</Text>
                        </StyledMobileButton>
                    )}
                </Section>

                <Section marginTop="20px">
                    <StyledMobileLink target="_blank" to={{ pathname: bullzSitePolicyUrl }}>
                        <Text color={grey['500']}>Privacy</Text>
                    </StyledMobileLink>
                </Section>

                <Section marginTop="20px">
                    <StyledMobileLink target="_blank" to={{ pathname: bullzSiteTermsUrl }}>
                        <Text color={grey['500']}>Terms & Conditions</Text>
                    </StyledMobileLink>
                </Section>

                <Section marginTop="20px">
                    <Text color={grey['500']} size="-1">
                        {year} © WOM Protocol Pte. Ltd. All Rights reserved.
                    </Text>
                </Section>
            </Column>
        </MobileMenuWrapper>
    );
};
