import { ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { lsAdditionalRegisterCompleted } from 'constants/global';
import { asyncErrorMessage } from 'constants/messages';
import { createEffect, createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { API } from 'services';
import { additionalRegisterModal, infoModal, registerModal } from 'stores/initialize.modal';
import { goToHelpDesk, noop } from 'utils/common';

// Events

const resetUsernameIsAvailable = createEvent();
const setAdditionalRegisterIsCompleted = createEvent<boolean>();
const setAdditionalRegisterIsFirstShowModal = createEvent<boolean>();

// Effects

interface SendCodeFxProps {
    phone: string;
    firebaseToken: string;
}

const sendCodeFx = createEffect<SendCodeFxProps, BULLZ.GetAllUserExternalNetworkResponse, BULLZ.Error400BadRequest>({
    handler: async ({ phone, firebaseToken }) =>
        await API.user.sendVerificationCode({ mobileNumber: phone }, firebaseToken)
});

const sendCodeFailFx = createEffect({
    handler: (params?: BULLZ.Error400BadRequest) => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: params?.message || '',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const checkSmsCodeFx = createEffect({
    handler: async (data: BULLZ.CheckSmsCodeRequest) => {
        try {
            const { result } = await API.user.checkSmsCode(data);

            return result;
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return e.message;
        }
    }
});

const checkUsernameExistenceFx = createEffect({
    handler: async (username: string) => {
        const { exists } = await API.user.checkUsernameExistence({ username });

        return exists;
    }
});

const createAccountFx = createEffect({
    handler: async (data: BULLZ.UserCreateAccountRequest) => await API.user.createAccount(data)
});

const createAccountFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'There was a problem creating your account. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const createAccountDoneFx = createEffect({
    handler: () => {
        registerModal.closeModal();
        infoModal.openModal({
            icon: ConfettiLgIcon,
            title: 'Congrats!',
            text:
                'You successfully created your account. Now let’s finish setting up your account for the best BULLZ experience!',
            buttonText: 'Let’s Go',
            onClick: () => additionalRegisterModal.openModal(),
            afterCloseCb: () => additionalRegisterModal.openModal()
        });
        setAdditionalRegisterIsFirstShowModal(true);
        setAdditionalRegisterIsCompleted(false);
    }
});

const updateAccountFx = createEffect({
    handler: async (data: BULLZ.UserUpdateRequest) => await API.user.updateAccount(data)
});

const updateAccountFailFx = createEffect({
    handler: () => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: 'There was a problem adding your details. Please try again or contact our support team.',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onClick: noop,
            onCloseClick: goToHelpDesk
        });
    }
});

const updateAccountDoneFx = createEffect({
    handler: () => {
        additionalRegisterModal.closeModal();
        infoModal.openModal({
            icon: ConfettiLgIcon,
            title: 'Congrats!',
            text:
                'You successfully added your details. Ready to find projects, share recommendations, and earn rewards?',
            buttonText: 'Let’s Go',
            onClick: noop
        });
        setAdditionalRegisterIsCompleted(true);
    }
});

// Stores

const $usernameIsAvailable = createStore(true)
    .on(checkUsernameExistenceFx.doneData, (_, payload) => !payload)
    .reset(resetUsernameIsAvailable);

const additionalRegisterLs = connectLocalStorage(lsAdditionalRegisterCompleted);
const $additionalRegisterIsCompleted = createStore<boolean>(additionalRegisterLs.init(true)).on(
    setAdditionalRegisterIsCompleted,
    (_, payload) => payload
);

const $additionalRegisterIsFirstShowModal = createStore<boolean>(false).on(
    setAdditionalRegisterIsFirstShowModal,
    (_, payload) => payload
);

// Exports

export const registerEvents = {
    resetUsernameIsAvailable
};

export const registerEffects = {
    sendCodeFx,
    sendCodeFailFx,
    checkSmsCodeFx,
    checkUsernameExistenceFx,
    createAccountFx,
    createAccountFailFx,
    createAccountDoneFx,
    updateAccountFx,
    updateAccountFailFx,
    updateAccountDoneFx
};

export const registerStores = {
    $usernameIsAvailable,
    $additionalRegisterIsCompleted,
    $additionalRegisterIsFirstShowModal
};

export const localStorageConnects = {
    additionalRegisterLs
};
