import { Row } from 'components/grid/wrappers/FlexWrapper/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const EditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: nowrap;

    ${media.laptop(`
        flex-direction: row;
        justify-content: flex-end;
    `)};
`;

export const CopyButtonWrapper = styled.div`
    display: flex;
    margin-top: 8px;
    order: 1;

    ${media.laptop(`
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0;
    `)};
`;

export const ButtonEditWrapper = styled(Row)`
    margin-left: 16px;

    ${media.laptop(`
        order: 2;
    `)};
`;
