import { PlusBgMdIcon, VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { authStores } from 'stores/auth';
import { userFollowEffects } from 'stores/user-follow';
import { AvatarWrapper, FollowWrapper, ProfileWrapper, UsernameLink } from './styles';

interface Props {
    userId?: string;
    username?: string;
    avatar?: string;
    isTrusted?: boolean;
    isFollowed?: boolean;
}

export const UserProfile: FC<Props> = ({ userId, username, avatar, isTrusted, isFollowed }) => {
    const history = useHistory();
    const user = useStore(authStores.$user);
    const visibleFollowButton = !!userId && !!user && user.userId !== userId && isFollowed === false;

    const follow = () => {
        if (user && userId)
            userFollowEffects.followUserFx({
                userId: user.userId,
                creatorId: userId,
                isFollow: true
            });
    };

    const goToProfile = () => {
        if (userId) {
            history.push(`${AppRoute.Profile}/${userId}`);
        }
    };

    return (
        <ProfileWrapper isVisibleFollow={visibleFollowButton}>
            <UsernameLink to={`${AppRoute.Profile}/${userId}`}>
                <Text size="-2">@{username}</Text>

                {isTrusted && (
                    <Column marginLeft="8px">
                        <VerificationSmIcon height="20px" width="20px" />
                    </Column>
                )}
            </UsernameLink>

            <AvatarWrapper>
                <AvatarImg alt={username || 'Avatar'} diameter="32px" src={avatar || undefined} onClick={goToProfile} />
            </AvatarWrapper>

            {visibleFollowButton && (
                <FollowWrapper>
                    <IconButton onClick={follow}>
                        <PlusBgMdIcon height={20} width={20} />
                    </IconButton>
                </FollowWrapper>
            )}
        </ProfileWrapper>
    );
};
