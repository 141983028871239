import { disableDefaultButtonStyleMixin, ellipsisMixin, transitionTime } from 'constants/styles';
import { grey } from 'constants/styles/colors';
import styled from 'styled-components';

export const WrapperCard = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding: 8px 4px 4px;
    width: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const TextWrapper = styled.div`
    max-width: 74px;
    ${ellipsisMixin};
`;
