import { MontserratFontFamily } from 'constants/styles';
import { baseColors } from 'constants/styles/colors';
import { createGlobalStyle } from 'styled-components';

interface Props {
    maxWidth?: string;
}

export const GlobalStyle = createGlobalStyle<Props>`
.custom-tooltip {
  .rc-tooltip-inner {
      max-width: ${({ maxWidth }) => maxWidth || '180px'};
      padding: 9px 8px !important;
      border: none !important;
      font-family: ${MontserratFontFamily};
      font-size: 12px;
      line-height: 18px;
      color: ${baseColors.white};
      background: ${baseColors.darkGrey} !important;
  }

  &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
      border-bottom-color: ${baseColors.darkGrey} !important;
  }

  &.rc-tooltip-placement-top .rc-tooltip-arrow,
  &.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
      border-top-color: ${baseColors.darkGrey} !important;
  }

  &.rc-tooltip-placement-left .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
      border-left-color: ${baseColors.darkGrey} !important;
  }

  &.rc-tooltip-placement-right .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
      border-right-color: ${baseColors.darkGrey} !important;
  }
}
`;
