import { ellipsisMixin, grey, MontserratFontFamily, transitionTime } from 'constants/styles';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

export const TabItem = styled.div`
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    font-size: 12px;
    align-items: center;
    font-family: ${MontserratFontFamily};
    ${ellipsisMixin};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background: ${grey[800]};
    }
`;
