import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { flexCenter, grey, transitionTime } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const StyledLabel = styled.div`
    background: linear-gradient(29.65deg, #f15f31 11.36%, #4353ff 64.09%);
    ${flexCenter};
    padding: 2px 8px;
    border-radius: 50px;
`;

export const Header = styled(Section)`
    align-items: center;
    margin-bottom: 16px;

    ${media.laptop(`
        margin-bottom: 8px;
    `)}
`;

export const AvatarsList = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 16px;
`;

export const AvatarWrapper = styled(Column)`
    width: 84px;
    min-width: 84px;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px;
    margin-right: 4px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;
    white-space: pre-wrap;
    word-break: break-all;

    &:hover {
        background-color: ${grey[800]};
    }

    ${media.laptop(`
        width: 100%;
        min-width: 100%;
        margin-right: 0;
    `)};
`;

export const SeeAllWrapper = styled(Section)`
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const SliderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
`;
