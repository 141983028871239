import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { ButtonLink } from 'components/pages/settings/components/ButtonLink';
import { Wrapper } from 'components/pages/settings/components/CommonPart/styles';
import { ContentHeader } from 'components/pages/settings/components/ContentHeader';
import { getVerified, makeSuggestion, reportAnIssue } from 'constants/routes';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { ReactClick } from 'types/react';

export const FeedbackContent: FC<ReactClick<HTMLButtonElement>> = ({ onClick }) => (
    <Wrapper>
        <ContentHeader title="Feedback" onClick={onClick} />

        <Column marginTop={isMobile ? '20px' : '32px'}>
            <ButtonLink link={reportAnIssue} title="Report an Issue" />
            <ButtonLink link={makeSuggestion} title="Make a suggestion" />
            <ButtonLink link={getVerified} title="Get verified" />
        </Column>
    </Wrapper>
);
