import { ConfettiLgIcon, WrongLgIcon } from 'assets/icons';
import { createEffect } from 'effector';
import { bucmAPI } from 'services/bucm';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk } from 'utils/common';

//Effects

const joinCollaborationFx = createEffect({
    handler: async ({ userId, collaborationId }: { userId: string; collaborationId: string }) => {
        const { isSuccess, data } = await bucmAPI.participants.estimateSubmissionDate(userId, collaborationId);
        if (isSuccess) {
            const result = await bucmAPI.participants.participateInCollaboration(userId, collaborationId, {
                acceptedSubmissionDeadlineUtc: data?.submissionDeadlineUtc || '',
                isAccepted: true
            });
            if (result.isSuccess) {
                infoModal.openModal({
                    icon: ConfettiLgIcon,
                    title: `Congrats!`,
                    text: `You successfully enrolled in the Collaboration! Create your video and submit it by the deadline to earn WOM.`,
                    buttonText: 'Let’s Go'
                });
            } else {
                infoModal.openModal({
                    icon: WrongLgIcon,
                    title: 'Something went wrong',
                    text: `There was a problem in enrolling in the collaboration. Please try again or contact our support team.`,
                    buttonText: 'Try Again',
                    cancelText: 'CONTACT SUPPORT',
                    onCloseClick: goToHelpDesk
                });
            }
        }
        return { bcmUserId: userId, collaborationId };
    }
});

const requestParticipationFx = createEffect({
    handler: async ({ userId, collaborationId }: { userId: string; collaborationId: string }) => {
        const { isSuccess, data } = await bucmAPI.participants.estimateSubmissionDate(userId, collaborationId);
        if (isSuccess) {
            const result = await bucmAPI.participants.participateInCollaboration(userId, collaborationId, {
                acceptedSubmissionDeadlineUtc: data?.submissionDeadlineUtc || '',
                isAccepted: true
            });
            if (result.isSuccess) {
                infoModal.openModal({
                    title: `Request Sent`,
                    text: `Thank you for your request. Please wait until the brand has reviewed your request. Once approved, you can submit your content.`,
                    buttonText: 'Okay, got it!'
                });
            }
        }
        return { bcmUserId: userId, collaborationId };
    }
});

const leaveCollaborationFx = createEffect({
    handler: async ({ userId, collaborationId }: { userId: string; collaborationId: string }) => {
        await bucmAPI.participants.leaveCollaboration(userId, collaborationId);
        return { bcmUserId: userId, collaborationId };
    }
});

//Exports

export const participateCollaborationEffects = {
    joinCollaborationFx,
    leaveCollaborationFx,
    requestParticipationFx
};
