import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { Activities } from 'components/pages/wallet/Activities';
import { WalletCards } from 'components/pages/wallet/WalletCards';
import { WalletInfo } from 'components/pages/wallet/WalletInfo';
import { Wrapper } from 'pages/Wallet/styles';
import React, { useEffect } from 'react';
import { walletDataEffects } from 'stores/wallet-data';

const { getWalletInfoFx } = walletDataEffects;

export const WalletPage = () => {
    useEffect(() => {
        getWalletInfoFx();
    }, []);

    return (
        <NewMainLayout>
            <Wrapper>
                <Section marginBottom="12px">
                    <WalletInfo />
                </Section>

                <Section marginBottom="24px">
                    <WalletCards />
                </Section>

                <Activities />
            </Wrapper>
        </NewMainLayout>
    );
};
