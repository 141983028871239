import { baseColors } from 'constants/styles/colors';
import { CSSProperties } from 'react';
import styled from 'styled-components';

const lineHeight = 2;

export const TimelineWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
`;

export const RangeWrapper = styled.div`
    flex-grow: 1;
`;

export const handleStyles: CSSProperties = {
    height: 14,
    width: 14,
    backgroundColor: baseColors.white,
    marginTop: '-6px',
    marginLeft: '7px',
    border: 'none',
    boxShadow: 'none',
    opacity: 1
};

export const trackStyles: CSSProperties = {
    height: lineHeight,
    backgroundColor: baseColors.blue,
    borderRadius: 26,
    cursor: 'pointer'
};

export const railStyles: CSSProperties = {
    width: '100%',
    height: lineHeight,
    backgroundColor: baseColors.lightGrey,
    borderRadius: 26,
    cursor: 'pointer'
};
