import { BackArrowSmIcon, CheckSmIcon, PlusSmIcon } from 'assets/icons';
import NotFound from 'assets/img/not-found.png';
import { PreviewVideoCard } from 'components/cards/PreviewVideoCard';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { defaultUserVideosQueryParams } from 'constants/services/user-videos';
import { baseColors, grey } from 'constants/styles';
import { useStore } from 'effector-react';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { hashtagOrTopicVideosEffects, hashtagOrTopicVideosStores } from 'stores/hashtag-or-topic';
import { userEffects, userStores } from 'stores/user';
import { userHashtagEffects } from 'stores/user-hashtag';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems } from 'utils/video';
import { Empty, Image, VideoCardsWrapper, Wrapper } from './styles';

const { $hashtagOrTopicVideos, $hasError } = hashtagOrTopicVideosStores;
const { getHashtagOrTopicVideosFx, loadMoreHashtagOrTopicFx } = hashtagOrTopicVideosEffects;

const { $userHashtags } = userStores;
const { getUserHashtagsFx } = userEffects;

const { handleFollowHashtagFx } = userHashtagEffects;

const { $user, $isAuth } = authStores;

export interface QueryProps {
    by?: SearchCategory.Topic | SearchCategory.Hashtag;
    query?: string;
    topicId?: string;
    tagId?: string;
    name?: string;
    isCategory?: string;
}

export const VideoCards = () => {
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [isHoveredId, setIsHoveredId] = useState('');

    const { items, currentPageIndex, totalPages } = useStore($hashtagOrTopicVideos);
    const isLoading = useStore(getHashtagOrTopicVideosFx.pending);
    const hasError = useStore($hasError);
    const isMoreLoading = useStore(loadMoreHashtagOrTopicFx.pending);
    const userHashtags = useStore($userHashtags);
    const user = useStore($user);
    const isAuth = useStore($isAuth);

    const history = useHistory();

    const { query, by, topicId, tagId, name, isCategory } = queryString.parse(history.location.search) as QueryProps;

    const isFollowed = !!userHashtags?.items?.find(item => item.hashTag === name);

    const goToBack = () => {
        if (query && by) {
            switch (by) {
                case SearchCategory.Topic:
                    history.push(`${AppRoute.Search}?type=${SearchCategory.Topic}&query=${query}`);
                    break;
                case SearchCategory.Hashtag:
                    history.push(`${AppRoute.Search}?type=${SearchCategory.Hashtag}&query=${query}`);
                    break;
                default:
                    break;
            }
        } else {
            history.goBack();
        }
    };

    const handleCardClick = useCallback(
        (id?: string | null) => {
            fullscreenVideoEvents.setPlaylist(getPlaylistItems(items));
            id && history.push(getFullscreenVideoUrl(id, 'search'));
        },
        [history, items]
    );

    const handleCardMouseEnter = useCallback((id?: string | null) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (id) {
            timer.current = setTimeout(() => {
                setIsHoveredId(id);
            }, 300);
        }
    }, []);

    const handleCardMouseLeave = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        setIsHoveredId('');
    }, []);

    const onLoadMore = () => {
        if (currentPageIndex !== undefined) {
            switch (by) {
                case SearchCategory.Topic: {
                    loadMoreHashtagOrTopicFx({
                        ...defaultUserVideosQueryParams,
                        byProductId: topicId,
                        pageIndex: currentPageIndex + 1
                    });
                    break;
                }
                case SearchCategory.Hashtag: {
                    loadMoreHashtagOrTopicFx({
                        ...defaultUserVideosQueryParams,
                        byHashtag: tagId,
                        pageIndex: currentPageIndex + 1
                    });
                    break;
                }
            }
        }
    };

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore,
        disabled: hasError,
        rootMargin: '0px'
    });

    const onClickHashTag = () => {
        handleFollowHashtagFx({ userId: user?.userId || '', hashtag: name, isFollow: !isFollowed }).then(() => {
            getUserHashtagsFx();
        });
    };

    useEffect(() => {
        if ((tagId || topicId) && by) {
            switch (by) {
                case SearchCategory.Hashtag:
                    getHashtagOrTopicVideosFx({
                        byHashtag: tagId
                    });
                    break;
                case SearchCategory.Topic:
                    getHashtagOrTopicVideosFx({
                        byProductId: topicId
                    });
                    break;
                default:
                    break;
            }
        }
    }, [by, query, topicId, tagId]);

    useEffect(() => {
        getUserHashtagsFx();
    }, []);

    return (
        <Wrapper>
            <Section alignCenter justifyBetween marginBottom={isMobile ? '20px' : '30px'}>
                <Row noWrap>
                    <IconButton onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                    <Section marginLeft="8px">
                        <Text isBold size={isMobile ? '0' : '1'}>
                            {`${
                                by === SearchCategory.Hashtag && isCategory && !Boolean(+isCategory) ? '#' : ''
                            }${name}`}
                        </Text>
                    </Section>
                </Row>

                {isAuth && !isMobile && (
                    <Button
                        buttonType={isFollowed ? 'secondary' : 'primary'}
                        icon={isFollowed ? <CheckSmIcon color={baseColors.black} /> : <PlusSmIcon />}
                        isBold={false}
                        size="small"
                        textSize="small"
                        onClick={onClickHashTag}
                    >
                        {isFollowed ? 'Following' : 'Follow'}
                    </Button>
                )}
            </Section>

            {items && items.length > 0 && (
                <>
                    <VideoCardsWrapper>
                        {items.map(item => (
                            <PreviewVideoCard
                                key={item.id}
                                id={item.id}
                                isActive={item.id === isHoveredId}
                                thumbnailUrl={item?.streaming?.screenGrabUrl}
                                videoSrc={item?.streaming?.hlsUrl}
                                viewCount={item?.engagement?.views}
                                onClick={handleCardClick}
                                onMouseEnter={handleCardMouseEnter}
                                onMouseLeave={handleCardMouseLeave}
                            />
                        ))}
                    </VideoCardsWrapper>
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}

            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    {isMobile && <Image src={NotFound} />}
                    <Text color={grey[500]} size={isMobile ? '-2' : '0'}>
                        No Results Found
                    </Text>
                </Empty>
            )}

            {(isLoading || isMoreLoading) && (
                <Section justifyCenter marginTop="24px">
                    <Spinner />
                </Section>
            )}
        </Wrapper>
    );
};
