import {
    AppStoreLgIcon,
    bullzLogoPath,
    bullzSmPath,
    BurgerMdIcon,
    GooglePlayLgIcon,
    logoutSmPath,
    profileSettingsSmPath,
    settingsWhiteSmPath,
    walletSmPath,
    XMdIcon
} from 'assets/icons';
import { CustomImage } from 'components/common/CustomImg/styles';
import { MobileMenu } from 'components/grid/Header/MobileMenu';
import { SearchDropdown } from 'components/grid/Header/SearchDropdown';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { DropdownNotifications } from 'components/pages/notifications/DropdownNotifications';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { OptionItemProps, OptionsPopover } from 'components/ui/OptionsPopover';
import { androidAppStoreLink, AppRoute, iosAppStoreLink } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { authEvents, authStores } from 'stores/auth';
import { headerEvents, headerStores } from 'stores/header';
import { infoModal, loginModal, registerModal } from 'stores/initialize.modal';
import { walletStores } from 'stores/wallet';
import { handleClickAppStoreApp, handleClickGooglePlayApp } from 'utils/googleTagManagerEvents';
import { Avatar, Container, DefaultAvatarWrapper, LogoWrapper, StyledLink, Wrapper } from './styles';

interface Props {
    handleUploadClick?: () => void;
}

export const Header: FC<Props> = ({ handleUploadClick }) => {
    const isVisibleBurgerMenu = useStore(headerStores.$isVisibleBurgerMenu);

    const isAuth = useStore(authStores.$isAuth);
    const user = useStore(authStores.$user);
    const hasWallet = useStore(walletStores.$hasWallet);
    const womUserIsLoaded = useStore(walletStores.$womUserIsLoaded);

    const handleBurgerClick = () => {
        headerEvents.toggleBurgerMenu();
    };

    const handleLogOut = () => {
        infoModal.openModal({
            title: 'Log Out',
            text: 'Are you sure you want to log out?',
            buttonText: 'Yes',
            cancelText: 'cancel',
            onClick: () => authEvents.logout(),
            onCloseClick: () => infoModal.closeModal()
        });
    };

    const popoverOptions: OptionItemProps[] = useMemo(() => {
        const options: OptionItemProps[] = [
            {
                id: 'profile',
                title: 'Profile',
                icon: profileSettingsSmPath,
                link: `${AppRoute.Profile}/${user?.userId}`
            }
        ];

        if (isAuth && womUserIsLoaded && hasWallet) {
            options.push({
                id: 'wallet',
                title: 'Wallet',
                icon: walletSmPath,
                link: AppRoute.Wallet
            });
        }

        if (isAuth) {
            options.push({
                id: 'settings',
                title: 'Settings',
                icon: settingsWhiteSmPath,
                link: AppRoute.Settings
            });
        }

        options.push({
            id: 'logout',
            title: 'Log Out',
            icon: logoutSmPath,
            action: handleLogOut
        });

        return options;
    }, [user, isAuth, womUserIsLoaded, hasWallet]);

    return (
        <Wrapper>
            <Container>
                <LogoWrapper>
                    <Link to={AppRoute.Main}>
                        <CustomImage alt="Bullz logo" height="42px" src={bullzLogoPath} width="88px" />
                    </Link>
                </LogoWrapper>

                {isMobile ? (
                    <Section alignCenter justifyEnd>
                        {isIOS ? (
                            <StyledLink
                                href={iosAppStoreLink}
                                rel="noreferrer"
                                target="_blank"
                                onClick={handleClickAppStoreApp}
                            >
                                <AppStoreLgIcon />
                            </StyledLink>
                        ) : (
                            <StyledLink
                                href={androidAppStoreLink}
                                rel="noreferrer"
                                target="_blank"
                                onClick={handleClickGooglePlayApp}
                            >
                                <GooglePlayLgIcon />
                            </StyledLink>
                        )}

                        <Column alignEnd justifyCenter marginLeft="16px">
                            <IconButton size="40px" onClick={handleBurgerClick}>
                                {isVisibleBurgerMenu ? <XMdIcon /> : <BurgerMdIcon />}
                            </IconButton>
                        </Column>
                    </Section>
                ) : (
                    <Section alignCenter justifyBetween>
                        <SearchDropdown />
                        <Row alignCenter justifyEnd>
                            <Row marginRight="12px">
                                <StyledLink
                                    href={iosAppStoreLink}
                                    rel="noreferrer"
                                    target="_blank"
                                    onClick={handleClickAppStoreApp}
                                >
                                    <AppStoreLgIcon />
                                </StyledLink>
                            </Row>
                            <Row marginRight="28px">
                                <StyledLink
                                    href={androidAppStoreLink}
                                    rel="noreferrer"
                                    target="_blank"
                                    onClick={handleClickGooglePlayApp}
                                >
                                    <GooglePlayLgIcon />
                                </StyledLink>
                            </Row>
                            {isAuth ? (
                                <Row alignCenter>
                                    <MarginWrapper marginRight="16px">
                                        <Button onClick={handleUploadClick}>Upload</Button>
                                    </MarginWrapper>
                                    <Column marginRight="16px">
                                        <DropdownNotifications />
                                    </Column>
                                    <OptionsPopover items={popoverOptions}>
                                        {user?.profile?.profileImageUrl ? (
                                            <Avatar height="32px" src={user?.profile?.profileImageUrl} width="32px" />
                                        ) : (
                                            <DefaultAvatarWrapper>
                                                <CustomImage height="13px" src={bullzSmPath} width="8px" />
                                            </DefaultAvatarWrapper>
                                        )}
                                    </OptionsPopover>
                                </Row>
                            ) : (
                                <Row alignCenter justifyEnd>
                                    <TextButton size="large" onClick={() => loginModal.openModal()}>
                                        Login
                                    </TextButton>

                                    <Column marginLeft="20px">
                                        <Button isBold onClick={() => registerModal.openModal({})}>
                                            Sign up
                                        </Button>
                                    </Column>
                                </Row>
                            )}
                        </Row>
                    </Section>
                )}
            </Container>

            {isMobile && <MobileMenu isVisible={isVisibleBurgerMenu} onLogout={handleLogOut} />}
        </Wrapper>
    );
};
