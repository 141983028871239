import { requiredShortMessage } from 'constants/messages';
import { yupQsDescription, yupQsPrice, yupQsTitle } from 'constants/yup';
import { FormikErrors } from 'formik';
import { qsChannelEffects } from 'stores/quick-signals-channel';
import * as Yup from 'yup';

export type PriceType = string | number;

export interface QSFormValues {
    title: string;
    price: PriceType;
    description: string;
}

export interface QSFormEditValues extends Omit<QSFormValues, 'price'> {}

export const qsInitialValues: QSFormValues = {
    title: '',
    price: '',
    description: ''
};

export const schema = Yup.object().shape({
    title: yupQsTitle,
    price: yupQsPrice,
    description: yupQsDescription
});

const validateTitle = async (title: string) => {
    try {
        await Yup.reach(schema, 'title').validate(title);

        const response = await qsChannelEffects.checkTitleFx(title);
        if (!response.data) {
            return 'Title is not available';
        }

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validatePrice = async (price: PriceType) => {
    try {
        if (price === '') return requiredShortMessage;

        await Yup.reach(schema, 'price').validate(+price);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

const validateDescription = async (description: string) => {
    try {
        await Yup.reach(schema, 'description').validate(description);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

export const validateQSForm = async ({ title, price, description }: QSFormValues) => {
    let errors: FormikErrors<Partial<QSFormValues>> = {};

    const titleError = await validateTitle(title);
    errors = titleError ? { ...errors, title: titleError } : errors;

    const priceError = await validatePrice(price);
    errors = priceError ? { ...errors, price: priceError } : errors;

    const descriptionError = await validateDescription(description);
    errors = descriptionError ? { ...errors, description: descriptionError } : errors;

    return errors;
};
