import { FormWrapper } from 'components/form/styles';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { SelectButton } from 'components/ui/buttons/SelectButton';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { authStores } from 'stores/auth';
import { AdditionalRegisterFieldName, Step } from '../constants';
import { genderList } from './constants';
import { SelectGrid } from './styles';

const { $user } = authStores;

interface Props {
    setCurrentStep: Dispatch<SetStateAction<Step>>;
}

export const GenderStep: FC<Props> = ({ setCurrentStep }) => {
    const user = useStore($user);

    const [, { value: genderValue, error: genderError }, { setValue: setGenderValue }] = useField<
        BULLZ.UserGender | undefined
    >(AdditionalRegisterFieldName.Gender);

    const isSubmitActive = genderValue !== undefined && !genderError;

    const handleGenderChange = (value: BULLZ.UserGender) => {
        setGenderValue(genderValue === value ? undefined : value);
    };

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!!genderError || !user) {
            return;
        }

        setCurrentStep(Step.Categories);
    };

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Column width="100%">
                <Section marginBottom="8px">
                    <Text size="-1">Gender</Text>
                </Section>
                <SelectGrid>
                    {genderList.map(({ value, label }) => (
                        <SelectButton
                            key={value}
                            wide
                            selected={genderValue !== undefined && value === genderValue}
                            type="button"
                            onClick={() => handleGenderChange(value)}
                        >
                            {label}
                        </SelectButton>
                    ))}
                </SelectGrid>
            </Column>

            <Section>
                <Button wide disabled={!isSubmitActive} type="submit">
                    Next
                </Button>
            </Section>
        </FormWrapper>
    );
};
