import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';
import { authStores } from 'stores/auth';
import { createWalletPinModal, walletGuideModal } from 'stores/initialize.modal';
import { settingsWalletPinEffects, settingsWalletPinStores } from 'stores/settings/wallet-pin';
import { walletEffects, walletStores } from 'stores/wallet';
import { NoopType } from 'types';
import { buttonTexts, descriptions, RenderCase, titles } from './constants';

interface Props {
    onSuccess: NoopType;
}

export const SendCodeStep: FC<Props> = ({ onSuccess }) => {
    const user = useStore(authStores.$user);
    const numberFragment = user?.profile?.mobileNumber ? user.profile.mobileNumber.slice(-2) : 'xx';

    const hasWallet = useStore(walletStores.$hasWallet);
    const womUserIsLoaded = useStore(walletStores.$womUserIsLoaded);
    const hasWalletPin = useStore(settingsWalletPinStores.$hasWalletPin);
    const hasWalletPinIsLoaded = useStore(settingsWalletPinStores.$hasWalletPinIsLoaded);
    const isLoading = useStore(settingsWalletPinEffects.existsWalletPinFx.pending);

    const renderCase: RenderCase = useMemo(() => {
        if (womUserIsLoaded && hasWalletPinIsLoaded) {
            if (hasWallet) {
                if (hasWalletPin) {
                    return RenderCase.WalletPinCreated;
                } else {
                    return RenderCase.WalletPinNotCreated;
                }
            } else {
                return RenderCase.WalletNotCreated;
            }
        }

        return RenderCase.None;
    }, [hasWallet, hasWalletPin, hasWalletPinIsLoaded, womUserIsLoaded]);

    const getDescription = () => {
        if (renderCase === RenderCase.WalletPinCreated) {
            return `${descriptions[renderCase]}${numberFragment}`;
        }

        return descriptions[renderCase];
    };

    const sendCode = () => {
        walletEffects.sendForgotPinSmsCodeFx().then(() => {
            onSuccess();
        });
    };

    const openWalletCreateModal = () => {
        walletGuideModal.openModal();
    };

    const openWalletPinCreateModal = () => {
        createWalletPinModal.openModal();
    };

    const handleClick = () => {
        switch (renderCase) {
            case RenderCase.WalletPinCreated:
                sendCode();
                break;
            case RenderCase.WalletPinNotCreated:
                openWalletPinCreateModal();
                break;
            case RenderCase.WalletNotCreated:
                openWalletCreateModal();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        !hasWalletPinIsLoaded && settingsWalletPinEffects.existsWalletPinFx();
    }, [hasWalletPinIsLoaded]);

    return (
        <Column noWrap height="100%" width="100%">
            {isLoading || renderCase === RenderCase.None ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : (
                <>
                    <Section marginBottom="8px">
                        <Text isBold size="1">
                            {titles[renderCase]}
                        </Text>
                    </Section>

                    <Column justifyBetween height="100%">
                        <Section marginBottom="16px">
                            <Text size="-2">{getDescription()}</Text>
                        </Section>

                        <Section justifyEnd>
                            <Button onClick={handleClick}>{buttonTexts[renderCase]}</Button>
                        </Section>
                    </Column>
                </>
            )}
        </Column>
    );
};
