import { Column } from 'components/grid/wrappers/FlexWrapper';
import { AuthModalLayout } from 'components/layouts/AuthModalLayout';
import { CategoriesStep } from 'components/modals/AdditionalRegisterModal/CategoriesStep';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { authStores } from 'stores/auth';
import { additionalRegisterModal } from 'stores/initialize.modal';
import { registerStores } from 'stores/register';
import { noop } from 'utils/common';
import { BirthdayStep } from './BirthdayStep';
import { GenderStep } from './GenderStep';
import { Step, subtitles, titles } from './constants';
import { additionalRegisterInitialValues, validateAdditionalRegisterForm } from './validation';

const { modal, openModal } = additionalRegisterModal;
const { $isAuth, $user } = authStores;
const { $additionalRegisterIsCompleted, $additionalRegisterIsFirstShowModal } = registerStores;

export const AdditionalRegisterModal = () => {
    const isAuth = useStore($isAuth);
    const user = useStore($user);
    const isCompleted = useStore($additionalRegisterIsCompleted);
    const isFirstShowModal = useStore($additionalRegisterIsFirstShowModal);
    const [visible] = useStore(modal);

    const { dateOfBirth } = user || {};

    const [currentStep, setCurrentStep] = useState<Step>(Step.Birthday);

    const steps: Record<Step, ReactNode> = {
        [Step.Birthday]: <BirthdayStep setCurrentStep={setCurrentStep} />,
        [Step.Gender]: <GenderStep setCurrentStep={setCurrentStep} />,
        [Step.Categories]: <CategoriesStep />
    };

    const handleBackClick = () => {
        let newStep = Step.Birthday;

        if (currentStep === Step.Gender) {
            newStep = Step.Birthday;
        } else if (currentStep === Step.Categories) {
            newStep = Step.Gender;
        }

        setCurrentStep(newStep);
    };

    useEffect(() => {
        if (!visible) {
            setCurrentStep(Step.Birthday);
        }
    }, [visible]);

    useEffect(() => {
        if (!dateOfBirth && !isFirstShowModal && !isCompleted && isAuth) {
            openModal();
        }
    }, [isCompleted, isAuth, isFirstShowModal, dateOfBirth]);

    return (
        <AuthModalLayout
            closeModal={noop}
            isBackButtonVisible={currentStep !== Step.Birthday}
            isCloseButtonVisible={false}
            subtitle={subtitles[currentStep]}
            title={titles[currentStep]}
            visible={visible && isAuth}
            onBackClick={handleBackClick}
        >
            <Formik
                initialValues={additionalRegisterInitialValues}
                validate={validateAdditionalRegisterForm}
                onSubmit={noop}
            >
                <Column width="100%">{steps[currentStep]}</Column>
            </Formik>
        </AuthModalLayout>
    );
};
