import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import walletImg from 'assets/img/create-wallet/wallet.png';
import { CustomImg } from 'components/common/CustomImg';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { ImageWrapper } from 'components/modals/CreateWalletPinModal/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { forgotWalletPinModal, verifyWalletPinModal } from 'stores/initialize.modal';
import { noop } from 'utils/common';
import { Form } from './Form';
import { validateWalletPinForm, walletPinValues } from './validation';

const { modal, closeModal } = verifyWalletPinModal;

export const VerifyWalletPinModal = () => {
    const history = useHistory();
    const [visible] = useStore(modal);

    const handleClose = () => closeModal();

    const handleFormSuccess = () => {
        history.push(AppRoute.Wallet);
    };

    const handleForgotPasswordClick = () => {
        closeModal();
        forgotWalletPinModal.openModal();
    };

    return (
        <ModalWrapper fullscreenMobile visible={visible} zIndex={200} onClose={handleClose}>
            <Section justifyEnd={!isMobile} marginBottom="24px">
                <IconButton color={grey[500]} onClick={handleClose}>
                    {isMobile ? <BackArrowSmIcon /> : <CloseSmIcon />}
                </IconButton>
            </Section>

            <Column justifyBetween noWrap height="100%" width="100%">
                <ImageWrapper>
                    <CustomImg src={walletImg} />
                </ImageWrapper>

                <Column noWrap width="100%">
                    <Formik initialValues={walletPinValues} validate={validateWalletPinForm} onSubmit={noop}>
                        <Form onSuccess={handleFormSuccess} />
                    </Formik>

                    <Section justifyCenter marginTop="24px">
                        <TextButton isBold isUppercase onClick={handleForgotPasswordClick}>
                            Forgot wallet pin
                        </TextButton>
                    </Section>
                </Column>
            </Column>
        </ModalWrapper>
    );
};
