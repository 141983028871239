import { BottomMenu } from 'components/grid/BottomMenu';
import { Header } from 'components/grid/Header';
import { SideBar } from 'components/grid/SideBar';
import { Container, Wrapper } from 'components/layouts/NewMainLayout/styles';
import { useUploadClick } from 'hooks/useUploadClick';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
    isHideCreators?: boolean;
    isHideSideBar?: boolean;
    isHidePaddingX?: boolean;
    hasMobilePaddingTop?: boolean;
}

export const NewMainLayout: FC<Props> = ({
    children,
    isHideCreators,
    isHidePaddingX,
    hasMobilePaddingTop,
    isHideSideBar
}) => {
    const handleUploadClick = useUploadClick();

    return (
        <>
            <Header handleUploadClick={handleUploadClick} />
            <Wrapper hasMobilePaddingTop={hasMobilePaddingTop} isHidePaddingX={isHidePaddingX}>
                {!isMobile && !isHideSideBar && <SideBar isHideCreators={isHideCreators} />}
                <Container isHideSideBar={isHideSideBar} isMobile={isMobile}>
                    {children}
                </Container>
            </Wrapper>
            {isMobile && <BottomMenu handleUploadClick={handleUploadClick} />}
        </>
    );
};
