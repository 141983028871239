import { Section } from 'components/grid/wrappers/FlexWrapper';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const SelectsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr;

    ${media.laptop(`
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
    `)};
`;

export const TextWrapper = styled(Section)`
    justify-content: center;
    text-align: center;
    margin-top: 8px;
`;
