import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const WrapperCard = styled.div`
    position: relative;
    width: 100%;
    height: 140px;
    ${flexCenter};
    cursor: pointer;
    background-color: ${baseColors.darkGrey};
    overflow: hidden;

    ${media.laptop(`
        height: 250px;
    `)};
`;

export const PreviewImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;

    ${media.laptop(`
        height: auto;
    `)};
`;

export const FeaturesWrapper = styled.div`
    position: absolute;
    bottom: 8px;
    left: 8px;
    z-index: 1;
`;

export const Views = styled.div`
    height: 24px;
    background-color: ${baseColors.black};
    mix-blend-mode: normal;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 3px 6px;
`;
