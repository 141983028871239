import { BackArrowSmIcon, CopySmIcon, WomSmIcon } from 'assets/icons';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { totalCurrency } from 'components/pages/wallet/utils';
import { TextWrapper, WalletIdWrapper } from 'components/pages/wallet/WalletInfo/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { walletAccountType } from 'constants/global';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { walletDataEffects, walletDataStores } from 'stores/wallet-data';

const { $walletInfo, $womRates } = walletDataStores;
const { getWalletInfoFx, getWomRatesFx } = walletDataEffects;

export const WalletInfo = () => {
    const history = useHistory();

    const { items } = useStore($walletInfo);
    const isLoading = useStore(getWalletInfoFx.pending);
    const rates = useStore($womRates);
    const usdRates = rates && rates.rates ? Number(rates.rates[0].price?.toFixed(4)) : 0;

    const walletInfo = items?.find(item => item.accountType === walletAccountType);

    const valueUsd = totalCurrency(walletInfo?.balance || 0, usdRates || 0);

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Link Copied');
        }
    };

    const goToBack = () => {
        history.goBack();
    };

    useEffect(() => {
        getWalletInfoFx();
        getWomRatesFx();
    }, []);

    return (
        <Column width="100%">
            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}

            {walletInfo && Object.keys(walletInfo).length > 0 && (
                <>
                    <Section noWrap marginBottom="24px">
                        {isMobile && (
                            <IconButton onClick={goToBack}>
                                <BackArrowSmIcon />
                            </IconButton>
                        )}
                        <Section justifyCenter={isMobile}>
                            <Text isBold size="1">
                                WOM Wallet Activity
                            </Text>
                        </Section>
                    </Section>
                    <Column alignCenter={isMobile} marginBottom="16px" width="100%">
                        <Row alignCenter noWrap marginBottom="4px">
                            <Text isBold size="4">
                                {walletInfo?.balance || 0}
                            </Text>
                            <Section marginLeft="12px">
                                <WomSmIcon />
                            </Section>
                        </Row>
                        <Text>$ {valueUsd || 0}</Text>
                    </Column>

                    {walletInfo && walletInfo.address && (
                        <CopyToClipboard text={walletInfo.address} onCopy={handleCopy}>
                            <WalletIdWrapper>
                                <TextWrapper>{walletInfo.address}</TextWrapper>

                                <IconButton color={grey['500']} size="16px">
                                    <CopySmIcon />
                                </IconButton>
                            </WalletIdWrapper>
                        </CopyToClipboard>
                    )}
                </>
            )}
        </Column>
    );
};
