import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { authStores } from 'stores/auth';
import { loginModal, registerModal } from 'stores/initialize.modal';
import { ParentWrapper, Wrapper } from './styles';

export const NoComments: FC = () => {
    const isAuth = useStore(authStores.$isAuth);

    return (
        <ParentWrapper>
            <Wrapper alignCenter justifyCenter>
                {isAuth ? (
                    <Text color={grey[500]} size="-1">
                        Be the first one to comment!
                    </Text>
                ) : (
                    <Column alignCenter>
                        <Row marginBottom="16px">
                            <Text alignTextCenter color={grey[500]} size="-1">
                                Log In/Sign up to be the first one to comment!
                            </Text>
                        </Row>
                        <MarginWrapper marginBottom="16px">
                            <Button isBold={false} onClick={() => loginModal.openModal()}>
                                Log In
                            </Button>
                        </MarginWrapper>
                        <TextButton onClick={() => registerModal.openModal({})}>Sign Up</TextButton>
                    </Column>
                )}
            </Wrapper>
        </ParentWrapper>
    );
};
