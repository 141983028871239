import { FlexGrow, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import { ParticipationEndedReason } from 'constants/defaults/collaborations';
import { grey } from 'constants/styles';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { videoEvents } from 'stores/video';

interface Props {
    myCollaborationData: BUCM.CreatorCollaborationResponse | null;
    timerComponent: FC;
}

export const EndedParticipationFlow: FC<Props> = ({ myCollaborationData, timerComponent: TimerComponent }) => {
    const checkResults = () => {
        if (myCollaborationData?.mediaAssets && myCollaborationData.mediaAssets[0].remoteId) {
            videoEvents.openStatusVideoModal(myCollaborationData.mediaAssets[0].remoteId);
        }
    };

    if (myCollaborationData?.participationEndedReason === ParticipationEndedReason.Leave) {
        return (
            <FlexGrow
                alignCenter
                flexDirection={isMobile ? 'column' : 'row'}
                justifyBetween={!isMobile}
                justifyCenter={isMobile}
            >
                <Row marginBottom={isMobile ? '24px' : '0'} width={isMobile ? 'auto' : '460px'}>
                    <Text alignTextCenter={isMobile} color={grey[500]} size="-1">
                        Unfortunately this Collaboration is not available for you. No worries, you can check out other
                        Collaborations!
                    </Text>
                </Row>
                <Button disabled>Collaboration Unavailable</Button>
            </FlexGrow>
        );
    } else if (myCollaborationData?.participationEndedReason === ParticipationEndedReason.KickedOut) {
        return (
            <Section alignCenter justifyCenter>
                <Button disabled>Video not approved</Button>
            </Section>
        );
    } else if (
        myCollaborationData?.participationEndedReason === ParticipationEndedReason.SubmittedAndPublished ||
        myCollaborationData?.participationEndedReason === ParticipationEndedReason.SubmittedOnly
    ) {
        return (
            <Section alignCenter justifyBetween={!isMobile} justifyCenter={isMobile}>
                <TimerComponent />
                <Button onClick={checkResults}>Check Results</Button>
            </Section>
        );
    } else if (
        myCollaborationData?.participationEndedReason === ParticipationEndedReason.ParticipationRequestRejected
    ) {
        return (
            <Section alignCenter justifyCenter>
                <Button disabled>Request not approved</Button>
            </Section>
        );
    }
    return null;
};
