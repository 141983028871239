import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { IconWrapper, StyledButton } from './styles';
import { NotificationData } from './types';
import { formatDate, getNotificationData } from './utils';

interface Props {
    data: BULLZ.NotificationItemResponse;
}

export const NotificationItem: FC<Props> = ({ data }) => {
    const history = useHistory();
    const info = getNotificationData(data);

    const handleClick = ({ link, action }: NotificationData) => () => {
        if (!link && !action) return;

        if (action) {
            action();
        }

        if (link) {
            history.push(link);
        }
    };

    if (!info) {
        return null;
    }

    return (
        <StyledButton type="button" onClick={handleClick(info)}>
            <IconWrapper>{info.icon || <AvatarImg diameter="40px" />}</IconWrapper>

            <Column marginLeft="8px">
                <Text size="-2">{info.title}</Text>

                {info?.text && (
                    <Text color={grey[500]} size="-2">
                        {info.text}
                    </Text>
                )}

                <Row marginTop="4px">
                    <Text color={grey[500]} size="-2">
                        {formatDate(data.utcCreated)}
                    </Text>
                </Row>
            </Column>
        </StyledButton>
    );
};
