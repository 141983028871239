export const baseURL = process.env.REACT_APP_API_URL as string;
export const womURL = process.env.REACT_APP_WOM_API_URL as string;
export const bucmURL = process.env.REACT_APP_BUCM_API_URL as string;
export const environment = process.env.REACT_APP_ENV as string;

export const walletAccountType = 'wom';

export const isDev = baseURL === 'https://bullz-dev.bullz.io/';

export const buwpLinkDev = 'https://bullz.player.dev-stage.su/';
export const buwpLinkClientProd = 'https://app.bullz.com/';
export const buwpLink = isDev ? buwpLinkDev : buwpLinkClientProd;

// API versions
export const bullzApiVersion = process.env.REACT_APP_API_VERSION as string;
export const womApiVersion = process.env.REACT_APP_WOM_API_VERSION as string;
export const bucmApiVersion = process.env.REACT_APP_BUCM_API_VERSION as string;

// Local storage keys
export const lsTokenKey = 'buwp_token';
export const lsUserKey = 'buwp_user';
export const lsAnonymousUserKey = 'buwp_anonymous_user';
export const lsSessionKey = 'buwp_session';
export const lsAdditionalRegisterCompleted = 'buwp_add_register_completed';
export const hasCloseKeyboardShortcutModal = 'buwp_ShortcutModal_has_close';
