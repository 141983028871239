import { DeleteAccountLgIcon } from 'assets/icons';
import { defaultUserHashtagsQueryParams } from 'constants/services/user';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk } from 'utils/common';

const reportErrorMessage = 'User already reported by this reason';

//Events
const resetProfileStores = createEvent();
const updateProfile = createEvent<BULLZ.GetUserProfileResponse>();

//Effects
export const getUserProfileFx = createEffect({
    handler: async (id: string) => await API.user.getProfile({ userId: id })
});

const getUserHashtagsFx = createEffect({
    handler: async () => await API.user.getUserHashtags({ ...defaultUserHashtagsQueryParams })
});

const reportUserFx = createEffect({
    handler: async (data: BULLZ.ReportUserRequest) => {
        try {
            const { isSuccess } = await API.user.reportUser(data);

            if (!isSuccess) {
                throw new Error();
            }

            if (data.requestUserBlocking) {
                infoModal.openModal({
                    title: 'Reported and blocked successfully',
                    text: 'You successfully reported this account & blocked the creator.',
                    buttonText: 'Got it'
                });
            } else {
                infoModal.openModal({
                    title: 'Reported Successfully',
                    text: 'You have successfully reported this account.',
                    buttonText: 'Got it'
                });
            }
        } catch (error) {
            const { message } = error as BULLZ.MessageResponseBase;
            if (message === reportErrorMessage) {
                infoModal.openModal({
                    title: 'Profile already reported',
                    text:
                        'Thank you for your report. This profile is currently under review by our team to ensure a safe community experience.',
                    buttonText: 'Ok, got it!'
                });
            } else {
                infoModal.openModal({
                    icon: DeleteAccountLgIcon,
                    title: 'Something went wrong',
                    text: 'There was a problem reporting this account. Please try again or contact our support team.',
                    buttonText: 'Try again',
                    cancelText: 'CONTACT SUPPORT',
                    onCloseClick: goToHelpDesk
                });
            }
        }
    }
});

//Stores
const $userProfile = createStore<BULLZ.GetUserProfileResponse>({})
    .on([getUserProfileFx.doneData, updateProfile], (_, newState) => newState)
    .reset(resetProfileStores);

const $userHashtags = createStore<BULLZ.QueryFollowingHashTagsResponse>({})
    .on(getUserHashtagsFx.doneData, (_, newState) => newState)
    .reset(resetProfileStores);

//Exports
export const userEvents = { resetProfileStores, updateProfile };
export const userEffects = { getUserProfileFx, getUserHashtagsFx, reportUserFx };
export const userStores = { $userProfile, $userHashtags };
