export enum GiftTab {
    All,
    My
}

export enum GiftStatus {
    None = 0,
    InProcess = 100,
    Success = 200,
    Failure = -100
}
