import { CustomImage } from 'components/common/CustomImg/styles';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/ui/typography/Text';
import React from 'react';

export interface CountryCodeOption {
    countryCode: string;
    label: string;
    name: string;
    value: number;
    code: string;
}

export const CountryOption = ({ label, code, countryCode, name }: CountryCodeOption) => (
    <Section alignCenter justifyBetween>
        <Row alignCenter>
            <CustomImage
                alt={label}
                height="14px"
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
                width="20px"
            />
            <MarginWrapper marginLeft="8px">
                <Text size="-2">{name}</Text>
            </MarginWrapper>
        </Row>
        <Text size="-2">+{code}</Text>
    </Section>
);
