import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { AuthModalLayout } from 'components/layouts/AuthModalLayout';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { forgotPasswordModal, registerModal } from 'stores/initialize.modal';
import { noop } from 'utils/common';
import { Step, subtitles, titles } from './constants';
import { PasswordStep } from './PasswordStep';
import { PhoneStep } from './PhoneStep';
import { initialValues, validateForm } from './validation';

const { modal, closeModal } = forgotPasswordModal;

export const ForgotPasswordModal = () => {
    const [visible] = useStore(modal);
    const [currentStep, setCurrentStep] = useState<Step>(Step.Phone);

    const steps: Record<Step, ReactNode> = {
        phone: <PhoneStep setCurrentStep={setCurrentStep} />,
        password: <PasswordStep />
    };

    const close = () => {
        closeModal();
    };

    const openRegister = () => {
        closeModal();
        registerModal.openModal({});
    };

    const handleBackClick = () => {
        if (currentStep === Step.Phone) {
            closeModal();
        } else {
            setCurrentStep(Step.Phone);
        }
    };

    useEffect(() => {
        if (!visible) {
            setCurrentStep(Step.Phone);
        }
    }, [visible]);

    return (
        <AuthModalLayout
            isBackButtonVisible
            bottomExtra={
                currentStep === Step.Phone ? (
                    <Row justifyCenter width="100%">
                        <Column marginRight="16px">
                            <Text size="-2">Don’t have an account?</Text>
                        </Column>
                        <TextButton onClick={openRegister}>Sign Up</TextButton>
                    </Row>
                ) : undefined
            }
            closeModal={close}
            isCloseButtonVisible={false}
            subtitle={subtitles[currentStep]}
            title={titles[currentStep]}
            visible={visible}
            onBackClick={handleBackClick}
        >
            <Formik initialValues={initialValues} validate={validateForm} onSubmit={noop}>
                <Column width="100%">{steps[currentStep]}</Column>
            </Formik>
        </AuthModalLayout>
    );
};
