import { LinkSmIcon } from 'assets/icons';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { ButtonLinkWrapper } from 'components/pages/settings/components/ButtonLink/styles';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { goToExternalLink } from 'utils/common';

interface Props {
    link: string;
    title: string;
    icon?: FC;
}

export const ButtonLink: FC<Props> = ({ link, title, icon: Icon }) => {
    const goToPage = () => {
        goToExternalLink(link);
    };

    return (
        <ButtonLinkWrapper onClick={goToPage}>
            <Section noWrap>
                {Icon && (
                    <Row marginRight="10px">
                        <Icon />
                    </Row>
                )}
                <Text size="-1">{title}</Text>
            </Section>
            <LinkSmIcon />
        </ButtonLinkWrapper>
    );
};
