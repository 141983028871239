export enum BodyType {
    Undefined = 0,
    PlainText = 1,
    Html = 2
}

export const defaultCommentsQueryParams: BULLZ.QueryPostsRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};

export const defaultRepliesQueryParams: BULLZ.QueryRepliesRequest = {
    pageIndex: 0,
    limit: 3,
    returnQueryCount: true
};
