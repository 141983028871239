import { RightLineArrowSmIcon, VerificationSmIcon } from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { defaultQsSubscriptionsParams } from 'constants/services/quick-signals';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { qsChannelEffects, qsChannelEvents, qsChannelStores } from 'stores/quick-signals-channel';
import { getFormattedDate } from 'utils/quick-signals';
import { IconWrapper, SubscriptionsItem } from './styles';

const { resetStores } = qsChannelEvents;
const { getSubscriptionsFx } = qsChannelEffects;
const { $subscriptions, $hasSubscriptionsError } = qsChannelStores;

export const SubscriptionsList = () => {
    const subscriptionsData = useStore($subscriptions);
    const isLoading = useStore(getSubscriptionsFx.pending);
    const hasSubscriptionsError = useStore($hasSubscriptionsError);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: subscriptionsData ? subscriptionsData.hasNext || false : true,
        onLoadMore: () =>
            getSubscriptionsFx({
                limit: defaultQsSubscriptionsParams.limit,
                pageIndex:
                    subscriptionsData?.pageIndex !== undefined
                        ? subscriptionsData.pageIndex + 1
                        : defaultQsSubscriptionsParams.pageIndex
            }),
        disabled: hasSubscriptionsError,
        rootMargin: '0px'
    });

    useEffect(() => () => resetStores(), []);

    return (
        <Column noWrap maxWidth="620px" width="100%">
            {!!subscriptionsData?.items?.length &&
                subscriptionsData.items.map(item => (
                    <SubscriptionsItem key={item.channelId} to={`${AppRoute.QuickSignalsChannel}/${item.channelId}`}>
                        <Row alignCenter>
                            <AvatarImg src={item.avatarUrl || undefined} />

                            <Column marginLeft="16px">
                                <Row alignCenter marginBottom="4px">
                                    <Text>@{item.ownerName}</Text>

                                    {item.isTrusted && (
                                        <Column marginLeft="8px">
                                            <VerificationSmIcon />
                                        </Column>
                                    )}
                                </Row>

                                {item.subscriptionRenewDate && (
                                    <Text color={item.isSubscriptionCancelled ? baseColors.red : grey[500]} size="-1">
                                        {item.isSubscriptionCancelled ? 'Available until' : 'Renews'}{' '}
                                        {getFormattedDate(item.subscriptionRenewDate)}
                                    </Text>
                                )}
                            </Column>
                        </Row>

                        <IconWrapper>
                            <RightLineArrowSmIcon />
                        </IconWrapper>
                    </SubscriptionsItem>
                ))}

            <HiddenScrollBlock ref={loadRef} />

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
