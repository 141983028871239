export const boundaryNumber = 10000;

export enum KeyboardCode {
    Escape = 'Escape',
    Space = 'Space',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    L = 'KeyL',
    M = 'KeyM',
    Enter = 'Enter',
    Backspace = 'Backspace'
}
