import { BackArrowSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { SubscriptionsList } from 'components/pages/quickSignals/SubscriptionsList';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';

const { $user } = authStores;

export const QuickSignalsSubscriptions = () => {
    const history = useHistory();
    const user = useStore($user);

    const goToBack = () => {
        if (user && user.userId) {
            history.push(`${AppRoute.Profile}/${user.userId}`);
        } else {
            history.goBack();
        }
    };

    return (
        <NewMainLayout hasMobilePaddingTop>
            <Section alignCenter marginBottom="24px">
                <IconButton color={grey[500]} onClick={goToBack}>
                    <BackArrowSmIcon />
                </IconButton>
                <Column marginLeft="12px">
                    <Text isBold size="1">
                        Quick Signal Channels
                    </Text>
                </Column>
            </Section>

            <SubscriptionsList />
        </NewMainLayout>
    );
};
