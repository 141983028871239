import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { iconWrapperMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

interface WrapperProps {
    isDark?: boolean;
}

export const Wrapper = styled(Section)<WrapperProps>`
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 8px 10px;
    background-color: ${({ isDark }) => (isDark ? baseColors.black : baseColors.darkGrey)};
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const TextWrapper = styled.div`
    flex-grow: 1;
    margin-right: 4px;

    ${Text} {
        word-break: break-all;
        white-space: pre-line;
    }
`;

export const IconWrapper = styled.div`
    ${iconWrapperMixin};
`;
