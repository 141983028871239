import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Screen } from 'components/modals/LoginModal';
import { SelectButton } from 'components/ui/buttons/SelectButton';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { SelectGrid } from './styles';

interface Props {
    setCurrentScreen: Dispatch<SetStateAction<Screen>>;
}

export const ScreenChooser: FC<Props> = ({ setCurrentScreen }) => {
    const handleSelectClick = (screen: Screen) => () => {
        setCurrentScreen(screen);
    };

    return (
        <Section>
            <SelectGrid>
                <SelectButton wide onClick={handleSelectClick(Screen.Phone)}>
                    Phone Number
                </SelectButton>
                <SelectButton wide onClick={handleSelectClick(Screen.Username)}>
                    Username
                </SelectButton>
            </SelectGrid>
        </Section>
    );
};
