import { CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { FormWrapper, IconWrapper, SelectGrid } from 'components/modals/ReportVideoModal/styles';
import { Button } from 'components/ui/buttons/Button';
import { SelectButton } from 'components/ui/buttons/SelectButton';
import { Text } from 'components/ui/typography/Text';
import { Title } from 'components/ui/typography/Title';
import { yupDefault } from 'constants/yup';
import { useStore } from 'effector-react';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { reportCommentModal } from 'stores/initialize.modal';
import { reportCommentsEffects } from 'stores/report-comment';
import * as Yup from 'yup';

const { modal, closeModal } = reportCommentModal;

export const ReportCommentModal = () => {
    const [visible, { commentId, parentId }] = useStore(modal);
    const commentReportReasons: Partial<Record<BULLZ.CommentReportedState, string>> = {
        2: 'Comment contained violence',
        3: 'Comment contained harassment',
        4: 'Comment was objectionable',
        1: 'Comment was inappropriate'
    };

    const validationSchema = Yup.object().shape({
        commentId: yupDefault,
        reportedReason: yupDefault.oneOf(Object.keys(commentReportReasons))
    });

    const handleClose = () => closeModal();

    const { isValid, dirty, values, setFieldValue, handleSubmit, resetForm } = useFormik({
        initialValues: {
            commentId: '',
            reportedReason: undefined
        },
        onSubmit: ({ commentId, reportedReason }) => {
            reportCommentsEffects.reportCommentFx({
                commentId,
                reportedReason,
                parentId
            });
            handleClose();
            resetForm();
        },
        validationSchema
    });

    useEffect(() => {
        setFieldValue('commentId', commentId);
    }, [setFieldValue, commentId]);

    return (
        <ModalWrapper visible={visible} onClose={handleClose}>
            <FormWrapper onSubmit={handleSubmit}>
                <Column alignCenter>
                    <Section justifyEnd marginBottom="22px">
                        <IconWrapper type="button" onClick={handleClose}>
                            <CloseSmIcon />
                        </IconWrapper>
                    </Section>
                    <Section justifyCenter marginBottom="16px">
                        <Title size="4">Report</Title>
                    </Section>
                    <Section justifyCenter marginBottom="16px">
                        <Text alignTextCenter size="-2">
                            We are sorry that you found this comment inappropriate. Please indicate what you found
                            unacceptable about the comment and the action you would like us to undertake.
                        </Text>
                    </Section>
                    <SelectGrid>
                        {Object.entries(commentReportReasons).map(([reasonType, title]) => (
                            <SelectButton
                                key={reasonType}
                                wide
                                selected={values.reportedReason === reasonType}
                                type="button"
                                onClick={() => setFieldValue('reportedReason', reasonType)}
                            >
                                {title}
                            </SelectButton>
                        ))}
                    </SelectGrid>
                    <Section justifyCenter marginBottom="8px">
                        <Text alignTextCenter size="-2">
                            What happens now?
                        </Text>
                    </Section>
                    <Section marginBottom="28px">
                        <Text alignTextCenter size="-2">
                            This comment will be hidden immediately from all viewers. We will review your feedback and
                            take appropriate action.
                        </Text>
                    </Section>
                    <Button wide disabled={!isValid || !dirty} type="submit">
                        Report
                    </Button>
                </Column>
            </FormWrapper>
        </ModalWrapper>
    );
};
