import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const ButtonWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;

    ${media.laptop(`
        right: 16px;
    `)}
`;
