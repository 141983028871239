import { defaultCollaborationQueryParams } from 'constants/services/collaboration';
import { createEffect, createEvent, createStore } from 'effector';
import { bucmAPI } from 'services/bucm';
import { LimitPageIndex } from 'types/interfaces/data';

//Events
const resetStores = createEvent();
const resetMyCollaborations = createEvent();

// Effects
const getCreatorCollaborationsFx = createEffect({
    handler: async () => await bucmAPI.collaboration.getCreatorCollaborations({ ...defaultCollaborationQueryParams })
});

const loadMoreCollaborationsFx = createEffect({
    handler: async (params: Paths.CreatorsCollaborations.Get.QueryParameters) =>
        await bucmAPI.collaboration.getCreatorCollaborations(params)
});

interface MyCollaborationsRequest extends LimitPageIndex {
    userId: string;
}

const getMyCollaborationsFx = createEffect({
    handler: async (params: MyCollaborationsRequest) => {
        const res = await bucmAPI.collaboration.getMyCollaborations(params.userId, {
            pageIndex: params.pageIndex || defaultCollaborationQueryParams.pageIndex,
            limit: params.limit || defaultCollaborationQueryParams.limit
        });

        if (res.isSuccess) {
            return res.data || null;
        }

        return null;
    }
});

//Stores
const $creatorCollaborations = createStore<BUCM.PublicCreatorCollaborationsResponseApiResponse>({})
    .on(getCreatorCollaborationsFx.doneData, (_, newState) => newState)
    .on(loadMoreCollaborationsFx.doneData, (state, payload) => ({
        ...state,
        ...payload,
        data: { ...state.data, ...payload.data, items: [...(state.data?.items || []), ...(payload.data?.items || [])] }
    }))
    .reset(resetStores);

const $hasError = createStore<boolean>(false)
    .on(getCreatorCollaborationsFx.fail, () => true)
    .on(loadMoreCollaborationsFx.fail, () => true)
    .reset([getCreatorCollaborationsFx.done, loadMoreCollaborationsFx.done, resetStores]);

const $myCollaborations = createStore<BUCM.UserEngagedCollaborationsResponse | null>(null)
    .on(getMyCollaborationsFx.doneData, (state, payload) => ({
        ...state,
        ...payload,
        items: [...(state?.items || []), ...(payload?.items || [])]
    }))
    .reset(resetMyCollaborations);

const $myCollaborationsHasError = createStore<boolean>(false)
    .on(getMyCollaborationsFx.fail, () => true)
    .reset([getMyCollaborationsFx.done, resetMyCollaborations]);

//Exports
export const collaborationsEvents = { resetStores, resetMyCollaborations };
export const collaborationsEffects = { getCreatorCollaborationsFx, loadMoreCollaborationsFx, getMyCollaborationsFx };
export const collaborationsStores = { $creatorCollaborations, $hasError, $myCollaborations, $myCollaborationsHasError };
