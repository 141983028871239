import { PhoneLoginFormValues } from 'components/modals/LoginModal/PhoneForm/constants';
import { yupDefault, yupPhone } from 'constants/yup';
import { FormikErrors } from 'formik';
import { authEffects } from 'stores/auth';
import * as Yup from 'yup';

const { analyzeMobileNumberFx } = authEffects;

export const schema = Yup.object().shape({
    mobileNumber: yupPhone,
    password: yupDefault
});

const validateMobileNumber = async (mobileNumber: string) => {
    try {
        await Yup.reach(schema, 'mobileNumber').validate(mobileNumber);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message;
    }
};

const validatePassword = async (password: string) => {
    try {
        await Yup.reach(schema, 'password').validate(password);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message;
    }
};

//
/*** Validation ***/
//

export const validateLogInForm = async ({ mobileNumber, password }: PhoneLoginFormValues) => {
    const errors: FormikErrors<PhoneLoginFormValues> = {};

    const mobileNumberErrors = await validateMobileNumber(mobileNumber);
    if (mobileNumberErrors) {
        errors.mobileNumber = mobileNumberErrors;
    }

    const passwordErrors = await validatePassword(password);
    if (passwordErrors) {
        errors.password = passwordErrors;
    }

    return errors;
};

//
/*** API Validation ***/
//

enum AnalyzeResult {
    Exists,
    NotFoundInSystem,
    PendingVerification = -2,
    InvalidFormat = -1
}

const checkApiMobileNumber = async (mobileNumber: string) => {
    try {
        const response = await analyzeMobileNumberFx({ mobileNumber });

        if (response?.result === AnalyzeResult.InvalidFormat) {
            return 'Invalid mobile number format';
        }

        if (response?.result === AnalyzeResult.NotFoundInSystem) {
            return 'Mobile number not found';
        }

        if (response?.result === AnalyzeResult.PendingVerification) {
            return 'Mobile number is on verification';
        }

        return '';
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message as string;
    }
};

export const submitValidateLogInForm = async ({ mobileNumber }: Pick<PhoneLoginFormValues, 'mobileNumber'>) => {
    const errors: FormikErrors<PhoneLoginFormValues> = {};

    const mobileNumberError = await checkApiMobileNumber(mobileNumber);
    if (mobileNumberError) errors.mobileNumber = mobileNumberError;

    return errors;
};
