import {
    InstagramSmIcon,
    ShareFilledSmIcon,
    TiktokSmIcon,
    TwitchSmIcon,
    TwitterSmIcon,
    YoutubeSmIcon
} from 'assets/icons';
import { Row } from 'components/grid/wrappers/FlexWrapper';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { Shares } from 'components/ui/Shares';
import { IconButton } from 'components/ui/buttons/IconButton';
import { SocialNetworkName, socialNetworkLinks } from 'constants/services/social-networks';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { socialNetworksEffects, socialNetworksEvents, socialNetworksStores } from 'stores/social-networks';
import { getProfileLink, goToExternalLink } from 'utils/common';
import { ButtonWrapper, IconWrapper } from './styles';

const { getUserNetworksFx } = socialNetworksEffects;
const { resetStores } = socialNetworksEvents;
const { $userNetworks } = socialNetworksStores;

interface Props {
    userId?: string;
    isAuth?: boolean;
}

export const SocialNetworks: FC<Props> = ({ userId, isAuth }) => {
    const userNetworks = useStore($userNetworks);

    useEffect(() => {
        userId && isAuth && getUserNetworksFx(userId);

        return () => resetStores();
    }, [userId, isAuth]);

    const getNetworkIcon = (name: SocialNetworkName) => {
        switch (name) {
            case 'instagram':
                return <InstagramSmIcon />;
            case 'twitter':
                return <TwitterSmIcon />;
            case 'youtube':
                return <YoutubeSmIcon />;
            case 'tiktok':
                return <TiktokSmIcon />;
            case 'twitch':
                return <TwitchSmIcon />;
            default:
                return null;
        }
    };

    const handleNetworkClick = (network: BULLZ.GetUserExternalNetworkResponse) => {
        if (!network.username || !network.networkName) return;

        const networkLink = socialNetworkLinks[network.networkName as SocialNetworkName] || '';
        networkLink && goToExternalLink(`${networkLink}/${network.username}`);
    };

    const handleShareClick = () => {
        if (window.navigator.share && userId && isMobile) {
            window.navigator.share({
                title: 'BULLZ',
                url: getProfileLink(userId)
            });
        }
    };

    return (
        <Row alignCenter justifyEnd>
            {isAuth &&
                userNetworks.length > 0 &&
                userNetworks.map(item => (
                    <ButtonWrapper key={item.id} hasBorder>
                        <IconButton disabled={!item.networkName} onClick={() => handleNetworkClick(item)}>
                            <IconWrapper>{getNetworkIcon(item.networkName as SocialNetworkName)}</IconWrapper>
                        </IconButton>
                    </ButtonWrapper>
                ))}

            <ButtonWrapper>
                {!!window.navigator.share && isMobile ? (
                    <IconButton onClick={handleShareClick}>
                        <ShareFilledSmIcon />
                    </IconButton>
                ) : (
                    <OptionsPopover placement="bottom-end" renderItems={<Shares userId={userId} />}>
                        <IconButton>
                            <ShareFilledSmIcon />
                        </IconButton>
                    </OptionsPopover>
                )}
            </ButtonWrapper>
        </Row>
    );
};
