import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 16px;

    ${media.laptop(`
        width: calc(100% - 244px);
        margin-top: 0;
    `)};
`;

export const StyledCardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-bottom: 28px;
`;
