import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import React, { FC, ReactNode } from 'react';
import { ButtonsWrapper, IconWrapper, StyledSubtitle, StyledTitle } from './styles';

interface Props {
    visible: boolean;
    closeModal: () => void;
    title: string;
    subtitle: string;
    bottomExtra?: ReactNode;
    onBackClick?: () => void;
    isBackButtonVisible?: boolean;
    isCloseButtonVisible?: boolean;
    isCloseOnClickBackdrop?: boolean;
}

export const AuthModalLayout: FC<Props> = ({
    visible,
    closeModal,
    title,
    subtitle,
    children,
    bottomExtra,
    onBackClick,
    isBackButtonVisible,
    isCloseButtonVisible = true,
    isCloseOnClickBackdrop = true
}) => (
    <ModalWrapper visible={visible} onClose={isCloseOnClickBackdrop ? closeModal : undefined}>
        <Column alignCenter noWrap width="100%">
            {(isBackButtonVisible || isCloseButtonVisible) && (
                <ButtonsWrapper>
                    <IconWrapper type="button" visible={isBackButtonVisible} onClick={onBackClick}>
                        <BackArrowSmIcon />
                    </IconWrapper>
                    <IconWrapper
                        type="button"
                        visible={!isBackButtonVisible && isCloseButtonVisible}
                        onClick={closeModal}
                    >
                        <CloseSmIcon />
                    </IconWrapper>
                </ButtonsWrapper>
            )}

            <StyledTitle size="4">{title}</StyledTitle>
            <StyledSubtitle size="-2">{subtitle}</StyledSubtitle>
            {children}
            {bottomExtra && <Section marginTop="32px">{bottomExtra}</Section>}
        </Column>
    </ModalWrapper>
);
