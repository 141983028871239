import { baseColors, disableDefaultButtonStyleMixin, flexCenter, grey, transitionTime } from 'constants/styles';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
    &.guide-swiper {
        .swiper-pagination {
            position: static;
            margin-top: 24px;
        }

        .swiper-pagination-bullet {
            width: 9px;
            height: 9px;
            background-color: ${grey[700]};
            opacity: 1;

            &-active {
                background-color: ${baseColors.white};
            }
        }
    }
`;

export const IconWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    ${flexCenter};
    color: ${grey[500]};
    transition: color ${transitionTime} linear;

    &:hover {
        color: ${baseColors.white};
    }
`;
