import { BackArrowSmIcon, DeleteSmIcon, SettingsRightSmIcon, UserSmIcon } from 'assets/icons';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { EmptyBlock, SettingsTitle, Wrapper } from 'components/pages/settings/components/CommonPart/styles';
import { IconWrapper, TabsButton } from 'components/pages/settings/SettingsTabs/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { baseColors } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { settingsEffects, settingsStores } from 'stores/settings';
import { NoopType } from 'types';
import { ChangeProfile } from './ChangeProfile';
import { DeleteProfile } from './DeleteProfile';

enum AccountSettingStep {
    None,
    Change,
    Delete
}

interface Props {
    onBack: NoopType;
}

export const AccountSettings: FC<Props> = ({ onBack }) => {
    const [step, setStep] = useState(AccountSettingStep.None);
    const closureAvailability = useStore(settingsStores.$closureAvailability);

    const handleBackClick = () => {
        if (step === AccountSettingStep.None) {
            onBack();
        } else {
            if (step === AccountSettingStep.Change && !closureAvailability) {
                settingsEffects.closeSettingsFx(() => setStep(AccountSettingStep.None));
                return;
            }

            setStep(AccountSettingStep.None);
        }
    };

    const handleStepChange = (step: AccountSettingStep) => () => {
        setStep(step);
    };

    const getTitle = () => {
        switch (step) {
            case AccountSettingStep.Change:
                return 'Change Profile';
            case AccountSettingStep.Delete:
                return 'Delete Account';
            default:
                return 'Account';
        }
    };

    return (
        <Wrapper>
            <Section alignCenter noWrap justifyBetween={isMobile} marginBottom={isMobile ? '20px' : '24px'}>
                {isMobile && (
                    <IconButton onClick={handleBackClick}>
                        <BackArrowSmIcon />
                    </IconButton>
                )}

                {!isMobile && step !== AccountSettingStep.None && (
                    <IconButton onClick={handleBackClick}>
                        <BackArrowSmIcon />
                    </IconButton>
                )}

                <SettingsTitle isBold size={isMobile ? '1' : '-1'}>
                    {getTitle()}
                </SettingsTitle>

                {isMobile && <EmptyBlock />}
            </Section>

            {step === AccountSettingStep.None && (
                <Column noWrap width="100%">
                    <TabsButton type="button" onClick={handleStepChange(AccountSettingStep.Change)}>
                        <Row alignCenter noWrap>
                            <IconWrapper isActive>
                                <UserSmIcon />
                            </IconWrapper>
                            <Text size="-1">Change Profile</Text>
                        </Row>
                        <SettingsRightSmIcon color={baseColors.white} />
                    </TabsButton>

                    <Section marginTop="8px">
                        <TabsButton type="button" onClick={handleStepChange(AccountSettingStep.Delete)}>
                            <Row alignCenter noWrap>
                                <IconWrapper isActive>
                                    <DeleteSmIcon />
                                </IconWrapper>
                                <Text size="-1">Delete Account</Text>
                            </Row>
                            <SettingsRightSmIcon color={baseColors.white} />
                        </TabsButton>
                    </Section>
                </Column>
            )}

            {step === AccountSettingStep.Change && <ChangeProfile onCancel={handleBackClick} />}
            {step === AccountSettingStep.Delete && <DeleteProfile onCancel={handleBackClick} />}
        </Wrapper>
    );
};
