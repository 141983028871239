import { Section } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const ParentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;

    ${media.laptop(`
        padding-left: 0;
        padding-right: 0;
    `)};
`;

export const Wrapper = styled(Section)`
    background-color: ${grey[900]};
    border-radius: 8px;
    height: 100%;
`;
