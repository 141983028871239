import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { Text } from 'components/ui/typography/Text';
import { ellipsisMixin } from 'constants/styles';
import { baseColors } from 'constants/styles/colors';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const Empty = styled.div`
    width: 100%;
    background-color: ${baseColors.darkGrey};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 2 * ${headerHeight} - 110px);

    ${media.laptop(`
        height: 100%;
    `)};
`;

export const UserName = styled(Text)`
    ${ellipsisMixin};
    max-width: 100px;

    ${media.phone(`
         max-width: 100%;
    `)};
`;
