import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { MobileModal } from 'components/modals/WalletGuideModal/MobileModal';
import { Button } from 'components/ui/buttons/Button';
import { useStore } from 'effector-react';
import React, { ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { walletGuideModal } from 'stores/initialize.modal';
import { walletEffects, walletStores } from 'stores/wallet';
import Swiper, { Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { Slide } from './constants';
import { SlideOne, SlideThree, SlideTwo } from './Slides';
import { IconWrapper, StyledSwiper } from './styles';

const { modal, closeModal } = walletGuideModal;
const { createWomWalletFx } = walletEffects;
const { $hasWallet, $womUserIsLoaded } = walletStores;

const DesktopModal = () => {
    const [visible] = useStore(modal);
    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const hasWallet = useStore($hasWallet);
    const womUserIsLoaded = useStore($womUserIsLoaded);

    const modalIsVisible = visible && womUserIsLoaded && !hasWallet;

    const slides: Record<Slide, ReactNode> = {
        [Slide.First]: <SlideOne />,
        [Slide.Second]: <SlideTwo />,
        [Slide.Third]: <SlideThree />
    };

    const handleBackClick = () => {
        if (activeIndex === Slide.First) {
            closeModal();
        } else {
            swiper && swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (activeIndex === Slide.Third) {
            createWomWalletFx();
        } else {
            swiper && swiper.slideNext();
        }
    };

    return (
        <ModalWrapper visible={modalIsVisible} onClose={() => closeModal()}>
            <Section justifyBetween marginBottom="24px">
                <IconWrapper onClick={handleBackClick}>
                    <BackArrowSmIcon />
                </IconWrapper>
                <IconWrapper onClick={() => closeModal()}>
                    <CloseSmIcon />
                </IconWrapper>
            </Section>
            <Section marginBottom="24px">
                <StyledSwiper
                    autoHeight
                    className="guide-swiper"
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                    slidesPerView={1}
                    onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
                    onSwiper={swiper => setSwiper(swiper)}
                >
                    {Object.entries(slides).map(([key, slide]) => (
                        <SwiperSlide key={key}>{slide}</SwiperSlide>
                    ))}
                </StyledSwiper>
            </Section>
            <Button wide onClick={handleNextClick}>
                {activeIndex === Slide.Third ? 'Create wallet' : 'Next'}
            </Button>
        </ModalWrapper>
    );
};

export const WalletGuideModal = () => (isMobile ? <MobileModal /> : <DesktopModal />);
