import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { baseColors, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles';
import styled from 'styled-components';

const opacityBlack = 'rgba(0, 0, 0, 0.4)';

export const SlideWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const PreviewImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
`;

export const TopBarWrapper = styled(Section)`
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
`;

export const ButtonsWrapper = styled(Column)`
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;
    z-index: 3;
`;

export const LinksWrapper = styled(Column)`
    flex-wrap: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: calc(100% - 40px);
    padding: 16px;
    z-index: 2;
`;

export const WebsiteButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 18px;
    background-color: ${opacityBlack};
`;

export const GoWrapper = styled.div`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    ${flexCenter};
    border-radius: 50px;
    background-color: ${baseColors.blue};
    text-align: center;
`;

export const TagsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 8px;
`;
