import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { baseColors, grey, transitionTime } from 'constants/styles';
import { media } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

export const TabsWrapper = styled(Column)`
    width: 100%;
    height: fit-content;
    max-height: calc(100vh - ${headerHeight});
    padding-right: 16px;
    padding-left: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;

    ${media.laptop(`
        border-right: 1px solid ${grey[600]};
        width: 308px;
        padding-left: 0;
        padding-right: 8px;
        max-height: 70vh;
    `)};
`;

export const TabsPanelWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - ${headerHeight});
    overflow-y: auto;

    ${media.laptop(`
        max-height: 70vh;
    `)};
`;

interface TabsButtonProps {
    isActive?: boolean;
}

export const TabsButton = styled.button<TabsButtonProps>`
    ${disableDefaultButtonStyleMixin};
    white-space: nowrap;
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 12px 8px;
    color: ${grey[500]};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${baseColors.white};
        `};
`;

export const IconWrapper = styled.div<TabsButtonProps>`
    ${flexCenter};
    width: 24px;
    height: 24px;
    margin-right: 8px;

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${baseColors.white};
        `};
`;

export const EmptyBlock = styled.div`
    width: 24px;
    height: 24px;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;
`;

export const TabsGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100% auto;
    gap: 8px;

    ${media.laptop(`
        grid-template-columns: 300px auto;
    `)};
`;
