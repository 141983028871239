import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Input } from 'components/ui/inputs/Input';
import { Text } from 'components/ui/typography/Text';
import {
    atLeastOneNumberRequiredRegExp,
    oneCapitalCharRequiredRegExp,
    oneSpecialCharacterRequiredRegExp
} from 'constants/regExp';
import React, { ChangeEvent, FC, useState } from 'react';
import { NoopType } from 'types';

interface Props {
    onSubmit: (password: string) => void;
    onCancel: NoopType;
}

export const NewPasswordStep: FC<Props> = ({ onSubmit, onCancel }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const passwordChecklist: RulesChecklistItem[] = [
        {
            title: '6 or more characters',
            isSuccess: password.length >= 6
        },
        {
            title: 'Include a capital letter',
            isSuccess: oneCapitalCharRequiredRegExp.test(password)
        },
        {
            title: 'Include a number',
            isSuccess: atLeastOneNumberRequiredRegExp.test(password)
        },
        {
            title: 'Include a special character - _& . $',
            isSuccess: oneSpecialCharacterRequiredRegExp.test(password)
        }
    ];

    const confirmPasswordChecklist: RulesChecklistItem[] = [
        {
            title: 'Passwords match',
            isSuccess: !!password && !!confirmPassword && password === confirmPassword
        }
    ];

    const passwordIsCorrect = passwordChecklist.filter(item => !item.isSuccess).length === 0;
    const confirmIsCorrect = confirmPasswordChecklist.filter(item => !item.isSuccess).length === 0;

    const submitIsActive = !!password && !!confirmPassword && passwordIsCorrect && confirmIsCorrect;

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = target;
        name === 'password' ? setPassword(value) : setConfirmPassword(value);
    };

    const handleSubmit = () => {
        if (!submitIsActive) return;
        onSubmit(password);
    };

    return (
        <Column noWrap width="100%">
            <Section marginBottom="8px">
                <Text isBold size="1">
                    Create New Password
                </Text>
            </Section>

            <Section marginBottom="16px">
                <Text size="-2">Please create a new password to secure your account</Text>
            </Section>

            <Section marginBottom="16px">
                <Input
                    error={!!password && !passwordIsCorrect ? 'Password is incorrect' : undefined}
                    label="Password"
                    name="password"
                    placeholder="Add password"
                    type="password"
                    value={password}
                    onChange={handleChange}
                />
            </Section>
            <Section marginBottom="16px">
                <RulesChecklist items={passwordChecklist} />
            </Section>

            <Section marginBottom="16px">
                <Input
                    error={!!confirmPassword && !confirmIsCorrect ? 'Please match passwords' : undefined}
                    name="confirm"
                    placeholder="Confirm password"
                    type="password"
                    value={confirmPassword}
                    onChange={handleChange}
                />
            </Section>
            <Section marginBottom="16px">
                <RulesChecklist items={confirmPasswordChecklist} />
            </Section>

            <Section alignCenter justifyBetween>
                <TextButton onClick={onCancel}>Cancel</TextButton>

                <Button disabled={!submitIsActive} onClick={handleSubmit}>
                    Next
                </Button>
            </Section>
        </Column>
    );
};
