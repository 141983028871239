import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import { BackArrowSmIcon } from 'assets/icons';
import mobileSrc from 'assets/img/quick-signals/mobile.png';
import { QsAvatar } from 'components/common/imageComponents/QsAvatar';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { NewMainLayout } from 'components/layouts/NewMainLayout';
import { Spinner } from 'components/loaders/Spinner';
import { ImageWrapper, MobileImage } from 'components/modals/QSModal/components/JoinCard/styles';
import { QSCreateForm } from 'components/pages/quickSignals/create/QSCreateForm';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { quickSignalsEffects, quickSignalsEvents, quickSignalsStores } from 'stores/quick-signals';

const { $user } = authStores;
const { $quickSignalsInfo, $quickSignalsInfoIsLoaded } = quickSignalsStores;
const { getQuickSignalsInfoFx } = quickSignalsEffects;
const { resetStores } = quickSignalsEvents;

export const QuickSignalsCreateOrEdit = () => {
    const history = useHistory();
    const isEdit = !!useRouteMatch(AppRoute.QuickSignalsEdit);

    const user = useStore($user);
    const { data: qsData } = useStore($quickSignalsInfo);
    const qsDataIsLoading = useStore(getQuickSignalsInfoFx.pending);
    const qsDataIsLoaded = useStore($quickSignalsInfoIsLoaded);

    const [myQSInfo] = (qsData || []) as [BULLZ.GetChannelOwnerInfoResponse | undefined];
    const hasMyChannel = !!myQSInfo;

    const handleBack = () => {
        history.goBack();
    };

    useEffect(() => {
        getQuickSignalsInfoFx();

        return () => resetStores();
    }, []);

    if (!isEdit && hasMyChannel) {
        return <Redirect to={AppRoute.QuickSignals} />;
    }

    if (isEdit && qsDataIsLoaded && !hasMyChannel) {
        return <Redirect to={user ? `${AppRoute.Profile}/${user.userId}` : AppRoute.Main} />;
    }

    return (
        <NewMainLayout hasMobilePaddingTop>
            <Section alignCenter>
                <IconButton onClick={handleBack}>
                    <BackArrowSmIcon />
                </IconButton>

                <Column marginLeft="8px">
                    <Text isBold size="1">
                        {isEdit ? 'Edit' : 'Create a Quick Signal Channel'}
                    </Text>
                </Column>
            </Section>

            <Section alignCenter justifyCenter={isMobile} marginLeft={!isMobile ? '16px' : '-24px'} marginTop="24px">
                <ImageWrapper>
                    <QsAvatar large url={user?.profile?.profileImageUrl || defaultAvatar} />
                    <MobileImage src={mobileSrc} />
                </ImageWrapper>
            </Section>

            <Section justifyCenter={qsDataIsLoading} marginTop="24px">
                {qsDataIsLoading ? <Spinner /> : <QSCreateForm data={myQSInfo} isEdit={isEdit} />}
            </Section>
        </NewMainLayout>
    );
};
