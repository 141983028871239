import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { Button } from 'components/ui/buttons/Button';
import { TextButton } from 'components/ui/buttons/TextButton';
import { Text } from 'components/ui/typography/Text';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React from 'react';
import { submitAlertModal, uploadCollaborationModal } from 'stores/initialize.modal';

const { modal, closeModal } = submitAlertModal;

export const SubmitAlertModal = () => {
    const [visible, { collaboration }] = useStore(modal);

    const bullzNetworkInfo =
        collaboration?.networks?.find(item => item.networkTitle?.toLowerCase() === ExternalNetworkKey.Bullz) || {};
    const wom = bullzNetworkInfo?.rewardOnSubmitted || 0;
    const usd = bullzNetworkInfo?.rewardOnSubmittedUsd || 0;
    const isNeedApproveForVideo = !collaboration?.autoApproveMedia && collaboration?.autoApproveParticipants;

    const handleSubmit = () => {
        closeModal();
        uploadCollaborationModal.openModal({ collaborationId: collaboration.id || '' });
    };

    const handleCancel = () => {
        closeModal();
    };

    return (
        <ModalWrapper visible={visible} onClose={closeModal}>
            <Column alignCenter>
                <Section justifyCenter marginBottom="12px">
                    <Text alignTextCenter isBold>
                        Hey, you are about to submit a video
                    </Text>
                </Section>
                <Section marginBottom="16px">
                    <Text alignTextCenter size="-2">
                        By submitting your video, you agree that you have read, understand and followed the
                        requirements. If your video is flagged for not fulfilling the requirements, your account will be
                        frozen until you fulfill the requirements.
                    </Text>
                </Section>
                {isNeedApproveForVideo && (
                    <MarginWrapper marginBottom="28px">
                        <Text alignTextCenter size="-2">
                            To receive the reward, the brand must approve your video.
                        </Text>
                    </MarginWrapper>
                )}
                <MarginWrapper marginBottom="8px">
                    <Text size="-1">
                        {isNeedApproveForVideo ? 'Submit to potentially receive:' : 'Submit to receive:'}
                    </Text>
                </MarginWrapper>
                <MarginWrapper marginBottom="28px">
                    <Text color={grey[500]} size="-1">
                        {`${wom} WOM (approx. $${usd.toFixed(2)})`}
                    </Text>
                </MarginWrapper>
                <Section marginBottom="24px">
                    <Button wide onClick={handleSubmit}>
                        Submit
                    </Button>
                </Section>
                <TextButton isBold onClick={handleCancel}>
                    CANCEL
                </TextButton>
            </Column>
        </ModalWrapper>
    );
};
