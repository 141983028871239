import { CountryCodeOption } from 'components/form/options/CountryOption';
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js/min';

export const prepareCountryOptions = (): CountryCodeOption[] => {
    const getCountryNames = new Intl.DisplayNames(['en'], { type: 'region' });
    const countries = getCountries()
        .map((countryCode: CountryCode, index: number) => {
            const code = getCountryCallingCode(countryCode);
            return {
                countryCode: countryCode,
                label: `${countryCode} +${code}`,
                name: getCountryNames.of(countryCode) || '',
                value: index,
                code
            };
        })
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    return countries;
};
