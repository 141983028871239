import NotFound from 'assets/img/not-found.png';
import { PreviewVideoCard } from 'components/cards/PreviewVideoCard';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { Text } from 'components/ui/typography/Text';
import { defaultUserVideosQueryParams } from 'constants/services/user-videos';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { fullscreenVideoEvents } from 'stores/fullscreen-video';
import { userVideosEffects, userVideosEvents, userVideosStores } from 'stores/user-videos';
import { Id } from 'types/interfaces/data';
import { getFullscreenVideoUrl } from 'utils/common';
import { getPlaylistItems } from 'utils/video';
import { Empty, Image, VideoCardsWrapper } from './styles';

const { getUserVideosFx, loadMoreUserVideosFx } = userVideosEffects;
const { $userVideos, $hasError } = userVideosStores;
const { resetStores } = userVideosEvents;

export const ProfileVideoCards: FC<Id> = ({ id }) => {
    const history = useHistory();
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [isHoveredId, setIsHoveredId] = useState('');

    const { items, currentPageIndex, totalPages } = useStore($userVideos);
    const isMoreLoading = useStore(loadMoreUserVideosFx.pending);
    const isLoading = useStore(getUserVideosFx.pending);
    const hasError = useStore($hasError);

    const handleCardClick = useCallback(
        (id?: string | null) => {
            fullscreenVideoEvents.setPlaylist(getPlaylistItems(items));
            id && history.replace(getFullscreenVideoUrl(id));
        },
        [history, items]
    );

    const handleCardMouseEnter = useCallback((id?: string | null) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (id) {
            timer.current = setTimeout(() => {
                setIsHoveredId(id);
            }, 300);
        }
    }, []);

    const handleCardMouseLeave = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        setIsHoveredId('');
    }, []);

    useEffect(() => {
        getUserVideosFx(id);

        return () => resetStores();
    }, [id]);

    const [loadRef] = useInfiniteScroll({
        loading: isMoreLoading,
        hasNextPage: currentPageIndex !== undefined && totalPages !== undefined && currentPageIndex + 1 < totalPages,
        onLoadMore: () => {
            currentPageIndex !== undefined &&
                loadMoreUserVideosFx({
                    ...defaultUserVideosQueryParams,
                    byCreatorId: id,
                    pageIndex: currentPageIndex + 1
                });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    return (
        <Column height="100%" width="100%">
            {items && items.length > 0 && (
                <>
                    <VideoCardsWrapper>
                        {items.map(item => (
                            <PreviewVideoCard
                                key={item.id}
                                id={item.id}
                                isActive={item.id === isHoveredId}
                                thumbnailUrl={item?.streaming?.screenGrabUrl}
                                videoSrc={item?.streaming?.hlsUrl}
                                viewCount={item?.engagement?.views}
                                onClick={handleCardClick}
                                onMouseEnter={handleCardMouseEnter}
                                onMouseLeave={handleCardMouseLeave}
                            />
                        ))}
                    </VideoCardsWrapper>
                    <HiddenScrollBlock ref={loadRef} />
                </>
            )}
            {(!items || items.length === 0) && !isLoading && (
                <Empty>
                    <Image src={NotFound} />
                    <Text color={grey[500]}>No Videos Found</Text>
                </Empty>
            )}
            {(isLoading || isMoreLoading) && (
                <Section justifyCenter marginTop="24px">
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
