import { defaultImgDiameter } from 'components/common/imageComponents/AvatarImg/constants';
import { AvatarImgStyledProps } from 'components/common/imageComponents/AvatarImg/types';
import { baseColors } from 'constants/styles';
import styled from 'styled-components';

export const StyledImg = styled.img<AvatarImgStyledProps>`
    width: ${({ diameter }) => diameter || defaultImgDiameter};
    min-width: ${({ diameter }) => diameter || defaultImgDiameter};
    height: ${({ diameter }) => diameter || defaultImgDiameter};
    min-height: ${({ diameter }) => diameter || defaultImgDiameter};
    border-radius: 50%;
    ${({ hasBorder }) => hasBorder && `border: 2px solid ${baseColors.white}`};
    object-fit: cover;
    ${({ pointer }) => pointer && 'cursor: pointer;'};
`;
