import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { CardsGrid } from 'components/pages/gifts/CardsGrid';
import { GiftCard } from 'components/ui/GiftCard';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { defaultGiftsParams } from 'constants/services/gifts';
import { useStore } from 'effector-react';
import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { giftsEffects, giftsEvents, giftsStores } from 'stores/gifts';

const { getBrandsFx } = giftsEffects;
const { $brands, $hasBrandsError } = giftsStores;
const { openPurchaseModal } = giftsEvents;

export const MoreCards = () => {
    const { currentPageIndex, totalPages, items } = useStore($brands);
    const isLoading = useStore(getBrandsFx.pending);
    const hasError = useStore($hasBrandsError);

    const handleCardClick = (item: BULLZ.BrandVoucherResponse) => () => {
        openPurchaseModal(item);
    };

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage:
            currentPageIndex !== undefined && totalPages !== undefined ? currentPageIndex + 1 < totalPages : true,
        onLoadMore: () => {
            getBrandsFx({
                ...defaultGiftsParams,
                pageIndex: currentPageIndex !== undefined ? currentPageIndex + 1 : defaultGiftsParams.pageIndex
            });
        },
        disabled: hasError,
        rootMargin: '0px'
    });

    return (
        <Column marginTop="24px" width="100%">
            {items && items.length > 0 && (
                <CardsGrid>
                    {items.map(item => (
                        <GiftCard
                            key={item.productId}
                            src={item.productImage || ''}
                            title={item.brandName || ''}
                            onClick={handleCardClick(item)}
                        />
                    ))}
                </CardsGrid>
            )}

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}

            <HiddenScrollBlock ref={loadRef} />
        </Column>
    );
};
