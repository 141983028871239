import defaultAvatar from 'assets/defaults/default-profile-avatar.svg';
import {
    BackArrowSmIcon,
    DateSignalsSmIcon,
    DescriptionSignalsSmIcon,
    PriceWomSmIcon,
    QuickSignalSmIcon,
    TotalSignalsSmIcon
} from 'assets/icons';
import Phone from 'assets/img/quick-signals-phone.png';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Spinner } from 'components/loaders/Spinner';
import { InfoBlock } from 'components/pages/quickSignals/QuickSignalsInfo/InfoBlock';
import { AvatarBorder } from 'components/ui/AvatarBorder';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { AppRoute } from 'constants/routes';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { quickSignalsEffects, quickSignalsEvents, quickSignalsStores } from 'stores/quick-signals';
import { getFormattedDate } from 'utils/quick-signals';
import { AvatarWrapper, Image, ImageWrapper, InfoBlocksWrapper } from './styles';

const { $quickSignalsInfo } = quickSignalsStores;
const { getQuickSignalsInfoFx } = quickSignalsEffects;
const { resetStores } = quickSignalsEvents;

const { $user } = authStores;

export const QuickSignalsInfo = () => {
    const history = useHistory();

    const { data } = useStore($quickSignalsInfo);
    const isLoading = useStore(getQuickSignalsInfoFx.pending);
    const user = useStore($user);

    const [info] = data ? data : [];

    const goToBack = () => {
        if (user && user.userId) {
            history.push(`${AppRoute.Profile}/${user.userId}`);
        } else {
            history.goBack();
        }
    };

    const goToEdit = () => {
        history.push(AppRoute.QuickSignalsEdit);
    };

    useEffect(() => {
        getQuickSignalsInfoFx();

        return () => resetStores();
    }, []);

    return (
        <Column noWrap width="100%">
            <Section alignCenter justifyBetween noWrap marginBottom="30px">
                <Row alignCenter noWrap>
                    <IconButton color={grey[500]} onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                    <Column marginLeft="12px">
                        <Text isBold size="1">
                            My Quick Signals
                        </Text>
                    </Column>
                </Row>

                {info && (
                    <Button isBold={false} size="small" textSize="small" onClick={goToEdit}>
                        Edit
                    </Button>
                )}
            </Section>

            {info && (
                <Column width="100%">
                    <Section justifyCenter={isMobile}>
                        <ImageWrapper noWrap>
                            <AvatarWrapper>
                                <AvatarBorder src={user?.profile?.profileImageUrl || defaultAvatar} />
                            </AvatarWrapper>
                            <Image src={Phone} />
                        </ImageWrapper>
                    </Section>

                    <InfoBlocksWrapper>
                        <InfoBlock icon={QuickSignalSmIcon} text={info.name || ''} title="Title" />
                        <InfoBlock icon={PriceWomSmIcon} text={info.womValue || 0} title="PRICE IN WOM PER MONTH" />

                        <InfoBlock
                            icon={TotalSignalsSmIcon}
                            text={`${info.totalSignalsCount || 0} ${'Signals'}`}
                            title="VIDEOS POSTED"
                        />
                        <InfoBlock
                            icon={DateSignalsSmIcon}
                            text={getFormattedDate(info.utcStart) || ''}
                            title="CHANNEL Created"
                        />
                    </InfoBlocksWrapper>

                    <InfoBlock icon={DescriptionSignalsSmIcon} text={info.description || ''} title="DESCRIPTION" />
                </Column>
            )}

            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}
        </Column>
    );
};
