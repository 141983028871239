import { BackArrowSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import {
    EmptyBlock,
    InnerWrapper,
    SettingsTitle,
    Wrapper
} from 'components/pages/settings/components/CommonPart/styles';
import { SettingsCategory } from 'components/pages/settings/SettingsContent/constants';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import { useStore } from 'effector-react';
import { Formik } from 'formik';
import React, { FC, ReactNode, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { authStores } from 'stores/auth';
import { noop } from 'utils/common';
import { Step, subtitles, titles } from './constants';
import { PasswordStep } from './PasswordStep';
import { PhoneStep } from './PhoneStep';
import { SmsCodeStep } from './SmsCodeStep';
import { initialValues, validateForm } from './validation';

interface Props {
    onTabChange: (value: SettingsCategory) => void;
}

export const ForgotPassword: FC<Props> = ({ onTabChange }) => {
    const [step, setStep] = useState(Step.Phone);

    const user = useStore(authStores.$user);
    const numberFragment = user?.profile?.mobileNumber ? user.profile.mobileNumber.slice(-2) : 'xx';

    const getDescription = () => {
        if (step === Step.Phone) {
            return `${subtitles[step]}${numberFragment}`;
        }

        return subtitles[step];
    };

    const handleCancel = () => {
        onTabChange(SettingsCategory.ChangePassword);
    };

    const steps: Record<Step, ReactNode> = {
        phone: <PhoneStep setCurrentStep={setStep} />,
        smsCode: <SmsCodeStep setCurrentStep={setStep} />,
        password: <PasswordStep setCurrentStep={setStep} onCancel={handleCancel} />
    };

    if (!user || !user?.profile?.mobileNumber) {
        return null;
    }

    return (
        <Wrapper>
            <Section alignCenter noWrap justifyBetween={isMobile} marginBottom={isMobile ? '20px' : '24px'}>
                <IconButton onClick={handleCancel}>
                    <BackArrowSmIcon />
                </IconButton>

                <SettingsTitle isBold size={isMobile ? '1' : '-1'}>
                    Forgot Password?
                </SettingsTitle>

                {isMobile && <EmptyBlock />}
            </Section>

            <InnerWrapper>
                <Section marginBottom="8px">
                    <Text isBold size="1">
                        {titles[step]}
                    </Text>
                </Section>

                <Section marginBottom="16px">
                    <Text size="-2">{getDescription()}</Text>
                </Section>

                <Formik initialValues={initialValues} validate={validateForm} onSubmit={noop}>
                    {steps[step]}
                </Formik>
            </InnerWrapper>
        </Wrapper>
    );
};
