import { baseColors, grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

interface OptionButtonProps {
    isSelected?: boolean;
}

export const OptionButton = styled.button<OptionButtonProps>`
    ${disableDefaultButtonStyleMixin};
    text-align: left;
    width: 100%;
    padding: 4px 10px;
    ${({ isSelected }) => isSelected && `background-color: ${baseColors.black}`};
    transition: background-color ${transitionTime} linear;

    &:hover:not(:disabled) {
        background-color: ${grey['800']};
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${baseColors.black};
    }
`;
