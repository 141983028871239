import React, { FC, MouseEvent } from 'react';
import { Disabled } from 'types';
import { SelectOnChange, SelectOption } from '../types';
import { OptionButton } from './styles';

interface Props extends Pick<SelectOption, 'value'>, SelectOnChange, Disabled {
    isSelected?: boolean;
}

export const Option: FC<Props> = ({ value, onChange, isSelected, disabled, children }) => {
    const handleChange = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onChange && onChange(value);
    };

    return (
        <OptionButton disabled={isSelected || disabled} isSelected={isSelected} type="button" onClick={handleChange}>
            {children}
        </OptionButton>
    );
};
