import { BackArrowSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { NotificationsActivity } from 'components/pages/notifications/NotificationsActivity';
import { NotificationsContent } from 'components/pages/notifications/NotificationsContent';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Tabs } from 'components/ui/Tabs';
import { Text } from 'components/ui/typography/Text';
import { NotificationsPageType } from 'constants/enum';
import React, { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

interface Props {
    isDropdown?: boolean;
}

export const NotificationsTabs: FC<Props> = ({ isDropdown }) => {
    const [activeTab, setActiveTab] = useState(NotificationsPageType.Notifications);

    const handleTabsChange = (value: NotificationsPageType) => {
        setActiveTab(value);
    };

    const history = useHistory();

    const goToBack = () => {
        history.goBack();
    };

    return (
        <Column noWrap height="100%" width="100%">
            {isMobile && (
                <Section noWrap marginBottom="20px">
                    <IconButton onClick={goToBack}>
                        <BackArrowSmIcon />
                    </IconButton>
                    <Section marginLeft="80px">
                        <Text isBold size="1">
                            Notifications
                        </Text>
                    </Section>
                </Section>
            )}

            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: NotificationsPageType.Notifications,
                        title: 'Notifications',
                        content: <NotificationsContent isDropdown={isDropdown} />,
                        width: isMobile ? '50%' : '175px'
                    },
                    {
                        id: NotificationsPageType.Activity,
                        title: 'Activity',
                        content: <NotificationsActivity isDropdown={isDropdown} />,
                        width: isMobile ? '50%' : '175px'
                    }
                ]}
                onChange={handleTabsChange}
            />
        </Column>
    );
};
