import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

// Events
const updateVideo = createEvent<BULLZ.PlaylistSingleVideoResponse>();
const setPlaylist = createEvent<string[]>();
const closeFullscreenVideo = createEvent();
const resetStores = createEvent();

// Effects

const getVideoByIdFx = createEffect({
    handler: async (videoId: string) => await API.playlist.get({ playlistVideoId: videoId })
});

const getVideoValidationFx = createEffect({
    handler: async (videoId: string) => {
        if (!!videoId) {
            const { validation } = await API.video.getValidationVideo({ videoId });
            return validation || null;
        }

        return null;
    }
});

// Stores

const $video = createStore<BULLZ.PlaylistSingleVideoResponse>({})
    .on(getVideoByIdFx.doneData, (_, data) => data)
    .on(getVideoByIdFx.fail, () => ({}))
    .on(updateVideo, (_, newState) => newState)
    .reset([resetStores, closeFullscreenVideo]);

const $currentPlaylistVideos = createStore<string[]>([])
    .on(setPlaylist, (_, payload) => payload)
    .reset(closeFullscreenVideo);

const $currentVideoValidation = createStore<BULLZ.VideoDetailsValidation | null>(null)
    .on(getVideoValidationFx.doneData, (_, payload) => payload)
    .reset([resetStores, closeFullscreenVideo, getVideoValidationFx.fail]);

// Exports

export const fullscreenVideoEvents = {
    updateVideo,
    setPlaylist,
    resetStores,
    closeFullscreenVideo
};

export const fullscreenVideoEffects = {
    getVideoByIdFx,
    getVideoValidationFx
};

export const fullscreenVideoStores = {
    $video,
    $currentPlaylistVideos,
    $currentVideoValidation
};
