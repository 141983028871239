import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Spinner } from 'components/loaders/Spinner';
import { HiddenScrollBlock } from 'components/ui/HiddenScrollBlock';
import { CommentInput } from 'components/video/FullscreenVideo/components/Comments/CommentInput';
import { NoComments } from 'components/video/FullscreenVideo/components/Comments/NoComments';
import { SingleComment } from 'components/video/FullscreenVideo/components/Comments/SingleComment';
import { defaultCommentsQueryParams } from 'constants/services/comments';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { authStores } from 'stores/auth';
import { commentsEffects, commentsEvents, commentsStores } from 'stores/comments';
import { CommentsItems, CommentsWrapper } from './styles';

const { getCommentsByVideoIdFx, loadMoreCommentsByVideoIdFx } = commentsEffects;
const { $currentVideoComments, $fetchingCommentsError } = commentsStores;
const { clearStores, resetReplies } = commentsEvents;

interface Props {
    videoId?: string;
}

export const Comments: FC<Props> = ({ videoId }) => {
    const comments = useStore($currentVideoComments);
    const { items, totalRecords } = comments;
    const commentsIsLoading = useStore(getCommentsByVideoIdFx.pending);
    const moreCommentsIsLoading = useStore(loadMoreCommentsByVideoIdFx.pending);
    const hasError = useStore($fetchingCommentsError);
    const isAuth = useStore(authStores.$isAuth);
    const [newComment, setNewComment] = useState('');
    const [replyingComment, setReplyingComment] = useState<BULLZ.GetRootPostResponse | undefined>(undefined);

    useEffect(() => {
        if (videoId) {
            getCommentsByVideoIdFx(videoId);
        }

        return () => {
            resetReplies();
            clearStores();
        };
    }, [videoId]);

    const [loadRef] = useInfiniteScroll({
        loading: moreCommentsIsLoading,
        hasNextPage: !!items && !!totalRecords && items.length < totalRecords,
        onLoadMore: () => {
            if (videoId && items && totalRecords) {
                const totalPages = Math.ceil(totalRecords / defaultCommentsQueryParams.limit) - 1;
                const currentPage = Math.ceil(items.length / defaultCommentsQueryParams.limit) - 1;

                if (currentPage < totalPages) {
                    loadMoreCommentsByVideoIdFx({ videoId, pageIndex: currentPage + 1 });
                }
            }
        },
        disabled: !videoId || hasError,
        rootMargin: '0px'
    });

    if (!videoId) {
        return null;
    }

    return (
        <>
            <CommentsWrapper>
                {items && items.length > 0 && (
                    <CommentsItems>
                        {items.map(comment => (
                            <SingleComment
                                key={comment.id}
                                comment={comment}
                                setNewComment={setNewComment}
                                setReplyingComment={setReplyingComment}
                            />
                        ))}

                        <HiddenScrollBlock ref={loadRef} />
                    </CommentsItems>
                )}

                {(Object.keys(comments).length === 0 ||
                    (items && items.length === 0 && !commentsIsLoading && !moreCommentsIsLoading)) && <NoComments />}

                {(commentsIsLoading || moreCommentsIsLoading) && (
                    <Section justifyCenter>
                        <Spinner />
                    </Section>
                )}
            </CommentsWrapper>
            {((items && items.length > 0) || isAuth) && (
                <CommentInput
                    newComment={newComment}
                    replyingComment={replyingComment}
                    setNewComment={setNewComment}
                    setReplyingComment={setReplyingComment}
                    videoId={videoId}
                />
            )}
        </>
    );
};
