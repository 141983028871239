import { headerHeight } from 'components/layouts/NewMainLayout/constants';
import { flexCenter } from 'constants/styles';
import { baseColors } from 'constants/styles/colors';
import styled from 'styled-components';

export const BottomMenuWrapper = styled.div`
    width: 100%;
    height: ${headerHeight};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    background-color: ${baseColors.black};
    padding: 12px 24px;
    overflow: hidden;
`;

export const MenuGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(5, 1fr);
`;

export const MenuCol = styled.div`
    width: 100%;
    ${flexCenter};
`;
