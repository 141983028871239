import { Text } from 'components/ui/typography/Text';
import { CollaborationStatusesUi } from 'constants/defaults/collaborations';
import { baseColors, orange } from 'constants/styles';
import styled, { css } from 'styled-components';

export const getTagStyle = (status?: number) => {
    switch (status) {
        case CollaborationStatusesUi.Active:
            return css`
                background-color: ${baseColors.green};
                color: ${baseColors.black};
            `;
        case CollaborationStatusesUi.Finalizing:
            return css`
                background-color: ${baseColors.red};
                color: ${baseColors.white};
            `;
        case CollaborationStatusesUi.Ending:
            return css`
                background-color: ${orange[600]};
                color: ${baseColors.white};
            `;
        case CollaborationStatusesUi.StartingSoon:
        case CollaborationStatusesUi.Ended:
            return css`
                background-color: ${baseColors.lightGrey};
                color: ${baseColors.white};
            `;
        default:
            return css`
                background-color: ${baseColors.darkGrey};
                color: ${baseColors.white};
            `;
    }
};

interface Props {
    status?: number;
}

export const BadgeWrapper = styled(Text)<Props>`
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 2px 4px;

    ${({ status }) => getTagStyle(status)};
`;
