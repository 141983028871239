import { Effect, createEffect } from 'effector';
import { primaryPadding } from 'constants/styles';
import { defaultScrollWidth } from 'constants/defaults';
import { calculateHashtagWidth } from './calculators';
import { PlaylistType } from 'components/video/FullscreenVideo/types';
import { AppRoute, helpDeskLink } from 'constants/routes';
import { avatarDiameter, marginRightTrendingCard } from 'constants/trendingCard';

/**
 * Create effect with bad response notifications
 */
interface BadResponseErrors
    extends BULLZ.Error409ConflictResponse,
        BULLZ.Error400BadRequest,
        BULLZ.Error404NotFoundResponse {}

export function createNotifyingEffect<Params, Done>(config: {
    name?: string;
    handler?: (params: Params) => Promise<Done> | Done;
    sid?: string;
}): Effect<Params, Done> {
    const effect = createEffect(config);
    effect.fail.watch(({ error }: { error: BadResponseErrors }) => {
        if (!error.isSuccess) {
            // effects.setNotification({
            //     message: error.message || '',
            //     description: ''
            // });
        }
    });

    return effect;
}

export const noop = () => {};
export const retrieveLocale = () => (navigator?.language?.length === 5 ? navigator.language : 'en-US');

// wait 5 sec.
export const wait = (time = 5000) =>
    new Promise(resolve => {
        setTimeout(resolve, time);
    });

export const getCountTimersForEventVideo = (duration: number) => Math.ceil(duration / 5) - 1;

export function mergeGenericHashtagResponse<T extends { id?: string }>(state: T[], items: T[] | undefined): T[] {
    const merge = [...state, ...(items || [])];
    let mergedMap = new Map<string | undefined, T>();
    merge.forEach(item => mergedMap.set(item.id, item));
    let result: T[] = [];
    // @ts-ignore
    for (let value of mergedMap.values()) result.push(value);
    return result;
}

export const lengthLineTrendingHashtags = (hashtags: BULLZ.GetTrendingTagResponse[]) =>
    hashtags.reduce(
        (sum, { tag }) =>
            sum +
            (calculateHashtagWidth(tag as string, '600') + parseInt(primaryPadding) * 2 + parseInt(primaryPadding)),
        0
    );

export const isAllHashtagsVisible = (hashtags: BULLZ.GetTrendingTagResponse[], width: number) =>
    lengthLineTrendingHashtags(hashtags) > width;

export const calculateTrendingHashtagsScrollWidth = (hashtags: BULLZ.GetTrendingTagResponse[], width: number) =>
    Math.round((width * parseInt(defaultScrollWidth)) / lengthLineTrendingHashtags(hashtags));

export const lengthLineTrendingCreators = (users: BULLZ.GetTrendingUserResponse[]) =>
    users.reduce(sum => sum + parseInt(avatarDiameter) + parseInt(marginRightTrendingCard), 20);

export const isAllCreatorsVisible = (users: BULLZ.GetTrendingUserResponse[], width: number) =>
    lengthLineTrendingCreators(users) > width;

export const calculateTrendingCreatorsScrollWidth = (users: BULLZ.GetTrendingUserResponse[], width: number) =>
    Math.round((width * parseInt(defaultScrollWidth)) / lengthLineTrendingCreators(users));

export const findElementInChildrenList = (targetElement: Element, searchElement: EventTarget | null) => {
    let isInParentBlock = false;
    const checkChildrenRef = (el: Element) => {
        if (el.childElementCount === 0) return;
        else
            el.childNodes.forEach((el: any) => {
                if (searchElement === el) isInParentBlock = true;
                checkChildrenRef(el);
            });
    };

    checkChildrenRef(targetElement);
    return isInParentBlock;
};

export const goToExternalLink = (href?: string | null) => {
    if (!href) return;

    window.open(href, '_blank', 'noopener,noreferrer');
    return;
};

export const getVideoLink = (id?: string) => {
    if (!window || !id) return '';

    return `${window.location.origin}${AppRoute.Video}/${id}`;
};

export const getProfileLink = (id?: string) => {
    if (!window || !id) return '';

    return `${window.location.origin}${AppRoute.Profile}/${id}`;
};

export const getFullscreenVideoUrl = (videoId: string, playlistType: PlaylistType = 'profile') =>
    `${AppRoute.Video}/${videoId}?playlistType=${playlistType}`;

export const getFormattedNumber = (value?: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value || 0);

export const getFormattedDollars = (value?: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    }).format(value || 0);

export const goToHelpDesk = () => goToExternalLink(helpDeskLink);

export const getCommonReward = (
    socialNetworks: BUCM.TiltedPublishingNetworkRequirementWithUsdValue[],
    contentReward?: number
) => {
    const minSocialNetworkReward =
        socialNetworks.length > 0
            ? Math.min.apply(null, socialNetworks?.map(item => item.rewardOnSubmitted) as number[])
            : 0;

    const commonReward = (contentReward || 0) + minSocialNetworkReward;

    return commonReward;
};

export const getCommonRewardUsd = (
    socialNetworks: BUCM.TiltedPublishingNetworkRequirementWithUsdValue[],
    contentRewardUsd?: number
) => {
    const minSocialNetworkRewardUsd =
        socialNetworks.length > 0
            ? Math.min.apply(null, socialNetworks?.map(item => item.rewardOnSubmittedUsd) as number[])
            : 0;

    const commonRewardUsd = (contentRewardUsd || 0) + minSocialNetworkRewardUsd;

    return commonRewardUsd;
};
