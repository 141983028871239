import { WrongLgIcon } from 'assets/icons';
import { FormWrapper } from 'components/form/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Button } from 'components/ui/buttons/Button';
import { asyncErrorMessage } from 'constants/messages';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import { useReCaptcha } from 'hooks/useReCaptcha';
import React, { FC, FormEvent, useEffect } from 'react';
import { authEffects, authStores } from 'stores/auth';
import { infoModal } from 'stores/initialize.modal';
import { goToHelpDesk } from 'utils/common';
import { ForgotPasswordFieldName, Step } from '../constants';
import { ISetCurrentStep } from '../types';

interface Props extends ISetCurrentStep {}

export const PhoneStep: FC<Props> = ({ setCurrentStep }) => {
    const user = useStore(authStores.$user);
    const [, { value, error }, { setValue }] = useField<string>(ForgotPasswordFieldName.MobileNumber);

    const token = useReCaptcha();

    const submitIsActive = !!value && !error && !!token;

    const openErrorCodeModal = (text?: string) => {
        infoModal.openModal({
            icon: WrongLgIcon,
            title: asyncErrorMessage,
            text: text || '',
            buttonText: 'Try Again',
            cancelText: 'CONTACT SUPPORT',
            onCloseClick: goToHelpDesk
        });
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!submitIsActive) return;

        authEffects
            .forgotPasswordSendSmsCodeFx({
                phone: value,
                firebaseToken: token
            })
            .then(data => {
                if (data.isOk) {
                    setCurrentStep(Step.SmsCode);
                } else {
                    openErrorCodeModal();
                }
            })
            .catch(e => {
                openErrorCodeModal((e.message as string) || '');
            });
    };

    useEffect(() => {
        if (user?.profile?.mobileNumber) {
            setValue(user.profile.mobileNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <FormWrapper onSubmit={handleFormSubmit}>
            <Section justifyEnd>
                <Button disabled={!submitIsActive} type="submit">
                    Send Code
                </Button>
            </Section>
        </FormWrapper>
    );
};
