import { useStore } from 'effector-react';
import queryString from 'query-string';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { walletGuideModal } from 'stores/initialize.modal';
import { walletEffects, walletStores } from 'stores/wallet';

export interface QueryParamsHandlerProps {
    modal?: string;
}

export enum ModalEnum {
    Wallet = 'wallet'
}

export const useHandleQueryParams = () => {
    const history = useHistory();

    const isAuth = useStore(authStores.$isAuth);
    const hasWallet = useStore(walletStores.$hasWallet);
    const womUserIsLoaded = useStore(walletStores.$womUserIsLoaded);

    const openWalletModal = useCallback(() => {
        if (!isAuth || !womUserIsLoaded) return;

        if (!hasWallet) {
            walletGuideModal.openModal();
        } else {
            walletEffects.existsWalletPinFx();
        }
    }, [hasWallet, isAuth, womUserIsLoaded]);

    useEffect(() => {
        const { modal } = queryString.parse(history.location.search) as QueryParamsHandlerProps;

        if (modal && modal === ModalEnum.Wallet) {
            openWalletModal();
        }
    }, [history.location.search, openWalletModal]);
};
