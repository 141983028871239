import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { authStores } from 'stores/auth';

export const AuthRoute: FC<RouteProps> = ({ component: Component, path, ...props }) => {
    const isAuth = useStore(authStores.$isAuth);

    if (!isAuth) {
        return <Redirect to={AppRoute.Main} />;
    }

    return <Route component={Component} path={path} {...props} />;
};
