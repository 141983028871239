import { Column } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import { baseColors, grey } from 'constants/styles';
import { useCountdown } from 'hooks/useCountdown';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { Wrapper } from './styles';

interface Props {
    date: string;
    type?: 'primary' | 'secondary';
    color?: string;
}

export const Timer: FC<Props> = ({ date, type = 'primary', color = 'transparent' }) => {
    const futureTime = new Date(date).getTime();
    const nowTime = new Date(Date.now()).getTime();
    const timeEnded = futureTime - nowTime < 0;
    const { days, hours, minutes, seconds } = useCountdown(futureTime);

    const addZero = (value: number): string => {
        if (timeEnded) {
            return '00';
        }
        return value > 9 ? `${value}` : `0${value}`;
    };

    const allHours = days * 24 + hours;
    const formattedHours = addZero(allHours);
    const formattedMinutes = addZero(minutes);
    const formattedSeconds = addZero(seconds);

    const minutesAndSecondsColor = allHours > 0 ? baseColors.white : baseColors.red;
    const hoursColor = timeEnded ? baseColors.red : baseColors.white;

    return (
        <Wrapper justifyBetween color={color} type={type} width="100%">
            <Column alignCenter>
                <Text color={hoursColor} size={type === 'secondary' ? '1' : '4'}>
                    {formattedHours}
                </Text>
                <Text color={grey[500]} size={isMobile ? '-2' : '-1'}>
                    Hours
                </Text>
            </Column>
            <Column alignCenter>
                <Text color={minutesAndSecondsColor} size={type === 'secondary' ? '1' : '4'}>
                    {formattedMinutes}
                </Text>
                <Text color={grey[500]} size={isMobile ? '-2' : '-1'}>
                    Minutes
                </Text>
            </Column>
            <Column alignCenter>
                <Text color={minutesAndSecondsColor} size={type === 'secondary' ? '1' : '4'}>
                    {formattedSeconds}
                </Text>
                <Text color={grey[500]} size={isMobile ? '-2' : '-1'}>
                    Seconds
                </Text>
            </Column>
        </Wrapper>
    );
};
