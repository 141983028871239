import { baseColors } from 'constants/styles/colors';
import { allTransition, disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

interface StyledButtonProps {
    size?: string;
    color?: string;
    hoverColor?: string;
    rotate?: number;
}

export const StyledButton = styled.button<StyledButtonProps>`
    ${disableDefaultButtonStyleMixin};
    width: ${({ size }) => size || '24px'};
    height: ${({ size }) => size || '24px'};
    ${flexCenter};
    flex-shrink: 0;
    color: ${({ color }) => color || baseColors.white};
    ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`};
    ${allTransition};

    &:disabled {
        /* TODO fix to not-allowed */
        cursor: default;
    }

    &:hover:not(:disabled) {
        color: ${({ hoverColor }) => hoverColor || baseColors.white};
    }
`;
