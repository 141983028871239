import { BackArrowSmIcon, CloseSmIcon } from 'assets/icons';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import { PropertyBlock } from 'components/pages/gifts/PropertyBlock';
import { GiftStatus } from 'components/pages/gifts/types';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { GiftCard } from 'components/ui/GiftCard';
import { HtmlText } from 'components/ui/typography/HtmlText';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React from 'react';
import { giftsEffects, giftsEvents, giftsStores } from 'stores/gifts';
import { goToExternalLink } from 'utils/common';
import { getFormattedDate } from 'utils/formateDate';
import { PropertyGrid } from './styles';

const { $giftInfoModal } = giftsStores;
const { closeDetailsModal, openHowToUseModal } = giftsEvents;
const { buyCardFx } = giftsEffects;

const dateFormat = 'dd/MM/yyyy';
const defaultCurrency = 'USD';

export const GiftDetailsModal = () => {
    const data = useStore($giftInfoModal);
    const cardInfo = data?.giftCardData?.[0] || null;

    const close = () => {
        closeDetailsModal();
    };

    const tryAgain = () => {
        if (data && data.productId && data.denomination) {
            closeDetailsModal();
            buyCardFx({
                productId: data.productId,
                denomination: data.denomination
            });
        }
    };

    const openHowToUse = () => {
        if (data && data.productInfo?.howToUse) {
            openHowToUseModal(data.productInfo.howToUse);
        }
    };

    return (
        <ModalWrapper visible={!!data} onClose={close}>
            <Section alignCenter justifyBetween marginBottom="32px">
                <IconButton onClick={close}>
                    <BackArrowSmIcon />
                </IconButton>
                <IconButton onClick={close}>
                    <CloseSmIcon />
                </IconButton>
            </Section>

            <Section justifyCenter marginBottom="16px">
                <Column maxWidth="152px" width="100%">
                    <GiftCard
                        src={data?.productInfo?.productImage || ''}
                        status={data?.transactionStatus}
                        title={data?.productInfo?.brandName || ''}
                    />
                </Column>
            </Section>

            {data && data.productInfo && (
                <Section justifyCenter marginBottom="8px">
                    <HtmlText
                        alignTextCenter
                        dangerouslySetInnerHTML={{ __html: data.productInfo.productDescription || '' }}
                        size="-2"
                    />
                </Section>
            )}

            <Section justifyCenter marginBottom="16px">
                <Text alignTextCenter color={grey[500]} size="-2">
                    {`${data?.denomination || '0'} ${cardInfo?.voucherCurrency || defaultCurrency}`}
                </Text>
            </Section>

            {data?.productInfo?.howToUse && (
                <Section justifyCenter marginBottom="20px">
                    <TextButton isBold isUnderline size="large" onClick={openHowToUse}>
                        How to use
                    </TextButton>
                </Section>
            )}

            {data?.transactionStatus !== GiftStatus.Failure ? (
                <Column noWrap width="100%">
                    <Section justifyCenter marginBottom="16px">
                        <Text alignTextCenter isBold>
                            Card Details
                        </Text>
                    </Section>

                    <Section marginBottom="20px">
                        <PropertyGrid>
                            <PropertyBlock copied title="Card Number" value={cardInfo?.code || ''} />
                            <PropertyBlock copied title="Pin" value={cardInfo?.pin || ''} />
                        </PropertyGrid>
                    </Section>

                    <Section marginBottom="20px">
                        <PropertyGrid>
                            <PropertyBlock
                                title="Expiration Date"
                                value={
                                    cardInfo?.validityDate ? getFormattedDate(cardInfo.validityDate, dateFormat) : ''
                                }
                            />
                            <PropertyBlock
                                title="Purchase Date"
                                value={data?.createdUtc ? getFormattedDate(data.createdUtc, dateFormat) : ''}
                            />
                        </PropertyGrid>
                    </Section>

                    <Section marginBottom="20px">
                        <PropertyGrid>
                            <PropertyBlock title="Gift Card Value" value={cardInfo?.faceValue || ''} />
                            <PropertyBlock
                                title="WOM Value"
                                value={data?.priceInWom ? `${data.priceInWom.toFixed(4)} WOM` : ''}
                            />
                        </PropertyGrid>
                    </Section>

                    <Section>
                        <PropertyGrid>
                            <PropertyBlock title="Currency" value={cardInfo?.voucherCurrency || defaultCurrency} />
                        </PropertyGrid>
                    </Section>

                    {cardInfo?.url && (
                        <Column noWrap marginTop="12px" width="100%">
                            <Section justifyCenter marginBottom="24px">
                                <Text alignTextCenter size="-2">
                                    Gift card details are available on the {data?.productInfo?.brandName} website
                                </Text>
                            </Section>
                            <Button wide onClick={() => goToExternalLink(cardInfo.url)}>
                                Go to Website
                            </Button>
                        </Column>
                    )}
                </Column>
            ) : (
                <Button wide onClick={tryAgain}>
                    Try Again
                </Button>
            )}
        </ModalWrapper>
    );
};
