import { BackArrowSmIcon } from 'assets/icons';
import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { EmptyBlock } from 'components/pages/settings/components/CommonPart/styles';
import { IconButton } from 'components/ui/buttons/IconButton';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { ReactClick } from 'types/react';

interface Props extends ReactClick<HTMLButtonElement> {
    title?: string;
}

export const ContentHeader: FC<Props> = ({ onClick, title }) => (
    <Section>
        {isMobile ? (
            <Section justifyBetween>
                <IconButton onClick={onClick}>
                    <BackArrowSmIcon />
                </IconButton>
                <Text isBold size="1">
                    {title}
                </Text>
                <EmptyBlock />
            </Section>
        ) : (
            <Section marginLeft="10px">
                <Text isBold size="-1">
                    {title}
                </Text>
            </Section>
        )}
    </Section>
);
