import { Text } from 'components/ui/typography/Text';
import { disableDefaultButtonStyleMixin, transitionTime } from 'constants/styles';
import { grey } from 'constants/styles/colors';
import styled from 'styled-components';

interface Props {
    noPadding?: boolean;
}

export const TooltipContainer = styled.div<Props>`
    background: ${grey[900]};
    border-radius: 8px;
    z-index: 99;
    ${({ noPadding }) => !noPadding && 'padding: 8px'};
`;

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    border-radius: 8px;
    padding: 9px 10px;
    width: 100%;
    text-align: left;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const IconWrapper = styled.span`
    display: inline-flex;
    margin-right: 8px;
    vertical-align: middle;
`;

export const StyledText = styled(Text)`
    vertical-align: middle;
`;
