import axios from './axios';

export const createAccountAnonymous = (data: BULLZ.UserCreateAnonymousAccountRequest) =>
    axios<BULLZ.UserJwtTokenResponse>(
        {
            url: '/user/create-account-anonymous',
            data
        },
        false
    );

export const getProfile = (data: BULLZ.GetUserProfileRequest) =>
    axios<BULLZ.GetUserProfileResponse>({
        url: '/user/get-profile',
        data
    });

export const getNetworksByUserId = (data: BULLZ.GetAllUserExternalNetworkRequest) =>
    axios<BULLZ.GetAllUserExternalNetworkResponse>({
        url: '/user/get-all-user-external-networks',
        data
    });

export const sendVerificationCode = (data: BULLZ.SendVerificationSmsRequest, firebaseToken: string) =>
    axios<BULLZ.GetAllUserExternalNetworkResponse>({
        url: '/user/sms-send-verification',
        data,
        headers: {
            'X-Firebase-AppCheck': firebaseToken
        }
    });

export const forgotPasswordSendVerificationCode = (
    data: BULLZ.SendForgottenPasswordSmsRequest,
    firebaseToken: string
) =>
    axios<BULLZ.SendForgottenPasswordSmsResponse>({
        url: '/user/sms-send-forgotten-password',
        data,
        headers: {
            'X-Firebase-AppCheck': firebaseToken
        }
    });

export const checkSmsCode = (data: BULLZ.CheckSmsCodeRequest) =>
    axios<BULLZ.CheckSmsCodeResponse>({
        url: '/user/sms-check-verification-code',
        data
    });

export const checkUsernameExistence = (data: BULLZ.UsernameExistsRequest) =>
    axios<BULLZ.ExistenceResponse>({
        url: '/user/username-exists',
        data
    });

export const createAccount = (data: BULLZ.UserCreateAccountRequest) =>
    axios<BULLZ.UserJwtTokenResponse>({
        url: '/user/create-account',
        data
    });

export const updateAccount = (data: BULLZ.UserUpdateRequest) =>
    axios<BULLZ.GetUserResponse>({
        url: '/user/update',
        data
    });

export const getUserHashtags = (data: BULLZ.QueryFollowedHashTagsRequest) =>
    axios<BULLZ.QueryFollowingHashTagsResponse>({
        url: '/user/hashtag/query-followed',
        data
    });

export const getUser = (data: BULLZ.GetBullzUserRequest) =>
    axios<BULLZ.GetUserResponse>({
        url: '/user/get',
        data
    });

export const changePassword = (data: BULLZ.ChangeUserPasswordViaSmsRequest) =>
    axios<BULLZ.ChangeUserPasswordViaSmsResponse>({
        url: '/user/sms-change-user-password',
        data
    });

export const uploadAvatar = (userId: string, file: File) => {
    const formData = new FormData();
    formData.append('fileName', file);

    return axios<BULLZ.GetUserProfileImageResponse>({
        url: `/user/upload-profile-image/${userId}`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteAccount = (data: BULLZ.DeleteUserRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/delete-account',
        data
    });

export const validatePassword = (data: BULLZ.UserValidatePasswordRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/validate-password',
        data
    });

export const setNewPassword = (data: BULLZ.UserChangePasswordRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/change-password',
        data
    });

export const getReferralLink = () =>
    axios<BULLZ.StringWrapped>({
        url: '/user/referral',
        method: 'GET'
    });

export const getReferralCount = () =>
    axios<BULLZ.UserSignupsCountResponseApiResponse>({
        url: '/user/referrals/accepted-invites',
        method: 'GET'
    });

export const reportUser = (data: BULLZ.ReportUserRequest) =>
    axios<BULLZ.MessageResponseBase>({
        url: '/user/report',
        data
    });
