import { defaultWalletDataQueryParams } from 'constants/services/wallet-data';
import { createEffect, createEvent, createStore } from 'effector';
import { womAPI } from 'services/wom';

//Events
const resetStores = createEvent();

// Effects
const getWalletInfoFx = createEffect({
    handler: async () => await womAPI.data.getWalletData({})
});

const getTransactionsInfoFx = createEffect({
    handler: async (values: Omit<WOM.TransactionQueryRequest, 'pageIndex' | 'limit'>) =>
        await womAPI.data.getTransactionsInfo({ ...defaultWalletDataQueryParams, ...values })
});

const loadMoreTransactionsFx = createEffect({
    handler: async (data: WOM.TransactionQueryRequest) => await womAPI.data.getTransactionsInfo(data)
});

const getWomRatesFx = createEffect({
    handler: async () => await womAPI.data.getWomRates({})
});

//Stores
const $walletInfo = createStore<WOM.WalletResponse>({})
    .on(getWalletInfoFx.doneData, (_, newState) => newState)
    .reset(resetStores);

const $transactionsInfo = createStore<WOM.TransactionQueryResponse>({})
    .on(getTransactionsInfoFx.doneData, (_, newState) => newState)
    .on(loadMoreTransactionsFx.doneData, (state, payload) => ({
        ...payload,
        items: [...(state.items || []), ...(payload.items || [])]
    }))
    .reset(resetStores);

const $womRates = createStore<WOM.ExchangeRateResponse>({})
    .on(getWomRatesFx.doneData, (_, newState) => newState)
    .reset(resetStores);

const $hasErrorTransactions = createStore<boolean>(false)
    .on(getTransactionsInfoFx.fail, () => true)
    .on(loadMoreTransactionsFx.done, () => true)
    .reset([getTransactionsInfoFx.done, loadMoreTransactionsFx.done, resetStores]);

//Exports

export const walletDataEvents = { resetStores };
export const walletDataEffects = { getWalletInfoFx, getTransactionsInfoFx, getWomRatesFx, loadMoreTransactionsFx };
export const walletDataStores = { $walletInfo, $transactionsInfo, $womRates, $hasErrorTransactions };
