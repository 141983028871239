import { Column, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { Button } from 'components/ui/buttons/Button';
import { Text } from 'components/ui/typography/Text';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { Disabled } from 'types';
import { BlockWrapper } from './styles';

interface Props extends Disabled {
    title?: string;
    text?: string;
    textButton?: string;
    onClick: () => void;
}

export const CreatingBlockPage: FC<Props> = ({ title, text, textButton, disabled = false, onClick }) => (
    <BlockWrapper>
        <Column marginBottom={isMobile ? '12px' : '0'} maxWidth="300px">
            <Section marginBottom="8px">
                <Text isBold size="-1">
                    {title}
                </Text>
            </Section>
            <Text size="-2">{text}</Text>
        </Column>
        <Button disabled={disabled} isBold={false} size="small" wide={isMobile} onClick={onClick}>
            {textButton}
        </Button>
    </BlockWrapper>
);
