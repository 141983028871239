import { Column } from 'components/grid/wrappers/FlexWrapper';
import { allTransition, grey } from 'constants/styles';
import { media } from 'constants/styles/media';
import styled from 'styled-components';

export const videoWidth = '270px';

export const Wrapper = styled(Column)`
    ${allTransition};
    width: ${videoWidth};
    height: 300px;
    border: 1px solid ${grey[600]};
    background-color: ${grey[900]};
    border-radius: 8px;
    overflow: hidden;

    ${media.laptop(`
        height: 512px;
    `)};
`;

export const StyledInput = styled.input`
    display: none;
`;

export const StyledColumn = styled(Column)`
    padding: 24px;
`;
