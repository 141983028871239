import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import styled from 'styled-components';

export const TagsWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    margin-left: -8px;
`;

export const TagWrapper = styled(Section)`
    white-space: nowrap;
    margin-left: 8px;
`;
