import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { grey } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/global';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const VideoSharesWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
`;

export const VideoSharesItem = styled(Section)`
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 8px;
    padding: 9px 10px;
    text-align: left;
    transition: background-color ${transitionTime} linear;
    cursor: pointer;

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const shareButtonStyles: CSSProperties = {
    width: '100%'
};
