import {
    InstagramSmIcon,
    ReelsSmIcon,
    ShortsSmIcon,
    TiktokLogoSmIcon,
    TwitchSmIcon,
    TwitterSmIcon,
    YoutubeSmIcon
} from 'assets/icons';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';

export enum Network {
    Twitter = 'Twitter',
    Post = 'Post',
    Reels = 'Reels',
    Story = 'Story',
    TikTok = 'TikTok',
    Youtube = 'Youtube',
    Shorts = 'Shorts',
    Twitch = 'Twitch'
}

export enum ShareStep {
    First = 'first',
    Second = 'second',
    Third = 'third'
}

export enum Slide {
    First,
    Second,
    Third
}

// TODO: Need refactoring networks, netwokAssociation. We need to take backend result, not front filter.
export const networks = [
    { id: 0, title: Network.Twitter, icon: TwitterSmIcon },
    { id: 1, title: Network.Post, icon: InstagramSmIcon },
    { id: 2, title: Network.Reels, icon: ReelsSmIcon },
    { id: 3, title: Network.Story, icon: InstagramSmIcon },
    { id: 4, title: Network.TikTok, icon: TiktokLogoSmIcon },
    { id: 5, title: Network.Youtube, icon: YoutubeSmIcon },
    { id: 6, title: Network.Shorts, icon: ShortsSmIcon },
    { id: 7, title: Network.Twitch, icon: TwitchSmIcon }
];

export const networkAssociation = {
    [Network.Post]: ExternalNetworkKey.Instagram,
    [Network.Reels]: ExternalNetworkKey.Instagram,
    [Network.Shorts]: ExternalNetworkKey.Youtube,
    [Network.Story]: ExternalNetworkKey.Instagram,
    [Network.TikTok]: ExternalNetworkKey.Tiktok,
    [Network.Twitter]: ExternalNetworkKey.Twitter,
    [Network.Youtube]: ExternalNetworkKey.Youtube,
    [Network.Twitch]: ExternalNetworkKey.Twitch
};
