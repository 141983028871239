import { Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { baseColors, ellipsisMixin, grey, MontserratFontFamily, transitionTime } from 'constants/styles';
import styled from 'styled-components';

export const WalletIdWrapper = styled(Section)`
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    flex-wrap: nowrap;
    transition: background-color ${transitionTime} linear;
    background-color: ${baseColors.darkGrey};

    &:hover {
        background-color: ${grey[800]};
    }
`;

export const TextWrapper = styled.div`
    ${ellipsisMixin};
    max-width: 90%;
    font-family: ${MontserratFontFamily};
    font-size: 14px;
    line-height: 20px;
    color: ${grey['500']};
`;
