import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { RulesChecklist } from 'components/modals/RegisterModal/RulesChecklist';
import { RulesChecklistItem } from 'components/modals/RegisterModal/RulesChecklist/types';
import { Button } from 'components/ui/buttons/Button';
import { CodeInput } from 'components/ui/inputs/CodeInput';
import { Text } from 'components/ui/typography/Text';
import React, { FC, useState } from 'react';

const codeLength = 6;

interface Props {
    onSuccess: (pin: string) => void;
}

export const NewPinStep: FC<Props> = ({ onSuccess }) => {
    const [code, setCode] = useState('');
    const [codeIsTouched, setCodeIsTouched] = useState(false);
    const [confirmCode, setConfirmCode] = useState('');
    const [confirmCodeIsTouched, setConfirmCodeIsTouched] = useState(false);

    const confirmChecklist: RulesChecklistItem[] = [
        {
            title: 'Pins match',
            isSuccess: code.length === codeLength && code === confirmCode
        }
    ];

    const codeError = codeIsTouched && code.length === 0 ? 'Required' : undefined;
    const confirmCodeError = confirmCodeIsTouched && confirmCode.length === 0 ? 'Required' : undefined;

    const submitIsActive = code.length === codeLength && code === confirmCode;

    const handleChange = (type: 'code' | 'confirm') => (value: string) => {
        if (type === 'code') {
            setCode(value);
            !codeIsTouched && setCodeIsTouched(true);
        } else {
            setConfirmCode(value);
            !confirmCodeIsTouched && setConfirmCodeIsTouched(true);
        }
    };

    const handleSubmit = () => {
        if (!submitIsActive || !!codeError || !!confirmCodeError) return;
        onSuccess(code);
    };

    return (
        <Column noWrap height="100%" width="100%">
            <Column justifyBetween height="100%">
                <Column>
                    <Section marginBottom="8px">
                        <Text isBold size="1">
                            Enter New Wallet Pin
                        </Text>
                    </Section>

                    <Section marginBottom="16px">
                        <Text size="-2">Please enter the new wallet pin</Text>
                    </Section>

                    <Section marginBottom="24px">
                        <CodeInput error={codeError} value={code} onChange={handleChange('code')} />
                    </Section>

                    <Section marginBottom="16px">
                        <Text size="-2">Confirm Wallet Pin</Text>
                    </Section>

                    <Section marginBottom="16px">
                        <CodeInput
                            error={confirmCodeError}
                            shouldAutoFocus={false}
                            value={confirmCode}
                            onChange={handleChange('confirm')}
                        />
                    </Section>

                    <Section marginBottom="16px">
                        <RulesChecklist items={confirmChecklist} />
                    </Section>
                </Column>

                <Section justifyEnd>
                    <Button disabled={!submitIsActive} onClick={handleSubmit}>
                        Next
                    </Button>
                </Section>
            </Column>
        </Column>
    );
};
