import {
    AttachSmIcon,
    CommentSmIcon,
    HeartFilledSmIcon,
    HeartSmIcon,
    LinkSmIcon,
    ReportSmIcon,
    SaveSmIcon,
    ShareFilledSmIcon,
    ThreeDotsVerticalSmIcon,
    VerificationSmIcon
} from 'assets/icons';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { VideoInfoSkeleton } from 'components/loaders/VideoInfoSkeleton';
import { Button } from 'components/ui/buttons/Button';
import { IconButton } from 'components/ui/buttons/IconButton';
import { TextButton } from 'components/ui/buttons/TextButton';
import { OptionsPopover } from 'components/ui/OptionsPopover';
import { Shares } from 'components/ui/Shares';
import { Tag } from 'components/ui/Tag';
import { notify } from 'components/ui/Toasts';
import { Text } from 'components/ui/typography/Text';
import { SearchCategory } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { ValidationStatusUI } from 'constants/services/video-statuses';
import { baseColors, grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { authStores } from 'stores/auth';
import { fullscreenVideoStores } from 'stores/fullscreen-video';
import { loginModal, reportVideoModal } from 'stores/initialize.modal';
import { savedVideoEffects } from 'stores/saved-video';
import { userFollowEffects } from 'stores/user-follow';
import { videoEffects } from 'stores/video';
import { getVideoLink, goToExternalLink } from 'utils/common';
import { getValidationStatus } from 'utils/video';
import { ValidationStatusButton } from '../ValidationStatusButton';
import { Dot, UserLink, VideoInfoWrapper } from './styles';

const { registerEventVideo } = videoEffects;
const { $currentVideoValidation } = fullscreenVideoStores;

interface Props {
    video: BULLZ.PlaylistSingleVideoResponse;
    isLoading?: boolean;
}

export const VideoInfo: FC<Props> = ({ video, isLoading }) => {
    const history = useHistory();
    const user = useStore(authStores.$user);
    const isAuth = useStore(authStores.$isAuth);
    const currentVideoValidation = useStore($currentVideoValidation);

    const {
        profileImageUrl,
        username,
        engagement,
        primaryProduct,
        hashTags,
        ownerId,
        isFollowed,
        id,
        engagementUser,
        isTrusted,
        isInCollaboration
    } = video;

    const tags = hashTags ? hashTags.filter(tag => !!tag) : [];

    const validStatus = useMemo(() => getValidationStatus(currentVideoValidation, isInCollaboration), [
        currentVideoValidation,
        isInCollaboration
    ]);
    const statusIsVisible = validStatus !== ValidationStatusUI.None && !!currentVideoValidation;

    const handleTagClick = (tag: string) => {
        history.push(`${AppRoute.SearchVideos}?by=${SearchCategory.Hashtag}&name=${tag}&tagId=${tag}`);
    };

    const handleProfileClick = () => {
        history.push(ownerId ? `${AppRoute.Profile}/${ownerId}` : AppRoute.Main);
    };

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Link Copied');
        }
    };

    const goToWebsite = () => {
        primaryProduct?.link && goToExternalLink(primaryProduct.link);
    };

    const handleLikeVideo = () => {
        if (isAuth) {
            registerEventVideo({
                videoId: id,
                liked: !engagementUser?.isLiked
            });
        } else {
            loginModal.openModal();
        }
    };

    const handleSaveVideo = () => {
        if (isAuth) {
            savedVideoEffects.saveVideoFx({
                videoId: id,
                isSave: !engagementUser?.isSaved
            });
        } else {
            loginModal.openModal();
        }
    };

    const handleReportVideo = () => {
        if (isAuth) {
            reportVideoModal.openModal({ videoId: id });
        } else {
            loginModal.openModal();
        }
    };

    const handleFollow = () => {
        if (user)
            userFollowEffects.followUserFx({
                userId: user.userId,
                creatorId: ownerId,
                isFollow: !isFollowed
            });
    };

    const additionalOptions = [
        {
            id: 0,
            title: 'Report',
            action: handleReportVideo,
            iconComponent: <ReportSmIcon color={grey[500]} height="14px" width="14px" />
        }
    ];

    return (
        <VideoInfoWrapper>
            {isLoading ? (
                <VideoInfoSkeleton />
            ) : (
                <>
                    <Section alignCenter justifyBetween marginBottom="28px">
                        <Row alignCenter>
                            <UserLink type="button" onClick={handleProfileClick}>
                                <AvatarImg diameter="40px" src={profileImageUrl || undefined} />

                                <Column marginLeft="10px">
                                    <Text size="-2">@{username}</Text>
                                </Column>

                                {isTrusted && (
                                    <Column marginLeft="6px">
                                        <VerificationSmIcon />
                                    </Column>
                                )}
                            </UserLink>

                            {user && user.userId !== ownerId && (
                                <>
                                    <Column marginLeft="16px">
                                        <Dot />
                                    </Column>

                                    <Column marginLeft="16px">
                                        <TextButton onClick={handleFollow}>
                                            {isFollowed ? 'Following' : 'Follow'}
                                        </TextButton>
                                    </Column>
                                </>
                            )}
                        </Row>

                        <Row alignCenter justifyEnd>
                            {statusIsVisible && id && <ValidationStatusButton status={validStatus} videoId={id} />}

                            <Column marginLeft="16px">
                                <IconButton
                                    color={engagementUser?.isSaved ? baseColors.blue : grey['500']}
                                    onClick={handleSaveVideo}
                                >
                                    <SaveSmIcon />
                                </IconButton>
                            </Column>

                            <Column marginLeft="16px">
                                <CopyToClipboard text={getVideoLink(video.id)} onCopy={handleCopy}>
                                    <IconButton color={grey['500']}>
                                        <AttachSmIcon />
                                    </IconButton>
                                </CopyToClipboard>
                            </Column>

                            {video.ownerId !== user?.userId && (
                                <Column marginLeft="16px">
                                    <OptionsPopover
                                        noPadding
                                        items={additionalOptions}
                                        placement="bottom-end"
                                        width="117px"
                                    >
                                        <IconButton color={grey['500']}>
                                            <ThreeDotsVerticalSmIcon />
                                        </IconButton>
                                    </OptionsPopover>
                                </Column>
                            )}
                        </Row>
                    </Section>

                    <Section alignCenter>
                        <IconButton
                            color={engagementUser?.isLiked ? baseColors.white : grey['500']}
                            size="auto"
                            onClick={handleLikeVideo}
                        >
                            <Row alignCenter>
                                {engagementUser?.isLiked ? <HeartFilledSmIcon /> : <HeartSmIcon />}
                                <MarginWrapper marginLeft="6px">
                                    <Text color="inherit" size="-2">
                                        {engagement?.likes || 0}
                                    </Text>
                                </MarginWrapper>
                            </Row>
                        </IconButton>

                        <MarginWrapper marginLeft="18px">
                            <IconButton disabled color={grey['500']} size="auto">
                                <Row alignCenter>
                                    <CommentSmIcon />
                                    <MarginWrapper marginLeft="6px">
                                        <Text color="inherit" size="-2">
                                            {engagement?.commentCount || 0}
                                        </Text>
                                    </MarginWrapper>
                                </Row>
                            </IconButton>
                        </MarginWrapper>

                        <MarginWrapper marginLeft="18px">
                            <OptionsPopover placement="right-end" renderItems={<Shares videoId={id} />}>
                                <IconButton color={grey['500']} size="auto">
                                    <Row alignCenter>
                                        <ShareFilledSmIcon />
                                        <MarginWrapper marginLeft="6px">
                                            <Text color="inherit" size="-2">
                                                {engagement?.shares || 0}
                                            </Text>
                                        </MarginWrapper>
                                    </Row>
                                </IconButton>
                            </OptionsPopover>
                        </MarginWrapper>
                    </Section>

                    {tags.length > 0 && (
                        <Section marginTop="16px">
                            {tags.map(tag => (
                                <MarginWrapper key={tag} marginRight="4px">
                                    <Tag subject={tag} onClick={handleTagClick}>
                                        #{tag}
                                    </Tag>
                                </MarginWrapper>
                            ))}
                        </Section>
                    )}

                    {primaryProduct && primaryProduct.link && (
                        <Section marginTop="16px">
                            <Button icon={<LinkSmIcon />} isBold={false} size="small" onClick={goToWebsite}>
                                Go to Website
                            </Button>
                        </Section>
                    )}
                </>
            )}
        </VideoInfoWrapper>
    );
};
