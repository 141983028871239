export const criteria = [
    { id: 0, title: 'Authenticity', text: 'Is the recommendation honest, reliable and trustworthy?' },
    { id: 1, title: 'Creativity', text: 'Does the recommendation show creativity, effort and originality?' },
    {
        id: 2,
        title: 'Positivity',
        text: 'Is the recommendation showing the product in a positive light to create desire?'
    }
];

export enum Slide {
    First,
    Second,
    Third
}
