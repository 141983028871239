import bucmAxios from './bucm-axios';

export const estimateSubmissionDate = (userId: string, collaborationId: string) =>
    bucmAxios<BUCM.EstimateSubmissionDateResponseApiResponse>({
        url: `/participants/${userId}/collaborations/${collaborationId}/estimate-submission-date`,
        method: 'GET'
    });

export const participateInCollaboration = (
    userId: string,
    collaborationId: string,
    data: BUCM.EnrollInCollaborationRequest
) =>
    bucmAxios<BUCM.ApiResponse>({
        url: `/participants/${userId}/collaborations/${collaborationId}`,
        data
    });

export const leaveCollaboration = (userId: string, collaborationId: string) =>
    bucmAxios<BUCM.ApiResponse>({
        url: `/participants/${userId}/collaborations/${collaborationId}`,
        method: 'DELETE'
    });
