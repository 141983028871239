import { forward } from 'effector';
import { qsGuideModal } from 'stores/initialize.modal';
import { loaderEffects } from 'stores/loader';
import { qsChannelEffects } from 'stores/quick-signals-channel';

const { createChannelFx, createChannelFailFx, editChannelFx, editChannelFailFx } = qsChannelEffects;

forward({
    from: [createChannelFx.pending, editChannelFx.pending],
    to: loaderEffects.loaderFx
});

// Create

forward({
    from: createChannelFx.fail,
    to: createChannelFailFx
});

forward({
    from: createChannelFx.done,
    to: qsGuideModal.openModal
});

// Edit

forward({
    from: editChannelFx.fail,
    to: editChannelFailFx
});
