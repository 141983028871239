import { format } from 'date-fns';

export const getFormattedDate = (date?: string | null, separator = '.') => {
    if (!date) return '';
    if (!Date.parse(date)) return '';

    return format(Date.parse(date), `dd${separator}MM${separator}yyyy`); //16.01.2021
};

export const getFormattedTime = (date?: string | null) => {
    if (!date) return '';
    if (!Date.parse(date)) return '';

    return format(Date.parse(date), 'hh:mm'); // 10:17
};

export const totalCurrency = (walletBalance: number, rate: number) =>
    Number.isNaN(walletBalance) ? 0 : (rate * walletBalance).toFixed(2);

interface TransactionsDate {
    date: string;
    items: WOM.TransactionResponse[];
}

export const prepareTransactions = (items?: WOM.TransactionResponse[] | null): TransactionsDate[] => {
    if (!items || items.length === 0) {
        return [];
    }

    const result: TransactionsDate[] = [];

    for (let i = 0; i < items.length; i++) {
        const date = getFormattedDate(items[i].utcCreated);

        const index = result.findIndex(item => item.date === date);
        if (index !== -1) {
            result[index].items.push(items[i]);
        } else {
            result.push({ date: date, items: [items[i]] });
        }
    }
    return result;
};
