import { CopySmIcon } from 'assets/icons';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { IconButton } from 'components/ui/buttons/IconButton';
import { notify } from 'components/ui/Toasts';
import { HtmlText } from 'components/ui/typography/HtmlText';
import { Text } from 'components/ui/typography/Text';
import { grey } from 'constants/styles/colors';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props {
    title: string;
    value?: string | number;
    copied?: boolean;
    isHtml?: boolean;
}

export const PropertyBlock: FC<Props> = ({ title, value, copied, isHtml }) => {
    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify(`${title} Copied`);
        }
    };

    return (
        <Column>
            <Row marginBottom="4px">
                <Text size="-1">{title}</Text>
            </Row>

            <Row alignCenter noWrap>
                {isHtml ? (
                    <HtmlText color={grey[500]} dangerouslySetInnerHTML={{ __html: `${value}` }} size="-2" />
                ) : (
                    <Text color={grey[500]} size="-2">
                        {value || '-'}
                    </Text>
                )}

                {copied && (
                    <Column marginLeft="8px">
                        <CopyToClipboard text={`${value}` || ''} onCopy={handleCopy}>
                            <IconButton color={grey[500]} size="16px">
                                <CopySmIcon height={16} width={16} />
                            </IconButton>
                        </CopyToClipboard>
                    </Column>
                )}
            </Row>
        </Column>
    );
};
