import { CheckSmIcon } from 'assets/icons';
import { Row } from 'components/grid/wrappers/FlexWrapper';
import { Text } from 'components/ui/typography/Text';
import React, { FC, InputHTMLAttributes } from 'react';
import { HiddenInput, Label, StyledCheckbox } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

export const Checkbox: FC<Props> = ({ label, checked, ...props }) => (
    <Label>
        <HiddenInput checked={checked} type="checkbox" {...props} />
        <StyledCheckbox checked={checked}>{checked && <CheckSmIcon height={8} width={11} />}</StyledCheckbox>
        {label && (
            <Row marginLeft="8px">
                <Text size="-1">{label}</Text>
            </Row>
        )}
    </Label>
);
